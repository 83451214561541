
























import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import $axios from '@/plugins/axios'
import { resetNote, addNote as addClientNote } from '@/plugins/Notes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'

export const scrollToBottom = () => {
    const vDialog = document.querySelector('.messages')
    if (typeof vDialog !== 'undefined') {
        vDialog?.scrollTo({
            left: 0,
            top: vDialog.scrollHeight,
            behavior: 'smooth',
        })
    }
}

/**
 * @name ClientAddNewNote
 * Component in the Client Notes that allows client to send note to the AMC
 *
 * @SetupData note - note that client will send
 * @SetupMethod addNote - posts to /v1/order/order.id/note with the note, source being the client, and target AMC. Refreshes order after note is sent
 */

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const note = ref('')
        const loading = ref(false)
        const addNote = (): void => {
            loading.value = true
            if (!order.value) return
            $axios
                .post('/v1/order/' + order.value.id + '/note', {
                    note: note.value,
                    source: 'Client',
                    target: 'AMC',
                })
                .then(({ data }) => {
                    showSnackbar('Note Added Successfully!')
                    loading.value = false
                    note.value = ''
                    resetNote()
                    addClientNote(data)
                    setTimeout(() => {
                        scrollToBottom()
                    }, 300)
                })
                .catch(() => {
                    loading.value = false
                })
        }
        return {
            note,
            loading,
            addNote,
        }
    },
})
