



























































import { defineComponent, ref } from '@vue/composition-api'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import { refreshOrder } from '@/plugins/order/Order'
import { specialists } from '@/plugins/Amc.ts'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getAllUsers } from '@/plugins/Amc.ts'
import { order } from '@/plugins/order/Order'

export const cssUserDialog = ref(false)

function reset() {
    return {
        saving: false,
        assignee: null,
        note: '',
    }
}

export default defineComponent({
    components: {
        ValidationObserver,
        ACardAction,
    },
    setup() {
        return { specialists, order, cssUserDialog }
    },
    data() {
        return reset()
    },
    mounted() {
        getAllUsers()
    },
    methods: {
        open() {
            this.cssUserDialog = true
        },
        changeUser() {
            showSnackbar('Order account rep changed.')
            refreshOrder()
            Object.assign(this.$data, reset())
            this.cssUserDialog = false
            const form = this.$refs.form as InstanceType<typeof ValidationObserver>
            form.reset()
        },
    },
})
