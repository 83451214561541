import Vue from 'vue'
import ascentHelper from '@/helpers/ascent-helper'
import { CustomerFeatures } from '@/types'

Object.defineProperty(Vue.prototype, '$ascent', { value: ascentHelper })

export interface AscentHelperType {
    featureEnabled: (path: string, value: boolean | string) => string | boolean
    customerFeatureEnabled: (path: string, value?: boolean | string) => string | boolean
    orderCustomerFeatureEnabled: (data: CustomerFeatures, path: string, value?: string | boolean) => string | boolean
    getUserType: () => string
}
