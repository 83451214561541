

































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { valueArray } from '@/pages/Office/CompSearch/SearchPartails/CompSearchClass'
import {
    orderStartDate,
    orderEndDate,
    completedEndDate,
    completedStartDate,
    AdvSearchArray,
    searchText,
} from '@/components/OrderTable/Classes/OrderTable'
import { user } from '@/plugins/User'
import axios from 'axios'

const dialog = ref(false)
const filtersData = ref([] as unknown[])
const filtersTitle = ref('')

export const openSaveFilter = (): void => {
    if (!user.value) return
    axios.get('v1/user/' + user.value.id + '/searchSettings').then((response) => {
        if (response.data && response.data.settings) {
            filtersData.value = response.data.settings
        }
    })
    dialog.value = true
}

/**
 * @name 'Save Filter Dialog'
 * Saves the current filters for the Advanced Search with a title.
 *
 * @SetupMethod saveFilters - Saves the table filters.
 */

export default defineComponent({
    components: { ValidationObserver },
    props: {
        savedFilers: {
            type: [Array, Object],
        },
    },
    setup() {
        const loading = ref(false)

        const dataFilters = ref([] as { value: string }[])

        const getDates = () => {
            dataFilters.value = []
            if (orderStartDate.value) dataFilters.value.push({ value: 'Order Start Date: ' + orderStartDate.value })
            if (orderEndDate.value) dataFilters.value.push({ value: 'Order End Date: ' + orderEndDate.value })
            if (completedStartDate.value)
                dataFilters.value.push({ value: 'Completed Start Date: ' + completedStartDate.value })
            if (completedEndDate.value)
                dataFilters.value.push({ value: 'Completed End Date: ' + completedEndDate.value })
        }

        const dataFiltersComp = computed(() => {
            getDates()
            return dataFilters.value
        })

        const saveFilters = () => {
            if (!user.value) return
            const saveValue = {
                facetFilters: Object.values(AdvSearchArray.value),
                q: searchText.value,
                orderStartDate: orderStartDate.value,
                orderEndDate: orderEndDate.value,
                completedStartDate: completedStartDate.value,
                completedEndDate: completedEndDate.value,
                searchName: filtersTitle.value,
            }
            filtersData.value.push(saveValue)
            axios
                .post('v1/user/' + user.value.id + '/searchSettings', {
                    settings: filtersData.value,
                })
                .then(() => {
                    showSnackbar('Filters Saved')
                    dialog.value = false
                })
                .catch(() => {
                    filtersData.value.splice(filtersData.value.indexOf(saveValue), 1)
                    showError('Title has already been used.')
                    // dialog.value = false
                })
        }

        return {
            dialog,
            loading,
            saveFilters,
            order,
            filtersTitle,
            valueArray,
            orderEndDate,
            orderStartDate,
            completedEndDate,
            completedStartDate,
            AdvSearchArray,
            searchText,
            user,
            dataFilters,
            dataFiltersComp,
            filtersData,
        }
    },
})
