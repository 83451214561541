































































import {getFiles} from "@/plugins/Files";
import { User } from '@/types'
import {computed, defineComponent, onBeforeMount, ref} from '@vue/composition-api'
import { fileStatuses } from '@/plugins/Data'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export const dialog = ref(false)
export const fileIds = ref([] as number[])

export const openBulkShareFile = (selectedFiles: number[]): void => {
    fileIds.value = selectedFiles
    dialog.value = true
}

export default defineComponent({
    setup() {
        const status = ref('')
        const users = ref([])
        const selectedUsers = ref([])

        const btnText = computed(() => {
            if (status.value !== '' && selectedUsers.value.length > 0) return 'Save and Send'
            if (selectedUsers.value.length > 0) return 'Send'
            return 'Save'
        })

        const closeDialog = (useSnackbar: boolean) => {
            if (useSnackbar) {
                if (status.value !== '' && selectedUsers.value.length > 0) showSnackbar('Files Edited and Shared Successfully.')
                if (status.value !== '' && selectedUsers.value.length === 0) showSnackbar('File Edited Successfully')
                if (status.value === '' && selectedUsers.value.length > 0) showSnackbar('Files Shared Successfully.')
            }

            status.value = ''
            selectedUsers.value = []
            dialog.value = false
        }

        const saveAndSendFiles = () => {
            if (!order.value) return

            if (status.value !== '') {
                $axios
                    .patch('v1/order/' + order.value.id + '/bulk-file-actions/update', {
                        status: status.value,
                        file_ids: fileIds.value,
                    })
                    .then(() => {
                        dialog.value = false
                        if (!order.value) return
                        getFiles(order.value.id)
                    })
            }

            if (selectedUsers.value.length > 0) {
                $axios
                    .post('v1/order/' + order.value.id + '/action/share-bulk-files', {
                        userIds: selectedUsers.value.map((user: User) => user.id),
                        fileIds: fileIds.value,
                    })
                    .catch(() => {
                        showSnackbar('An error ocurred while trying to share files.', 'error')
                    })
            }

            closeDialog(true)
        }

        const getUsers = () => {
            $axios.get('/v1/customer/user').then((response) => {
                users.value = response.data
            })
        }

        onBeforeMount(() => getUsers())

        return {
            btnText,
            dialog,
            fileStatuses,
            fileIds,
            selectedUsers,
            status,
            users,
            closeDialog,
            saveAndSendFiles,
        }
    },
})
