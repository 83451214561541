import $axios from '@/plugins/axios'
import { computed, ref } from '@vue/composition-api'
import { Tag, TagConfig } from '@/types'
import { sortBy } from 'lodash'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

export const noteTags = ref([] as Tag[])
export const orderTags = ref([] as Tag[])
export const appraiserTags = ref([] as TagConfig[])
export const clientTags = ref([] as Tag[])
export const GlobalTags = ref([] as TagConfig[])

export const tableTags = computed(() => {
    return orderTags.value?.map((elem) => {
        return { id: elem.title, text: elem.title }
    })
})

export const getTagConfig = () => {
    $axios.get('/v1/settings/tags').then(({ data }) => {
        if (!data.result) return
        orderTags.value = data.result.find((e: TagConfig) => e.type === 'Order Tags')?.tags
        if (
            AscentHelper.customerFeatureEnabled('orderScreen.appraisersCanManageTags') &&
            user.value?.type === 'appraiser'
        ) {
            orderTags.value = orderTags.value.filter((e: Tag) => e.showToAppraiser)
        }
        orderTags.value = sortBy(orderTags.value, 'title')
        noteTags.value = data.result.find((e: TagConfig) => e.type === 'Note Tags')?.tags
        noteTags.value = sortBy(noteTags.value, 'title')
        appraiserTags.value = data.result.find((e: TagConfig) => e.type === 'Appraiser Tags')?.tags
        appraiserTags.value = sortBy(appraiserTags.value, 'title')
        clientTags.value = data.result.find((e: TagConfig) => e.type === 'Client Tags')?.tags
        clientTags.value = sortBy(clientTags.value, 'title')
        GlobalTags.value = data.result
    })
}
