var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('span',{staticClass:"grey--text"},[_vm._v("Quality and Delivery Notes")])])],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_c('strong',[_vm._v("Key Directives")])])],1),(_vm.optionShow.key_directives)?_c('v-list-item',[_c('v-list-item-title',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.optionShow.key_directives)}})])],1):_vm._e()],1)],1),(!_vm.optionShow.key_directives)?_c('v-col',{staticClass:"pl-2"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"grey--text",domProps:{"innerHTML":_vm._s('None Added')}})])],1)],1)],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_c('strong',[_vm._v("Client Notes")])])],1),(_vm.optionShow.client_notes)?_c('v-list-item',[_c('v-list-item-title',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.optionShow.client_notes)}})])],1):_vm._e()],1)],1),(!_vm.optionShow.client_notes)?_c('v-col',{staticClass:"pl-2"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"grey--text",domProps:{"innerHTML":_vm._s('None Added')}})])],1)],1)],1):_vm._e()],1)],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[(_vm.$ascent.canDo('Edit Clients'))?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondarydarken4"}},'v-btn',attrs,false),on),[_vm._v(" Edit ")]):_vm._e()],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('a-card',{attrs:{"title":"Edit Quality and Delivery Notes"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Key Directives")]),_c('a-editor',{ref:"editor",attrs:{"uuid":"Instructions","tag":"textarea"},model:{value:(_vm.optionEdit.key_directives),callback:function ($$v) {_vm.$set(_vm.optionEdit, "key_directives", $$v)},expression:"optionEdit.key_directives"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Client Notes")]),_c('a-editor',{ref:"editor1",attrs:{"uuid":"Instructions1","tag":"textarea1"},model:{value:(_vm.optionEdit.client_notes),callback:function ($$v) {_vm.$set(_vm.optionEdit, "client_notes", $$v)},expression:"optionEdit.client_notes"}})],1)],1)],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"btnText":"Save Notes","url":("/v1/lender/" + (_vm.lender.id) + "/option"),"method":"POST","submitData":{
                    option_key: 'quality-and-delivery-notes',
                    option_value: _vm.optionEdit,
                }},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.saveChanges}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }