import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'
import { orderProducts } from '@/plugins/order/OrderProducts'

const OrderHelper = {
    orderCanBePaid: computed(() => {
        if (!order || !order.value) return false
        return AscentHelper.canBePaid(order.value, order.value.incremental)
    }),

    orderIsPaid: computed(() => {
        if (!order || !order.value) return false
        return AscentHelper.isPaid(order.value)
    }),

    hasBorrowerPayment: computed(() => {
        if (!order || !order.value) return false
        if (AscentHelper.isPaid(order.value)) return false
        if (AscentHelper.featureEnabled('orderScreen.invoice.showSendPaymentAlways', true)) return true
        if (order.value.billingMethod === 'borrower-paid') return true
        if (order.value.billingMethod === 'split') {
            if (!order.value?.billingDetails) return
            if (!order.value?.billingDetails.splits) return
            return order.value.billingDetails.splits.some(
                (split: { method: string }) => split.method === 'borrower-paid',
            )
        }
        return false
    }),

    is1004D: computed(() => {
        if (!order || !order.value || !order.value.incremental) return false

        const internalFormIds = [19, 20]

        return orderProducts.value.some((product) => {
            return internalFormIds.includes(product.product_id)
        })
    }),

    due: computed(() => {
        if (order.value?.billingMethod !== 'split' || !order.value?.incremental?.billing_details?.splits.length)
            return order.value?.incremental?.total?.due
        const splitDue = order.value?.incremental?.billing_details?.splits.reduce((sum, elem) => {
            if (elem.method === 'credit-card') {
                return sum + parseFloat(elem.amount)
            }
            return sum
        }, 0)
        if (splitDue <= (order.value?.incremental?.total?.due as number)) return splitDue
        return order.value?.incremental?.total?.due
    }),
}

Object.defineProperty(Vue.prototype, '$orderHelper', { value: OrderHelper })
export default OrderHelper
