





































import { defineComponent } from '@vue/composition-api'
import ClientOrderScreen from '@/components/OrderScreens/Client/ClientOrderScreen.vue'
import AppraiserOrderScreen from '@/components/OrderScreens/Appraiser/AppraiserOrderScreen.vue'
import AppraiserOrderScreenMobile from '@/components/OrderScreens/Appraiser/AppraiserOrderScreenMobile.vue'
import ClientOrderScreenMobile from '@/components/OrderScreens/Client/ClientOrderScreenMobile.vue'
import SnapshotOrderScreen from '@/components/OrderScreens/Snapshot/SnapshotOrderScreen.vue'

import { user } from '@/plugins/User'
import { closeOrder, orderOpen, order, snapshotOrder } from '@/plugins/order/Order'
import CustomerOrderScreen from '@/components/OrderScreens/Customer/CustomerOrderScreen.vue'

export const orderScreenScroll = (where: string) => {
    const orderScreen = document?.getElementsByClassName('order-screen')
    orderScreen[0]?.scrollTo({
        top: where == 'top' ? 0 : orderScreen[0].scrollHeight,
        left: 0,
        behavior: 'smooth',
    })
}

/**
 * @name 'Order Screen'
 * Component description: The order screen and all the components displayed within it.
 * @SetupData orderScreenScroll() => : Scrolls to the top of the order
 */
export default defineComponent({
    components: {
        CustomerOrderScreen,
        ClientOrderScreen,
        ClientOrderScreenMobile,
        AppraiserOrderScreen,
        AppraiserOrderScreenMobile,
        SnapshotOrderScreen,
    },
    setup() {
        return {
            orderScreenScroll,
            user,
            orderOpen,
            closeOrder,
            order,
            snapshotOrder,
        }
    },
})
