




















































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { getNotes } from '@/plugins/Notes'
import { order, refreshOrder, updateOrder, directOrder } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import axios from '@/plugins/axios'

function reset() {
    return {
        note: '',
        loading: false,
        request: false,
    }
}

/**
 * @name ClientRevisionAction
 * Component in Client Workflow that allows client to start a reconsideration request after order is completed. Client can also order additional products
 *
 */

export default defineComponent({
    components: { ValidationObserver },
    props: {},
    data() {
        return reset()
    },
    computed: {
        /**
         * The url of the reconsideration
         */
        reconsiderationURL(): string {
            if (!order.value || !order.value?.incremental) return ''
            return '/appraisal/reconsideration/' + order.value.id + '/' + order.value.incremental.token
        },
    },
    methods: {
        /**
         * Escalates order to put back into review for the amc qc team. Currently not used.
         */
        escalate() {
            confirm(
                'Escalate File For Review?',
                'Would you like to put this file back in QC to be Reviewed again?',
            ).then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    axios.post('/v1/quality/action/' + order.value.id + '/escalate-order-for-review').then((data) => {
                        if (!order.value) return
                        let tags = order.value.tags
                        if (tags == null) {
                            tags = []
                        }
                        tags.push(data.data.tag)
                        updateOrder()
                        getNotes(order.value.id)
                        showSnackbar('File Escalated successfully')
                        Object.assign(this.$data, reset())
                    })
                }
            })
        },
        /**
         * Starts a revision request with a note a reason. Posts to /v1/order/order.,id/workflow/revision-request. Refreshes order after.
         */
        submitRevision() {
            this.loading = true
            if (!order.value) return
            axios
                .post('/v1/order/' + order.value.id + '/workflow/revision-request', {
                    note: this.note,
                })
                .then(() => {
                    showSnackbar('Revision Request Submitted Successfully!')
                    refreshOrder()
                    Object.assign(this.$data, reset())
                    const form = this.$refs.form as Vue & { reset: () => void }
                    form.reset()
                })
                .catch(() => {
                    this.loading = false
                    showError('Sorry! Something went wrong.')
                })
        },
    },
    setup() {
        if (!order.value) return
        const orderID = ref(order.value.id)
        const orderAdditionalProducts = ref(null)

        onMounted(() => {
            axios
                .get('/v1/settings/order_additional_products')
                .then((res) => {
                    if (res.data && res.data.result && user.value?.type === 'client') {
                        orderAdditionalProducts.value = res.data.result
                    }
                })
                .catch(() => {
                    orderAdditionalProducts.value = null
                })
        })
        return { orderID, order, directOrder, orderAdditionalProducts }
    },
})
