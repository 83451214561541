var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"650"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',{staticClass:"accentWhite",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center mx-8"},[_c('v-col',{staticClass:"my-4"},[_c('h2',[_vm._v("Coordinators")])])],1),_c('a-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('span',{staticClass:"grey--text"},[_vm._v("Company Coordinators:")])])],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('AmcUserSelect',{attrs:{"label":"Coordinators","name":"coordinators","chips":"","multiple":"","deletable-chips":"","disabled":true},model:{value:(_vm.displayAssigning),callback:function ($$v) {_vm.displayAssigning=$$v},expression:"displayAssigning"}})],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("Edit")])],1)],1)],1)],1)],1),_c('v-spacer',{staticClass:"ma-8"})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('a-card',{attrs:{"title":"Edit Coordinators"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('AmcUserSelect',{attrs:{"label":"Coordinators","name":"coordinators","chips":"","multiple":"","deletable-chips":""},model:{value:(_vm.assigning),callback:function ($$v) {_vm.assigning=$$v},expression:"assigning"}})],1)],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"btnText":"Save","url":"/v1/lender/" + _vm.lender.id + "/option","method":"POST","submitData":{
                    option_key: 'coordinators',
                    option_value: _vm.assigning.length ? _vm.assigning : [_vm.assigning],
                }},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.saveOption}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }