























import { defineComponent, ref } from '@vue/composition-api'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import axios from '@/plugins/axios'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'

const dialog = ref(false)

export const openCompleteOrderWithoutUploadActionDialog = () => {
    dialog.value = true
}

export default defineComponent({
    setup() {
        const submit = () => {
            if (!order.value) return
            confirm(
                'Complete Order',
                'Are you absolutely sure you want to mark this order as Completed? This action cannot be undone.',
            ).then((confirm) => {
                if (confirm) {
                    axios
                        .post('/v1/order/' + order.value?.id + '/action/complete-without-upload')
                        .then(() => {
                            refreshOrder()
                            showSnackbar('Order Completed Successfully!')
                            dialog.value = false
                        })
                        .catch(() => {
                            showError('Something went wrong')
                            dialog.value = false
                        })
                }
            })
        }
        return {
            submit,
            dialog,
        }
    },
})
