









































import { defineComponent, PropType, ref } from '@vue/composition-api'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { PreAuthorizationCardPaymentOption, LenderOptions } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref({ enabled: false } as PreAuthorizationCardPaymentOption)
        const numberOfDaysOptions = [1, 2, 3, 4, 5]

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as PreAuthorizationCardPaymentOption
        }

        const updateOption = () => {
            axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'pre-authorization-card-payment',
                    option_value: optionLocal.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            numberOfDaysOptions,
            optionLocal,
            updateOption,
        }
    },
})
