












































































































































































interface QualityReviewer {
    id: number
    lenderId: number
    userId: number
    type: string
    createdAt?: Date | string
    updatedAt?: Date | string
    pivot: {
        id: number
        type: string
    }
}

import { ValidationObserver } from 'vee-validate'

import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions, QualityReviewOption } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import AmcUserSelect from '@/components/General/AutoCompletes/AmcUserSelect.vue'

export default defineComponent({
    components: { AmcUserSelect, ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },

    setup(props) {
        const dialog = ref(false)
        const reviewerId = ref(0)
        const type = ref('Primary')
        const saving = ref(false)

        const optionShow = ref({
            type: 'Normal',
            offshore_review: false,
            send_files: false,
            reviewers: [],
        } as QualityReviewOption)

        const reviewers = ref([] as QualityReviewer[])

        if (props.option !== undefined) {
            optionShow.value = props.option.option_value as QualityReviewOption
        }

        const optionEdit = ref(JSON.parse(JSON.stringify(optionShow.value)))

        const handleChange = (reviewType: string) => {
            if (reviewType !== 'Normal') {
                optionEdit.value.offshore_review = false
            }
        }

        const save = () => {
            optionShow.value = JSON.parse(JSON.stringify(optionEdit.value))

            showSnackbar('Option saved successfully')
            dialog.value = false
        }

        const addReviewer = () => {
            if (reviewers.value.find((item) => item.id === reviewerId.value)) return
            saving.value = true

            $axios
                .post('/v1/lender/' + lender.value.id + '/reviewer', {
                    user: { id: reviewerId.value },
                    type: type.value,
                })
                .then((res) => {
                    showSnackbar('Reviewer added')
                    reviewers.value.push(res.data)
                    saving.value = false
                })
        }

        const removeReviewer = (reviewer: QualityReviewer) => {
            $axios.delete('/v1/reviewer/' + reviewer.pivot.id).then(() => {
                showSnackbar('Reviewer deleted')
                reviewers.value.splice(reviewers.value.indexOf(reviewer), 1)
            })
        }

        onMounted(() => {
            $axios.get('/v1/lender/' + lender.value.id + '/reviewer').then((res) => (reviewers.value = res.data))
        })

        return {
            saving,
            addReviewer,
            removeReviewer,
            type,
            dialog,
            save,
            lender,
            reviewerId,
            optionShow,
            optionEdit,
            reviewers,
            handleChange,
        }
    },
})
