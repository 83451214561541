











import { defineComponent, ref, watch } from '@vue/composition-api'
import { VueConstructor } from 'vue'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import SnippetHelper from '@/helpers/snippet-helper'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'

interface EditorProps {
    value?: string
    uuid: string
    toolbarItems: string[]
    placeholder: string
}

interface FroalaHTML {
    el: Element
    events: {
        on: (event: string, cb: (e: KeyboardEvent) => boolean, useCapture: boolean) => void | boolean
    }
}

interface FixedCombinedInstance extends InstanceType<VueConstructor> {
    _uid: string | number
}

export const froalaEditor = ref()
export default defineComponent({
    components: { ValidationProvider },
    props: {
        value: String,
        rules: {
            type: [Object, String],
            default: '',
        },
        uuid: {
            type: String,
            default: 'ascent-editor',
        },
        placeholder: {
            type: String,
            default: 'Start Typing',
        },
    },
    setup(props, { emit }) {
        const textValue = ref(props.value)
        const froalaValidator = ref()
        watch(textValue, () => {
            emit('input', textValue.value)
        })

        const reset = () => {
            textValue.value = ''
            froalaEditor.value.textValue = ''
        }

        return { textValue, froalaEditor, reset, froalaValidator }
    },
    data() {
        return {
            froalaConfig: {
                key: 'VCC6kE4A3F3J4C7C8D7cWHNGGDTCWHIg1Ee1Oc2Yc1b1Lg1POkB6B5F5F5F3E3B3F2B6B4==',
                serviceId: 'TJlkIrCyw57Xe9G',
                attribution: false,
                tooltips: false,
                pastePlain: true,
                placeholderText: this.placeholder,
                toolbarButtons: [
                    'bold',
                    'italic',
                    '-',
                    'formatOL',
                    'formatUL',
                    '-',
                    'insertLink',
                    'undo',
                    'redo',
                    'clearFormatting',
                ],
                pasteAllowedStyleProps: [
                    'border',
                    'border-bottom',
                    'border-left',
                    'border-radius',
                    'border-right',
                    'border-style',
                    'border-top',
                    'border-width',
                    'clear',
                    'display',
                    'float',
                    'padding',
                    'padding-bottom',
                    'padding-left',
                    'padding-right',
                    'padding-top',
                    'position',
                    'text-align',
                    'text-decoration',
                    'text-indent',
                    'top',
                    'vertical-align',
                    'visibility',
                    'white-space',
                ],
                toolbarInline: true,
                toolbarVisibleWithoutSelection: false,
                quickInsertEnabled: false,
                charCounterCount: false,
                events: {
                    initialized: function () {
                        SnippetHelper.init(order.value, order.value?.incremental, user.value).then((tribute) => {
                            if (!tribute) return

                            const editor = this as unknown as FroalaHTML
                            tribute.attach(editor.el)

                            editor.events.on('keydown', () => false, true)
                        })
                    },
                    blur: () => {
                        this.$emit('blur')
                    },
                },
            },
        }
    },
    watch: {
        value(val, oldVal) {
            if (val !== oldVal && !val) this.textValue = ''
            if (val !== oldVal && !val) {
                const validator = this.$refs.froalaValidator as InstanceType<typeof ValidationProvider>
                validator.reset()
            }
        },
    },
})
