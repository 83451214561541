import $axios from '@/plugins/axios'
import { order, refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { Condition } from '@/types'

const setConditions = (conditions: Condition[], notification: string) => {
    if (!order.value || !order.value.incremental) return
    order.value.incremental.conditions = conditions
        .sort((a, b) => {
            return a.verified === b.verified ? 0 : a.verified ? 1 : -1
        })
        .sort((a, b) => {
            return a.appraiser_complete === b.appraiser_complete ? 0 : a.appraiser_complete ? 1 : -1
        })
    showSnackbar('Condition ' + notification + ' successfully')
}

export const verifyCondition = (condition: Condition) => {
    $axios
        .patch('/v1/order/' + condition.order_id + '/condition/' + condition.id, { ...condition, verified: true })
        .then((response) => setConditions(response.data, 'Verified'))
}

export const failCondition = (condition: Condition) => {
    $axios
        .patch('/v1/order/' + condition.order_id + '/condition/' + condition.id, {
            ...condition,
            appraiser_complete: false,
        })
        .then((response) => setConditions(response.data, 'Failed'))
}

export const removeCondition = (id: number) => {
    confirm('Remove Condition?', 'Are you sure you want to remove this condition?').then((confirm) => {
        if (confirm && order.value) {
            $axios.delete('/v1/order/' + order.value.id + '/condition/' + id).then((response) => {
                setConditions(response.data, 'removed')
                refreshOrder()
            })
        }
    })
}
