<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on }" v-if="show">
            <v-list-item @click="open" v-on="on">
                <v-list-item-icon><v-icon>$vuetify.icons.creditCard</v-icon></v-list-item-icon>
                <v-list-item-title>Add New Card</v-list-item-title>
            </v-list-item>
        </template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <ACard title="Add Credit Card" id="body">
                <v-card-text v-if="showCreditCardEntry">
                    <credit-card
                        ref="credit"
                        :show="false"
                        :help="false"
                        @card-added="cardAdded"
                        @error="caughtError"
                        :gateway="$ascent.customerFeatureEnabled('gateway', 'nexio')"
                        :customer-id="order.customer_id"
                        :disallow="$ascent.customerFeatureEnabled('creditCard.disallow', [])"
                        :show-address="showAddressOnCreditCard"
                    />
                </v-card-text>
                <v-card-text v-if="!showCreditCardEntry">
                    <v-alert type="success" outlined>Payment gateway not set up, please contact support.</v-alert>
                </v-card-text>

                <v-card-actions v-if="showCreditCardEntry">
                    <v-btn @click="skipCard" color="secondary" :loading="loading">Add Card Later</v-btn>
                    <v-spacer />
                    <a-btn-cancel @click="cancel" />
                    <v-btn color="primary" depressed :loading="loading" @click="handleSubmit(submit)">Add Card</v-btn>
                </v-card-actions>
                <template
                    v-if="card != null && billing.length > 0 && billing.includes('company_card') && showCreditCardEntry"
                >
                    <v-divider />
                    <v-card-title>Or Use Company Card</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Card Ending in {{ card[0].card }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row>
                                        <v-btn
                                            outlined
                                            color="primary"
                                            @click="openChargeCard"
                                            class="d-inline-block mr-5"
                                            small
                                        >
                                            <v-icon small left>$vuetify.icons.cartShopping</v-icon>
                                            Charge Card
                                        </v-btn>
                                        <v-btn
                                            :loading="lenderCardSaving"
                                            outlined
                                            color="success"
                                            @click="lenderCard(card[0])"
                                            class="d-inline-block"
                                            small
                                        >
                                            <v-icon small left>$vuetify.icons.creditCard</v-icon>
                                            Use Card
                                        </v-btn>
                                    </v-row>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </template>
            </ACard>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import { openChargeCompanyCard } from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeCompanyCard.vue'
import OrderHelper from '@/helpers/OrderHelper'
import { ValidationObserver } from 'vee-validate'
import CreditCard from '@/components/General/CreditCard.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'

export default {
    components: {
        ValidationObserver,
        CreditCard,
    },
    setup() {
        return { order, openChargeCompanyCard }
    },
    props: {
        show: Boolean,
    },
    data() {
        return {
            dialog: false,
            card: null,
            billing: [],
            loading: false,
            lenderCardSaving: false,
        }
    },
    computed: {
        showCreditCardEntry() {
            return AscentHelper.customerFeatureEnabled('gateway', 'nexio') !== 'none'
        },
        showAddressOnCreditCard() {
            if (AscentHelper.customerFeatureEnabled('showCreditCardAddress')) return true
            return order.value?.incremental?.appraiser?.options?.find((option) => {
                return option.option_key === 'show-credit-card-address'
            })?.option_value
        },
    },
    methods: {
        open() {
            this.dialog = true
            this.$axios.get('/v1/lender/' + order.value.lender_id + '/option/lender-saved-card').then((response) => {
                this.card = response.data.option_value
            })
            this.$axios.get('/v1/lender/' + order.value.lender_id + '/option/billing').then((response) => {
                if (!response.data.option_value) return
                this.billing = response.data.option_value
            })
        },
        submit() {
            this.loading = true
            this.$refs.credit.addCard()
        },

        skipCard() {
            if (!order.value) return
            this.loading = true
            this.$axios
                .patch('/v1/order/' + order.value.id + '/action/update-billing', { billing_method: 'credit-card' })
                .then(() => {
                    refreshOrder()
                    showSnackbar('Billing Type Changed Successfully')
                    this.dialog = false
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },

        openChargeCard() {
            openChargeCompanyCard(OrderHelper.due.value)
            this.cancel()
        },

        cancel() {
            this.loading = false
            this.lenderCardSaving = false
            this.dialog = false
            this.$refs.credit.cancel()
        },

        cardAdded(details) {
            this.loading = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/card', {
                    token: details.token,
                    card: details.card,
                    type: details.type,
                    name: details.name,
                    expMonth: details.expMonth,
                    expYear: details.expYear,
                    address: details.address,
                })
                .then(() => {
                    refreshOrder()
                        .then(() => {
                            this.loading = false
                            this.dialog = false
                            this.lenderCardSaving = false
                            showSnackbar('Card Saved')
                        })
                        .catch(() => {
                            this.loading = false
                            this.dialog = false
                            this.lenderCardSaving = false
                            showSnackbar('Card Saved, please refresh your browser')
                        })
                })
        },

        caughtError() {
            this.loading = false
            this.lenderCardSaving = false
        },

        lenderCard(card) {
            this.lenderCardSaving = true
            this.cardAdded(card)
        },
    },
}
</script>
<style scoped>
#body {
    overflow: hidden;
}
</style>
