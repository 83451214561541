

















































import { defineComponent, computed, ComputedRef } from '@vue/composition-api'
import AppraiserFileComponent from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/Partials/AppraiserFileComponent.vue'
import AddAppraiserFileDialog from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/AddAppraiserFileDialog.vue'
import { File } from '@/types'
import { files } from '@/plugins/Files'
import OrderPdf from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/OrderPdf.vue'
import { order, isLinkReport } from '@/plugins/order/Order'
import RequestTDCX from '@/components/OrderScreens/Office/OrderComponents/OfficeFile/RequestTDCX.vue'
import { openDialog as openPreloadDialog } from '@/components/OrderScreens/Office/OrderComponents/Workflows/PreloadReviewDialog.vue'
import InvoiceFile from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/InvoiceFile.vue'

/**
 * @name AppraiserOrderFiles
 * Section in Appraiser Order Screen that shows all the files available to the appraiser to see.
 *
 * @SetupComputed filteredFiles - filters all files on the order screen to those with status of All or Appraiser
 */

export default defineComponent({
    components: { AppraiserFileComponent, AddAppraiserFileDialog, OrderPdf, RequestTDCX, InvoiceFile },
    setup() {
        const orderHasEngagementLetter = computed(() => {
            if (order.value && order.value.incremental?.customer_id === 2) return true
            if (!files.value) return false
            return files.value.findIndex((elem) => elem.type == 'Engagement Letter') > -1
        })

        const orderHasLockedOrderForm = computed(() => {
            if (!files.value) return false
            const activeFiles = files.value.filter((elem: File) => {
                return elem.status === 'All' || elem.status === 'Appraiser'
            })
            return activeFiles.findIndex((elem) => elem.type === 'Order Form PDF') > -1
        })

        const filteredFiles: ComputedRef<File[]> = computed(() => {
            return files.value.filter((elem: File) => {
                return elem.status === 'All' || elem.status === 'Appraiser'
            })
        })

        const downloadPDF = (url: string) => {
            window.location.href = url
        }

        const openLinkReport = () => {
            if (order.value && order.value.incremental?.customer_id === 2)
                window.open(`${process.env.VUE_APP_REPORT_EXTERNAL}?internal-order-id=${order.value.id}`, '_blank')
        }

        return {
            filteredFiles,
            files,
            order,
            orderHasEngagementLetter,
            openPreloadDialog,
            downloadPDF,
            orderHasLockedOrderForm,
            openLinkReport,
            isLinkReport,
        }
    },
})
