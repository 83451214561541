





































import { defineComponent, onMounted } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { open } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderReminders/AddReminderDialog.vue'
import ReminderActions from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderReminders/ReminderActions.vue'
import { Reminder } from '@/types'

interface Dialog {
    open: () => void
    close: () => void
}

export default defineComponent({
    components: {
        ReminderActions,
    },
    props: {
        color: {
            type: String,
            default: 'transparent',
        },
    },
    setup() {
        onMounted(() => {
            if (!order.value || !order.value?.incremental || !order.value?.incremental?.reminders) return
            order.value?.incremental?.reminders.sort((a: Reminder, b: Reminder) => {
                return new Date(a.reminder_date).getTime() - new Date(b.reminder_date).getTime()
            })
        })

        return {
            open,
            order,
        }
    },
})
