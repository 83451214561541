











import { defineComponent } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export const requestFile = () => {
    if (!order.value) return
    $axios.post('/v1/order/' + order.value.id + '/request-tdcx').then((response) => {
        showSnackbar(response.data.message ?? 'TDCX File Is Generating')
    })
}

export default defineComponent({
    name: 'RequestTDCX',
    setup() {
        return {
            requestFile,
        }
    },
})
