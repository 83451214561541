




































































































import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import { MenuItem } from '@/types'
import $axios from '@/plugins/axios'
import AscentHelper from '@/helpers/ascent-helper'
import OrderServiceProviderToolBar from '@/components/OrderScreens/Office/OrderComponents/ServiceProviders/OrderServiceProviderToolBar.vue'
import { openEditMilestone } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/EditMilestoneDialog.vue'
import { appraiserTagColors } from '@/pages/Appraiser/Profile/SharedProfile/AppraiserAmcSettingsPage.vue'
import { setImpersonateToken } from '@/helpers/token-helper'

export default defineComponent({
    components: { OrderServiceProviderToolBar },
    setup(_, { root }) {
        const actions = computed(() => {
            if (!order.value) return
            if (!user.value) return
            const actionItems: MenuItem[] = []
            if (order.value.appraiser_id !== null) {
                actionItems.push({
                    label: 'View Profile',
                    action: () => {
                        window.open('/appraiser/' + order.value?.appraiser_id + '/edit', '_blank')
                    },
                })
            }
            if (AscentHelper.canDo('Admin')) {
                actionItems.push({
                    label: 'Impersonate Appraiser',
                    action: () => {
                        if (!order.value) return
                        $axios
                            .post('/v1/ascent/impersonate/appraiser/' + order.value.appraiser_id, {
                                path: root.$route.path,
                            })
                            .then((response) => {
                                if (response.data.data.token) {
                                    setImpersonateToken(response.data.data.token)
                                }
                                window.location = response.data.data.path
                            })
                    },
                })
            }
            return actionItems
        })

        const appraiserStatuses = computed(() => {
            const statuses: { [key: string]: boolean } = {}

            const preScheduleStatuses = ['Evaluate', 'Allocate', 'Schedule', 'Waiting Inspection']

            if (!order.value) return
            statuses['Data Analyst'] = !(
                order.value.tags &&
                order.value.tags.findIndex(
                    (elem) => elem.tag == 'Preload Required' || elem.tag == 'Comp Pull Required',
                ) != -1
            )
            statuses['Inspector'] = preScheduleStatuses.findIndex((elem) => elem == order.value?.status) == -1
            statuses['Reviewer'] = order.value.status == 'Completed'
            if (order.value?.incremental?.order_appraisers?.find((el) => el.role === 'Preload'))
                statuses['Preload'] = true
            return statuses
        })

        const primaryAppraiser = computed(() =>
            order.value?.incremental?.order_appraisers?.find((el) => el.role === 'Primary'),
        )

        return {
            actions,
            order,
            openEditMilestone,
            user,
            appraiserStatuses,
            appraiserTagColors,
            primaryAppraiser,
        }
    },
})
