




























import { computed, defineComponent, ref } from '@vue/composition-api'
import CustomerEditMilestone, {
    openEditMilestone,
} from '@/components/OrderScreens/Customer/CustomerEditComponents/CustomerEditMilestone.vue'
import ChangeInspection from '@/components/OrderScreens/Customer/CustomerEditComponents/ChangeInspection.vue'
import { openModRequest } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/RequestModificationDialog.vue'
import { requiresDateModification } from '@/plugins/order/OrderModification'
import CustomerRequiresModRequest from '@/components/OrderScreens/Customer/CustomerEditComponents/CustomerRequiresModRequest.vue'

export default defineComponent({
    name: 'CustomerTimelineStep',
    components: { ChangeInspection, CustomerEditMilestone, CustomerRequiresModRequest },
    props: {
        complete: Boolean,
        value: Number,
        milestone: String,
    },
    setup(props) {
        const menu = ref(false)

        const dateFormat = computed(() => {
            if (props.milestone !== 'Completed') {
                return 'MM/dd/yyyy'
            }
            return 'MM/dd h:mm a'
        })

        const editType = () => {
            if (props.milestone === undefined || props.milestone === 'Completed' || props.complete) return
            if (props.milestone === 'Client Deadline' && requiresDateModification.value) {
                openModRequest()
                return
            }
            openEditMilestone(props.milestone, props.value as number, props.milestone !== 'Inspection')
        }

        return {
            menu,
            openEditMilestone,
            dateFormat,
            editType,
            requiresDateModification,
        }
    },
})
