











































































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions, RestrictionOption } from '@/types'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const settings = ref({
            eo_limit: '',
            eo_per_claim: '',
            experience: '',
            preferred_appraisers: 'Has No Preferred Appraisers',
        })

        if (props.option !== undefined) {
            settings.value = props.option.option_value as RestrictionOption
        }

        const settingsDisplay = ref(JSON.parse(JSON.stringify(settings.value)))

        const saveChanges = () => {
            showSnackbar('Restrictions saved successfully')
            settingsDisplay.value = settings.value
            dialog.value = false
        }

        return {
            dialog,
            saveChanges,
            settings,
            settingsDisplay,
            lender,
        }
    },
})
