

























import { defineComponent, ref } from '@vue/composition-api'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order } from '@/plugins/order/Order'
import { GET_PANELS } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { getAllQuotes } from '@/pages/AMC/Quotes/Quotes'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { sendQuotes as quotes } from '@/pages/AMC/Quotes/Quotes'
import $axios from '@/plugins/axios'
import AttachFileEmail from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AttachFileEmail.vue'

/**
 * @name SendQuoteAssignTable
 *Partial in the quotes table to send mass quotes to selected appraisers
 *
 * @SetupData appraiserNote - note to the appraiser
 * @SetupData addFiles - boolean to determine if files will be sent with quote
 * @SetupData quoting - loading boolean
 * @SetupData fileIds - file ids to be sent with the quote
 * @SetupData sendOrderPdf - boolean to determine if the order pdf will be sent with quote
 *
 * @SetupMethod filesUpdated - updates the fileIds
 * @SetupMethod orderPdfUpdated - updates the orderPdf boolean
 *
 * @SetupMethod sendQuotes - sends quotes to the appraisers that the user has selected. Updates order after quotes are sent
 */

export default defineComponent({
    components: { AttachFileEmail },
    setup() {
        const appraiserNote = ref('')
        const addFiles = ref(false)
        const quoting = ref(false)
        const fileIds = ref([] as number[])
        const sendOrderPdf = ref(false)

        const filesUpdated = (files: number[]) => {
            fileIds.value = files
        }

        const orderPdfUpdated = (orderPdf: boolean) => (sendOrderPdf.value = orderPdf)

        const sendQuotes = () => {
            confirm(
                'Send Quotes?',
                `Are you sure you want to send a quote request to
                        ${quotes.value.length} ${quotes.value.length === 1 ? 'appraiser?' : 'appraisers?'}`,
            ).then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    quoting.value = true
                    $axios
                        .post('/v1/order/' + order.value.id + '/action/quote-send', {
                            quotes: quotes.value,
                            note: appraiserNote.value,
                            'file-ids': fileIds.value,
                            'send-order-pdf': sendOrderPdf.value,
                        })
                        .then(() => {
                            if (!order.value) return
                            quoting.value = false
                            GET_PANELS({
                                type: 'order',
                                id: order.value.id as number,
                            })
                            getAllQuotes('order', order.value.id as number)
                            quotes.value = []
                            appraiserNote.value = ''
                            fileIds.value = []
                            sendOrderPdf.value = false
                            addFiles.value = false

                            showSnackbar('Quotes Sent Successfully')
                        })
                        .catch(() => {
                            showError('Oops! Something went wrong!')
                        })
                }
            })
        }

        return {
            appraiserNote,
            addFiles,
            sendQuotes,
            quoting,
            filesUpdated,
            orderPdfUpdated,
        }
    },
})
