















































































import { defineComponent, ref, PropType, computed } from '@vue/composition-api'
import { DefaultBillingMethodOption, Form } from '@/types'
import { ValidationObserver } from 'vee-validate'

export const specificProductDialog = ref(false)

export default defineComponent({
    emits: ['add', 'remove', 'save', 'cancel'],
    components: {
        ValidationObserver,
    },
    props: {
        optionLocal: {
            type: Object as PropType<DefaultBillingMethodOption>,
        },
        billingOptionItems: {
            type: Array as PropType<{ title: string; value: string }[]>,
        },
        mainProducts: {
            type: Array as PropType<Form[]>,
        },
    },
    setup(props) {
        const isUnique = computed(() => {
            const ids = props.optionLocal?.products.map((product) => product.id)
            const uniqueIds = [...new Set(ids)]
            return ids?.length === uniqueIds.length
        })

        return {
            specificProductDialog,
            isUnique,
        }
    },
})
