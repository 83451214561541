
































import { defineComponent } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'
import PreloadReviewDialog, {
    openDialog,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/PreloadReviewDialog.vue'

export default defineComponent({
    components: { PreloadReviewDialog },
    setup() {
        return {
            user,
            order,
            openDialog,
        }
    },
})
