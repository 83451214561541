












































import { defineComponent, ref, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import ScheduleIssue from '@/components/OrderScreens/Workflow/SubWorkflow/ScheduleIssue.vue'
import { order, updateOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import AscentHelper from '@/helpers/ascent-helper'

/**
 * @name ScheduleAction
 * Workflow step in the AMC Order Screen where the inspection is scheduled
 *
 * @SetupData date - date of inspection
 * @SetupData time - time of inspection
 * @SetupData showVirtualInspection - determines if is virtual inspection
 * @SetupData expanded - determines if component is expanded or not. Defaults to false
 *
 * @SetupComputed inspection_date - formats inspection date
 * @SetupMethod checkTime - takes in string. Moves the time 12 hours if time is before 8 AM
 * @SetupMethod scheduleAppraisal - schedules appraisal. Refreshes order.
 */

export default defineComponent({
    components: {
        ValidationObserver,
        ScheduleIssue,
    },
    setup() {
        const modal = ref(false)
        const date = ref('')
        const time = ref('')
        const loading = ref(false)
        const showVirtualInspection = ref(false)
        const expanded = AscentHelper.userOrCustomerFeatureEnabled('orderScreen.newOrderScreen', 'newOrderScreen', true)
            ? true
            : ref()

        const inspection_date = computed(() => {
            if (!date.value) return ''

            const [year, month, day] = date.value.split('-')
            return `${month}/${day}/${year}`
        })

        const checkTime = (data: string) => {
            const hour = parseInt(data)
            if (!time.value) time.value = data + ':00'
            const ampm = time.value.split(':')
            if (hour <= 8) {
                ampm[0] = (parseInt(ampm[0]) + 12).toString()
                time.value = `${ampm[0]}:${ampm[1]}`
            }
        }

        const scheduleAppraisal = (): void => {
            if (!order.value) return
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/schedule', {
                    appointment: date.value + ' ' + time.value,
                })
                .then(() => {
                    loading.value = false

                    updateOrder()

                    showSnackbar('Appointment Scheduled Successfully')
                })
                .catch(() => {
                    loading.value = false
                })
        }

        return {
            modal,
            date,
            time,
            loading,
            showVirtualInspection,
            expanded,
            inspection_date,
            scheduleAppraisal,
            checkTime,
        }
    },
    methods: {
        openReportSchedulingIssue() {
            const ReportSchedulingIssue = this.$refs.ReportSchedulingIssue as Vue & { open: () => void }
            ReportSchedulingIssue.open()
        },
    },
})
