import { GridModel, IFilter } from '@syncfusion/ej2-vue-grids'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import Vue, { VueConstructor } from 'vue'
import { DataStateChangeEventArgs } from '@syncfusion/ej2-vue-grids'

import $axios from '@/plugins/axios'
import { PredicateModel, Predicate, FilterSearchBeginEventArgs } from '@syncfusion/ej2-grids'
import { CancelTokenSource } from 'axios'
import { Appraiser, OrderCache, OrderSearchParams } from '@/types'
import { Aggregates } from '@syncfusion/ej2-data/src/util'

export interface CompFacet {
    key: string
    label: string
    type: 'range' | 'checkbox'
    min?: number
    max?: number
}

export interface DataResult {
    nodeType?: number
    addedRecords?: object[]
    d?: DataResult | object[]
    Count?: number
    count?: number
    result?: object | object[]
    results?: object[] | DataResult
    aggregate?: DataResult
    aggregates?: Aggregates
    value?: object
    Items?: object[] | DataResult
    keys?: string[]
    groupDs?: object[]
    hits?: object[]
    facetsDistribution?: {
        [x: string]: number | object
    }
    facets?: CompFacet[]
    estimatedTotalHits?: number
    subject?: object
    details?: object
    order?: OrderCache
    total?: number
    data?: object[]
    found?: number
}

export class AscentApi {
    public url = '/'
    public params = {}
    public searchVal = ''
    public cancelToken: undefined | CancelTokenSource = undefined

    public state: Omit<DataStateChangeEventArgs, 'action'> = {
        skip: 0,
        take: 50,
    }

    constructor(url: string) {
        this.url = url
    }

    public update(): Promise<DataResult | undefined> {
        if (this.cancelToken) {
            this.cancelToken.cancel('Cancelled')
        }

        this.cancelToken = $axios.CancelToken.source()
        return $axios
            .post(
                this.url,
                {
                    ...this.state,
                    ...this.params,
                    search: this.searchVal,
                },
                {
                    cancelToken: this.cancelToken.token,
                },
            )
            .then((result) => {
                if (result === undefined) {
                    return undefined
                }
                if (result && result.data) {
                    return result.data as DataResult
                }

                return {
                    count: 50000,
                    result: [],
                }
            })
            .catch(() => {
                return undefined
            })
    }

    public setParams(params: OrderSearchParams): AscentApi {
        this.params = params
        this.searchVal = params.search ?? ''
        return this
    }

    public execute(state: DataStateChangeEventArgs): Promise<DataResult | undefined> {
        this.setState(state)
        return this.update()
    }

    public search(query?: string) {
        if (query) {
            this.searchVal = query
            return this
        }
        this.searchVal = ''
        return this
    }

    private setState(state: DataStateChangeEventArgs): void {
        this.state = {
            skip: state.skip,
            take: state.take,
            sorted: state?.sorted,
        }
        if (state.where) this.state.where = state.where
    }
}

export interface ColumnTemplate {
    template: VueConstructor<Vue>
}

export interface AscentColumnModel
    extends Omit<ColumnModel, 'template' | 'valueAccessor' | 'filter' | 'allowFiltering' | 'field' | 'headerTemplate'> {
    field: string
    allowFiltering: boolean
    filter: IFilter
    hideExcel?: boolean
    colorFunction?: string
    color?: string
    dataTitle?: string
    template?: () => ColumnTemplate
    headerTemplate?: () => ColumnTemplate | string
    valueAccessor?: AscentValueAccessor
    user?: string[]
    method?: (appraiser: Appraiser) => Window | null
}

export declare type AscentValueAccessor = (
    field: string,
    data: { [x: string]: string | unknown },
    column?: AscentColumnModel,
) => string

export interface AscentGrid extends Omit<GridModel, 'detailTemplate'> {
    detailTemplate?: () => ColumnTemplate
}

interface Options {
    field: string
}

interface FilterModel {
    options: Options
    existingPredicate: {
        [key: string]: PredicateModel[]
    }
}

interface FixedFilter extends Omit<FilterSearchBeginEventArgs, 'filterModel'> {
    name: string
    columnName?: string
    direction?: string
    filterModel: FilterModel
    currentPage?: number
    currentFilteringColumn?: string
    currentFilterObject?: {
        value: string
    }
    target?: {
        innerText: string
    }
}

export interface FixedActionArgs extends Omit<DataStateChangeEventArgs, 'action' | 'where'> {
    action: FixedFilter
    name?: string
    dataSource: Function
    cancel: boolean
    where?: Predicate[] | undefined
}
