<template>
    <v-dialog v-model="dialog" width="500">
        <v-card style="background-color: #f5f5f5">
            <v-card-title primary-title>Upload New Report</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p class="small">Must upload a .xml file.</p>
                        <file-upload
                            v-if="dialog"
                            ref="fileUpload"
                            element="newReportFile"
                            :url="url"
                            :allowed="allowed"
                            @upload-success="saveFile"
                            @file-added="ready = true"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <a-btn-cancel @click="Object.assign(this.$data, reset())" />
                <a-btn-confirm :loading="loading" :disabled="!ready" @click="uploadFile">Upload</a-btn-confirm>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { getFiles } from '@/plugins/Files'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        dialog: false,
        loading: false,
        ready: false,
        allowed: ['.xml'],
    }
}

/**
 * @name UploadNewReportDialog
 * Dialog in order actions that allows user to upload a new appraisal
 */

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
    components: {},
    data() {
        return reset()
    },
    computed: {
        /**
         * Url of the order
         * @returns {string}
         */
        url() {
            return 'order/' + order.value.id
        },
    },
    methods: {
        open() {
            this.dialog = true
        },
        /**
         * Triggers file upload component to upload the file
         */
        uploadFile() {
            this.loading = true
            this.$refs.fileUpload.triggerUpload()
        },
        /**
         * Saves the file path and completes the complete workflow step
         * @param {File} file - file that was just uploaded
         */
        saveFile(file) {
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/complete', {
                    path: file.path,
                })
                .then(() => {
                    this.loading = false
                    this.dialog = false
                    getFiles(order.value.id)
                    showSnackbar('Report Uploaded Successfully')
                })
                .catch(() => {
                    showError('Oops! Something went wrong.')
                })
        },
    },
})
</script>
