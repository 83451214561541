































import { defineComponent, PropType } from '@vue/composition-api'
import { ProductFee, Product } from '@/types'

export interface FeeUpdateInput {
    val: string
    column: string
    item: Product
}

/**
 * @name OrderAddOnTable
 * Fee add on table component on the order screen
 *
 * @SetupData headers - headers on the table
 * @SetupMethod deleteFee - takes in product and emits product to parent component
 */

export default defineComponent({
    props: {
        products: {
            type: [] as PropType<Product[]>,
            default: () => [],
        },
        fees: {
            type: [] as PropType<ProductFee>,
            default: () => [],
        },
        error: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const headers = [
            { text: 'Product', value: 'form.name' },
            { text: 'Type', value: 'form.type' },
            { text: 'AMC Fee', value: 'amc_value' },
            { text: 'Appr Fee', value: 'appraiser_value' },
            { text: 'Client Fee', value: 'line_total' },
            { text: '', value: 'action', align: 'center' },
        ]

        const addAddOn = (item: Product) => {
            emit('add-on-added', item)
        }

        return {
            headers,
            addAddOn,
        }
    },
})
