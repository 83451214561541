










































import { defineComponent, ref } from '@vue/composition-api'
import $axios from 'axios'
import { lenderId } from '@/components/General/LenderOptions/LenderOptions'
import { Address } from '@/types'
import { uniq } from 'lodash'

export const auditLogDialog = ref(false)
export default defineComponent({
    name: 'LenderAddressAuditLog',
    setup() {
        const addressAudits = ref([] as Address[])

        const changedAttributes = (item: Address) => {
            const attributes = uniq([...Object.keys(item.old_values || {}), ...Object.keys(item.new_values || {})])
            const getValues = (values: { [name: string]: string } | unknown[] = {}) =>
                Array.isArray(values) ? {} : values
            return attributes.reduce(
                (acc: { oldValues: string[]; newValues: string[] }, attribute) => ({
                    oldValues: [...acc.oldValues, getValues(item.old_values)[attribute]],
                    newValues: [...acc.newValues, getValues(item.new_values)[attribute]],
                }),
                { oldValues: [], newValues: [] },
            )
        }

        const getAddressAudits = () => {
            if (!lenderId.value) return
            $axios.get('v1/lender/' + lenderId.value + '/address-changes').then(({ data }) => {
                if (!data) return

                addressAudits.value = data
            })
        }

        return { auditLogDialog, addressAudits, getAddressAudits, changedAttributes }
    },
})
