










import { defineComponent, PropType } from '@vue/composition-api'
import { Note } from '@/types'

export default defineComponent({
    name: 'CustomerDateNote',
    props: {
        note: Object as PropType<Note>,
    },
    setup() {
        return {}
    },
})
