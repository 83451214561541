











import FileDownload from 'js-file-download'
import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'

const downloading = ref(false)
const progress = ref(0)
export const download = (file_id: number | string, name: string) => {
    downloading.value = true
    $axios.get('/v1/file/' + file_id).then((response) => {
        $axios
            .get(response.data.url, {
                responseType: 'blob',
                onDownloadProgress: function (progressEvent) {
                    progress.value = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                },
            })
            .then(({ data }) => {
                FileDownload(data, name)
                downloading.value = false
                progress.value = 0
            })
    })
}

export const downloadCustom = (url: string, name: string) => {
    downloading.value = true
    $axios.get(url).then(({ data }) => {
        $axios
            .get(data.url, {
                responseType: 'blob',
                onDownloadProgress: function (progressEvent) {
                    progress.value = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
                },
            })
            .then((response) => {
                FileDownload(response.data, name)
                downloading.value = false
                progress.value = 0
            })
    })
}

export const downloadS3 = (url: string, name: string) => {
    downloading.value = true
    $axios
        .get(url, {
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                progress.value = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
            },
        })
        .then((response) => {
            FileDownload(response.data, name)
            downloading.value = false
            progress.value = 0
        })
}

export default defineComponent({
    setup() {
        return {
            progress,
            downloading,
        }
    },
})
