















































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import OrderToolbar from '@/AppLayout/order/OrderToolbar.vue'
import OrderInstructions from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/OrderInstructions.vue'
import AppraiserOrderDuplicates from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderDuplicates.vue'
import AppraiserOrderDetails from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderDetails.vue'
import AppraiserOrderContacts from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderContacts.vue'
import AppraiserOrderFiles from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderFiles.vue'
import AppraiserOrderNotes from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderNotes.vue'
import AddNewAppraiserNote from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/AddNewAppraiserNote.vue'
import AppraiserActionComponent from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserActionComponent.vue'
import OrderServiceProvider from '@/components/OrderScreens/Office/OrderComponents/ServiceProviders/OrderServiceProvider.vue'
import OrderStatusBar from '@/components/OrderScreens/Office/OrderComponents/OrderStatusBar/OrderStatusBar.vue'
import PreloadReviewDialog from '@/components/OrderScreens/Office/OrderComponents/Workflows/PreloadReviewDialog.vue'
import AppraiserOrderActions from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/AppraiserOrderActions.vue'
import AppraiserCommercialDetails from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserCommercialDetails.vue'
import AppraiserOrderEntities from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderEntities.vue'
import AscentHelper from '@/helpers/ascent-helper'
import {
    entities,
    getEntities,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderEntities/Entities'

/**
 * @name AppraiserOrderScreen
 * The order screen for the appraiser
 */

export default defineComponent({
    name: 'AppraiserOrderScreen',
    components: {
        OrderInstructions,
        OrderToolbar,
        AppraiserOrderContacts,
        AppraiserOrderDetails,
        AppraiserOrderEntities,
        AppraiserOrderFiles,
        AppraiserOrderNotes,
        AddNewAppraiserNote,
        AppraiserActionComponent,
        AppraiserOrderDuplicates,
        OrderServiceProvider,
        OrderStatusBar,
        PreloadReviewDialog,
        AppraiserOrderActions,
        AppraiserCommercialDetails,
    },
    setup() {
        const actionError = ref<string | null>(null)

        const appraiserDuplicates = computed(() => {
            if (!order.value?.incremental) return []
            if (!order.value.incremental?.duplicates) return []
            return order.value.incremental.duplicates.filter((elem) => {
                if (!elem.duplicate_cache || !user.value) return false
                if (elem?.duplicate_cache?.customer_id === user.value.customer_id) {
                    return (
                        AscentHelper.featureEnabled('appraiserAccessType') != false &&
                        AscentHelper.featureEnabled('appraiserAccessType') == 'full'
                    )
                }

                return elem?.duplicate_cache?.appraiser_id === user.value.appraiser?.id
            })
        })

        const getActionComponent = computed(() => {
            if (!order.value) return
            switch (order.value.status) {
                default:
                    return ''
            }
        })

        const openEditCard = () => {
            if (!order.value?.appraiser_id) return
            if (!user.value?.billable) return
            window.open('/appraiser/' + order.value?.appraiser_id + '/payments', '_self')
        }

        const showActionError = (error: string) => {
            actionError.value = error
        }

        onMounted(() => {
            getEntities()
        })

        return {
            actionError,
            showActionError,
            order,
            openEditCard,
            getActionComponent,
            user,
            appraiserDuplicates,
            entities,
        }
    },
})
