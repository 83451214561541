var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.orderHeaders,"items":_vm.tableData,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.col1))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.col2))])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-3",attrs:{"type":"error","outlined":""}},[(
                            _vm.$ascent.customerFeatureEnabled('assignType') === 'vendor' &&
                            _vm.tableOrder.appraiser_id > 0 &&
                            _vm.orderConfiguration.vendorFeeTable
                        )?[_vm._v(" Please set vendor fee schedule to view fees. ")]:_vm._e(),(
                            _vm.$ascent.customerFeatureEnabled('assignType') === 'vendor' &&
                            _vm.tableOrder.appraiser_id === 0 &&
                            _vm.orderConfiguration.vendorFeeTable
                        )?[_vm._v(" Please get vendor allocation to view fees. ")]:_vm._e(),(
                            _vm.$ascent.customerFeatureEnabled('assignType') !== 'vendor' ||
                            !_vm.orderConfiguration.vendorFeeTable
                        )?[_vm._v(" No fee schedule set. ")]:_vm._e()],2)]},proxy:true},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[(_vm.feeButton && !_vm.corpCCApprovalFeatureEnabled)?_c('v-btn',{attrs:{"depressed":"","variant":"tonal","density":"compact","color":"secondary","disabled":_vm.readOnly},on:{"click":function($event){return _vm.setTotalFromType(_vm.feeButton)}}},[_vm._v(" Use "+_vm._s(_vm.feeButton)+" ")]):_vm._e()],1),_c('td',{staticClass:"text-right",attrs:{"colspan":_vm.orderHeaders.length}},[(_vm.total != null)?_c('strong',[_vm._v(" "+_vm._s(_vm.useDisclosedFee ? 'Disclosed Fee' : _vm.$ascent.customerFeatureEnabled('lang.order_form_default_fee', 'Total Fee'))+": $"+_vm._s(_vm.total)+" ")]):_vm._e(),(_vm.total == null)?_c('strong',[_vm._v("Quoted")]):_vm._e()])])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }