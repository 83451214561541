


























import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions } from '@/types'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: {},
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup() {
        const optionLocal = ref(false)

        onMounted(() => {
            axios.get(`/v1/lender/${lender.value.id}/option/trainee-appraiser`).then((response) => {
                if (response && response.data) optionLocal.value = response.data.option_value
            })
        })
        const updateOption = (event: Event) => {
            axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'trainee-appraiser',
                    option_value: event,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            optionLocal,
            updateOption,
        }
    },
})
