





































import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { user } from '@/plugins/User'

export default defineComponent({
    name: 'ClientDirectives',
    data() {
        return {
            tabs: null,
            clientDirectives: undefined,
            branchDirectives: undefined,
            user,
        }
    },
    mounted() {
        if (!order.value) return
        this.$axios.get('/v1/lender/' + order.value.lender_id + '/option/directives').then((response) => {
            this.clientDirectives = response.data
        })
        $axios.get('/v1/branch/' + order.value?.branch_id + '/option/directive').then((response) => {
            this.branchDirectives = response.data
        })
    },
})
