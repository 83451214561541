<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on }" v-if="show">
            <v-list-item v-on="on" @click="getCard">
                <v-list-item-icon><v-icon>$vuetify.icons.cartShopping</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Charge Company Card</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <v-card>
                <v-card-title primary-title>
                    Charge Card
                    <v-spacer />
                    <v-btn icon color="primary" @click="close">
                        <v-icon>$vuetify.icons.times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="error == null" wrap>
                        <v-col cols="12">
                            <h3>
                                ${{ order.incremental.total.due }}
                                <span class="small">Total Due</span>
                            </h3>
                            <p>Choose which card and amount to charge.</p>
                            <a-select-field
                                v-model="card"
                                :items="cards"
                                item-text="card"
                                return-object
                                label="Credit Card Last Four"
                                name="credit card"
                                outlined
                                rules="required"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                v-model="amount"
                                name="amount"
                                label="Amount"
                                :rules="'required|max_value:' + total"
                                prepend-icon="$vuetify.icons.dollarSign"
                                autofocus
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="error != null">
                        <v-col cols="12">
                            <v-alert text dense :type="errorType" icon="$vuetify.icons.exclamation-triangle">
                                {{ error }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" depressed :loading="charging" @click="handleSubmit(chargeCard)">
                        Charge Card
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script lang="js">



import { defineComponent, ref } from "@vue/composition-api"
import {ValidationObserver} from 'vee-validate'
import {showSnackbar} from '@/AppLayout/helpers/snackbar.vue'
import {refreshOrder, order} from "@/plugins/order/Order";
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'


const dialog = ref(false)
const charging = ref(false)
const card = ref(null)
const cards = ref([])
const error = ref(null)
const amount = ref(null)
const email = ref('')
const errorType = ref('error')

function reset() {
    dialog.value = false
    charging.value = false
    card.value = null
    cards.value = []
    error.value = null
    amount.value = null
    email.value = ''
    errorType.value = 'error'
}

export const openChargeCompanyCard = (due) => {
    dialog.value = true
    cards.value = lenderOptions.value['lender-saved-card']
    if(cards.value.length === 1) {
        card.value = cards.value[0]
    }
    amount.value = due
}



export default defineComponent({
    components: {
        ValidationObserver
    },
    props: {
        id: Number,
        due: Number,
        show: Boolean,
        total: Number
    },
    setup() {
        return {
            dialog,
            charging,
            card,
            cards,
            error,
            amount,
            email,
            errorType,
            order,
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.getCard()
        },
        getCard() {
            this.cards = lenderOptions.value['lender-saved-card']
            if(this.cards.length === 1) {
                this.card = this.cards[0]
            }
            this.amount = this.due
        },
        close() {
            reset()
        },
        chargeCard() {
            this.charging = true

            const timer = setTimeout(() => {
                this.errorType = 'info'
                this.error = 'We haven\'t received a response back from the payment processor yet. Please refresh and try again if necessary.'
                this.charging = false
            }, 20000)

            this.$axios.post('/v1/order/' + this.id + '/payment/charge-company-card', {amount: this.amount, card: this.card}).then((response) => {
                if (response.data.result) {
                    clearTimeout(timer)
                    showSnackbar('Card Charged Successfully for ' + this.amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }))
                    refreshOrder().then(() => {
                        reset()
                    }).catch(() => {
                        this.charging = false
                        this.errorType = 'info'
                        this.error = 'The charge was successful, please refresh your browser.'
                    })

                    this.$emit('charged')
                } else {
                    clearTimeout(timer)
                    this.error = response.data.message
                    this.charging = false
                }
            }).catch(() => {
                clearTimeout(timer)
                this.charging = false
            })
        }
    },
})
</script>
