



















import { defineComponent, PropType } from '@vue/composition-api'
import { openAssignPanelNotesDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignPanelNotesDialog.vue'
import ExpandedAppraiserDetails from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/ExpandedAppraiserDetails.vue'
import ExpandedAppraiserStatusDetails from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/ExpandedAppraiserStatusDetails.vue'
import ExpandedAssignAppraiser from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/ExpandedAssignAppraiser.vue'
import AppraiserPanelNotes from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/AppraiserPanelNotes.vue'
import ExpandedAssignNotes from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/ExpandedAssignNotes.vue'
import { headers } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/AssignTable.vue'

import { AppraiserPanel } from '@/types'

/**
 * @name ExpandedTablePartial
 * Partial in AssignTable that has the expanded information about the appraiser
 */

export default defineComponent({
    components: {
        ExpandedAppraiserDetails,
        ExpandedAppraiserStatusDetails,
        ExpandedAssignAppraiser,
        AppraiserPanelNotes,
        ExpandedAssignNotes,
    },
    props: {
        /**
         * Specific appraiser in AppraiserPanel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
        /**
         * Details of the appraiser
         */
        details: {
            type: Object,
        },
    },
    setup() {
        return {
            openAssignPanelNotesDialog,
            headers,
        }
    },
})
