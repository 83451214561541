

























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { AppraiserNotes } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

export default defineComponent({
    setup() {
        const canEdit = computed(() => AscentHelper.canDo('Edit Appraiser'))

        return {
            canEdit,
        }
    },
    props: {
        note: Object as PropType<AppraiserNotes>,
        default: () => {
            return {}
        },
    },
    data() {
        return {}
    },
    methods: {
        deleteNote(): void {
            confirm(
                'Delete Appraiser Note?',
                'Are you sure you want to delete this appraiser note? It cannot be undone',
            ).then((confirm) => {
                if (confirm) {
                    if (!this.note) return
                    this.$axios.delete('/v1/audit/' + this.note.id).then(() => {
                        showSnackbar('Note Deleted Successfully')
                        this.$emit('note-deleted')
                    })
                }
            })
        },
    },
    computed: {
        getClass(): string | undefined {
            if (!this.note) return
            if (this.note.type === 'note') {
                return 'c-note__amc'
            } else if (this.note.type === 'audit') {
                return 'c-note__audit'
            } else {
                return 'c-note'
            }
        },
        tag(): string | undefined {
            if (!this.note) return
            switch (this.note.type) {
                case 'Reference':
                    return 'primary'
                case 'Critical':
                    return 'error'
                case 'Document':
                    return 'secondary'
                case 'Payment':
                    return 'success'
                default:
                    return 'accentBlacklighten2'
            }
        },
    },
})
