import { RouteConfig } from 'vue-router'
const clientDashboardPage = () => import('@/pages/Client/ClientDashboard/ClientDashboardPage.vue')

const routes: Array<RouteConfig> = [
    {
        path: '/client/dashboard',
        name: 'dashboard',
        component: clientDashboardPage,
        meta: { requiresAuth: true, userType: ['client'], title: 'Dashboard' },
    },
    {
        path: '/client/order',
        name: 'order',
        component: () => import('@/pages/Client/OrderFormPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['client'],
            title: 'New Order',
            notViewOnly: true,
        },
    },
    {
        path: '/client/order/confirm/:id',
        name: 'confirm',
        component: () => import('@/pages/Client/ConfirmOrderPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['client', 'amc', 'desk', 'office'],
            title: 'Order Confirmation',
        },
    },
    {
        path: '/client/encompass/orders',
        name: 'encompassOrders',
        component: () => import('@/pages/Client/Encompass/EncompassLinkedOrdersPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['client'],
            title: 'Encompass Orders',
        },
    },
    {
        path: '/client/encompass/:user/:type/:token',
        name: 'encompassLogin',
        component: () => import('@/pages/Client/Encompass/EncompassLoginPage.vue'),
        meta: {
            requiresAuth: false,
            userType: ['client'],
            title: 'Encompass Login',
        },
    },
    {
        path: '/client/encompass/:id',
        name: 'encompassOrder',
        component: () => import('@/pages/Client/Encompass/EncompassOrderFormPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['client'],
            title: 'Encompass Order',
        },
    },
    {
        path: '/client/guild/:id',
        name: 'guildOrder',
        component: () => import('@/pages/Client/Guild/GuildOrderFormPage.vue'),
        meta: { requiresAuth: true, userType: ['client'], title: 'New Order' },
    },
    {
        path: '/client/order/:id',
        name: 'additionalorder',
        component: () => import('@/pages/Client/OrderFormPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['client', 'amc', 'desk'],
            title: 'Additional Order',
        },
    },
    {
        path: '/client/fee-estimator',
        name: 'client-fee-estimator',
        component: () => import('@/pages/Client/ClientFeeEstimatorPage.vue'),
        meta: { requiresAuth: true, userType: ['client'], title: 'Fee Estimator' },
    },
    {
        path: '/client/users',
        name: 'client-users',
        component: () => import('@/pages/Client/ClientUsers/ClientUsersPage.vue'),
        meta: { requiresAuth: true, userType: ['client'], title: 'Users' },
    },
    {
        path: '/client/:id/profile',
        name: 'profile',
        component: () => import('@/pages/Client/ClientProfile/ClientProfilePage.vue'),
        meta: { requiresAuth: true, userType: ['client'], title: 'Profile' },
    },
    {
        path: '/client/order-search',
        name: 'Client Order Search',
        component: () => import('@/pages/AMC/Settings/OrderSearch.vue'),
        meta: { requiresAuth: true, userType: ['client'] },
    },
    {
        path: '/client/alt-products',
        name: 'Snapshot Review Product',
        component: () => import('@/pages/Client/ClientReviewSnapshot/ClientAltProducts.vue'),
        meta: { requiresAuth: true, userType: ['client'], permissions: ['Order Snapshot Review'] },
    },
]

export default routes
