






































import { defineComponent, onMounted } from '@vue/composition-api'
import { Note } from '@/types'
import $ascent from '@/helpers/ascent-helper'
import ClientAddNewNote from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientAddNewNote.vue'
import { filteredNotes } from '@/plugins/Notes'

/**
 * @name ClientOrderNotes
 * Order notes on the Client Order Screen. Allows client to see notes and post notes to the AMC
 *
 * @SetupMethod messageColumn - Takes in note and outputs what column it needs to show up in
 * @SetupMethod messageClass - Returns css class depending on source of note
 * @SetupMethod getNoteName - Takes in note, returns name formatted of who sent the note.
 */

export default defineComponent({
    components: { ClientAddNewNote },
    setup() {
        const messageColumn = (note: Note): number => {
            if (note.source == 'AMC') return 1
            if (note.source == 'Client') return 2
            return 0
        }
        const messageClass = (note: Note): string => {
            let returnClass = ''
            if (note.source) {
                if (note.source === 'Client') returnClass = 'message__client'
                if (note.source === 'AMC') returnClass = 'message__amc'
            }
            return returnClass
        }
        const getNoteName = (note: Note): string => {
            if (note.source && note.from) return note.from + ' (' + $ascent.formatIsoDate(note.created_at, 'f') + ')'
            return $ascent.formatIsoDate(note.created_at, 'f')
        }
        const scrollToBottom = () => {
            const vDialog = document.querySelector('.messages')
            if (typeof vDialog !== 'undefined') {
                vDialog?.scrollTo({
                    left: 0,
                    top: vDialog.scrollHeight,
                    behavior: 'smooth',
                })
            }
        }

        onMounted(() => scrollToBottom())
        return {
            scrollToBottom,
            messageColumn,
            messageClass,
            getNoteName,
            filteredNotes,
        }
    },
})
