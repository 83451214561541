


















import { defineComponent } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserSettingsId, appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    components: {},
    props: {
        option: {
            type: Object,
        },
    },

    setup(props) {
        if (props.option && props.option.tags) {
            appraiserOptions.value.billing = props.option.billing
        }

        const saveChanges = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'billing',
                    option_value: appraiserOptions.value.billing,
                })
                .then(() => {
                    showSnackbar('Saved successfully')
                })
        }

        return {
            saveChanges,
            lender,
            appraiserOptions,
        }
    },
})
