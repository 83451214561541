




























import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import OrderReminders from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/OrderReminders.vue'

export default defineComponent({
    name: 'CustomerOrderReminder',
    components: {
        OrderReminders,
    },

    setup() {
        const menu = ref(false)

        return {
            order,
            menu,
        }
    },
})
