var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":{
        lng: parseFloat(_vm.order.address.longitude),
        lat: parseFloat(_vm.order.address.latitude),
    },"zoom":16,"options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUI: false,
        mapTypeId: 'hybrid',
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
            } ],
    }}},[_c('GmapCustomMarker',{attrs:{"marker":{
            lng: _vm.order.address.longitude,
            lat: _vm.order.address.latitude,
        }}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("$vuetify.icons.house")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }