















import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

/**
 * @name OrderDetailsBorrowers
 * Section in Order Details that shows all the borrowers that are on the order to user
 */

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
})
