














































import { defineComponent, PropType, ref } from '@vue/composition-api'
import Vue from 'vue'
import { appraiserPanelNotes } from '@/plugins/Notes'
import PanelMap from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/PanelMap.vue'
import QuoteEnter from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/QuoteEnter.vue'
import { order } from '@/plugins/order/Order'
import { AppraiserPanel, AppraiserPanelDetails } from '@/types'
import OfficeExpandTablePartial from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeExpandTablePartial.vue'
import ExpandPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandPartial.vue'
import OfficeAssignTablePrepend, {
    search,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/AssignTablePartials/OfficeAssignTablePrepend.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export const headers = ref([
    { text: 'Name', value: 'appraiser.name', width: '14%' },
    { text: 'Turn Time', value: 'turn_time', width: '10%' },
    { text: 'Distance', value: 'distance', width: '8%' },
    { text: '', value: 'expand', align: 'end', width: '3%' },
])

export default defineComponent({
    setup() {
        const expanded = ref([] as AppraiserPanel[])
        const details = ref({} as { [appraiserId: number]: AppraiserPanelDetails })

        return {
            order,
            expanded,
            headers,
            search,
            details,
        }
    },
    components: {
        ExpandPartial,
        PanelMap,
        QuoteEnter,
        OfficeAssignTablePrepend,
        OfficeExpandTablePartial,
    },
    props: {
        items: {
            type: [] as PropType<AppraiserPanel[]>,
        },
    },
    data() {
        return {
            hasExpanded: false as boolean,
            error: false,
        }
    },
    methods: {
        async expand(item: AppraiserPanel) {
            if (!item || !item.appraiser) return

            await this.$axios.get('/v1/panel/appraiser/' + item.appraiser.id).then((response) => {
                if (!response.data) {
                    showSnackbar('Something went wrong!')
                    return
                }
                if (!item || !item.appraiser) return
                if (appraiserPanelNotes.value)
                    response.data.notes = appraiserPanelNotes.value.filter((elem) => {
                        if (!item || !item.appraiser || !elem.appraiser_id) return false
                        return (
                            elem.appraiser_id === item?.appraiser.id &&
                            (elem.target === 'Assign Phone' ||
                                elem.target === 'Assign Email' ||
                                (elem.source == 'Prior Appraiser' && elem.target == 'AMC') ||
                                (elem.source === 'AMC' && elem.target === 'Prior Appraiser'))
                        )
                    })
                Vue.set(this.details, item?.appraiser?.id as number, response.data)
                if (!item) return
            })

            await this.$axios.get(`/v1/appraiser/${item.appraiser.id}/orders`).then((response) => {
                this.details[item.appraiser.id].appraiserOrders = response.data.orders
                this.details[item.appraiser.id].allOtherOrders = response.data.totalCount
            })

            this.expanded.push(item)
        },
        collapse(item: AppraiserPanel) {
            this.expanded.splice(
                this.expanded.findIndex((elem) => elem.index == item.index),
                1,
            )
        },
    },
})
