import {
    changeAvailableColumns,
    filterByUser,
    getCustomerColumnLabels,
    updateVisibleColumns,
    useSavedColumns,
} from './SetColumnMethods'
import { name } from '@/components/OrderTable/Classes/OrderTable'

const setAvailableColumns = (availableColumns: string[] = [], visibleColumns: string[] = []) => {
    getCustomerColumnLabels()

    const savedValue = localStorage.getItem(name.value)
    if (savedValue) useSavedColumns(savedValue)
    if (availableColumns.length > 0) changeAvailableColumns(availableColumns)
    if (visibleColumns.length > 0) updateVisibleColumns(visibleColumns)

    filterByUser()
}

export default setAvailableColumns
