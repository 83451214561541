<template>
    <v-container pa-0 fluid dense>
        <v-row>
            <v-col cols="8">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle v-html="'Property Address:'" />
                            <v-list-item-title style="white-space: pre-wrap">
                                {{ order.address.full_address_formatted }}
                                <span class="pr-4">({{ order.county }} County)</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle v-html="'Product:'" />
                            <v-list-item-title style="white-space: pre-wrap">
                                {{ order.productList }} / {{ order.loanPurpose }}
                                <span v-if="order.purchasePrice && order.loanPurpose !== 'Refinance'" class="small">
                                    - {{ $ascent.currency(order.purchasePrice) }}
                                </span>
                                <span class="small">- {{ order.residence_type }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle v-html="'Inspection Date:'" />
                            <v-list-item-title v-if="order.inspection != null" style="white-space: pre-wrap">
                                <strong>{{ $ascent.formatUnix(order.inspection, 'EEE, MMM d, t') }}</strong>
                            </v-list-item-title>
                            <v-list-item-title v-if="order.inspection == null"><strong>N/A</strong></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <AppraiserSecondInspection />
                </v-list>
            </v-col>
            <v-col class="d-flex flex-column" align="center">
                <h3 class="text-center">Due Date:</h3>
                <v-card class="mx-auto" width="80">
                    <v-img class="white--text red darken-2 align-center" height="30px">
                        <v-card-title style="display: grid; place-items: center">
                            {{ $ascent.formatUnix(order.appraiserDeadline, 'LLL', false).toUpperCase() }}
                        </v-card-title>
                    </v-img>

                    <v-card-text class="align-center text-center text--primary py-2 mb-0">
                        <span style="font-size: 30px">
                            {{ $ascent.formatUnix(order.appraiserDeadline, 'd', false) }}
                        </span>
                    </v-card-text>
                </v-card>
                <v-card class="mt-4 mx-auto" width="80" v-if="order.appraiserAccess.indexOf(user.appraiser.id) > -1">
                    <v-img class="white--text primary align-center justify-center" height="30px">
                        <v-card-title style="display: grid; place-items: center">Fee:</v-card-title>
                    </v-img>
                    <v-card-text class="align-center text-center text--primary py-3">
                        <h3>${{ appraiserFee }}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AppraiserSecondInspection from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/Partials/AppraiserSecondInspection'
import { order, appraiserFee } from '@/plugins/order/Order.ts'
import { user } from '@/plugins/User'

/**
 * @name AppraiserOrderHeaderMobile
 * Header for mobile order screen for the appraiser
 */

export default {
    components: { AppraiserSecondInspection },
    setup() {
        return { order, appraiserFee, user }
    },
}
</script>
