




















import { defineComponent, PropType, ref } from '@vue/composition-api'
import { LenderOptions } from '@/types'

export default defineComponent({
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref(false)

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as boolean
        }

        return {
            optionLocal,
        }
    },
})
