




















import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { getValidJaroPanels } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'

export default defineComponent({
    setup() {
        const dialog = ref(false)
        const agree = async () => {
            if (!order.value) return
            await getValidJaroPanels({
                order_id: order.value.id,
                customer_id: order.value.customer_id,
                override_closed_panel: true,
            })
            dialog.value = false
        }
        return {
            dialog,
            agree,
        }
    },
})
