





















































import { defineComponent, watch, ref, onMounted } from '@vue/composition-api'
import BranchInfo from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchInfo/BranchInfo.vue'
import BranchConfig from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchConfig/BranchConfig.vue'
import BranchUsers from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchUsers/BranchUsers.vue'
import BranchDirectives from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchDirectives/BranchDirectives.vue'
import BranchAssignmentPanelDirectives from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchAssignmentPanelDirectives/BranchAssignmentPanelDirectives.vue'
import BorrowerDelivery from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/BorrowerDelivery.vue'
import $axios from '@/plugins/axios'
import { Branch, LenderOptions } from '@/types'
import BranchEncompass from './BranchEncompass.vue'
import { currentCustomer } from '@/plugins/User'

export const branch = ref<Branch | undefined>(undefined)
export const branchId = ref(0)
export const branchName = ref('')
export const dialog = ref(false)
export const encompassFolders = ref()

export default defineComponent({
    components: {
        BranchConfig,
        BranchUsers,
        BranchDirectives,
        BranchInfo,
        BranchAssignmentPanelDirectives,
        BranchEncompass,
        BorrowerDelivery,
    },
    setup() {
        const navItems = ref([
            { label: 'Branch Information', type: 'branch-info' },
            { label: 'Configuration', type: 'branch-config' },
            { label: 'Directives', type: 'branch-directives' },
            { label: 'Users', type: 'branch-users' },
            { label: 'Assignment/Panel Directives', type: 'branch-assignment-panel-directives' },
            { label: 'Quality/Delivery', type: 'borrower-delivery' },
        ])
        const lenderMercury = ref({} as LenderOptions)

        const getLenderMercury = (id: number) => {
            $axios.get('/v1/lender/' + id + '/option/mercury').then((response) => {
                if (response.data) {
                    lenderMercury.value = response.data
                }
            })
        }

        onMounted(() => {
            if (currentCustomer.value.type == 'office') {
                navItems.value = [{ label: 'Branch Information', type: 'branch-info' }]
            }
        })

        const currentTab = ref({})
        const loading = ref(true)

        const getLenderBranch = (id: number) => {
            $axios.get('/v1/lender/' + id + '/option/encompass').then((response) => {
                if (response.data) {
                    encompassFolders.value = response.data.option_value
                    navItems.value.push({ label: 'Encompass Folders', type: 'branch-encompass' })
                }
            })
        }

        const getBranch = () => {
            if (!branchId.value) return
            $axios.get('/v1/branch/' + branchId.value).then(({ data }) => {
                branch.value = {
                    ...data,
                    address: (data && data.address) || {
                        street: '',
                        sub_street: '',
                        city: '',
                        state: '',
                        zip: '',
                    },
                }

                if (data.lender_id) {
                    getLenderBranch(data.lender_id)
                    getLenderMercury(data.lender_id)
                }
                loading.value = false
            })
        }

        const closeDialog = () => {
            loading.value = true
            dialog.value = false
        }

        watch(branchId, getBranch)

        watch(dialog, () => {
            if (dialog.value) return
            branchId.value = 0
            currentTab.value = 0
        })

        return {
            navItems,
            currentTab,
            dialog,
            branch,
            loading,
            closeDialog,
            branchName,
            lenderMercury,
        }
    },
})
