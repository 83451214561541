




































































































































































































































































import { defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import AddPreQuoteDialog from '@/pages/AMC/Quotes/Partials/AddPreQuoteDialog.vue'
import ViewPreQuoteDialog, { openPreQuoteNote } from '@/pages/AMC/Quotes/Partials/ViewPreQuoteDialog.vue'
import { Quote, QuoteNotes } from '@/types'
import { AscentGrid, FixedActionArgs } from '@/helpers/AscentDataAdapter'
import { GridComponent } from '@syncfusion/ej2-vue-grids'
import PreQuoteIdColumn from './PreQuoteIdColumn.vue'
import PreQuoteNotesColumn from './PreQuoteNotesColumn.vue'
import { DateTime } from 'luxon'
import QuoteOrderIdColumn from './QuoteOrderIdColumn.vue'
import ComplexityScoreColumn from './ComplexityScoreColumn.vue'
import PropertyComplexity from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/PropertyComplexity.vue'
import QuoteSearch from '@/pages/AMC/Quotes/Partials/QuoteSearch.vue'
import { type, dates, updateQuoteTable, searchText, zipSearch, QuoteQuery, searchType } from '@/pages/AMC/Quotes/Quotes'
import { filteredColumns, name, viewUser } from '@/components/OrderTable/Classes/OrderTable'
import {
    ActionEventArgs,
    ColumnChooser,
    ColumnMenu,
    ContextMenu,
    DataStateChangeEventArgs,
    ExcelExport,
    Filter,
    ForeignKey,
    Freeze,
    Page,
    Reorder,
    Resize,
    DetailRow,
    Sort,
} from '@syncfusion/ej2-grids'
import { getTableUsers } from '@/components/OrderTable/Classes/TableFilters'
import { getColumnFilters } from '@/components/OrderTable/Classes/OrderFilters'
import AppraisersQuote from '@/pages/AMC/Quotes/Partials/AppraisersQuote.vue'
import AppraiserZipCodeSearch from '@/pages/Shared/CustomerSettings/AppraiserZipCodeSearch/AppraiserZipCodeSearch.vue'

interface PreQuoteResponse {
    result: Quote[]
}

export const tableSettings = ref({
    dataSource: {} as PreQuoteResponse,
    detailDataBound: (args) => {
        if (args && args.detailElement) {
            // eslint-disable-next-line
            ;(args.detailElement as HTMLTableCellElement).colSpan = 12
        }
    },
    isResponsive: true,
    allowFiltering: false,
    allowReordering: true,
    allowSorting: true,
    allowSelection: true,
    allowMultiSorting: true,
    allowEditing: false,
    showColumnChooser: true,
    pageSettings: { pageSizes: [25, 50, 75, 100], pageSize: 50 },
    allowPaging: true,
    allowResizing: true,
    allowTextWrap: true,
    allowExcelExport: true,
} as AscentGrid)

export const PreQuote = ref({} as GridComponent)

/**
 * @name PreQuote
 * Component Description: PreQuote shows all quotes in a table.
 * Users can select the status and the created after date to filter quotes.
 * Users can also add a pre quote and export data.
 * @SetupData type - status of quote
 * @SetupData dates - date used for created after
 * @SetupMethod exportExcel - exports to excel
 * @SetupMethod updateQuotes - updates quotes with new status and date selected.
 */
export default defineComponent({
    components: { AppraiserZipCodeSearch, AddPreQuoteDialog, ViewPreQuoteDialog, PropertyComplexity, QuoteSearch },
    props: {
        /**
         * Array of all quotes specified.
         */
        quotes: {
            type: Array as PropType<Quote[]>,
        },
    },
    setup() {
        name.value = 'Quote Table'
        const actionHandling = async (args: FixedActionArgs) => {
            if (!args) return

            if (
                (args && args.action && args.action.requestType === 'filterchoicerequest') ||
                args.action.requestType === 'filtersearchbegin'
            ) {
                const filters = await getColumnFilters(args.action.filterModel.options.field, args.action.query)
                args.dataSource(filters)
                return
            }
            QuoteQuery.setParams({
                filter: type.value,
                viewUser: viewUser.value.length ? viewUser.value : [-1],
                searchType: searchType.value,
                created_after: dates.value,
                sorted: args.sorted,
            })
                .search(searchText.value)
                .execute(args as DataStateChangeEventArgs)
                .then((data) => {
                    if (data === undefined) return
                    tableSettings.value.dataSource = data
                })
        }
        const modal = ref(false)

        onMounted(() => {
            getTableUsers('Quotes').then(() => {
                updateQuoteTable()
            })
        })

        const actionBegin = async (args: ActionEventArgs) => {
            if (args && args.columns && args?.action === 'filter') {
                filteredColumns.value = []
                args.columns.forEach((elem) => filteredColumns.value.push(elem.field as string))
            }
        }

        const exportExcel = () => {
            if (!tableSettings.value.dataSource) return
            const exportData = ((tableSettings.value.dataSource as PreQuoteResponse).result as Quote[]).map(
                (elem: Quote) => {
                    if (elem.quote_notes && elem.quote_notes.length > 0) {
                        elem.quote_notes = (elem.quote_notes as QuoteNotes[])[0].note
                    }
                    if (elem.order_cache && elem.order_cache.order.assigned) {
                        elem.order_cache.order.assigned = new Date(elem.order_cache.order.assigned)
                    }
                    return elem
                },
            )

            PreQuote.value.ej2Instances.excelExport({
                dataSource: exportData,
                fileName: 'PreQuoteExport.xlsx',
            })
        }

        const columnId = () => {
            return { template: PreQuoteIdColumn }
        }

        const OrderIdColumn = () => {
            return { template: QuoteOrderIdColumn }
        }

        const quoteNotes = () => {
            return { template: PreQuoteNotesColumn }
        }

        const complexityScore = () => {
            return { template: ComplexityScoreColumn }
        }

        watch(dates, () => (dates.value === '' ? updateQuoteTable() : ''))

        return {
            openPreQuoteNote,
            tableSettings,
            exportExcel,
            formatOptions: { type: 'date', format: 'MM/dd/yyyy' },
            shipFormat: { type: 'dateTime', format: 'dd/MM/yyyy hh:mm a' },
            PreQuote,
            columnId,
            quoteNotes,
            dates,
            DateTime,
            modal,
            OrderIdColumn,
            type,
            complexityScore,
            updateQuoteTable,
            searchText,
            zipSearch,
            actionHandling,
            actionBegin,
        }
    },
    data() {
        return {
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Requester Name', value: 'client_contact_name' },
                { text: 'Form', value: 'product_list' },
                { text: 'Address', value: 'address' },
                { text: 'Loan Number', value: 'loan_number' },
                { text: 'Quoted Fee', value: 'quoted_fee' },
                { text: 'Quoted ETA', value: 'quoted_business_days' },
                { text: 'Company', value: 'client' },
            ],
        }
    },
    methods: {
        /**
         * Gets all quotes with newly added quote
         * @emits quote-added
         */
        getQuotes() {
            this.$emit('quote-added')
        },
        getDetailTemplate() {
            return {
                template: AppraisersQuote,
            }
        },
    },
    provide: {
        grid: [
            Filter,
            Sort,
            Page,
            Resize,
            ExcelExport,
            ForeignKey,
            ColumnMenu,
            Reorder,
            ColumnChooser,
            ContextMenu,
            Freeze,
            DetailRow,
        ],
    },
})
