















































































































































import { noteTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import { ValidationObserver } from 'vee-validate'
import Vue from 'vue'
import { defineComponent, ref } from '@vue/composition-api'
import { resetNote, addNote, scrollToBottom, newNote } from '@/plugins/Notes'
import { order } from '@/plugins/order/Order'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { AppraisalPortOption, CarbonCopyConfiguration } from '@/types'
import { user } from '@/plugins/User'

interface UserList {
    name: string
    email: string
}

const customCCTemplates = ref([])

export function reset() {
    return {
        search: null as string | null,
        lastNoteDate: null,
        filterPanelOpen: false,
        amcUsers: [] as UserList[],
        ccEmails: false,
    }
}

export const loading = ref(false)
export default defineComponent({
    components: {
        ValidationObserver,
    },
    name: 'AmcAddNote',
    setup() {
        return {
            noteTags,
            newNote,
            order,
            loading,
            customCCTemplates,
        }
    },
    data() {
        return reset()
    },
    mounted() {
        if (!user.value) return
        this.$axios
            .get('/v1/settings/carbon-copy-configuration')
            .then((response) => {
                if (!response || !response.data) return
                customCCTemplates.value = response.data.result.map((el: CarbonCopyConfiguration) => {
                    return { name: el.title, email: el.email }
                })
            })
            .catch(() => {
                customCCTemplates.value = []
            })
        this.$axios.get('/v1/customer/user').then((response) => {
            this.amcUsers = [...customCCTemplates.value, ...response.data]
            this.amcUsers = this.amcUsers.filter((elem) => {
                return elem.name !== 'Staff Appraisers'
            })
        })
        if (this.order && this.order.source === 'appraisalport') this.newNote.extra.subject = 'Other'
    },
    computed: {
        subjectLines() {
            if (
                lenderOptions.value &&
                lenderOptions.value.appraisalport &&
                (lenderOptions.value.appraisalport as AppraisalPortOption).lenderSubjectLines
            )
                return (lenderOptions.value.appraisalport as AppraisalPortOption).lenderSubjectLines
            return ['Other']
        },
    },
    methods: {
        ccUpdate(val: string) {
            this.search = val
        },
        // @vuese
        addNote() {
            if (!order.value) return
            loading.value = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/note', this.newNote)
                .then(({ data }) => {
                    showSnackbar('Note Added Successfully!')
                    this.search = null
                    loading.value = false
                    this.lastNoteDate = null
                    this.filterPanelOpen = false
                    resetNote()
                    this.ccEmails = false
                    addNote(data)
                    const form = this.$refs.form as Vue & { reset: () => void }
                    form.reset()
                    const editor = this.$refs.editor as Vue & { reset: () => void }
                    editor.reset()
                    this.$emit('note-added', data)
                    Vue.nextTick(() => {
                        scrollToBottom()
                    })
                })
                .catch(() => {
                    loading.value = false
                })
        },
        checkFocus() {
            if (this.newNote.read === true) {
                const editor = this.$refs.editor as Vue & { focus: () => void }
                editor.focus()
                scrollToBottom()
            }
        },
        updateResults() {
            this.newNote.cc.forEach((contact, i, arr) => {
                if (typeof contact === 'object') {
                    arr[i] = contact.email as string
                }
            })
            this.search = null
        },
    },
})
