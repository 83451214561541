




















import { defineComponent, PropType } from '@vue/composition-api'
import { LenderSearchResults } from '@/types'

export default defineComponent({
    props: {
        lenderLabel: {
            type: String,
            default: 'Lender',
        },
        returnObject: {
            type: Boolean,
            default: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String,
            default: 'Active',
        },
        customLenders: {
            type: Array as PropType<LenderSearchResults[]>,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            items: [] as LenderSearchResults[],
        }
    },
    watch: {
        search(val) {
            val && val !== this.select && this.searchLender(val)
        },
    },
    mounted() {
        this.searchLender('')
    },
    methods: {
        searchLender(search: string) {
            this.loading = true
            if (this.customLenders.length > 0) {
                this.items = this.sort(this.customLenders)
                this.loading = false
                return
            }
            this.$axios
                .post('/v1/search', {
                    val: search,
                    type: 'lender',
                    object: true,
                    limit: 250,
                    where: this.status,
                })
                .then(({ data }) => {
                    this.items = this.sort(data)
                    this.loading = false
                })
        },
        sort(data: LenderSearchResults[]) {
            return data.sort((a: { name: string }, b: { name: string }) => {
                return a.name < b.name || a.name == null ? -1 : 1
            })
        },
    },
})
