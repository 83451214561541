










import Vue from 'vue'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
/**
 * @name 'A Icon'
 * Component description: A reusable component where we grab and display the font-awesome-icon
 */
export default Vue.extend({
    components: {
        FontAwesomeIcon,
        FontAwesomeLayers,
        FontAwesomeLayersText,
    },
    props: {
        /**
         * The icon being displayed
         */
        icon: {
            type: Array,
            default: null,
        },
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {}
    },
    methods: {},
})
