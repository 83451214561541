var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"tile":"","flat":"","color":"white","title":"Files"}},[(!_vm.files.length)?_c('v-card-text',[_c('v-alert',{attrs:{"type":"info","text":"","color":"info"}},[_vm._v("No files uploaded.")])],1):_vm._e(),(_vm.files.length && !_vm.filesReviewed)?_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-list-item',{staticClass:"my-2"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-black",domProps:{"innerHTML":_vm._s('Reviewing ' + (_vm.fileIndex + 1) + '/' + _vm.files.length + ' files:')}}),(_vm.order.incremental.loan_purpose === 'Purchase')?_c('v-list-item-subtitle',[_c('strong',[_vm._v("Purchase Price:")]),_vm._v(" "+_vm._s(_vm.$ascent.currency(_vm.order.incremental.purchase_price))+" ")]):_vm._e(),(_vm.order.borrowers)?_c('v-list-item-subtitle',[_c('strong',[_vm._v("Borrowers:")]),_vm._v(" "+_vm._s(_vm.order.borrowers)+" "),(
                                    _vm.$ascent.userOrCustomerFeatureEnabled(
                                        'orderScreen.newOrderScreen',
                                        'newOrderScreen',
                                        true
                                    ) &&
                                    !_vm.snapshotOrder &&
                                    !['appraiser', 'client'].includes(_vm.user.type)
                                )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openContacts()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.edit")])],1):_vm._e()],1):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.fileIndex)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getFile(-1)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$vuetify.icons.angleLeft")]),_vm._v(" Previous File ")],1):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.filePreviewed},on:{"click":function($event){return _vm.getFile(1)}}},[_vm._v(" Save "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("$vuetify.icons.angleRight")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('EditFile',{attrs:{"for-dialog":false},on:{"file-deleted":_vm.getFileOnDelete}}),(_vm.order && _vm.order.incremental)?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","target":"_blank"},on:{"click":_vm.openFile}},[_vm._v(" Open In New Tab ")]):_vm._e(),(!_vm.isPdf)?_c('v-alert',{staticClass:"my-4",attrs:{"type":"info","text":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.openFile}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.filePreviewed ? 'Download Again' : 'Download File')}})])]},proxy:true}],null,false,1619132104)},[_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.filePreviewed
                                ? 'File Downloaded'
                                : 'No file preview available, please open file to review:'
                        )}})]):_vm._e()],1),(_vm.isPdf)?_c('v-col',[(!_vm.loading)?_c('iframe',{attrs:{"src":_vm.fileUrl,"width":"100%","height":"1000"}}):_vm._e()]):_vm._e()],1)],1):_vm._e(),(_vm.filesReviewed && _vm.files.length)?_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"10"}},[_c('h2',[_vm._v("Reviewed Files")]),(_vm.files.length === 0)?_c('v-alert',{attrs:{"type":"info","text":"","color":"info"}},[_vm._v("No files uploaded.")]):_vm._e(),_vm._l((_vm.files),function(file){return _c('v-list-item',{key:file.id},[_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticClass:"no-underline",attrs:{"target":"_blank"},domProps:{"innerHTML":_vm._s(file.type)},on:{"click":function($event){return _vm.openFileFromList(file)}}})]),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.$ascent.formatIsoDate(file.created_at, 'EEE, MMM d, t'))}})],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"px-2",attrs:{"align-self":"center"}},[_c('v-chip',{attrs:{"small":"","label":"","dark":"","color":_vm.getColor(file.status)},domProps:{"innerHTML":_vm._s(file.status)}})],1),(_vm.$ascent.canDo('Work'))?_c('v-col',{staticClass:"px-2"},[_c('v-btn',{attrs:{"icon":"","color":"secondary","target":"_blank"},on:{"click":function($event){return _vm.editFile(file)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.edit")])],1)],1):_vm._e()],1)],1)],1)})],2),_c('v-spacer')],1)],1):_vm._e(),(_vm.filesReviewed && _vm.files.length)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"my-2",attrs:{"text":""},on:{"click":function($event){_vm.newFileDialog = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$vuetify.icons.plus")]),_vm._v(" Add File ")],1),_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary"},on:{"click":function () {
                    _vm.filesReviewed = false
                    _vm.fileIndex = 0
                    _vm.getFile(0)
                }}},[_vm._v(" Review Files Again ")])],1):_vm._e(),_c('EditFileDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }