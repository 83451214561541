var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('a-text-field',{class:_vm.advancedSearch ? '' : 'ascent-search-category',attrs:{"placeholder":_vm.placeholder,"solo":"","hide-details":"","background-color":_vm.searchBackgroundColor,"clearable":"","clear-icon":"$vuetify.icons.timesCircle","id":"search-box","loading":_vm.loading,"loader-height":"4"},on:{"input":_vm.updateSearchTable,"focus":function($event){_vm.placeholder = _vm.advancedSearch ? 'Search orders...' : 'Press / again to change search type'},"blur":function($event){_vm.placeholder = ' Press / to focus'}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}},[(_vm.searchDropDown !== false)?_c('template',{slot:"prepend-inner"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 rounded-sm",attrs:{"color":"accentBlack","dark":"","x-large":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.searchType)+" "),_c('v-icon',{attrs:{"right":"","x-small":"","dark":""}},[_vm._v("$vuetify.icons.chevronDown")])],1)]}}],null,false,1025452135)},[_c('v-list',{attrs:{"dense":""},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}},_vm._l((_vm.searchItems),function(searchItem){return _c('v-list-item',{key:searchItem,on:{"click":function($event){_vm.searchType = searchItem}}},[_c('v-list-item-title',[_vm._v(_vm._s(searchItem))])],1)}),1)],1)],1):_vm._e(),(_vm.searchDropDown !== false)?_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-sm-inline-block",attrs:{"icon":"","color":"accentBlack"},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.sliders")])],1)]}}],null,false,3299947736)},[_c('span',[_vm._v("Select Filter")])]),_c('v-btn',{staticClass:"d-none d-sm-inline-block",attrs:{"icon":"","color":"accentBlack"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openColumnChooser.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.columns")])],1),_c('v-menu',{attrs:{"bottom":"","offset-y":"","rounded":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-sm-inline-block",attrs:{"icon":"","color":"accentBlack"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.folderTree")])],1)]}}],null,true)},[_c('span',[_vm._v("Recent Orders")])])]}}],null,false,457004912)},[_c('v-list',_vm._l((_vm.recentLocalOrders),function(order,index){return _c('v-list-item',{key:index,staticClass:"ml-2",on:{"click":function($event){return _vm.openRecentOrder(order.id)}}},[(order.id)?_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.view")])],1):_vm._e(),(order.id)?_c('v-list-item-content',{staticClass:"text-xs-right"},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(order.addressFormatted)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(order.lenderName)+" ")])],1):_vm._e()],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","offset-y":"","rounded":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-sm-inline-block",attrs:{"icon":"","color":"accentBlack"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$vuetify.icons.ellipsis")])],1)]}}],null,false,3952568675)},[_c('v-list',[(_vm.$ascent.customerFeatureEnabled('orderTable.allowExport', false))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportExcel.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("$vuetify.icons.fileExcel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Export")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveTable.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("$vuetify.icons.save")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Save Table Layout")])],1)],1),(
                            _vm.user &&
                            _vm.user.type === 'office' &&
                            _vm.$ascent.canDo('Office Manager/Appraiser') &&
                            _vm.user.appraiser
                        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeUserType('appraiser')}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("$vuetify.icons.users")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Appraiser View")])],1)],1):_vm._e()],1)],1)],1):_vm._e()],2),(_vm.orderFilter === 'Escalations' || _vm.orderFilter === 'Escalations Lead')?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"info","outlined":""}},[_vm._v(" Showing Escalation Orders ")]):_vm._e(),_c('TableUserSelectDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }