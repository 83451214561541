













import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { getFiles } from '@/plugins/Files'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { downloadS3 } from '@/components/General/DownloadFile.vue'

export default defineComponent({
    name: 'GeneratePdcoFile',
    setup() {
        const loading = ref(false)
        const requestPDCO = () => {
            loading.value = true
            $axios.get('/v1/kit/order/' + order.value?.id + '/generatePDCO').then(({ data }) => {
                if (order.value) {
                    getFiles(order.value.id)
                }
                showSnackbar('File Generated Successfully')
                downloadS3(data.path, order.value?.customer_order_id + '_pdc.pdco')
                loading.value = false
            })
        }
        return {
            requestPDCO,
            loading,
        }
    },
})
