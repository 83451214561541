import EscalationTag from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/TablePartials/EscalationTag.vue'
import TagAssignees from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/TablePartials/TagAssignees.vue'
import TagOrderStatuses from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/TablePartials/TagOrderStatuses.vue'
import EscalationTagEdit from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/TablePartials/EscalationTagEdit.vue'
import EscalationTagDelete from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/TablePartials/EscalationTagDelete.vue'
import TagDescriptionColumn from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/TablePartials/TagDescriptionColumn.vue'
import { user } from '@/plugins/User'

import { ref } from '@vue/composition-api'
import { AscentGrid } from '@/helpers/AscentDataAdapter'
import $axios from '@/plugins/axios'
import { User } from '@/types'

const EscalationTagColumn = () => {
    return {
        template: EscalationTag,
    }
}

const TagAssigneesColumn = () => {
    return {
        template: TagAssignees,
    }
}

const TagOrderStatusColumn = () => {
    return {
        template: TagOrderStatuses,
    }
}

const TagEditColumn = () => {
    return {
        template: EscalationTagEdit,
    }
}

const TagDeleteColumn = () => {
    return {
        template: EscalationTagDelete,
    }
}

const TagDescription = () => {
    return {
        template: TagDescriptionColumn,
    }
}

export const tableSettings = ref({
    dataSource: {},
    isResponsive: true,
    allowFiltering: false,
    allowReordering: true,
    allowSorting: true,
    allowSelection: true,
    allowMultiSorting: true,
    allowEditing: false,
    showColumnChooser: true,
    pageSettings: { pageSizes: [25, 50, 75, 100], pageSize: 50 },
    allowPaging: true,
    allowResizing: true,
    allowTextWrap: true,
    allowExcelExport: true,
} as AscentGrid)

export const columns = [
    {
        field: 'name',
        allowFiltering: false,
        headerText: 'Tag',
        textAlign: 'Left',
        template: EscalationTagColumn,
        width: '150px',
    },
    {
        field: 'description',
        allowFiltering: false,
        headerText: 'Description',
        textAlign: 'Left',
        width: '150px',
        template: TagDescription,
    },
    {
        field: 'assignees',
        allowFiltering: false,
        headerText: 'Assignees',
        textAlign: 'Left',
        template: TagAssigneesColumn,
    },
    {
        field: 'approval',
        allowFiltering: false,
        type: 'boolean',
        headerText: 'Approval Required',
        textAlign: 'Left',
        displayAsCheckBox: true,
    },
    {
        field: 'order_statuses',
        allowFiltering: false,
        headerText: 'Order Statuses',
        textAlign: 'Left',
        template: TagOrderStatusColumn,
    },
    {
        field: 'edit',
        allowFiltering: false,
        headerText: 'Edit',
        textAlign: 'Left',
        width: '7%',
        template: TagEditColumn,
    },
    {
        field: 'delete',
        allowFiltering: false,
        headerText: 'Delete',
        textAlign: 'Left',
        width: '8%',
        template: TagDeleteColumn,
    },
]

export const getEscalationTags = () => {
    return new Promise((resolve) => {
        $axios.get('/v1/escalation').then((res) => {
            tableSettings.value.dataSource = res.data
            resolve(res.data)
        })
    })
}

export const availableAssignees = ref([])
export const customerUsers = ref([] as User[])
export const statuses = ref([])

export const getTagData = () => {
    $axios.get('/v1/customer/user').then((res) => {
        availableAssignees.value = res.data.filter((user: User) => {
            if (!user.permissions || !user.permissions.length) return false
            return user.permissions.find((elem) => elem === 'Handles Escalation')
        })
        customerUsers.value = res.data
    })
    if (!user.value) return
    $axios.get(`/v1/order-statuses`).then((response) => {
        statuses.value = response.data
    })
}
