

































































import { defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
import freddiemac from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/freddiemac.vue'
import axios from '@/plugins/axios'
import { lender, sublender } from '@/components/General/LenderOptions/LenderOptions'
import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

const options = ref({})
const integrations = ref(['freddiemac'] as string[])
const loaded = ref(false)

export const getTasks = () => {
    if (!sublender.value) {
        return
    }

    axios.get(`/v1/sublender/${sublender.value.id}/get-integration`).then((res) => {
        options.value = res.data.options

        integrations.value = integrations.value.filter(
            (integration) => !Object.keys(options.value).includes(integration),
        )

        options.value = { ...options.value, ...res.data.static_options }
        loaded.value = true
    })
}

export default defineComponent({
    components: {
        LenderOnboardingStatus,
        ValidationObserver,
        freddiemac,
    },
    setup() {
        const dialog = ref(false)
        const integrationSelect = ref<string>('')

        const save = () => {
            integrations.value.splice(integrations.value.indexOf(integrationSelect.value), 1)
            options.value = { ...options.value, [integrationSelect.value]: undefined }
            dialog.value = false
            showSnackbar('Integration added successfully')
        }

        onMounted(getTasks)

        onUnmounted(() => {
            options.value = {}
            integrations.value = ['freddiemac']
        })

        return {
            save,
            integrationSelect,
            dialog,
            integrations,
            options,
            loaded,
            lender,
            sublender,
        }
    },
})
