




import { defineComponent } from '@vue/composition-api'
import CustomerOrderAddEntity from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderAddEntity.vue'
import CustomerOrderAddFile from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderAddFile.vue'
import CustomerOrderAddContact from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderAddContact.vue'
import AddProductDialog from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/AddProductDialog.vue'
import { addFee } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerEditOrderInvoice.vue'
import EditCommercial from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/EditCommercial.vue'

export default defineComponent({
    name: 'DetailAddComponent',
    methods: { addFee },
    props: {
        detailItem: String,
    },
    components: {
        CustomerOrderAddEntity,
        CustomerOrderAddFile,
        CustomerOrderAddContact,
        AddProductDialog,
        EditCommercial,
    },
})
