































































import { defineComponent } from '@vue/composition-api'

/**
 * @name zillowSvg
 * SVG for zillow
 */

export default defineComponent({})
