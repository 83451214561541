






















































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { open } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderInvoiceDialog.vue'
import { order } from '@/plugins/order/Order'
import AddClientPaymentDialog from '@/components/OrderScreens/Amc/AMCActions/Payment/AddClientPaymentDialog.vue'
import OfficeFormsAndFeesDialog, {
    openFormsAndFees,
} from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/Partials/OfficeFormsAndFeesDialog.vue'
import { role } from '@/plugins/User'
import { user } from '@/plugins/User'
import OfficeExpensesDetails from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/Partials/OfficeExpensesDetails.vue'
import OfficeRevenueDetails from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/Partials/OfficeRevenueDetails.vue'
import ClientOrderPayments from '@/components/OrderScreens/Amc/AMCActions/Payment/ClientOrderPayments.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import SendBorrowerPayLinkDialog from '@/components/OrderScreens/Amc/AMCActions/Payment/SendBorrowerPayLinkDialog.vue'
import AuditNotes from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AuditNotes.vue'
import AppraiserPayments from '@/components/OrderScreens/Amc/AMCActions/Payment/AppraiserPayments.vue'

interface Dialog {
    open: () => void
}

/**
 * @name OfficeOrderFees
 * Sidebar in the office order screen to show total fees, expenses of appraisers, and margin.
 *
 * @SetupComputed grossExpenses - total of all the Order Appraisers fees that they will make on the order
 * @SetupComputed grossMarginDollar - margin in dollar amounts
 * @SetupComputed grossMarginPercentage - margin in percentage
 */

export const netRevenue = computed(() => {
    if (!order.value || !order.value.incremental) return 0

    const innovationFee = orderProducts.value.find((elem) => elem.product_name === 'Innovation Fee')
    if (innovationFee)
        return parseFloat(order.value?.incremental?.total?.total as string) + (innovationFee.amc_value as number)

    return order.value?.incremental?.total?.total
})

export default defineComponent({
    name: 'OfficeOrderFees',
    components: {
        OfficeFormsAndFeesDialog,
        OfficeExpensesDetails,
        OfficeRevenueDetails,
        ClientOrderPayments,
        AddClientPaymentDialog,
        SendBorrowerPayLinkDialog,
        AuditNotes,
        AppraiserPayments,
    },
    setup() {
        const grossExpenses = computed(() => {
            if (
                !order.value ||
                !order.value.incremental ||
                !order.value.incremental.total ||
                !order.value?.incremental.total.appraiser_value
            )
                return 0

            const innovationFee = orderProducts.value.find((elem) => elem.product_name === 'Innovation Fee')

            if (innovationFee) {
                return (
                    parseFloat(order.value?.incremental?.total?.appraiser_value as string) -
                    (innovationFee.appraiser_value as number)
                )
            } else {
                return order.value?.incremental?.total.appraiser_value
            }
        })

        const grossMarginDollar = computed(() => {
            if (!order.value || !order.value.incremental || !order.value.incremental.total) return 0
            return (netRevenue.value as number) - (grossExpenses.value as number)
        })

        const grossMarginPercentage = computed(() => {
            if (
                !order.value ||
                !order.value.incremental ||
                !order.value.incremental.total ||
                !order.value.incremental.total.amc_value
            )
                return 0
            return Math.floor(
                ((parseFloat(order.value?.incremental?.total?.total as string) - (grossExpenses.value as number)) /
                    (netRevenue.value as number)) *
                    100,
            )
        })

        const AddClientPaymentDialog = ref({} as Dialog)
        const SendBorrowerPayLinkDialog = ref({} as Dialog)

        const openDialog = (type: string) => {
            switch (type) {
                case 'client-payment':
                    AddClientPaymentDialog.value.open()
                    break
                case 'payment-link':
                    SendBorrowerPayLinkDialog.value.open()
                    break
            }
        }

        return {
            role,
            user,
            order,
            open,
            openFormsAndFees,
            grossMarginDollar,
            grossMarginPercentage,
            grossExpenses,
            openDialog,
            AddClientPaymentDialog,
            SendBorrowerPayLinkDialog,
            netRevenue,
        }
    },
})
