



































































import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { LenderOptions, User } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup() {
        const dialog = ref(false)
        const optionShow = ref({ id: 0, name: '' })
        const salesReps = ref([] as User[])
        const optionEdit = ref({ id: 0, name: '' })

        onMounted(() => {
            $axios
                .get('/v1/customer/user', {
                    params: { role: 'Sales' },
                })
                .then((res) => (salesReps.value = res.data))

            $axios.get('/v1/lender/' + lender.value.id + '/option/sales-rep').then((res) => {
                if (res.data !== undefined && res.data.option_value !== undefined) {
                    optionShow.value = res.data.option_value
                    optionEdit.value = res.data.option_value
                }
            })
        })

        const save = () => {
            optionShow.value = JSON.parse(JSON.stringify(optionEdit.value))
            dialog.value = false
            showSnackbar('Option saved successfully')
        }

        return {
            optionShow,
            optionEdit,
            save,
            dialog,
            lender,
            salesReps,
        }
    },
})
