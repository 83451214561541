






















import { defineComponent, ref } from '@vue/composition-api'
import CustomerOrderToolbar from '@/components/OrderScreens/Customer/CustomerLayout/CustomerOrderToolbar.vue'
import CustomerOrderTags from '@/components/OrderScreens/Customer/CustomerLayout/CustomerOrderTags.vue'
import CustomerOrderDialogs from '@/components/OrderScreens/Customer/CustomerLayout/CustomerOrderDialogs.vue'
import CustomerStatusBar from '@/components/OrderScreens/Customer/CustomerLayout/CustomerStatusBar.vue'
import CustomerOrderNotes from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes.vue'
import CustomerLeftSide from '@/components/OrderScreens/Customer/CustomerLayout/CustomerLeftSide.vue'
import { closeOrder, order } from '@/plugins/order/Order'
import CustomerPresenceBar from '@/components/OrderScreens/Customer/CustomerLayout/CustomerPresenceBar.vue'
import { Note } from '@/types'

export default defineComponent({
    name: 'CustomerOrderScreen',
    components: {
        CustomerPresenceBar,
        CustomerLeftSide,
        CustomerOrderNotes,
        CustomerStatusBar,
        CustomerOrderDialogs,
        CustomerOrderTags,
        CustomerOrderToolbar,
    },
    props: {
        /**
         * Determines if toolbar is shown or not
         */
        hideToolbar: {
            type: Boolean,
            default: false,
        },
        fixedToolbar: {
            type: Boolean,
            default: true,
        },
    },
    setup(_, { emit }) {
        const openDrawer = ref(true)

        const addNote = (note: Note): void => {
            emit('note-added', note)
        }

        return {
            openDrawer,
            order,
            closeOrder,
            addNote,
        }
    },
})
