













































import { defineComponent, PropType } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { Appraiser, AppraiserPanel } from '@/types'

/**
 * @name ExpandedAppraiserDetails
 * Details of the appraiser in the expanded partial of AssignTable. Shows email, address, phone number, language, certifications
 *
 * @SetupMethod copyEmail - shows snackbar after email was copied
 * @SetupData languages - Array of languages and their corresponding code
 * @SetupMethod getLanguageName - takes in language code, and returns the name of that language
 *
 */

export default defineComponent({
    props: {
        /**
         * Appraiser Details for specific appraiser
         */
        appraiser: {
            type: Object as PropType<Appraiser>,
        },
        /**
         * Appraiser panel information for specific appraiser
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup() {
        const copyEmail = () => {
            showSnackbar('Email Copied to Clipboard')
        }
        const languages = [
            { code: 'es', name: 'Spanish' },
            { code: 'zh', name: 'Chinese' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
        ]

        const getLanguageName = (code: string) => {
            const language = languages.find((lang) => lang.code === code)
            if (!language) return
            return language.name
        }
        return {
            copyEmail,
            getLanguageName,
        }
    },
})
