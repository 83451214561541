<template>
    <a v-if="data.property" href="#" @click.stop="openQuoteComplexity(data)">
        <strong>{{ data.property.ComplexityScore }}</strong>
    </a>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
    openComplexity,
    openComplexityQuote,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/PropertyComplexity.vue'
import { order } from '@/plugins/order/Order'

/**
 * @name ComplexityScoreColumn
 * Component description: Column for quote table to show open and score complexity quote for property
 * @SetupMethod openQuoteComplexity - opens complexity quote in PropertyComplexity and sets current order to order w/ quote
 */
export default defineComponent({
    props: {},
    setup() {
        const openQuoteComplexity = (data) => {
            if (data.order_cache && data.order_cache.order) {
                order.value = data.order_cache.order
                openComplexity()
                return
            }
            openComplexityQuote(data)
        }
        return { openQuoteComplexity }
    },
})
</script>
