




































import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import ClientOrderToolbarMobile from '@/components/OrderScreens/Client/ClientMobileComponents/ClientOrderToolbarMobile.vue'
import ClientOrderHeaderMobile from '@/components/OrderScreens/Client/ClientMobileComponents/ClientOrderHeaderMobile.vue'
import ClientActionComponent from '@/components/OrderScreens/Client/OrderComponents/ClientActionComponent.vue'
import ClientOrderNotes from '@/components/OrderScreens/Client/OrderComponents/ClientOrderNotes.vue'
import ClientOrderFiles from '@/components/OrderScreens/Client/OrderComponents/ClientOrderFiles.vue'
import ClientOrderAllContacts from '@/components/OrderScreens/Client/OrderComponents/ClientOrderAllContacts.vue'
import ClientContactsAndParticipants from '@/components/OrderScreens/Client/OrderComponents/ClientContactsAndParticipants.vue'
import ClientOrderInstructions from '@/components/OrderScreens/Client/OrderComponents/ClientOrderInstructions.vue'

/**
 * @name ClientOrderScreenMobile
 * Mobile order screen for the Client Order Screen
 */
export default defineComponent({
    name: 'ClientOrderScreenMobile',
    components: {
        ClientOrderInstructions,
        ClientOrderFiles,
        ClientOrderNotes,
        ClientOrderToolbarMobile,
        ClientOrderHeaderMobile,
        ClientActionComponent,
        ClientOrderAllContacts,
        ClientContactsAndParticipants,
    },
    setup() {
        return { order }
    },
})
