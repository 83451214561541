




































import { ValidationObserver } from 'vee-validate'
import { defineComponent, ref } from '@vue/composition-api'
import AEditor from '@/global/Form/AEditor.vue'
import { branch } from '../../BranchEdit.vue'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { AEditor, ValidationObserver },
    props: {
        option: String,
        revisionsLength: Number,
    },
    setup(props, { emit }) {
        const value = ref(props.option)
        const show = ref(false)
        const dialog = ref(false)

        const save = () => {
            if (!branch.value) return
            $axios
                .post('/v1/branch/' + branch.value.id + '/option', {
                    option_key: 'directive',
                    option_value: value.value,
                })
                .then((res) => {
                    showSnackbar('Directive saved successfully')
                    dialog.value = false
                    emit('directive-updated', res.data)
                })
        }
        return {
            dialog,
            save,
            value,
            show,
        }
    },
})
