var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('a-card',{attrs:{"elevation":0}},[_c('v-card-text',[_c('v-stepper',{staticClass:"mb-4",attrs:{"alt-labels":_vm.$vuetify.breakpoint.mdAndDown,"complete-icon":"$vuetify.icon.check","flat":""},model:{value:(_vm.currentReviewStep),callback:function ($$v) {_vm.currentReviewStep=$$v},expression:"currentReviewStep"}},[_c('v-stepper-header',[_vm._l((_vm.steps),function(step){return [_c('v-stepper-step',{key:step.id,attrs:{"step":_vm.steps.indexOf(step) + 1,"complete":_vm.stepsCompleted > _vm.steps.indexOf(step),"color":_vm.stepsCompleted > _vm.steps.indexOf(step) ? 'success' : 'primary'},on:{"click":function () {
                                    if (_vm.stepsCompleted >= _vm.steps.indexOf(step)) {
                                        _vm.currentReviewStep = _vm.steps.indexOf(step) + 1
                                    }
                                }}},[_vm._v(" "+_vm._s(step.name)+" ")])]})],2),_c('v-stepper-items',_vm._l((_vm.steps),function(step){return _c('v-stepper-content',{key:'content_' + step.id,staticClass:"pa-0",attrs:{"step":_vm.steps.indexOf(step) + 1}},[(_vm.steps.indexOf(step) + 1 === _vm.currentReviewStep)?_c('review-' + step.id,{tag:"component"}):_vm._e()],1)}),1)],1),(_vm.currentReviewStep !== _vm.steps.length)?_c('ClientReviewNotes'):_vm._e()],1),_c('v-card-actions',[(_vm.currentReviewStep > 1)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () {
                        _vm.currentReviewStep -= 1
                    }}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$vuetify.icons.chevronDoubleLeft")]),_vm._v(" Back ")],1):_vm._e(),_c('v-spacer'),(_vm.reviewDisabled)?_c('ReviewErrors'):_vm._e(),(!_vm.reviewDisabled)?_c('v-spacer'):_vm._e(),(_vm.currentReviewStep <= _vm.steps.length - 1)?_c('v-btn',{attrs:{"disabled":(_vm.reviewDisabled && _vm.stepsCompleted < _vm.currentReviewStep) || (_vm.isFHA && _vm.fhaNumber === false),"color":"primary"},on:{"click":function () {
                        _vm.stepsCompleted = _vm.currentReviewStep
                        _vm.currentReviewStep += 1
                    }}},[_vm._v(" Continue "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("$vuetify.icons.next")])],1):_vm._e(),(_vm.currentReviewStep === _vm.steps.length)?_c('v-btn',{attrs:{"disabled":_vm.reviewDisabled,"color":"primary"},on:{"click":_vm.completeReview}},[(_vm.finalReviewNote)?_c('span',[_vm._v("Send To Client")]):_c('span',[_vm._v("Complete Review")])]):_vm._e()],1)],1),_c('ContactsDialog',{ref:"CustomerContactsDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }