














import { defineComponent, PropType } from '@vue/composition-api'
import IntegrationWithCompanyID from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/IntegrationWithCompanyID.vue'
import { LenderOptions } from '@/types'

export default defineComponent({
    components: {
        IntegrationWithCompanyID,
    },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = props.option
        return {
            optionLocal,
        }
    },
})
