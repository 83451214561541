











































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import BranchEdit, { branchId, branchName, dialog } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'
import BranchAddDialog from '@/pages/AMC/Lenders/Branches/Partials/BranchAddDialog.vue'
import { Branch, Client, Task } from '@/types'
import ClientAddDialog from '@/pages/AMC/Lenders/Clients/Partials/ClientAddDialog.vue'
import ClientEditDialog, { open } from '@/pages/Client/ClientUsers/Partials/ClientEditDialog.vue'
import axios from 'axios'

/**
 * @name 'Office Lender Branches'
 * Component description: Is a Lender Option for the Office Branches. It will display a list of the office branches and users and the ability to edit existing or create a new one;
 * @SetupData getTasks() => will get the office branches values for the customer id and set the correct values;
 * @SetupData userAdded() => Is listening for when a user is added, is called when added from the ClientAddDialog, will push the new user added to the rendered list;
 * @SetupData editUser() => Will open the edit user dialog while passing the editing client;
 * @SetupData updateStatus() => Will emit that the status was updated;
 * @SetupData branchAdded() => Is listening for when a Branch is added, is called when added from the BranchAddDialog, will push the new branch added to the rendered list;
 * @SetupData addBranchOrUserDialog: The boolean value for the dialog addUser OR addBranch;
 * @SetupData branches: The value as an array of all the branches and users;
 * @SetupData loaded: The boolean value that will determine if the data has been loaded;
 */
export default defineComponent({
    name: 'LenderBranches',
    components: {
        BranchEdit,
        BranchAddDialog,
        ClientAddDialog,
        ClientEditDialog,
    },
    setup(_, { emit }) {
        const addBranchOrUserDialog = ref(false)
        const branches = ref([] as Branch[])
        const loaded = ref<boolean>(false)
        const task = ref<Task>({ id: 0, status: '', user_id: 0 })

        const updateStatus = (task: Task) => {
            emit('updateStatus', task)
        }

        const editUser = (client: Client) => {
            open(client)
        }

        const getTasks = () => {
            axios.get(`/v1/lender/${lender.value.id}/action/get-lender-branches`).then((res) => {
                branches.value = res.data.lender.branches
                task.value = res.data.task
                loaded.value = true
            })
        }

        const userAdded = (newClient: Client) => {
            branches.value = branches.value.map((branch) => {
                if (branch.id === newClient.branch_id) {
                    const clients = branch.clients as Client[]
                    clients.push(newClient)
                }
                return branch
            })
        }

        const branchAdded = (newBranch: Branch) => {
            if (newBranch.lender_id === lender.value.id) {
                branches.value = branches.value.concat({ ...newBranch, clients: [] })
            }
        }

        onMounted(getTasks)

        return {
            updateStatus,
            branchAdded,
            userAdded,
            loaded,
            task,
            branches,
            addBranchOrUserDialog,
            lender,
            editUser,
        }
    },
    methods: {
        /**
         * Is called when user edits a branch;
         * It sets the editing branch values to the dialog branch values;
         * opens the edit branch dialog;
         */
        editBranch(branch: Branch) {
            branchName.value = branch.name
            branchId.value = branch.id
            dialog.value = true
        },
    },
})
