
























import { defineComponent } from '@vue/composition-api'
import ModFeeRequestDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/ModFeeRequestDialog.vue'
import LenderEditDialog from '@/pages/AMC/Lenders/LenderListing/Partials/LenderEditDialog.vue'
import { complexity } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'
import PropertyComplexity from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/PropertyComplexity.vue'
import ViewAppraiserOrdersDialog from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/ViewAppraiserOrdersDialog.vue'
import RequestModificationDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/RequestModificationDialog.vue'
import DetailPopoverComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailPopoverComponent.vue'
import AddOrderDirective from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/AddOrderDirective.vue'
import EditMilestoneDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/EditMilestoneDialog.vue'
import EditOrderInvoiceDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderInvoiceDialog.vue'
import AddFileDialog from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/AddFileDialog.vue'
import AssignPanelNotesDialog from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignPanelNotesDialog.vue'
import BorrowerOutboundsDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderBorrower/BorrowerOutboundsDialog.vue'
import EditClientFileNumberDialog from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderClient/Partials/EditClientFileNumberDialog.vue'
import CustomerCancelOrderDialog from '@/components/OrderScreens/Customer/CustomerOrderComponents/DuplicatedComponents/Dialogs/CustomerCancelOrderDialog.vue'
import EditAccountingOfficeDialog from '@/components/OrderScreens/Office/OrderComponents/OfficePropertyDetails/EditAccountingOfficeDialog.vue'
import CustomerClientVendorOrderScreenViewOnly from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerClientVendorOrderScreenViewOnly.vue'
import TagActionDialog from '@/components/TagActions/TagActionDialog.vue'
import MortgageDirectorImportFileDialog from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/MortgageDirectorImportDialog.vue'
import CompleteOrderWithoutUploadAction from '@/components/OrderScreens/Shared/Actions/CompleteOrderWithoutUploadAction.vue'

export default defineComponent({
    name: 'CustomerOrderDialogs',
    components: {
        MortgageDirectorImportFileDialog,
        EditOrderInvoiceDialog,
        EditMilestoneDialog,
        DetailPopoverComponent,
        ViewAppraiserOrdersDialog,
        LenderEditDialog,
        ModFeeRequestDialog,
        PropertyComplexity,
        RequestModificationDialog,
        AddOrderDirective,
        AddFileDialog,
        AssignPanelNotesDialog,
        BorrowerOutboundsDialog,
        CustomerCancelOrderDialog,
        EditClientFileNumberDialog,
        EditAccountingOfficeDialog,
        CustomerClientVendorOrderScreenViewOnly,
        TagActionDialog,
        CompleteOrderWithoutUploadAction,
    },
    setup() {
        return {
            complexity,
        }
    },
})
