




import { defineComponent } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { order, updateOrder } from '@/plugins/order/Order'
import { getNotes } from '@/plugins/Notes'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

export const cancelInspection = () => {
    confirm('Cancel Inspection?', 'Are you sure you want to cancel this inspection?').then((confirm) => {
        if (confirm) {
            $axios
                .post('/v1/order/' + order.value?.id + '/workflow/schedule-cancel')
                .then(() => {
                    if (!order.value?.incremental) return
                    updateOrder()
                    order.value.inspection = null
                    order.value.incremental.inspection = null
                    getNotes(order.value.id)
                    showSnackbar('Inspection Cancelled')
                })
                .catch(() => {
                    showError('Oops, something went wrong!')
                })
        }
    })
}

export default defineComponent({
    setup() {
        return { cancelInspection }
    },
})
