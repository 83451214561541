import { order } from '@/plugins/order/Order'
import { ref, computed, Ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import {
    Form,
    Branch,
    Client,
    Order,
    SubLender,
    NewOrderLender,
    NewOrderConfiguration,
    MortgageDirectorFiles,
} from '@/types'
import {
    NewOrderFile,
    NewOrder,
    Addon,
    NewOrderFee,
    Officer,
    SetOrderData,
    CarbonCopy,
    SelectedFees,
    NewOrderAutoParticipants,
    ParticipantClient,
    AssignedLender,
    EncompassFile,
} from '@/types/newOrder'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import router from '@/pages/router'
import AscentHelper from '@/helpers/ascent-helper'
import { addCard } from '@/pages/Client/CreateOrderForm/NewOrderPayment.vue'
import { user } from '@/plugins/User'
import { GetNewOrderAllocation } from '@/pages/Client/GetOrderAllocation'
import { commercialPropertyTypes, propertyTypes as residentialPropertyTypes } from '@/plugins/Data'
import { CommercialProperyType } from '@/types'

export const assignedLender: Ref<AssignedLender | undefined> = ref(undefined)
export const amcLenders = ref([] as SubLender[])
export const LenderList = ref([] as unknown[])
export const lenders: Ref<NewOrderLender[]> = ref([])
export const officers = ref([] as Officer[])
export const carboncopylist = ref([] as CarbonCopy[])
export const forms = ref([] as Form[])
export const autoCopy = ref([])
export const autoFollower = ref([] as CarbonCopy[])
export const branches = ref([] as Branch[])
export const assignedBranch = ref({} as Branch)

export const loanTypes = ref([] as Form[])
export const addons = ref([] as Addon[])
export const client = ref({} as Client)
export const fees = ref([] as NewOrderFee[])

export const originalLoanTypes = ref([] as Form[])
export const originalAddons = ref([] as Addon[])
export const originalForms = ref([] as Form[])
export const branchOnOrder = ref(false)
export const loanOfficerOnOrder = ref(false)
export const useDisclosedFee = ref(false)

export const newOrder = ref({
    lender: {
        form_name: '',
        id: 0,
        name: '',
        type: '',
        options: [],
        address: {
            street: '',
            city: '',
            zip: '',
        },
    },
    assigner_id: 0,
    lender_id: 0,
    branch_id: 0,
    client_id: 0,
    appraiser_id: undefined,
    team_id: undefined,
    sublender_id: 0,
    officer_id: null,
    lender_loan_number: '',
    fha: '',
    priority: 'Standard',
    billing_method: '',
    billing_auth_code: '',
    loan_purpose: '',
    purchase_price: '',
    propertyType: 'Residential',
    residence_type: '',
    occupancy: '',
    jumbo: 'No',
    construction: 'No',
    construction_details: {
        builder: '',
        name: '',
        phone: '',
        subdivision: '',
    },
    commercial: {
        parcel: '',
        notes: '',
        propertyInterest: '',
        approachToValue: [],
        valueScenarios: [],
    },
    address: {
        street: '',
        city: '',
        zip: '',
    },
    forms: {
        loan: '',
        main: '',
        addons: [],
        total: 0,
        feeScheduleTotal: 0,
    },
    billing: {
        cards: [],
        splits: [],
        note: '',
    },
    borrowers: [
        {
            name: '',
            email: '',
            phones: [{ type: '', phone: '' }],
            entry: false,
        },
    ],
    contacts: [],
    loantype: '',
    financetype: '',
    carboncopy: [],
    instructions: '',
    due_date: '',
    source_system: 'form',
    integration: {
        type: 'form',
    },
    intentDate: '',
    intentPassed: false,
    loanAmount: 0,
    paymentEmails: [],
    disclosed_fee: null,
    overrideAssignment: false,
    appraised_value: 0,
    copyFiles: {
        sourceOrderId: null,
        files: [],
    },
} as NewOrder)

export const files = ref([] as NewOrderFile[])
export const encompassFiles = ref([] as EncompassFile[])
export const mortgageDirectorFiles = ref({} as MortgageDirectorFiles)
export const showForm = ref(true)
export const manualUser = ref(null as number | null)

export const orderConfiguration: Ref<NewOrderConfiguration> = ref({})
export const mortgageDirectorId = ref(null as number | null)

export const getForms = computed(() => {
    let currentForms = forms.value
    if (newOrder.value.propertyType == 'Commercial')
        currentForms = forms.value.filter((elem) => elem.detail?.details.commercial)
    if (newOrder.value.propertyType == 'Residential')
        currentForms = forms.value.filter((elem) => !elem.detail?.details.commercial)

    if (!AscentHelper.customerFeatureEnabled('monitoring.orderForm.propertyTypeFilter')) {
        if (newOrder.value.forms.loan == '') return currentForms

        return currentForms.filter((elem: Form) => {
            if (elem.detail?.details?.rules == null) return elem
            if (elem.detail?.details?.rules.indexOf(Number(newOrder.value.forms.loan)) > -1) return elem
        })
    }

    if (newOrder.value.forms.loan === '' && newOrder.value.residence_type === '') {
        return currentForms
    }

    return currentForms.filter((elem: Form) => {
        // If form doesn't have any loan type rules or property type rules, include it
        if (
            (!elem.detail?.details?.rules || elem.detail?.details?.rules.length === 0) &&
            (!elem.detail?.details?.property_types || elem.detail?.details?.property_types.length === 0)
        ) {
            return elem
        }

        // If form has property type rules AND loan type rules, make sure that both rules match
        if (
            elem.detail?.details?.property_types &&
            elem.detail?.details?.property_types.length > 0 &&
            elem.detail?.details?.rules &&
            elem.detail?.details?.rules.length > 0
        ) {
            if (newOrder.value.forms.loan !== '' && newOrder.value.residence_type !== '') {
                if (
                    elem.detail?.details?.rules.indexOf(Number(newOrder.value.forms.loan)) > -1 &&
                    elem.detail?.details?.property_types.indexOf(newOrder.value.residence_type) > -1
                ) {
                    return elem
                }
                return false
            }
        }

        // If form only has loan type rules, then check loan type matches
        if (elem.detail?.details?.rules && elem.detail?.details?.rules.length > 0) {
            if (newOrder.value.forms.loan === '') {
                return elem
            }

            if (
                newOrder.value.forms.loan !== '' &&
                elem.detail?.details?.rules &&
                elem.detail?.details?.rules.length > 0 &&
                elem.detail?.details?.rules.indexOf(Number(newOrder.value.forms.loan)) > -1
            ) {
                return elem
            }
        }

        // If form only has property type rules, then check property type matches
        if (elem.detail?.details?.property_types && elem.detail?.details?.property_types.length > 0) {
            if (newOrder.value.residence_type === '') {
                return elem
            }

            if (
                newOrder.value.residence_type !== '' &&
                elem.detail?.details?.property_types &&
                elem.detail?.details?.property_types.length > 0 &&
                elem.detail?.details?.property_types.indexOf(newOrder.value.residence_type) > -1
            ) {
                return elem
            }
        }
    })
})
export const filteredAddons = computed(() => {
    const main = Number(newOrder.value.forms.main)

    if (main == null) return addons.value

    return addons.value.filter((elem) => {
        if (elem.detail?.details?.rules == null) return elem
        if (elem.detail?.details?.rules.indexOf(main) > -1) return elem
    })
})

export const getOrderTotal = computed(() => {
    if (order.value) return null
    if (fees.value.length == 0) {
        return newOrder.value.disclosed_fee === null ? 0 : newOrder.value.disclosed_fee
    }
    const quoted = fees.value.find((elem: NewOrderFee) => {
        return elem.quote
    })
    if (typeof quoted != 'undefined') return 0
    return fees.value.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.total
    }, 0)
})

// Mutations

export function RESET() {
    assignedLender.value = undefined
    amcLenders.value = []
    lenders.value = []
    officers.value = []
    carboncopylist.value = []
    forms.value = []
    autoCopy.value = []
    autoFollower.value = []
    branches.value = []
    loanTypes.value = []
    addons.value = []
    client.value = {} as Client
    fees.value = []
    assignedBranch.value = {} as Branch
    newOrder.value = {
        lender: {
            id: null,
            name: null,
            form_name: null,
            options: [],
            type: '',
            address: {
                street: null,
                city: null,
                zip: null,
            },
        },
        lender_id: 0,
        branch_id: 0,
        client_id: 0,
        officer_id: 0,
        sublender_id: 0,
        appraiser_id: undefined,
        assigner_id: 0,
        lender_loan_number: '',
        fha: '',
        priority: 'Standard',
        billing_method: '',
        billing_auth_code: '',
        loan_purpose: '',
        purchase_price: '',
        propertyType: 'Residential',
        residence_type: '',
        occupancy: '',
        jumbo: 'No',
        construction: 'No',
        construction_details: {
            builder: '',
            name: '',
            phone: '',
            subdivision: '',
        },
        commercial: {
            parcel: '',
            notes: '',
            propertyInterest: '',
            approachToValue: [],
            valueScenarios: [],
        },
        address: {
            street: '',
            city: '',
            zip: '',
        },
        forms: {
            loan: '',
            main: 0,
            addons: [],
            total: 0,
            feeScheduleTotal: 0,
        },
        billing: {
            cards: [],
            splits: [],
            note: '',
        },
        borrowers: [
            {
                name: '',
                email: '',
                phones: [{ type: '', phone: '' }],
                entry: false,
            },
        ],
        contacts: [],
        carboncopy: [],
        paymentEmails: [],
        instructions: '',
        due_date: '',
        loantype: '',
        financetype: '',
        source_system: 'form',
        integration: {
            type: 'form',
        },
        intentDate: '',
        intentPassed: false,
        overrideAssignment: false,
        disclosed_fee: null,
        appraised_value: 0,
        copyFiles: {
            sourceOrderId: null,
            files: [],
        },
    }
    files.value = []
    encompassFiles.value = []
    showForm.value = true
    manualUser.value = null
}

export const addBorrower = (): void => {
    newOrder.value.borrowers.push({
        name: '',
        email: '',
        phones: [{ phone: '', type: '' }],
        entry: false,
    })
}

export const removeBorrower = (index: number): void => {
    newOrder.value.borrowers.splice(index, 1)
}

export const addContact = (): void => {
    newOrder.value.contacts.push({
        name: '',
        email: '',
        phones: [{ phone: '', type: '' }],
        contact_type: '',
        entry: false,
    })
}

export const removeContact = (index: number): void => {
    newOrder.value.contacts.splice(index, 1)
}

export const addSplit = (): void => {
    newOrder.value.billing.splits.push({ method: '', amount: 0 })
}

export const removeSplit = (index: number): void => {
    newOrder.value.billing.splits.splice(index, 1)
}

export const addFile = (file: NewOrderFile) => {
    files.value.push({
        name: file.name,
        path: file.path,
        id: file.id,
        type: file.type,
    })
}

export const removeFile = (index: number): void => {
    files.value.splice(index, 1)
}

export const addEncompassFile = (file: EncompassFile): void => {
    encompassFiles.value.push(file)
}

export const removeEncompassFiles = (index: number): void => {
    encompassFiles.value.splice(index, 1)
}

export const setFees = (selectedFees: SelectedFees): void => {
    fees.value = []

    if (selectedFees.loan != null) {
        fees.value.push({
            form: typeof selectedFees.loan.form != 'undefined' ? selectedFees.loan.form.name : selectedFees.loan.name,
            id: typeof selectedFees.loan.form != 'undefined' ? selectedFees.loan.form.id : selectedFees.loan.id,
            quote: selectedFees.loan.quote,
            total: parseFloat(selectedFees.loan.amc) + parseFloat(selectedFees.loan.appraiser),
            appraiser: parseFloat(selectedFees.loan.appraiser),
            amc: parseFloat(selectedFees.loan.amc),
        })
    }
    if (selectedFees.main != null) {
        fees.value.push({
            form: typeof selectedFees.main.form != 'undefined' ? selectedFees.main.form.name : selectedFees.main.name,
            id: typeof selectedFees.main.form != 'undefined' ? selectedFees.main.form.id : selectedFees.main.id,
            quote: selectedFees.main.quote,
            total: parseFloat(selectedFees.main.amc) + parseFloat(selectedFees.main.appraiser),
            appraiser: parseFloat(selectedFees.main.appraiser),
            amc: parseFloat(selectedFees.main.amc),
        })
    }
    if (selectedFees.addons != null && selectedFees.addons.length > 0) {
        selectedFees.addons.forEach((product) => {
            fees.value.push({
                form: typeof product.form != 'undefined' ? product.form.name : product.name,
                id: typeof product.form != 'undefined' ? product.form.id : product.id,
                quote: product.quote,
                total: parseFloat(product.amc) + parseFloat(product.appraiser),
                appraiser: parseFloat(product.appraiser),
                amc: parseFloat(product.amc),
            })
        })
    }

    const feeScheduleTotal = fees.value.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.total
    }, 0)

    newOrder.value.forms = { ...newOrder.value.forms, feeScheduleTotal: !feeScheduleTotal ? 0 : feeScheduleTotal }
}

export const setFeeType = (type: string | null) => {
    if (type === null) {
        newOrder.value.forms.total = !newOrder.value.forms.total ? 0 : newOrder.value.forms.total
        useDisclosedFee.value = false
    }

    if (type === 'Disclosed Fee' && newOrder.value.disclosed_fee !== null) {
        newOrder.value.forms.total = Number(newOrder.value.disclosed_fee)
        useDisclosedFee.value = true
    }

    if (
        type === AscentHelper.translate('lang.order_form_default_fee', 'Default') &&
        typeof getOrderTotal.value === 'number'
    ) {
        newOrder.value.forms.total = Number(
            useDisclosedFee.value && getOrderTotal.value === 0
                ? newOrder.value.forms.feeScheduleTotal
                : getOrderTotal.value,
        )
        useDisclosedFee.value = false
    }
}

const setOfficerCC = (update: NewOrderAutoParticipants): void => {
    update.participants.map((elem: ParticipantClient) => {
        elem.label = elem.name + ' - ' + elem.type
        newOrder.value.carboncopy.push(elem)
        return elem
    })
    update.followers.forEach((elem: CarbonCopy) => newOrder.value.carboncopy.push(elem))
}

export const getLenderOptionsById = (id: number) => {
    $axios.get('/v1/lender/' + id + '/option/branch-on-order').then((response) => {
        if (response.data && response.data.option_value) {
            branchOnOrder.value = response.data.option_value
        }
    })
    $axios.get('/v1/lender/' + id + '/option/officer-on-order').then((response) => {
        if (response.data && response.data.option_value) {
            loanOfficerOnOrder.value = response.data.option_value
        }
    })
}

interface Favorites {
    clients: number[]
    sublenders: number[]
}

const SetFavoritesList = (favorites: Favorites) => {
    const pulledFavorites = [] as SubLender[]
    amcLenders.value.forEach((elem: SubLender, index) => {
        if (favorites.sublenders.indexOf(elem.id as number) > -1) {
            pulledFavorites.push(amcLenders.value.splice(index, 1)[0])
        }
    })

    LenderList.value = []
    LenderList.value.push({ divider: true })
    LenderList.value.push({ header: 'Favorites' })
    pulledFavorites.forEach((elem) => {
        LenderList.value.push(elem)
    })
    LenderList.value.push({ header: 'Others' })
    LenderList.value.push({ divider: true })
    amcLenders.value.forEach((elem) => {
        LenderList.value.push(elem)
    })
}

export const clientInfo = () => {
    $axios.get('/v1/user/' + user.value?.id + '/settings/favorites').then((response) => {
        if (!response.data.meta_value) return
        const favorites = response.data.meta_value as Favorites
        LenderList.value = amcLenders.value
        if (favorites.sublenders && favorites.sublenders.length > 0) {
            SetFavoritesList(favorites)
        }

        if (favorites.clients && favorites.clients.length > 0) {
            officers.value.map((elem) => {
                if (favorites.clients.indexOf(elem.id as number) > -1) {
                    officers.value.splice(officers.value.indexOf(elem), 1)
                    elem.name = 'Favorite: ' + elem.name
                    officers.value.unshift(elem)
                }
            })

            carboncopylist.value.map((elem) => {
                if (favorites.clients.indexOf(elem.id as number) > -1) {
                    carboncopylist.value.splice(carboncopylist.value.indexOf(elem), 1)
                    elem.label =
                        'Favorite: ' + elem.name.replace('Favorite: ', '') + ', ' + elem.type + '(' + elem.email + ')'
                    carboncopylist.value.unshift(elem)
                }
            })
        }
    })
}

export const setOrderData = (data: SetOrderData): void => {
    branchOnOrder.value = false
    loanOfficerOnOrder.value = false

    assignedLender.value = data.assignedLender
    assignedBranch.value = data.branch
    amcLenders.value = data.amcLenders
    newOrder.value.lender_id = data.assignedLender.id

    if (data.lenders.length == 1) {
        newOrder.value.lender_id = data.lenders[0].id as number
        if (data.lenders[0].id != 589) {
            newOrder.value.lender = data.lenders[0]
        }
        if (data.lenders[0].id == 589) {
            newOrder.value.lender = {
                id: null,
                name: null,
                form_name: null,
                options: [],
                type: '',
                address: {
                    street: null,
                    city: null,
                    zip: null,
                },
            }
        }
    }

    const independentBrokersId = data.lenders.findIndex((elem) => elem.id == 589)
    LenderList.value = data.amcLenders

    if (independentBrokersId != -1) {
        data.lenders.splice(independentBrokersId, 1)
    }

    if (independentBrokersId === -1) {
        data.lenders.forEach((elem) => LenderList.value.push(elem))
    }

    if (!newOrder.value.branch_id) {
        newOrder.value.branch_id = data.client.branch_id
    }

    const compareFormNames = (a: Form | Addon, b: Form | Addon) => {
        if (!(a.name < b.name) && !(a.name < b.name)) return 0
        return a.name < b.name ? -1 : 1
    }
    loanTypes.value = data.loanTypes.sort(compareFormNames)
    forms.value = data.forms.sort(compareFormNames)
    addons.value = data.addOns.sort(compareFormNames)

    originalLoanTypes.value = data.loanTypes
    originalForms.value = data.forms
    originalAddons.value = data.addOns
    carboncopylist.value = data.carbonCopy.map((elem) => {
        elem.label = elem.name + ', ' + elem.type + '(' + elem.email + ')'
        return elem
    })
    if (data.processors) {
        officers.value = Array.prototype.concat(data.officers, data.processors)
    } else {
        officers.value = data.officers
    }
    client.value = data.client
    branches.value = data.branches
    lenders.value = data.lenders
    const newCopy = data.autoCopy.map((elem) => {
        elem.label = elem.name + ' - ' + elem.type
        return elem
    })
    newOrder.value.carboncopy.concat(newCopy)
    newOrder.value.client_id = client.value.id
    data.autoFollower.forEach((elem) => newOrder.value.carboncopy.push(elem))
    autoFollower.value = [...newOrder.value.carboncopy]
    if (data.due_date) {
        newOrder.value.due_date = data.due_date
    }
    clientInfo()
    if (data.assignedLender.lender_options['branch-on-order']) {
        branchOnOrder.value = data.assignedLender.lender_options['branch-on-order']['option_value'] as boolean
    }
    if (data.assignedLender.lender_options['officer-on-order']) {
        loanOfficerOnOrder.value = data.assignedLender.lender_options['officer-on-order']['option_value'] as boolean
    }

    if (data.borrowers) {
        newOrder.value.borrowers = data.borrowers
    }
    useDisclosedFee.value =
        Object.keys(orderConfiguration.value).indexOf('disclosedFee') === -1 ||
        (orderConfiguration.value?.disclosedFee ?? false)
}

export const GET_FEES = async () => {
    return await $axios
        .post('/v1/fees/lookup', {
            forms: newOrder.value.forms,
            address: newOrder.value.address,
            lender_id: newOrder.value.lender_id,
            appraiser_id: newOrder.value.appraiser_id,
            order: newOrder.value,
        })
        .then((response) => {
            if (response.data.status && response.data.status === 'error') {
                showError(response.data.message)
                return
            }
            setFees(response.data)
        })
        .catch((error) => {
            if (error.response?.data?.errors?.zipcode) {
                showError(error.response.data.errors.zipcode[0])
                return
            }
            if (error.response?.data?.message) {
                showError(error.response.data.message)
                return
            }
            showError('Oops, something went wrong!')
        })
}

export const ORDER_SETUP = async (id?: number) => {
    return await $axios
        .get('/v1/setup/order', {
            params: {
                id: id,
                client: manualUser.value,
            },
        })
        .then((response) => {
            setOrderData(response.data)
            if (newOrder.value.address.zip) GET_FEES()
        })
}

const setIntegrationData = (data: NewOrder) => {
    for (const key in data) {
        if (key === 'client_id' && data[key] !== null) {
            manualUser.value = data[key]
            ORDER_SETUP()
        }
        newOrder.value[key] = data[key]
    }
}

export const LOAN_OFFICER_CC = async (value: number) => {
    return await $axios
        .get('/v1/setup/order/officer', {
            params: {
                id: value,
            },
        })
        .then((response) => {
            setOfficerCC(response.data)
        })
}

export const loadDuplicate = (id: string, forms: string) => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/order/' + id + '/action/duplicate')
            .then((response) => {
                setIntegrationData(response.data)
                manualUser.value = response.data.client_id
                if (forms === 'final') {
                    newOrder.value.forms.main = 19
                }
                if (forms === 'recert') {
                    newOrder.value.forms.main = 20
                }
                newOrder.value.forms.loan = response.data.loanType.id
                GET_FEES()
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const loadMortgageDirector = (id: number): Promise<unknown> => {
    mortgageDirectorId.value = id
    return new Promise((resolve, reject) => {
        $axios
            .get('/mortgagedirector/new-order-details/' + id)
            .then((response) => {
                setIntegrationData(response.data.details)
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const loadEncompass = (id: number): Promise<unknown> => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/encompass/order-details/' + id)
            .then((response) => {
                setIntegrationData(response.data)
                ORDER_SETUP()
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const REFRESH_ENCOMPASS = (id: number): Promise<unknown> => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/encompass/order-details/' + id + '?manual=true')
            .then((response) => {
                setIntegrationData(response.data)
                ORDER_SETUP()
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const loadGuild = (id: string): Promise<unknown> => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/guild/order-details/' + id)
            .then((response) => {
                setIntegrationData(response.data)
                ORDER_SETUP()
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const serverErrors = ref([])

export const CREATE_ORDER = (): Promise<Order> => {
    if (manualUser.value !== null) {
        newOrder.value.client_id = manualUser.value
    }
    return new Promise((resolve, reject) => {
        serverErrors.value = []
        $axios
            .post('/v1/order', {
                ...newOrder.value,
                files: files.value,
                encompassFiles: encompassFiles.value,
                mortgageDirectorFiles: mortgageDirectorFiles.value,
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch(({ response }) => {
                if (response.data?.errors) serverErrors.value = response.data.errors
                showError('Failed to Create Order')
                reject()
            })
    })
}

export const getOrderConfiguration = async (runOrderSetup = true) => {
    return $axios
        .get('/v1/settings/new-order-form')
        .then((response) => {
            orderConfiguration.value = response.data.result
            if (!runOrderSetup) return
            if (
                (!router.currentRoute.meta.userType.includes('client') || AscentHelper.isCustomer()) &&
                router.currentRoute.path !== 'settings'
            ) {
                if (
                    Object.keys(orderConfiguration.value).indexOf('useDefaultUser') !== -1 &&
                    orderConfiguration.value.useDefaultUser
                ) {
                    manualUser.value = orderConfiguration.value.useDefaultUser as number
                    ORDER_SETUP()
                }
            }
        })
        .catch()
}

export const getVendorFees = (vendorId: number) => {
    newOrder.value.appraiser_id = vendorId
    GET_FEES()
}

export const setOverrideLender = (lender: SubLender | string | number) => {
    if (lender === null) {
        newOrder.value.lender = {
            id: '',
            name: '',
            form_name: '',
            type: '',
            address: {
                street: '',
                city: '',
                zip: '',
            },
            options: [],
        }

        loanTypes.value = originalLoanTypes.value
        addons.value = originalAddons.value
        forms.value = originalForms.value
        branchOnOrder.value = false
        loanOfficerOnOrder.value = false
        return
    }

    if (typeof lender === 'string') {
        newOrder.value.lender = {
            id: 'new',
            name: lender as string,
            form_name: lender as string,
            type: 'sublender',
            address: {
                street: '',
                city: '',
                zip: '',
            },
            options: [],
        }
        newOrder.value.lender_id = assignedLender.value?.id as number
        newOrder.value.sublender_id = 0
        getLenderOptionsById(newOrder.value.lender_id)

        loanTypes.value = originalLoanTypes.value
        addons.value = originalAddons.value
        forms.value = originalForms.value
        return
    }

    if (typeof lender !== 'number' && lender.address) {
        newOrder.value.lender.address.street = lender.address.street
        newOrder.value.lender.address.city = lender.address.city
        newOrder.value.lender.address.zip = lender.address.zip
    }

    newOrder.value.billing_method = ''
    newOrder.value.billing = {
        cards: [],
        splits: [],
        note: '',
    }
    addCard.value = true

    if (typeof lender !== 'number' && lender.type === 'lender') {
        newOrder.value.lender_id = lender.id as number
        $axios.get('v1/lender/' + lender.id + '/forms').then((response) => {
            loanTypes.value = response.data.loanTypes
            forms.value = response.data.forms
            addons.value = response.data.addOns
        })
        getLenderOptionsById(lender.id as number)

        return
    }

    if (typeof lender !== 'number' && lender.type !== 'lender') {
        newOrder.value.sublender_id = lender.id as number
        newOrder.value.lender_id = assignedLender.value?.id as number
        getLenderOptionsById(newOrder.value.lender_id)
    }

    if (typeof lender === 'number') {
        newOrder.value.lender_id = lender
        getLenderOptionsById(newOrder.value.lender_id)
    }
    loanTypes.value = originalLoanTypes.value
    addons.value = originalAddons.value
    forms.value = originalForms.value
}

export const movementVaLoan = computed(() => {
    if (
        !newOrder.value ||
        !newOrder.value.forms ||
        !newOrder.value.forms.loan ||
        newOrder.value.lender.customer_id !== 6
    )
        return false

    const movementVaLoanIds = [323, 390]

    return movementVaLoanIds.includes(Number(newOrder.value.forms.loan))
})

export const snapshotFileAdded = ref(false)
export const snapshotFileRequired = ref(false)

const validateEmail = (email: CarbonCopy | string) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email as string)
}

export const validateAll = () => {
    newOrder.value.carboncopy = newOrder.value.carboncopy.filter((elem) => {
        if (elem.email) return true
        return validateEmail(elem)
    })
}

export const zipChange = (zip: string) => {
    if (zip.length !== 5) return
    if (
        (AscentHelper.customerFeatureEnabled('assignType') as unknown as string) === 'vendor' &&
        (newOrder.value.appraiser_id || newOrder.value.appraiser_id == 0)
    ) {
        GetNewOrderAllocation()
    }
    GET_FEES()
}

export const commercialType = ref('Land' as CommercialProperyType)
export const selectableTypes = Object.keys(commercialPropertyTypes)

export const propertyTypes = computed(() => {
    if (newOrder.value.propertyType == 'Commercial') return commercialPropertyTypes[commercialType.value]

    return residentialPropertyTypes
})
