<template>
    <v-dialog v-model="dialog" max-width="600px">
        <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-card>
                <v-form>
                    <v-card-title>
                        <span class="headline">Refund card</span>
                    </v-card-title>
                    <v-card-text v-if="$ascent.canDo('Refund Cards')">
                        <v-container grid-list-md>
                            <v-row>
                                <v-col cols="12">Enter the amount you would like to refund.</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <a-text-field
                                        v-model="amount"
                                        rules="required"
                                        label="Refund Amount"
                                        name="amount"
                                        prepend-icon="$vuetify.icons.dollarSign"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-alert v-show="error != null" color="error">
                                        {{ error }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-alert color="primary" dark type="warning">
                            Refunding cards requires the "Refunds Cards" permission. If you should have access to this
                            feature, please reach out to your admins to give you access.
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" text @click="dialog = false">Close</v-btn>
                        <v-btn
                            v-if="$ascent.canDo('Refund Cards')"
                            color="error"
                            depressed
                            :loading="loading"
                            :disabled="invalid"
                            @click="processRefund"
                        >
                            Refund Card
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import $ascent from '@/helpers/ascent-helper'

function reset() {
    return {
        dialog: false,
        payment: null,
        amount: null,
        loading: null,
        error: null,
    }
}
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'

export default {
    data() {
        return reset()
    },
    components: {
        ValidationObserver,
    },
    methods: {
        open(item) {
            this.payment = item
            this.amount = item.amount
            this.dialog = true
        },
        processRefund() {
            this.error = null
            this.loading = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/payment/' + this.payment.id + '/refund', {
                    amount: this.amount,
                    gateway: $ascent.featureEnabled('gateway', 'nexio'),
                })
                .then((response) => {
                    if (response.data.result === 'error') {
                        this.error = response.data.message
                        this.loading = false
                        return
                    }
                    refreshOrder().then(() => {
                        showSnackbar('Refund processed successfully')
                        Object.assign(this.$data, reset())
                    })
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>
