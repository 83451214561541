var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',{staticClass:"accentWhite",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center mx-8"},[_c('v-col',{staticClass:"my-4"},[_c('h2',[_vm._v("Notes")])])],1),_c('a-card',{staticClass:"mx-8"},[_c('v-row',[_c('v-col',{attrs:{"align":_vm.notes.length ? 'end' : 'center'}},[(_vm.$ascent.canDo('Edit Clients'))?_c('v-btn',_vm._g(_vm._b({class:_vm.notes.length && 'mr-8 mt-4',attrs:{"color":"secondarydarken4","text":""}},'v-btn',attrs,false),on),[_vm._v(" Add Note ")]):_vm._e()],1),(!_vm.$ascent.canDo('Edit Clients') && !_vm.notes.length)?_c('h4',[_vm._v("No Notes Added")]):_vm._e()],1),(_vm.notes.length)?_c('v-list',{staticClass:"c-list__notes"},_vm._l((_vm.notes),function(note,index){return _c('v-row',{key:note.id},[_c('v-col',[_c('lender-note',{attrs:{"note":note}}),(index !== _vm.notes.length - 1)?_c('v-divider'):_vm._e()],1)],1)}),1):_vm._e()],1),_c('v-container',[_c('v-row',{staticClass:"ma-16"}),_c('v-row',{staticClass:"ma-16"}),_c('v-row',{staticClass:"ma-16"}),_c('v-row',{staticClass:"ma-16"})],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('a-card',{attrs:{"title":"New Note"}},[_c('ValidationObserver',{ref:"note",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',[_c('a-editor',{attrs:{"uuid":"LenderEditDirectives","name":"newnote","outlined":"","label":"Note","rules":"required"},model:{value:(_vm.newNote),callback:function ($$v) {_vm.newNote=$$v},expression:"newNote"}})],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"url":("/v1/lender/" + (_vm.lender.id) + "/note/"),"disabled":!_vm.newNote,"submitData":{
                    note: _vm.newNote,
                }},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.save}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }