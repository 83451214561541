










































import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import GmapCustomMarker from 'vue2-gmap-custom-marker'

export default defineComponent({
    components: {
        GmapCustomMarker,
    },
    setup() {
        return {
            order,
        }
    },
})
