










































import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'

export default defineComponent({
    setup() {
        return {
            order,
            user,
        }
    },
})
