

















































































import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { OrderModel } from '@/types'

const dialog = ref(false)
const appraiserOrders = ref([] as OrderModel[])
const appraiserName = ref('')
const total = ref(0)

export const openAppraiserOrdersDialog = (orders: OrderModel[], name: string, totalNumber: number) => {
    appraiserOrders.value = orders
    appraiserName.value = name
    total.value = totalNumber
    dialog.value = true
}

/**
 * @name ViewAppraiserOrdersDialog
 * Shows all the appraisers that the appraiser currently has in the system and allows user to open up those orders
 *
 * @SetupData appraiserOrders - Array of all the appraiser's orders.
 * @SetupData appraiserName - String of appraiser's name
 * @SetupMethod openAppraiserOrdersDialog - sets the appraiserOrders and appraiserName and opens dialog
 */

export default defineComponent({
    setup() {
        const openOrder = (id: number): void => {
            window.open(`/order/${id}`, '_blank')
        }
        return {
            dialog,
            order,
            openOrder,
            appraiserOrders,
            appraiserName,
            total,
        }
    },
})
