




































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import AEditor from '@/global/Form/AEditor.vue'

export default defineComponent({
    components: {
        AEditor,
    },
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const contractAnalyzed = ref('')
        const reasonContractNotAnalyzed = ref('')
        const contractSignature = ref('No')

        watch(props, () => {
            if (!props.editData && !props.cancelEdit) {
                if (contractAnalyzed.value == 'Yes') reasonContractNotAnalyzed.value = ''
                if (!props.editData && !props.cancelEdit) {
                    preloadData.value.contractData.contractAnalyzed = contractAnalyzed.value
                    preloadData.value.contractData.reasonContractNotAnalyzed = reasonContractNotAnalyzed.value
                    preloadData.value.contractData.contractSignature = contractSignature.value
                }
            }
            if (props.editData) {
                contractAnalyzed.value = preloadData.value.contractData.contractAnalyzed
                reasonContractNotAnalyzed.value = preloadData.value.contractData.reasonContractNotAnalyzed
                contractSignature.value = preloadData.value.contractData.contractSignature
            }
        })

        return {
            preloadData,
            contractAnalyzed,
            reasonContractNotAnalyzed,
            contractSignature,
        }
    },
})
