




import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { AppraiserOption } from '@/types'

export default defineComponent({
    name: 'AppraiserStaggerDays',
    setup() {
        const appraiserStaggerDays = computed(() => {
            if (
                !order.value ||
                !order.value.incremental ||
                !order.value.incremental.appraiser ||
                !order.value.incremental.appraiser.options
            )
                return undefined
            if (typeof order.value.incremental.appraiser.options !== 'object') return undefined
            const service = order.value.incremental.appraiser.options.find((elem: AppraiserOption) => {
                return elem.option_key === 'stagger'
            })
            if (!service) return undefined
            return service.option_value
        })

        return {
            appraiserStaggerDays,
        }
    },
})
