<template>
    <v-container pa-0 fluid dense>
        <ACard>
            <template slot="top" v-if="order.incremental">
                <v-app-bar dense flat color="accentWhite text-white">
                    <v-toolbar-title class="title pl-0">
                        <v-icon left color="accentBlack">$vuetify.icon.info</v-icon>
                        Details
                    </v-toolbar-title>
                </v-app-bar>
            </template>

            <v-row>
                <v-col cols="8">
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle v-html="'Property Address:'" />
                                <v-list-item-title style="white-space: pre-wrap">
                                    {{ order.address.full_address_formatted }}
                                    <span class="pr-4">({{ order.county }} County)</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle v-html="'Product:'" />
                                <v-list-item-title style="white-space: pre-wrap">
                                    {{ order.productList }} / {{ order.loanPurpose }}
                                    <span v-if="order.purchasePrice && order.loanPurpose !== 'Refinance'" class="small">
                                        - {{ $ascent.currency(order.purchasePrice) }}
                                    </span>
                                    <span class="small">- {{ order.residence_type }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-col cols="12">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle v-html="'Ordered Date:'" />
                                    <v-list-item-title v-if="order.inspection != null" style="white-space: pre-wrap">
                                        <span class="small">
                                            {{ $ascent.formatUnix(order.requestedDate, 'EEE, MMM d, t') }}
                                        </span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle v-html="'Inspection Date:'" />
                                    <v-list-item-title v-if="order.inspection != null" style="white-space: pre-wrap">
                                        <span class="small">
                                            {{ $ascent.formatUnix(order.inspection, 'EEE, MMM d, t') }}
                                        </span>
                                    </v-list-item-title>
                                    <v-list-item-title v-if="order.inspection == null">
                                        <strong>N/A</strong>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-list>
                </v-col>
                <v-col class="d-flex flex-column" align="center">
                    <h3 class="text-center">Due Date:</h3>
                    <v-card class="mx-auto" width="80">
                        <v-img class="white--text red darken-2 align-center" height="30px">
                            <v-card-title style="display: grid; place-items: center">
                                {{ $ascent.formatUnix(order.appraiserDeadline, 'LLL', false).toUpperCase() }}
                            </v-card-title>
                        </v-img>

                        <v-card-text class="align-center text-center text--primary py-2 mb-0">
                            <span style="font-size: 30px">
                                {{ $ascent.formatUnix(order.clientDeadline, 'd', false) }}
                            </span>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-4 mx-auto" width="80">
                        <v-img class="white--text primary align-center justify-center" height="30px">
                            <v-card-title style="display: grid; place-items: center">Fee:</v-card-title>
                        </v-img>
                        <v-card-text class="align-center text-center text--primary py-3">
                            <h3>${{ order.fee }}</h3>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </ACard>

        <ACard class="mt-6">
            <template slot="top" v-if="order.incremental">
                <v-app-bar dense flat color="accentWhite text-white">
                    <v-toolbar-title class="title pl-0">
                        <v-icon left color="accentBlack">$vuetify.icon.info</v-icon>
                        Information
                    </v-toolbar-title>
                </v-app-bar>
            </template>
            <v-col cols="12" v-if="order && order.incremental">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content v-if="order.incremental.user">
                            <v-list-item-subtitle class="font-weight-bold">
                                <strong>Representative:</strong>
                            </v-list-item-subtitle>
                            <v-list-item-title>
                                {{ order.incremental.user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ order.incremental.user.email }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="representativePhone">
                                {{ representativePhone.phone_formatted }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle class="font-weight-bold">
                                <strong>Lender Information</strong>
                            </v-list-item-subtitle>
                            <v-list-item-title>Lender: {{ order.incremental.lender.form_name }}</v-list-item-title>
                            <v-list-item-title>Branch: {{ order.branchName }}</v-list-item-title>
                            <v-list-item-title>
                                <strong>Loan #:</strong>
                                {{ order.lenderLoanNumber }}
                            </v-list-item-title>
                            <v-list-item-title v-if="order.fha">
                                <strong>FHA #:</strong>
                                {{ order.fha }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Residence Type:</v-list-item-subtitle>
                            <v-list-item-title>{{ order.residence_type }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </ACard>
    </v-container>
</template>

<script>
import { order, representativePhone } from '@/plugins/order/Order.ts'

/**
 * @name ClientOrderHeaderMobile
 * The mobile order screen header for the Client Order Screen
 */

export default {
    components: {},
    setup() {
        return {
            order,
            representativePhone,
        }
    },
}
</script>
