import { computed, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { Contact, File } from '@/types'
import { unreadNotes, notes } from '@/plugins/Notes'
import { files } from '@/plugins/Files'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { orderProducts } from '@/plugins/order/OrderProducts'
import Vue from 'vue'

interface ReviewNote {
    note: string
    removable: true
}

export const stepsCompleted = ref(0)
export const currentReviewStep = ref(1)
export const filesReviewed = ref(true)
export const finalReviewNote = ref('')

export const contactNoteAdded = ref(false)
export const reviewNotes = ref([] as ReviewNote[])

export const otherLoanType = computed(() => order.value?.loan === 'Other')
export const conventionalFHACheck = computed(() => order.value?.loan === 'Conventional' && order.value.fha?.length)

export const paymentNotes = computed(() => {
    return notes.value.filter((note) => ['Review Payment', 'Review Error'].indexOf(note.target) > -1)
})

export const billingAlertsReviewed = ref(true)

let hasNotes = false

export const steps = computed(() => {
    const steps = [{ id: 'property', name: 'Property and Loan' }]
    if (order.value?.incremental && order.value?.incremental.duplicates.length > 0) {
        steps.push({ id: 'duplicates', name: 'Duplicates' })
    }

    if (files.value.length > 0) {
        steps.push({ id: 'files', name: 'Files' })
    }

    if (unreadNotes.value.length > 0 || hasNotes) {
        hasNotes = true
        steps.push({ id: 'notes', name: 'Unread Notes' })
    }
    steps.push({ id: 'fees', name: 'Fees and Billing' })

    return steps
})

export const hasEntryContacts = computed(() => {
    if (!order.value) return
    if (!order.value.incremental) return
    const entryNotRequired = orderProducts.value.filter((elem) => {
        return elem.formType === 'Main Product' && elem.form?.detail?.details.inspection === 0
    })
    if (entryNotRequired.length > 0) return true
    const entry_c = order.value.incremental.contacts.filter((elem: Contact) => elem.entry)
    const entry_b = order.value.incremental.borrowers.filter((elem: Contact) => elem.entry)
    const all = entry_c.concat(entry_b)
    return all.length > 0
})

export const entryContactsReviewed = computed(() => {
    if (contactNoteAdded.value) {
        return true
    }

    return hasEntryContacts.value
})

export const fhaHasPurchaseContract = computed(() => {
    if (!files.value || !order.value || !order.value.incremental) return false

    // TODO: Refactor temporary hard code for customers 1 and 2 to be configurable
    if (![1, 2].includes(order.value.incremental.customer_id)) return true

    if (files.value.length && currentReviewStep.value == 1) return true
    const order1004D = orderProducts.value.find(
        (product) => product.form.id == 19 || product.form.id == 20 || product.form.id == 46 || product.form.id == 27,
    )
    if (order1004D) return true
    if (
        order.value.fha &&
        order.value.loanPurpose == 'Purchase' &&
        !files.value.find((elem) => elem.type == 'Purchase Contract')
    )
        return false
    return true
})

export const lenderBilling = computed(() => {
    const billing = lenderOptions.value.billing
    if (!billing) return ''
    return JSON.parse(JSON.stringify(billing)).map((billingMethod: string) => {
        if (billingMethod === 'card_up_front' || billingMethod === 'company_card' || billingMethod === 'credit_card') {
            return 'credit-card'
        }
        if (billingMethod === 'invoice_with_permission' || billingMethod === 'invoice_with_auth') {
            return 'net-30'
        }
        if (billingMethod === 'borrower') {
            return 'borrower-paid'
        }

        return billingMethod
    })
})

export const billingTypes = {
    'net-30': 'Bill To',
    'credit-card': 'Credit Card',
    'borrower-paid': 'Borrower',
}

export const borrowerEmailNeeded = computed(() => {
    let emailNeeded = false
    if (order.value?.billingMethod === 'borrower-paid') {
        emailNeeded = true
        order.value?.incremental?.borrowers.forEach((borrower) => {
            if (borrower.email) {
                emailNeeded = false
            }
        })
    }

    return emailNeeded
})

export const reviewDisabled = computed(() => {
    if (currentReviewStep.value == 1) {
        return !entryContactsReviewed.value || !fhaHasPurchaseContract.value
    }

    if (!steps.value || !steps.value[currentReviewStep.value - 1]) return false
    if (steps.value[currentReviewStep.value - 1].id === 'files') {
        if (!files.value.length) return false
        return !filesReviewed.value || !fhaHasPurchaseContract.value
    }

    if (steps.value[currentReviewStep.value - 1].id === 'notes') {
        return !!unreadNotes.value.length
    }

    if (steps.value[currentReviewStep.value - 1].id === 'fees') {
        if (!billingAlertsReviewed.value) return true

        if (['split', 'mercury-card', 'valuepad-card'].includes(order.value?.billingMethod as string)) return false

        if (order.value?.billingMethod === 'deferred-card' && order.value?.source === 'mercury') return false

        if (
            order.value?.billingMethod === 'credit-card' &&
            lenderBilling.value.includes('borrower-paid') &&
            order.value?.incremental?.cards &&
            order.value?.incremental?.cards.length > 0
        )
            return false

        if (order.value?.billingMethod === 'borrower-paid') {
            return borrowerEmailNeeded.value
        }

        if (
            !lenderBilling.value.includes(order.value?.billingMethod as string) &&
            order.value?.paidStatus == 'Not Paid'
        ) {
            return true
        }
    }

    return false
})

export const saveNote = (note: string) => {
    if (reviewNotes.value.find((reviewNote) => reviewNote.note === note)) return
    reviewNotes.value.push({
        note,
        removable: true,
    })
}

export const removeNote = (note: string) => {
    reviewNotes.value = reviewNotes.value.filter((reviewNote) => reviewNote.note !== note)
}

export const userRemoveNote = (item: ReviewNote) => {
    if (item.note === 'Please provide contact for entry') {
        contactNoteAdded.value = false
        stepsCompleted.value = 0
        currentReviewStep.value = 1
    }

    reviewNotes.value.splice(reviewNotes.value.indexOf(item), 1)
}

export const duplicateFiles = ref([] as File[][])

export const getDuplicateFiles = () => {
    order.value?.incremental?.duplicates.forEach((duplicate, index) => {
        $axios.get('/v1/order/' + duplicate.duplicate_id + '/file').then((response) => {
            duplicateFiles.value[index] = response.data
                .filter((file: File) => {
                    return (
                        [
                            'Appraisal XML',
                            'Appraiser EO',
                            'Appraiser License',
                            'EAD SSR',
                            'FNM SSR',
                            'FRE SSR',
                            'Guild Combined',
                        ].indexOf(file.type) === -1 && file.status !== 'Inactive'
                    )
                })
                .map((file: File) => ({ ...file, addedToOrder: false }))
            Vue.set(duplicate, 'hasFiles', duplicateFiles.value[index].length > 0)
        })
    })
}
