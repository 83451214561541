



















































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { files } from '@/plugins/Files'
import { File } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    setup(_, { emit }) {
        const selectedFile = ref(undefined)
        const localFiles = ref([] as File[])
        const fileIds = ref([] as number[])
        const orderFiles = ref([] as File[])
        const includeOrderPdf = ref(false)

        const addFile = (file: File) => {
            if (!file) return
            localFiles.value.push(file)
            const fileIndex = orderFiles.value.findIndex((elem) => elem.id == file.id)
            orderFiles.value.splice(fileIndex, 1)
            fileIds.value = localFiles.value.map((elem) => elem.id)
            selectedFile.value = undefined
            emit('files-updated', fileIds.value)
        }

        const removeFile = (file: File) => {
            orderFiles.value.push(file)
            const fileIndex = localFiles.value.findIndex((elem) => elem.id == file.id)
            localFiles.value.splice(fileIndex, 1)
            fileIds.value = localFiles.value.map((elem) => elem.id)
            selectedFile.value = undefined
            emit('files-updated', fileIds.value)
        }

        const getLabel = (file: File) => {
            return file.type + ' ' + AscentHelper.formatIsoDate(file.created_at, 'MM/d, t')
        }

        watch(includeOrderPdf, () => {
            emit('order-pdf-updated', includeOrderPdf.value)
        })

        onMounted(() => {
            if (!files.value) return
            orderFiles.value = [...files.value]
        })

        return {
            fileIds,
            orderFiles,
            includeOrderPdf,
            selectedFile,
            localFiles,
            getLabel,
            addFile,
            removeFile,
        }
    },
})
