









































































































































































































































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import DataMapSelect from '@/components/General/AutoCompletes/DataMapSelect.vue'

interface AvailableFolder {
    id: string
    label: string
}

interface MappedFolder {
    id: string
    value: string
}

export default defineComponent({
    components: { DataMapSelect, ValidationObserver },
    setup() {
        const dialog = ref(false)
        const appraisal = ref('Appraisal')
        const final = ref('Appraisal')
        const insurance = ref('Appraisal')
        const license = ref('Appraisal')
        const ssr = ref('Appraisal')
        const invoice = ref('Appraisal')
        const compliance = ref('Appraisal')
        const borrower = ref('Appraisal')
        const receipt = ref('Appraisal')
        const fields = ref([])
        const fieldDisplay = ref([])
        const folders = ref<MappedFolder[]>([])

        const folderLabels = {
            Review: 'Jaro Review',
            Checklist: 'QC Checklist',
        }

        const availableFolders = computed(() => {
            const available = [] as AvailableFolder[]
            Object.keys(folderLabels).forEach((folder) => {
                const exists = folders.value.find((elem) => elem.id === folder)
                if (!exists) {
                    available.push({ id: folder, label: folderLabels[folder as keyof object] })
                }
            })
            return available
        })

        const getAvailableFolders = (folder: MappedFolder) => {
            if (!folder.id) {
                return availableFolders.value
            }

            return [{ id: folder.id, label: folderLabels[folder.id as keyof object] }, ...availableFolders.value]
        }

        onMounted(() => {
            $axios.get('/v1/lender/' + lender.value.id + '/option/encompass-folders').then((response) => {
                if (response.data.option_value) {
                    appraisal.value = response.data.option_value.Appraisal
                    final.value = response.data.option_value.Final
                    insurance.value = response.data.option_value.Insurance
                    license.value = response.data.option_value.License
                    ssr.value = response.data.option_value.SSR
                    invoice.value = response.data.option_value.Invoice
                    compliance.value = response.data.option_value.Compliance
                    borrower.value = response.data.option_value.Borrower
                    receipt.value = response.data.option_value.Receipt
                    if (response.data.option_value.fields) {
                        fields.value = response.data.option_value.fields
                        fieldDisplay.value = response.data.option_value.fields
                    }
                    if (response.data.option_value.folders) {
                        folders.value = response.data.option_value.folders
                    }
                }
            })
        })

        const save = () => {
            dialog.value = false
            fieldDisplay.value = [...fields.value]
            showSnackbar('Encompass Buckets Saved')
        }

        return {
            open,
            dialog,
            lender,
            save,
            appraisal,
            final,
            insurance,
            license,
            ssr,
            invoice,
            compliance,
            borrower,
            receipt,
            fields,
            fieldDisplay,
            folders,
            folderLabels,
            availableFolders,
            getAvailableFolders,
        }
    },
})
