

























import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'
import {
    appraiserValue,
    appraiserSettingsId,
    appraiserOptions,
    AppraiserOptionsSettings,
} from '@/pages/Appraiser/Profile/AppraiserProfile'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    props: {
        option: {
            type: Object || undefined,
        },
    },
    setup(props, { root }) {
        const appraiserOptionsLocal = ref({
            click_fees_enabled: false,
        } as { click_fees_enabled: boolean | undefined })

        const customerClickFeesEnabled = computed(() => {
            return AscentHelper.customerFeatureEnabled('orderScreen.clickFee')
        })

        const clickFeeNotSet = ref(false)

        if (appraiserOptions.value.click_fees_enabled === undefined) {
            clickFeeNotSet.value = true
            appraiserOptionsLocal.value.click_fees_enabled =
                AscentHelper.customerFeatureEnabled('autoEnableAppraiserClickFees')
        }

        watch(appraiserOptions && appraiserValue, () => {
            if (props.option && props.option.click_fees_enabled) {
                appraiserOptions.value.click_fees_enabled = props.option.click_fees_enabled
                appraiserOptionsLocal.value.click_fees_enabled = props.option.click_fees_enabled
            }
        })

        if (props.option && props.option.click_fees_enabled) {
            appraiserOptions.value.click_fees_enabled = props.option.click_fees_enabled
            appraiserOptionsLocal.value.click_fees_enabled = props.option.click_fees_enabled
        }

        const saveChanges = async () => {
            if (!customerClickFeesEnabled) return
            if (root.$route.params.id) appraiserSettingsId.value = root.$route.params.id
            await axios.post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                option_key: 'click_fees_enabled',
                option_value: appraiserOptions.value.click_fees_enabled,
            })
            appraiserOptionsLocal.value = appraiserOptions.value as AppraiserOptionsSettings
            clickFeeNotSet.value = false
            showSnackbar('Saved successfully')
        }

        if (
            AscentHelper.customerFeatureEnabled('autoEnableAppraiserClickFees') &&
            appraiserOptions.value.click_fees_enabled === undefined
        ) {
            appraiserOptions.value.click_fees_enabled = true
        }

        return {
            saveChanges,
            appraiserOptions,
            appraiserOptionsLocal,
            customerClickFeesEnabled,
            clickFeeNotSet,
        }
    },
})
