import { render, staticRenderFns } from "./CustomerOrderActions.vue?vue&type=template&id=7f455e94&scoped=true&"
import script from "./CustomerOrderActions.vue?vue&type=script&lang=ts&"
export * from "./CustomerOrderActions.vue?vue&type=script&lang=ts&"
import style0 from "./CustomerOrderActions.vue?vue&type=style&index=0&id=7f455e94&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f455e94",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMenu,VSubheader,VSwitch})
