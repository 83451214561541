























































































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { Lender } from '@/types'

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const dialog = ref(false)
        const investors = ref((lender.value as Lender).investors as string[])
        const save = () => {
            axios
                .patch('/v1/lender/' + lender.value.id + '/action/sync-investors', {
                    investors: investors.value,
                })
                .then((response) => {
                    const l = lender.value as Lender
                    l.investors = response.data
                    showSnackbar('Investors Updated Successfully')
                })
        }

        return {
            save,
            dialog,
            investors,
        }
    },
    data() {
        return {
            items: [],
        }
    },

    mounted() {
        this.$axios.post('/v1/investors', { rowsPerPage: false }).then((response) => {
            this.items = response.data
        })
    },
})
