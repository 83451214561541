var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ACard',[_c('template',{slot:"top"},[_c('v-app-bar',{attrs:{"dense":"","flat":"","color":"accentWhite text-white"}},[_c('v-toolbar-title',{staticClass:"title pl-0"},[_vm._v("Start A Revision or ROV")])],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.request),expression:"!request"}]},[_vm._v(" I want to "),(
                (_vm.$ascent.featureEnabled('orderScreen.actions.clientShowRevisionRequestOnCompleted', true) &&
                    _vm.order.status == 'Completed') ||
                _vm.order.status != 'Completed'
            )?_c('span',[_c('v-btn',{staticClass:"mx-2",staticStyle:{"font-weight":"bold"},attrs:{"small":"","depressed":""},on:{"click":function($event){_vm.request = true}}},[_vm._v(" Start Revision Request ")]),_vm._v(" or ")],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",staticStyle:{"font-weight":"bold"},attrs:{"href":_vm.reconsiderationURL,"target":"_blank","small":"","depressed":""}},[_vm._v(" Start Reconsideration of Value ")]),(
                _vm.$ascent.canDo('Work') &&
                _vm.$ascent.canDo('Order') &&
                !_vm.$ascent.featureEnabled('preventClientsFromOrderingAdditionalProducts') &&
                _vm.directOrder
            )?[_vm._v(" or "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",staticStyle:{"font-weight":"bold"},attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Order Additional Product ")])]}}],null,false,362976415)},[_c('v-list',[(_vm.orderAdditionalProducts === null || _vm.orderAdditionalProducts.includes('final'))?_c('v-list-item',{attrs:{"href":'/client/order/' + _vm.orderID + '?product=final'}},[_c('v-list-item-title',[_vm._v("Final Inspection (1004D Final Inspection)")])],1):_vm._e(),(_vm.orderAdditionalProducts === null || _vm.orderAdditionalProducts.includes('recert'))?_c('v-list-item',{attrs:{"href":'/client/order/' + _vm.orderID + '?product=recert'}},[_c('v-list-item-title',[_vm._v("Recertification of Value/1004D Appraisal Update")])],1):_vm._e(),(_vm.orderAdditionalProducts === null || _vm.orderAdditionalProducts.includes('product'))?_c('v-list-item',{attrs:{"href":'/client/order/' + _vm.orderID}},[_c('v-list-item-title',[_vm._v("Order Additional Product")])],1):_vm._e()],1)],1)]:_vm._e()],2),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
            var handleSubmit = ref.handleSubmit;
return [(_vm.request)?_c('v-card-text',[_c('p',{staticClass:"small"},[_vm._v("Add requests for revision below.")]),_c('a-editor',{attrs:{"rules":"required","uuid":"RevisionEditor","label":"Revision Requests","name":"revision request text","outlined":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1):_vm._e(),(_vm.request)?_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.submitRevision)}}},[_vm._v(" Start Revision Request ")]),_c('v-spacer')],1):_vm._e()]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }