<template>
    <v-dialog v-model="dialog" max-width="600px">
        <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-card>
                <v-form>
                    <v-card-title>
                        <span class="headline">Cancel Card Payment</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-row>
                                <v-col cols="12">
                                    Please confirm you want to cancel this card payment. This action cannot be undone
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-alert v-show="error != null" color="error">
                                        {{ error }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" text @click="dialog = false">Close</v-btn>
                        <v-btn color="error" depressed :loading="loading" :disabled="invalid" @click="cancelPayment">
                            Cancel Card Payment
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
function reset() {
    return {
        dialog: false,
        payment: null,
        amount: null,
        loading: null,
        error: null,
    }
}
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'

export default {
    data() {
        return reset()
    },
    components: {
        ValidationObserver,
    },
    methods: {
        open(item) {
            this.payment = item
            this.amount = item.amount
            this.dialog = true
        },
        cancelPayment() {
            this.error = null
            this.loading = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/payment/' + this.payment.id + '/cancel')
                .then((response) => {
                    if (response.data.result === 'error') {
                        this.error = response.data.message
                        this.loading = false
                        return
                    }
                    refreshOrder().then(() => {
                        showSnackbar('Card payment cancelled successfully')
                        Object.assign(this.$data, reset())
                    })
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>
