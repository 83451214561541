






















































import AscentHelper from '@/helpers/ascent-helper'
import FeeTable from '@/pages/Client/CreateOrderForm/FeeTable.vue'
import { getOrderAllocation } from '@/pages/Client/GetOrderAllocation'
import { GET_FEES, newOrder, setFees } from '@/pages/Client/NewOrderMethods'
import { Allocation } from '@/pages/Shared/Vendors/AssignmentRules/Priority/Partials/AssignmentClass'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { orderProducts } from '@/plugins/order/OrderProducts'
import { Appraiser, ProductForm } from '@/types'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import axios from 'axios'
import { showError } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { FeeTable },
    props: {
        label: {
            type: String,
            default: 'AMC',
        },
        returnObject: {
            type: Boolean,
            default: true,
        },
        checkAllocationRules: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const loading = ref(false)
        const showAllocationRulesOption = ref(props.checkAllocationRules)
        const assignOutsideOfAllocation = ref(!props.checkAllocationRules)
        const withAllocation = ref([])
        const withoutAllocation = ref([])
        const currentOrder = ref()
        const primaryAllocation = ref()
        const selectedVendor = ref()

        if (newOrder.value) currentOrder.value = order.value
        if (order.value) currentOrder.value = order.value

        const getAllocationFees = async (vendorId = null) => {
            if (selectedVendor.value !== null && vendorId === selectedVendor.value) {
                selectedVendor.value = null
                emit('set-selected-appraiser', null)
                return
            }
            selectedVendor.value = selectedVendor.value === null ? primaryAllocation.value.id : vendorId
            emit('set-selected-appraiser', selectedVendor.value)

            if (!order.value) {
                await GET_FEES()
                return
            }

            const forms = {
                loan: orderProducts.value.find((product: ProductForm) => product.formType === 'Loan Type')?.product_id,
                main: orderProducts.value.find((product: ProductForm) => product.formType === 'Main Product')
                    ?.product_id,
                addons: orderProducts.value
                    .filter((product: ProductForm) => product.formType === 'Add On')
                    .map((product: ProductForm) => product.product_id),
            }
            await $axios
                .post('/v1/fees/lookup', {
                    forms: forms,
                    address: currentOrder.value?.address,
                    lender_id: currentOrder.value?.lender_id,
                    appraiser_id: vendorId ?? selectedVendor.value ?? currentOrder.value?.appraiser_id,
                })
                .then((response) => {
                    if (response.data.status && response.data.status === 'error') {
                        showError(response.data.message)
                        return
                    }
                    setFees(response.data)
                })
                .catch((error) => {
                    if (error.response?.data?.errors?.zipcode) {
                        showError(error.response.data.errors.zipcode[0])
                        return
                    }
                    if (error.response?.data?.message) {
                        showError(error.response.data.message)
                        return
                    }
                    showError('Oops, something went wrong!')
                })
        }

        const setWithAllocation = async () => {
            await getOrderAllocation().then((data) => (withAllocation.value = data))
            primaryAllocation.value = withAllocation.value.find(
                (allocation: Allocation) => allocation?.allocation === 'Primary',
            )
            if (!withAllocation.value || withAllocation.value.length == 0) {
                showAllocationRulesOption.value = false
                assignOutsideOfAllocation.value = true
            }
        }

        const setWithoutAllocation = async () => {
            axios
                .post('/v1/search', {
                    val: '',
                    type: 'amcs',
                    object: true,
                    limit: 250,
                })
                .then(({ data }) => {
                    data = data.filter((newData: Appraiser, index: number, array: Appraiser[]) => {
                        return (
                            index ===
                            array.findIndex(
                                (elm: Appraiser) => elm.company === newData.company && elm.email === newData.email,
                            )
                        )
                    })
                    withoutAllocation.value = data
                        .map((el: Appraiser) => {
                            return { ...el, disabled: el.amc_status?.amc_appraiser_status !== 'Active' }
                        })
                        .sort((a: { name: string }, b: { name: string }) => {
                            return a.name < b.name || a.name == null ? -1 : 1
                        })
                })
        }

        const amcNamesAndStatus = (amc: Appraiser) => {
            if (amc.amc_status?.amc_appraiser_status === 'Active') return amc.name
            return amc.name + ' (' + amc.amc_status?.amc_appraiser_status + ')'
        }

        onBeforeMount(async () => {
            loading.value = true
            await setWithoutAllocation()
            if (!AscentHelper.customerFeatureEnabled('orderScreen.useAmcReassignAllocationRules')) {
                showAllocationRulesOption.value = false
                assignOutsideOfAllocation.value = true
                loading.value = false
                return
            }
            if (props.checkAllocationRules && withAllocation.value.length <= 0) await setWithAllocation()
            if (withAllocation.value.length > 0) await getAllocationFees()
            loading.value = false
        })

        return {
            loading,
            showAllocationRulesOption,
            assignOutsideOfAllocation,
            withAllocation,
            withoutAllocation,
            currentOrder,
            primaryAllocation,
            selectedVendor,
            getAllocationFees,
            amcNamesAndStatus,
        }
    },
})
