









































import { defineComponent, ref } from '@vue/composition-api'
import { AdvSearchArray, updateSearchTable, saveTableFilters } from '@/components/OrderTable/Classes/OrderTable'
import { openSaveFilter } from '@/pages/AMC/Settings/SearchPartials/SaveFilterDialog.vue'
export const keyArray = ref([] as string[])
import { clearSearchFilters } from '@/pages/AMC/Settings/AdvancedSearch.vue'
import { facetValue } from '@/pages/AMC/Settings/FacetSearch.vue'
import SaveFilterDialog from '@/pages/AMC/Settings/SearchPartials/SaveFilterDialog.vue'

export const valueArray = ref([] as string[])

export const filterItems = (value: string[], array: {}) => {
    if (!value) return
    keyArray.value = keyArray.value.concat(value)
    let merged = [] as string[]
    merged = Object.values(array).concat.apply([], Object.values(array)) as string[]

    keyArray.value = merged
    valueArray.value = []
    merged.map((elem) => {
        const newString = elem.split('=')

        if (newString[0] == 'appraiser_id') {
            facetValue.value = keyArray.value
            return
        }

        valueArray.value.push(newString[1])
        facetValue.value = keyArray.value
    })
}

export default defineComponent({
    components: {
        SaveFilterDialog,
    },
    setup() {
        const removeTag = (value: string, index: number) => {
            const sortArray = new Set(keyArray.value)
            const finalSorted = sortArray.values()
            keyArray.value = Array.from(finalSorted)
            keyArray.value.splice(index, 1)
            valueArray.value.splice(valueArray.value.indexOf(value), 1)
            AdvSearchArray.value.filter = Object.assign(keyArray.value)
            facetValue.value = keyArray.value
            updateSearchTable()
        }

        return {
            filterItems,
            clearSearchFilters,
            AdvSearchArray,
            keyArray,
            valueArray,
            removeTag,
            saveTableFilters,
            openSaveFilter,
        }
    },
})
