import { RouteConfig } from 'vue-router'

//
//
// const CompleteSnapshot = () => import( './../../components/snapshot/CompleteSnapshot')
// const SnapshotQuestionnaire = () => import( './../../components/appraisal/public/SnapshotQuestionnaire')
//
//
//
// const DwollaSignup = () => import( './../../components/tokenized/DwollaSignup')
// const DwollaOwners = () => import( './../../components/tokenized/DwollaOwners')
// const DwollaAccount = () => import( './../../components/tokenized/DwollaAccount')
//
//
const routes: Array<RouteConfig> = [
    {
        path: '/link-expired',
        name: 'Link Expired',
        component: () => import('@/pages/Client/MagicLinkFailedLoginPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },

    {
        path: '/comp-cleanup',
        name: 'Comp Cleanup',
        component: () => import('@/pages/Office/CompCleanup/CompCleanup.vue'),
        meta: { requiresAuth: false, showNav: false },
    },

    {
        path: '/browser-download',
        name: 'Browser Download',
        component: () => import('@/pages/Public/Ascent/BrowserDownloadPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },

    // need to finish
    {
        path: '/external/order/new/',
        name: 'External Order',
        component: () => import('@/pages/Public/Order/ExternalOrderPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/external/order/new/:token',
        name: 'External New Order',
        component: () => import('@/pages/Public/Order/ExternalOrderPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/reports/daily/:token',
        name: 'Daily Orders',
        component: () => import('@/pages/Public/EmailReports/DailyNewOrdersPage.vue'),
        meta: { requiresAuth: false },
    },

    {
        path: '/quote/:token',
        name: 'Appraiser Quote',
        component: () => import('@/pages/Public/Appraiser/AppraiserOrderQuotePage.vue'),
        meta: { requiresAuth: false },
    },

    {
        path: '/appraiser/invite/:token',
        name: 'Appraiser Registration',
        component: () => import('@/pages/Public/Appraiser/AppraiserRegistration/AppraiserRegistrationPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/amc/invite/:token',
        name: 'Amc Registration',
        component: () => import('@/pages/Public/Appraiser/AmcRegistration/AmcRegistrationPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/agent/invite/:token',
        name: 'Agent Registration',
        component: () => import('@/pages/Public/Appraiser/AgentRegistration/AgentRegistrationPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/invite-expired',
        name: 'Invite Expired',
        component: () => import('@/pages/Public/PublicInviteExpired.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/office/invite/:token',
        name: 'Appraiser Office Registration',
        component: () => import('@/pages/Public/Appraiser/OfficeRegistration/OfficeRegistrationPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/appraisal/reconsideration/:order/:token',
        name: 'Reconsideration',
        component: () => import('@/pages/Public/Appraisal/AppraisalReconsiderationPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/appraisal/borrower/:order/:token',
        name: 'Borrower Landing',
        component: () => import('@/pages/Public/Appraisal/AppraisalReviewPage.vue'),
        meta: { requiresAuth: false, type: 'borrower' },
    },
    {
        path: '/appraisal/lender/:order/:token',
        name: 'Lender Landing',
        component: () => import('@/pages/Public/Appraisal/AppraisalReviewPage.vue'),
        meta: { requiresAuth: false, type: 'lender' },
    },
    {
        path: '/appraisal/quality/:order/:token',
        name: 'Lender Landing Quality',
        component: () => import('@/pages/Public/Appraisal/AppraisalReviewPage.vue'),
        meta: { requiresAuth: false, type: 'lender' },
    },
    {
        path: '/appraisal/payment/:token',
        name: 'Payment',
        component: () => import('@/pages/Public/Appraisal/AppraisalPaymentPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/appraisal/payment/:token/:paymentToken',
        name: 'Payment Token',
        component: () => import('@/pages/Public/Appraisal/AppraisalPaymentPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/appraisal/payment/:token/:paymentToken/collect',
        name: 'Payment Collect Token',
        component: () => import('@/pages/Public/Appraisal/AppraisalPaymentCollectionPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/payment-collected',
        name: 'Payment Collected Page',
        component: () => import('@/pages/Public/Appraisal/Partials/PaymentCollectedPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/payment-link-expired',
        name: 'Payment Link Expired Page',
        component: () => import('@/pages/Public/Appraisal/Partials/PaymentLinkExpiredPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/appraisal/consent/:order/:token',
        name: 'Borrower Consent',
        component: () => import('@/pages/Public/Appraisal/AppraisalConsentPage.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/avm/order/:token',
        name: 'AVM Order',
        component: () => import('@/pages/Public/Veros/AVMOrderPage.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/auth-code/:token',
        name: 'Invoice Authorization',
        component: () => import('@/pages/Public/InvoiceAuthorization/InvoiceAuthorizationChangePage.vue'),
        meta: {
            showNav: false,
            requiresAuth: false,
            title: 'Authorization Code',
        },
    },
    {
        path: '/broker-registration/:token',
        name: 'Broker Registration',
        component: () => import('@/pages/Public/BrokerPortal/BrokerPortalSignup.vue'),
        meta: { requiresAuth: false, showNav: false, type: 'brokerSignup' },
    },
    {
        path: '/insurance/dashboard',
        name: 'Insurance Dashboard',
        component: () => import('@/pages/Public/Appraisal/AppraisalInsuranceGetNextPage.vue'),
        meta: { requiresAuth: true, showNav: false, userType: ['insurance'] },
    },
]

export default routes
