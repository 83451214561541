





























import { defineComponent } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    name: 'DetailWithLabelComponent',
    props: {
        value: [String, Number, Boolean, Array],
        label: String,
        link: Boolean,
        format: {
            type: String,
            default: '',
        },
        allowClick: Boolean,
        sticky: Boolean,
        labelClass: {
            type: String,
            default: '',
        },
        valueClass: {
            type: String,
            default: '',
        },
    },
    methods: {
        formatValue(value: string | number | boolean) {
            switch (this.format) {
                case 'percent':
                    return AscentHelper.formatPercent(value)
                case 'currency':
                    return AscentHelper.currency(value, '$0,0')
                default:
                    return value
            }
        },
    },
    computed: {
        itemStyles: () => {
            return ''
        },
        subtitleStyles: () => {
            return ''
        },
        titleStyles: (props: { link: boolean; value: string | number }) => {
            let styles = 'text-capitalize'
            if (['Unavailable'].includes(props.value as string)) {
                styles += ' text--disabled'
                return styles
            }
            if (['Not Assigned'].includes(props.value as string)) {
                return styles
            }
            if (props.link) styles += ' detail-link-2 font-weight-bold'
            return styles
        },
        clickable: (props: { allowClick: boolean; value: string | number }) => {
            if (props.value === 'Unavailable') return false
            return props.allowClick
        },
    },
})
