










































































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import { licensedAppraiserProhibited } from '@/pages/AMC/Lenders/Lender'
import { licensedAppraiserBranchProhibited } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/Branch'
import { appraiserTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'

export const filters = ref([] as string[])
export const currentTag = ref('')

/**
 * @name AssignTableFilters
 * Filters for the Assign Table for user to filter appraisers
 *
 * @SetupData filters - array of filters for the assign table
 * @SetupComputed prohibitLicensed - if license is prohibited returns true
 *
 * @onMounted - if prohibitLicensed is true, pushed Certified General and Certified Residential to filters
 */

export default defineComponent({
    setup() {
        const prohibitLicensed = computed(() => {
            if (licensedAppraiserProhibited.value || licensedAppraiserBranchProhibited.value) return true
            return false
        })

        onMounted(() => {
            if (prohibitLicensed.value) {
                filters.value.push('Certified General')
                filters.value.push('Certified Residential')
            }
        })

        return {
            filters,
            prohibitLicensed,
            appraiserTags,
            currentTag,
        }
    },
})
