



























































import { ValidationObserver } from 'vee-validate'
import { computed, defineComponent, ref } from '@vue/composition-api'
import AEditor from '@/global/Form/AEditor.vue'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { AEditor, ValidationObserver },
    props: {
        option: String,
        revisionsLength: Number,
        isAdmin: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const value = ref(props.option)
        const dialog = ref(false)
        const updated = computed(() => value.value !== props.option)
        const directiveTitle = computed(() => {
            if (!props) return 'Add a Directive'
            if (props.revisionsLength && props.revisionsLength > 0) return 'Edit Directive'
            if (props.revisionsLength === 0 && props.isAdmin) return 'Add Admin Directive'
            return 'Add a Directive'
        })
        const save = () => {
            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: props.isAdmin ? 'admin-directives' : 'directives',
                    option_value: value.value,
                })
                .then((res) => {
                    showSnackbar('Option saved successfully')
                    dialog.value = false
                    emit('directive-updated', res.data)
                })
        }
        return {
            dialog,
            save,
            value,
            updated,
            directiveTitle,
        }
    },
})
