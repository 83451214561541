import { render, staticRenderFns } from "./CustomerOrderFiles.vue?vue&type=template&id=42c232f8&scoped=true&"
import script from "./CustomerOrderFiles.vue?vue&type=script&lang=ts&"
export * from "./CustomerOrderFiles.vue?vue&type=script&lang=ts&"
import style0 from "./CustomerOrderFiles.vue?vue&type=style&index=0&id=42c232f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42c232f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCardText,VCheckbox,VChip,VCol,VDataTable,VIcon,VList,VListItem,VListItemContent,VListItemTitle,VRow,VSwitch})
