















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'DetailLinkComponent',
    props: {
        component: String,
        value: [String, Number],
        label: String,
    },
    computed: {
        itemStyles: () => {
            return ''
        },
        subtitleStyles: () => {
            return ''
        },
        titleStyles: () => {
            return 'detail-link-2 font-weight-bold'
        },
    },
})
