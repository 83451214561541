






































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { AppraiserPayment, MenuItem } from '@/types'
import { open } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderInvoiceDialog.vue'
import ClientOrderPayments from '@/components/OrderScreens/Amc/AMCActions/Payment/ClientOrderPayments.vue'
import AppraiserPayments from '@/components/OrderScreens/Amc/AMCActions/Payment/AppraiserPayments.vue'
import { order } from '@/plugins/order/Order'
import SendBorrowerPayLinkDialog from '@/components/OrderScreens/Amc/AMCActions/Payment/SendBorrowerPayLinkDialog.vue'
import AddClientPaymentDialog from '@/components/OrderScreens/Amc/AMCActions/Payment/AddClientPaymentDialog.vue'
import OrderBillingMethod from '@/components/OrderScreens/Amc/AMCActions/Payment/OrderBillingMethod.vue'
import OrderPaymentAlert from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/OrderPaymentAlert.vue'
import axios from '@/plugins/axios'
import AscentHelper from '@/helpers/ascent-helper.js'
import { role, user } from '@/plugins/User'
import AuditNotes from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AuditNotes.vue'
import SplitInvoiceSummary from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderInvoice/SplitInvoiceSummary.vue'
import TotalInvoiceSummary from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderInvoice/TotalInvoiceSummary.vue'
import PaymentLinkAction from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderInvoice/PaymentLinkAction.vue'
import CreditCardActions from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderInvoice/CreditCardActions.vue'
import OrderCreditCards from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderInvoice/OrderCreditCards.vue'
import LenderInvoiceCode from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderInvoice/LenderInvoiceCode.vue'
import OrderDisclosedFee from '@/pages/Client/CreateOrderForm/OrderDisclosedFee.vue'
import ClientFeesSummary from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderInvoice/ClientFeesSummary.vue'

interface Dialog {
    open: () => void
}

export const viewLenderCode = ref(false)
export const LenderCode = ref('')

export default defineComponent({
    name: 'OrderInvoice',
    components: {
        ClientFeesSummary,
        OrderDisclosedFee,
        LenderInvoiceCode,
        OrderCreditCards,
        CreditCardActions,
        PaymentLinkAction,
        TotalInvoiceSummary,
        SplitInvoiceSummary,
        AuditNotes,
        SendBorrowerPayLinkDialog,
        AddClientPaymentDialog,
        OrderBillingMethod,
        AppraiserPayments,
        ClientOrderPayments,
        OrderPaymentAlert,
    },
    setup() {
        const SendBorrowerPayLinkDialog = ref({} as Dialog)
        const AddClientPaymentDialog = ref({} as Dialog)
        const showClientPayments = ref(false)
        const showPayments = ref(false)

        const actions: MenuItem[] = []

        if (AscentHelper.canDo('Work')) {
            actions.push({
                label: 'Edit Forms & Fees',
                action: () => {
                    open()
                },
            })
        }

        function openDialog(type: string) {
            switch (type) {
                case 'payment-link':
                    SendBorrowerPayLinkDialog.value.open()
                    break
                case 'client-payment':
                    AddClientPaymentDialog.value.open()
                    break
            }
        }

        const hasAppraiserHold = computed(() => {
            if (!order.value) return
            if (!order.value.incremental) return
            return (
                order.value.incremental.appraiser_payment.length > 0 &&
                order.value.incremental.appraiser_payment.find((elem: AppraiserPayment) => elem.status === 'Hold') !==
                    undefined
            )
        })

        const getLenderCode = () => {
            if (!order.value) return
            viewLenderCode.value = true
            axios.get('v1/lender/' + order.value.lender_id + '/get-lender-invoice-authorizations').then(({ data }) => {
                if (data.invoice_authorizations.length > 0) {
                    return (LenderCode.value = data.invoice_authorizations[0].code)
                }
                return (LenderCode.value = 'Code Not Available')
            })
        }

        return {
            openDialog,
            hasAppraiserHold,
            SendBorrowerPayLinkDialog,
            AddClientPaymentDialog,
            showClientPayments,
            showPayments,
            actions,
            role,
            user,
            order,
            open,
            LenderCode,
            viewLenderCode,
            getLenderCode,
        }
    },
})
