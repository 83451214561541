
























































import { defineComponent, Ref, ref, UnwrapRef } from '@vue/composition-api'
import { Note } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ValidationObserver } from 'vee-validate'
import { getNotes } from '@/plugins/Notes'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'

export const newNote: Ref<undefined | Note> = ref(undefined as undefined | Note)

/**
 * @name EditNote
 * Dialog that allows amc user to edit the note that they sent to client or appraiser
 *
 * @SetupData reason - reasons for editing the note
 */

export default defineComponent({
    props: {},
    components: { ValidationObserver },
    setup() {
        const reason = ref('')

        return { reason, newNote }
    },
    data() {
        return {
            dialog: false,
            /**
             * Tracking for who edits the notes
             */
            tracking: {
                recipients: [],
            },
        }
    },
    methods: {
        /**
         * Opens dialog with the note that the user is wanting to edit
         * @param note - Note that user wants to edit
         */
        open(note: Note) {
            newNote.value = JSON.parse(JSON.stringify(note))
            this.$axios.get('/v1/note/' + note.id + '/note-tracking').then((response) => {
                if (response.data === 'null') {
                    this.tracking = {
                        recipients: [],
                    }
                    return
                }
                this.tracking = response.data
            })
            this.dialog = true
        },
        /**
         * Saves the updated note and refreshes notes after saving
         */
        saveNote() {
            if (!newNote.value) return
            getNotes(newNote.value.order_id as number)
            this.reason = ''
            showSnackbar('Note Updated Successfully ')
            this.dialog = false
        },
        /**
         * Confirms and deletes the note
         * @param note - Note in the dialog
         */
        deleteNote(note: UnwrapRef<Note | undefined>) {
            confirm('Delete Note?', 'Are you sure you want to delete this note?').then((confirm) => {
                if (confirm) {
                    if (!note) return
                    $axios.delete('/v1/note/' + note.id).then(() => {
                        getNotes(note.order_id as number)
                        showSnackbar('Note Deleted')
                    })
                }
            })
        },
    },
})
