















































import { defineComponent, ref } from '@vue/composition-api'
import { fileTypes } from '@/plugins/Data.ts'
import { File } from '@/types'

function reset() {
    return {
        added: false,
        loading: false,
        file_type: null,
        allowed: ['.pdf', '.doc', '.docx', '.xml', '.xlsx', '.xls'],
    }
}

import { ValidationObserver } from 'vee-validate'
import FileUpload from '@/components/General/FileUpload.vue'
import { user } from '@/plugins/User'
import { getFiles } from '@/plugins/Files.ts'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order.ts'

export const newFileDialog = ref(false)

/**
 * @name ClientAddOrderFileDialog
 * Dialog on Client Order Screen that allows them to upload a file and add it to the file
 *
 *
 */

export default defineComponent({
    name: 'AddOrderFileDialog',
    components: {
        ValidationObserver,
        FileUpload,
    },
    setup() {
        return {
            newFileDialog,
        }
    },
    data() {
        return reset()
    },
    computed: {
        /**
         * Url for order
         */
        url() {
            if (!order.value) return ''
            return 'order/' + order.value.id
        },
        /**
         * Filtered files that filters out the appraiser files
         */
        filteredFileTypes() {
            if (!user.value) return []
            if (user.value.type !== 'appraiser') return fileTypes
            return ['Additional']
        },
    },
    methods: {
        /**
         * Triggers the File Upload component to upload file to S3
         */
        uploadFile() {
            this.loading = true
            const fileUpload = this.$refs.fileUpload as InstanceType<typeof FileUpload>
            fileUpload.triggerUpload()
        },
        /**
         * Stops the upload and resets the files
         */
        cancel() {
            const fileUpload = this.$refs.fileUpload as InstanceType<typeof FileUpload>
            fileUpload.resetFiles()
            this.newFileDialog = false
        },
        /**
         * Saves the file path to the order. Posts to '/v1/order/' + order.value.id + '/file' with the type, path, and status of All. Refreshes order after set.
         * @param {File} file - file that has been uploaded to S3.
         */
        saveFile(file: File) {
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/file', {
                    type: this.file_type,
                    path: file.path,
                    status: 'ALL',
                })
                .then(() => {
                    if (!order.value) return
                    Object.assign(this.$data, reset())
                    this.newFileDialog = false
                    getFiles(order.value.id)
                    const fileUpload = this.$refs.fileUpload as InstanceType<typeof FileUpload>
                    fileUpload.resetFiles()
                    showSnackbar('File Uploaded Successfully')
                })
        },
    },
})
