








































































































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import LenderSelect from '@/components/General/AutoCompletes/LenderSelect.vue'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'
import { Lender } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

function reset() {
    return {
        dialog: false,
        companySearch: null,
        companies: [],
        loading: false,
        name: null,
        status: 'Pending',
        broker: false,
        brokerOwnLender: false,
        brokerLendersSelected: [],
        address: {
            street: null,
            sub_street: null,
            city: null,
            state: null,
            zip: null,
        },
    }
}

const company = ref(null as Lender | null)

export default defineComponent({
    setup() {
        return {
            company,
        }
    },
    components: { LenderSelect, ValidationObserver, StateSelect },
    data() {
        return reset()
    },
    computed: {
        belongsToLender() {
            if (!this.broker) {
                return true
            }
            return this.brokerOwnLender
        },
    },
    mounted() {
        if (this.$route.meta.type === 'Broker') {
            this.broker = true
        }
    },
    methods: {
        saveChanges() {
            this.loading = true
            this.$axios
                .post('/v1/branch', {
                    address: this.address,
                    name: this.name,
                    lender_id: company.value?.id,
                    status: this.status,
                    broker: this.broker,
                    brokerLenders: this.brokerLendersSelected,
                })
                .then((res) => {
                    Object.assign(this.$data, reset())
                    company.value = null
                    const form = this.$refs.form as InstanceType<typeof ValidationObserver>
                    form.reset()
                    showSnackbar('Branch added successfully')
                    this.$emit('branch-added', res.data)
                    this.$emit('dialog-closed')
                })
        },
        closeDialog() {
            this.$emit('dialog-closed')
            this.dialog = false
        },
    },
})
