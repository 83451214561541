import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { computed, Ref, ref } from '@vue/composition-api'
import { AirDNA } from '@/types/AirDNA'
import { DynamicPage } from '@/types/SimpleDynamicForm'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { KitPropertyType } from '@/types/KitSubject'
import { get } from 'lodash'

interface Sig {
    sig: string
    name: string
    company: string
    address: string
    number: string
    state: string
    expiration: string
    agree?: string
}

interface KitConfig {
    pages: DynamicPage[]
}

interface KitValue {
    '30DayAsIs': number
    LandValue: number
    CurrentValue: number
    '90DayAsIsList': number
    '90DayAsIsSale': number
    AdditionalComments: string
}

interface KitReportDetails {
    airdna?: AirDNA
    signature: Sig
    allComps: { [status in 'closed' | 'listing']: KitPropertyType[] }
    comps: { [status in 'sold' | 'pending']?: { [index: string]: KitPropertyType } }
    subject: KitPropertyType
    value: KitValue
    images: Record<string, string>
}

export interface KitReport {
    id: number
    order_id: number
    status: 'Open' | 'Completed'
    type: string
    details: KitReportDetails
    config: KitConfig
    report: string
    file: string
    signed_url: string
    signature: string
    images: Record<string, string>
}

export const report: Ref<undefined | KitReport> = ref(undefined)
export const page: Ref<number> = ref(0)

export const isCompletable = computed(() => {
    if (!report.value) return false
    return report.value.config.pages.every((elem) => elem.complete)
})

export const getKitReport = () => {
    $axios.get('/v1/kit/order/' + order.value?.id + '/kitReport').then(({ data }) => {
        report.value = data as KitReport
    })
}

export const getKitReportDetails = async (component: string): Promise<unknown> => {
    if (!report.value) {
        return false
    }

    return $axios
        .get('/v1/kit/order/' + order.value?.id + '/detail/' + component)
        .then(({ data }) => {
            return data
        })
        .catch((error) => {
            const message = error.response?.data?.message ?? error
            showError(message)
        })
}

export const saveKitReport = async (snack = false, page: number, path: string, complete = false): Promise<boolean> => {
    if (!report.value) {
        return false
    }

    const reportData = get(report.value.details, path, {})
    const saved = ref(false)

    await $axios
        .patch('/v1/kit/kitReport/' + report.value.id, {
            complete: complete,
            page: page,
            path: path,
            data: reportData,
        })
        .then((): void => {
            saved.value = true
            if (snack) showSnackbar('Report Progress Saved')
        })
        .catch((error): void => {
            saved.value = false
            const message = error.response?.data?.message ?? error
            if (snack) showError(message)
        })

    return saved.value
}

export const getCurrentValues = (component: string) => {
    $axios
        .post('/v1/kitReport/' + report.value?.id + '/detail-mapping/' + component)
        .then(({ data }) => {
            report.value = data as KitReport
        })
        .catch((error) => {
            const message = error.response?.data?.message ?? 'An error occurred while processing'
            showError(message)
        })
}

export const generatingPreview = ref(false)
export const generatePreview = () => {
    generatingPreview.value = true
    $axios
        .post('/v1/kitReport/' + report.value?.id + '/generate-report')
        .then(({ data }) => {
            generatingPreview.value = false
            report.value = data as KitReport
            report.value.config.pages[page.value].complete = true
        })
        .catch((error) => {
            const message = error.response?.data?.message ?? 'An error occurred while generating the preview.'
            showError(message)
            generatingPreview.value = false
        })
}
export const completingReport = ref(false)
export const completeReport = () => {
    completingReport.value = true
    $axios
        .post('/v1/kitReport/' + report.value?.id + '/complete')
        .then(({ data }) => {
            completingReport.value = false
            report.value = data as KitReport
        })
        .catch((error) => {
            const message = error.response?.data?.message ?? 'An error occurred while completing the report.'
            showError(message)
            completingReport.value = false
        })
}
