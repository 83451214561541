












































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { ValidationObserver } from 'vee-validate'
import { GlobalTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import { Tag, TagConfig } from '@/types'

/**
 * @name 'Office Lender Client Tags'
 * Component description: Is a Lender Option for the Office Info. Will Display information on the office with the option to edit in a dialog;
 * @SetupData getTags() => will get the client tags values based off the customer id and set the correct values;
 * @SetupData saveChanges() => Will save the updated option and reset the displayed values to the correct values while closing the dialog;
 * @SetupData options: The value that is the v-model when editing and saving ;
 * @SetupData displayOptions: The value that is displayed and is reset every time a new option gets saved;
 * @SetupData dialog: The boolean value that will determine if the editing dialog is open or closed;
 * @SetupData loaded: The boolean value that will determine if the data has been loaded;
 * @SetupData clientTags: The static tags a user can set for the client tags;
 */
export default defineComponent({
    components: {
        ValidationObserver,
    },
    name: 'OfficeLenderClientTags',
    setup() {
        const loaded = ref<boolean>(false)
        const dialog = ref(false)
        const options = ref()
        const displayOptions = ref([] as Tag[])

        const clientTags = GlobalTags.value.find((e: TagConfig) => e.type === 'Client Tags')?.tags

        const getClientOfficeTags = () => {
            if (!clientTags) return
            if (!options.value) return

            displayOptions.value = clientTags?.filter((elem: Tag) => {
                if (
                    options.value.find((el: string) => {
                        return el === elem.tag
                    })
                ) {
                    return elem
                }
            })

            options.value = displayOptions.value.map((tag) => {
                return tag.tag
            })
        }

        const getTags = () => {
            axios.get(`/v1/lender/${lender.value.id}/option/office-client-tags`).then((response) => {
                options.value = response.data.option_value
                displayOptions.value = response.data.option_value
                getClientOfficeTags()
            })
        }

        const saveChanges = () => {
            displayOptions.value = options.value
            dialog.value = false
            getClientOfficeTags()
        }

        onMounted(getTags)

        return {
            options,
            loaded,
            dialog,
            lender,
            saveChanges,
            clientTags,
            displayOptions,
        }
    },
})
