

















































import { defineComponent } from '@vue/composition-api'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import { refreshOrder } from '@/plugins/order/Order'
import { specialists } from '@/plugins/Amc.ts'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getAllUsers } from '@/plugins/Amc.ts'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        saving: false,
        assignee: null,
        note: '',
    }
}

export default defineComponent({
    components: {
        ValidationObserver,
        ACardAction,
    },
    setup() {
        return { specialists, order }
    },
    data() {
        return reset()
    },
    mounted() {
        getAllUsers()
    },
    methods: {
        changeUser() {
            showSnackbar('Order account rep changed.')
            refreshOrder()
            Object.assign(this.$data, reset())
            const form = this.$refs.form as InstanceType<typeof ValidationObserver>
            form.reset()
            this.$emit('closeDetailItem')
        },
    },
})
