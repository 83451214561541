import { ref } from '@vue/composition-api'
import { Lender } from '@/types'
import axios from '@/plugins/axios'
import { DataUtil } from '@syncfusion/ej2-data'
import { Linter } from 'eslint'
import ParserOptions = Linter.ParserOptions

export const unpaidOrders = ref([])
export const paidOrders = ref([])
export const search = ref({
    card: false,
    status: 'Complete',
    type: '',
    payment_status: 'Not Paid',
    lender: undefined as Lender | undefined,
    start_date: undefined as string | undefined,
    end_date: undefined as string | undefined,
})

export const paidSearch = ref({
    status: null,
    type: null,
    lender: null,
    start_date: null,
    end_date: null,
})

export const getBillingOrders = () => {
    return new Promise(() => {
        axios.post('/v1/billing/orders', search.value).then((response) => {
            unpaidOrders.value = (DataUtil.parse as ParserOptions).parseJson(response.data)
        })
    })
}

export const getPaidOrders = () => {
    return new Promise(() => {
        axios.post('/v1/billing/orders/paid', paidSearch.value).then((response) => {
            paidOrders.value = (DataUtil.parse as ParserOptions).parseJson(response.data)
        })
    })
}
