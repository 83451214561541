
























































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import FileUpload from '@/components/General/FileUpload.vue'
import { lender, lenderId } from '@/components/General/LenderOptions/LenderOptions'
import $axios from 'axios'
import { File } from '@/types/files'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    name: 'LenderMsaUpload',
    components: { FileUpload },
    setup() {
        const currentFiles = ref([] as File[])
        const filePath = ref('')

        const getFiles = () => {
            $axios.get('/v1/lender/' + lender.value.id + '/files').then((res) => {
                if (!res.data || !res.data) return
                currentFiles.value = res.data
            })
        }
        onBeforeMount(getFiles)
        const showFile = (file: File) => {
            filePath.value = file.path
        }
        const download = (file: File) => {
            $axios.get('/v1/lender/' + lender.value.id + '/files/' + file.id).then((response) => {
                window.open(response.data, '_blank')
            })
        }

        const deleteFile = (file: File) => {
            confirm('Delete File', 'Are you sure you want to delete this file?').then((confirm) => {
                if (confirm) {
                    $axios
                        .delete('/v1/lender/' + lender.value.id + '/files/' + file.id)
                        .then(() => {
                            showSnackbar('Successfully deleted.')
                            getFiles()
                        })
                        .catch(() => {
                            showError('Unsuccessfully deleted.')
                        })
                }
            })
        }

        const successfulFileUpload = () => {
            showSnackbar('MSA Successfully Uploaded')
            getFiles()
        }

        return { lenderId, currentFiles, showFile, filePath, getFiles, deleteFile, successfulFileUpload, download }
    },
})
