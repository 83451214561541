























































import { defineComponent, ref } from '@vue/composition-api'
import { reviewNotes, userRemoveNote } from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
    components: {
        ValidationObserver,
    },
    setup(_, { refs }) {
        const reviewNote = ref('')
        const userAddNote = () => {
            reviewNotes.value.push({ note: reviewNote.value.replace(/<[^>]*>?/gm, ''), removable: true })
            reviewNote.value = ''
            const form = refs.form as InstanceType<typeof ValidationObserver>
            form.reset()
        }

        return {
            userRemoveNote,
            reviewNotes,
            reviewNote,
            userAddNote,
        }
    },
})
