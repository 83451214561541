



































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ValidationObserver } from 'vee-validate'

/**
 * @name 'Office Lender Documents'
 * Component description: Is a Lender Option for the Office Documents. Will Display the Current Document with the option to edit;
 * @SetupData onMounted() => will get the office document value for the customer id and set the correct values;
 * @SetupData save() => Will save the updated option and reset the displayed values to the correct values while closing the dialog;
 * @SetupData document: The value that is the v-model when editing and saving ;
 * @SetupData option: The value that is displayed and is reset every time a new option gets saved;
 * @SetupData dialog: The boolean value that will determine if the editing dialog is open or closed;
 */
export default defineComponent({
    components: {
        ValidationObserver,
    },
    name: 'OfficeLenderDocuments',
    setup() {
        const dialog = ref(false)
        const document = ref()
        const option = ref('')

        const save = () => {
            option.value = document.value
            showSnackbar('Option saved successfully')
            dialog.value = false
        }

        onMounted(() => {
            $axios.get('/v1/lender/' + lender.value.id + '/option/office-documents').then((response) => {
                if (response.data.option_value) {
                    document.value = response.data.option_value
                    option.value = response.data.option_value
                }
            })
        })

        return {
            save,
            dialog,
            document,
            option,
            lender,
        }
    },
})
