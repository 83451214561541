<template>
    <v-btn text icon class="ma-0" :ripple="false" @click="toggleNotificationDrawer">
        <v-badge overlap :value="pending.length > 0" color="warning" offset-x="12" offset-y="20">
            <template v-slot:badge>
                <span>{{ pending.length }}</span>
            </template>

            <v-avatar size="40">
                <v-icon color="accentBlack" size="20">$vuetify.icons.exclamationTriangle</v-icon>
            </v-avatar>
        </v-badge>
    </v-btn>
</template>

<script>
import {
    approvals,
    getPendingApprovals,
    getResolvedApprovals,
    setShowApprovalNotifications,
} from '@/pages/System/Notifications/NotificationPartials/Approvals'
import { showNotifications, setShowNotifications } from '@/pages/System/Notifications/notifications'
import { handleConnection } from '@/plugins/Channels'
import { showCustomSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { user } from '@/plugins/User.ts'

export default {
    props: {
        isTeamLead: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        pending() {
            return approvals.value.pending
        },
    },
    mounted() {
        if (!user.value.ascent) getPendingApprovals()
        if (!this.isTeamLead) getResolvedApprovals()
        this.registerEvents()
    },
    methods: {
        registerEvents() {
            if (!user.value.ascent) {
                handleConnection({
                    channelRef: 'approvals',
                    id: 1,
                }).then((connector) => {
                    connector
                        .listen('.approval-created', (event) => {
                            getPendingApprovals()
                            getResolvedApprovals()

                            showCustomSnackbar({
                                message: event.approval.details,
                                color: 'warning',
                                mode: null,
                                x: 'center',
                                y: 'bottom',
                            })
                        })
                        .listen('.approval-updated', () => {
                            getPendingApprovals()
                            getResolvedApprovals()
                        })
                })
                handleConnection({
                    channelRef: 'escalationApprovals',
                    id: user.value.id,
                }).then((connector) => {
                    connector.listen('.escalation-approval-created', () => {
                        getPendingApprovals()
                    })
                })
            }
        },
        toggleNotificationDrawer() {
            if (showNotifications) {
                setShowNotifications() // closes regular notifications if open
            }
            setShowApprovalNotifications()
        },
    },
}
</script>
