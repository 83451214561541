




































import { defineComponent, onMounted } from '@vue/composition-api'
import { appraiserSelection, getAppraisers } from '@/components/OrderTable/Classes/TableFilters'
import { viewFilters } from '@/components/OrderTable/Classes/OrderTable'

/**
 * @name AppraiserSelectFilter
 * Dialog on Order Table that allows user to select different Appraisers to see their orders on the Order Table
 *
 */

export default defineComponent({
    setup() {
        const resetSelection = () => {
            viewFilters.value.appraiserAccess = [-1]
        }

        const selectAllWhenNoneSelected = (state: number) => {
            if (viewFilters.value.appraiserAccess.length === 0 && state !== -1) viewFilters.value.appraiserAccess = [-1]
            if (state === -1) viewFilters.value.appraiserAccess = []
        }

        onMounted(() => {
            getAppraisers()
        })

        return {
            resetSelection,
            selectAllWhenNoneSelected,
            appraiserSelection,
            viewFilters,
        }
    },
})
