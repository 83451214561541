










































import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ref, SetupContext, PropOptions } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { order, refreshOrder } from '@/plugins/order/Order'

export const addCondition = async (newCondition: string, controllable: number) => {
    if (!order.value) return
    return await $axios
        .post('/v1/order/' + order.value.id + '/condition', {
            condition: newCondition,
            controllable: controllable,
        })
        .then(({ data }) => {
            order.value?.incremental?.conditions.push(data)
            refreshOrder()
        })
}

/**
 * @name AddCondition
 * Adds conditions to the order for the appraiser to look at
 *
 * @SetupData condition - condition that is being added to order
 * @SetupData addCondition - posts to backend and adds condition to the order
 * @SetupData addLocalCondition - adds the condition locally to this component
 *
 */
export default {
    components: {
        ValidationObserver,
    },
    setup(_: PropOptions, { refs }: SetupContext) {
        const loading = ref(false)
        const condition = ref('')
        const controllable = ref(0)
        const addLocalCondition = () => {
            loading.value = true
            addCondition(condition.value, controllable.value).then(() => {
                loading.value = false
                condition.value = ''
                showSnackbar('Condition added successfully')
            })

            const form = refs.form as InstanceType<typeof ValidationObserver>
            form.reset()
        }
        return {
            loading,
            condition,
            addLocalCondition,
            controllable,
            order,
        }
    },
}
