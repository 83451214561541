







import { defineComponent, PropType } from '@vue/composition-api'
import OrderAmountPaidChip from '@/components/General/OrderAmountPaidChip.vue'

export default defineComponent({
    props: {
        status: {
            type: String as PropType<'' | 'Not Paid' | 'Partial'>,
            default: () => '',
        },
        renderOrderAmount: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        OrderAmountPaidChip,
    },
    setup(props) {
        const colorsByStatus = {
            '': '',
            'Not Paid': 'error',
            Partial: 'amber',
        }

        return {
            color: colorsByStatus[props.status] ?? 'success',
        }
    },
})
