














import { defineComponent } from '@vue/composition-api'
import { openAppraiserOrdersDialog } from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/ViewAppraiserOrdersDialog.vue'
import { order } from '@/plugins/order/Order'
export default defineComponent({
    name: 'AppraiserOtherOrders',
    setup() {
        return {
            order,
            openAppraiserOrdersDialog,
        }
    },
})
