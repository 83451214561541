






















































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import CreditCard from '@/components/General/CreditCard.vue'
import { LenderOptions, CreditCardType } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import LenderEditSavedCard from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderEditSavedCard.vue'
import Vue from 'vue/types/umd'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { LenderEditSavedCard, ValidationObserver, CreditCard },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props, { refs }) {
        const dialog = ref(false)
        const loading = ref(false)
        const creditCards = ref([] as CreditCardType[])

        if (props.option !== undefined) {
            creditCards.value = props.option.option_value as CreditCardType[]
        }

        const caughtError = () => {
            loading.value = false
        }

        const saveChanges = () => {
            dialog.value = false
            showSnackbar('Option saved successfully')
        }

        const deleteCard = (incomingCard: CreditCardType) => {
            creditCards.value = creditCards.value.filter((card) => {
                if (card.token !== incomingCard.token) {
                    return card
                }
            })

            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'lender-saved-card',
                    option_value: creditCards.value,
                })
                .then(() => {
                    showSnackbar('Card deleted successfully')
                })
        }

        const saveCard = () => {
            loading.value = true
            const card = refs.card as Vue & { addCard: () => void }
            card.addCard()
        }

        const saveLenderCard = (data: {
            card: string
            name: string
            token: string
            expMonth: string
            expYear: string
        }) => {
            const newCards = creditCards.value.concat(data)

            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'lender-saved-card',
                    option_value: newCards,
                })
                .then(() => {
                    dialog.value = false
                    loading.value = false
                    showSnackbar('Card added successfully')
                    creditCards.value = newCards
                })
        }

        return {
            deleteCard,
            dialog,
            saveChanges,
            creditCards,
            lender,
            saveCard,
            saveLenderCard,
            loading,
            caughtError,
        }
    },
})
