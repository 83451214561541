















































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import ACardAction from '@/global/ACardAction.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { User } from '@/types'
import $axios from '@/plugins/axios'

const assigners = ref([] as User[])
const note = ref('')
const assignee = ref(0)
const form = ref({} as InstanceType<typeof ValidationObserver>)

const getAssigners = (): void => {
    if (!order.value) return
    $axios.get(`/v1/order/${order.value.id}/assigner`).then(({ data }) => {
        assigners.value = data
    })
}

export default defineComponent({
    components: {
        ACardAction,
        ValidationObserver,
    },
    methods: {
        assignerChanged() {
            showSnackbar('Assigner successfully changed!')
            refreshOrder()
            note.value = ''
            assignee.value = 0
            form.value.reset()
            this.$emit('closeDetailItem')
        },
    },
    setup() {
        onMounted(() => {
            getAssigners()
        })
        return {
            note,
            assignee,
            order,
            form,
            assigners,
        }
    },
})
