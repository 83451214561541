import { render, staticRenderFns } from "./AssignTable.vue?vue&type=template&id=637722f8&scoped=true&"
import script from "./AssignTable.vue?vue&type=script&lang=ts&"
export * from "./AssignTable.vue?vue&type=script&lang=ts&"
import style0 from "./AssignTable.vue?vue&type=style&index=0&id=637722f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637722f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCol,VContainer,VDataTable,VRow})
