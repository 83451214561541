import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/desk/dashboard',
        name: 'Desk Dashboard',
        component: () => import('@/components/General/Dashboard/DashboardPage.vue'),
        meta: { requiresAuth: true, userType: ['desk'], dashboard: true },
    },
    {
        path: '/client/mortgagedirector/new/:user/:id',
        name: 'mortgageDirectorOrderNew',
        component: () => import('@/pages/Client/MortgageDirector/MortgageDirectorOrderFormPage.vue'),
        meta: {
            requiresAuth: false,
            userType: ['amc', 'desk'],
            title: 'Mortgage Director Order',
        },
    },
    {
        path: '/client/mortgagedirector/order/:user/:id',
        name: 'mortgageDirectorOrder',
        component: () => import('@/pages/Client/MortgageDirector/MortgageDirectorOrderPage.vue'),
        meta: {
            requiresAuth: false,
            userType: ['amc', 'desk'],
            title: 'Mortgage Director Order',
        },
    },
    {
        path: '/client/mortgagedirector/dashboard/:user',
        name: 'mortgageDirectorDashboard',
        component: () => import('@/pages/Client/MortgageDirector/MortgageDirectorDashboardPage.vue'),
        meta: {
            requiresAuth: false,
            userType: ['amc', 'desk', 'client'],
            title: 'Mortgage Director Dashboard',
        },
    },
]

export default routes
