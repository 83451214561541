































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { User } from '@/types'
import { appraiserOptions, appraiserSettingsId } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        option: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const schedulers = ref([] as User[])

        watch(appraiserOptions, () => {
            if (props.option && props.option.scheduler) {
                appraiserOptions.value.scheduler = props.option.scheduler
            }
        })

        const grabSchedulers = () => {
            $axios.get('/v1/customer/user/').then((res) => {
                schedulers.value = res.data.filter(
                    (elem: User) =>
                        elem.permissions && elem.permissions.find((permission) => permission === 'Schedules Orders'),
                )
            })
        }

        const addScheduler = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'scheduler',
                    option_value: appraiserOptions.value.scheduler,
                })
                .then(() => {
                    showSnackbar('Option updated successfully')
                })
        }

        const removeScheduler = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'scheduler',
                    option_value: null,
                })
                .then(() => {
                    emit('options-updated')
                    showSnackbar('Scheduler removed successfully')
                })
        }

        onMounted(() => {
            grabSchedulers()
        })

        return {
            schedulers,
            addScheduler,
            appraiserOptions,
            removeScheduler,
        }
    },
})
