import { render, staticRenderFns } from "./AddOrderDirective.vue?vue&type=template&id=566e3856&"
import script from "./AddOrderDirective.vue?vue&type=script&lang=ts&"
export * from "./AddOrderDirective.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VCard,VCardText,VCardTitle,VDialog,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VSkeletonLoader})
