




















import { computed, defineComponent } from '@vue/composition-api'
import CustomerRequestTDCX from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerRequestTDCX.vue'
import CustomerPreload from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerPreload.vue'
import GeneratePdcoFile from '@/pages/AlternativeProducts/PDC/GeneratePdcoFile.vue'
import CustomerAccessJaroKit from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerAccessJaroKit.vue'
import CustomerAccessJaroKitBeta from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerAccessJaroKitBeta.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    components: {
        CustomerRequestTDCX,
        CustomerPreload,
        GeneratePdcoFile,
        CustomerAccessJaroKit,
        CustomerAccessJaroKitBeta,
    },
    setup() {
        const showBetaLink = computed(() => {
            if (
                !order.value ||
                !order.value?.incremental ||
                !order.value?.incremental?.report_uuid ||
                !order.value?.incremental?.appraiser ||
                !order.value?.incremental?.appraiser?.customer_id ||
                !order.value?.incremental?.appraiser?.options ||
                order.value?.incremental?.appraiser?.options?.length === 0
            ) {
                return false
            }

            const option = order.value.incremental.appraiser.options.find(
                (opt) => opt.option_key === 'jarokit_beta' && opt.customer_id === order.value?.incremental?.customer_id,
            )

            return option?.option_value ?? false
        })

        return {
            order,
            showBetaLink,
        }
    },
})
