import { ref, watch } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import {
    viewUser,
    updateOrders,
    orderFilter,
    viewFilters,
    resetViewFilters,
    viewTeam,
} from '@/components/OrderTable/Classes/OrderTable'
import { Appraiser, Lender, Team, User } from '@/types'
import { favorites, favoriteTeams, user as userStore } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'
import { updateQuoteTable } from '@/pages/AMC/Quotes/Quotes'
import states from '@/static/states'
import { currentDashboard } from '@/components/General/Dashboard/Dashboards'
import { user } from '@/plugins/User'
import { teams as AmcTeams } from '@/plugins/Amc'
import { DashboardFilter, DashboardSubfilter, FilterCondition, FilterConditionDetails } from '@/types/Dashboard'

export interface UserList {
    id?: number
    name?: string
    header?: string
}

interface Tags {
    title?: string
}

export interface ActionFilter {
    filter: string
    text: string
    icon: string
    count: string | number
    subfilters?: string[]
}

export const teams = ref([] as UserList[])
export const users = ref([] as UserList[])
export const tags = ref([] as Tags[])
export const newViewUser = ref([])
export const tableFilters = ref([])
export const actionsFilters = ref([])
export const subFilters = ref([])
export const currentFilter = ref(undefined as ActionFilter | undefined)
export const previousFilter = ref('')
export const activeFilter = ref('')
export const activeSubfilter = ref('')
export const dashboard = ref('')
export const stateOption = ref(states as { text: string; value: string }[] | string[])
export const lenderSelection = ref([] as Lender[])

export const appraiserSelection = ref([] as Appraiser[])
export const filterConditions = ref([] as FilterCondition[])
export const filterConditionsDetails = ref<FilterConditionDetails>()
export const selectedFilterSubFilters = ref<DashboardSubfilter[]>([])

export const filterViews = ref()
export const singleFilter = ref({})
export const oneEnabled = ref(false)

export const findFilterConditions = (filter: string, type: 'counts' | 'filters', subfilter = '') => {
    let selectedFilter: DashboardFilter | DashboardSubfilter | undefined
    if (filter && !subfilter)
        selectedFilter = currentDashboard.value[type].find((elem: DashboardFilter) => elem.filter == filter)
    if (filter && subfilter)
        selectedFilter = (
            currentDashboard.value['filters'].find((elem: DashboardFilter) => elem.filter == filter) as DashboardFilter
        )['subfilters'].find((elem: DashboardSubfilter) => elem.filter == subfilter)
    filterConditions.value = []
    selectedFilterSubFilters.value = []
    if (!selectedFilter) return
    filterConditions.value = selectedFilter.conditions
    filterConditionsDetails.value = selectedFilter.conditionsDetails
    if ((selectedFilter as DashboardFilter).subfilters) {
        selectedFilterSubFilters.value = (selectedFilter as DashboardFilter).subfilters
    }
}
export const loadingSubfiltersCount = ref(false)
export const loadingFiltersCount = ref(false)

export const getSubActionFilters = (filter: ActionFilter) => {
    if (activeSubfilter.value) return
    loadingSubfiltersCount.value = true
    $axios
        .post('/v1/orders/action/get-subfilter-counts', {
            viewTeam: viewTeam.value,
            viewUser: viewUser.value,
            filter: filter.text,
            dashboard_id: currentDashboard.value.id,
            dashboardName: filter.text,
            viewFilters: viewFilters.value,
        })
        .then(({ data }) => {
            loadingSubfiltersCount.value = false
            if (data) {
                subFilters.value = data.filters
            }
        })
        .catch(() => {
            loadingSubfiltersCount.value = false
        })
}

const grabStoredFilters = () => {
    if (AscentHelper.customerFeatureEnabled('viewByUser', true)) {
        const savedValue = localStorage.getItem(`user-select-${user.value?.id}_${user.value?.customer_id}`)
        if (savedValue) viewUser.value = JSON.parse(savedValue)
    }
    if (AscentHelper.customerFeatureEnabled('assignType') === 'team') {
        const savedTeamValue = localStorage.getItem(`team-select-${user.value?.id}_${user.value?.customer_id}`)
        if (savedTeamValue) viewTeam.value = JSON.parse(savedTeamValue)
    }
    resetViewFilters()
}

const updateCounts = (url: string, filter = '') => {
    grabStoredFilters()

    loadingFiltersCount.value = true
    $axios
        .post(url, {
            viewUser: viewUser.value,
            viewTeam: viewTeam.value,
            viewFilters: viewFilters.value,
            filter: filter,
            dashboard_id: currentDashboard.value.id,
        })
        .then(({ data }) => {
            loadingFiltersCount.value = false
            if (data) {
                if (dashboard.value == 'Assignment' || dashboard.value == 'Appraiser' || dashboard.value == 'Client') {
                    actionsFilters.value = data
                    if (currentFilter.value === undefined) {
                        currentFilter.value = actionsFilters.value[0]
                        orderFilter.value = (currentFilter.value as ActionFilter).filter
                    }
                    return
                }
                tableFilters.value = data.counts
                actionsFilters.value = data.filters
            }
        })
        .catch(() => {
            loadingFiltersCount.value = false
        })
    if (currentFilter.value) {
        getSubActionFilters(currentFilter.value)
    }
}

export const refreshDashboardCounts = async () => {
    if (user.value && (user.value.type === 'appraiser' || user.value.type == 'client'))
        return updateCounts(`/v1/orders/action/${user.value.type}/order-counts`)
    if (!currentDashboard.value || !currentDashboard.value.id) return
    updateCounts('/v1/orders/action/dashboard-counts')
}

const checkForRemovedTeams = () => {
    let parsedSavedTeams = localStorage.getItem(`team-select-${user.value?.id}_${user.value?.customer_id}`)
    if (!parsedSavedTeams) return
    const filteredTeams = teams.value
        .filter((el) => {
            if (el.id) {
                return el.id
            }
        })
        .map((el) => el.id)

    const isContained = JSON.parse(parsedSavedTeams).every((el: number) => {
        return filteredTeams.includes(el)
    })

    if (filteredTeams.length && !isContained) {
        parsedSavedTeams = JSON.parse(parsedSavedTeams).filter((el: number) => {
            return filteredTeams.includes(el)
        })
        localStorage.setItem(
            `team-select-${user.value?.id}_${user.value?.customer_id}`,
            JSON.stringify(parsedSavedTeams),
        )
    }
}

const checkForRemovedUsers = () => {
    let parsedSavedUsers = localStorage.getItem(`user-select-${user.value?.id}_${user.value?.customer_id}`)
    if (!parsedSavedUsers) return
    const filteredUsers = users.value
        .filter((el) => {
            if (el.id) {
                return el.id
            }
        })
        .map((el) => el.id)

    const isContained = JSON.parse(parsedSavedUsers).every((el: number) => {
        return filteredUsers.includes(el)
    })

    if (filteredUsers.length && !isContained) {
        parsedSavedUsers = JSON.parse(parsedSavedUsers).filter((el: number) => {
            return filteredUsers.includes(el)
        })
        localStorage.setItem(
            `user-select-${user.value?.id}_${user.value?.customer_id}`,
            JSON.stringify(parsedSavedUsers),
        )
    }
}

export const getLenders = () => {
    $axios
        .get('/v1/lender', {
            params: {
                noPagination: true,
            },
        })
        .then((response) => {
            lenderSelection.value = response.data
        })
}

export const getAppraisers = () => {
    $axios.get('/v1/appraiser/').then((response) => {
        appraiserSelection.value = response.data
    })
}

export const getTableTeams = () => {
    const assignType = AscentHelper.customerFeatureEnabled('assignType') as string

    if (!userStore.value || assignType !== 'team') {
        return
    }

    const savedValue = localStorage.getItem(`team-select-${userStore.value?.id}_${userStore.value?.customer_id}`)

    if (savedValue) {
        viewTeam.value = JSON.parse(savedValue)
    } else if (userStore.value?.teams && userStore.value.teams.length > 0) {
        viewTeam.value = userStore.value.teams
    } else {
        viewTeam.value = [-1]
    }

    teams.value = [{ id: -1, name: 'All Teams' } as UserList]

    teams.value = teams.value
        .concat([{ header: 'Favorite Teams' }])
        .concat(AmcTeams.value.filter((team: Team) => favoriteTeams.value.includes(team.id)))
        .concat([{ header: 'Other Teams' }])
        .concat(AmcTeams.value.filter((team: Team) => !favoriteTeams.value.includes(team.id)))

    checkForRemovedTeams()
}

export const getOfficeTableUsers = () => {
    return $axios
        .post('v1/customer/actions/getOfficeUsers', {
            type: 'office',
        })
        .then(({ data }) => {
            if (AscentHelper.customerFeatureEnabled('viewByUser', true)) {
                const savedValue = localStorage.getItem(`user-select-${user.value?.id}_${user.value?.customer_id}`)
                if (savedValue) {
                    viewUser.value = JSON.parse(savedValue)
                } else if (data.some((user: User) => user.id === userStore.value?.id)) {
                    viewUser.value = [userStore.value?.id as number]
                } else {
                    viewUser.value = [-1]
                }
                users.value = []

                if (AscentHelper.canDo('View All Users')) {
                    users.value = [{ id: -1, name: 'All Users' } as UserList]
                }

                users.value = users.value
                    .concat([{ header: 'Favorite Users' }])
                    .concat(data.filter((user: User) => favorites.value.includes(user.id)))
                    .concat(
                        [{ header: 'Other Users' }].concat(
                            data.filter((user: User) => !favorites.value.includes(user.id)),
                        ),
                    )
            }
            getTableTeams()
            resetViewFilters()
        })
}

export const getTableUsers = (user: string) => {
    return $axios
        .post('v1/customers/user/actions/getProcessorUsers', {
            type: user === 'assigner_id' ? 'assign' : 'css',
        })
        .then(({ data }) => {
            if (AscentHelper.customerFeatureEnabled('viewByUser', true)) {
                const savedValue = localStorage.getItem(`user-select-${data.id}_${data.customer_id}`)
                if (savedValue) {
                    viewUser.value = JSON.parse(savedValue)
                } else if (data.some((user: User) => user.id === userStore.value?.id)) {
                    viewUser.value = [userStore.value?.id as number]
                } else {
                    viewUser.value = [-1]
                }

                users.value = []

                if (AscentHelper.canDo('View All Users')) {
                    users.value = [{ id: -1, name: 'All Users' } as UserList]
                }

                users.value = users.value
                    .concat([{ header: 'Favorite Users' }])
                    .concat(data.filter((user: User) => favorites.value.includes(user.id)))
                    .concat(
                        [{ header: 'Other Users' }].concat(
                            data.filter((user: User) => !favorites.value.includes(user.id)),
                        ),
                    )

                checkForRemovedUsers()
            }

            getTableTeams()

            resetViewFilters()

            stateOption.value = states
            tags.value = [{ id: -1, name: 'All Tags' } as Tags]

            if (user === 'Quotes') {
                updateQuoteTable()
                refreshDashboardCounts()
            }
        })
}

watch(currentDashboard, () => {
    subFilters.value = []
    if (!user.value) return

    if (user.value.type == 'amc')
        getTableUsers(currentDashboard.value.viewUser).then(() => {
            refreshDashboardCounts().then(() => {
                updateOrders()
            })
        })
    if (['office', 'desk'].includes(user.value.type))
        getOfficeTableUsers().then(() => {
            refreshDashboardCounts().then(() => {
                updateOrders()
            })
        })
})
