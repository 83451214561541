

























import { defineComponent, ref, watch } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'
import {
    appraiserValue,
    appraiserSettingsId,
    appraiserOptions,
    AppraiserOptionsSettings,
} from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    props: {
        option: {
            type: Object || undefined,
        },
    },
    setup(props, { root }) {
        const appraiserOptionsLocal = ref({
            external_id: '',
        } as { external_id: string | undefined })

        watch(appraiserOptions && appraiserValue, () => {
            if (props.option && props.option.external_id) {
                appraiserOptions.value.external_id = props.option.external_id
                appraiserOptionsLocal.value.external_id = props.option.external_id
            }
        })

        if (props.option && props.option.external_id) {
            appraiserOptions.value.external_id = props.option.external_id
            appraiserOptionsLocal.value.external_id = props.option.external_id
        }

        const saveChanges = async () => {
            if (root.$route.params.id) appraiserSettingsId.value = root.$route.params.id
            await axios.post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                option_key: 'external_id',
                option_value: appraiserOptions.value.external_id,
            })
            appraiserOptionsLocal.value = appraiserOptions.value as AppraiserOptionsSettings

            showSnackbar('Saved successfully')
        }

        return {
            saveChanges,
            appraiserOptions,
            appraiserOptionsLocal,
        }
    },
})
