import { computed, nextTick, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { QualityChecklistItem, QualityConditions, QualityOverlayItem, QualityQueue, QualityTotals } from '@/types'
import { refreshDashboardCounts } from '@/components/OrderTable/Classes/TableFilters'
import AscentKeyboardEvents from '@/plugins/KeyEventListener'
import { user } from '@/plugins/User'
import { order, loadIncremental, subscribeOrderUpdates } from '@/plugins/order/Order'
import { handleConnection } from '@/plugins/Channels'
import { getNotes } from '@/plugins/Notes'

export const moreOrders = ref(true)
export const loading = ref(false)
export const qualityFileViewer = ref(false)

export const url = ref('')
export const questions = ref([] as QualityChecklistItem[])
export const overlays = ref([] as QualityOverlayItem[])
export const qcNotes = ref(false)
export const queue = ref(undefined as QualityQueue | undefined)
export const totals = ref({
    failed: 0,
    passed: 0,
    total: 0,
    verify: 0,
} as QualityTotals)
export const orderLoaded = ref(false)

export const steps = ref([])
export const currentStep = ref(0)

export let predefinedConditions: QualityConditions[] = []
const setPredefinedConditions = () => {
    $axios.get('/v1/quality-condition').then(({ data }) => {
        predefinedConditions = data as QualityConditions[]
    })
}

const openFileViewer = () => {
    qualityFileViewer.value = true
    if (user.value?.type === 'amc' || user.value?.type === 'office') {
        window.open(`${process.env.VUE_APP_BASE_URL}/quality/viewer`)
        return
    }
    window.open(`${process.env.VUE_APP_BASE_URL}/quality/external-files`)
}

export const getNext = async (id = '', external = false) => {
    loading.value = true
    $axios
        .post('/v1/quality/action/get-next', {
            id: id,
            external: external,
        })
        .then(({ data }) => {
            if (!qualityFileViewer.value) openFileViewer()
            steps.value = data.steps
            order.value = data.cache.order
            handleConnection({
                channelRef: 'orderPresence',
                id: data.cache.id,
            })
            subscribeOrderUpdates()
            loadIncremental()
                .then((order) => {
                    if (!order) return
                    orderLoaded.value = true
                })
                .then(() => {
                    loading.value = false
                })
            url.value = data.url
            questions.value = data.questions
            overlays.value = data.overlays
            queue.value = data.queue
            totals.value = data.totals
            getNotes(data.cache.id)
            nextTick(() => {
                currentStep.value = 1
            })
            if (predefinedConditions.length === 0) {
                setPredefinedConditions()
            }
        })
        .catch(() => {
            moreOrders.value = false
            loading.value = false
        })
}

export const filteredItems = computed(() => {
    if (questions.value.length === 0) return []
    return questions.value.filter((elem) => elem.status !== 'Passed')
})

export const filteredOverlays = computed(() => {
    if (overlays.value.length === 0) return []
    return overlays.value.filter((elem) => elem.status !== 'Passed')
})

export const passQuestion = async (item: QualityChecklistItem | QualityOverlayItem) => {
    await $axios
        .patch('/v1/quality/action/' + item.id + '/update-' + item.type + '-result', {
            status: 'Passed',
        })
        .then(() => {
            const question =
                item.type === 'quality'
                    ? questions.value.find((elem) => elem.id === item.id)
                    : overlays.value.find((elem) => elem.id === item.id)

            if (question === undefined) return
            question.status = 'Passed'
        })
}

export const failQuestion = async (item: QualityChecklistItem | QualityOverlayItem) => {
    await $axios
        .patch('/v1/quality/action/' + item.id + '/update-' + item.type + '-result', {
            status: 'Failed',
        })
        .then(() => {
            const question =
                item.type === 'quality'
                    ? questions.value.find((elem) => elem.id === item.id)
                    : overlays.value.find((elem) => elem.id === item.id)

            if (question === undefined) return
            question.status = 'Failed'
        })
}

export const updateStep = () => {
    currentStep.value = currentStep.value + 1
}

export const SetCurrentStep = (step: number) => {
    currentStep.value = step
}

export const resetQuality = () => {
    if (!user.value) return
    if (user.value.type == 'amc') refreshDashboardCounts()
    url.value = ''
    questions.value = []
    overlays.value = []
    queue.value = undefined
    totals.value = {
        failed: 0,
        passed: 0,
        total: 0,
        verify: 0,
    }
    orderLoaded.value = false
    steps.value = []
    currentStep.value = 0
    AscentKeyboardEvents.removeFocusEditor()
    AscentKeyboardEvents.focusSearch()
}
