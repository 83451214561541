










import { defineComponent, nextTick, Ref, ref } from '@vue/composition-api'

const showMenu = ref(false)
const x = ref(0)
const y = ref(0)

interface MenuItem {
    title: string
    disabled?: boolean
    callback?: () => void
}
export const menuItems: Ref<MenuItem[]> = ref([])

export const show = (e: MouseEvent) => {
    e.preventDefault()
    showMenu.value = false
    x.value = e.clientX
    y.value = e.clientY
    nextTick(() => {
        showMenu.value = true
    })
}

export default defineComponent({
    name: 'DetailMenuComponent',
    setup() {
        return {
            showMenu,
            x,
            y,
            menuItems,
        }
    },
})
