






































import { defineComponent, ref } from '@vue/composition-api'
import axios from '@/plugins/axios'
import { Directive } from '@/types/Directive'
import { order } from '@/plugins/order/Order'
import { Incremental, OrderModel } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

const dialog = ref(false)
const directives = ref([] as Directive[])
const loadingDirectives = ref(true)
const selectedDirective = ref(undefined)

export const addOrderDirective = () => {
    dialog.value = true
    loadingDirectives.value = true
    directives.value = []
    selectedDirective.value = undefined
    axios.get('/v1/directive').then((response) => {
        directives.value = response.data
        loadingDirectives.value = false
    })
}

/**
 * @name AddOrderDirective
 * Dialog in the OrderDetails that will add a directive onto the order
 *
 * @SetupData directives - directives that can be added.
 * @SetupMethod addOrderDirectives - opens the dialog and sets the directives that can be added to the order
 * @SetupData selectedDirective - directive that will be added to the order
 *
 * @SetupMethod submit - called after the directive has been added to the order
 */

export default defineComponent({
    setup() {
        const panels = ref(undefined)
        const closePopup = () => {
            dialog.value = false
            panels.value = undefined
        }
        const submit = (data: Directive[]) => {
            const incremental = (order.value as OrderModel).incremental as Incremental
            incremental.directives = data
            closePopup()
            showSnackbar('Directive added successfully')
        }

        return {
            directives,
            selectedDirective,
            dialog,
            order,
            submit,
            closePopup,
            panels,
            loadingDirectives,
        }
    },
    computed: {
        showAddDirectiveButton() {
            return !this.selectedDirective
        },
    },
})
