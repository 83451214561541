





























































import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import AscentHelper from '@/helpers/ascent-helper'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import AppraiserTeamSelect from '@/components/General/AutoCompletes/AppraiserTeamSelect.vue'

const state = reactive({
    dialog: false,
    date: '',
    fee: null as number | null,
    type: 'fee',
    appraiser: 0 as number | Appraiser,
})
const reason = ref('')
import { order, updateOrder } from '@/plugins/order/Order'
import { DateTime } from 'luxon'
import { Appraiser } from '@/types'

export default defineComponent({
    components: {
        ValidationObserver,
        AppraiserTeamSelect,
    },
    setup() {
        const submit = () => {
            state.dialog = false
            reason.value = ''
            state.type = 'fee'
            state.date = ''
            state.fee = 0
            showSnackbar('Conditions Requested Successfully')
            updateOrder()
        }

        const allowedDates = (date: number) => {
            if (date === order.value?.clientDeadline) return false
            if (date <= DateTime.now().toISODate()) return false
            return AscentHelper.isDateBlackedOut(date)
        }

        const clearState = () => {
            state.fee = 0
            reason.value = ''
            state.date = ''
            state.appraiser = 0
        }

        watch(
            () => state,
            () => {
                if (state.type == 'fee' && state.fee)
                    reason.value = `Due to the complexities of this order, we will accept this order with fee of $${state.fee}.`
                if (state.type == 'due' && state.date)
                    reason.value = `We can accept this order with a due date of ${AscentHelper.formatIsoDate(
                        state.date,
                        'L/d/y',
                        false,
                    )}.`
                if (state.type == 'reassign' && state.appraiser)
                    reason.value = `We can accept this order if it is assigned to ${
                        (state.appraiser as Appraiser).name
                    }.`
                if (state.type == 'both' && state.fee && state.date)
                    reason.value = `We can accept this order with a due date of ${AscentHelper.formatIsoDate(
                        state.date,
                        'L/d/y',
                        false,
                    )} and fee of $${state.fee}.`
            },
            { deep: true },
        )

        return {
            state,
            allowedDates,
            submit,
            order,
            clearState,
            reason,
        }
    },
})
