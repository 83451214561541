var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('a-card',{attrs:{"elevation":0}},[_c('v-card-title',{staticClass:"text-h5"},[_c('span',[_vm._v("Reassign Appraisers or Update Fees")]),_c('v-spacer'),(
                    _vm.$ascent.canDo('Override Appraiser Assignment') &&
                    _vm.$ascent.featureEnabled('orderScreen.overrideAppraiser', false)
                )?_c('v-menu',{attrs:{"close-on-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$vuetify.icons.ellipsis")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(
                            _vm.$ascent.canDo('Override Appraiser Assignment') &&
                            _vm.$ascent.featureEnabled('orderScreen.overrideAppraiser', false)
                        )?_c('v-list-item',{on:{"click":_vm.openOverrideDialogAction}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Override Appraiser Assignment")])],1)],1):_vm._e()],1)],1):_vm._e()],1),_c('v-card-text',[(_vm.$ascent.featureEnabled('orderScreen.office.reassignTeam'))?_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.reassignmentType),callback:function ($$v) {_vm.reassignmentType=$$v},expression:"reassignmentType"}},[_c('v-radio',{attrs:{"label":"A to Z","value":"az"}}),_c('v-radio',{attrs:{"label":"Team","value":"team"}})],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-5"},_vm._l((_vm.teamAllocations),function(appraiser,index){return _c('AppraiserTeamAllocate',{key:index,attrs:{"type":appraiser.role,"appraiser":_vm.teamAllocations[index],"primary-appraiser":_vm.teamAllocations[0],"role":appraiser.role},on:{"updatedFee":_vm.updateFee},model:{value:(_vm.teamAllocations[index]),callback:function ($$v) {_vm.$set(_vm.teamAllocations, index, $$v)},expression:"teamAllocations[index]"}})}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-area',{attrs:{"rules":_vm.$ascent.featureEnabled('orderScreen.office.reassignReason', true) ? 'required' : '',"outlined":"","name":"note","label":"Reason"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1)],1),_c('v-card-actions',{attrs:{"justify":"end"}},[_c('v-container',{staticClass:"min-w-full"},[_c('v-row',{attrs:{"justify":"end"}},[_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"method":"PATCH","url":'v1/order/' + _vm.order.id + '/action/appraiser-office-reassign',"submitData":{
                            roles: _vm.teamAllocations,
                            note: _vm.reason,
                            type: _vm.reassignmentType,
                        },"show-cancel":false,"disableSubmit":_vm.disableButton,"btnText":"Save and Update"},on:{"success":_vm.updateAppraiser}})],1)],1)],1)],1),_c('OverridePanelAssignDialog',{attrs:{"appraiser-roles":_vm.roles}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }