interface ServerSettingsBase {
    type: 'lender-option' | 'sublender-option' | 'generic'
}

interface LenderOptionServerSettings extends ServerSettingsBase {
    type: 'lender-option'
    optionName: string
}

interface SublenderOptionServerSettings extends ServerSettingsBase {
    type: 'sublender-option'
    optionName: string
}

interface GenericServerSettings extends ServerSettingsBase {
    type: 'generic'
    getUrl: string
    updateUrl: string
    updateMethod: 'POST' | 'PATCH'
    namespace?: string
    root?: string
    requiredFields?: string[]
}

export type ServerSettings = LenderOptionServerSettings | SublenderOptionServerSettings | GenericServerSettings

interface ServerStrategy {
    parse: (json: { [key: string]: { [key: string]: unknown } }) => {
        [key: string]: unknown
    }
    format: (valuesByName: { [key: string]: unknown }) => {
        [key: string]: unknown
    }
    getUrl: string
    updateUrl: string
    updateMethod: string
}

const lenderOptionServerStrategy = (
    serverSettings: LenderOptionServerSettings,
    configData: { [key: string]: unknown },
): ServerStrategy => {
    return {
        getUrl: `/v1/lender/${configData.lenderId}/option/${serverSettings.optionName}`,
        updateUrl: `/v1/lender/${configData.lenderId}/option`,
        updateMethod: 'POST',
        parse: (json) => {
            // option_value is an object, it's a multi-field lender-option form
            if (typeof json.option_value === 'object' && !Array.isArray(json.option_value)) {
                return json.option_value
            }

            // option_value is a scalar or an array, it's a single-field lender-option form
            return { [serverSettings.optionName]: json.option_value }
        },
        format: (valuesByName) => {
            return {
                option_key: serverSettings.optionName,
                option_value:
                    Object.values(valuesByName).length === 1 ? valuesByName[serverSettings.optionName] : valuesByName,
            }
        },
    }
}

const sublenderOptionServerStrategy = (
    serverSettings: SublenderOptionServerSettings,
    configData: { [key: string]: unknown },
): ServerStrategy => {
    return {
        getUrl: `/v1/sublender/${configData.sublenderId}/option/${serverSettings.optionName}`,
        updateUrl: `/v1/sublender/${configData.sublenderId}/option`,
        updateMethod: 'POST',
        parse: (json) => {
            // option_value is an object, it's a multi-field lender-option form
            if (typeof json.option_value === 'object' && !Array.isArray(json.option_value)) {
                return json.option_value
            }

            // option_value is a scalar or an array, it's a single-field lender-option form
            return { [serverSettings.optionName]: json.option_value }
        },
        format: (valuesByName) => {
            return {
                option_key: serverSettings.optionName,
                option_value:
                    Object.values(valuesByName).length === 1 ? valuesByName[serverSettings.optionName] : valuesByName,
            }
        },
    }
}

const genericServerStrategy = (
    serverSettings: GenericServerSettings,
    configData: { [key: string]: string },
): ServerStrategy => {
    const resolveUrl = (url = '', configData: { [key: string]: string }) => {
        let ret = url

        Object.keys(configData).forEach((key) => {
            ret = ret.replaceAll('${' + key + '}', configData[key])
        })

        return ret
    }

    // place to save server data for later to use with requiredData
    let data: { [key: string]: unknown } = {}

    return {
        getUrl: resolveUrl(serverSettings.getUrl, configData),
        updateUrl: resolveUrl(serverSettings.updateUrl, configData),
        updateMethod: serverSettings.updateMethod,
        parse: (json) => {
            // saving for later to use with requiredData
            data = json

            // if root defined, values are burried under root key
            return serverSettings.root ? json[serverSettings.root] : json
        },
        format: (valuesByName) => {
            const requiredFields = (serverSettings.requiredFields || []).reduce(
                (acc: { [key: string]: unknown }, fieldName: string) => {
                    acc[fieldName] = Object.keys(valuesByName).includes(fieldName)
                        ? valuesByName[fieldName]
                        : data[fieldName]
                    return acc
                },
                {},
            )

            const allFields = serverSettings.root
                ? { [serverSettings.root]: valuesByName, ...requiredFields }
                : { ...valuesByName, ...requiredFields }

            const payload = serverSettings.namespace ? { [serverSettings.namespace]: allFields } : allFields

            return payload
        },
    }
}

const dummyServerStrategy: ServerStrategy = {
    parse: () => ({}),
    format: () => ({}),
    getUrl: '',
    updateUrl: '',
    updateMethod: '',
}

export const createServerStrategy = (
    serverSettings: ServerSettings | undefined,
    configData: { [key: string]: string },
) => {
    if (!serverSettings) return dummyServerStrategy

    switch (serverSettings.type) {
        case 'lender-option':
            return lenderOptionServerStrategy(serverSettings as LenderOptionServerSettings, configData)
        case 'sublender-option':
            return sublenderOptionServerStrategy(serverSettings as SublenderOptionServerSettings, configData)
        case 'generic':
            return genericServerStrategy(serverSettings as GenericServerSettings, configData)
        default:
            throw new Error(`Not supported server strategy.`)
    }
}
