






















import { defineComponent } from '@vue/composition-api'
import { unreadNotes, markAllUnreadRead } from '@/plugins/Notes'

export default defineComponent({
    name: 'CustomerUnreadNoteDivider',
    setup() {
        return {
            unreadNotes,
            markAllUnreadRead,
        }
    },
})
