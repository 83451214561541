















import { computed, defineComponent } from '@vue/composition-api'
import { getPresentUsers } from '@/plugins/Channels'
import { user } from '@/plugins/User'

export default defineComponent({
    components: {},
    setup() {
        const otherUsers = computed(() => {
            const userId = user.value?.id ?? 0

            return getPresentUsers('orderPresence').filter((elem: { id: number; name: string }) => elem.id !== userId)
        })
        const userColor = computed(() => {
            otherUsers.value.map((elem) => {
                if (elem.type === 'amc') elem.color = 'secondary'
                if (elem.type === 'desk') elem.color = 'secondary'
                if (elem.type === 'ascent') elem.color = 'primary'
                if (elem.type === 'appraiser') elem.color = '#8434A3'
                if (elem.type === 'client') elem.color = 'pink darken-4'
                return true
            })
            return true
        })

        return {
            userColor,
            otherUsers,
        }
    },
})
