























import { defineComponent, ref } from '@vue/composition-api'
import $axios from '../../../../../plugins/axios'
import { user } from '@/plugins/User'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import OfficeSelect from '@/components/General/AutoCompletes/OfficeSelect.vue'
import { appraiserSettingsId } from '@/pages/Appraiser/Profile/AppraiserProfile'

const editOptions = ref({
    accounting: [],
})

export default defineComponent({
    components: {
        OfficeSelect,
    },
    props: {
        option: {
            type: Object,
        },
    },

    setup(props, { emit }) {
        if (props.option && props.option.appraiser_accounting) {
            editOptions.value.accounting = props.option.appraiser_accounting
        }

        const save = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'appraiser_accounting',
                    option_value: editOptions.value.accounting,
                })
                .then(() => {
                    showSnackbar('Option updated successfully')
                    emit('options-updated')
                })
        }

        return {
            save,
            user,
            editOptions,
        }
    },
})
