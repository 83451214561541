























import { defineComponent, PropType, ref } from '@vue/composition-api'
import { LenderOptions, LenderQualityChecklistOption } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref({ sendQualityChecklistToLender: false } as LenderQualityChecklistOption)

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as LenderQualityChecklistOption
        }

        const save = () => {
            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'lender-quality-checklist',
                    option_value: optionLocal.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully!')
                })
                .catch(() => {
                    return
                })
        }

        return {
            optionLocal,
            save,
        }
    },
})
