































import { defineComponent, PropType } from '@vue/composition-api'
import { DateTime } from 'luxon'
import { AuditEvent, AuditEventType } from '@/types/OrderAuditLog'

export default defineComponent({
    props: {
        events: {
            type: Array as PropType<AuditEvent[]>,
            required: true,
        },
        value: {
            type: Object as PropType<AuditEvent>,
            required: false,
        },
    },
    setup(_, { emit }) {
        const iconByType: { [type in AuditEventType]: string } = {
            Access: 'circleUser',
            Debug: 'circleQuestion',
            EAD: 'houseLaptop',
            IntegrationInbound: 'diagramSankey',
            JaroBotRun: 'robot',
            JaroBotScheduled: 'robot',
            Note: 'circleUser',
            IntegrationOutbound: 'diagramSankey',
            Payment: 'circleDollar',
            Status: 'timelineArrow',
            System: 'circleQuestion',
            UCDP: 'houseLaptop',
            Update: 'squarePen',
            Veros: 'dollarSign',
        }

        const select = (event: AuditEvent) => {
            emit('input', event)
        }

        const formatDate = (isoDate: string) => DateTime.fromISO(isoDate).toFormat('EEE, MMM d, t (ZZZZ)')

        return { select, iconByType, formatDate }
    },
})
