var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"450px","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('close')}},model:{value:(_vm.state.dialog),callback:function ($$v) {_vm.$set(_vm.state, "dialog", $$v)},expression:"state.dialog"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit "+_vm._s(_vm.state.type))])]),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-lg":"","pa-0":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"reactive":"","full-width":"","rules":"required","min":_vm.getLocalTime()},model:{value:(_vm.state.date),callback:function ($$v) {_vm.$set(_vm.state, "date", $$v)},expression:"state.date"}})],1)],1),(['Inspection Date', 'Second Inspection Date'].includes(_vm.state.type))?_c('v-row',[_c('v-col',[_c('a-select-field',{attrs:{"rules":"required","items":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],"name":"hour","label":"Hour"},model:{value:(_vm.state.hour),callback:function ($$v) {_vm.$set(_vm.state, "hour", $$v)},expression:"state.hour"}})],1),_c('v-col',[_c('a-select-field',{attrs:{"rules":"required","items":['00', '15', '30', '45'],"name":"minute","label":"Minute"},model:{value:(_vm.state.minute),callback:function ($$v) {_vm.$set(_vm.state, "minute", $$v)},expression:"state.minute"}})],1),_c('v-col',[_c('a-select-field',{attrs:{"rules":"required","items":['AM', 'PM'],"name":"time","label":"AM/PM"},model:{value:(_vm.state.time),callback:function ($$v) {_vm.$set(_vm.state, "time", $$v)},expression:"state.time"}})],1)],1):_vm._e()],1)],1),(!_vm.appraiserAfterClient)?_c('v-col',{staticClass:"pa-0"},[_c('v-col',[_c('v-alert',{staticClass:"mb-0 text-center",attrs:{"type":"warning","dense":"","text":""}},[_vm._v(" Deadline must be after the Appraiser Deadline ")])],1)],1):_vm._e(),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"submitData":{
                    newDate: _vm.state.date + ' ' + _vm.state.hour + ':' + _vm.state.minute + ' ' + _vm.state.time,
                    type: _vm.state.type,
                },"url":'/v1/order/' + _vm.order.id + '/milestone',"method":"POST","disableSubmit":!_vm.appraiserAfterClient},on:{"cancel":function($event){_vm.state.dialog = false},"success":_vm.submit}},[_c('template',{slot:"secondaryAction"},[(
                            ['Inspection Date', 'Second Inspection Date'].includes(_vm.state.type) &&
                            _vm.user.type !== 'appraiser'
                        )?_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.deleteMilestone}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$vuetify.icons.trashAlt")]),_vm._v(" Delete ")],1):_vm._e()],1)],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }