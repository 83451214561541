























































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { LenderOptions } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import ACardAction from '@/global/ACardAction.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { ACardAction, ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const optionLocal = ref('')

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as string
        }

        const qualityName = ref(optionLocal.value)

        const save = () => {
            dialog.value = false
            optionLocal.value = qualityName.value
            showSnackbar('Quality Name updated successfully')
        }

        return {
            lender,
            save,
            dialog,
            optionLocal,
            qualityName,
        }
    },
})
