
































































































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import ClientFileComponent from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientFileComponent.vue'
import { files } from '@/plugins/Files'
import { File } from '@/types'
import ClientAddOrderFileDialog, {
    newFileDialog,
} from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientAddOrderFileDialog.vue'
import EditFile, {
    open,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditFileDialog.vue'
import MortgageDirectorImportFileDialog, {
    newMortgageDirectorFileDialog,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/MortgageDirectorImportDialog.vue'
import { order, isCompleted } from '@/plugins/order/Order'

/**
 * @name ClientOrderFiles
 * Section on Client Order Screen that shows the files on the file. Allows client to edit and add files as well
 *
 * @SetupData showInactive - boolean to show inactive files or not
 * @SetupComputed filteredFiles - filters files on clients to those that have status of All or Client
 * @SetupComputed hasInactive - filters out inactive files
 */

export default defineComponent({
    components: {
        ClientFileComponent,
        ClientAddOrderFileDialog,
        EditFile,
        MortgageDirectorImportFileDialog,
    },
    setup() {
        const showInactive = ref(false)
        const expanded = ref(false)

        const activeFiles = computed(() => files.value.filter((f) => f.status !== 'Inactive'))
        const hasInactive = computed(() => files.value.some((f: File) => f.status === 'Inactive'))
        const filteredFiles = computed(() =>
            files.value.filter(
                (f) =>
                    ['all', 'client', ...(showInactive.value ? ['inactive'] : [])].includes(
                        (f.status || '').toLowerCase(),
                    ) || f.source.toLowerCase() === 'client',
            ),
        )

        const pdfUrl = computed(() => process.env.VUE_APP_PDF)

        const downloadPDF = (url: string) => {
            window.location.href = url
        }

        return {
            expanded,
            hasInactive,
            showInactive,
            filteredFiles,
            downloadPDF,
            open,
            order,
            isCompleted,
            newFileDialog,
            newMortgageDirectorFileDialog,
            activeFiles,
            pdfUrl,
        }
    },
})
