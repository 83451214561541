






















































import { defineComponent, onMounted, ref } from '@vue/composition-api'

export const date = ref('')
export const time = ref('')

/**
 * @name DateTimeDialog
 * Dialog to let user choose a date and time
 *
 * @SetupData modal - boolean that will open dialog to choose the date
 * @SetupData modal2 - will determine if dialog to choose time is open or not
 * @SetupMethod checkTime - checks time chosen, and if it is before 8, it will add 12 to make it PM instead of AM
 */

export default defineComponent({
    name: 'DateTimeDialog',
    setup() {
        const modal = ref(false)
        const modal2 = ref(false)

        const checkTime = (data: string) => {
            const hour = parseInt(data)
            if (!time.value) time.value = data + ':00'
            const ampm = time.value.split(':')
            if (hour <= 8) {
                ampm[0] = (parseInt(ampm[0]) + 12).toString()
                time.value = `${ampm[0]}:${ampm[1]}`
            }
        }

        onMounted(() => {
            date.value = ''
            time.value = ''
        })

        return {
            date,
            time,
            modal,
            modal2,
            checkTime,
        }
    },
})
