















































import { computed, defineComponent, ref, onMounted, PropType } from '@vue/composition-api'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder, fees, addOn, originalAddOn, getFeeList } from '@/plugins/order/Order'
import Vue from 'vue'
import { Product } from '@/types'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { currentReviewStep } from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import OrderFeeTable, {
    FeeUpdateInput,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderFeeTable.vue'
import OrderAddOnTable from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderAddOnTable.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import { requiresFeeModification } from '@/plugins/order/OrderModification'
import AscentHelper from '@/helpers/ascent-helper'
import { AscentHelperType } from '@/plugins/ascent-helper'
import $axios from '@/plugins/axios'
const products = ref<Product[]>([])

export function addFee(line: Product) {
    products.value.push({
        product_id: line.form?.id as number,
        product_name: line.form?.name as string,
        amc_value: line.amc_value,
        appraiser_value: line.appraiser_value,
        line_total: parseFloat(line.amc_value as string) + parseFloat(line.appraiser_value as string),
        form: line.form,
    })
    if (!order.value || !order.value?.incremental) return
    orderProducts.value.push({
        product_id: line.form?.id as number,
        product_name: line.form?.name as string,
        amc_value: line.amc_value,
        appraiser_value: line.appraiser_value,
        line_total: parseFloat(line.amc_value as string) + parseFloat(line.appraiser_value as string),
        form: line.form,
    })
}

export default defineComponent({
    name: 'CustomerEditOrderInvoice',
    components: { OrderFeeTable, OrderAddOnTable },
    props: {
        customProducts: {
            type: [] as PropType<Product[]> | null,
            default: null,
        },
        showUpdateButton: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const showAddOn = ref(false)
        const newFee = ref(false)
        const loading = ref(false)
        const error = ref('')
        const total = ref(0)
        const appraisersFees = ref<Product[]>([])

        const canEditFees = computed(() => {
            if (
                (AscentHelper as AscentHelperType).getUserType() === 'office' &&
                order.value?.source_system === 'ascent'
            )
                return false
            return true
        })

        const requiresModification = computed(() => {
            return (requiresFeeModification.value as boolean) && total.value !== order.value?.incremental?.total?.total
        })

        const deleteFee = (item: Product) => {
            if (!order.value || !order.value?.incremental) return
            products.value.splice(products.value.indexOf(item), 1)
            orderProducts.value.splice(products.value.indexOf(item), 1)
            if (originalAddOn.value.length > 0) {
                originalAddOn.value.map((elem) => {
                    if (elem.form.id === item.form.id) addOn.value.push(elem)
                })
            }
        }

        const addAddOn = (addOns: Product) => {
            products.value.push({
                product_id: addOns.form?.id as number,
                product_name: addOns.form?.name as string,
                amc_value: parseFloat(addOns.amc as string) as number,
                appraiser_value: parseFloat(addOns.appraiser as string) as number,
                line_total: parseFloat(addOns.company as string) as number,
                form: addOns.form,
            })
            addOn.value.splice(addOn.value.indexOf(addOns), 1)
            if (!order.value || !order.value?.incremental) return
            orderProducts.value.push({
                product_id: addOns.form?.id as number,
                product_name: addOns.form?.name as string,
                amc_value: parseFloat(addOns.amc as string) as number,
                appraiser_value: parseFloat(addOns.appraiser as string) as number,
                line_total: parseFloat(addOns.company as string) as number,
                form: addOns.form,
            })
        }

        const getFees = (item: Product) => {
            if (!order.value) return

            $axios.get('/v1/order/' + order.value.id + '/product/' + item.form.id).then((response) => {
                if (response.data.quote) {
                    products.value[products.value.indexOf(item)].amc_value = 0
                    products.value[products.value.indexOf(item)].appraiser_value = 0
                    products.value[products.value.indexOf(item)].line_total = 0
                    return
                }

                products.value[products.value.indexOf(item)].product_name = response.data.name
                products.value[products.value.indexOf(item)].amc_value = parseFloat(response.data.amc)
                    ? parseFloat(response.data.amc)
                    : 0
                products.value[products.value.indexOf(item)].appraiser_value = parseFloat(response.data.appraiser)
                    ? parseFloat(response.data.appraiser)
                    : 0
                products.value[products.value.indexOf(item)].line_total =
                    (products.value[products.value.indexOf(item)].amc_value as number) +
                    (products.value[products.value.indexOf(item)].appraiser_value as number)
            })
        }

        const updateFees = () => {
            if (products.value.filter((elem) => elem.form.type === 'Main Product').length !== 1) {
                error.value = 'You can only have one main product on the order and you must have at least one'
            }
            if (products.value.filter((elem) => elem.form.type === 'Loan Type').length !== 1) {
                error.value = 'You can only have one loan type on the order and you must have at least one'
            }
            if (error.value) {
                showError(error.value)
                return false
            }
            error.value = ''
            loading.value = true
            if (!order.value) return
            $axios
                .patch('/v1/order/' + order.value.id + '/action/update-products', {
                    products: products.value,
                })
                .then(() => {
                    if (!order.value) return
                    refreshOrder().then(() => {
                        if (!order.value?.incremental) return
                        Vue.set(order.value.incremental, 'products', products.value)
                        order.value.loan = products.value.find((product) => product.formType === 'Loan Type')
                            ?.product_name as string
                    })

                    showSnackbar('Fees and Forms Updated Successfully')
                    loading.value = false
                })
                .catch((err) => {
                    showError(err)
                    loading.value = false
                })
        }

        const updateTotal = (updatedTotal: number) => {
            total.value = updatedTotal
        }

        const updateInput = (updatedInput: FeeUpdateInput) => {
            const index = products.value.indexOf(updatedInput.item)
            products.value[index][updatedInput.column] = isNaN(parseFloat(updatedInput.val))
                ? 0
                : parseFloat(updatedInput.val).toFixed(2)

            products.value[products.value.indexOf(updatedInput.item)].amc_value =
                (products.value[products.value.indexOf(updatedInput.item)].line_total as number) -
                (products.value[products.value.indexOf(updatedInput.item)].appraiser_value as number)
        }

        onMounted(() => {
            const showExpenses = (AscentHelper as AscentHelperType).customerFeatureEnabled(
                'orderScreen.invoice.showExpenses',
                false,
            )
            if (props.customProducts) {
                products.value = props.customProducts
            } else {
                products.value = JSON.parse(JSON.stringify(orderProducts.value)) as Product[]
            }

            if (showExpenses) {
                products.value.forEach((p) => {
                    if (p.product_name == 'Tech Fee') p.expense = true
                })
            }

            const appraisers = showExpenses ? order.value?.incremental?.order_appraisers || [] : []
            appraisersFees.value = appraisers.map((appraiser) => ({
                // TODO: implement adapters to convert all types of fees to a common format used on fees table
                // currently mocking Product interface to satisfy OrderFeeTable.vue api
                product_id: -1,
                product_name: '',
                form: {
                    name: appraiser.role,
                    type: 'Appraiser',
                },
                amc_value: -appraiser.fee,
                line_total: 0,
                appraiser_value: appraiser.fee,
                expense: true,
                readonly: true,
            }))

            getFeeList()
            showAddOn.value = false
        })

        return {
            products,
            appraisersFees,
            fees,
            newFee,
            lenderOptions,
            currentReviewStep,
            addOn,
            showAddOn,
            canEditFees,
            deleteFee,
            addAddOn,
            updateInput,
            updateTotal,
            updateFees,
            getFees,
            loading,
            error,
            total,
            requiresModification,
        }
    },
})
