import { render, staticRenderFns } from "./AddReminderDialog.vue?vue&type=template&id=e40d20e4&scoped=true&"
import script from "./AddReminderDialog.vue?vue&type=script&lang=ts&"
export * from "./AddReminderDialog.vue?vue&type=script&lang=ts&"
import style0 from "./AddReminderDialog.vue?vue&type=style&index=0&id=e40d20e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e40d20e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VAlert,VCardActions,VCardText,VCol,VDatePicker,VDialog,VRow,VSpacer,VTimePicker})
