



















































































import { computed, defineComponent, onMounted, watch } from '@vue/composition-api'
import {
    newOrder,
    getOrderTotal,
    lenders,
    addSplit,
    removeSplit,
    useDisclosedFee,
} from '@/pages/Client/NewOrderMethods'
import AscentHelper from '@/helpers/ascent-helper'
import { BillingSplit } from '@/types'

const splitTotal = computed(() => {
    return useDisclosedFee.value
        ? Number(newOrder.value.disclosed_fee)
        : newOrder.value.forms.total === 0
        ? newOrder.value.forms.feeScheduleTotal
        : newOrder.value.forms.total
})

const remaining = computed(() => {
    return newOrder.value.billing.splits.reduce(
        (total: BillingSplit, elem) => {
            if (elem.amount > 0 && total) {
                total.amount = total.amount - elem.amount
            }
            return total
        },
        {
            method: '',
            amount: splitTotal.value,
        } as BillingSplit,
    )
})

export const splitPaymentIsZero = computed(() => {
    if (newOrder.value.billing_method !== 'split') return true
    return remaining.value.amount == 0
})

/**
 * @name 'New Order Split Payment'
 * Component description: The split payment information for the new order being created on the new order form.
 * @SetupData newOrder: All the new order data sets. The information of the order being created.
 * @SetupComputed splitPaymentIsZero: Checks to see if the billing method is split.
 * @SetupComputed remaining: gets the remaining total they are needing to pay within the split payment.
 * @SetupData getOrderTotal: gets the total fee of the order
 * @SetupData lenders: The lenders they can use for the order.
 * @SetupData addSplit: Adds a split to the order.
 * @SetupData removeSplit: Removes a split from the order.
 */
export default defineComponent({
    setup() {
        const autoPopulateSplits = () => {
            if (!AscentHelper.customerFeatureEnabled('monitoring.CorporateCreditCardApproval')) return false
            if (
                !newOrder.value.disclosed_fee ||
                newOrder.value.disclosed_fee > newOrder.value.forms.feeScheduleTotal ||
                useDisclosedFee.value
            ) {
                newOrder.value.billing = {
                    ...newOrder.value.billing,
                    splits: [
                        {
                            method: 'borrower-paid',
                            amount: Number(
                                newOrder.value.forms.total === 0
                                    ? newOrder.value.disclosed_fee === null
                                        ? 0
                                        : newOrder.value.disclosed_fee
                                    : newOrder.value.forms.total,
                            ),
                        },
                        { method: 'company-credit-card', amount: 0 },
                    ],
                }
                return false
            }

            const disclosed = newOrder.value.disclosed_fee as number
            const difference = newOrder.value.forms.feeScheduleTotal - disclosed

            const splits = [
                { method: 'borrower-paid', amount: disclosed },
                { method: 'company-credit-card', amount: difference },
            ]
            newOrder.value.billing = { ...newOrder.value.billing, splits: splits }
            return true
        }

        onMounted(() => {
            if (autoPopulateSplits()) {
                return
            }

            if (newOrder.value.billing.splits.length === 0) {
                addSplit()
                addSplit()
            }
        })

        watch(
            [
                () => newOrder.value.forms.main,
                () => newOrder.value.forms.loan,
                () => newOrder.value.forms.addons,
                () => newOrder.value.forms.total,
                () => newOrder.value.appraiser_id,
                () => newOrder.value.forms.feeScheduleTotal,
            ],
            () => {
                newOrder.value.billing = { ...newOrder.value.billing, splits: [] }
                autoPopulateSplits()
            },
        )

        return {
            newOrder,
            getOrderTotal,
            lenders,
            addSplit,
            removeSplit,
            remaining,
            splitPaymentIsZero,
            autoPopulateSplits,
            splitTotal,
        }
    },
    props: {
        /**
         * Indicates if they have a card saved
         */
        hasCard: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    methods: {
        /**
         * Will check if there is a card on file, will return the card or make them add a new card
         */
        checkTypes() {
            const splitCard = newOrder.value.billing.splits.find((elem) => {
                return elem.method === 'credit-card'
            })
            if (splitCard !== undefined) return this.$emit('update:hasCard', true)
            this.$emit('update:hasCard', false)
        },
    },
    computed: {
        /**
         * Will get the billing types allowed by the lender
         */
        items() {
            const items = []
            if (this.billing.indexOf('branch') > -1) {
                items.push({ value: 'bill-branch', text: 'Bill to Branch' })
            }
            if (
                this.billing.indexOf('net-30') > -1 ||
                (this.billing.indexOf('invoice_with_permission') > -1 && AscentHelper.canDo('invoice'))
            ) {
                items.push({ value: 'net-30', text: 'Invoice' })
            }
            if (this.billing.indexOf('borrower') > -1) {
                items.push({ value: 'borrower-paid', text: 'Borrower' })
            }
            if (this.billing.indexOf('company_card') > -1) {
                items.push({
                    value: 'company-credit-card',
                    text: 'Company Credit Card',
                })
            }
            if (this.billing.indexOf('card_up_front') > -1) {
                items.push({ value: 'credit-card', text: 'Credit Card' })
            }
            return items
        },
        /**
         * Will get the billing types allowed by the lender
         */
        billing() {
            if (newOrder.value.lender_id == null) return ['borrower', 'card_up_front']
            const lender = lenders.value.find((elem) => {
                return elem.id === newOrder.value.lender_id
            })
            if (typeof lender == 'undefined') return ['borrower', 'card_up_front']
            const billing = lender.options.filter((elem) => {
                return elem.option_key === 'billing'
            })
            if (billing.length > 0) {
                return JSON.parse(JSON.stringify(billing[0].option_value))
            }
            return ['borrower', 'card_up_front']
        },
    },
})
