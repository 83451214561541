




























import { defineComponent, ref, watch } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'
import {
    appraiserValue,
    appraiserSettingsId,
    appraiserOptions,
    AppraiserOptionsSettings,
} from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    props: {
        option: {
            type: Object || undefined,
        },
    },
    setup(props, { root }) {
        const appraiserOptionsLocal = ref({
            ssrs_disabled: false,
        })

        watch(appraiserOptions && appraiserValue, () => {
            if (props.option && props.option.ssrs_disabled) {
                appraiserOptions.value.ssrs_disabled = props.option.ssrs_disabled
                appraiserOptionsLocal.value.ssrs_disabled = props.option.ssrs_disabled
            }
        })

        if (props.option && props.option.ssrs_disabled) {
            appraiserOptions.value.ssrs_disabled = props.option.ssrs_disabled
            appraiserOptionsLocal.value.ssrs_disabled = props.option.ssrs_disabled
        }

        const saveChanges = async () => {
            if (root.$route.params.id) appraiserSettingsId.value = root.$route.params.id
            await axios.post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                option_key: 'ssrs_disabled',
                option_value: appraiserOptions.value.ssrs_disabled,
            })
            appraiserOptionsLocal.value = appraiserOptions.value as AppraiserOptionsSettings
            showSnackbar('Saved successfully')
        }

        return {
            saveChanges,
            appraiserOptions,
            appraiserOptionsLocal,
        }
    },
})
