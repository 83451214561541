import axios from '@/plugins/axios'
import crypto from 'crypto-js'
import { computed, ref } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { AxiosResponseHeaders } from 'axios'

interface AccessTokenItem {
    accessToken: string
    refreshToken: string | null
    expiry: number | null
    expired?: boolean
    oldToken?: string
    ascent?: boolean
}

interface AccessTokenResponse {
    access_token: string
    expires_in: number
    refresh_token: string
    error?: string
    error_description?: string
    hint?: string
    message?: string
}

export interface AuthorizeTokenResponse {
    code: string
    state: string
}

interface SessionTokens {
    session?: string
    xsrf?: string
}

export const encrypt = (string: string) => {
    const encodedString = crypto.enc.Utf8.parse(string)
    return crypto.enc.Base64.stringify(encodedString).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

const decrypt = (string: string) => {
    const encryptedString = string.replace(/-/g, '+').replace(/_/g, '/')
    const encodedString = crypto.enc.Base64.parse(encryptedString)
    return crypto.enc.Utf8.stringify(encodedString)
}

export const hash = (string: crypto.lib.WordArray) => {
    return string.toString(crypto.enc.Base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export const randomString = (len: number) => {
    return [...Array(len)].map(() => Math.random().toString(36)[2]).join('')
}

const refreshingToken = ref(false)

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const getSessionFromStorage = (): SessionTokens => {
    const encryptedSession = localStorage.getItem('jaro_session')
    if (encryptedSession) {
        return JSON.parse(decrypt(encryptedSession)) as SessionTokens
    }
    return {} as SessionTokens
}

const getTokenFromStorage = (): AccessTokenItem => {
    const newEncryptedToken = localStorage.getItem('jaro_auth')
    if (newEncryptedToken) {
        return JSON.parse(decrypt(newEncryptedToken)) as AccessTokenItem
    }
    return {} as AccessTokenItem
}

const waitForFreshToken = async (currentToken: string, timer: number): Promise<AccessTokenItem> => {
    if (refreshingToken.value) {
        await wait(timer)
        return await waitForFreshToken(currentToken, timer)
    }

    const newToken = getTokenFromStorage()

    if (newToken.accessToken === currentToken) {
        await wait(timer)
        return await waitForFreshToken(currentToken, timer)
    }

    return newToken
}

export const flushToken = () => {
    if (localStorage.getItem('auth_state')) {
        localStorage.removeItem('auth_state')
    }

    if (localStorage.getItem('auth_verifier')) {
        localStorage.removeItem('auth_verifier')
    }

    if (localStorage.getItem('jaro_auth')) {
        localStorage.removeItem('jaro_auth')
    }

    if (localStorage.getItem('jaro_session')) {
        localStorage.removeItem('jaro_session')
    }
}

export const getRedirectPath = () => {
    const path = localStorage.getItem('jaro_redirect_path')
    if (path) {
        localStorage.removeItem('jaro_redirect_path')
        return path
    }
    return null
}

export const setRedirectPath = (path: string) => {
    if (path === '/401') {
        path = '/'
    }
    localStorage.setItem('jaro_redirect_path', path)
}

export const setSession = (headers: AxiosResponseHeaders) => {
    const store = getSessionFromStorage()
    let startSession = false
    if (Object.keys(store).length === 0) {
        startSession = true
    }
    if (Object.keys(headers).includes('x-session-token')) {
        store.session = headers['x-session-token']
    }
    if (Object.keys(headers).includes('x-xsrf-token')) {
        store.xsrf = headers['x-xsrf-token']
    }
    localStorage.setItem('jaro_session', encrypt(JSON.stringify(store)))
    if (startSession) {
        window.dispatchEvent(new CustomEvent('jaro-session-started'))
    }
}

export const setToken = (accessToken: string, refreshToken: string | null, ttl: number | null) => {
    const now = new Date()
    const ttlMilliseconds = ttl ? ttl * 1000 : 0

    const token = {
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiry: ttlMilliseconds > 0 ? now.getTime() + ttlMilliseconds : ttl,
    } as AccessTokenItem

    localStorage.setItem('jaro_auth', encrypt(JSON.stringify(token)))
}

export const hasImpersonateToken = () => {
    const token = getTokenFromStorage()
    if (token.oldToken) {
        return true
    }
    return false
}

export const setImpersonateToken = (accessToken: string) => {
    const token = getTokenFromStorage()
    if (user.value && user.value.ascent) {
        token.ascent = true
    }
    token.oldToken = token.accessToken
    token.accessToken = accessToken
    localStorage.setItem('jaro_auth', encrypt(JSON.stringify(token)))
}

export const revertImpersonateToken = () => {
    const token = getTokenFromStorage()
    if (token.ascent !== undefined) {
        delete token.ascent
    }
    if (token.oldToken) {
        token.accessToken = token.oldToken
        token.oldToken = undefined
        localStorage.setItem('jaro_auth', encrypt(JSON.stringify(token)))
    }
}

export const checkAscentImpersonationToken = () => getTokenFromStorage()?.ascent || false

const refreshAccessToken = async (refreshToken: string) => {
    const params = {
        grant_type: 'refresh_token',
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        refresh_token: refreshToken,
    }

    refreshingToken.value = true

    return await axios
        .post(process.env.VUE_APP_AUTH_URL + '/oauth/token', params)
        .then(({ data }) => {
            const token = data as AccessTokenResponse
            setToken(token.access_token, token.refresh_token, token.expires_in)
            refreshingToken.value = false
            return getTokenFromStorage()
        })
        .catch(() => {
            refreshingToken.value = false
            return getTokenFromStorage()
        })
}

export const getSession = () => {
    return getSessionFromStorage()
}

export const getToken = async () => {
    const token = getTokenFromStorage()
    const now = new Date()

    if (!token.oldToken && token.expiry && token.refreshToken && now.getTime() > token.expiry) {
        token.expired = true
        if (!refreshingToken.value) {
            return await refreshAccessToken(token.refreshToken)
        } else {
            return await waitForFreshToken(token.accessToken, 100)
        }
    } else {
        token.expired = false
    }

    return token
}

export const initializeAccessToken = async (code: string, verifier: string) => {
    const token = (await getToken()) as AccessTokenItem

    if (token.accessToken && !token.expired) {
        return 'success'
    }

    const params = {
        grant_type: 'authorization_code',
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        code: code,
        code_verifier: verifier,
    }

    return await axios
        .post(process.env.VUE_APP_AUTH_URL + '/oauth/token', params)
        .then(({ data }) => {
            const token = data as AccessTokenResponse
            setToken(token.access_token, token.refresh_token, token.expires_in)
            return 'success'
        })
        .catch((error) => {
            if (error && error.response && error.response.data) {
                const data = error.response.data as AccessTokenResponse
                if (data.error && data.hint) {
                    return data.hint
                }
            } else {
                return error.message
            }
        })
}

export const sessionStarted = computed(() => {
    const session = getSessionFromStorage()
    return !!session.session
})
