






























import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { AppraiserPanel, PanelAppraiser } from '@/types'
import {
    appraiserDeadline,
    clientDeadline,
    isConditional,
    selectedAppraiser,
    allowSelect,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { order } from '@/plugins/order/Order'
import { openAssignDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignDialog.vue'

/**
 * @name EligibilityPartial
 * Partial in AssignTable that shows if appraiser if eligible or not to take order
 */

export default defineComponent({
    props: {
        /**
         * Specific appraiser in AppraiserPanel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup(props) {
        const isSameAppraiser = computed(() => {
            if (!order.value) return false
            if (!order.value.appraiser_id) return false
            if (!props.item) return false
            return order.value.appraiser_id === props.item.appraiser.id
        })

        const assignOrder = () => {
            if (!props || !props.item || !props.item.appraiser) return
            selectedAppraiser.value = props.item.appraiser.id

            openAssignDialog({
                appraiser: props.item.appraiser as PanelAppraiser,
                appraiser_deadline: appraiserDeadline.value,
                client_deadline: clientDeadline.value,
                eligible: !!props.item.eligible?.result,
                details: props.item.eligible?.details as string,
                manually_overridden: false,
            })
        }

        const show = ref(false)

        return {
            selectedAppraiser,
            assignOrder,
            show,
            isSameAppraiser,
            isConditional,
            allowSelect,
            order,
        }
    },
})
