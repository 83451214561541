































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        dialog: false,
        saving: false,
        reason: '',
    }
}

/**
 * @name AppraiserScheduleIssue
 * Dialog to allow appraiser in Appraiser Order Screen to report a scheduling issue with a reason
 */

export default defineComponent({
    setup() {
        return { order }
    },
    components: {
        ValidationObserver,
    },
    data() {
        return reset()
    },
    methods: {
        /**
         * Opens up the dialog
         */
        open() {
            this.dialog = true
        },
        /**
         * Saves the component. Uses ACardAction and posts to '/v1/order/' + order.id + '/workflow/schedule-issue' with the reason
         */
        save() {
            Object.assign(this.$data, reset())
            if (!order.value) return
            getNotes(order.value.id)
            showSnackbar('Scheduling Issue Reported')
        },
    },
})
