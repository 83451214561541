


















































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadActionButtons from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadActionButtons.vue'
import {
    preloadData,
    reviewStep,
    purchaseContractUrl,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import PreloadPublicRecordDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadContract/PreloadPublicRecordDetails.vue'
import ContractDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadContract/ContractDetails.vue'
import PreloadFinancialAssistance from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadContract/PreloadFinancialAssistance.vue'
import { order } from '@/plugins/order/Order'
import { getFiles, files } from '@/plugins/Files'
import { newFileDialog } from '@/components/OrderScreens/Office/OrderComponents/OfficeFile/AddOfficeFile.vue'

interface SmallFile {
    type: string
    id: number
}

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadActionButtons,
        PreloadPublicRecordDetails,
        ContractDetails,
        PreloadFinancialAssistance,
    },
    setup() {
        const editData = ref(false)
        const sections = ref([] as SmallFile[])
        const editCancelled = ref(false)
        const active = ref(0)
        const activeSection = ref('Select File')

        const preloadFiles = () => {
            sections.value = []
            activeSection.value = 'Select File'
            active.value = 0
            if (!files.value.length) return
            active.value = files.value[0].id
            activeSection.value = files.value[0].type
            if (files.value.find((elem) => elem.type == 'Purchase Contract')) {
                if (!files.value.find((elem) => elem.type == 'Purchase Contract')) return
                active.value = files.value.find((elem) => elem.type == 'Purchase Contract')?.id as number
                activeSection.value = files.value.find((elem) => elem.type == 'Purchase Contract')?.type as string
            }
            files.value.filter((elem: SmallFile) => {
                sections.value.push({ type: elem.type, id: elem.id })
            })
        }

        onMounted(() => {
            if (!order.value) return
            getFiles(order.value.id)
            preloadFiles()
        })

        watch(files, (val, oldVal) => {
            if (val != oldVal) {
                preloadFiles()
            }
        })

        const cancelEdit = () => {
            editCancelled.value = true
            editData.value = false
        }

        const openEditData = () => {
            editData.value = true
            editCancelled.value = false
        }

        const saveEdit = () => {
            editData.value = false
        }

        watch(reviewStep, () => {
            if (reviewStep) editData.value = false
        })

        return {
            editData,
            openEditData,
            saveEdit,
            cancelEdit,
            preloadData,
            reviewStep,
            purchaseContractUrl,
            editCancelled,
            files,
            sections,
            active,
            activeSection,
            newFileDialog,
        }
    },
})
