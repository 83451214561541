















import { defineComponent } from '@vue/composition-api'
import {
    appraiserSettings,
    appraiserSettingsOptions,
    appraiserOptions,
    appraiserSettingsId,
    appraiserValue,
} from '@/pages/Appraiser/Profile/AppraiserProfile'
import AppraiserStatus from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserStatusPartials/AppraiserStatus.vue'
import AppraiserBasicInfo from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserBasicInfo.vue'
import AppraiserClickFees from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserClickFees.vue'
import AppraiserQcAccess from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserQcAccess.vue'
import AppraiserRoles from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserRoles.vue'
import AppraiserAccounting from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserAccounting.vue'
import AppraiserDeadlineStagger from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserDeadlineStagger.vue'
import AppraiserDisableReviews from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserDisableReviews.vue'
import AppraiserTags from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserTags.vue'
import AppraiserAllowedPayment from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserAllowedPayment.vue'
import AppraiserAssignNotes from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserAssignNotes.vue'
import AppraiserForms from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserForms.vue'
import AppraiserFeeSchedule from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserFeeSchedule.vue'
import AppraiserScheduler from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserScheduler.vue'
import AppraiserOfficeUnlink from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserOfficeUnlink.vue'
import AppraiserDisableSsrs from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserDisableSsrs.vue'
import AscentHelper from '@/helpers/ascent-helper'
import AppraiserCustomerCustomFiles from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserCustomerCustomFiles.vue'
import AppraiserExternalId from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserExternalId.vue'
import AppraiserAllowCardRefund from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserAllowCardRefund.vue'
import AppraiserJaroKitBeta from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserJaroKitBeta.vue'

/**
 * @name AppraiserSettingsPage
 * Section of appraiser settings where the status and ability to change status is located.
 */

export default defineComponent({
    components: {
        AppraiserBasicInfo,
        AppraiserClickFees,
        AppraiserQcAccess,
        AppraiserRoles,
        AppraiserAccounting,
        AppraiserDeadlineStagger,
        AppraiserDisableReviews,
        AppraiserTags,
        AppraiserAssignNotes,
        AppraiserStatus,
        AppraiserFeeSchedule,
        AppraiserForms,
        AppraiserScheduler,
        AppraiserAllowedPayment,
        AppraiserOfficeUnlink,
        AppraiserDisableSsrs,
        AppraiserCustomerCustomFiles,
        AppraiserExternalId,
        AppraiserAllowCardRefund,
        AppraiserJaroKitBeta,
    },
    setup(props, { root }) {
        appraiserSettingsId.value = root.$route.params.id

        const filteredSettingsOptions = appraiserSettingsOptions.value.filter((option: string) => {
            if (option === 'click-fees') {
                return AscentHelper.customerFeatureEnabled('orderScreen.clickFee')
            }
            if (option === 'jaro-kit-beta') {
                return appraiserValue.value && appraiserValue.value.customer_id > 0
            }
            return true
        })

        return {
            appraiserSettings,
            appraiserOptions,
            filteredSettingsOptions,
        }
    },
})
