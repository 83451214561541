










































import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
import AwsS3 from '@uppy/aws-s3'
import $axios from '@/plugins/axios'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import { defineComponent, ref, onMounted, computed, set } from '@vue/composition-api'
import { ValidationProvider, extend } from 'vee-validate'
import { order } from '@/plugins/order/Order'
import { report } from '@/pages/AlternativeProducts/Kit/KitReport'

extend('required', {
    validate(value) {
        return !!value || 'Image is required'
    },
    computesRequired: true,
})

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        formData: {
            type: Object,
            default: () => {
                return {}
            },
        },
        rules: String,
        value: String,
    },
    setup(props, { emit }) {
        const progress = ref(0)
        const url = computed(() => {
            if (props.value && props.value.includes('https')) {
                return props.value
            }
            if (props.value) {
                return report.value?.images[props.value] ?? props.value
            }
            return ''
        })
        const uploading = ref(false)
        const element = `upload-${(Math.random() * 10000).toFixed(0).padStart(6, '0')}`
        const validationProvider = ref<null | { validate: (value: string | undefined) => void }>(null)

        const getUploadParameters = (file: { path: string }) =>
            $axios
                .post('/v1/files/getSignedURL', {
                    url: 'order/' + order.value?.id + '/kit',
                    file,
                    hash: true,
                    expiry: '+60 minutes',
                })
                .then((response) => {
                    file.path = response.data.path

                    return {
                        url: response.data.url,
                        method: response.data.method,
                        fields: response.data.fields,
                        headers: response.data.headers,
                    }
                })

        const setUppy = () => {
            try {
                const uppy = Uppy({
                    autoProceed: true,
                    restrictions: {
                        maxNumberOfFiles: 1,
                        minNumberOfFiles: 1,
                        allowedFileTypes: ['.jpg', '.jpeg', '.png', '.webp'],
                    },
                })
                    .use(AwsS3, { getUploadParameters })
                    .use(DragDrop, { target: '#' + element })

                uppy.on('file-added', () => {
                    uploading.value = true
                })

                uppy.on('upload-success', (file: { path: string }) => {
                    $axios
                        .post('/v1/kitReport/' + report.value?.id + '/attach-file', {
                            file: file.path,
                        })
                        .then(({ data }) => {
                            if (report.value) {
                                set(report.value, 'images', data.images)
                            }
                            emit('change', data.id)
                            uploading.value = false
                            uppy.close()
                        })
                })

                uppy.on('upload-progress', (_, uploadProgress) => {
                    if (uploadProgress.bytesUploaded !== uploadProgress.bytesTotal) {
                        progress.value = Math.round((uploadProgress.bytesUploaded / uploadProgress.bytesTotal) * 100)
                    } else {
                        progress.value = 100
                    }
                })
            } catch (e) {
                //
            }
        }

        onMounted(() => {
            setUppy()
        })

        const onDelete = () => {
            emit('change', '')
            setUppy()
        }

        return {
            progress,
            url,
            uploading,
            validationProvider,
            onDelete,
            element,
        }
    },
    components: {
        ValidationProvider,
    },
})
