

































































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import EditInvoiceEmailsDialog from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/EditInvoiceEmailsDialog.vue'
import { InvoiceOption, LenderOptions } from '@/types'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { dialog } from './EditInvoiceEmailsDialog.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { EditInvoiceEmailsDialog },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const value = ref({
            hold: false,
            split: false,
            send_payment: false,
            send_invoice: false,
            send_completed: false,
            do_not_send_invoice: false,
            do_not_show_surcharge: false,
            emails: [],
        } as InvoiceOption)

        if (props.option !== undefined) {
            value.value = props.option.option_value as InvoiceOption
        }

        const saveEmail = (emails: string[]) => {
            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'invoice-options',
                    option_value: { ...value.value, emails },
                })
                .then(() => {
                    value.value.emails = emails
                    dialog.value = false
                    showSnackbar('Emails saved successfully')
                })
        }

        const saveOption = () => {
            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'invoice-options',
                    option_value: value.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            value,
            saveEmail,
            saveOption,
        }
    },
})
