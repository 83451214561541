var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"tile":"","flat":"","color":"white","title":_vm.title ? 'Possible Duplicates' : ''}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"mx-8 no-hover white",attrs:{"three-line":""}},_vm._l((_vm.order.incremental.duplicates),function(duplicate,duplicateIndex){return _c('v-list-group',{key:duplicate.id,attrs:{"value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{attrs:{"href":'../order/' + duplicate.duplicate_id,"target":"_blank"}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("$vuetify.icons.fileAlt")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{attrs:{"color":"primary"},domProps:{"innerHTML":_vm._s(
                                    duplicate.duplicate_cache.customer_order_id +
                                    ' / ' +
                                    duplicate.duplicate_cache.order.productList
                                )}}),(
                                    duplicate.duplicate_cache != null &&
                                    duplicate.duplicate_cache.appraiserName != null
                                )?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(duplicate.duplicate_cache.appraiserName)}}):_vm._e()],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","color":"error","text":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeDuplicate(duplicate)}}},[_vm._v(" Remove Duplicate "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("$vuetify.icons.trash")])],1)],1)],1)]},proxy:true}],null,true)},[(_vm.duplicateFiles.length > 0 && !_vm.duplicateFiles[duplicateIndex].length)?_c('span',{staticClass:"grey--text ml-16",domProps:{"innerHTML":_vm._s('This order has no files')}}):_vm._e(),_vm._l((_vm.duplicateFiles[duplicateIndex]),function(file,fileIndex){return _c('v-list-item',{key:file.id,staticClass:"ml-16"},[_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticClass:"no-underline",domProps:{"innerHTML":_vm._s(file.type)},on:{"click":function($event){return _vm.openFile(file)}}})]),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.$ascent.formatIsoDate(file.created_at, 'MMM d, yyyy - t'))}})],1),_c('v-list-item-action',[_c('v-row',[_c('v-col',{staticClass:"px-2",attrs:{"align-self":"center"}},[_c('v-chip',{attrs:{"small":"","label":"","dark":""},domProps:{"innerHTML":_vm._s(file.status)}})],1),_c('v-col',{staticClass:"px-2"},[(!file.addedToOrder)?_c('v-btn',{attrs:{"text":"","color":"secondarydarken3","target":"_blank"},on:{"click":function($event){return _vm.addFileToOrder(file, fileIndex, duplicateIndex)}}},[_vm._v(" Add To Order "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("$vuetify.icons.plus")])],1):_vm._e(),(file.addedToOrder)?_c('v-btn',{attrs:{"text":"","color":"secondary","disabled":""}},[_vm._v(" File Added "),_c('v-icon',{attrs:{"right":"","text":""}},[_vm._v("$vuetify.icons.success")])],1):_vm._e()],1)],1)],1)],1)})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }