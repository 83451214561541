



































































import { defineComponent } from '@vue/composition-api'
import { searchText, searchType, searchTypeItems, updateQuoteTable } from '@/pages/AMC/Quotes/Quotes'
import { PreQuote } from '@/pages/AMC/Quotes/Partials/PreQuote.vue'
import TableUserSelectDialog, { dialog } from '@/components/OrderTable/TablePartials/TableUserSelectDialog.vue'
import FilterDisplays from '@/components/OrderTable/TablePartials/FilterViews/FilterDisplays.vue'

/**
 * @name QuoteSearch
 * @description Used to search quotes. Uses status drop down, selected dates, and selected search type to get quotes data for table.
 */
export default defineComponent({
    name: 'QuoteSearch',
    components: { TableUserSelectDialog, FilterDisplays },
    setup() {
        const updateSearchItem = (searchItem: string) => {
            searchType.value = searchItem
            updateQuoteTable()
        }

        const openColumnChooser = (event: MouseEvent) => {
            const targetElement = event.currentTarget as HTMLElement
            PreQuote.value.ej2Instances.columnChooserModule.openColumnChooser(targetElement.offsetLeft - 250, 0)
        }
        return {
            searchText,
            searchType,
            searchTypeItems,
            updateQuoteTable,
            openColumnChooser,
            dialog,
            updateSearchItem,
        }
    },
})
