




















































import { computed, defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { refreshOrder } from '@/plugins/order/Order'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { File, Tag } from '@/types'
import { user } from '@/plugins/User'
import OrderConditions from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/OrderConditionAction.vue'

export default defineComponent({
    components: {
        OrderConditions,
    },
    setup() {
        const loading = ref(false)
        const primaryApprovalTag = ref({} as Tag)
        const isPrimary = ref(false)
        const reviewSelection = ref('')
        const rejectionNotes = ref('')
        const appraisalFilePath = ref('')

        const checkOrderTagTask = computed(() => {
            if (!order?.value?.tags) return false

            order?.value?.tags?.find((elem) => {
                if (['Primary Review Required', 'Commercial Review'].indexOf(elem.tag as string) > -1)
                    primaryApprovalTag.value = elem
            })

            return Object.keys(primaryApprovalTag.value).length > 1
        })

        const addAppraisalFile = (file: File) => {
            appraisalFilePath.value = file.path
        }

        const completeTask = () => {
            $axios
                .post('/v1/task/' + primaryApprovalTag.value.id + '/complete')
                .then(() => {
                    showSnackbar('Order Review Approved Successfully')
                    refreshOrder()
                    loading.value = false
                })
                .catch(() => {
                    loading.value = false
                })
        }

        const completeApprovalAction = async () => {
            confirm('Approve Review', 'Are you sure you want to approve this review?').then((confirm) => {
                if (confirm && order.value) {
                    isPrimary.value =
                        order?.value?.incremental?.order_appraisers?.some((elem) => {
                            return elem.role === 'Primary' && elem.appraiser.user_id === user?.value?.id
                        }) ?? false
                    if (!isPrimary.value)
                        return showSnackbar('Order Review Must Be Approved By Primary Appraiser', 'red')
                    loading.value = true

                    if (appraisalFilePath.value !== '') {
                        $axios
                            .post('/v1/order/' + order?.value?.id + '/file', {
                                type: 'Appraisal PDF',
                                path: appraisalFilePath.value,
                                status: 'Appraiser',
                            })
                            .then(() => {
                                completeTask()
                            })
                        return
                    }
                    completeTask()
                }
            })
        }

        const completeReview = () => {
            const reviewTag = order?.value?.tags?.find((elem) => {
                return elem.tag === 'Review Required'
            })
            if (!reviewTag) {
                showError('The order does not have the correct tag')
                return
            }
            confirm('Approve Review', 'Are you sure you want to approve this review?').then((confirm) => {
                if (confirm && order.value) {
                    loading.value = true
                    $axios
                        .post('/v1/task/' + reviewTag.id + '/complete')
                        .then(() => {
                            showSnackbar('Order Review Approved Successfully')
                            refreshOrder()
                            loading.value = false
                            isPrimary.value = false
                            reviewSelection.value = ''
                            rejectionNotes.value = ''
                        })
                        .catch(() => {
                            loading.value = false
                        })
                }
            })
        }

        const rejectReview = () => {
            confirm('Reject Review', 'Are you sure you want to reject this review?').then((confirm) => {
                if (primaryApprovalTag.value.tag === 'Primary Review Required') {
                    isPrimary.value =
                        order.value?.incremental?.order_appraisers?.some((elem) => {
                            return elem.role === 'Primary' && elem.appraiser.user_id === user?.value?.id
                        }) ?? false
                    if (!isPrimary.value)
                        return showSnackbar('Order Review Must Be Rejected By Primary Appraiser', 'red')
                }

                if (primaryApprovalTag.value.tag === 'Commercial Review') {
                    isPrimary.value =
                        order.value?.incremental?.order_appraisers?.some((elem) => {
                            return elem.role === 'Reviewer' && elem.appraiser.user_id === user?.value?.id
                        }) ?? false
                    if (!isPrimary.value)
                        return showSnackbar('Order Review Must Be Rejected By Review Appraiser', 'red')
                }

                if (confirm && order.value) {
                    loading.value = true
                    $axios
                        .post('/v1/order/' + order.value.id + '/action/review-rejected', {
                            rejectionReason: rejectionNotes.value,
                        })
                        .then(() => {
                            showSnackbar('Order Review Rejected')
                            refreshOrder()
                            loading.value = false
                        })
                        .catch(() => {
                            loading.value = false
                        })
                }
            })
        }

        const submitReview = () => {
            if (reviewSelection.value == 'reject') return rejectReview()
            if (reviewSelection.value == 'complete') return completeReview()
            completeApprovalAction()
        }

        return {
            completeReview,
            completeApprovalAction,
            addAppraisalFile,
            appraisalFilePath,
            checkOrderTagTask,
            order,
            loading,
            primaryApprovalTag,
            reviewSelection,
            submitReview,
            rejectionNotes,
        }
    },
    computed: {
        url() {
            return 'order/' + order.value?.id
        },
    },
})
