































































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { DateTime } from 'luxon'
import AscentHelper from '@/helpers/ascent-helper'
import { refreshQuotes, id } from '@/pages/AMC/Quotes/Quotes'
import { GET_PANELS } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { AppraiserPanel } from '@/types'
import Vue from 'vue'

function reset() {
    return {
        dialog: false,
        error: false,
        appraiser: {
            appraiser: {
                id: 0,
            },
        } as AppraiserPanel,
        quote: {
            quotable_id: 0,
            quotable: '',
            fee: null,
            complete: null,
            appraiser_id: 0,
            status: 'Excluded',
            quote_token: 'None',
            complete_type: 'date',
        },
        adding: false,
        DateTime: DateTime,
    }
}

export default defineComponent({
    components: {
        ValidationObserver,
    },
    watch: {
        declined(newVal) {
            this.quote.status = newVal ? 'Decline' : 'Excluded'
        },
    },
    setup() {
        const declined = ref(false)
        const reason = ref('')

        return {
            declined,
            reason,
            id,
            order,
        }
    },
    data() {
        return reset()
    },
    methods: {
        open(appraiser: AppraiserPanel) {
            this.appraiser = appraiser
            this.quote.appraiser_id = appraiser.appraiser.id
            this.quote.quotable_id = order?.value?.id as number
            this.quote.quotable = 'order'
            this.quote.complete_type = order.value?.propertyType === 'Commercial' ? 'weeks' : 'date'
            this.dialog = true
        },
        submit() {
            this.adding = true
            GET_PANELS({ type: 'order', id: order?.value?.id as number })
            refreshQuotes().then(() => {
                showSnackbar('Quote Added Successfully')
                Object.assign(this.$data, reset())
                const form = this.$refs.form as Vue & { reset: () => void }
                form.reset()
                this.declined = false
                this.reason = ''
            })
        },
        allowedDates(date: string) {
            return AscentHelper.isDateBlackedOut(date) && this.isWeekday(date)
        },
        isWeekday(date: string) {
            return ![5, 6].includes(new Date(date).getDay())
        },
    },
})
