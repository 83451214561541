














import { defineComponent, PropType } from '@vue/composition-api'
import { DynamicField } from '@/types/DynamicForm'
import LenderAddressAuditLog from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderCompanyInfo/LenderCompanyInfoPartials/LenderAddressAuditLog.vue'

export default defineComponent({
    name: 'DynamicFormFieldView',
    props: {
        config: {
            type: Object as PropType<DynamicField>,
            default: () => ({ sections: [] }),
        },
        showLabels: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        LenderAddressAuditLog,
    },
    setup(props) {
        const field = props.config

        const formatField = (val: unknown) => {
            if (Array.isArray(val)) return val.join(', ')

            if (typeof val === 'boolean') return val ? 'Yes' : 'No'

            return val
        }

        return {
            field,
            formatField,
        }
    },
})
