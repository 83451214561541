


































import { defineComponent, ref } from '@vue/composition-api'
import OfficeFeeTable from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/Partials/OfficeFeeTable.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import Vue from 'vue'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { Product } from '@/types'
import { FeeUpdateInput } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderFeeTable.vue'
import OfficeAddProductDialog from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/Partials/OfficeAddProductDialog.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'

const products = ref([] as Product[])
const dataProducts = ref([] as Product[])
const fees = ref()
const addons = ref([] as Product[])

const getFeeList = () => {
    if (!order.value) return
    $axios.get('/v1/order/' + order.value.id + '/product').then((response) => {
        fees.value = response.data
        addons.value = response.data.other.filter(
            (elem: Product) => elem.name != 'Tech Fee' && elem.name != 'Innovation Fee',
        )
    })
}
const dialog = ref(false)
const techFee = ref(undefined as Product | undefined)
const innovationFee = ref(undefined as Product | undefined)
export const openFormsAndFees = () => {
    if (!order.value) return
    if (!order.value?.incremental) return
    getFeeList()
    products.value = JSON.parse(JSON.stringify(orderProducts.value)).filter(
        (elem: Product) => elem.product_name != 'Tech Fee' && elem.product_name != 'Innovation Fee',
    )
    techFee.value = JSON.parse(JSON.stringify(orderProducts.value)).find(
        (elem: Product) => elem.product_name == 'Tech Fee',
    )
    innovationFee.value = JSON.parse(JSON.stringify(orderProducts.value)).find(
        (elem: Product) => elem.product_name == 'Innovation Fee',
    )
    dialog.value = true
}

//Total fee AMC fee

/**
 * @name OfficeFormsAndFeesDialog
 * Dialog in OfficeOrderFees to show the different products on the order and the fee associated with product
 *
 * @SetupData headers - headers of the table
 */

export default defineComponent({
    components: { OfficeFeeTable, OfficeAddProductDialog },
    setup() {
        const headers = [
            { text: 'Product', value: 'form.name' },
            { text: 'Type', value: 'form.type' },
            { text: 'Total Fee', value: 'line_total' },
            { text: '', value: 'action', align: 'center' },
        ]
        const error = ref()
        const loading = ref(false)
        const updateFees = () => {
            if (products.value.filter((elem) => elem.form.type === 'Main Product').length !== 1) {
                error.value = 'You can only have one main product on the order and you must have at least one'
            }
            if (products.value.filter((elem) => elem.form.type === 'Loan Type').length !== 1) {
                error.value = 'You can only have one loan type on the order and you must have at least one'
            }
            if (error.value) return false
            error.value = ''
            loading.value = true
            if (!order.value) return

            dataProducts.value = products.value

            if (techFee.value) dataProducts.value = [...dataProducts.value, techFee.value]
            if (innovationFee.value) dataProducts.value = [...dataProducts.value, innovationFee.value]

            $axios
                .patch('/v1/order/' + order.value.id + '/action/update-products', {
                    products: dataProducts.value,
                })
                .then(() => {
                    dialog.value = false
                    techFee.value = undefined
                    innovationFee.value = undefined
                    if (!order.value) return
                    refreshOrder().then(() => {
                        if (!order.value?.incremental) return
                        Vue.set(order.value.incremental, 'products', products.value)
                        order.value.loan = products.value.find((product) => (product.formType = 'Loan Type'))
                            ?.product_name as string
                    })

                    showSnackbar('Fees and Forms Updated Successfully')
                    loading.value = false
                })
                .catch(() => {
                    showError('Something went wrong!')
                    loading.value = false
                })
        }

        const updateInput = (updatedInput: FeeUpdateInput) => {
            if (updatedInput.item.formType === 'Main Product') {
                products.value[products.value.indexOf(updatedInput.item)].amc_value =
                    parseFloat(updatedInput.item.line_total as string) -
                    parseFloat(updatedInput.item.appraiser_value as string)
            }
            if (updatedInput.item.product_name === 'Tech Fee') {
                products.value[products.value.indexOf(updatedInput.item)].appraiser_value =
                    parseFloat(updatedInput.item.amc_value as string) * -1
            }
        }

        const deleteFee = (item: Product) => {
            if (!order.value || !order.value?.incremental) return
            products.value.splice(products.value.indexOf(item), 1)
            orderProducts.value.splice(products.value.indexOf(item), 1)
        }

        const addFee = (line: Product) => {
            products.value.push({
                product_id: line.form?.id as number,
                product_name: line.form?.name as string,
                amc_value: line.amc_value,
                appraiser_value: 0,
                line_total: line.amc_value,
                form: line.form,
            })
            if (!order.value || !order.value?.incremental) return
            orderProducts.value.push({
                product_id: line.form?.id as number,
                product_name: line.form?.name as string,
                amc_value: line.amc_value,
                appraiser_value: 0,
                line_total: line.amc_value,
                form: line.form,
            })
        }

        return {
            headers,
            order,
            dialog,
            fees,
            updateFees,
            products,
            updateInput,
            error,
            loading,
            deleteFee,
            addFee,
            addons,
            techFee,
            innovationFee,
            dataProducts,
        }
    },
})
