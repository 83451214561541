


















import axios from 'axios'

import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            mainIndex: [],
            items: [],
        }
    },
    mounted() {
        this.searchMain()
    },
    methods: {
        searchMain() {
            this.loading = true
            axios.post('/v1/search', { type: 'main_product', object: true }).then((response) => {
                this.mainIndex = response.data
                this.loading = false
            })
        },
    },
})
