






















import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { Directive } from '@/types/Directive'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

/**
 * @name OrderDetailsProductsDirectives
 * Component in the OrderDetails that shows the Order Type and the directives if there are any
 *
 * @SetupMethod removeDirecitve - takes in directive, and removes that directive from the order
 */

export default defineComponent({
    setup() {
        const removeDirective = (directive: Directive) => {
            confirm('Remove this directive?', 'This will remove the custom directives from the order form.').then(
                (confirm) => {
                    if (confirm) {
                        $axios.delete('v1/order/' + order.value?.id + '/directive/' + directive.id).then(() => {
                            showSnackbar('Directive removed successfully')
                            order.value?.incremental?.directives.splice(
                                order.value?.incremental?.directives.indexOf(directive),
                                1,
                            )
                        })
                    }
                },
            )
        }
        return {
            order,
            removeDirective,
        }
    },
})
