














































































import { defineComponent, onMounted, PropType, ref, computed } from '@vue/composition-api'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { DynamicForm, DynamicField } from '@/types/DynamicForm'
import AscentHelper from '@/helpers/ascent-helper'
import DynamicFieldView from '@/components/DynamicForm/DynamicFormFieldView.vue'
import DynamicFieldEdit from '@/components/DynamicForm/DynamicFormFieldEdit.vue'
import { createServerStrategy } from './ServerSettings'

const resolveCanDo = (maybeCanDo: unknown) => {
    if (typeof maybeCanDo === 'string') {
        if (maybeCanDo.startsWith('canDo')) {
            return AscentHelper.canDo(maybeCanDo.split(':')[1])
        } else if (maybeCanDo.startsWith('!canDo')) {
            return !AscentHelper.canDo(maybeCanDo.split(':')[1])
        }
    }
    return maybeCanDo
}

const getFieldValues = (acc: { [key: string]: unknown }, field: DynamicField) => {
    if (!field.name) return acc

    acc[field.name] = field.val
    return acc
}

export default defineComponent({
    name: 'DynamicForm',
    components: {
        ValidationObserver,
        DynamicFieldView,
        DynamicFieldEdit,
    },
    props: {
        config: {
            type: Object as PropType<DynamicForm>,
            default: () => ({ fields: [] }),
        },
        configData: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props) {
        const fields = ref(
            props.config.fields.map((fieldConfig) => ({
                // set default values
                val: null as unknown,
                showInEditMode: true,
                showInViewMode: true,

                // set attributes provided via config
                ...fieldConfig,

                // resolve canDos
                ...Object.keys(fieldConfig).reduce(
                    (acc, key) => ((acc[key] = resolveCanDo(fieldConfig[key])), acc),
                    {} as DynamicField,
                ),
            })),
        )
        const formFields = ref<DynamicField[]>(JSON.parse(JSON.stringify(fields.value)))
        const formValues = computed(() => formFields.value.reduce(getFieldValues, {}))
        const serverValues = computed(() => fields.value.reduce(getFieldValues, {}))
        const isEditMode = ref<boolean>(false)
        const readonly = !!resolveCanDo(props.config.readonly)

        const serverStrategy = createServerStrategy(props.config.serverSettings, props.configData)

        const onEdit = () => {
            isEditMode.value = true
            formFields.value.forEach((field) => {
                field.val = serverValues.value[field.name]
            })
        }

        const onSave = () => {
            isEditMode.value = false
            fields.value.forEach((field) => {
                field.val = formValues.value[field.name]
            })
            showSnackbar(`${props.config.label} Updated successfully!`)
        }

        const onCancel = () => {
            isEditMode.value = false
        }

        const parseServerData = (json: { [key: string]: { [key: string]: unknown } }) => {
            const values = serverStrategy.parse(json)

            fields.value.forEach((field) => {
                field.val = values[field.name]
            })
        }

        const formatServerData = () => {
            const values = formFields.value.reduce(getFieldValues, {})

            return serverStrategy.format(values)
        }

        onMounted(() => {
            if (!props.config.serverSettings) return

            axios.get(serverStrategy.getUrl).then((res) => {
                parseServerData(res.data)
            })
        })

        return {
            isEditMode,
            fields,
            formFields,
            readonly,
            serverStrategy,
            onEdit,
            onSave,
            onCancel,
            formatServerData,
        }
    },
})
