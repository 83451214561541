




import { defineComponent } from '@vue/composition-api'
import { Quote } from '@/types/quotes'
import AppraiserZipCodeSearch from '@/pages/Shared/CustomerSettings/AppraiserZipCodeSearch/AppraiserZipCodeSearch.vue'

export default defineComponent({
    components: {
        AppraiserZipCodeSearch,
    },
    mounted() {
        const zipFromData = this.data as Quote
        const appraiserSearchRef = this.$refs.appraiserSearchRef as Vue & { searchZip: (zipCode: string) => void }
        if (zipFromData && appraiserSearchRef) {
            appraiserSearchRef.searchZip(zipFromData.address.zip)
        }
    },
})
