



































































































































































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order } from '@/plugins/order/Order'
import { Appraiser } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { loadIncremental, updateOrder } from '@/plugins/order/Order'
import { open as openEditOrderInvoiceDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderInvoiceDialog.vue'
import { openEditMilestone } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/EditMilestoneDialog.vue'

interface DialogContent {
    appraiser: Appraiser
    appraiser_deadline: string
    client_deadline: string
    eligible: boolean
    details: string
    amc_fee?: number
    appraiser_fee?: number
    client_fee?: number
    disable_edit?: boolean
}

/**
 * @name ConditionalAssignDialog
 * Dialog to conditionally assign appraiser to the order
 */

export default defineComponent({
    props: {
        /**
         * Boolean to determine if this is being done in the assignWorkflow
         */
        assignWorkflow: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        ValidationObserver,
    },
    setup() {
        return { order, openEditOrderInvoiceDialog, openEditMilestone }
    },
    data() {
        return {
            dialog: false,
            /**
             * content of the dialog, specifically the appraiser, deadlines for the client and appraiser, and if appraiser is eligible
             */
            dialogContent: {
                appraiser: {
                    name: '',
                },
                appraiser_deadline: '',
                client_deadline: '',
                appraiser_fee: 0,
                client_fee: 0,
                eligible: false,
                details: '',
                disable_edit: false,
            } as DialogContent,
            resolve: null,
            assigning: false,
            /**
             * Reason for override if appraiser is not eligible
             */
            overrideReason: null,
            error: false,
            loading: false,
        }
    },
    methods: {
        /**
         * Opens the dialog and sets the dialog content
         * @param dialogContent - specific content for the appraiser and dialog
         */
        open(dialogContent: DialogContent) {
            this.dialogContent = dialogContent
            if (!this.dialogContent.appraiser_deadline || !this.dialogContent.client_deadline) this.error = true
            this.dialog = true
        },
        /**
         * Confirms the assignment and assigns the order to the appraiser
         */
        confirm() {
            if (!order.value) return
            this.loading = true
            if (!this.assignWorkflow) return this.$emit('confirm-assign')
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/assign', {
                    appraiser: this.dialogContent.appraiser_deadline,
                    client: this.dialogContent.client_deadline,
                    selected: this.dialogContent.appraiser.id,
                    eligible: this.dialogContent.eligible,
                    details: this.dialogContent.details,
                    override_reason: this.overrideReason,
                })
                .then(() => {
                    if (!order.value) return
                    loadIncremental()
                    ;(order.value.appraiser_id = this.dialogContent.appraiser.id),
                        (order.value.appraiserName = this.dialogContent.appraiser.name),
                        (order.value.appraiserDeadline = new Date(this.dialogContent.appraiser_deadline).getTime()),
                        (order.value.clientDeadline = new Date(this.dialogContent.client_deadline).getTime())
                    updateOrder()

                    if (!this.dialogContent.eligible) {
                        showSnackbar('Override Submitted')
                    } else {
                        showSnackbar('Appraiser Assigned Successfully!')
                    }

                    this.assigning = false
                })
                .catch(() => {
                    this.assigning = false
                })

            this.dialog = false
            this.loading = false
        },
        /**
         * Closes the dialog
         */
        cancel() {
            this.dialog = false
            this.error = false
        },
    },
})
