




















































































import { computed, defineComponent, inject, onMounted, ref } from '@vue/composition-api'
import OrderInvoice from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/OrderInvoice.vue'
import { order } from '@/plugins/order/Order'
import {
    reviewDisabled,
    reviewNotes,
    finalReviewNote,
    billingAlertsReviewed,
    paymentNotes,
} from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import AEditor from '@/global/Form/AEditor.vue'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import _ from 'lodash'
import { BillingOption } from '@/types'
import { orderProducts } from '@/plugins/order/OrderProducts'

/**
 * @name ReviewFees
 * Step in the review workflow to look over the fees on the order
 *
 * @SetupData borrowerEmailNeeded - boolean to determine if the rep has to get the borrower email or not
 * @SetupMethod capitalizeBilling - capitalizes the billing method
 * @SetupComputed billingNotes - looks at the lender options and sets billing notes if the lender has them set
 * @SetupComputed billingMethods - looks at lender options and pulls out the billing if set
 * @SetupComputed invoiceOnFinalInspection - shows the invoice options available for lender if set
 *
 * @onMounted takes review notes and organizes them into a final note for the user to look at and edit before they send to client
 */

export default defineComponent({
    components: { AEditor, OrderInvoice },
    props: {},

    setup() {
        const borrowerEmailNeeded = ref(true)
        const sidebarItem = inject('sidebarItem', ref(''))
        const capitalizeBilling = (billingMethod: string | number) => {
            if (typeof billingMethod == 'string') {
                const method = billingMethod.split('_')
                return method.map((string) => _.capitalize(string) + ' ').join('')
            }
        }

        const billingNotes = computed(() => {
            if (lenderOptions.value['billing-notes']) {
                return (lenderOptions.value['billing-notes'] as BillingOption[])[0]
            }
            return ''
        })

        const billingMethods = computed(() => {
            if (lenderOptions.value['billing']) {
                return (lenderOptions.value['billing'] as BillingOption[]).filter((elem) => typeof elem === 'string')
            }
            return []
        })

        const invoiceOnFinalInspection = computed(() => {
            if (lenderOptions.value['billing']) {
                const billing = [] as string[]
                ;(lenderOptions.value['billing'] as BillingOption[]).forEach((elem) => {
                    if (typeof elem === 'number') {
                        const product = orderProducts.value.find((product) => product.product_id == elem)
                        if (product) billing.push(product.product_name)
                    }
                })
                return billing
            }
            return []
        })
        onMounted(() => {
            billingAlertsReviewed.value = true
            if (paymentNotes.value.length) {
                billingAlertsReviewed.value = false
            }
            let finalNote = ''
            if (reviewNotes.value.length === 0) finalReviewNote.value = ''
            reviewNotes.value.forEach((item, index) => {
                finalNote += `<p>${index + 1}. ${item.note}</p>`
            })

            if (!finalNote) return
            finalReviewNote.value = `<strong>Thank you for your order. Upon review, it looks like we still need the following information before we can assign this to an appraiser: </strong>${finalNote}`
        })

        return {
            billingAlertsReviewed,
            paymentNotes,
            borrowerEmailNeeded,
            finalReviewNote,
            order,
            reviewDisabled,
            sidebarItem,
            lenderOptions,
            capitalizeBilling,
            billingMethods,
            billingNotes,
            invoiceOnFinalInspection,
        }
    },
})
