var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":""}},[_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","align-self":"center"}},[_c('a-select-field',{attrs:{"items":_vm.items,"item-disabled":"disabled","value":_vm.order && _vm.order.incremental.billing_method,"name":"billing method","label":"Bill Type","dense":"","attach":""},on:{"change":_vm.updateBilling}})],1),(
                _vm.order.incremental.billing_details &&
                _vm.order.incremental.billing_details.auth_code &&
                _vm.order.incremental.billing_method !== 'split'
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s('Auth Code: ' + _vm.order.incremental.billing_details.auth_code)}})]):_vm._e(),(_vm.order.incremental.billing_method === 'split')?_c('v-col',[_c('v-list',{attrs:{"color":"transparent","flat":""}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Splits")]),_c('v-list-item-action',[_c('a-btn-menu',[_c('v-list-item',{on:{"click":function($event){return _vm.$refs.splitpayment.open(_vm.order.incremental.billing_details)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("$vuetify.icons.edit")]),_c('v-list-item-subtitle',[_vm._v("Edit Split")])],1)],1)],1)],1),_c('v-divider'),_vm._l((_vm.order.incremental.billing_details.splits),function(split,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.paymentTypes.find(function (elem) { return elem.id === split.method }).text)+" ")]),_c('v-list-item-action',[_vm._v(_vm._s(_vm.$ascent.currency(split.amount)))])],1)})],2),(_vm.order.incremental.billing_details.note)?_c('p',{staticClass:"text-subtitle-2"},[_vm._v("Splits Notes:")]):_vm._e(),(_vm.order.incremental.billing_details.note)?_c('v-clamp',{staticStyle:{"padding":"5px"},attrs:{"autoresize":"","max-lines":2,"ellipsis":"..."},domProps:{"innerHTML":_vm._s(_vm.order.incremental.billing_details.note)},scopedSlots:_vm._u([{key:"after",fn:function(ref){
            var toggle = ref.toggle;
            var clamped = ref.clamped;
            var expanded = ref.expanded;
return (clamped || expanded)?_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"primary"},on:{"click":toggle}},[(clamped)?_c('span',[_vm._v("Expand")]):_vm._e(),(expanded)?_c('span',[_vm._v("Collapse")]):_vm._e()]):_vm._e()}}],null,true)}):_vm._e()],1):_vm._e(),_c('add-credit-card',{ref:"addcard",attrs:{"show":false}}),_c('charge-company-card',{ref:"companycard",attrs:{"id":_vm.order.id,"total":_vm.order.incremental.total.total,"due":_vm.$orderHelper.due.value,"show":false}}),_c('split-payment',{ref:"splitpayment"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }