
































import { defineComponent, ref } from '@vue/composition-api'
import { appraiserOptions, appraiserSettingsId } from '@/pages/Appraiser/Profile/AppraiserProfile'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ValidationObserver } from 'vee-validate'

const appraiserStatusDialog = ref(false)

export const openAppraiserStatusDialog = () => {
    appraiserStatusDialog.value = true
}

/**
 * @name EditAppraiserStatusDialog
 * Dialog in AMC appraiser status that will update the appraiser status
 *
 * @SetupMethod saveChanges - makes call and saves the appraiser status. Closes dialog and emits to parent component that the status was saved
 * @SetupData appraiserStatuses - statuses the user can change the appraiser to
 * @SetupMethod openAppraiserStatusDialog - opens up the dialog.
 */

export default defineComponent({
    components: { ValidationObserver },
    setup(_, { root, emit }) {
        const appraiserStatuses = ['Active', 'Pending']

        const saveChanges = async () => {
            if (root.$route.params.id) appraiserSettingsId.value = root.$route.params.id
            await $axios.patch(
                '/v1/appraiser/' + appraiserSettingsId.value + '/options/action/save-appraiser-amc-settings',
                {
                    status: appraiserOptions.value.status,
                },
            )
            appraiserStatusDialog.value = false
            showSnackbar('Saved successfully')
            emit('appraiser-status-saved')
        }
        return {
            appraiserStatuses,
            appraiserStatusDialog,
            appraiserOptions,
            saveChanges,
        }
    },
})
