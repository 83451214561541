
























































import { defineComponent, ref, computed, onBeforeMount } from '@vue/composition-api'
import FeeScheduleSelect from '@/components/General/AutoCompletes/FeeScheduleSelect.vue'
import { Fee } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserSettingsId } from '../../AppraiserProfile'
import $axios from '@/plugins/axios'

export default defineComponent({
    props: {
        option: {
            type: Object,
        },
    },

    components: {
        FeeScheduleSelect,
    },
    setup() {
        const dialog = ref<boolean>(false)

        const feeSchedule = ref({} as Fee)

        const appraiserId = computed(() => {
            return appraiserSettingsId.value
        })

        const getAppraiserFeeSchedule = () => {
            $axios
                .get('v1/appraiser/' + appraiserId.value + '/get-fee-schedule')
                .then(({ data }) => {
                    if (data) feeSchedule.value = data
                })
                .catch(({ data }) => {
                    return data
                })
        }

        onBeforeMount(() => {
            getAppraiserFeeSchedule()
        })

        const save = () => {
            dialog.value = false
            showSnackbar('Fee Schedule Updated Successfully')
        }

        return {
            dialog,
            feeSchedule,
            save,
            getAppraiserFeeSchedule,
            appraiserId,
        }
    },
})
