











































































































import { computed, defineComponent } from '@vue/composition-api'
import { newOrder } from '@/pages/Client/NewOrderMethods'
/**
 * @name 'Order Property'
 * Component description: The order property information for the new order being created on the new order form.
 * @SetupData newOrder: All the new order data sets. The information of the order being created.
 * @SetupMethod GET_FEES: Will lookup all the fees for the corresponding address
 * @SetupData occupancyTypes: The occupancy status of the property. Static variables = ['Occupied', 'Vacant', 'Unknown']
 * @SetupData propertyTypes: The property type. Are Static variables.
 */
export default defineComponent({
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const disableApproachToValue = computed(() =>
            newOrder.value.commercial.approachToValue.some((elem) => elem === 'All Applicable Approaches'),
        )

        const filterApproachToValue = () => {
            newOrder.value.commercial.approachToValue = newOrder.value.commercial.approachToValue.filter(
                (elem) => elem === 'All Applicable Approaches',
            )
        }

        return {
            newOrder,
            disableApproachToValue,
            filterApproachToValue,
        }
    },
})
