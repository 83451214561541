







































import { defineComponent, ref } from '@vue/composition-api'
import { Note } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import AuditNoteComponent from './AuditNoteTypes/AuditNoteComponent.vue'
import AuditNoteTable from './AuditNoteTypes/AuditNoteTable.vue'
import AuditNoteText from './AuditNoteTypes/AuditNoteText.vue'

export const hasValueFormat = (formats: object | null, row: Record<string, string>): boolean => {
    if (!formats) return false
    if (!Object.keys(row).includes('param')) return false
    return Object.keys(formats).includes(row.param)
}

export const formatValue = (formats: object | null, param: string, value: string | number) => {
    if (!formats) return false
    const format = formats[param as keyof object]
    const type = typeof value

    if (format) {
        if (format === 'currency' && (type === 'number' || !isNaN(Number(value)))) {
            value = AscentHelper.currency(value)
        }
    }

    return value
}

export default defineComponent({
    name: 'AuditNoteDetails',
    components: {
        AuditNoteComponent,
        AuditNoteTable,
        AuditNoteText,
    },
    setup() {
        const activeAuditNote = ref<Note | null>(null)
        const auditNoteDialog = ref(false)

        const close = () => {
            auditNoteDialog.value = false
            activeAuditNote.value = null
        }

        const open = (note: Note) => {
            activeAuditNote.value = note
            auditNoteDialog.value = true
        }

        return {
            activeAuditNote,
            auditNoteDialog,
            close,
            open,
        }
    },
})
