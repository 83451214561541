





















































import AppraiserNav from '@/AppLayout//navigation/AppraiserNav.vue'
import ClientNav from '@/AppLayout//navigation/ClientNav.vue'
import CustomerNav from '@/AppLayout/navigation/CustomerNav.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { openCloseDrawer } from '@/plugins/Ascent'
export const mini = ref(true)
import { updateOrders } from '@/components/OrderTable/Classes/OrderTable'
import { openMobile } from '@/AppLayout/AppHeader.vue'
export const mobileNavOpen = ref(false)
/**
 * @name 'Left Nav'
 * Component description: The left nav to navigate through the different pages.
 * The menu on the left side of the application for the navigation
 * Has the routes and paths of the different pages it will direct you to.
 *
 * @SetupData mobile() => : the mobile view of the left nav, ability to open and close the nav
 */
export default defineComponent({
    components: { ClientNav, AppraiserNav, CustomerNav },
    computed: {
        openState: {
            get() {
                return true
            },
            set(value) {
                openCloseDrawer(value)
            },
        },
    },

    setup() {
        const mobile = () => {
            mobileNavOpen.value = !mobileNavOpen.value
            mini.value = true
        }

        return {
            user,
            updateOrders,
            mini,
            mobileNavOpen,
            mobile,
            openMobile,
        }
    },
})
