


















































































import { ValidationObserver } from 'vee-validate'
import OrderConditions from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/OrderConditionAction.vue'
import { defineComponent, computed } from '@vue/composition-api'
import { refreshDashboardCounts } from '@/components/OrderTable/Classes/TableFilters'
import { Note } from '@/types'
import { filteredNotes } from '@/plugins/Notes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder, updateOrder } from '@/plugins/order/Order'
import AmcUserSelect from '@/components/General/AutoCompletes/AmcUserSelect.vue'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

function reset() {
    return {
        rush: false,
        accept: 'accept',
        reason: null,
        sending: false,
        reviewer: 0,
    }
}

/**
 * @name UWRequest
 * Workflow step when client requests a uw revision. AMC users will look over order and decide if they will accept or reject the request
 *
 * @SetupComputed lastClientNote - filters notes and will return the last note the client has sent.
 */

export default defineComponent({
    setup() {
        const lastClientNote = computed(() => {
            const clientNotes = filteredNotes.value.filter(
                (note: Note) => note.source === 'Client' && note.target === 'AMC',
            )
            if (clientNotes.length !== 0) return clientNotes[clientNotes.length - 1]
            return ''
        })

        return {
            lastClientNote,
            order,
        }
    },
    props: {},
    components: { AmcUserSelect, OrderConditions, ValidationObserver },
    data() {
        return reset()
    },
    mounted() {
        if (AscentHelper.featureEnabled('orderScreen.workflow.quality.reviewer')) {
            this.reviewer = user.value?.id as number
        }
    },
    methods: {
        /**
         * Posts to /v1/order/order.id/workflow/uw-reject with reason. Rejects the request and refreshes order and dashboard counts
         */
        rejectCondition() {
            if (!order.value) return
            this.sending = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/uw-reject', {
                    reason: this.reason,
                })
                .then(() => {
                    this.$emit('reject')
                    showSnackbar('Condition Request Rejected Successfully')
                    refreshOrder()
                    Object.assign(this.$data, reset())
                    refreshDashboardCounts()
                })
                .catch(() => {
                    this.sending = false
                })
        },
        /**
         * Posts to /v1/order/order.id/action/condition-request with type and rush. Accepts the request and sends conditions to appraiser
         */
        sendCondition() {
            if (!order.value) return
            this.sending = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/action/condition-request', {
                    rush: this.rush,
                    type: 'UW Revision',
                    reviewer: this.reviewer,
                })
                .then(() => {
                    this.$emit('send-to-appraiser')
                    updateOrder()
                    showSnackbar('Conditions Sent Successfully')
                    Object.assign(this.$data, reset())
                    refreshDashboardCounts()
                })
                .catch(() => {
                    this.sending = false
                })
        },
    },
})
