import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/office/dashboard',
        name: 'Office Dashboard',
        component: () => import('@/components/General/Dashboard/DashboardPage.vue'),
        meta: { requiresAuth: true, userType: ['office'], dashboard: true },
    },
    {
        path: '/office/order',
        name: 'Office Order Form',
        component: () => import('@/pages/Office/AppraiserNewOrder/AppraiserOrderFormPage.vue'),
        meta: { requiresAuth: true, userType: ['office'] },
    },
    {
        path: '/office/appraiser-map',
        name: 'Office Appraiser Map',
        component: () => import('@/pages/Office/AppraiserOfficeMap/AppraiserOfficeMap.vue'),
        meta: { requiresAuth: true, userType: ['office'] },
    },
    {
        path: '/office/comparable-select/:token',
        name: 'Comparable Search & Select',
        component: () => import('@/pages/Office/CompSearch/CompSearch.vue'),
        meta: { requiresAuth: true, userType: ['office'] },
    },
    {
        path: '/office/order/confirm/:id',
        name: 'Office Confirm Order',
        component: () => import('@/pages/Client/ConfirmOrderPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['office'],
            title: 'Order Confirmation',
        },
    },
    {
        path: '/office/order/:id',
        name: 'Office Order Form By Order',
        component: () => import('@/pages/Office/AppraiserNewOrder/AppraiserOrderFormPage.vue'),
        meta: { requiresAuth: true, userType: ['office'] },
    },
    {
        path: '/preload/dashboard',
        name: 'Preload Dashboard',
        component: () =>
            import(
                '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/ExternalPreload/GetNextPreload.vue'
            ),
        meta: { requiresAuth: true, showNav: false, userType: ['office', 'preload'] },
    },
]

export default routes
