





























































































import { computed, defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { order, refreshOrder } from '@/plugins/order/Order'
import { RoleSetting, TeamAllocation } from '@/types'
import AppraiserTeamAllocate from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/TeamAllocation/AppraiserTeamAllocate.vue'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import OverridePanelAssignDialog, {
    overrideDialog,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/OverridePanelAssignDialog.vue'
import $ascent from '@/helpers/ascent-helper'

export const reassignDialog = ref(false)
export const teamAllocations = ref([] as TeamAllocation[])
export const reassignmentType = ref('az')
export const roles: Ref<RoleSetting[]> = ref([])
const reason = ref('')

export default defineComponent({
    computed: {
        $ascent() {
            return $ascent
        },
    },
    components: {
        AppraiserTeamAllocate,
        ValidationObserver,
        OverridePanelAssignDialog,
    },
    setup() {
        $axios.get('/v1/settings/appraiser-roles').then((response) => {
            roles.value = response.data.result
        })

        const setDataByType = (type: string) => {
            if (type == 'az') {
                teamAllocations.value = teamAllocations.value.filter(function (obj) {
                    return obj.role === 'Primary'
                })
            }

            if (type == 'team') {
                teamAllocations.value = []
                roles.value.forEach((role) => {
                    teamAllocations.value.push({
                        role: role.role,
                        appraiser_id: undefined,
                        fee: undefined,
                        required: role.required,
                    })
                })
            }
        }

        const disableButton = computed(() => {
            if (!order.value) return true
            if (!order.value.incremental) return true
            for (const allocation of teamAllocations.value) {
                if (allocation.required && (!allocation.appraiser_id || allocation.fee == null)) return true
            }
            return reason.value === ''
        })

        const setOrderAppraisers = (type: string) => {
            setDataByType(type)

            const primary = order.value?.incremental?.order_appraisers.find((e) => e.role === 'Primary')
            teamAllocations.value[0] = {
                role: 'Primary',
                appraiser_id: primary?.appraiser.id,
                fee: primary?.fee ? primary?.fee : 0,
                required: true,
            }

            if (type == 'team') {
                teamAllocations.value.forEach((alloc, index) => {
                    if (alloc.role == 'Primary') return
                    const appraiser = order.value?.incremental?.order_appraisers.find((e) => e.role === alloc.role)
                    teamAllocations.value[index] = {
                        role: alloc.role,
                        appraiser_id: appraiser?.appraiser.id,
                        fee: appraiser?.fee,
                        required: alloc.required,
                    }
                })
            }
        }

        const updateAppraiser = () => {
            showSnackbar('Updated Successfully')
            reason.value = ''
            reassignDialog.value = false
            refreshOrder()
        }

        const updateFee = (fee: string | number, role: string) => {
            const index = teamAllocations.value.findIndex(function (appraiser) {
                return appraiser.role == role
            })
            if (index <= teamAllocations.value.length - 1) teamAllocations.value[index].fee = fee
        }

        const openReassignDialog = () => {
            if (!order.value) return
            if (!order.value?.incremental) return
            teamAllocations.value = []
            if (order.value?.incremental?.order_appraisers && order.value?.incremental?.order_appraisers.length > 1) {
                reassignmentType.value = 'team'
                return
            }

            reassignmentType.value = 'az'
        }

        const openOverrideDialogAction = () => {
            reassignDialog.value = false
            overrideDialog.value = true
        }

        onMounted(() => {
            openReassignDialog()
        })

        watch(reassignmentType, () => {
            setOrderAppraisers(reassignmentType.value)
        })
        watch(reassignDialog, (value) => {
            if (value) {
                openReassignDialog()
            }
        })

        return {
            reassignDialog,
            order,
            updateAppraiser,
            reason,
            teamAllocations,
            reassignmentType,
            updateFee,
            openOverrideDialogAction,
            roles,
            disableButton,
        }
    },
})
