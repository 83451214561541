import { order } from '@/plugins/order/Order'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { AscentOption, MercuryOption, ReggoraOption } from '@/types'
import { computed } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper'

const requiresReggoraModification = (types: string[] = []) => {
    if (order.value?.source === 'reggora') {
        if (types === []) {
            return true
        }
        if (types.includes('none')) {
            return false
        }
        const options = lenderOptions.value.reggora as ReggoraOption
        if (options) {
            return types.includes(options.reggoraMod as string)
        }
    }
}

const requiresMercuryModification = () => {
    if (!order.value) return false
    if (!order.value?.incremental) return false
    if (order.value.status === 'Completed' && AscentHelper.canDo('Update Completed Order Fees')) return false
    if (
        lenderOptions.value.mercury &&
        (lenderOptions.value.mercury as MercuryOption).modification &&
        (lenderOptions.value.mercury as MercuryOption).modification === 'Yes'
    )
        return true
    return false
}

const requiresAscentModification = (type: keyof AscentOption) => {
    if (!order.value) return false
    if (!order.value?.incremental) return false
    if (order.value.status === 'Completed' && AscentHelper.canDo('Update Completed Order Fees')) return false
    if (
        lenderOptions.value.ascent &&
        (lenderOptions.value.ascent as AscentOption)[type] &&
        (lenderOptions.value.ascent as AscentOption)[type] === 'Yes'
    )
        return true
    return false
}

export const requiresDateModification = computed(() => {
    if (!order.value || !order.value.source) return false
    if (!order.value.incremental) return false
    if (order.value.lender_id === 48) return false
    if (!order.value.incremental.reviewed?.value && AscentHelper.featureEnabled('reviewOrder')) return false
    if (order.value.source === 'valuelink') return true
    if (order.value.source === 'reggora') return requiresReggoraModification(['date', 'both'])
    if (order.value.source === 'mercury') return requiresMercuryModification()
    if (order.value.source === 'ascent') return requiresAscentModification('date')
    return false
})

export const requiresFeeModification = computed(() => {
    if (!order.value || !order.value.source) return false
    if (!order.value?.incremental) return false
    if (order.value.lender_id === 48) return false
    if (order.value.source === 'ascent') return requiresAscentModification('fee')
    if (!order.value.incremental.reviewed?.value && AscentHelper.featureEnabled('reviewOrder')) return false
    if (order.value?.source === 'valuelink') return true
    if (order.value.source === 'reggora') return requiresReggoraModification(['fee', 'both'])
    if (order.value.source === 'mercury') return requiresMercuryModification()
    return false
})

export const requiresModification = computed(() => {
    return requiresDateModification.value || requiresFeeModification.value
})
