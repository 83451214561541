
















































































import { computed, defineComponent } from '@vue/composition-api'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { paymentTypes } from '@/plugins/Data'

interface Split {
    method: string
    amount: string
}

interface OrderDetail {
    note: string
    splits: Split[]
}

export default defineComponent({
    components: {
        ACardAction,
        ValidationObserver,
    },

    setup() {
        const defaultItems = [
            { value: 'borrower-paid', text: 'Borrower', disabled: true },
            { value: 'net-30', text: 'Bill To', disabled: true },
            { value: 'credit-card', text: 'Credit Card', disabled: true },
            { value: 'split', text: 'Split', disabled: true },
        ]

        const checkIntegration = () => {
            if (order.value?.source === 'mercury') {
                defaultItems.push({ value: 'mercury-card', text: 'Mercury Card', disabled: false })
                defaultItems.push({ value: 'deferred-card', text: 'Deferred - Waiting on Borrower', disabled: false })
            }
            if (order.value?.source === 'valuepad')
                defaultItems.push({
                    value: 'valuepad-card',
                    text: 'Valuepad Card',
                    disabled: false,
                })
        }

        const items = computed(() => {
            checkIntegration()
            const net30 = ['net-30', 'invoice_with_auth', 'invoice_with_permission', 'final_inspection', 'branch']
            const card = ['card_up_front', 'credit_card', 'company_card']
            const billingOptions = lenderOptions.value.billing as string[]
            if (!billingOptions) return defaultItems
            billingOptions.forEach((option) => {
                defaultItems.map((elem) => {
                    if (option === elem.value) elem.disabled = false
                    if (option === 'borrower' && elem.value.includes('borrower')) elem.disabled = false
                    if (net30.findIndex((ele) => ele === option) > -1 && elem.value === 'net-30') elem.disabled = false
                    if (card.findIndex((elem) => elem === option) > -1 && elem.value === 'credit-card')
                        elem.disabled = false
                    if (order.value?.billingMethod === 'split' && elem.value !== 'split') {
                        defaultItems.map((el) => {
                            if (el.value === 'split') el.disabled = false
                        })
                    }
                })
            })

            return defaultItems
        })

        return {
            paymentTypes,
            order,
            items,
        }
    },
    data() {
        return {
            details: {
                splits: [
                    { method: 'net-30', amount: '' },
                    { method: 'credit-card', amount: '' },
                ],
                note: '',
            },
            dialog: false,
        }
    },
    computed: {
        valid(): boolean {
            const total = this.details.splits.reduce((total: number, elem: Split) => {
                if (parseInt(elem.amount) > 0) {
                    total = total + parseInt(elem.amount)
                }
                return total
            }, 0)
            return total === order.value?.fee
        },
    },
    methods: {
        open() {
            if (order.value?.incremental?.billing_details !== null) {
                this.details = JSON.parse(JSON.stringify(order.value?.incremental?.billing_details))
            }
            this.dialog = true
        },
        close() {
            this.details = {
                splits: [
                    { method: 'net-30', amount: '' },
                    { method: 'credit-card', amount: '' },
                ],
                note: '',
            }
            this.dialog = false
        },
        splitSaved() {
            showSnackbar('Payment Type Updated Successfully')
            refreshOrder()
            this.details = {
                splits: [
                    { method: 'net-30', amount: '' },
                    { method: 'credit-card', amount: '' },
                ],
                note: '',
            }
            this.dialog = false
        },
    },
})
