





























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import directives from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderDirectives/LenderDirectiveEdit.vue'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import LenderDirectiveRevision from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderDirectives/LenderDirectiveRevision.vue'
import { LenderOptions } from '@/types'
import LenderAdminDirectives from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderDirectives/LenderAdminDirectives.vue'
import { user } from '@/plugins/User'

/**
 * @name LenderDirectives
 * Component description: Directives (instructions) to follow for specific lenders.
 * This is shown for all that can see the directives and shows the current directives as well
 * as the revision history of the directive.
 * @SetupData revisions - array of revisions showing a history of how the directive has changed.
 * @SetupMethod getDirectives - gets all directives and history
 */
export default defineComponent({
    components: {
        LenderAdminDirectives,
        LenderDirectiveRevision,
        directives,
    },
    setup() {
        const loaded = ref(false)
        const dialog = ref(false)
        const revisions = ref([] as LenderOptions[])

        const headers = [
            { text: 'Date', value: 'created_at', width: 300, sortable: false },
            { text: 'Created By', value: 'saved_by', sortable: false },
            { value: 'view', width: 200, sortable: false },
        ]

        const updateDirectives = (newDirective: LenderOptions) => {
            revisions.value.push(newDirective)
        }

        const getDirectives = () => {
            axios
                .get('/v1/lender/' + lender.value.id + '/option/directives', {
                    params: {
                        withRevisions: true,
                    },
                })
                .then((response) => {
                    loaded.value = true
                    revisions.value = response.data || []
                })
        }

        onMounted(getDirectives)

        return {
            headers,
            revisions,
            loaded,
            dialog,
            updateDirectives,
            user,
        }
    },
})
