
























































import ACardAction from '@/global/ACardAction.vue'

interface CreditCard {
    card: string
    name: string
    token: string
}

import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import CreditCard from '@/components/General/CreditCard.vue'
import { expMonths, expYears } from '@/plugins/Data'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

export default defineComponent({
    components: { ACardAction, ValidationObserver },
    props: {
        card: {
            type: Object as PropType<CreditCard>,
            default: () => {
                return {}
            },
        },
    },
    setup(props, { emit }) {
        const dialog = ref<boolean>(false)
        const payment = ref({
            cvv: '',
            expmonth: 0,
            expyear: 0,
        })

        const save = () => {
            dialog.value = false
            showSnackbar('Card Updated successfully!')
        }

        const deleteCard = () => {
            // todo move this to parent component and make addCardDialog component
            confirm('Delete Card', 'Are you sure you want to delete this saved card?').then((confirm) => {
                if (confirm) {
                    emit('deleteCard', props.card)
                }
            })
        }

        return {
            save,
            payment,
            dialog,
            deleteCard,
            expMonths,
            expYears,
        }
    },
})
