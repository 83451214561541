var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"tile":"","color":"transparent","elevation":0}},[_c('v-card-text',[_c('v-data-table',{staticClass:"scroll-table",attrs:{"headers":_vm.headers,"items":_vm.order.incremental.duplicates,"item-key":"id","hide-default-footer":"","show-select":"","show-expand":"","items-per-page":-1,"fixed-header":""},scopedSlots:_vm._u([{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [(item.duplicate_cache)?_c('a',{staticClass:"link",attrs:{"target":"_blank","href":'/order/' + item.duplicate_id}},[_vm._v(" "+_vm._s(item.duplicate_cache.customer_order_id)+" ")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.duplicate_cache)?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.duplicate_cache.order.productList)+" ")]):_vm._e()]}},{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [(item.duplicate_cache)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.duplicate_cache.order.clientName))]):_vm._e()]}},{key:"item.appraiserName",fn:function(ref){
var item = ref.item;
return [(item.duplicate_cache)?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.duplicate_cache.order.appraiserName)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.duplicate_cache)?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.duplicate_cache.order.status))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.removeDuplicate(item.id)}}},[_vm._v(" $vuetify.icons.trashCan ")])]}},{key:"header.actions",fn:function(){return [(_vm.selected.length > 0)?_c('v-icon',{staticClass:"mr-3 float-right",attrs:{"color":"red"},on:{"click":function($event){return _vm.removeDuplicates()}}},[_vm._v(" $vuetify.icons.trashCan ")]):_vm._e()]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.hasFiles && !isExpanded)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return expand(true)}}},[_vm._v(" $vuetify.icons.chevronDown ")]):_vm._e(),(item.hasFiles && isExpanded)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return expand(false)}}},[_vm._v(" $vuetify.icons.chevronUp ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},_vm._l((_vm.duplicateFiles[_vm.order.incremental.duplicates.indexOf(item)]),function(file,fileIndex){return _c('v-list-item',{key:file.id,staticClass:"ml-16"},[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('v-list-item-title',[_c('a',{staticClass:"link caption",domProps:{"innerHTML":_vm._s(file.type)},on:{"click":function($event){return _vm.openFile(file)}}})]),_c('v-list-item-subtitle',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.$ascent.formatIsoDate(file.created_at, 'M/dd/yyyy t'))}})],1),_c('v-col',{staticClass:"px-2",attrs:{"align-self":"center"}},[_c('v-chip',{attrs:{"x-small":"","color":_vm.getColor(file.status),"dark":""},domProps:{"innerHTML":_vm._s(file.status)}})],1)],1)],1),_c('v-list-item-action',[(!file.addedToOrder)?_c('v-btn',{attrs:{"color":"#3b35b1","text":""},on:{"click":function($event){_vm.addFileToOrder(file, fileIndex, _vm.order.incremental.duplicates.indexOf(item))}}},[_vm._v(" Add To Order "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("$vuetify.icons.plus")])],1):_vm._e(),(file.addedToOrder)?_c('v-btn',{attrs:{"text":"","color":"secondary","disabled":""}},[_vm._v(" File Added "),_c('v-icon',{attrs:{"right":"","text":""}},[_vm._v("$vuetify.icons.success")])],1):_vm._e()],1)],1)}),1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }