var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
            _vm.appraiserPanelNotes.filter(
                function (elem) { return elem.appraiser_id === _vm.item.appraiser.id &&
                    (elem.target === 'Assign Phone' ||
                        elem.target === 'Assign Email' ||
                        (elem.source === 'Prior Appraiser' && elem.target === 'AMC') ||
                        (elem.source === 'AMC' && elem.target === 'Prior Appraiser')); }
            ).length
        )?_c('v-avatar',{staticClass:"ml-2",attrs:{"color":_vm.noteIconColor,"size":"20"},on:{"click":_vm.openAppraiserPanelNotes}},[_c('p',{staticClass:"accentBlack--text"},[_vm._v(" "+_vm._s(_vm.appraiserPanelNotes.filter( function (elem) { return elem.appraiser_id === _vm.item.appraiser.id && (elem.target === 'Assign Phone' || elem.target === 'Assign Email' || (elem.source === 'Prior Appraiser' && elem.target === 'AMC') || (elem.source === 'AMC' && elem.target === 'Prior Appraiser')); } ).length)+" ")])]):_vm._e(),_c('v-dialog',{model:{value:(_vm.showNotesCard),callback:function ($$v) {_vm.showNotesCard=$$v},expression:"showNotesCard"}},[_c('v-card',[_c('v-card-text',[_c('AppraiserPanelNotes',{attrs:{"notes":_vm.filteredNotes}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showNotesCard = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }