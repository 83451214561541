


























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { OrderModel } from '@/types'
import { getFiles } from '@/plugins/Files'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'

export default defineComponent({
    name: 'UrlFileUpload',
    components: {
        ValidationProvider,
    },
    setup() {
        const reportUrl = ref('')
        const ValidationRef = ref({} as InstanceType<typeof ValidationProvider>)
        const isSubmitted = ref(false)

        const uploadReport = () => {
            $axios
                .post('/v1/order/' + (order.value as OrderModel).id + '/file', {
                    type: 'Commercial Draft',
                    path: reportUrl.value,
                    status: 'Appraiser',
                })
                .then(() => {
                    getFiles((order.value as OrderModel).id)
                    isSubmitted.value = true
                    ValidationRef.value.syncValue(isSubmitted.value)
                    ValidationRef.value.validate()
                })
        }

        onMounted(() => {
            ValidationRef.value.syncValue('')
            ValidationRef.value.validate()
        })

        return {
            reportUrl,
            ValidationRef,
            isSubmitted,
            uploadReport,
        }
    },
})
