

































































































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import {
    searchText,
    updateSearchTable,
    saveTable,
    searchType,
    searchItems,
    filteredColumns,
    grid,
    OrderQuery,
    loading,
    orderFilter,
    changeUserType,
} from '@/components/OrderTable/Classes/OrderTable'
import { clearFilters } from '@/components/OrderTable/Classes/OrderFilters'
import TableUserSelectDialog, { dialog } from '@/components/OrderTable/TablePartials/TableUserSelectDialog.vue'
import { getOrderById, searchDropDown } from '@/plugins/order/Order'
import { OrderModel } from '@/types'
import canDo from '@/store/Helpers/CanDo'
import { dashboard } from '@/components/OrderTable/Classes/TableFilters'
import { Tag } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import { ExcelQueryCellInfoEventArgs } from '@syncfusion/ej2-grids/src/grid/base/interface'

import { name } from '@/components/OrderTable/Classes/OrderTable'
import { user } from '@/plugins/User'
import router from '@/pages/router'

const recentLocalOrders = ref([] as number[])
export const setRecentOrders = (order: OrderModel): void => {
    const localRecentOrders = localStorage.getItem('recentOrders')
    let recentOrders = []
    if (localRecentOrders) recentOrders = JSON.parse(localRecentOrders)
    const duplicateOrder = recentOrders.findIndex((element: number) => element === order.id)
    if (duplicateOrder !== -1) recentOrders.splice(duplicateOrder, 1)
    if (recentOrders.length > 4) recentOrders.pop()
    const shortenedOrder: Pick<OrderModel, 'id' | 'addressFormatted' | 'lenderName'> = {
        id: order.id,
        addressFormatted: order.addressFormatted,
        lenderName: order.lenderName,
    }
    recentOrders.unshift(shortenedOrder)
    localStorage.setItem('recentOrders', JSON.stringify(recentOrders))
    recentLocalOrders.value = recentOrders
}
interface AscentQueryCellInfo extends Omit<ExcelQueryCellInfoEventArgs, 'value'> {
    value: Date | string | number | boolean | Tag[] | null
}

/**
 * @name TableSearch
 * Search component for the Amc Order Table
 *
 * @SetupData searchText - Variable for searching the table
 * @SetupMethod updateOrders - Method that will update the orders in the order table by what the search text is
 * @SetupMethod saveTable - Will save layout of the amc order table
 * @SetupData searchItems - array of filters that the amc users can search by
 * @SetupData filteredColumns - list of  filtered columns on the table
 * @SetupData grid - the OrderTable component itself
 * @SetupData OrderQuery - AscentApi object for querying the table
 *
 * @SetupComputed searchBackgroundColor - Change background of search when client is typing
 * @SetupMethod openColumnChooser - Opens dialog for amc user to chose columns they want to see
 * @SetupMethod exportExcel - Exports data to an excel sheet
 * @SetupData recentLocalOrders - Array of past 5 orders last opened by user
 * @SetupMethod setRecentOrders - Methods that takes in an Order that was opened, and saves it to local storage and sets recentlyOpenedOrders array
 * @SetupMethod openRecentOrder - Opens order from recent order menu
 *
 * When mounted, checks local storage for recent orders and adds that in.
 *
 */

export const exportExcel = () => {
    OrderQuery.execute({ skip: 0, take: 2500 }).then((dataResult) => {
        if (dataResult === undefined) return
        grid.value.ej2Instances.excelQueryCellInfo = (args: AscentQueryCellInfo) => {
            switch (args.column.field) {
                case 'tags':
                    if ((args.value as Tag[]).length === 0 || args.value == null) break
                    args.value = (args.value as Tag[])
                        .map((tag: Tag) => {
                            return tag.tag
                        })
                        .join(', ')
                    break
                case 'appraiserDeadline':
                case 'clientDeadline':
                    if (args.column.type === 'date' && args.value) {
                        args.value = AscentHelper.formatUnix(args.value, 'MM/dd/yyyy', false)
                    }
                    break
                default:
                    if (args.column.type === 'date' && args.value) {
                        args.value = AscentHelper.formatUnix(args.value, 'MM/dd/yyyy hh:mm a')
                    }
            }
        }
        grid.value.ej2Instances.excelExport({
            dataSource: dataResult.result,
            fileName: 'OrderExport_' + AscentHelper.formatUnix(Date.now(), 'MM-dd-yyyy H-mm') + '.xlsx',
        })
    })
}

export default defineComponent({
    name: 'TableSearch',
    components: { TableUserSelectDialog },
    props: {
        advancedSearch: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const searchBackgroundColor = computed(() =>
            searchText.value !== '' && searchText.value != null ? 'white' : 'accentWhite',
        )

        const openColumnChooser = (event: MouseEvent) => {
            const targetElement = event.currentTarget as HTMLElement
            grid.value.ej2Instances.columnChooserModule.openColumnChooser(targetElement.offsetLeft - 250, 0)
        }

        const placeholder = ref('Press / to focus')

        const openRecentOrder = (orderId: number): void => {
            getOrderById(orderId)
        }

        onMounted(() => {
            const localRecentOrders = localStorage.getItem('recentOrders')
            if (localRecentOrders) recentLocalOrders.value = JSON.parse(localRecentOrders)

            if (router.currentRoute.query.loan) {
                const loanId = router.currentRoute.query.loan
                searchText.value = loanId as string
                searchType.value = 'Loan #'
                return
            }

            const lastSearchType = localStorage.getItem('searchType')
            if (lastSearchType) searchType.value = lastSearchType
        })

        const userSelectPermission = () => {
            if (dashboard.value === 'Assign') {
                return canDo('Assign All Users')
            }
            if (dashboard.value === 'Main') {
                return canDo('View All Users')
            }
        }

        return {
            searchText,
            searchType,
            searchItems,
            updateSearchTable,
            exportExcel,
            saveTable,
            openColumnChooser,
            filteredColumns,
            clearFilters,
            searchBackgroundColor,
            placeholder,
            loading,
            dialog,
            openRecentOrder,
            recentLocalOrders,
            searchDropDown,
            userSelectPermission,
            name,
            orderFilter,
            changeUserType,
            user,
        }
    },
})
