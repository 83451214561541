












































































import { defineComponent, ref } from '@vue/composition-api'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import { User, EscalationTag } from '@/types'
import {
    getEscalationTags,
    availableAssignees,
    statuses,
} from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/EscalationTags'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import AEditorMin from '@/global/Form/AEditorMin.vue'

const dialog = ref(false)
const tag = ref(undefined as EscalationTag | undefined)
const tagName = ref('')
const tagStatuses = ref([] as string[])
const tagAssignees = ref([] as User[])
const tagColor = ref('#000')
export const openEdit = (selectedTag: EscalationTag) => {
    tag.value = JSON.parse(JSON.stringify(selectedTag))
    dialog.value = true
}

export default defineComponent({
    components: { AEditorMin, ACardAction, ValidationObserver },
    setup() {
        const form = ref({} as InstanceType<typeof ValidationObserver>)

        const tagUpdated = () => {
            showSnackbar('Tag updated successfully!')
            getEscalationTags()
            form.value.reset()
            tag.value = undefined
            dialog.value = false
        }

        return {
            tagAssignees,
            tagName,
            tagColor,
            dialog,
            availableAssignees,
            statuses,
            tagStatuses,
            tag,
            form,
            tagUpdated,
        }
    },
})
