
























import { ValidationObserver } from 'vee-validate'
import { defineComponent } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        note: null,
        dialog: false,
    }
}

/**
 * @name EscalateOrderDialog
 * Escalates order if order is already completed
 */

export default defineComponent({
    components: { ValidationObserver },
    data() {
        return reset()
    },
    methods: {
        open() {
            this.dialog = true
        },
        /**
         * Posts to backend to escalate order
         */
        escalateOrder() {
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/escalate', {
                    note: this.note,
                })
                .then(() => {
                    showSnackbar('Order escalated.')

                    Object.assign(this.$data, reset())
                    const form = this.$refs.form as InstanceType<typeof ValidationObserver>
                    form.reset()
                })
        },
    },
})
