































































































































import { defineComponent, inject, ref } from '@vue/composition-api'
import {
    billingAlertsReviewed,
    entryContactsReviewed,
    reviewNotes,
    currentReviewStep,
    contactNoteAdded,
    steps,
    lenderBilling,
    billingTypes,
    borrowerEmailNeeded,
    filesReviewed,
    fhaHasPurchaseContract,
} from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import { unreadNotes } from '@/plugins/Notes'
import { order, snapshotOrder } from '@/plugins/order/Order'
import { newFileDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AddOrderFileDialog.vue'
import ReviewSendNoteDialog, {
    clientNoteDialog,
} from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/partials/ReviewSendNoteDialog.vue'
import { openContacts } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts/ContactsDialog.vue'
import { user } from '@/plugins/User'

/**
 * @name ReviewErrors
 * Different errors that can come up in the review process that stops or alerts the reviewer on what they need to do to complete an order
 * Data being imported from Review.ts:
 * @SetupData billingAlertsReviewed: Boolean that determines if billing alerts were reviewed or not
 * @SetupData entryContactsReviewed: Boolean that checks if there is an entry contact on the order
 * @SetupData reviewNotes: Notes that are saved at end of review to send to client
 * @SetupData currentReviewStep: Current step user is on during the review
 * @SetupData contactNoteAdded: Boolean that determines if a note asking for contact entry was added or not
 * @SetupData lenderBilling: Billing lender accepts
 * @SetupData borrowerEmailNeeded: Boolean checking if borrower has an email on order or not
 * @SetupData filesReviewed: Boolean determining if files have all been seen and reviewed
 * @SetupData fhaHasPurchaseContract: computed property checking to see if an fha order has a purchase contract
 */

export default defineComponent({
    components: {
        ReviewSendNoteDialog,
    },
    setup() {
        const sidebarItem = inject('sidebarItem', ref(''))

        return {
            billingAlertsReviewed,
            borrowerEmailNeeded,
            billingTypes,
            order,
            lenderBilling,
            steps,
            filesReviewed,
            currentReviewStep,
            unreadNotes,
            sidebarItem,
            reviewNotes,
            contactNoteAdded,
            entryContactsReviewed,
            fhaHasPurchaseContract,
            newFileDialog,
            clientNoteDialog,
            openContacts,
            snapshotOrder,
            user,
        }
    },
})
