




























































































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { LenderOptions, LowAppraisalEmailsOption } from '@/types'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const local = ref({
            enabled: false,
            emails: [],
            text: '',
            client: false,
            ignore: false,
        } as LowAppraisalEmailsOption)

        if (props.option !== undefined && props.option.option_value) {
            local.value = props.option.option_value as LowAppraisalEmailsOption
        }

        const emails = ref(JSON.parse(JSON.stringify(local.value.emails)))
        const text = ref(local.value.text)
        const disabled = ref(true)
        const save = () => {
            if (!local.value.emails) return
            const tempLocal = { ...local.value, text: text.value, emails: emails.value }
            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'low-appraisal-emails',
                    option_value: tempLocal,
                })
                .then(() => {
                    local.value = tempLocal
                    dialog.value = false
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            disabled,
            text,
            emails,
            save,
            dialog,
            local,
        }
    },
})
