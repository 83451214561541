















































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import {
    statuses,
    canEdit,
    appraiserOptions,
    amcStatus,
    appraiserValue,
    appraiserSettingsId,
} from '@/pages/Appraiser/Profile/AppraiserProfile'
import axios from 'axios'

export default defineComponent({
    setup(_, { root }) {
        const status = ref('')

        const licenses = ref([])
        const insurances = ref([])
        const headers = [
            { text: 'State', value: 'state' },
            { text: 'Type', value: 'type' },
            { text: 'Status', value: 'status' },
            { text: 'File', value: 'file', sortable: false },
            { text: 'Approve', value: 'approve', sortable: false },
        ]
        const headersInsurance = [
            { text: 'Status', value: 'status' },
            { text: 'Effective', value: 'effective' },
            { text: 'Expiration', value: 'expiration' },
            { text: 'Policy Number', value: 'policy_number' },
            { text: 'Company', value: 'company' },
            { text: 'Coverage', value: 'policy_amount' },
            { text: 'File', value: 'file', sortable: false },
            { text: 'Approve', value: 'approve', sortable: false },
        ]
        const documentStatuses = ['Active', 'Pending', 'Incomplete', 'Expired']

        onMounted(() => {
            appraiserSettingsId.value = root.$route.params.id
            axios.get('/v1/appraiser/' + root.$route.params.id + '/options').then((response) => {
                if (response.data.options && response.data.options.officeSettings) {
                    appraiserOptions.value = response.data.options.officeSettings[0]
                    amcStatus.value = response.data.options.officeSettings[0].status
                    if (
                        response.data.options.officeSettings[0].service_type &&
                        response.data.options.officeSettings[0].service_type.length === 0
                    )
                        response.data.options.officeSettings[0].service_type = ''
                }
            })
        })

        return {
            status,
            amcStatus,
            appraiserValue,
            licenses,
            insurances,
            headers,
            headersInsurance,
            documentStatuses,
            appraiserOptions,
            canEdit,
            statuses,
        }
    },
})
