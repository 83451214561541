






























































































































import { PDRPDCDetails } from '@/types/orderDetails'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { getPdrPdcData, order } from '@/plugins/order/Order'
import OrderDetailListItem from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/OrderDetailListItem.vue'
import { lender, lenderId } from '@/components/General/LenderOptions/LenderOptions'
import { dialog } from '@/pages/AMC/Lenders/LenderListing/Partials/LenderEditDialog.vue'
import { showPopover } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailPopoverComponent.vue'
import { openViewClientDialog } from '@/components/OrderScreens/Customer/CustomerOrderComponents/PopoverComponents/ViewClientPopover.vue'
import { files } from '@/plugins/Files'
import { download as DownloadFile } from '@/components/General/DownloadFile.vue'
import $axios from '@/plugins/axios'
import { File, Tag } from '@/types'
import {
    menuItems,
    show,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailMenuComponent.vue'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { clientTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'

export default defineComponent({
    name: 'CustomerClientComponent',
    components: { OrderDetailListItem },
    setup() {
        const pdrPdcData = ref({} as PDRPDCDetails)

        const lenderOnAppraisal = computed(() => {
            if (!order.value || !order.value?.incremental) return ''
            if (order.value.incremental.sublender_id != null && order.value.incremental.sublender != null)
                return order.value.incremental.sublender.name
            return order.value.incremental.lender.form_name
        })

        const lenderOnAppraisalAddress = computed(() => {
            if (!order.value) return ''
            if (!order.value?.incremental) return ''
            if (order.value.incremental.sublender) {
                return order.value.incremental.sublender?.address?.full_address_formatted
            }
            return order.value.incremental.lender?.address?.full_address_formatted
        })

        const editLender = () => {
            if (!order.value?.lender_id) return
            lenderId.value = order.value.lender_id
            lender.value.id = order.value.lender_id
            dialog.value = true
        }

        const openPopover = (component: string, ev: MouseEvent) => {
            showPopover(component, ev)
        }

        const engagementLetter = computed(() => {
            if (!files.value) return false
            return files.value.find((file) => file.type === 'Engagement Letter' && file.status !== 'Inactive')
        })

        const openEngagementLetter = (file: File) => {
            $axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        }

        const showDownloadMenu = (ev: PointerEvent) => {
            menuItems.value = [
                {
                    title: 'Download',
                    callback: () => {
                        if (!engagementLetter.value) return
                        const name =
                            engagementLetter.value?.type.replace(/^.*[\\/]/, '') +
                            '_' +
                            engagementLetter.value?.order_id +
                            '.' +
                            engagementLetter.value?.path.substr(engagementLetter.value?.path.lastIndexOf('.') + 1)
                        DownloadFile(engagementLetter.value?.id, name)
                    },
                },
            ]
            show(ev)
        }

        const currentClientTags = computed(() => {
            if (!clientTags.value) return []
            if (!lenderOptions?.value['office-client-tags']) return []
            return clientTags.value.filter((elem: Tag) => {
                return (lenderOptions.value['office-client-tags'] as string[]).indexOf(elem.tag as string) > -1
            })
        })

        onMounted(() => {
            pdrPdcData.value = getPdrPdcData()
        })

        return {
            lenderOnAppraisal,
            lenderOnAppraisalAddress,
            editLender,
            order,
            openPopover,
            openViewClientDialog,
            pdrPdcData,
            engagementLetter,
            openEngagementLetter,
            showDownloadMenu,
            currentClientTags,
        }
    },
})
