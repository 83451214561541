




























import { defineComponent } from '@vue/composition-api'
import { kukun } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'
import KukunPropertyDetail from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/KukunPropertyDetail.vue'
export default defineComponent({
    components: {
        KukunPropertyDetail,
    },
    setup() {
        return {
            kukun,
        }
    },
})
