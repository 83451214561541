













import { defineComponent, ref, computed } from '@vue/composition-api'
import { MenuItem } from '@/types'
import { order } from '@/plugins/order/Order'
import { Dialog } from '@/ascent'
import { openDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AscentHelper from '@/helpers/ascent-helper'
import { user } from '@/plugins/User'
import ImpersonateAppraiserDialog, {
    impersonateDialog,
} from '@/components/OrderScreens/Office/OrderComponents/ServiceProviders/ImpersonateAppraiserDialog.vue'
import OfficeReassignDialog, {
    reassignDialog,
    reassignmentType,
} from '@/components/OrderScreens/Office/OrderComponents/ServiceProviders/OfficeReassignDialog.vue'

export const expanded = ref(false)

/**
 * @name 'OrderServiceProviderToolbar'
 *  Toolbar for the Office Service Provider Order component. Gives appraiser note and menu of actions for the appraiser
 *
 *  @SetupComputed actions - returns different action items for the appraiser. Lets amc user View the panel, reassign the appraiser,view appraiser's profile, and impersonate the appraiser
 *
 */

export default defineComponent({
    components: { ImpersonateAppraiserDialog, OfficeReassignDialog },
    setup() {
        const ImpersonateAppraiserDialog = ref({} as Dialog)

        const toAppraiserProfile = (): void => {
            if (!order.value) return
            if (order.value.appraiser_id) {
                window.open('/appraiser/' + order.value.appraiser_id + '/edit', '_blank')
            }
        }

        const actions = computed(() => {
            if (!order.value) return []
            if (!user.value || user.value.type === 'appraiser') return []
            if (!order.value.incremental) return []
            const actionItems: MenuItem[] = []
            if (user.value.type === 'Admin' || AscentHelper.canDo('Reassign Appraisers')) {
                actionItems.push({
                    label: 'Reassign or Update Fees',
                    action: () => {
                        reassignmentType.value = 'undefined'
                        reassignDialog.value = true
                    },
                })
            }
            if (
                user.value.type === 'Admin' ||
                (AscentHelper.canDo('Impersonate Appraisers') && order.value?.incremental?.order_appraisers.length > 0)
            ) {
                actionItems.push({
                    label: 'Impersonate an Appraiser',
                    action: () => {
                        impersonateDialog.value = true
                    },
                })
            }
            return actionItems
        })

        return {
            actions,
            ImpersonateAppraiserDialog,
            toAppraiserProfile,
            open,
            openDialog,
            order,
            expanded,
        }
    },
})
