






































































































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { isCompleted, order, refreshOrder } from '@/plugins/order/Order'
import axios from '@/plugins/axios'
import { File } from '@/types'
import { newFileDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AddOrderFileDialog.vue'
import EditFile, {
    open,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/EditFileDialog.vue'
import { files, getFiles } from '@/plugins/Files'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import SendInvoiceDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/SendInvoiceDialog.vue'
import { download as DownloadFile } from '@/components/General/DownloadFile.vue'
import $axios from '@/plugins/axios'
import { getNotes } from '@/plugins/Notes'
import OrderPdf from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/OrderPdf.vue'
import ComplianceFile from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/ComplianceFile.vue'
import ReconsiderationFile from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/ReconsiderationFile.vue'
import InvoiceFile from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/InvoiceFile.vue'
import EditOrderForm from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/EditOrderForm.vue'
import { requestFile } from '@/components/OrderScreens/Office/OrderComponents/OfficeFile/RequestTDCX.vue'
import EditBulkFileDialog, { openBulkShareFile } from './CustomerOrderFiles/ShareBulkFileDialog.vue'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    components: {
        InvoiceFile,
        ReconsiderationFile,
        ComplianceFile,
        OrderPdf,
        EditFile,
        SendInvoiceDialog,
        EditOrderForm,
        EditBulkFileDialog,
    },
    props: {},
    setup() {
        const showInactive = ref(false)
        const expanded = ref(false)
        const selected = ref<number[]>([])
        const selectAll = ref<boolean>(false)

        const filteredFiles = computed(() => {
            if (showInactive.value) return files.value
            return files.value.filter((elem: File) => {
                return elem.status != 'Inactive'
            })
        })

        const selectAllLabel = computed(() => {
            return 'Select All (' + filteredFiles.value.length + ')'
        })

        const headers = [
            { text: '', align: 'start', value: 'selectedRow', cellClass: 'px-2' },
            { text: '', align: 'start', sortable: false, value: 'filename', cellClass: 'px-2' },
            { text: '', align: 'center', sortable: false, value: 'type', cellClass: 'px-2' },
            { text: '', align: 'end', sortable: false, value: 'status', cellClass: 'px-2' },
            { text: '', align: 'end', sortable: false, value: 'actions', cellClass: 'px-2' },
        ]

        const clientAdminFiles = computed(() => {
            return files.value.filter((elem: File) => elem.status == 'Admin' || elem.status == 'Client')
        })

        const hasInactive = computed(() => {
            return files.value.some((elem: File) => {
                return elem.status === 'Inactive'
            })
        })
        const downloadPDF = (url: string) => {
            window.location.href = url
        }
        const deleteFile = (file: File): void => {
            confirm('Permanently Delete File', 'Are you sure you want to permanently delete this file?').then(
                (confirm) => {
                    if (confirm) {
                        $axios
                            .delete('/v1/file/' + file.id)
                            .then(() => {
                                if (!order.value) return
                                showSnackbar('File Deleted')
                                getNotes(order.value.id)
                                getFiles(order.value.id)
                            })
                            .catch(() => {
                                showError('Sorry. Something went wrong.')
                            })
                    }
                },
            )
        }
        const bulkDelete = (): void => {
            confirm('Delete Files?', 'Are you sure you want to permanently delete selected files ?').then((confirm) => {
                if (confirm && order.value) {
                    $axios
                        .post('/v1/order/' + order.value.id + '/bulk-file-actions/delete/', {
                            file_ids: selected.value,
                        })
                        .then(() => {
                            if (!order.value) return
                            showSnackbar('Files Deleted')
                            getFiles(order.value.id)
                        })
                        .catch(() => {
                            showError('Sorry. Something went wrong.')
                        })
                }
            })
        }
        const send = (file: File): void => {
            confirm('Send File?', 'Do you want to send this file to the client?').then((confirm) => {
                if (confirm && order.value) {
                    $axios.post('/v1/order/' + order.value.id + '/file/' + file.id + '/send').then(() => {
                        showSnackbar('File Sent successfully')
                    })
                }
            })
        }
        const textConfirm = () => {
            if (!order.value) return
            confirm(
                'Add Appraiser Docs',
                `Are you sure you want to attach an Appraiser E&O and License to this order?`,
            ).then((confirm) => {
                if (!order.value) return
                if (confirm) {
                    axios
                        .post('v1/order/' + order.value.id + '/action/sync-order-with-appraiser-docs')
                        .then((response) => {
                            if (!response.data.errors) {
                                if (!order.value) return
                                getFiles(order.value.id)
                                showSnackbar(response.data.message)
                            }
                            if (response.data.errors.length > 1) {
                                showError('Documents Invalid')
                            }
                            if (response.data.errors.length < 2) {
                                showError(`${response.data.errors[0]}`)
                            }
                            refreshOrder()
                        })
                        .catch(() => {
                            showError('Documents Invalid')
                        })
                }
            })
        }

        const toggleAllRows = () => {
            if (selectAll.value) {
                // Select all rows
                selected.value = filteredFiles.value.map((f: File) => f.id)
            } else {
                // Deselect all rows
                selected.value = []
            }
        }

        const toggleRow = () => {
            if (selected.value.length == filteredFiles.value.length) {
                selectAll.value = true
            } else {
                selectAll.value = false
            }
        }

        const download = (ids: number[]) => {
            const files = filteredFiles.value.filter((elem: File) => {
                return ids.includes(elem.id)
            })
            files.forEach(function (f: File) {
                const name =
                    f.type.replace(/^.*[\\/]/, '') + '_' + f.order_id + '.' + f.path.substr(f.path.lastIndexOf('.') + 1)
                DownloadFile(f.id, name)
            })
        }

        const transformFileDescriptionString = (item: File) => {
            const name = item.user?.name ?? 'System'
            return (
                '<span>' +
                name +
                (item.source !== '' ? ' (' + item.source + ') ' : '') +
                ' @ ' +
                AscentHelper.formatIsoDate(item.created_at, 'M/dd t') +
                '</span>'
            )
        }

        return {
            expanded,
            textConfirm,
            hasInactive,
            showInactive,
            filteredFiles,
            clientAdminFiles,
            downloadPDF,
            open,
            order,
            isCompleted,
            newFileDialog,
            headers,
            selected,
            deleteFile,
            selectAll,
            send,
            bulkDelete,
            openBulkShareFile,
            toggleAllRows,
            toggleRow,
            download,
            selectAllLabel,
            requestFile,
            transformFileDescriptionString,
        }
    },
    methods: {
        openFile(file: File) {
            this.$axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        },

        getStyle(status: string) {
            if (status === 'Inactive') {
                return 'color: lightgrey'
            } else {
                return 'color: primary'
            }
        },

        getColor(status: string) {
            switch (status) {
                case 'All':
                    return 'successdarken2'
                case 'Admin':
                    return 'teal darken-3'
                case 'Appraiser':
                    return 'yellow darken-4'
                case 'Client':
                    return 'purple darken-3'
                case 'Inactive':
                    return 'red darken-3'
            }
        },

        openEdit(file: File) {
            this.$emit('editFile', file)
        },

        regenerateFile(file: File) {
            confirm(
                'Confirm ' + file.type + ' Regeneration',
                'Are you sure you want to regenerate the ' + file.type + '?',
            ).then((confirm) => {
                if (confirm) {
                    this.$axios
                        .post('/v1/order/' + file.order_id + '/file/' + file.id + '/regenerate')
                        .then((response) => {
                            showSnackbar(response.data)
                            refreshOrder()
                        })
                        .catch((error) => {
                            showError(error)
                        })
                }
            })
        },

        editOrderForm() {
            const editOrderForm = this.$refs.editOrderForm as Vue & { open: () => void }
            editOrderForm.open()
        },
    },
    computed: {
        files() {
            return files.value.filter((elem: File) => {
                return elem.status !== 'Inactive'
            })
        },
    },
})
