























































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { statuses, canEdit, appraiserOptions, appraiserValue } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import axios from '@/plugins/axios'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import { AppraiserStats } from '@/types'

export default defineComponent({
    components: {},
    setup(_, { root }) {
        const status = ref('')
        const appraiserStats = ref({
            assigned: '',
            declined: '',
            on_time: '',
            completed: '',
            accepted: '',
            conditional: '',
        } as AppraiserStats)

        const licenses = ref([])
        const insurances = ref([])
        const headers = [
            { text: 'State', value: 'state' },
            { text: 'Type', value: 'type' },
            { text: 'Status', value: 'status' },
            { text: 'File', value: 'file', sortable: false },
            { text: 'Approve', value: 'approve', sortable: false },
        ]

        const getStats = () => {
            $axios.get('/v1/appraiser/' + root.$route.params.id + '/action/get-stats').then((res) => {
                appraiserStats.value = res.data.stats
            })
        }

        const createUser = () => {
            confirm(
                'Add user for this appraiser?',
                `Are you sure you want to add a user for this appraiser? We'll use the same name and email to create the user account.`,
            ).then((confirm) => {
                if (confirm) {
                    axios
                        .post('v1/appraiser/' + root.$route.params.id + '/action/invite-wfg-appraiser')
                        .then((response) => {
                            if (response.data.result == 'error') {
                                showError(response.data.message)
                            }
                            if (response.data.email) {
                                showSnackbar('Invite successfully sent to ' + response.data.email)
                            }
                        })
                        .catch(() => {
                            showError('Something went wrong!')
                        })
                }
            })
        }

        onMounted(() => {
            getStats()
        })

        return {
            status,
            licenses,
            insurances,
            headers,
            appraiserOptions,
            canEdit,
            statuses,
            createUser,
            appraiserValue,
            appraiserStats,
        }
    },
})
