






















































































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { IntegrationOption, LenderOptions } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'
import { getTasks } from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegration.vue'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const branchIdentification = ref(false)
        const optionLocal = ref({
            company: '',
            mercuryBranch: '',
            reggoraMod: '',
        } as IntegrationOption)

        const nameLocal = ref(props.name)
        const typeLocal = props.type

        const reggoraModOptions = [
            { text: 'Disabled', value: 'none' },
            { text: 'Both Fee + Date', value: 'both' },
            { text: 'Fee Only', value: 'fee' },
            { text: 'Date Only', value: 'date' },
        ]

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as IntegrationOption
        }

        if (nameLocal.value === 'Mercury') {
            $axios.get('/v1/lender/' + lender.value.id + '/option/mercury').then((response) => {
                if (response.data && response.data.option_value) {
                    optionLocal.value = response.data.option_value as IntegrationOption
                    if (response.data.option_value.mercuryBranch !== undefined) branchIdentification.value = true
                }
            })
        }

        const optionRef = ref(JSON.parse(JSON.stringify(optionLocal.value)) as IntegrationOption)

        const save = () => {
            optionRef.value = optionLocal.value
            dialog.value = false
            showSnackbar(`${nameLocal.value} saved successfully`)
        }

        const removeIntegration = () => {
            confirm('Remove Integration?', 'Are you sure you want to remove this integration from this lender?').then(
                (confirm) => {
                    if (confirm) {
                        $axios
                            .post(`/v1/lender/${lender.value.id}/remove-option`, {
                                key: typeLocal,
                            })
                            .then(() => {
                                dialog.value = false
                                showSnackbar('Integration successfully removed')
                                getTasks()
                            })
                    }
                },
            )
        }

        return {
            optionRef,
            typeLocal,
            nameLocal,
            save,
            removeIntegration,
            dialog,
            optionLocal,
            lender,
            branchIdentification,
            reggoraModOptions,
        }
    },
})
