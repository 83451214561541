






















import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { branch } from '../../BranchEdit.vue'
import ClosedPanelBranch from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchConfig/Partials/ClosedPanelBranch.vue'
import NewClientTagBranch from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchConfig/Partials/NewClientTagBranch.vue'
import PreventLicensedResidentialBranch from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchConfig/Partials/PreventLicensedResidentialBranch.vue'
import PreventNewBranchOrders from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchConfig/Partials/PreventNewBranchOrders.vue'
import { BranchOption } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'

interface BranchOptions {
    'closed-panel-branch': undefined | BranchOption
    'new-client-tag-branch': undefined | BranchOption
    'prevent-licensed-residential-branch': undefined | BranchOption
    'prevent-new-branch-orders'?: undefined | BranchOption
}

export default defineComponent({
    components: {
        ClosedPanelBranch,
        NewClientTagBranch,
        PreventLicensedResidentialBranch,
        PreventNewBranchOrders,
    },
    setup() {
        const options = ref<BranchOptions>({
            'closed-panel-branch': undefined as undefined | BranchOption,
            'new-client-tag-branch': undefined as undefined | BranchOption,
            'prevent-licensed-residential-branch': undefined as undefined | BranchOption,
        })

        const loadBranchOptions = () => {
            branch.value?.options?.forEach((option) => {
                if (option.option_key === 'closed-panel') {
                    options.value['closed-panel-branch'] = option
                }

                if (option.option_key === 'new-client-tag') {
                    options.value['new-client-tag-branch'] = option as BranchOption
                }

                if (option.option_key === 'prevent-licensed') {
                    options.value['prevent-licensed-residential-branch'] = option
                }

                if (
                    option.option_key === 'prevent-new-orders' &&
                    AscentHelper.customerFeatureEnabled('clients.showClientOrderPermission')
                ) {
                    options.value['prevent-new-branch-orders'] = option
                }
            })
        }

        onMounted(() => {
            if (AscentHelper.customerFeatureEnabled('clients.showClientOrderPermission')) {
                options.value = {
                    'closed-panel-branch': undefined as undefined | BranchOption,
                    'new-client-tag-branch': undefined as undefined | BranchOption,
                    'prevent-licensed-residential-branch': undefined as undefined | BranchOption,
                    'prevent-new-branch-orders': undefined as undefined | BranchOption,
                }
            }

            loadBranchOptions()
        })

        return {
            options,
        }
    },
})
