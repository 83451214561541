



















import { defineComponent } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadAdditionalSalesInfo from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadAdditionalInformation/PreloadAdditionalSalesInfo.vue'
import PreloadAdditionalRecordDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadAdditionalInformation/PreloadAdditionalRecordDetails.vue'
import PreloadAdditionalAppraisalInfo from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadAdditionalInformation/PreloadAdditionalAppraisalInfo.vue'

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadAdditionalSalesInfo,
        PreloadAdditionalRecordDetails,
        PreloadAdditionalAppraisalInfo,
    },
    setup() {
        return {}
    },
})
