

































import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { Milestone } from '@/types'
import { DateTime } from 'luxon'

/**
 * @name ClientOrderDates
 * Component on Client Order Screen that shows the important dates on the order.
 *
 * @SetupComputed inspectionDate - looks through the order milestones and finds the inspection date if there is any.
 */

export default defineComponent({
    name: 'ClientOrderDates',
    setup() {
        const inspectionDate = computed(() => {
            if (!order.value || !order.value.incremental) return
            const date = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Inspection Date'
            })
            if (!date) return
            const property = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone(order.value.timezone)
            const local = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone('local')
            return property.toFormat('EEE, MMM d, t (ZZZZ)') + ' / ' + local.toFormat('t (ZZZZ)')
        })
        return { inspectionDate, order }
    },
})
