
































import { defineComponent } from '@vue/composition-api'
import { Notification } from '@/types/notifications'
import { hideSnackbar, newNotifications } from '@/pages/System/Notifications/notifications'
import { getOrderById } from '@/plugins/order/Order'
import { Order } from '@/types'
import { user } from '@/plugins/User'

/**
 * @name 'Notification Snackbar'
 * Is the notifications snackbar shown when a user gets a notification
 *
 * @SetupMethod refreshPage: Will reload the page they are on.
 */
export default defineComponent({
    setup() {
        const refreshPage = () => {
            window.location.reload()
        }

        return {
            user,
            refreshPage,
        }
    },
    props: {},
    data() {
        return {
            show: true,
            snackbar: false,
            timeout: 6000,
            newNotifications,
        }
    },

    computed: {
        /**
         * returns the new notification to display in the snackbar
         */
        notifications() {
            return newNotifications.value
        },
    },
    methods: {
        /**
         * Will close the snackbar notification
         * @param {Notification} notification - Notification in the snackbar
         */
        removeNotification(notification: Notification) {
            hideSnackbar(notification)
        },
        /**
         * Will open the order to view
         * @param {Notification} notification - Notification in the snackbar
         */
        viewOrder(notification: Notification) {
            if (!notification.order) return
            getOrderById((notification.order as Order).id as number)
        },
    },
})
