































































import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import { Appraiser, AppraiserOptionPanel, AppraiserPanel, PanelAppraiser } from '@/types'
import { order } from '@/plugins/order/Order'
import {
    allowSelect,
    selectedAppraiser,
    clientDeadline,
    appraiserDeadline,
    isConditional,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { openAssignDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignDialog.vue'
import { appraiserStatus } from '@/pages/Appraiser/Appraiser'
import { openAppraiserOrdersDialog } from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/ViewAppraiserOrdersDialog.vue'

/**
 * @name ExpandedAssignAppraiser
 * Partial in expansion table on the Assign Table to show other appraiser others and the button to assign the appraiser to an order
 *
 * @SetupData error - sets error if there is one
 * @SetupMethod editAppraiser - opens a new window to the edit screen for specific appraiser
 * @SetupComputed isAppraiserEligible - checks appraiser eligibility and returns true or false depending on if they are eligible or not
 * @SetupMethod assignOrder - opens up dialog to assign appraiser to the order
 *
 */

export default defineComponent({
    props: {
        /**
         * Appraiser details
         */
        appraiser: {
            type: Object as PropType<Appraiser>,
        },
        /**
         * Appraiser details in the Appraiser Panel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup(props) {
        const error = ref(false)

        const editAppraiser = () => {
            if (!props.item || !props.item.appraiser) return
            window.open('/appraiser/' + props.item.appraiser.id + '/edit')
        }

        const isAppraiserEligible = computed(() => {
            if (!props.item || !props.item.eligible || !props.item.appraiser) return false

            if (
                !props.item.eligible.result ||
                appraiserStatus(props.item.appraiser.status, props.item.appraiser.amc_appraiser_status) !== 'Active'
            )
                return false
            return true
        })

        const eligibilityDetails = computed(() => {
            if (!props || !props.item || !props.item.eligible) return ''
            return props.item.eligible.details
        })

        const isSameAppraiser = computed(() => {
            if (!order.value) return false
            if (!order.value.appraiser_id) return false
            if (!props.item) return false
            return order.value.appraiser_id === props.item.appraiser.id
        })

        const assignOrder = () => {
            if (!props || !props.item || !props.item.appraiser) return
            selectedAppraiser.value = props.item.appraiser.id
            error.value = false
            if (selectedAppraiser.value == null) {
                error.value = true
                return
            }

            //const split = this.$refs.split as Vue & { open: () => void }
            //split.open()
            openAssignDialog({
                appraiser: props.item.appraiser as PanelAppraiser,
                appraiser_deadline: appraiserDeadline.value,
                client_deadline: clientDeadline.value,
                eligible: isAppraiserEligible.value,
                details: eligibilityDetails.value as string,
                manually_overridden: false,
            })
        }

        const appraiserStagger = computed(() => {
            if (!props.item || !props.item.appraiser || !props.item.appraiser.options) return -1
            return props.item?.appraiser?.options.findIndex((elem: AppraiserOptionPanel) => elem.stagger)
        })

        return {
            editAppraiser,
            appraiserStagger,
            allowSelect,
            assignOrder,
            isAppraiserEligible,
            eligibilityDetails,
            openAppraiserOrdersDialog,
            isSameAppraiser,
            isConditional,
            order,
        }
    },
})
