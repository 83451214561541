







































































import { defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api'
import LenderContact from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderCompanyInfo/LenderCompanyInfo.vue'
import LenderBranches from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBranches/LenderBranches.vue'
import LenderBilling from '@/components/General/LenderOptions/LenderBilling.vue'
import LenderQualityAndDelivery from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityAndDelivery.vue'
import LenderPreferredPanels from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderAppraiserPanel/LenderAppraiserPanel.vue'
import LenderIntegration from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegration.vue'
import LenderNotes from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderNotes/LenderNotes.vue'
import LenderDirectives from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderDirectives/LenderDirectives.vue'
import { LenderNavItem, OfficeLenderNavItem } from '@/pages/AMC/Lenders/LenderListing/Partials/LenderNavItems'
import { Task } from '@/types'
import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import LenderOnboardingStatusIndicator from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatusIndicator.vue'
import LenderSublenders from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderSublenders/LenderSublenders.vue'
import axios from 'axios'
import OfficeLenderBranches from '@/components/General/LenderOptions/OfficeLenderEditPartials/OfficeLenderBranches.vue'
import OfficeLenderClientTags from '@/components/General/LenderOptions/OfficeLenderEditPartials/OfficeLenderClientTags.vue'
import OfficeLenderAssigning from '@/components/General/LenderOptions/OfficeLenderEditPartials/OfficeLenderAssigning.vue'
import OfficeLenderDocuments from '@/components/General/LenderOptions/OfficeLenderEditPartials/OfficeLenderDocuments.vue'
import OfficeLenderCompanyInstruction from '@/components/General/LenderOptions/OfficeLenderEditPartials/OfficeLenderCompanyInstruction.vue'
import OfficeLenderDeliveryInstruction from '@/components/General/LenderOptions/OfficeLenderEditPartials/OfficeLenderDeliveryInstruction.vue'
import OfficeCoordinators from '@/components/General/LenderOptions/OfficeLenderEditPartials/OfficeCoordinators.vue'
import AlternativeProducts from '@/components/General/LenderOptions/AmcLenderEditPartials/AlternativeProducts/AlternativeProducts.vue'
import { getLenderSettings, lenderOptions } from '@/components/General/LenderOptions/LenderOptions'
import { lender, lenderId } from '@/components/General/LenderOptions/LenderOptions'
import { user } from '@/plugins/User'

export const dialog = ref(false)

export default defineComponent({
    components: {
        LenderOnboardingStatusIndicator,
        LenderOnboardingStatus,
        LenderContact,
        LenderBranches,
        LenderBilling,
        LenderQualityAndDelivery,
        LenderPreferredPanels,
        LenderIntegration,
        LenderDirectives,
        LenderSublenders,
        LenderNotes,
        OfficeLenderBranches,
        OfficeLenderClientTags,
        OfficeLenderAssigning,
        OfficeLenderDocuments,
        OfficeLenderCompanyInstruction,
        OfficeLenderDeliveryInstruction,
        OfficeCoordinators,
        AlternativeProducts,
    },
    name: 'LenderEditDialog',
    setup() {
        const navItemsWithStatus = ref([] as LenderNavItem[])
        const currentTab = ref({})
        const totalStatusPoints = ref(0)
        const maxStatusProgress = ref(6)

        watch(dialog, () => {
            if (dialog.value === false) {
                currentTab.value = 0
            }
        })

        const calculateStatusProgress = (status: string) => {
            if (status === 'Completed') {
                totalStatusPoints.value += 1
            }
        }

        const updateStatus = (task: Task) => {
            navItemsWithStatus.value = navItemsWithStatus.value.map((item) => {
                if (item.task === task.task) {
                    return { ...item, onboardingStatus: task.status }
                }
                return item
            })
            totalStatusPoints.value = 0
            navItemsWithStatus.value.forEach((item) => {
                if (item.onboardingStatus) {
                    calculateStatusProgress(item.onboardingStatus)
                }
            })
        }

        const getLender = async () => {
            if (!lenderId.value) return
            nextTick(() => {
                getLenderSettings().then(() => {
                    totalStatusPoints.value = 0
                    axios.get(`/v1/lender/${lenderId.value}`).then((res) => {
                        lender.value = res.data

                        navItemsWithStatus.value = lenderOptions.value.map((navItem: OfficeLenderNavItem) => {
                            let status = ''

                            lender.value.tasks.forEach((task) => {
                                if (task.task === navItem.task) {
                                    status = task.status
                                }
                            })

                            if (status) {
                                calculateStatusProgress(status)
                                return { ...navItem, onboardingStatus: status }
                            }

                            return navItem
                        })
                    })
                })
            })
        }

        const closeDialog = (): void => {
            lender.value.name = ''
            dialog.value = false
        }

        onMounted(getLender)

        watch(lenderId, getLender)

        return {
            updateStatus,
            currentTab,
            maxStatusProgress,
            dialog,
            navItemsWithStatus,
            lender,
            totalStatusPoints,
            closeDialog,
            user,
        }
    },
})
