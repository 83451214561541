var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticStyle:{"background-color":"transparent","max-width":"100%"}},[_c('v-slide-group',{staticClass:"v-slide-group__content",attrs:{"show-arrows":""}},[_vm._l((_vm.filteredTags),function(tag){return _c('v-slide-item',{key:tag.id},[_c('v-chip',_vm._g({staticClass:"mx-1",attrs:{"close":_vm.canDeleteTag(tag),"small":"","text-color":"white","color":tag.color,"pill":"","dark":""},on:{"click:close":function($event){return _vm.removeTag(tag)}}},
                    tag.tag === 'Mod Fee Req'
                        ? {
                              click: function () {
                                  _vm.openDialog = true
                                  _vm.getModHistory(tag)
                              },
                          }
                        : {}
                ),[(tag.icon != null)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$vuetify.icons."+_vm._s(tag.icon))]):_vm._e(),_vm._v(" "+_vm._s(tag.tag)+" ")],1)],1)}),(
                _vm.orderTags.length > 0 &&
                (_vm.$ascent.isCustomer() || _vm.$ascent.featureEnabled('orderScreen.appraisersCanManageTags'))
            )?_c('v-slide-item',[_c('v-menu',{attrs:{"bottom":"","offset-y":"","max-height":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":"blue-grey lighten-4","text-color":"accentBlack","dark":"","small":""}},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("$vuetify.icons.plus")]),_vm._v(" Tag ")],1)]}}],null,false,1884651845)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.orderTags),function(tag){return _c('v-list-item',{key:tag.id,on:{"click":function($event){return _vm.addTag(tag)}}},[_c('v-list-item-content',[_c('v-chip',{attrs:{"color":tag.color,"small":"","dark":""}},[(tag.icon)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$vuetify.icons."+_vm._s(tag.icon))]):_vm._e(),_vm._v(" "+_vm._s(tag.title)+" ")],1)],1)],1)}),1)],1)],1):_vm._e(),_c('ModFeeRequestDialog')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }