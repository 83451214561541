


















import { File } from '@/types'

import { defineComponent, PropType } from '@vue/composition-api'

import { download as DownloadFile } from '@/components/General/DownloadFile.vue'

/**
 * @name AppraiserFileComponent
 * Section in Appraiser Order Screen that holds all of the files on the order for the appraiser to see. Appraiser can open and download the files
 *
 *
 */

export default defineComponent({
    props: {
        /**
         * Specific File for the appraiser to open and download
         */
        file: {
            type: Object as PropType<File>,
        },
    },
    data() {
        return {
            /**
             * Boolean to show appraiser they can click on file
             */
            hover: false,
        }
    },
    methods: {
        /**
         *
         * @param {string} status - The status of who can see the file
         * @returns {string} - color that tag in file will be
         */
        getColor(status: string) {
            switch (status) {
                case 'All':
                    return 'successdarken2'
                case 'Admin':
                    return 'teal darken-3'
                case 'Appraiser':
                    return 'yellow darken-4'
                case 'Client':
                    return 'purple darken-3'
                case 'Inactive':
                    return 'red darken-3'
            }
        },
        /**
         * Downloads files
         * @param {File} file - File that appraiser will download
         */
        download(file: File) {
            const name =
                file.type.replace(/^.*[\\/]/, '') +
                '_' +
                file.order_id +
                '.' +
                file.path.substr(file.path.lastIndexOf('.') + 1)
            DownloadFile(file.id, name)
        },
        /**
         * Opens a link to the file
         * @param {File} file - Specific file
         */
        openFile(file: File) {
            this.$axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        },
    },
})
