


























import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { orderProducts } from '@/plugins/order/OrderProducts'

interface ExpenseList {
    name: string
    fee: number
}

export default defineComponent({
    props: {
        grossExpenses: {
            type: Number,
        },
    },
    setup() {
        const expensesList = computed(() => {
            const expenses: ExpenseList[] = []
            if (!order.value || !order.value.incremental) return expenses
            const techFee = orderProducts.value.find((elem) => elem.product_name == 'Tech Fee')
            if (techFee) expenses.push({ name: 'Tech Fee', fee: techFee.appraiser_value as number })

            if (!order.value.incremental.order_appraisers.length) return expenses
            order.value.incremental.order_appraisers.forEach((elem) => {
                expenses.push({
                    name: elem.role,
                    fee: elem.fee,
                })
            })
            return expenses
        })

        return {
            expensesList,
            order,
        }
    },
})
