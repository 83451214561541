
































































import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import axios from '@/plugins/axios'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { Entity } from '@/types'
import {
    entities,
    getEntities,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderEntities/Entities'

export default defineComponent({
    setup() {
        const activeEntity = ref<Entity | null>(null)
        const detailsOpen = ref<boolean>(false)

        const close = () => {
            detailsOpen.value = false
            activeEntity.value = null
        }

        const open = (entity: Entity) => {
            activeEntity.value = entity
            detailsOpen.value = true
        }

        const headers = [
            { text: 'Name', align: 'start', sortable: false, value: 'name' },
            { text: 'Role', align: 'start', sortable: false, value: 'role' },
            { text: 'Actions', align: 'end', sortable: false, value: 'actions' },
        ]

        const deleteEntity = (entity: Entity) => {
            confirm('Delete Entity', 'Are you sure you want to remove ' + entity.name + ' from this order?').then(
                (confirm) => {
                    if (confirm && order.value) {
                        axios
                            .delete('/v1/order/' + order.value.id + '/entities/' + entity.id)
                            .then(() => {
                                getEntities()
                                showSnackbar(entity.name + ' was removed from this order.')
                            })
                            .catch(() => {
                                showError('Sorry. Something went wrong.')
                            })
                    }
                },
            )
        }

        return {
            order,
            headers,
            activeEntity,
            close,
            deleteEntity,
            detailsOpen,
            entities,
            open,
        }
    },
    methods: {
        getStyle(status: string) {
            if (status === 'Inactive') {
                return 'color: lightgrey'
            } else {
                return 'color: primary'
            }
        },

        getColor(status: string) {
            switch (status) {
                case 'All':
                    return 'successdarken2'
                case 'Admin':
                    return 'teal darken-3'
                case 'Appraiser':
                    return 'yellow darken-4'
                case 'Client':
                    return 'purple darken-3'
                case 'Inactive':
                    return 'red darken-3'
            }
        },
    },
})
