












































































































































































































































import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import {
    compPosition,
    infoCompOptions,
    complexLatLng,
    complexity,
    currentAppraisers,
    complexScoreColor,
    appraiserComplexScoreColor,
    appraiserComplexScoreText,
    currentAppraiserLength,
    Complexity,
    loadComplexity,
    loadKukun,
    kukun,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import ComplexityCompsList from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/ComplexityCompsList.vue'
import OrderComplexityComparablesTable from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexityComparablesTable.vue'
import ComplexCompsPopup from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/ComplexCompsPopup.vue'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import ComplexiryColorPanel from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/ComplexiryColorPanel.vue'
import { Quote } from '@/types'
import KukunPropertyContainer from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/KukunPropertyContainer.vue'

const state = reactive({
    dialog: false,
})

export const openInfoDialog = ref(false)
export const openInfo = ref(true)
const orderComplex = ref(false)
const propComplexity = ref({} as Complexity)

export const openComplexity = () => {
    if (!order.value) return showError('No Data for this Order is Available')
    if (!propComplexity.value.property) return showError('No Data for this Order is Available')
    loadComplexity()
    loadKukun()
    complexity.value = propComplexity.value
    orderComplex.value = true
    state.dialog = true
}

export const openComplexityQuote = (data: Quote) => {
    complexLatLng.value = {
        lat: data.address.latitude,
        lng: data.address.longitude,
    }
    complexity.value = data as Quote
    orderComplex.value = false
    state.dialog = true
}

/**
 * @name PropertyComplexity
 * Dialog that show the complexity of a certain property compared to other properties around it
 *
 * @SetupData complexLatLng: Latitude and Longitude of property
 * @SetupData complexity: Complexity data for property
 * @SetupData orderComplex: Boolean showing if there is complexity or not
 *
 * @method openComplexity - Opens complexity dialog and gets the data for the complexity, Endpoint: 'v1/order/' + order.value.id + '/complexity'
 * @computed complexScoreColor - color of complexity determined by ComplexityScore
 */

export default defineComponent({
    components: {
        KukunPropertyContainer,
        GmapCustomMarker,
        OrderComplexityComparablesTable,
        ComplexityCompsList,
        ComplexCompsPopup,
        ComplexiryColorPanel,
    },
    setup() {
        const closeInfo = () => {
            openInfoDialog.value = false
            openInfo.value = false
        }

        onMounted(() => {
            propComplexity.value = complexity.value as Complexity
        })

        return {
            currentAppraisers,
            state,
            order,
            complexity,
            complexScoreColor,
            openInfoDialog,
            complexLatLng,
            compPosition,
            infoCompOptions,
            closeInfo,
            orderComplex,
            appraiserComplexScoreColor,
            appraiserComplexScoreText,
            currentAppraiserLength,
            kukun,
        }
    },
})
