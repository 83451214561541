

























































import { defineComponent, ref, computed } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import axios from '@/plugins/axios'
import { Tag, TagActionClickableAction } from '@/types'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import TagActionHtml from '@/components/TagActions/Partials/TagActionHtml.vue'
import { ValidationObserver } from 'vee-validate'

const tagActionDialogOpen = ref(false)
const currentTag = ref({} as Tag)
const tagAction = ref({})
const loaded = ref(false)
const actionInProgress = ref(false)
const companyCardAuthorizationCode = ref('')
const errorMessage = ref('')

const closeTagActionDialog = () => {
    tagActionDialogOpen.value = false
    currentTag.value = {} as Tag
    tagAction.value = {}
    loaded.value = false
    actionInProgress.value = false
    errorMessage.value = ''
    companyCardAuthorizationCode.value = ''
}

const getTagAction = async (uuid: string) => {
    if (!order.value) return
    await axios
        .get('/v1/customer/' + order.value.customer_id + '/tag-actions/' + uuid)
        .then((response) => {
            tagAction.value = response.data
            loaded.value = true
        })
        .catch((error) => {
            showError(error.data.message ?? 'Unable to retrieve specified action.')
            closeTagActionDialog()
        })
}

export const openTagActionDialog = async (tag: Tag) => {
    if (!tag.action_id) return
    if (!user.value) return
    if (!['desk', 'office', 'amc'].includes(user.value.type)) return

    tagAction.value = {}
    await getTagAction(tag.action_id)

    if (!loaded.value) return

    currentTag.value = tag
    tagActionDialogOpen.value = true
}

export default defineComponent({
    components: {
        TagActionHtml,
        ValidationObserver,
    },
    setup() {
        const handleClick = (action: TagActionClickableAction) => {
            if (!order.value) return
            if (!currentTag.value) return

            errorMessage.value = ''
            actionInProgress.value = true

            const payload = { ...action, ...{ companyCardAuthorizationCode: companyCardAuthorizationCode.value } }

            axios
                .post(
                    '/v1/order/' + order.value.id + '/tag/' + currentTag.value.id + '/action/process-tag-action',
                    payload,
                )
                .then((response) => {
                    if (response.data.error) {
                        errorMessage.value = response.data.message
                        actionInProgress.value = false
                        return
                    }

                    showSnackbar(response.data.result ?? response.data.message ?? response.data)
                    closeTagActionDialog()
                })
                .catch((error) => {
                    showError(error.data.message ?? 'Error completing the selected action.')
                    closeTagActionDialog()
                })
        }

        const showVerificationCode = computed(() => {
            if (!currentTag.value) return false
            if (currentTag.value.tag !== 'Company CC Review') return false
            return !currentTag.value.extra?.appraisalScopeUrl
        })

        return {
            tagActionDialogOpen,
            tagAction,
            handleClick,
            loaded,
            actionInProgress,
            companyCardAuthorizationCode,
            errorMessage,
            currentTag,
            closeTagActionDialog,
            showVerificationCode,
        }
    },
})
