



























import { queue } from '@/pages/AMC/Quality/Process/QcReview/Partials/QCReviewProcessData'
import { defineComponent } from '@vue/composition-api'
import { order, openCloseOrder, closeOrder } from '@/plugins/order/Order'

/**
 * @name AppraiserOrderToolbarMobile
 * The mobile version of the appraiser order toolbar
 */
export default defineComponent({
    setup() {
        return { order }
    },
    methods: {
        /**
         * Closes the order screen
         */
        closeAppraisal(): void {
            if (queue.value !== undefined) {
                openCloseOrder(false)
                return
            }
            closeOrder()
        },
    },
})
