





























































import { defineComponent, ref } from '@vue/composition-api'
import { refreshDashboardCounts, UserList, users } from '@/components/OrderTable/Classes/TableFilters'
import { updateOrders, viewUser } from '@/components/OrderTable/Classes/OrderTable'
import { favorites, user as userStore } from '@/plugins/User'
import { name } from '@/components/OrderTable/Classes/OrderTable'
import { updateQuoteTable } from '@/pages/AMC/Quotes/Quotes'

export const dialog = ref(false)

/**
 * @name TableUserSelectDialog
 * Dialog on Order Table that allows user to select different users to see their orders on the Order Table
 *
 * @SetupData favorites - Array of users that the user selected as their favorites
 * @SetupData user - Object of current user
 * @SetupMethod refreshDash - Resets the dashboard with orders that the user has selected
 * @SetupMethod resetSelection - Will reset dashboard back to the specific user or selects all users
 * @SetupMethod selectAllFavoriteUsers - Selects all favorites of the user logged in
 */

export default defineComponent({
    props: {
        tableType: {
            type: String,
        },
    },
    setup(props) {
        const refreshDash = () => {
            dialog.value = false
            localStorage.setItem(
                `user-select-${userStore.value?.id}_${userStore.value?.customer_id}`,
                JSON.stringify(viewUser.value),
            )
            if (name.value === 'Quote Table') return updateQuoteTable()
            if (!props.tableType) {
                refreshDashboardCounts()
                updateOrders()
            }
        }

        const resetSelection = () => {
            if (users.value.some((user: UserList) => user.id === userStore.value?.id)) {
                viewUser.value = [userStore.value?.id as number]
            } else {
                viewUser.value = [-1]
            }
        }
        const selectAllWhenNoneSelected = (id: number) => {
            if (viewUser.value.length === 0 && id !== -1) viewUser.value = [-1]
        }

        const selectAllFavoriteUsers = () => {
            viewUser.value = favorites.value
        }

        return {
            viewUser,
            users,
            dialog,
            refreshDash,
            resetSelection,
            selectAllWhenNoneSelected,
            selectAllFavoriteUsers,
        }
    },
})
