




import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
    props: {
        source: { type: String, required: true },
    },
    setup(props) {
        const formattedSource = computed(() => {
            try {
                return JSON.stringify(JSON.parse(props.source), null, 2)
            } catch (_) {
                return props.source
            }
        })

        return { formattedSource }
    },
})
