











import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import QualityAction from '@/components/OrderScreens/Workflow/QualityAction.vue'
import AppraiserOfficeReviewAction from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserOfficeReviewAction.vue'

/**
 * @name UnifiedQualityAction
 * Workflow step in AMC workflow that opens the quality tool or approve order to be sent back
 *
 */

export default defineComponent({
    components: {
        QualityAction,
        AppraiserOfficeReviewAction,
    },
    setup() {
        const showQcReview = computed(() => {
            if (!order.value) return false
            if (
                order.value.tags?.some((elem) => {
                    return elem.tag === 'QC Review'
                })
            ) {
                return true
            }
            return ['QC Review', 'Reconsideration Review'].includes(order.value.status)
        })

        const showApproval = computed(() => {
            return order?.value?.tags?.some((elem) => {
                return (
                    ['Primary Review Required', 'Review Required', 'Commercial Review'].indexOf(elem.tag as string) > -1
                )
            })
        })

        return {
            showQcReview,
            showApproval,
        }
    },
})
