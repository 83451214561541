





























import { defineComponent, PropType } from '@vue/composition-api'
import { openAssignPanelNotesDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignPanelNotesDialog.vue'

import { AppraiserPanel } from '@/types'

/**
 * @name EmailPhoneAction
 * Action in the Assign Table that will pop up dialog for AMC user to add notes or send email to appraiser
 *
 * @SetupMethod openAssignPanelNotesDialog - opens up dialog
 */

export default defineComponent({
    props: {
        /**
         * Specific appraiser in the AppraiserPanel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup() {
        return {
            openAssignPanelNotesDialog,
        }
    },
})
