import { computed } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'
import { ChevronProgressItem } from '@/components/General/ChevronProgressBar/ChevronProgressItem.vue'

export const orderStatuses = computed(() => {
    if (user.value?.type === 'office') {
        return [
            'Evaluate',
            'Allocate',
            'Schedule',
            'Waiting Inspection',
            'Complete',
            'Internal Review',
            'UW Revision',
            'Reconsideration of Value',
            'Completed',
        ]
    }
    if (user.value?.type === 'appraiser') {
        return ['Accept', 'Schedule', 'Scheduled', 'Waiting Inspection', 'Complete', 'Appraiser Due']
    }
    if (user.value?.type === 'client') {
        return ['New Order', 'Accept', 'Schedule', 'Waiting Inspection', 'Complete', 'Due']
    }
    if (user.value?.type === 'amc') {
        return ['Accept', 'Schedule', 'Inspection', 'Appraiser Due', 'Client Due']
    }
    return []
})

export const getClass = (orderStatusIndex: number, status: string): string => {
    if (orderStatuses.value.indexOf(status) < orderStatusIndex) return ' complete-order-status-bar'
    if (orderStatuses.value.indexOf(status) === orderStatusIndex) return ' current-order-status-bar'
    return ''
}

export const progressBarInfo = computed(() => {
    if (!order.value || !order.value.incremental || !order.value.incremental.milestones) return []
    let orderStatusIndex = orderStatuses.value.indexOf(order.value?.status)
    if (user.value?.type === 'amc') {
        if (order.value?.status === 'Assign' || order.value?.status === 'Accept') orderStatusIndex = 0
        if (order.value?.status === 'Waiting Inspection') orderStatusIndex = 2
        if (order.value?.status === 'Complete') orderStatusIndex = 3
    }
    let statuses: ChevronProgressItem[] = []

    if (user.value?.type === 'office') {
        statuses = [
            {
                title: 'New Order',
                value: (order.value?.requestedDate
                    ? order.value?.requestedDate
                    : order.value?.incremental.accepted) as number,
                status: 'Evaluate',
                returnClass: getClass(orderStatusIndex, 'Evaluate'),
            },
        ]

        statuses.push({
            title: orderStatuses.value.indexOf('Allocate') < orderStatusIndex ? 'Assigned' : 'Assign',
            value: order.value?.assigned as number,
            status: 'Allocate',
            returnClass: getClass(orderStatusIndex, 'Allocate'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Schedule') < orderStatusIndex ? 'Scheduled' : 'Schedule',
            value: order.value?.scheduled as number,
            status: 'Schedule',
            returnClass: getClass(orderStatusIndex, 'Schedule'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Waiting Inspection') < orderStatusIndex ? 'Inspected' : 'Inspection',
            value: order.value.inspection as number,
            status: 'Waiting Inspection',
            returnClass: getClass(orderStatusIndex, 'Waiting Inspection'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Complete') < orderStatusIndex ? 'Vendor Completed' : 'Complete',
            value: order.value?.appraiserCompleted as number,
            status: 'Complete',
            returnClass: getClass(orderStatusIndex, 'Complete'),
        })

        statuses.push({
            title: order.value?.clientCompleted ? 'Client Completed' : 'Client Due',
            value: order.value?.clientCompleted != null ? order.value?.clientCompleted : order.value?.clientDeadline,
            status: 'Completed',
            returnClass:
                (order.value?.appraiserCompleted != null && order.value?.clientCompleted == null) ||
                order.value?.status == 'Internal Review'
                    ? ' current-order-status-bar'
                    : order.value.status == 'Completed'
                    ? ' complete-order-status-bar'
                    : '',
        })
    }

    if (user.value?.type === 'appraiser') {
        statuses = [
            {
                title: orderStatusIndex > 0 ? 'Assigned' : 'Assign',
                value: order.value?.assigned as number,
                status: 'Assigned',
                returnClass: getClass(orderStatusIndex, 'Assigned'),
            },
        ]

        statuses.push({
            title: orderStatuses.value.indexOf('Accept') < orderStatusIndex ? 'Accepted' : 'Accept',
            value: new Date(order.value?.incremental.accepted as unknown as string).getTime() as number,
            status: 'Accept',
            returnClass: getClass(orderStatusIndex, 'Accept'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Schedule') < orderStatusIndex ? 'Scheduled' : 'Schedule',
            value: order.value?.scheduled as number,
            status: 'Schedule',
            returnClass: getClass(orderStatusIndex, 'Schedule'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Waiting Inspection') < orderStatusIndex ? 'Inspected' : 'Inspection',
            value: order.value.inspection as number,
            status: 'Waiting Inspection',
            returnClass: getClass(orderStatusIndex, 'Waiting Inspection'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Complete') < orderStatusIndex ? 'Completed' : 'Complete',
            value: order.value?.appraiserCompleted as number,
            status: 'Complete',
            returnClass: getClass(orderStatusIndex, 'Complete'),
        })

        statuses.push({
            title: 'Appraiser Due',
            value: order.value?.appraiserDeadline as number,
            status: 'Appraiser Due',
            returnClass: getClass(orderStatusIndex, 'Appraiser Due'),
        })
    }

    if (user.value?.type === 'client') {
        statuses = [
            {
                title: 'New Order',
                value: order.value?.requestedDate as number,
                status: 'Accept',
                returnClass: getClass(orderStatusIndex, 'Accept'),
            },
        ]

        statuses.push({
            title: orderStatuses.value.indexOf('Schedule') < orderStatusIndex ? 'Scheduled' : 'Schedule',
            value: order.value?.scheduled as number,
            status: 'Schedule',
            returnClass: getClass(orderStatusIndex, 'Schedule'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Waiting Inspection') < orderStatusIndex ? 'Inspected' : 'Inspection',
            value: order.value.inspection as number,
            status: 'Waiting Inspection',
            returnClass: getClass(orderStatusIndex, 'Waiting Inspection'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Complete') < orderStatusIndex ? 'Completed' : 'Complete',
            value: order.value?.clientCompleted as number,
            status: 'Complete',
            returnClass: getClass(orderStatusIndex, 'Complete'),
        })

        statuses.push({
            title: 'Due',
            value: order.value?.clientDeadline as number,
            status: 'Due',
            returnClass: getClass(orderStatusIndex, 'Due'),
        })
    }

    if (user.value?.type === 'amc') {
        statuses = [
            {
                title: 'New Order',
                value: order.value?.requestedDate as number,
                status: 'Accept',
                returnClass: getClass(orderStatusIndex, 'Accept'),
            },
        ]

        statuses.push({
            title: orderStatuses.value.indexOf('Schedule') < orderStatusIndex ? 'Scheduled' : 'Schedule',
            value: order.value?.scheduled as number,
            status: 'Schedule',
            returnClass: getClass(orderStatusIndex, 'Schedule'),
        })

        statuses.push({
            title: orderStatuses.value.indexOf('Inspection') < orderStatusIndex ? 'Inspected' : 'Inspection',
            value: order.value.inspection as number,
            status: 'Inspection',
            returnClass: getClass(orderStatusIndex, 'Inspection'),
        })

        statuses.push({
            title: 'Appraiser Due',
            value: order.value?.appraiserDeadline as number,
            status: 'Appraiser Due',
            returnClass: getClass(orderStatusIndex, 'Appraiser Due'),
        })

        statuses.push({
            title: 'Client Due',
            value: order.value?.clientDeadline as number,
            status: 'Client Due',
            returnClass: getClass(orderStatusIndex, 'Client Due'),
        })
    }

    return statuses
})
