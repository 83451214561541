







































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { OfficeAssigningOption } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { ValidationObserver } from 'vee-validate'
import AmcUserSelect from '@/components/General/AutoCompletes/AmcUserSelect.vue'

export default defineComponent({
    components: { ValidationObserver, AmcUserSelect },
    name: 'OfficeLenderAssigning',
    setup() {
        const options = ref({})
        const assigning = ref([] as OfficeAssigningOption[])
        const displayAssigning = ref()
        const dialog = ref(false)

        const getTasks = () => {
            $axios.get(`/v1/lender/${lender.value.id}/option/coordinators`).then((response) => {
                if (response.data.option_value) {
                    assigning.value = response.data.option_value
                    displayAssigning.value = response.data.option_value
                }
            })
        }

        onMounted(getTasks)

        const saveOption = () => {
            dialog.value = false
            displayAssigning.value = assigning.value
            showSnackbar('Option saved successfully')
        }

        return {
            options,
            assigning,
            saveOption,
            dialog,
            displayAssigning,
            lender,
        }
    },
})
