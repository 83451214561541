import $axios from '@/plugins/axios'
import { ref } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { AmcUser, Team, User } from '@/types'
import { viewUser } from '@/components/OrderTable/Classes/OrderTable'

export const teams = ref<Team[]>([])
export const users = ref([])
export const specialists = ref([])
export const dropdownList = ref([])

export const getAllTeams = () => {
    $axios.get('/v1/team').then((response) => {
        teams.value = response.data
    })
}

export const getAllUsers = async () => {
    return await $axios.get('v1/customer/user').then((response) => {
        users.value = response.data
        const filteredUsers = response.data.filter((elem: AmcUser) => {
            if (elem.role && elem.role === 'Admin') return true
            if (elem.permissions !== undefined && typeof elem.permissions === 'object') {
                const elemPermissions: string[] = elem.permissions
                return elemPermissions.includes('Can Be Assigned Orders')
            }
            return false
        })
        if (user.value && ![1, 2].includes(user.value.customer_id)) {
            specialists.value = response.data
        } else {
            specialists.value = filteredUsers
        }

        if (
            filteredUsers.find((elem: User) => {
                return elem.id == user.value?.id
            }) !== undefined &&
            user.value
        ) {
            viewUser.value = [user.value?.id]
        }
    })
}
