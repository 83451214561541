













































import { defineComponent, computed, PropType } from '@vue/composition-api'
import { Appraiser, AppraiserPanel, PanelAppraiser } from '@/types'
import { allowSelect, selectedAppraiser } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { openAssignDialog } from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignDialog.vue'
import { openAppraiserOrdersDialog } from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/ViewAppraiserOrdersDialog.vue'
import { order } from '@/plugins/order/Order'
import OfficeAssignButton from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/AssignTablePartials/OfficeAssignButton.vue'

export default defineComponent({
    props: {
        appraiser: {
            type: Object as PropType<Appraiser>,
        },
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    components: {
        OfficeAssignButton,
    },
    setup(props) {
        const editAppraiser = () => {
            if (!props.item || !props.item.appraiser) return
            window.open('/appraiser/' + props.item.appraiser.id + '/edit')
        }

        const eligibilityDetails = computed(() => {
            if (!props || !props.item || !props.item.eligible) return ''
            return props.item.eligible.details
        })

        const assignOrder = () => {
            if (!props || !props.item || !props.item.appraiser) return
            selectedAppraiser.value = props.item.appraiser.id
            openAssignDialog({
                appraiser: props.item.appraiser as PanelAppraiser,
                details: eligibilityDetails.value as string,
            })
        }

        return {
            editAppraiser,
            allowSelect,
            assignOrder,
            eligibilityDetails,
            openAppraiserOrdersDialog,
            order,
        }
    },
})
