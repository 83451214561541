import { RichTextEditor } from '@syncfusion/ej2-richtexteditor'
import {
    searchType,
    searchItems as amcSearchItems,
    clientSearchItems,
} from '@/components/OrderTable/Classes/OrderTable'
/* @ts-ignore */
import { open } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderReminders/AddReminderDialog.vue'
import { orderScreenScroll } from '@/AppLayout/order/OrderScreen.vue'
import { order } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { addNote, resetNote, scrollToBottom, newNote } from '@/plugins/Notes'
import Vue from 'vue'
import $axios from '@/plugins/axios'
import { reset, loading } from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/AmcAddNote.vue'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

const addNoteListener = () => {
    if (!order.value) return
    if (!newNote.value.target) return
    loading.value = true
    $axios
        .post('/v1/order/' + order.value.id + '/note', newNote.value)
        .then(({ data }) => {
            showSnackbar('Note Added Successfully!')
            resetNote()
            addNote(data)
            reset()
            loading.value = false
            Vue.nextTick(() => {
                scrollToBottom()
            })
        })
        .catch(() => {
            loading.value = false
        })
}

export default class AscentKeyboardEvents {
    static searching = false
    public static editor: RichTextEditor | undefined = undefined

    static focusSearch = () => {
        document.addEventListener('keydown', AscentKeyboardEvents.searchSlash)
    }

    static removeFocusSearch = () => {
        document.removeEventListener('keydown', AscentKeyboardEvents.searchSlash)
    }

    static searchSlash = (ev: KeyboardEvent) => {
        if (
            ev.code === 'Slash' &&
            !ev.shiftKey &&
            (ev.target as HTMLElement).localName !== 'ubcomment' &&
            (ev.target as HTMLElement).localName !== 'textarea'
        ) {
            ev.preventDefault()
            const searchInput = document.querySelector('input#search-box') as HTMLInputElement
            const searchItems = user.value && user.value.type == 'client' ? clientSearchItems : amcSearchItems
            if (AscentKeyboardEvents.searching || document.activeElement === searchInput) {
                const index = searchItems.indexOf(searchType.value)
                searchType.value =
                    typeof searchItems[index + 1] !== 'undefined' ? searchItems[index + 1] : searchItems[0]
            }
            AscentKeyboardEvents.searching = true

            searchInput?.focus()
            searchInput?.addEventListener('blur', () => (AscentKeyboardEvents.searching = false))
        }
    }

    static editorSlash = (ev: KeyboardEvent) => {
        const dialog = document.querySelector('div.v-dialog--active')
        if (ev.key === 'Enter' && ev.ctrlKey) {
            addNoteListener()
        }
        if (ev.target instanceof HTMLBodyElement || (ev.target as Node).childNodes[0] !== dialog) return
        if (ev.key === 'r' && !ev.metaKey && !ev.ctrlKey && user.value && AscentHelper.isCustomer()) {
            open(undefined)
            AscentKeyboardEvents.removeFocusEditor()
        }
        if (ev.key === 'ArrowUp' && ev.shiftKey) orderScreenScroll('top')
        if (ev.key === 'ArrowDown' && ev.shiftKey) orderScreenScroll('bottom')

        if (ev.code === 'Slash' && !ev.shiftKey) {
            ev.preventDefault()
            dialog?.scrollTo(0, dialog?.scrollHeight)
            AscentKeyboardEvents.editor?.focusIn()
            AscentKeyboardEvents.removeFocusEditor()
        }
    }

    static focusEditor = () => {
        document.addEventListener('keydown', AscentKeyboardEvents.editorSlash)
    }

    static removeFocusEditor = () => {
        document.removeEventListener('keydown', AscentKeyboardEvents.editorSlash)
    }
}
