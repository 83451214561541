import { RouteConfig } from 'vue-router/types'

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/pages/Auth/login.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/pages/Auth/auth.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/auth/callback',
        name: 'Auth Callback',
        component: () => import('@/pages/Auth/callback.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/password/reset',
        name: 'Reset',
        component: () => import('@/pages/Auth/email.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/password/reset/:token',
        name: 'Reset Token',
        component: () => import('@/pages/Auth/reset.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/change-password',
        name: 'Change Password',
        component: () => import('@/pages/Auth/ChangePassword.vue'),
        meta: { requiresAuth: true, showNav: false },
    },
    {
        path: '/401',
        name: 'Error',
        component: () => import('../../AppLayout/helpers/Error401.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/lender/accepted-quote',
        name: 'Quote Accepted',
        component: () => import('@/pages/AMC/Quotes/Partials/AcceptedQuote.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/:token/lender/accepted-quote',
        name: 'Customer Quote Accepted',
        component: () => import('@/pages/AMC/Quotes/Partials/AcceptedQuote.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/lender/response-previously-submitted',
        name: 'Quote Previously Submitted',
        component: () => import('@/pages/AMC/Quotes/Partials/QuotePreviouslySubmitted.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/:token/lender/response-previously-submitted',
        name: 'Customer Quote Previously Submitted',
        component: () => import('@/pages/AMC/Quotes/Partials/QuotePreviouslySubmitted.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/lender/rejected-quote',
        name: 'Quote Rejected',
        component: () => import('@/pages/AMC/Quotes/Partials/RejectQuote.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
    {
        path: '/:token/lender/rejected-quote',
        name: 'Customer Quote Rejected',
        component: () => import('@/pages/AMC/Quotes/Partials/RejectQuote.vue'),
        meta: { requiresAuth: false, showNav: false },
    },
]

export default routes
