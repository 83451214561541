





































import { computed, defineComponent, ref } from '@vue/composition-api'
import axios from 'axios'
import { showError } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: {},
    props: {
        title: {
            type: String,
            default: '',
        },
        showSearchInput: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const searchText = ref('')
        const appraisersByZip = ref([])
        const searched = ref(false)
        const headers = [
            { text: 'ID', value: 'appraiser.id', align: 'center' },
            { text: 'Name', value: 'appraiser.name' },
            { text: 'Email', value: 'appraiser.email' },
            { text: 'Status', value: 'appraiser.status' },
            { text: 'Company', value: 'appraiser.company' },
            { text: 'TAT', value: 'turn_time' },
            { text: 'Open Orders', value: 'open' },
        ]

        const searchZip = (zipCode?: string) => {
            const searchZipCode = zipCode || searchText.value
            if (searchZipCode.length < 5) {
                appraisersByZip.value = []
                searched.value = false
                return
            }
            if (searchZipCode.length > 5) {
                return
            }
            axios
                .get('v1/zipcode-lookup/' + searchZipCode)
                .then((response) => {
                    if (response) {
                        searched.value = true
                        appraisersByZip.value = response.data
                        return
                    }
                })
                .catch(() => {
                    showError('Not a valid Zip Code')
                })
        }

        const clearSearch = () => {
            appraisersByZip.value = []
            searched.value = false
        }
        const searchBackgroundColor = computed(() => (searchText.value !== '' ? 'white' : 'accentWhite'))
        return {
            searchText,
            searchZip,
            searchBackgroundColor,
            headers,
            appraisersByZip,
            searched,
            clearSearch,
        }
    },
    provide: {},
})
