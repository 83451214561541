<template>
    <v-list v-if="getSafe(() => data[data.column.field][0])" style="background-color: transparent" dense>
        <a class="accentBlacklighten2--text">{{ data[data.column.field][0].note | strippedTags }}</a>
    </v-list>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { openPreQuoteNote } from '@/pages/AMC/Quotes/Partials/ViewPreQuoteDialog.vue'

/**
 * @name PreQuoteNotesColumn
 * If notes exist for the quote, shows additional note as a template in column of PreQuote table.
 * @SetupMethod getSafe (fn): checks for null value of data.
 */
export default defineComponent({
    props: {},
    setup() {
        const getSafe = (fn) => {
            try {
                return fn()
            } catch (e) {
                return null
            }
        }

        return { getSafe }
    },
    data() {
        return { openPreQuoteNote }
    },
    filters: {
        /**
         * Removes html tags from notes added
         * @param string current note
         * @returns non-html string
         */
        strippedTags: function (string) {
            return string.replace(/<\/?[^>]+>/gi, ' ')
        },
    },
})
</script>
