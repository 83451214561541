






















































import { computed, defineComponent } from '@vue/composition-api'

import { ValidationObserver } from 'vee-validate'
import AppraiserNotePartial from '@/pages/Appraiser/Profile/AMC/Partials/AppraiserNotePartial.vue'
import { AppraiserNotes } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    setup() {
        const canEdit = computed(() => AscentHelper.canDo('Edit Appraiser'))

        return {
            canEdit,
        }
    },
    components: { ValidationObserver, AppraiserNotePartial },
    data() {
        return {
            types: ['Critical', 'Reference'],
            appraiser_notes: [] as AppraiserNotes[],
            showLog: false,
            showAddNote: false,
            newNote: '',
            type: 'Reference',
            newNoteError: false,
        }
    },
    computed: {
        filteredNotes(): AppraiserNotes[] {
            if (this.showLog) return this.appraiser_notes
            return this.appraiser_notes.filter(
                (elem: AppraiserNotes) => ['Reference', 'Critical'].indexOf(elem.type) > -1,
            )
        },
    },
    mounted() {
        this.getAppraiserNotes()
    },
    methods: {
        getAppraiserNotes() {
            this.$axios.get('/v1/appraiser/' + this.$route.params.id + '/audit').then((response) => {
                this.appraiser_notes = response.data
            })
        },
        saveNote() {
            this.$axios
                .post('/v1/appraiser/' + this.$route.params.id + '/audit', {
                    note: this.newNote,
                    type: this.type,
                })
                .then(() => {
                    this.newNote = ''
                    this.showAddNote = false
                    this.getAppraiserNotes()
                    showSnackbar('Note added successfully!')
                })
        },
    },
})
