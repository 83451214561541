











































import { computed, defineComponent, reactive } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper'
const state = reactive({
    type: '',
    date: 0,
    oppositeDate: 0,
    hour: 12,
    minute: '00',
    time: 'AM',
})
import { user } from '@/plugins/User'

export const openDatePicker = (type: string, date: number, oppositeDate: number) => {
    state.type = type
    state.date = AscentHelper.formatUnix(date, 'y-MM-dd', false)
    state.oppositeDate = AscentHelper.formatUnix(oppositeDate, 'y-MM-dd', false)
}

export default defineComponent({
    name: 'DeadlineDatePicker',
    setup(_, { emit }) {
        const appraiserAfterClient = computed(() => {
            if (state.type === 'Client Deadline') {
                if (!state.oppositeDate) return
                if (new Date(state.date).getDate() === new Date(state.oppositeDate).getDate()) return true
                if (new Date(state.date) <= new Date(state.oppositeDate)) return false
            }
            return true
        })

        const appraiserBeforeClient = computed(() => {
            if (state.type === 'Appraiser Deadline') {
                if (!state.oppositeDate) return
                if (new Date(state.date) == new Date(state.oppositeDate)) return true
                if (new Date(state.date) > new Date(state.oppositeDate)) return false
            }
            return true
        })

        const getLocalTime = () => {
            return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
        }

        const save = () => {
            emit('save', { date: state.date, type: state.type })
        }

        const cancel = () => {
            emit('cancel')
        }

        return {
            save,
            cancel,
            state,
            appraiserAfterClient,
            appraiserBeforeClient,
            user,
            getLocalTime,
        }
    },
})
