






























































































import { defineComponent, ref, watch } from '@vue/composition-api'
import {
    preloadData,
    finalReview,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import AEditor from '@/global/Form/AEditor.vue'

export default defineComponent({
    components: { AEditor },
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const classification = ref('')
        const description = ref('')
        const compliance = ref('')
        const complianceDescription = ref('')
        const zone = ref('')
        const zoneName = ref('')

        watch(props, () => {
            if (!props.editData && !props.cancelEdit) {
                if (compliance.value !== 'Illegal' && compliance.value !== 'Legal Nonconforming')
                    complianceDescription.value = ''

                preloadData.value.zoning.classification = classification.value
                preloadData.value.zoning.description = description.value
                preloadData.value.zoning.compliance = compliance.value
                preloadData.value.zoning.complianceDescription = complianceDescription.value
                preloadData.value.zoneomicData.zone = zone.value
                preloadData.value.zoneomicData.zoneName = zoneName.value
            }
            if (props.editData) {
                classification.value = preloadData.value.zoning.classification
                description.value = preloadData.value.zoning.description
                compliance.value = preloadData.value.zoning.compliance
                complianceDescription.value = preloadData.value.zoning.complianceDescription
                zoneName.value = preloadData.value.zoneomicData.zoneName
                zone.value = preloadData.value.zoneomicData.zone
            }
        })

        return {
            preloadData,
            complianceDescription,
            classification,
            description,
            compliance,
            finalReview,
            zone,
            zoneName,
        }
    },
})
