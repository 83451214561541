



























































import { ValidationObserver } from 'vee-validate'
import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions } from '@/types'
import AEditor from '@/global/Form/AEditor.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { AEditor, ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const optionShow = ref('')

        if (props.option !== undefined) {
            optionShow.value = props.option.option_value as string
        }

        const optionEdit = ref(optionShow.value)
        const ready = ref(false) //todo this is broken

        const saveChanges = () => {
            dialog.value = false
            optionShow.value = optionEdit.value
            showSnackbar('Option saved successfully')
        }

        onMounted(() => (ready.value = true))
        return {
            ready,
            dialog,
            lender,
            optionShow,
            optionEdit,
            saveChanges,
        }
    },
})
