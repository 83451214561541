<template lang="html">
    <v-footer app color="accentBlack" class="white--text px-3 pt-1 pb-0 text-caption">
        <template v-if="!$vuetify.breakpoint.mobile">
            <span class="text-right small pr-2">&copy;{{ new Date().getFullYear() }} |</span>
            <a
                href="https://tryjaro.com/privacy-policy"
                class="white--text text-decoration-none text-caption pr-2"
                target="_blank"
            >
                Privacy Policy
            </a>
            |
            <a
                href="https://tryjaro.com/terms"
                class="white--text text-decoration-none text-caption pl-2"
                target="_blank"
            >
                Terms of Use
            </a>
        </template>
        <v-spacer />
        <span class="small">Powered by</span>
        <img src="https://assets.ascentvms.com/jarodesk-logo.svg" width="100" height="30" />
    </v-footer>
</template>

<script>
import Vue from 'vue'

/**
 * @name 'App Footer'
 * Component description: The Footer for the application.
 */
export default Vue.extend({
    name: 'AppFooter',
    methods: {},
})
</script>

<style scoped></style>
