import { computed, Ref, ref } from '@vue/composition-api'
import { AscentColumnModel } from '@/helpers/AscentDataAdapter'
export const selectedComps = ref(0)
export const selectedCompsAll = ref(undefined as undefined | Comparable)
export const complexity = ref({} as Complexity | Quote)
export const allComparables = ref()
import {
    openInfoDialog,
    openInfo,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/PropertyComplexity.vue'
import $axios from 'axios'
import { order } from '@/plugins/order/Order'
import { currentTab, tabsSelection } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { Quote } from '@/types'
import { Kukun } from '@/types/Kukun'

export const compPosition = ref({ lat: 0, lng: 0 })
export const complexLatLng = ref({})
export const currentProperty = ref()
export const infoCompOptions = {
    pixelOffset: {
        width: 0,
        height: -35,
    },
}

export const kukun: Ref<undefined | Kukun> = ref(undefined)

export const resetProperties = () => {
    selectedComps.value = 0
    selectedCompsAll.value = undefined
    openInfoDialog.value = false
}

export const selectComparable = (comparable: Comparable) => {
    openInfoDialog.value = false
    if (!openInfo.value) {
        resetProperties()
        openInfo.value = true
        return
    }
    selectedComps.value = comparable.id
    selectedCompsAll.value = comparable
    compPosition.value = {
        lat: parseFloat(comparable.Latitude as string),
        lng: parseFloat(comparable.Longitude as string),
    }
    openInfoDialog.value = true
    return
}

export const currentAppraiserLength = ref(0)

export const loadComplexity = async () => {
    if (!order.value) return
    await $axios.get('v1/order/' + order.value.id + '/complexity').then((response) => {
        complexLatLng.value = {
            lat: parseFloat(order.value?.address.latitude as string),
            lng: parseFloat(order.value?.address.longitude as string),
        }
        complexity.value = response.data as Complexity
        if (response.data.appraiser_complexity)
            currentAppraiserLength.value = response.data.appraiser_complexity.appraisers
    })
}

export const loadKukun = async () => {
    if (!order.value) return
    await $axios.get('v1/order/' + order.value.id + '/kukun').then((response) => {
        kukun.value = response.data.details
    })
}

export const currentAppraisers = computed(() => {
    switch (currentTab.value) {
        case 'branch-panel':
            return tabsSelection.value.branch
        case 'lender-panel':
            return tabsSelection.value.lender
        case 'secondary-panel':
            return tabsSelection.value.secondary
        case 'primary-panel':
        default:
            return tabsSelection.value.primary
    }
})

export const appraiserComplexScoreColor = computed(() => {
    if (!currentAppraiserLength.value) return 'accentBlacklighten4'
    if (currentAppraiserLength.value >= 70) return 'success'
    if (currentAppraiserLength.value >= 30) return 'yellowLighten '
    return `error`
})

export const appraiserComplexScoreText = computed(() => {
    if (!currentAppraiserLength.value) return 'Unknown'
    if (currentAppraiserLength.value >= 70) return 'Low'
    if (currentAppraiserLength.value >= 30) return 'Medium'
    return 'High'
})

export const complexScoreColor = computed(() => {
    if (!complexity.value || !(complexity.value as Complexity).property) return 'error'
    if ((complexity.value as Complexity).property.ComplexityScore <= 50) return 'success'
    if ((complexity.value as Complexity).property.ComplexityScore <= 80) return 'yellowLighten'
    return 'error'
})

export const complexScoreLabel = computed(() => {
    if (!complexity.value || !(complexity.value as Complexity).property) return 'Unavailable'
    if ((complexity.value as Complexity).property.ComplexityScore <= 60) return 'Low'
    if ((complexity.value as Complexity).property.ComplexityScore <= 75) return 'Medium'
    if ((complexity.value as Complexity).property.ComplexityScore <= 85) return 'High'
    return 'Very High'
})

export const complexScoreValue = computed(() => {
    if (!complexity.value || !(complexity.value as Complexity).property) return '#3B35B1'
    if ((complexity.value as Complexity).property.ComplexityScore <= 60) return '#037c00'
    if ((complexity.value as Complexity).property.ComplexityScore <= 75) return '#FFD600'
    if ((complexity.value as Complexity).property.ComplexityScore <= 85) return '#FA5C28'
    return '#E50000'
})

export interface Property {
    ComplexityScore: number
}

export interface Complexity {
    property: Property
}

interface Images {
    [key: string]: string | undefined
}

export interface Comparable {
    Bathrooms: number
    Bedrooms: number
    CloseDate: string
    ClosePrice: string
    Fireplace: string
    FullBath: number
    HalfBath: number
    Images?: Images[]
    Latitude: string
    ListPrice: string
    ListingContractDate: string
    ListingId: string
    LivingArea: number
    Longitude: string
    LotSize: number
    PricePerSquareFeet: number
    Status: string
    YearBuilt: number
    column: AscentColumnModel
    created_at: string
    foreignKeyData: undefined
    id: number
    index: string
    property_id: number
    similarity_score: number
    updated_at: string
}
