









import { defineComponent, ref } from '@vue/composition-api'
import { headers } from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignTable.vue'

export const sendQuote = ref(true)
export const search = ref('')

export default defineComponent({
    setup() {
        return {
            search,
            sendQuote,
            headers,
        }
    },
})
