































import { defineComponent } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import $axios from '../../../../../plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserTagColors } from '@/pages/Appraiser/Profile/SharedProfile/AppraiserAmcSettingsPage.vue'
import {
    appraiserSettingsId,
    appraiserOptions,
    appraiserTagsSettings,
} from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    components: {},
    props: {
        option: {
            type: Object,
        },
    },

    setup(props) {
        const appraiserTags = [
            'Flagstar',
            'Jumbo',
            'New Construction',
            'Probation',
            'Service Issues',
            'Important Notes',
            'S1L +$100',
            'Staff',
            'Golden',
        ]

        if (props.option && props.option.tags) {
            appraiserOptions.value.tags = props.option.tags
        }

        const saveChanges = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'tags',
                    option_value: appraiserOptions.value.tags,
                })
                .then(() => {
                    showSnackbar('Saved successfully')
                })
        }

        return {
            saveChanges,
            lender,
            appraiserOptions,
            appraiserTagColors,
            appraiserTags,
            appraiserTagsSettings,
        }
    },
})
