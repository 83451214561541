





































































import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import {
    preloadData,
    step,
    preloadSteps,
    getPurchaseContractUrl,
    closePreload,
    loadReview,
    initialSteps,
    reviewStep,
    preloadReviewSteps,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import PreloadOrderDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadOrderDetails/PreloadOrderDetails.vue'
import PreloadSubjectInfo from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSubjectInfo/PreloadSubjectInfo.vue'
import PreloadContract from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadContract/PreloadContract.vue'
import PreloadSaleHistory from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSaleHistory/PreloadSaleHistory.vue'
import PreloadZoning from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadZoning/PreloadZoning.vue'
import PreloadReview from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadReview/PreloadReview.vue'
import PreloadFinalScreen from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadFinalScreen.vue'

/**
 * @name 'PreloadReviewDialog'
 */

export const dialog = ref(false)
export const openDialog = async (reviewSteps: boolean) => {
    if (!order.value) return
    await $axios.get('v1/order/' + order.value.id + '/preload-data').then((response) => {
        if (!order.value) return
        if (reviewSteps) {
            preloadReviewSteps.value = true
            loadReview.value = true
            reviewStep.value = true
            initialSteps.value = initialSteps.value.filter((elem: { name: string }) => {
                if (elem.name === 'Final') return elem
                if (elem.name === 'Review and Submit') return elem
            })
        }
        preloadData.value = Object.assign(preloadData.value, response.data)
    })
    getPurchaseContractUrl()
    dialog.value = true
}
export default defineComponent({
    name: 'PreloadReviewDialog',
    components: {
        PreloadOrderDetails,
        PreloadSubjectInfo,
        PreloadContract,
        PreloadSaleHistory,
        PreloadZoning,
        PreloadReview,
        PreloadFinalScreen,
    },
    setup() {
        return {
            dialog,
            closePreload,
            preloadData,
            order,
            step,
            preloadSteps,
        }
    },
})
