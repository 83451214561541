






























































































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import CancelOrderDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/CancelOrderDialog.vue'
import EditOrderDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/EditOrderDialog.vue'
import axios from 'axios'
import EscalateOrderDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/EscalateOrderDialog.vue'
import UploadNewReportDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/UploadNewReportDialog.vue'
import VerosSubmissionDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/VerosSubmissionDialog.vue'
import EditOrderInstructionsDialog, {
    openEditInstructions,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/EditOrderInstructionsDialog.vue'
import OrderRemoveHoldDialog, {
    removeHoldDialog,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/OrderRemoveHoldDialog.vue'
import OrderHoldDialog, {
    orderHoldDialog,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/OrderHoldDialog.vue'
import {
    lenderCanChangeOrderStatus,
    lenderHasUCDP,
    lenderHasUcdpHold,
    lenderHasBorrowerDelivery,
    lenderCanManuallySendBorrowerDelivery,
    lenderCanResendBorrowerDelivery,
} from '@/pages/AMC/Lenders/Lender'
import { user, role } from '@/plugins/User'
import { Tag } from '@/types/tags.ts'
import { Approvals } from '@/types/approvals'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, isCompleted, directOrder, updateOrder, refreshOrder } from '@/plugins/order/Order'
import OrderAdditionalProduct from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/OrderAdditionalProduct.vue'
import RevertCancelledOrderDialog, {
    openRevertDialog,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/RevertCancelledOrderDialog.vue'
import EscalateOrdersDialog, {
    openOrderEscalation,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderEscalations/EscalateOrderDialog.vue'
import CancelEscalationDialog, {
    openCancelEscalation,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderEscalations/CancelEscalationDialog.vue'

interface Dialog {
    open: () => void
}

/**
 * @name OrderActions
 * Section in order toolbar that gives different actions that amc user can take on the order
 *
 * @SetupComputed hasPaymentHoldTag - checks tag. If payment is on hold, returns true.
 * @SetupMethod PlaceOrderOnHold - Confirm dialog to place order on hold if order is not already on hold.
 * @SetupMethod ReleaseDraftReport - sends report to client
 * @SetupMethod ResendBorrowerDelivery - sends the borrower delivery if enabled by lender options
 * @SetupMethod ResendClientFiles - sends all files to Client
 */

export default defineComponent({
    name: 'OrderActions',
    components: {
        OrderAdditionalProduct,
        OrderHoldDialog,
        OrderRemoveHoldDialog,
        VerosSubmissionDialog,
        UploadNewReportDialog,
        EscalateOrderDialog,
        EditOrderDialog,
        CancelOrderDialog,
        EditOrderInstructionsDialog,
        EscalateOrdersDialog,
        CancelEscalationDialog,
        RevertCancelledOrderDialog,
    },
    setup() {
        const CancelOrderDialog = ref({} as Dialog)
        const EditOrderDialog = ref({} as Dialog)
        const EscalateOrderDialog = ref({} as Dialog)
        const UploadNewReportDialog = ref({} as Dialog)
        const VerosSubmissionDialog = ref({} as Dialog)
        const orderPayment = ref(false)

        const hasPaymentHoldTag = computed(() => {
            if (order.value?.tags == null) return false
            const tagsFromStore = order.value.tags
            let setTag = false
            tagsFromStore.filter((el: Tag) => {
                if (el.tag === 'Payment Hold') {
                    setTag = true
                }
            })
            return setTag
        })
        const PlaceOrderOnHold = (payment: boolean | object) => {
            if (payment) {
                confirm('Are you sure?', 'Are you sure you want to place this order on hold?').then((confirm) => {
                    if (!order.value) return
                    if (confirm) {
                        axios
                            .post('/v1/order/' + order.value.id + '/workflow/hold', {
                                payment: payment,
                            })
                            .then(() => {
                                updateOrder()
                                showSnackbar('Order placed on hold successfully')
                            })
                    }
                })
            } else {
                orderHoldDialog.value = true
            }
        }

        const ReleaseDraftReport = () => {
            confirm(
                'Send Draft PDF report?',
                'Are you sure you want to send the PDF to the client? The report will still need to be completed',
            ).then((confirm) => {
                if (!order.value) return
                if (confirm) {
                    axios
                        .post('/v1/order/' + order.value.id + '/invoice/send', {
                            type: 'draft',
                        })
                        .then(() => {
                            showSnackbar('Draft Report Sent Successfully')
                        })
                }
            })
        }

        const ResendBorrowerDelivery = () => {
            confirm(
                'Resend Borrower Delivery?',
                'Are you sure you want to resend this to the borrower? This method will override any waiting periods in the lender profile and send immediately.',
            ).then((confirm) => {
                if (!order.value) return
                if (confirm) {
                    axios
                        .post('/v1/order/' + order.value.id + '/action/borrower-delivery')
                        .then((response) => {
                            if (response.data && response.data.data && response.data.data.message) {
                                showSnackbar(response.data.data.message)
                            }
                        })
                        .catch((error) => {
                            const message = error.response?.data?.message ?? error
                            showError(message)
                        })
                }
            })
        }

        const SendBorrowerDelivery = () => {
            confirm(
                'Send Borrower Delivery?',
                'Are you sure you want to send this to the borrower? This method will override any waiting periods in the lender profile and send immediately.',
            ).then((confirm) => {
                if (!order.value) return
                if (confirm) {
                    axios
                        .post('/v1/order/' + order.value.id + '/action/send-borrower-delivery')
                        .then((response) => {
                            if (response.data && response.data.data && response.data.data.message) {
                                showSnackbar(response.data.data.message)
                            }
                        })
                        .catch((error) => {
                            const message = error.response?.data?.message ?? error
                            showError(message)
                        })
                }
            })
        }

        const ResendClientFiles = () => {
            confirm(
                'Resend Client Files?',
                'Are you sure you want to resend this to the client? This will immediately send all delivery files to the client through their integration or provide another email link.',
            ).then((confirm) => {
                if (!order.value) return
                if (confirm) {
                    axios
                        .post('/v1/order/' + order.value.id + '/workflow/send')
                        .then((response) => {
                            if (response.data.message == 'Order Sent Successfully') showSnackbar('Sent Successfully')
                        })
                        .catch(({ response }) => {
                            const errors = response.data.errors
                            showError(errors[0][0])
                        })
                        .finally(() => {
                            refreshOrder()
                        })
                }
            })
        }
        const isUserRep = computed(() => {
            if (!order.value || !user.value || !order.value.incremental) return false
            return (
                (order.value.incremental.assigner && order.value.incremental.assigner.id === user.value.id) ||
                (order.value.incremental.user && order.value.incremental.user.id === user.value.id)
            )
        })

        const canEscalate = computed(() => {
            if (!order.value || !user.value) return false
            if (order.value.tags == null) return true
            return order.value.tags.find((elem) => elem.extra && elem.extra.escalated_by) === undefined
        })

        const canCancelEscalation = computed(() => {
            if (!order.value) return false
            if (order.value.tags == null) return true
            return !!order.value.tags.find(
                (elem) => elem.extra && elem.extra.approval && (elem.extra.approval as Approvals).status === 'Pending',
            )
        })

        return {
            user,
            order,
            isCompleted,
            refreshOrder,
            hasPaymentHoldTag,
            CancelOrderDialog,
            EditOrderDialog,
            EscalateOrderDialog,
            UploadNewReportDialog,
            VerosSubmissionDialog,
            orderPayment,
            removeHoldDialog,
            PlaceOrderOnHold,
            ReleaseDraftReport,
            ResendBorrowerDelivery,
            ResendClientFiles,
            SendBorrowerDelivery,
            openEditInstructions,
            openOrderEscalation,
            lenderHasUCDP,
            lenderHasUcdpHold,
            lenderHasBorrowerDelivery,
            lenderCanManuallySendBorrowerDelivery,
            lenderCanResendBorrowerDelivery,
            canEscalate,
            openCancelEscalation,
            canCancelEscalation,
            openRevertDialog,
            role,
            isUserRep,
            directOrder,
            lenderCanChangeOrderStatus,
        }
    },
})
