




























































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import { BillingNoteOption } from '@/types'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },

    setup(props) {
        const dialog = ref<boolean>(false)
        const billingNotes = ref('')
        if (props.option !== undefined && props.option?.option_value !== undefined) {
            const notes = (props as BillingNoteOption).option.option_value[0] as string
            if (notes) billingNotes.value = notes as string
        }

        const billingLocal = ref(JSON.parse(JSON.stringify(billingNotes.value)))

        const saveChanges = () => {
            $axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'billing-notes',
                    option_value: [billingNotes.value],
                })
                .then(() => {
                    dialog.value = false
                    billingLocal.value = billingNotes.value as string
                    showSnackbar('Note saved successfully')
                })
        }

        return {
            billingLocal,
            saveChanges,
            dialog,
            lender,
            billingNotes,
        }
    },
})
