





















































































































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { contactTypes, phoneTypes, masks } from '@/plugins/Data'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { refreshOrder } from '@/plugins/order/Order'
import ACardAction from '@/global/ACardAction.vue'

const state = reactive({
    index: 0,
    contact: {
        name: null,
        email: null,
        phones: [{ type: null, phone: null }],
        contact_type: null,
        entry: false,
        id: null,
    },
})

const dialog = ref(false)

export const editContact = (contact: object, index: number) => {
    state.index = index
    state.contact = JSON.parse(JSON.stringify(contact))
    if (state.contact.phones.length == 0) {
        state.contact.phones.push({
            type: null,
            phone: null,
        })
    }
    dialog.value = true
}

/**
 * @name ClientEditContactDialog
 * Dialog on Client Order Screen that allows client to edit a specific contact on the order
 *
 * @SetupMethod editContact - exports and allows user to edit a specific contact and opens the dialog
 * @SetupMethod updateContact - called after client is saved to /v1/contact/ + state.contact.id. Refreshes order after saved.
 */

export default defineComponent({
    directives: { mask },
    components: {
        ACardAction,
        ValidationObserver,
    },
    setup() {
        const updateContact = () => {
            refreshOrder()
            showSnackbar('Contact Updated Successfully')
            dialog.value = false
        }

        return {
            state,
            updateContact,
            contactTypes,
            phoneTypes,
            dialog,
            masks,
        }
    },
})
