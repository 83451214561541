









































import { defineComponent } from '@vue/composition-api'
import Nexio from '@/components/General/CreditCard/Nexio.vue'
import AuthorizeNet from '@/components/General/CreditCard/AuthorizeNet.vue'
import TokenEx from '@/components/General/CreditCard/TokenEx.vue'
import Ironwood from '@/components/General/CreditCard/Ironwood.vue'

export default defineComponent({
    components: { AuthorizeNet, Nexio, TokenEx, Ironwood },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        help: {
            type: Boolean,
            default: true,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        clearFields: {
            type: Boolean,
            default: true,
        },
        gateway: {
            type: String,
            default: 'nexio',
        },
        customerId: {
            type: Number,
            default: null,
        },
        chargeAmount: {
            type: Number,
            default: null,
        },
        usesSurcharge: {
            type: Boolean,
            default: false,
        },
        disallow: {
            type: Array,
            default: () => [],
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        addCard() {
            if (this.gateway === 'nexio') {
                const cards = this.$refs.nexio as Vue & { addCard: () => void }
                cards.addCard()
                return
            }
            if (this.gateway === 'authorize') {
                const cards = this.$refs.authorize as Vue & { addCard: () => void }
                cards.addCard()
                return
            }
            if (this.gateway === 'tokenex') {
                const cards = this.$refs.tokenex as Vue & { addCard: () => void }
                cards.addCard()
                return
            }
            if (this.gateway === 'ironwood') {
                const cards = this.$refs.ironwood as Vue & { addCard: () => void }
                cards.addCard()
                return
            }
        },
        cancel() {
            if (this.gateway === 'nexio') {
                const cards = this.$refs.nexio as Vue & { cancel: () => void }
                cards.cancel()
                return
            }
            if (this.gateway === 'authorize') {
                const cards = this.$refs.authorize as Vue & { cancel: () => void }
                cards.cancel()
                return
            }
            if (this.gateway === 'tokenex') {
                const cards = this.$refs.tokenex as Vue & { cancel: () => void }
                cards.cancel()
                return
            }
            if (this.gateway === 'ironwood') {
                const cards = this.$refs.ironwood as Vue & { cancel: () => void }
                cards.cancel()
                return
            }
        },
        handleSurcharge(surcharge: { enabled: boolean; amount: number }) {
            this.$emit('handle-surcharge', surcharge)
        },
        cardAdded(details: {
            card: string
            token: string
            name: string
            type: string
            expYear: string
            expMonth: string
            address?: object
        }) {
            this.$emit('card-added', details)
        },
        caughtError(details?: { error?: boolean; result?: boolean; message?: string }) {
            this.$emit('error', details)
        },
    },
})
