










import { defineComponent } from '@vue/composition-api'
import { AppraiserPanel } from '@/types'

export default defineComponent({
    props: {
        item: {
            type: Object as () => AppraiserPanel,
            required: true,
        },
    },
    setup() {
        const getLicenseTagColor = (type: string) => {
            switch (type) {
                case 'Trainee':
                    return 'warning'
                default:
                    return 'primary'
            }
        }
        return {
            getLicenseTagColor,
        }
    },
})
