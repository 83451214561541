



























































































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions, QualityAndDeliveryNotesOption } from '@/types'
import AEditor from '@/global/Form/AEditor.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { AEditor, ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const optionShow = ref({
            key_directives: '',
            client_notes: '',
        })

        if (props.option !== undefined) {
            optionShow.value = props.option.option_value as QualityAndDeliveryNotesOption
        }

        const optionEdit = ref(JSON.parse(JSON.stringify(optionShow.value)))

        const saveChanges = () => {
            dialog.value = false
            optionShow.value = JSON.parse(JSON.stringify(optionEdit.value))

            showSnackbar('Option saved successfully')
        }

        return {
            dialog,
            lender,
            optionShow,
            optionEdit,
            saveChanges,
        }
    },
})
