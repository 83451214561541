































import { defineComponent, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { revisions } from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchAssignmentPanelDirectives/BranchAssignmentPanelDirectives.vue'
import { branch } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
export default defineComponent({
    name: 'BranchAssignmentEdit',
    components: { ValidationObserver },
    setup(props, { emit }) {
        const dialog = ref(false)
        const value = ref('')
        const show = ref(false)

        const save = () => {
            if (!branch.value) return
            $axios
                .post('/v1/branch/' + branch.value.id + '/option', {
                    option_key: 'assign-directive',
                    option_value: value.value,
                })
                .then((res) => {
                    showSnackbar('Directive saved successfully')
                    dialog.value = false
                    emit('directive-updated', res.data)
                })
        }

        watch(revisions, () => {
            if (revisions.value.length > 0) {
                value.value = revisions.value[revisions.value.length - 1].option_value
            }
        })

        return { dialog, value, show, revisions, save }
    },
})
