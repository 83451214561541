var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order && _vm.order.incremental)?_c('ACard',{attrs:{"tile":"","color":"transparent","elevation":0},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0"},[_vm._v("Order Fees")]),_c('v-spacer'),_c('a-btn-menu',{attrs:{"items":_vm.actions}},[(_vm.$ascent.featureEnabled('orderScreen.office.paymentLinks'))?_c('PaymentLinkAction'):_vm._e(),(
                        _vm.$ascent.featureEnabled('orderScreen.invoice.lenderInvoiceCode') &&
                        (_vm.user.type === 'amc' || _vm.user.ascent === true)
                    )?_c('v-list',[_c('v-list-item',{on:{"click":_vm.getLenderCode}},[_c('v-list-item-title',[_vm._v("Invoice Code")])],1)],1):_vm._e(),(
                        _vm.$ascent.featureEnabled('orderScreen.invoice.sendBorrowerPayment') &&
                        _vm.$orderHelper.hasBorrowerPayment
                    )?_c('v-list-item',{on:{"click":function($event){return _vm.openDialog('payment-link')}}},[_c('v-list-item-title',[_vm._v("Send Pay Link")])],1):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.invoice.allowCreditCard'))?_c('CreditCardActions'):_vm._e()],1)],1)]},proxy:true}],null,false,1311681569)},[_c('v-card-text',[(_vm.$ascent.featureEnabled('orderScreen.invoice.disclosedFee'))?_c('OrderDisclosedFee'):_vm._e(),(_vm.$ascent.canDo('Work'))?_c('OrderBillingMethod'):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.invoice.lenderInvoiceCode'))?_c('LenderInvoiceCode'):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.invoice.allowCreditCard'))?_c('OrderCreditCards'):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.invoice.feeSummaryType') === 'split_fees')?_c('SplitInvoiceSummary'):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.invoice.feeSummaryType') === 'total_fees')?_c('TotalInvoiceSummary'):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.invoice.feeSummaryType') === 'client_fees')?_c('ClientFeesSummary'):_vm._e(),_c('OrderPaymentAlert'),_c('v-row',[_c('v-col',[_c('AuditNotes',{attrs:{"item":"Payment"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ClientOrderPayments',{on:{"open-client-payment":function($event){return _vm.openDialog('client-payment')}}}),(
                        _vm.order.clientCompleted &&
                        _vm.$ascent.canDo('Work') &&
                        (['Accounting', 'Admin'].indexOf(_vm.role) > -1 || _vm.user.ascent)
                    )?_c('AppraiserPayments'):_vm._e()],1)],1)],1),(_vm.$ascent.featureEnabled('orderScreen.invoice.allowManualPayment', true))?_c('AddClientPaymentDialog',{ref:"AddClientPaymentDialog"}):_vm._e(),(_vm.$orderHelper.hasBorrowerPayment)?_c('SendBorrowerPayLinkDialog',{ref:"SendBorrowerPayLinkDialog"}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }