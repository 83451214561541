var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","scrollable":"","retain-focus":false},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[(!_vm.requiresApproval)?_c('a-card',{attrs:{"title":"Fee Modification Request History"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableItems,"hide-default-footer":true,"show-expand":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ascent.formatIsoDate(item.created_at, 'MM/dd/y t'))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ascent.currency(item.extra.total, '$0.00'))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pt-3",attrs:{"colspan":headers.length}},[_c('v-data-table-header',{attrs:{"headers":_vm.expandHeaders}}),_vm._l((item.extra.products),function(product){return _c('v-row',{key:product.id,staticClass:"my-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(product.product_name)+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$ascent.currency(product.amc_value, '$0.00'))+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$ascent.currency(product.appraiser_value, '$0.00'))+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$ascent.currency(product.line_total, '$0.00'))+" ")])],1)})],2)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('v-btn',{staticClass:"justify-center",on:{"click":function($event){_vm.openDialog = false}}},[_vm._v("Close")])],1)],1)],1):_vm._e(),(_vm.requiresApproval)?_c('a-card',{attrs:{"title":"Fee Modification Approval"}},[_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.currentTag.extra.reason)}}),_c('v-alert',{attrs:{"type":"info"}},[_vm._v("New Total: "+_vm._s(_vm.currentTag.extra.total))])],1),_c('a-card-action',{attrs:{"btnText":"Approve","showCancel":false,"method":"POST","url":'/v1/task/' + _vm.currentTag.id + '/approve-modification'},on:{"success":function () {
                    _vm.openDialog = false
                    _vm.refreshOrder()
                }},scopedSlots:_vm._u([{key:"secondaryAction",fn:function(){return [_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.declineMod}},[_vm._v("Decline")])]},proxy:true}],null,false,2120756039)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }