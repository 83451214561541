



































import { computed, defineComponent } from '@vue/composition-api'
import { order, updateOrder } from '@/plugins/order/Order'
import { Milestone } from '@/types'
import { DateTime } from 'luxon'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'
import { getNotes } from '@/plugins/Notes'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { openEditMilestone } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/EditMilestoneDialog.vue'

/**
 * @name InspectionDates
 * Inspection dates in the OrderDetails in the Amc Order Screen
 *
 * @SetupComputed inspectionDate - looks through order milestones and returns a formatted date if inspection date is in there.
 * @SetupComputed secondInspectionDate - looks through order milestones and returns a formatted date if second inspection date is in there.
 * @SetupMethod cancelInspection - cancels the inspection, refreshes order after
 */

export default defineComponent({
    setup() {
        const inspectionDate = computed(() => {
            if (!order.value || !order.value.incremental) return
            const date = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Inspection Date'
            })
            if (!date) return
            const property = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone(order.value.timezone)
            const local = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone('local')
            return property.toFormat('EEE, MMM d, t (ZZZZ)') + ' / ' + local.toFormat('t (ZZZZ)')
        })

        const secondInspectionDate = computed(() => {
            if (!order.value) return
            if (!order.value.incremental) return
            const date = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Second Inspection Date'
            })

            if (date) {
                const otherDate = new Date(date.milestone_value)
                return otherDate.getTime()
            }

            return 0
        })

        const cancelInspection = () => {
            confirm('Cancel Inspection?', 'Are you sure you want to cancel this inspection?').then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    $axios
                        .post('/v1/order/' + order.value.id + '/workflow/schedule-cancel')
                        .then(() => {
                            if (!order.value) return
                            if (!order.value.incremental) return
                            updateOrder()
                            order.value.inspection = null
                            order.value.incremental.inspection = null
                            getNotes(order.value.id)
                            showSnackbar('Inspection Cancelled')
                        })
                        .catch(() => {
                            showError('Oops, something went wrong!')
                        })
                }
            })
        }

        return {
            inspectionDate,
            cancelInspection,
            secondInspectionDate,
            order,
            openEditMilestone,
        }
    },
})
