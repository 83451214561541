<template>
    <v-dialog v-model="dialog" width="600">
        <ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
            <ACard title="Submit to UCDP/EAD">
                <v-card-text>
                    <v-container grid-list-lg class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider name="Receiver" rules="required">
                                    <v-radio-group v-model="receiver" name="receiver" hide-details="true" row>
                                        <v-radio value="UCDP" label="UCDP" />
                                        <v-radio value="FHA" label="FHA" />
                                    </v-radio-group>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row v-if="error != null">
                            <v-col>
                                <v-alert
                                    color="error"
                                    icon="$vuetify.icons.exclamationCircle"
                                    title="Error"
                                    :text="error"
                                    variant="tonal"
                                >
                                    {{ error }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <a-btn-cancel @click="dialog = false" />
                    <a-btn-confirm :loading="loading" :disabled="invalid" @click="handleSubmit(submitOrder)">
                        Submit Order
                    </a-btn-confirm>
                </v-card-actions>
            </ACard>
        </ValidationObserver>
    </v-dialog>
</template>

<script lang="js">
import {defineComponent} from "@vue/composition-api"
import {ValidationObserver} from 'vee-validate'
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm"
import {showSnackbar} from '@/AppLayout/helpers/snackbar.vue'
import {order, getVeros, refreshOrder} from '@/plugins/order/Order'

/**
 * @name VerosSubmissionDialog
 * Submits UCDP/EAD
 */

export default defineComponent({
    setup() {
        return {
            order
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            /**
             * Either UCDP or FHA
             */
            receiver: null,
            dialog: false,
            loading: false,
            error: null
        }
    },
    computed: {},
    methods: {
        open() {
            this.dialog = true
        },
        /**
         * Submits the order with the receiver. Refreshes Veros after submission
         */
        submitOrder() {
            if (!order.value) return
            this.loading = true
            this.error = null
            this.$axios.post('/v1/order/' + order.value.id + '/veros', {
                receiver: this.receiver,
                auto: false,
                manualSubmission: true,
            }).then((response) => {
                if (response.data.result === 'error') {
                    this.error = response.data.message
                    this.loading = false
                    return
                }

                this.receiver = null
                const message = response.data.message ?? 'Order Submitted Successfully'
                setTimeout(() => {
                    showSnackbar(message)
                    this.loading = false
                    this.dialog = false
                    getVeros()
                    refreshOrder()
                }, 600)
            }).catch((error) => {
                this.loading = false
                this.error = error.response?.data?.message ?? 'An error occurred while submitting the order.'
            })
        },
    }
})
</script>
