




































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'

export const openRevertDialog = ref(false)
const revertReason = ref('')

/**
 * @name RevertCancelledOrderDialog
 * Component Description: This is a dialog that will allow a cancelled order to be reverted for Form, Mercury, Guild, or Encompass orders
 * @SetupMethod handleCancel - will set the reason back to blank and close the dialog
 * @SetupMethod handleSubmit - confirms the request, posts the reason to the back-end for processing, refreshes order, closes dialog.
 */
export default defineComponent({
    name: 'RevertCancelledOrderDialog',
    components: { ValidationObserver },
    setup() {
        const handleCancel = () => {
            revertReason.value = ''
            openRevertDialog.value = false
        }
        const handleSubmit = (revertReason: string) => {
            confirm('Re-open Order', 'Are you sure you want to re-open this order?').then((confirm) => {
                if (confirm && order.value) {
                    $axios
                        .post('/v1/order/' + order.value.id + '/action/revert', { reason: revertReason })
                        .then((response) => {
                            if (response.data === 'noCurrentCancel') {
                                showError('The order is not in a cancelled status.')
                            } else {
                                showSnackbar('Order has been re-opened')
                                refreshOrder()
                            }
                        })
                        .catch((error) => {
                            showError(error)
                        })
                }
                openRevertDialog.value = false
            })
        }
        return {
            openRevertDialog,
            revertReason,
            handleSubmit,
            handleCancel,
        }
    },
})
