











































import { defineComponent, PropType } from '@vue/composition-api'
import { Appraiser, AppraiserPanel } from '@/types'

/**
 * @name ExpandedAppraiserStatusDetails
 * Partial in expanded AssignTable to give details of status of appraiser and tags on the appraiser
 *
 * @SetupMethod - takes in tag name and returns the color of the tag.
 */

export default defineComponent({
    props: {
        /**
         * Appraiser details of appraiser in AssignTable
         */
        appraiser: {
            type: Object as PropType<Appraiser>,
        },
        /**
         * Appraisal Panel information for appraiser
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup() {
        const getColor = (tag: string) => {
            switch (tag) {
                case 'Probation':
                    return 'warning'
                case 'Service Issues':
                    return 'error'
                default:
                    return 'primary'
            }
        }

        return {
            getColor,
        }
    },
})
