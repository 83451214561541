var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ejs-dialog',{ref:"syncDialog",staticClass:"e-fixed",attrs:{"id":"dialog","header":"Add Contact","width":600,"allowDragging":true,"visible":false,"position":{ X: 'right', Y: 'top' },"target":"#clientOrderScreen"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"Name","name":"contact name","outlined":""},model:{value:(_vm.state.contact.name),callback:function ($$v) {_vm.$set(_vm.state.contact, "name", $$v)},expression:"state.contact.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('a-text-field',{attrs:{"rules":"email","label":"Email","name":"contact email","outlined":""},model:{value:(_vm.state.contact.email),callback:function ($$v) {_vm.$set(_vm.state.contact, "email", $$v)},expression:"state.contact.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('a-select-field',{attrs:{"rules":"required","items":_vm.contactTypes,"name":"contact type","attach":"","label":"Type"},model:{value:(_vm.state.contact.type),callback:function ($$v) {_vm.$set(_vm.state.contact, "type", $$v)},expression:"state.contact.type"}})],1),(_vm.state.contact.phones.length === 0)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.state.contact.phones.push({
                            phone: null,
                            type: null,
                        })}}},[_c('v-icon',[_vm._v("$vuetify.icons.phonePlus")])],1)],1):_vm._e(),_vm._l((_vm.state.contact.phones),function(phone,index){return _c('v-col',{key:index,staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('a-select-field',{attrs:{"rules":"required","items":_vm.phoneTypes,"label":"Phone Type","name":'contact phone type' + index,"data-vv-as":"Phone Type"},model:{value:(phone.type),callback:function ($$v) {_vm.$set(phone, "type", $$v)},expression:"phone.type"}})],1),_c('v-col',[_c('a-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.phone),expression:"masks.phone"}],attrs:{"label":"Phone","name":'contact phone' + index,"rules":"required","outlined":""},model:{value:(phone.phone),callback:function ($$v) {_vm.$set(phone, "phone", $$v)},expression:"phone.phone"}})],1),_c('v-col',{attrs:{"cols":"1","align-self":"start"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.state.contact.phones.splice(index, 1)}}},[_c('v-icon',[_vm._v("$vuetify.icons.minus")])],1)],1),_c('v-col',{attrs:{"cols":"1","align-self":"start"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(index + 1 === _vm.state.contact.phones.length),expression:"index + 1 === state.contact.phones.length"}],attrs:{"icon":""},on:{"click":function($event){return _vm.state.contact.phones.push({
                                    type: null,
                                    phone: null,
                                })}}},[_c('v-icon',[_vm._v("$vuetify.icons.plus")])],1)],1)],1)],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"name":"entry","color":"success","label":"Has property access?"},model:{value:(_vm.state.contact.entry),callback:function ($$v) {_vm.$set(_vm.state.contact, "entry", $$v)},expression:"state.contact.entry"}})],1)],2),_c('v-row',[_c('v-spacer'),_c('a-btn-cancel',{on:{"click":_vm.closeDialog}}),_c('a-btn-confirm',{attrs:{"color":"primary","loading":_vm.state.adding,"disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.addContact)}}},[_vm._v(" Add Contact ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }