




























import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'

const dialog = ref(false)
const clientFileNumber = ref('')

const setClientFileNumber = () => {
    if (!order.value) return
    if (!order.value.incremental) return
    clientFileNumber.value = order.value.incremental.integration.integration_id
}
export const openEditClientFileNumber = () => {
    setClientFileNumber()
    dialog.value = true
}

export default defineComponent({
    name: 'EditClientFileNumberDialog',
    components: { ValidationObserver },
    setup() {
        interface ClientFileNumberResponse {
            success?: string
            error?: string
        }
        const handleResponse = (response: ClientFileNumberResponse) => {
            if (!clientFileNumber.value) return
            if (!order.value || !order.value?.incremental) return
            if (response.success) {
                showSnackbar('The Client File Number has been successfully updated.')
                order.value.incremental.integration.integration_id = clientFileNumber.value
                dialog.value = false
                return
            }
            if (response.error) {
                showError(response.error)
                return
            }
        }

        return {
            dialog,
            clientFileNumber,
            order,
            handleResponse,
        }
    },
})
