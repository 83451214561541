










































































import { defineComponent, PropType } from '@vue/composition-api'
import { ExtraNoteField } from '@/types'

export default defineComponent({
    name: 'AuditNoteComponent',
    props: {
        details: {
            type: Object as PropType<ExtraNoteField>,
            default: null,
        },
    },
    setup() {
        const getPhoneIcon = (type: string) => {
            switch (type) {
                case 'Office':
                    return '$vuetify.icons.phoneOffice'
                case 'Mobile':
                    return '$vuetify.icons.mobile'
            }
            return '$vuetify.icons.phone'
        }

        return {
            getPhoneIcon,
        }
    },
})
