
















import { defineComponent, PropType } from '@vue/composition-api'
import { Appraiser } from '@/types'

/**
 * @name ExpandedAssignNotes
 * Expansion in the Assign Table that shows the assign notes that is associated with a specific appraiser passed through the props
 */

export default defineComponent({
    props: {
        /**
         * Appraiser in the Appraiser Panel
         */
        appraiser: {
            type: Object as PropType<Appraiser>,
        },
    },
})
