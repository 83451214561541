
































import { defineComponent, ref, PropType, computed } from '@vue/composition-api'
import { headers } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/AssignTable.vue'
import AscentHelper from '@/helpers/ascent-helper'
import { sendQuotes } from '@/pages/AMC/Quotes/Quotes'
import { AppraiserPanel } from '@/types/appraisers'

export const sendQuote = ref(true)
export const search = ref('')

/**
 * @name AssignTablePrepend
 * Component on top of the assign table.
 */

export default defineComponent({
    props: {
        appraisers: {
            type: Array as PropType<AppraiserPanel[]>,
            required: true,
        },
    },
    setup(props) {
        const usingJaroPanels = ref(AscentHelper.customerFeatureEnabled('usesJaroPanels', false))
        const allSelected = computed(() => {
            return sendQuotes.value.length === props.appraisers.length
        })

        const toggleAll = () => {
            const allSelected = sendQuotes.value.length === props.appraisers.length
            sendQuotes.value = allSelected ? [] : props.appraisers.map((a) => a.appraiser.id)
        }

        return {
            search,
            allSelected,
            sendQuote,
            headers,
            usingJaroPanels,
            sendQuotes,
            toggleAll,
        }
    },
})
