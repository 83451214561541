<template>
    <a v-if="data.order_id" href="#" @click.stop="viewOrder">
        <strong>{{ data.order_id }}</strong>
    </a>
</template>

<script lang="js">

import {defineComponent} from "@vue/composition-api"
import {getOrderById} from '@/plugins/order/Order'

/**
 * @name QuoteOrderIdColumn
 * Component description: shows order id and offers a template to click to view and open an order.
 */
export default defineComponent({
    props: {},
    data() {
        return {
            data: {}
        }
    },
    methods: {
        /**
         * Opens the order when clicked.
         */
        viewOrder() {
            getOrderById(this.data.order_id)
        },
    }
})
</script>
