



















import { defineComponent, PropType } from '@vue/composition-api'
import { County } from '@/types'

export default defineComponent({
    props: {
        state: {
            type: Array,
        },
        appendOuter: {
            type: Boolean,
            default: false,
        },
        filterCounties: {
            type: Array,
            default: () => [] as PropType<County[]>,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            items: [],
        }
    },
    watch: {
        state() {
            this.searchCounty()
        },
        filterCounties() {
            this.searchCounty()
        },
    },
    mounted() {
        this.searchCounty()
    },
    methods: {
        searchCounty() {
            this.loading = true
            this.$axios
                .post('/v1/counties', {
                    state: this.state,
                    paginate: 'FALSE',
                })
                .then(({ data }) => {
                    this.items = data.filter((elem: County) => {
                        return (
                            (this.filterCounties as County[]).map((county: County) => county.id).indexOf(elem.id) == -1
                        )
                    })
                    this.loading = false
                    this.$emit('counties-loaded', this.items)
                })
        },
    },
})
