


































import { defineComponent, reactive } from '@vue/composition-api'
import Vue from 'vue'

interface ConfirmOptions {
    color?: string
    width?: number
    zIndex?: number
    confirm?: string
    decline?: string
    cancel_color?: string
    x_btn?: boolean
}

export const state = reactive({
    dialog: false,
    message: '',
    resolve: undefined as undefined | ((value: boolean | PromiseLike<boolean>) => void),
    title: '',
    options: {
        color: 'primary',
        width: 500,
        zIndex: 200,
        confirm: 'Yes',
        decline: 'Cancel',
        cancel_color: 'primary',
        x_btn: false,
    } as ConfirmOptions,
})

export const confirm = (title: string, message: string, options?: ConfirmOptions): Promise<boolean> => {
    state.dialog = true
    state.title = title
    state.message = message
    state.options = Object.assign(state.options, options)
    return new Promise((resolve) => {
        state.resolve = resolve
    })
}

export const agree = () => {
    if (!state.resolve) return false
    state.resolve(true)
    state.dialog = false
}
export const cancel = () => {
    if (!state.resolve) return false
    state.resolve(false)
    state.dialog = false
}
export default defineComponent(
    Vue.extend({
        setup() {
            return { state, confirm, agree, cancel }
        },
    }),
)
