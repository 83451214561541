

























import { defineComponent, PropType } from '@vue/composition-api'

export interface ChevronProgressItem {
    title: string
    value: number
    status: string
    returnClass: string
}

export default defineComponent({
    props: {
        item: {
            type: Object as PropType<ChevronProgressItem>,
            default: () => {
                return {
                    title: '',
                    value: 0,
                    status: '',
                    returnClass: '',
                }
            },
        },
    },
})
