








































































































































































































































import OrderUcdp from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderUcdp/OrderUcdp.vue'
import UcdpOverride from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderUcdp/UcdpOverride.vue'
import { addCondition } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/AddCondition.vue'
import { defineComponent } from '@vue/composition-api'
import { VerosOverrides, VerosSubmissions, VerosWarning } from '@/types'
import { lenderShouldShowUcdp } from '@/pages/AMC/Lenders/Lender'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    components: { UcdpOverride, OrderUcdp },
    props: {
        actions: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        return {
            lenderShouldShowUcdp,
            order,
        }
    },
    data() {
        return {
            findingHeader: [
                { text: 'Section', value: 'section' },
                { text: 'Field', value: 'field' },
                { text: 'Data Point', value: 'data' },
                { text: 'Property', value: 'property' },
                { text: 'Message', value: 'message' },
                { text: 'Severity', value: 'severity' },
                { text: 'Action', value: 'action', sortable: false },
            ],
            stopsHeader: [
                { text: 'File', value: 'section' },
                { text: 'Description', value: 'field' },
                { text: 'Code', value: 'data' },
                { text: 'Status', value: 'property' },
                { text: 'Decision Code', value: 'message' },
                { text: 'Decision Description', value: 'severity' },
                { text: 'Action', value: 'action', sortable: false },
            ],
        }
    },
    computed: {
        submission(): VerosSubmissions | undefined {
            if (!order.value) return
            if (!order.value.incremental) return
            return order.value.incremental.ucdp.submission
        },
    },
    mounted() {
        if (!this.actions) {
            this.findingHeader.pop()
            this.stopsHeader.pop()
        }
    },
    methods: {
        addWarning(item: VerosWarning) {
            let conditionActionMessage: string = item.ActionMessage
            if (item.PropertyAffected !== 'N/A' && item.PropertyAffected !== 'Appraisal')
                conditionActionMessage = item.PropertyAffected + ' - ' + conditionActionMessage
            addCondition(conditionActionMessage, 0).then(() => {
                item.added = true
                showSnackbar('Condition added successfully')
            })
        },
        addStop(item: VerosOverrides) {
            addCondition(item.description as string, 0).then(() => {
                showSnackbar('Condition added successfully')
            })
        },
        isOverrideable(code: string | number) {
            return [101, 302, '101', '302', 'FNM0399', 'FNM0179', 'FNM0197', 'FNM0083', 'FHA201'].indexOf(code) > -1
        },
    },
})
