<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on }">
            <v-list-item v-on="on" @click="getCard">
                <v-list-item-icon><v-icon>$vuetify.icons.dollarSign</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Charge Order Card</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <v-card>
                <v-card-title primary-title>
                    Charge Card
                    <v-spacer />
                    <v-btn icon color="primary" @click="close">
                        <v-icon>$vuetify.icons.times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="error == null" wrap>
                        <v-col cols="12">
                            <h3>
                                ${{ order.incremental.total.due }}
                                <span class="small">Total Due</span>
                            </h3>
                            <p>Choose which card and amount to charge.</p>
                            <a-select-field
                                v-model="card"
                                :items="cards"
                                item-text="cc_label"
                                return-object
                                label="Credit Card Last Four"
                                name="credit card"
                                outlined
                                rules="required"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                v-model="amount"
                                name="amount"
                                label="Amount"
                                :rules="'required|max_value:' + total"
                                prepend-icon="$vuetify.icons.dollarSign"
                                autofocus
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                v-model="email"
                                name="email"
                                label="Email to send Receipt"
                                rules="email"
                                hint="Email for receipt is optional"
                                persistent-hint
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="error != null">
                        <v-col cols="12">
                            <v-alert text dense :type="errorType" icon="$vuetify.icons.exclamation-triangle">
                                {{ error }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" depressed :loading="charging" @click="handleSubmit(chargeCard)">
                        Charge Card
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script lang="js">


function reset() {
    return {
        dialog: false,
        charging: false,
        card: null,
        cards: [],
        error: null,
        amount: null,
        email: '',
        errorType: 'error',
    }
}
import { defineComponent } from "@vue/composition-api"
import {ValidationObserver} from 'vee-validate'
import {showSnackbar} from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from "@/plugins/order/Order"

export default defineComponent({
    components: {
        ValidationObserver
    },
    props: {
        id: Number,
        due: Number,
        total: Number
    },
    setup() {
        return {
            order
        }
    },
    data() {
        return reset()
    },
    methods: {
        open() {
            this.dialog = true
            this.getCard()
        },
        getCard() {
            this.$axios.get('/v1/order/' + this.id + '/card').then((response) => {
                this.cards = response.data.card
                if (this.cards.length === 1) {
                    this.card = this.cards[0]
                }
                this.amount = this.due
            }).catch(() => {
                this.charging = false
            })
        },
        close() {
            Object.assign(this.$data, reset())
        },
        chargeCard() {
            this.charging = true

            const timer = setTimeout(() => {
                this.errorType = 'info'
                this.error = 'We haven\'t received a response back from the payment processor yet. Please refresh and try again if necessary.'
                this.charging = false
            }, 20000)

            this.$axios.post('/v1/order/' + this.id + '/payment/' + this.card.id + '/charge', {amount: this.amount, email: this.email}).then((response) => {
                if (response.data.result) {
                    clearTimeout(timer)
                    showSnackbar('Card Charged Successfully for ' + this.amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }))
                    refreshOrder().then(() => {
                        Object.assign(this.$data, reset())
                    }).catch(() => {
                        this.charging = false
                        this.errorType = 'info'
                        this.error = 'The charge was successful, please refresh your browser.'
                    })

                    this.$emit('charged')
                } else {
                    clearTimeout(timer)
                    this.error = response.data.message
                    this.charging = false
                }
            }).catch(() => {
                clearTimeout(timer)
                this.charging = false
            })
        }
    },
})
</script>
