import { user, role, permissions } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

export default function (permission: string) {
    if (user.value?.ascent) return true
    if (role.value === 'Admin') return true
    switch (permission) {
        case 'Work':
            return role.value !== 'Sales' && role.value !== 'View Only'
        case 'Order':
            if (
                user.value?.type === 'client' &&
                AscentHelper.customerFeatureEnabled('clients.showClientOrderPermission')
            ) {
                return (
                    permissions.value?.includes(permission) &&
                    !user.value.client?.branch?.options?.find((option) => option.option_key === 'prevent-new-orders')
                        ?.option_value
                )
            }
            return role.value !== 'Limited' && role.value !== 'Sales' && role.value !== 'View Only'
        case 'Quality':
            if (['Admin', 'Manager', 'Quality'].indexOf(role.value) > -1) return true
            return false
        default:
            if (permissions.value == null) return false
            return (
                permissions.value.filter((elem) => {
                    return elem === permission
                }).length > 0
            )
    }
}
