





























import SplitInvoice from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/SplitInvoice.vue'
import { defineComponent } from '@vue/composition-api'
import { File } from '@/types'
import { order } from '@/plugins/order/Order'
import { openSendInvoiceDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/SendInvoiceDialog.vue'

export default defineComponent({
    setup() {
        return {
            order,
            openSendInvoiceDialog,
        }
    },
    components: {
        SplitInvoice,
    },
    data() {
        return {}
    },
    methods: {
        splitInvoice() {
            const split = this.$refs.split as Vue & { open: () => void }
            split.open()
        },
        openFile(file: File) {
            this.$axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        },
    },
    computed: {
        pdfUrl() {
            return process.env.VUE_APP_PDF
        },
    },
})
