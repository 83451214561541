




























































































































































import { defineComponent } from '@vue/composition-api'
import { DateTime } from 'luxon'
import VClamp from 'vue-clamp'
import { ValidationObserver } from 'vee-validate'
import CompleteQuote from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/CompleteQuote.vue'
import { Quote } from '@/types'
import { refreshQuotes, quotesArray, type, id } from '@/pages/AMC/Quotes/Quotes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { getNotes } from '@/plugins/Notes'
import { order } from '@/plugins/order/Order'

interface FilteredQuotes {
    company: string
    email: string
    first_name: string
    id: number
    last_name: string
    name: string
    quote: Quote
    search: string
    status: string
}

export default defineComponent({
    components: {
        VClamp,
        ValidationObserver,
        CompleteQuote,
    },
    data() {
        return {
            DateTime: DateTime,
            note: '',
            amc: null,
            client: null,
            loading: false,
            open: null,
            headers: [
                { text: 'Option', value: 'quote.details', width: '10%' },
                { text: 'Name', value: 'name', width: '10%' },
                { text: 'status', value: 'quote.status', width: '10%' },
                { text: 'Appr Fee', value: 'quote.fee', width: '5%' },
                { text: 'AMC Fee', value: 'quote.amc', width: '5%' },
                { text: 'Appr Deadline', value: 'deadline', width: '10%' },
                {
                    text: 'Clt Deadline',
                    value: 'client_deadline',
                    width: '10%',
                },
                { text: 'Note', value: 'note', width: '38%' },
                { text: 'Include', value: 'include', width: '10%' },
            ],
            order: order.value,
        }
    },
    computed: {
        items() {
            return quotesArray.value
        },
    },
    methods: {
        include(item: FilteredQuotes, invalid: boolean) {
            if (invalid) return false
            this.open = null
            this.$axios
                .patch('/v1/quoteResponse/' + item.quote.id, {
                    status: 'Included',
                    amc: this.amc,
                    client_deadline: this.client,
                })
                .then(() => {
                    this.amc = null
                    refreshQuotes()
                })
        },
        exclude(item: FilteredQuotes) {
            this.$axios
                .patch('/v1/quoteResponse/' + item.quote.id, {
                    status: 'Excluded',
                    amc: null,
                    client_deadline: null,
                })
                .then(() => {
                    refreshQuotes()
                })
        },
        completeQuote(item: FilteredQuotes) {
            const completeQuote = this.$refs.completeQuote as Vue & { open: (id: number) => void }
            completeQuote.open(item.quote.id)
        },
        returnQuotes() {
            confirm('Return Quotes To Client?', 'Are you sure you want to return these quotes to the client?').then(
                (confirm) => {
                    if (confirm) {
                        this.loading = true
                        this.$axios
                            .post('/v1/' + type.value + '/' + id.value + '/action/quote-return', {
                                note: this.note,
                            })
                            .then(() => {
                                if (!order.value) return
                                refreshQuotes()
                                if ((type as unknown) === 'order') {
                                    getNotes(order.value.id)
                                }
                                this.loading = false
                                this.note = ''
                                showSnackbar('Quotes Returned to Client Successfully')
                            })
                    }
                },
            )
        },
    },
})
