import { render, staticRenderFns } from "./EventDetail.vue?vue&type=template&id=10c68e26&scoped=true&"
import script from "./EventDetail.vue?vue&type=script&lang=ts&"
export * from "./EventDetail.vue?vue&type=script&lang=ts&"
import style0 from "./EventDetail.vue?vue&type=style&index=0&id=10c68e26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c68e26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VCol,VRow,VSkeletonLoader})
