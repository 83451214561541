





































































import { defineComponent, watch, ref } from '@vue/composition-api'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import AEditor from '@/global/Form/AEditor.vue'

export default defineComponent({
    components: {
        AEditor,
    },
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const financialAssistance = ref('')
        const financialAssistanceAmount = ref('')
        const itemsToBePaid = ref('')

        watch(props, () => {
            if (!props.editData && !props.cancelEdit) {
                if (financialAssistance.value == 'No') {
                    financialAssistanceAmount.value = ''
                    itemsToBePaid.value = ''
                }
                preloadData.value.contractData.financialAssistance = financialAssistance.value
                preloadData.value.contractData.financialAssistanceAmount = financialAssistanceAmount.value
                preloadData.value.contractData.itemsToBePaid = itemsToBePaid.value
            }
            if (props.editData) {
                financialAssistance.value = preloadData.value.contractData.financialAssistance
                financialAssistanceAmount.value = preloadData.value.contractData.financialAssistanceAmount
                itemsToBePaid.value = preloadData.value.contractData.itemsToBePaid
            }
        })
        return {
            preloadData,
            financialAssistance,
            financialAssistanceAmount,
            itemsToBePaid,
        }
    },
})
