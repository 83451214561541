





































































import { computed, defineComponent, ref } from '@vue/composition-api'
import CustomerAddNote from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/CustomerAddNote.vue'
import CustomerOrderNote from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/CustomerOrderNote.vue'
import { filteredNotes, unreadNotes, filterNotesByTag, showAllNotes, notes } from '@/plugins/Notes'
import CustomerStatusNote from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/CustomerStatusNote.vue'
import CustomerDateNote from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/CustomerDateNote.vue'
import CustomerNoteMenu from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/CustomerNoteMenu.vue'
import CustomerUnreadNoteDivider from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/CustomerUnreadNoteDivider.vue'
import { noteTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import AuditNoteDetails from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/AuditNoteDetails.vue'
import { Note } from '@/types'
import { uniqBy } from 'lodash'

export default defineComponent({
    name: 'CustomerOrderNotes',
    components: {
        AuditNoteDetails,
        CustomerUnreadNoteDivider,
        CustomerNoteMenu,
        CustomerDateNote,
        CustomerStatusNote,
        CustomerOrderNote,
        CustomerAddNote,
    },
    setup(_, { emit }) {
        const scrollToUnread = () => {
            document.getElementById('unreadmessages')?.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            })
        }

        const scrollToTop = () => {
            document.getElementById('notesScrollBox')?.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }

        const scrollToBottom = () => {
            const box = document.getElementById('notesScrollBox')
            if (box) {
                box.scrollTo({
                    top: box.scrollHeight,
                    behavior: 'smooth',
                })
            }
        }

        const addNote = (note: Note): void => {
            emit('note-added', note)
        }

        const setShowAllNotes = () => {
            showAllNotes.value = !showAllNotes.value
            localStorage.setItem('show-all-notes', JSON.stringify(showAllNotes.value))
        }

        const removeShowAllNotes = () => {
            showAllNotes.value = false
            setShowAllNotes()
        }

        const menu = ref(false)
        const currentTag = ref('All Tags')
        const auditNoteDetails = ref()

        const openAuditNoteDetails = (note: Note) => {
            auditNoteDetails.value.open(note)
        }

        if (localStorage.getItem('show-all-notes') === 'true') {
            showAllNotes.value = true
        }

        const allTags = computed(() => {
            return Array.from(
                new Set(
                    uniqBy(
                        notes.value
                            .filter((note) => note && note.tag)
                            .map((item) => {
                                return item.tag
                            }),
                        'tag',
                    ),
                ),
            )
        })

        const updateTag = (tag: string) => {
            if (currentTag.value === tag) {
                currentTag.value = 'All Tags'
                return
            }
            currentTag.value = tag
        }

        return {
            filteredNotes,
            unreadNotes,
            scrollToUnread,
            menu,
            noteTags,
            currentTag,
            filterNotesByTag,
            showAllNotes,
            setShowAllNotes,
            auditNoteDetails,
            openAuditNoteDetails,
            scrollToTop,
            scrollToBottom,
            removeShowAllNotes,
            addNote,
            allTags,
            updateTag,
        }
    },
    watch: {
        currentTag: function (newValue) {
            filterNotesByTag(newValue)
        },
    },
})
