
























































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import AscentHelper from '@/helpers/ascent-helper'
import { refreshQuotes } from '@/pages/AMC/Quotes/Quotes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        dialog: false,
        error: false,
        adding: false,
        note: '',
        fee: 0,
        deadline: '',
        id: 0,
        order: order,
    }
}

/**
 * @name CompleteQuote
 * Dialog to complete quote for an appraiser
 *
 * @ResetData note - note added to the quote
 * @ResetData fee - fee for the quote
 * @ResetData deadline - deadline to have the appraisal done by
 * @Resetata id - id of the quote
 */

export default defineComponent({
    props: {},
    components: {
        ValidationObserver,
    },
    data() {
        return reset()
    },
    methods: {
        /**
         * Opens the dialog and sets the data
         * @param {number} id - id of the quote
         */
        open(id: number) {
            Object.assign(this.$data, reset())
            this.id = id
            this.dialog = true
        },
        /**
         * After quote is submitted, resets dialog
         */
        submit() {
            this.adding = true
            refreshQuotes().then(() => {
                showSnackbar('Quote Updated Successfully')
                Object.assign(this.$data, reset())
                const form = this.$refs.form as Vue & { reset: () => void }
                form.reset()
            })
        },
        /**
         * Takes in dates and checks to see if that date is allowed or is blacked out
         * @param date - date on calendar
         */
        allowedDates(date: string) {
            return AscentHelper.isDateBlackedOut(date) && this.isWeekday(date)
        },
        /**
         * Checks in the allowedDates method to make sure that the day is a weekday
         * @param date - date to check
         */
        isWeekday(date: string) {
            return ![5, 6].includes(new Date(date).getDay())
        },
        /**
         * Declines quote for the appraiser
         */
        decline() {
            confirm('Decline Quote', 'Are you sure you want to decline this opportunity?').then((confirm) => {
                if (confirm) {
                    this.$axios
                        .patch('/v1/quoteResponse/' + this.id, {
                            status: 'Declined',
                        })
                        .then(() => {
                            showSnackbar('Quote Declined')
                            Object.assign(this.$data, reset())
                            const form = this.$refs.form as Vue & { reset: () => void }
                            form.reset()
                        })
                }
            })
        },
    },
})
