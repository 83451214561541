























import { defineComponent } from '@vue/composition-api'
import { unreadNotes, markAllUnreadRead } from '@/plugins/Notes'
import UnreadNotes from '@/pages/AMC/Dashboard/Processes/Partials/ProcessScreens/UnreadNotes.vue'

/**
 * @name ReviewNotes
 * Step in Review Order workflow where Rep must read all unread notes
 *
 * @SetupData unreadNotes - Array of unread notes
 * @SetupMethod markAllUnreadRead - Marks all notes read that are unread
 */

export default defineComponent({
    components: { UnreadNotes },
    props: {},
    setup() {
        return {
            markAllUnreadRead,
            unreadNotes,
        }
    },
})
