














































import { defineComponent, onMounted } from '@vue/composition-api'
import { Note } from '@/types'
import $ascent from '@/helpers/ascent-helper'
import { filteredNotes } from '@/plugins/Notes'

export const scrollToBottom = () => {
    const vDialog = document.querySelector('.messages')
    if (typeof vDialog !== 'undefined') {
        vDialog?.scrollTo({
            left: 0,
            top: vDialog.scrollHeight,
            behavior: 'smooth',
        })
    }
}

/**
 * @name AppraiserOrderNotes
 * Order notes component on appraiser order screen. Lets the appraiser and the amc communicate
 *
 *
 * @SetupMethod scrollToBottom - function to let user scroll to bottom of the notes
 * @SetupMethod messageColumn - returns a number of where the note should be placed whether it's from the Amc of the Appraiser
 * @SetupMethod messageClass - returns the CSS class of message based on what source it comes from
 * @SetupMethod getNoteName - Takes in note, and returns the name of who sent the note formatted.
 */

export default defineComponent({
    setup() {
        const messageColumn = (note: Note): number => {
            if (note.source == 'AMC') return 1
            if (note.source == 'Appraiser') return 2
            return 0
        }

        const messageClass = (note: Note): string => {
            let returnClass = ''
            if (note.source) {
                if (note.source === 'Appraiser') returnClass = 'message__appraiser'
                if (note.source === 'AMC') returnClass = 'message__amc'
            }
            return returnClass
        }

        const getNoteName = (note: Note): string => {
            if (note.source != null) return note.from + ' (' + $ascent.formatIsoDate(note.created_at, 'f') + ')'
            return $ascent.formatIsoDate(note.created_at, 'f')
        }

        onMounted(() => scrollToBottom())

        return {
            scrollToBottom,
            messageColumn,
            messageClass,
            getNoteName,
            filteredNotes,
        }
    },
})
