
































import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    setup() {
        const value = ref(false)
        const saveOption = () => {
            $axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'qc-billing-override',
                    option_value: value.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            value,
            saveOption,
        }
    },
})
