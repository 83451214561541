









import { defineComponent, ref } from '@vue/composition-api'
import ClientOrderScreen from '@/components/OrderScreens/Client/ClientOrderScreen.vue'
import AppraiserOrderScreen from '@/components/OrderScreens/Appraiser/AppraiserOrderScreen.vue'
import { loadIncremental, order } from '@/plugins/order/Order'
import { user, role } from '@/plugins/User'
import { filteredNotes, setNotes } from '@/plugins/Notes'

const openOrderViewOnly = ref(false)
const viewerComponent = ref('')
const openViewOnlyAs = (type: string) => {
    viewerComponent.value = type
    openOrderViewOnly.value = true
    role.value = 'View Only'
}

export default defineComponent({
    components: {
        ClientOrderScreen,
        AppraiserOrderScreen,
    },
    methods: {
        filterNotes(type: string) {
            switch (type) {
                case 'vendor':
                    setNotes(
                        filteredNotes.value?.filter((note) => {
                            return note.source === 'Appraiser' || note.target === 'Appraiser'
                        }),
                    )
                    break
                case 'client':
                    setNotes(
                        filteredNotes.value?.filter((note) => {
                            return note.source === 'Client' || note.target === 'Client'
                        }),
                    )
                    break
                default:
                    return
            }
        },
    },
    mounted() {
        if (!user.value) return
        if (['client', 'vendor'].includes(this.$route.params.type)) {
            this.$axios.get('/v1/ordercache/' + this.$route.params.id).then(async (response) => {
                if (!response.data) return
                order.value = response.data
                await loadIncremental()
                switch (this.$route.params.type) {
                    case 'client':
                        openViewOnlyAs('ClientOrderScreen')
                        break
                    case 'vendor':
                        openViewOnlyAs('AppraiserOrderScreen')
                        break
                    default:
                        return
                }
                this.filterNotes(this.$route.params.type)
            })
        }
    },
    setup() {
        return {
            openOrderViewOnly,
            viewerComponent,
        }
    },
})
