var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('span',{staticClass:"grey--text"},[_vm._v("Sales Rep")])])],1)],1),_c('v-col',{attrs:{"cols":"7"}},[(_vm.optionShow && _vm.optionShow.name)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_vm._v(_vm._s(_vm.optionShow.name))])],1):_vm._e(),(!_vm.optionShow.name)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_vm._v("No Sales Rep Added")])],1):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[(_vm.$ascent.canDo('Edit Clients'))?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondarydarken4"}},'v-btn',attrs,false),on),[_vm._v(" Edit ")]):_vm._e()],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('a-card',{attrs:{"title":"Edit Sales Rep"}},[_c('v-card-text',[_c('a-autocomplete',{attrs:{"return-object":true,"label":"Sales Rep","item-text":"name","item-value":"id","items":_vm.salesReps},model:{value:(_vm.optionEdit),callback:function ($$v) {_vm.optionEdit=$$v},expression:"optionEdit"}})],1),_c('a-card-action',{attrs:{"btnText":"Save Sales Rep","url":'/v1/lender/' + _vm.lender.id + '/option',"submitData":{
                    option_key: 'sales-rep',
                    option_value: _vm.optionEdit,
                },"disabled":invalid,"handleSubmit":handleSubmit},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.save}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }