






























































































































































































import { defineComponent, computed } from '@vue/composition-api'
import { mini } from './LeftNav.vue'
import { orderSearchFilter } from '@/components/OrderTable/Classes/OrderTable'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

/**
 * @name 'AMC Nav'
 * Component description: The amc nav to navigate through the different pages.
 * The left side menu on Ascent.
 * Has the routes and paths of the different pages it will direct you to.
 */
export default defineComponent({
    setup() {
        const reportUrl = computed((): string => {
            return process.env.VUE_APP_REPORTS as string
        })

        const appraisers = computed((): boolean => {
            return (
                (AscentHelper.pageEnabled('vendors') && AscentHelper.canView('Appraisers')) ||
                AscentHelper.canView('Pending Documents') ||
                AscentHelper.canView('Exclusion Lists')
            )
        })

        const clients = computed((): boolean => {
            return !!(AscentHelper.pageEnabled('clients') || AscentHelper.canView('Clients'))
        })

        const settings = computed((): boolean => AscentHelper.canDo('Settings'))

        const accounting = computed((): boolean => {
            if (AscentHelper.pageEnabled('accounting.enabled') && AscentHelper.canView('Client Billing')) {
                return true
            }

            return AscentHelper.canView('Billing') && AscentHelper.pageEnabled('accounting.enabled')
        })

        const tools = computed((): boolean => AscentHelper.pageEnabled('tools'))

        const reports = computed((): boolean => {
            return AscentHelper.pageEnabled('reports') && AscentHelper.canView('Reports')
        })

        const quality = computed((): boolean => {
            return AscentHelper.canDo('Quality Control') && AscentHelper.pageEnabled('quality')
        })

        return {
            mini,
            orderSearchFilter,
            user,
            reportUrl,
            appraisers,
            clients,
            settings,
            accounting,
            tools,
            reports,
            quality,
        }
    },
})
