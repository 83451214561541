




























































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'
import { order, updateOrder } from '@/plugins/order/Order'
import OrderHelper from '@/helpers/OrderHelper'

export const revisionCanceled = ref(false)

/**
 * @name CancelRevisionDialog
 * Dialog to cancel a revision
 *  * @SetupData orderIsPaid: Check to see if order is paid or not

 */

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        return {
            revisionCanceled,
            order,
        }
    },
    data() {
        return {
            dialog: false,
            saving: false,
            /**
             * Message to be sent to appraiser that revision isn't needed anymore
             */
            message: null,
            /**
             * Message to be sent to the client that revision isn't needed anymore
             */
            client: null,
        }
    },
    methods: {
        /**
         *Saves and sends the revision cancel request. Resets dialog after endpoint is sent
         * Endpoint used is '/v1/order/' + order.value.id + '/workflow/revision-cancel'
         */
        save() {
            this.saving = true
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/revision-cancel', {
                    message: this.message,
                    client: this.client,
                    paid: OrderHelper.orderIsPaid.value,
                })
                .then(() => {
                    if (!order.value) return
                    updateOrder()
                    this.saving = false
                    this.dialog = false
                    this.message = null
                    this.client = null
                    this.revisionCanceled = true
                    getNotes(order.value.id)
                    showSnackbar('Revision cancelled successfully')
                })
        },
    },
})
