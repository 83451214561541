

































































































































































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { AppraiserPanel, AppraiserStats, JaroPanelData, JaroPanelExpandedHeader } from '@/types'
import AssignedPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AssignedPartial.vue'
import PanelNotePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/PanelNotePartial.vue'
import JaroAssignPanelsClickableNamePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsClickableNamePartial.vue'
import JaroAssignPanelsTagCountPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsTagCountPartial.vue'
import JaroAssignPanelsAssignButtonPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsAssignButtonPartial.vue'
import AssignDialog, { openAssignDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignDialog.vue'
import AppraiserRating from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AppraiserRating.vue'
import PanelMap from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/PanelMap.vue'
import {
    getValidJaroPanels,
    requiresClosedPanel,
    distinctAppraiserLicenseTypes,
    jaroSavedPanelColumns,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'
import OverridePanelAssignDialog, {
    overrideDialog,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/OverridePanelAssignDialog.vue'
import AssignmentDatePicker from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/AssignmentDatePicker.vue'
import SendQuoteAssignTable from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/SendQuoteAssignTable.vue'
import SendQuotePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/SendQuotePartial.vue'
import { sendQuotes as quotes } from '@/pages/AMC/Quotes/Quotes'
import OverrideClosedPanelDialogPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/OverrideClosedPanelDialogPartial.vue'
import AssignTablePrepend from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AssignTablePrepend.vue'
import QuoteEnter from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/QuoteEnter.vue'
import JaroAssignPanelsLicenseTagPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsLicenseTagPartial.vue'
import JaroAssignPanelColumnPicker, {
    columnPickerDialog,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelColumnPicker.vue'

/**
 * @name JaroAssignPanels
 *
 */

export default defineComponent({
    components: {
        JaroAssignPanelColumnPicker,
        PanelNotePartial,
        AssignedPartial,
        JaroAssignPanelsClickableNamePartial,
        JaroAssignPanelsTagCountPartial,
        JaroAssignPanelsAssignButtonPartial,
        AssignDialog,
        AppraiserRating,
        PanelMap,
        OverridePanelAssignDialog,
        AssignmentDatePicker,
        SendQuoteAssignTable,
        SendQuotePartial,
        OverrideClosedPanelDialogPartial,
        AssignTablePrepend,
        QuoteEnter,
        JaroAssignPanelsLicenseTagPartial,
    },
    props: {
        jaroPanels: {
            default: () => [],
            type: Array as () => JaroPanelData[],
        },
        largePanelViewer: {
            type: Boolean,
            default: false,
        },
        allowAssignment: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const jaroPanelHeaders = ref([{ text: '', value: 'panel', sortable: false, align: 'start' }])
        const appraiser = ref('')
        const client = ref('')
        const nameFilter = ref('')
        const licenseTypeFilter = ref([] as string[])
        const quoteEnter = ref()
        const sortDescending = ref(true)

        const filteredAndSortedPanels = computed(() => {
            // Filter JaroPanels by searched name
            let filteredPanels = props.jaroPanels.map((panel) => ({
                ...panel,
                appraisers: panel.appraisers.filter((appraiser) =>
                    appraiser.appraiser.name.toLowerCase().includes(nameFilter.value.toLowerCase()),
                ),
            }))

            // Filter JaroPanels by license type
            if (licenseTypeFilter.value.length > 0) {
                filteredPanels = filteredPanels.map((panel) => ({
                    ...panel,
                    appraisers: panel.appraisers.filter((appraiser) =>
                        licenseTypeFilter.value.includes(appraiser.appraiser.type),
                    ),
                }))
            }

            // Sort by distance
            return filteredPanels.map((panel) => ({
                ...panel,
                appraisers: [...panel.appraisers].sort((a, b) => {
                    const distanceA = Number(a.distance) ?? 0
                    const distanceB = Number(b.distance) ?? 0

                    return sortDescending.value ? distanceA - distanceB : distanceB - distanceA
                }),
            }))
        })

        const distanceSortingIcon = computed(() => {
            return sortDescending.value ? '$vuetify.icons.arrowUp' : '$vuetify.icons.arrowDown'
        })

        const getJaroAssignPanelsPadding = computed(() => {
            if (props.largePanelViewer) {
                return 'jaro-assign-panels-with-padding'
            }
            return ''
        })

        const updateDeadline = (args: { type: string; value: string }) => {
            switch (args.type) {
                case 'appraiser':
                    appraiser.value = args.value
                    break
                case 'client':
                    client.value = args.value
                    break
            }
        }

        const jaroExpandedPanelHeaders = ref([
            {
                text: 'Quote',
                requireOrder: true,
                renderOnlyLargePanelViewer: true,
                renderText: false,
                showColumn: true,
            },
            { text: 'Notes', requireOrder: true, renderOnlyLargePanelViewer: false, showColumn: true },
            {
                text: 'Name',
                requireOrder: false,
                renderOnlyLargePanelViewer: false,
                showColumn: true,
            },
            { text: 'Vendor Type', requireOrder: false, renderOnlyLargePanelViewer: true, showColumn: true },
            {
                text: 'License Type',
                requireOrder: false,
                renderOnlyLargePanelViewer: true,
                showColumn: true,
            },
            {
                text: 'Assign Status',
                requireOrder: true,
                renderOnlyLargePanelViewer: false,
                showColumn: true,
            },
            { text: 'Rating', requireOrder: false, renderOnlyLargePanelViewer: true, showColumn: true },
            {
                text: 'Distance',
                requireOrder: false,
                renderOnlyLargePanelViewer: false,
                handleSort: () => (sortDescending.value = !sortDescending.value),
                sortingIcon: () => distanceSortingIcon.value,
                showColumn: true,
            },
            { text: 'Turn Time', requireOrder: false, renderOnlyLargePanelViewer: true, showColumn: true },
            {
                text: 'Declined % / On-Time %',
                requireOrder: false,
                renderOnlyLargePanelViewer: false,
                showColumn: true,
            },
            { text: 'Availability', requireOrder: false, renderOnlyLargePanelViewer: true, showColumn: true },
            { text: 'Accepted', requireOrder: true, renderOnlyLargePanelViewer: false, showColumn: false },
            { text: 'Assigned', requireOrder: true, renderOnlyLargePanelViewer: false, showColumn: false },
            { text: 'Conditional', requireOrder: true, renderOnlyLargePanelViewer: false, showColumn: false },
            { text: 'Completed', requireOrder: true, renderOnlyLargePanelViewer: false, showColumn: false },
            {
                text: 'History',
                requireOrder: false,
                renderOnlyLargePanelViewer: false,
                showColumn: true,
            },
            {
                text: 'Tags',
                requireOrder: false,
                renderOnlyLargePanelViewer: false,
                showColumn: true,
            },
            { text: 'Assign', requireOrder: true, renderOnlyLargePanelViewer: false, showColumn: true },
        ] as JaroPanelExpandedHeader[])

        const renderedExpandedPanelHeaders = computed(() => {
            if (jaroSavedPanelColumns.value) {
                return jaroSavedPanelColumns.value.filter((header) => {
                    let shouldRender = true
                    if (header.requireOrder && order.value === undefined) {
                        shouldRender = false
                    }
                    if (header.renderOnlyLargePanelViewer && !props.largePanelViewer) {
                        shouldRender = false
                    }

                    if (!header.showColumn) {
                        shouldRender = false
                    }

                    return shouldRender
                })
            }
            return jaroExpandedPanelHeaders.value.filter((header) => {
                let shouldRender = true
                if (header.requireOrder && order.value === undefined) {
                    shouldRender = false
                }
                if (header.renderOnlyLargePanelViewer && !props.largePanelViewer) {
                    shouldRender = false
                }

                if (!header.showColumn) {
                    shouldRender = false
                }

                return shouldRender
            })
        })

        const showColumn = (text: string) => {
            return renderedExpandedPanelHeaders.value.find((column) => {
                return column.text === text && column.showColumn
            })
        }

        const expandedItems = ref([])

        const orderIsOnHold = computed(() => {
            if (!order.value) return false
            return ['Payment Hold', 'Hold'].includes(order.value.status)
        })

        const canAssign = computed(() => {
            return !!order.value && (props.allowAssignment as boolean) && !orderIsOnHold.value
        })

        const assignAppraiser = (item: AppraiserPanel) => {
            if (!item.eligible) return
            openAssignDialog({
                appraiser: item.appraiser,
                appraiser_deadline: appraiser.value,
                client_deadline: client.value,
                eligible: item.eligible.result,
                details: item.eligible.details ?? '',
                manually_overridden: false,
            })
        }

        const refreshPanels = () => {
            if (!order.value) return
            getValidJaroPanels({
                order_id: order.value.id,
                customer_id: order.value?.customer_id,
                override_closed_panel: true,
            })
        }

        const handleQuoteEnter = (item: AppraiserPanel) => {
            quoteEnter.value.open(item)
        }

        const getStatPercentage = (expandedAppraiser: AppraiserPanel, stat: string) => {
            if (
                !expandedAppraiser.appraiser ||
                !expandedAppraiser.appraiser.reports ||
                !expandedAppraiser.appraiser.reports[0] ||
                !expandedAppraiser.appraiser.reports[0][stat as keyof AppraiserStats]
            )
                return 'N/A'
            if (!expandedAppraiser.appraiser.reports[0]['assigned'] || stat === 'assigned')
                return expandedAppraiser.appraiser.reports[0][stat as keyof AppraiserStats]

            return (
                Math.round(
                    Number(expandedAppraiser.appraiser.reports[0][stat as keyof AppraiserStats]) /
                        Number(expandedAppraiser.appraiser.reports[0]['assigned']),
                ) *
                    100 +
                '%'
            )
        }

        const savePanelColumns = () => {
            if (localStorage.getItem('jaro-assign-panel-columns')) {
                jaroExpandedPanelHeaders.value = JSON.parse(localStorage.getItem('jaro-assign-panel-columns') ?? '[]')
            }
            jaroSavedPanelColumns.value = jaroExpandedPanelHeaders.value
        }

        onMounted(() => {
            if (!jaroSavedPanelColumns.value || jaroSavedPanelColumns.value.length === 0) {
                jaroSavedPanelColumns.value = jaroExpandedPanelHeaders.value
            }
        })

        return {
            jaroPanelHeaders,
            expandedItems,
            order,
            renderedExpandedPanelHeaders,
            canAssign,
            getJaroAssignPanelsPadding,
            assignAppraiser,
            appraiser,
            client,
            refreshPanels,
            overrideDialog,
            updateDeadline,
            quotes,
            requiresClosedPanel,
            nameFilter,
            handleQuoteEnter,
            quoteEnter,
            filteredAndSortedPanels,
            distinctAppraiserLicenseTypes,
            licenseTypeFilter,
            columnPickerDialog,
            showColumn,
            jaroExpandedPanelHeaders,
            savePanelColumns,
            getStatPercentage,
        }
    },
})
