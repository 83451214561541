















import { computed, defineComponent, onMounted } from '@vue/composition-api'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import {
    selectComparable,
    allComparables,
    selectedComps,
    compPosition,
    Comparable,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'

export default defineComponent({
    components: {
        GmapCustomMarker,
    },
    props: {
        comp: Object,
        index: Number,
    },
    name: 'ComplexityCompsList',
    setup(props, { emit }) {
        const selectProperty = (property: Comparable) => {
            compPosition.value = {
                lat: parseFloat(property.Latitude as string),
                lng: parseFloat(property.Longitude as string),
            }
            emit('select-property', property)
        }

        onMounted(() => {
            if (props.comp) allComparables.value = props.comp
        })

        const getCompColor = computed(() => {
            if (props && props.comp && selectedComps.value && selectedComps.value == props?.comp.id) {
                selectComparable(props?.comp as Comparable)
                return '#e81111'
            }

            if (props.comp && props.comp.similarity_score <= 86) return '#f1963d'
            if (props.comp && props.comp.similarity_score <= 95) return '#613fee'
            if (props.comp && props.comp.similarity_score <= 100) return '#7ad73e'
            return '#be2978'
        })

        return {
            getCompColor,
            selectComparable,
            selectProperty,
        }
    },
})
