




























































import { computed, defineComponent } from '@vue/composition-api'
import {
    Comparable,
    selectedComps,
    selectedCompsAll,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    props: {
        comps: {
            type: [Object, Array],
        },
    },
    setup(props) {
        const propertyColor = computed(() => {
            if (!props || !props.comps) return
            if (props && props.comps && selectedComps.value && selectedComps.value == (props?.comps as Comparable).id)
                return '#66BB6A'

            return '#be2978'
        })

        const getComp = computed(() => {
            if (selectedCompsAll.value) {
                return true
            }
            return false
        })

        return {
            propertyColor,
            order,
            selectedComps,
            getComp,
            selectedCompsAll,
        }
    },
})
