























import { defineComponent, ref } from '@vue/composition-api'

export const showTextDialog = ref(false)

export default defineComponent({
    props: {
        text: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    setup() {
        const cancel = () => {
            showTextDialog.value = false
        }

        return {
            cancel,
            showTextDialog,
        }
    },
})
