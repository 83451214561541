import { RouteConfig } from 'vue-router'
import { customerPages } from '@/plugins/User'

const routes: Array<RouteConfig> = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/components/General/Dashboard/DashboardPage.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'], dashboard: true },
    },
    {
        path: '/quotes',
        name: 'Quotes',
        component: () => import('@/pages/AMC/Quotes/QuotesPage.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'office'] },
    },
    {
        path: '/order-search',
        name: 'Order Search',
        component: () => import('@/pages/AMC/Settings/OrderSearch.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
    },
    {
        path: '/assign-map',
        name: 'Assignment Map',
        component: () => import('@/pages/AMC/Tools/AssignmentMap.vue'),
        meta: { requiresAuth: true, userType: ['amc'] },
    },
    {
        path: '/snapshot-review/order/:id',
        name: 'Snapshot Review',
        component: () => import('@/pages/Shared/CustomerSettings/FeeSettings/SnapshotReview/SnapshotReviewProcess.vue'),
        meta: { requiresAuth: true, userType: ['amc'] },
    },
    {
        path: '/file-viewer',
        name: 'File Viewer',
        component: () => import('@/pages/Shared/Utility/FileViewer/FileViewer.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'desk', 'office'] },
    },
    {
        path: '/billing',
        name: 'AMC Billing',
        component: () =>
            import('@/pages/Shared/CustomerSettings/CompanySettings/BillingSettings/BillingSettingsPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['amc', 'desk', 'office'],
            role: ['Admin', 'Super Admin'],
            pageEnabled: 'settings.billing',
        },
    },
    {
        path: '/appraisers',
        component: () => import('@/pages/AMC/Appraisers/AppraiserAmcPages.vue'),
        meta: {
            requiresAuth: true,
            userType: ['appraiser', 'amc', 'office', 'desk'],
            permissions: ['Appraisers', 'Pending Documents', 'Exclusion Lists'],
        },
        children: [
            {
                path: '',
                name: 'Appraisers',
                component: () => import('@/pages/AMC/Appraisers/Appraisers/AppraiserSearch/AppraiserSearch.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc', 'office', 'desk'],
                    permissions: ['Appraisers', 'Pending Documents', 'Exclusion Lists'],
                    title: 'Appraisers',
                },
                beforeEnter: (to, from, next) => {
                    return customerPages.value.vendors.appraisers ? next() : next('/appraisers/amcs')
                },
            },
            {
                path: 'static',
                name: 'Appraiser Static',
                component: () => import('@/pages/AMC/Appraisers/Appraisers/AppraisersPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc'],
                    permissions: ['Appraisers', 'Pending Documents', 'Exclusion Lists'],
                    title: 'Vendor Static',
                },
            },
            {
                path: 'offices',
                name: 'Appraiser Offices',
                component: () => import('@/pages/AMC/Appraisers/Offices/AppraiserOfficesPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc'],
                    permissions: ['Appraisers', 'Pending Documents', 'Exclusion Lists'],
                    title: 'Vendor Offices',
                },
            },
            {
                path: 'public-directory',
                name: 'Appraiser Directory',
                component: () => import('@/pages/AMC/Appraisers/Directory/DirectorySearch/DirectorySearch.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc'],
                    permissions: ['Pending Documents', 'Exclusion Lists'],
                },
            },
            {
                path: 'view-panel',
                name: 'View Panel',
                component: () => import('@/pages/AMC/Tools/ViewPanel/ViewPanelPageRouter.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'exclusionary',
                name: 'Exclusionary',
                component: () => import('@/pages/AMC/Appraisers/ExclusionaryList/ExclusionaryListsPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc'],
                    permissions: ['Pending Documents', 'Exclusion Lists'],
                },
            },
            {
                path: 'exclusionary/:id',
                name: 'Exclusionary Details',
                component: () => import('@/pages/AMC/Appraisers/ExclusionaryList/ExclusionDetailPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc'],
                    permissions: ['Pending Documents', 'Exclusion Lists'],
                },
            },
            {
                path: 'exclusion-matches',
                name: 'Exclusionary Matches',
                component: () => import('@/pages/AMC/Appraisers/ExclusionMatch/ExclusionMatchesPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc'],
                    permissions: ['Pending Documents', 'Exclusion Lists'],
                },
            },
            {
                path: 'exclusion-search',
                name: 'Exclusionary Search',
                component: () => import('@/pages/AMC/Tools/ExclusionSearch/ExclusionSearchPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'documents',
                name: 'Pending Documents',
                component: () => import('@/pages/AMC/Appraisers/PendingDocuments/AppraiserPendingDocumentsProcess.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['appraiser', 'amc'],
                    permissions: ['Pending Documents', 'Exclusion Lists'],
                },
            },
            {
                path: 'workday-exports',
                name: 'Workday Exports',
                component: () => import('@/pages/AMC/Appraisers/WorkdayExportsPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'ascent'], permissions: [] },
            },
            {
                path: 'panel-assignment',
                name: 'Panel Management',
                component: () => import('@/pages/Shared/Vendors/AssignmentRules/PanelManagementRouter.vue'),
                meta: { requiresAuth: true, userType: ['desk', 'office', 'amc', 'ascent'], permissions: [] },
            },
            {
                path: 'amcs',
                name: 'AMC Management',
                component: () => import('@/pages/Shared/Vendors/AMC/Search/AMCSearch.vue'),
                meta: { requiresAuth: true, userType: ['desk', 'ascent'], permissions: [] },
            },
        ],
    },

    {
        path: '/lenders',
        component: () => import('@/pages/AMC/Lenders/LenderAmcPages.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'desk', 'office'], permissions: ['Clients'] },
        children: [
            {
                path: '',
                name: 'Lenders',
                component: () => import('@/pages/AMC/Lenders/LenderListing/LenderListingPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['amc', 'desk', 'office'],
                    type: 'Lender',
                    permissions: ['Clients'],
                },
                beforeEnter: (to, from, next) => {
                    return customerPages.value.lenders.lender ? next() : next('/lenders/branches')
                },
            },
            {
                path: 'branches',
                name: 'Branches',
                component: () => import('@/pages/AMC/Lenders/Branches/BranchesListingPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['amc', 'desk', 'office'],
                    type: 'Branch',
                    permissions: ['Clients'],
                },
            },
            {
                path: 'brokerages',
                name: 'Brokerages',
                component: () => import('@/pages/AMC/Lenders/Brokers/BrokerListingPage.vue'),
                meta: { requiresAuth: true, userType: ['amc'], type: 'Broker', permissions: ['Clients'] },
            },
            {
                path: 'investors',
                name: 'Investors',
                component: () => import('@/pages/AMC/Lenders/Investors/InvestorListingPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'desk'], permissions: ['Clients'] },
            },
            {
                path: 'groups',
                name: 'Company Groups',
                component: () => import('@/pages/AMC/Lenders/CompanyGroups/CompanyGroupListingPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office'], permissions: ['Clients'] },
            },
            {
                path: 'clients',
                name: 'Clients',
                component: () => import('@/pages/AMC/Lenders/Clients/ClientListingPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'desk', 'office'], permissions: ['Clients'] },
            },
            {
                path: 'teams',
                name: 'Team Allocations',
                component: () => import('@/pages/AMC/Lenders/Teams/TeamAssignmentPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'desk', 'office', 'ascent'], permissions: ['Clients'] },
            },
        ],
    },
    {
        path: '/quality',
        component: () => import('@/pages/AMC/Quality/QualityControlPages.vue'),
        meta: { requiresAuth: true, userType: ['amc'], permissions: ['Quality Control', 'Reconsideration'] },
        children: [
            {
                path: '',
                name: 'Quality Control',
                component: () => import('@/pages/AMC/Quality/QualityControlPage.vue'),
                meta: { requiresAuth: true, userType: ['amc'], permissions: ['Quality Control', 'Reconsideration'] },
            },
            {
                path: 'reconsideration',
                name: 'Reconsiderations',
                component: () => import('@/pages/AMC/Quality/QualityReconsiderationPage.vue'),
                meta: { requiresAuth: true, userType: ['amc'], permissions: ['Reconsideration'] },
            },
            {
                path: 'external',
                name: 'Quality Control - External',
                component: () => import('@/pages/AMC/Quality/OffshoreQuality/OffshoreQCReview.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'quality'], showNav: false },
            },
            {
                path: 'external-dashboard',
                name: 'Quality Control - External Dashboard',
                component: () => import('@/pages/AMC/Quality/OffshoreQuality/Dashboard/OffshoreDashboard.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'quality'], showNav: false },
            },
            {
                path: 'external-files',
                name: 'External Files',
                component: () =>
                    import('@/pages/AMC/Quality/Process/QcReview/Partials/QualityFileViewer/QualityFileViewer.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'quality'], showNav: false },
            },
            {
                path: 'settings',
                component: () => import('@/pages/AMC/Quality/QualitySettings/QualitySettings.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'], permissions: ['Quality Control'] },
                children: [
                    {
                        path: '',
                        beforeEnter: (to, from, next) => next('/quality/settings/questions'),
                    },
                    {
                        path: 'questions',
                        name: 'Question Settings',
                        component: () =>
                            import('@/pages/AMC/Quality/QualitySettings/QuestionSettings/QuestionSettings.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Quality Control'],
                        },
                    },
                    {
                        path: 'lender',
                        name: 'Lender Quality Settings',
                        component: () =>
                            import(
                                '@/pages/AMC/Quality/QualitySettings/LenderOverlaySettings/LenderOverlaySettings.vue'
                            ),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Quality Control'],
                        },
                    },
                    {
                        path: 'overlays',
                        name: 'Overlay Settings',
                        component: () =>
                            import('@/pages/AMC/Quality/QualitySettings/QualityOverlays/QualityOverlays.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Quality Control'],
                        },
                    },
                    {
                        path: 'quality-settings',
                        name: 'Quality Settings',
                        component: () => import('@/pages/AMC/Quality/QualitySettings/Settings/QualitySettingsTab.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Quality Control'],
                        },
                    },
                    {
                        path: 'short-review',
                        name: 'Short Review',
                        component: () =>
                            import(
                                '@/pages/AMC/Quality/QualitySettings/ShortReviewSettings/ShortReviewSettingsPage.vue'
                            ),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Quality Control'],
                        },
                    },
                    {
                        path: 'second-review',
                        name: 'Second Review',
                        component: () =>
                            import('@/pages/AMC/Quality/QualitySettings/SecondReviewSettings/SecondReviewSettings.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Quality Control'],
                        },
                    },
                ],
            },
            {
                path: 'ucdp-submissions',
                name: 'UCDP Submissions',
                component: () => import('@/pages/AMC/Quality/UCDPSubmissions/UCDPSubmissionPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'report-viewer',
                name: 'Quality Report Viewer',
                component: () => import('@/pages/AMC/Quality/QualityReportViewer/QualityReportViewerPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'audit-controllable',
                name: 'Audit Controllable',
                component: () => import('@/pages/AMC/Quality/AuditControllable/AuditControllable.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'viewer',
                name: 'Quality Viewer',
                component: () =>
                    import('@/pages/AMC/Quality/Process/QcReview/Partials/QualityFileViewer/QualityFileViewer.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['amc', 'office', 'desk'],
                    name: 'Quality Viewer',
                },
            },
            {
                path: 'order/:id',
                name: 'Quality Review',
                component: () => import('@/pages/AMC/Quality/Process/QcReview/QcReviewProcess.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
        ],
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/pages/Shared/CustomerSettings/CustomerSettingsPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['amc', 'office', 'desk'],
            permissions: ['Settings'],
        },
    },
    {
        path: '/settings/fees/fee-setup/:id?',
        name: 'Fee Setup',
        component: () => import('@/pages/Shared/CustomerSettings/FeeSettings/FeeSetup/FeeSetupPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['amc', 'office', 'desk'],
            permissions: ['Settings'],
        },
    },
    {
        path: '/settings/dashboards/:id',
        name: 'Edit Dashboard',
        component: () =>
            import('@/pages/Shared/CustomerSettings/CompanySettings/DashboardSettings/EditDashboardPage.vue'),
        meta: {
            requiresAuth: true,
            userType: ['amc', 'desk', 'office'],
            permissions: ['Settings'],
        },
    },

    {
        path: '/tools',
        component: () => import('@/pages/AMC/Tools/ToolsPages.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'], isSubNav: true },
        children: [
            {
                path: 'password-management',
                name: 'User Password Management',
                component: () => import('@/pages/AMC/Tools/PasswordManagement/PasswordManagementPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'fee-schedule-test',
                name: 'Fee Schedule Test',
                component: () => import('@/pages/AMC/Tools/FeeScheduleTest/FeeScheduleTestPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'new-client-order',
                name: 'New Client Order',
                component: () => import('@/pages/AMC/Tools/NewClientOrder/NewClientOrderPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'view-lender-directives',
                name: 'View Lender Directives',
                component: () => import('@/pages/AMC/Tools/ViewLenderDirectives/ViewLenderDirectives.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'snippets',
                name: 'Snippets',
                component: () => import('@/pages/AMC/Tools/Snippets/SnippetsPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'jarobots',
                name: 'JaroBots',
                component: () => import('@/pages/AMC/Tools/CustomerActions/CustomerActionsPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'avm',
                name: 'AVMs',
                component: () => import('@/pages/AMC/Tools/AVM/AVMPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'uat-emails',
                name: 'View UAT Emails',
                component: () => import('@/pages/AMC/Tools/MailtrapEmailViewer/EmailViewer.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
            {
                path: 'order-entities',
                name: 'Order Entities',
                component: () => import('@/pages/AMC/Tools/Entities/EntitiesPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
            },
        ],
    },

    {
        path: '/accounting',
        component: () => import('@/pages/AMC/Accounting/AccountingPages.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
        children: [
            {
                path: 'client-billing',
                component: () => import('@/pages/AMC/Accounting/ClientBilling/ClientBillingPages.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                beforeEnter: (to, from, next) => {
                    if (to.path !== '/accounting/client-billing') return next()
                    if (customerPages.value.accounting.client.unpaid) {
                        return next('/accounting/client-billing/unpaid')
                    }
                    if (customerPages.value.accounting.client.bulk) {
                        return next('/accounting/client-billing/bulk-payments')
                    }
                    return next()
                },
                children: [
                    {
                        path: 'unpaid',
                        name: 'Unpaid Orders',
                        component: () => import('@/pages/AMC/Accounting/ClientBilling/Unpaid/ClientBillingUnpaid.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Client Billing'],
                        },
                    },
                    {
                        path: 'bulk-payments',
                        name: 'Bulk Payments',
                        component: () => import('@/pages/AMC/Accounting/ClientBilling/BulkPayment/BulkPaymentPage.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Client Billing'],
                        },
                    },
                    {
                        path: 'failed-payment',
                        name: 'Failed Payments',
                        component: () => import('@/pages/AMC/Accounting/ClientBilling/FailedPayment/FailedPayment.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Client Billing'],
                        },
                    },
                    {
                        path: 'paid',
                        name: 'Paid Orders',
                        component: () => import('@/pages/AMC/Accounting/ClientBilling/Paid/ClientBillingPaid.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                    {
                        path: 'over-payment',
                        name: 'Over Payment',
                        component: () => import('@/pages/AMC/Accounting/ClientBilling/OverPayment/OverPayment.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                    {
                        path: 'accounts-receivable',
                        name: 'Accounts Receivable',
                        component: () =>
                            import('@/pages/AMC/Accounting/ClientBilling/AccountsReceivable/AccountsReceivable.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                    {
                        path: 'deferred-revenue',
                        name: 'Deferred Revenue',
                        component: () =>
                            import('@/pages/AMC/Accounting/ClientBilling/DeferredRevenue/DeferredRevenue.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                ],
            },
            {
                path: 'appraiser-payroll',
                component: () => import('@/pages/AMC/Accounting/AppraiserPayroll/AppraiserPayrollPages.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                children: [
                    {
                        path: 'payroll',
                        name: 'Payroll',
                        component: () =>
                            import('@/pages/AMC/Accounting/AppraiserPayroll/Payroll/AppraiserPayrollListPage.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Appraiser Billing'],
                        },
                    },
                    {
                        path: 'run-payroll/:id',
                        name: 'Run Payroll',
                        component: () =>
                            import('@/pages/AMC/Accounting/AppraiserPayroll/Payroll/AppraiserPayrollPendingPage.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                    {
                        path: 'payroll/:id',
                        name: 'View Payroll',
                        component: () =>
                            import('@/pages/AMC/Accounting/AppraiserPayroll/Payroll/AppraiserPayrollReportPage.vue'),
                        meta: {
                            requiresAuth: true,
                            userType: ['amc', 'office', 'desk'],
                            permissions: ['Appraiser Billing'],
                        },
                    },
                    {
                        path: 'manual-check',
                        name: 'Manual Check',
                        component: () =>
                            import('@/pages/AMC/Accounting/AppraiserPayroll/ManualCheck/AppraiserManualCheckPage.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                    {
                        path: 'check-report',
                        name: 'Check Report',
                        component: () =>
                            import('@/pages/AMC/Accounting/AppraiserPayroll/CheckReport/AppraiserCheckReportPage.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                    {
                        path: 'dwolla',
                        name: 'ACH Details',
                        component: () => import('@/pages/AMC/Accounting/AppraiserPayroll/Dwolla/DwollaDetailsPage.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office', 'desk'] },
                    },
                ],
            },
            {
                path: 'workday',
                component: () => import('@/pages/AMC/Accounting/Workday/WorkdayPages.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'office'] },
                children: [
                    {
                        path: 'manual-upload',
                        name: 'Workday Manual Upload UI',
                        component: () => import('@/pages/AMC/Accounting/Workday/WorkdayManualUploadPage.vue'),
                        meta: { requiresAuth: true, userType: ['amc', 'office'] },
                    },
                ],
            },
        ],
    },
]

export default routes
