var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ACard',{attrs:{"elevation":0}},[_c('template',{slot:"top"},[_c('v-app-bar',{staticClass:"pt-5 mb-5",attrs:{"width":"100%","flat":"","dense":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"title pl-0 text-h4 bold"},[_vm._v(" "+_vm._s(_vm.stepText)+" ")]),_c('v-spacer'),(_vm.$ascent.isCustomer())?_c('a-btn-menu',[_c('v-list',{attrs:{"dense":""}},[(
                            _vm.order.status === 'Complete' &&
                            !_vm.override &&
                            _vm.$ascent.featureEnabled('orderScreen.uploadType', 'standard') === 'standard'
                        )?_c('v-list-item',{on:{"click":function($event){_vm.override = true}}},[_c('v-list-item-title',[_vm._v("Override File Upload")])],1):_vm._e(),(_vm.canCancelRevision)?_c('span',[_c('CancelRevisionDialog',{ref:"CancelRevisionDialog"}),_c('AddConditionsToRequestDialog'),_c('v-list-item',{attrs:{"href":'/appraisal/reconsideration/' + _vm.order.id + '/' + _vm.order.incremental.token,"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Start Reconsideration of Value")])],1),(_vm.$ascent.featureEnabled('orderScreen.workflow.qcFollowUp', true))?_c('QcFollowUpRemoveDialog'):_vm._e()],1):_vm._e(),(_vm.order && _vm.order.inspection)?_c('v-list-item',{on:{"click":_vm.cancelInspection}},[_c('v-list-item-title',[_vm._v("Cancel Inspection")])],1):_vm._e(),(_vm.order.status === 'Complete')?_c('v-list-item',{on:{"click":_vm.openSecondInspection}},[_c('v-list-item-title',[_vm._v("Schedule Second Inspection")])],1):_vm._e(),(
                            _vm.order.status === 'Complete' &&
                            _vm.$ascent.customerFeatureEnabled('orderScreen.actions.allowCompleteWithoutUpload')
                        )?_c('v-list-item',{on:{"click":_vm.openCompleteOrderWithoutUploadActionDialog}},[_c('v-list-item-title',[_vm._v("Complete Order Without Upload")])],1):_vm._e(),(_vm.order.status === 'UW Revision')?_c('v-list-item',{on:{"click":_vm.convertToRushUW}},[_c('v-list-item-title',[_vm._v("Convert to UW Rush")])],1):_vm._e(),(_vm.order.status === 'QC Revision')?_c('v-list-item',{on:{"click":_vm.returnAppraiser}},[_c('v-list-item-title',[_vm._v("Return to Appraiser")])],1):_vm._e()],1)],1):_vm._e(),(_vm.user && _vm.user.type === 'appraiser' && _vm.order.status === 'Complete')?_c('a-btn-menu',[_c('v-list-item',{on:{"click":_vm.openSecondInspection}},[_c('v-list-item-title',[_vm._v("Schedule Second Inspection")])],1)],1):_vm._e()],1)],1),(_vm.$ascent.featureEnabled('orderScreen.uploadType', 'standard') === 'standard')?_c('AppraiserAMCCompleteAction'):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.uploadType', 'standard') === 'office')?_c('AppraiserOfficeCompleteAction'):_vm._e(),_c('ScheduleSecondInspectionDialog',{ref:"ScheduleSecondInspectionDialog"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }