













































import { defineComponent } from '@vue/composition-api'
import { appraiserTags } from '@/plugins/Tags'
import { openAssignNotesDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderAppraiser/AppraiserAssignNotesDialog.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    name: 'AppraiserOrderTags',
    setup() {
        return {
            appraiserTags,
            order,
            openAssignNotesDialog,
        }
    },
})
