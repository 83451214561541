var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',{staticClass:"accentWhite",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center mx-8"},[_c('v-col',{staticClass:"my-4"},[_c('h2',[_vm._v("Branch Encompass Folders")])])],1),(_vm.branch)?_c('a-card',{staticClass:"mx-8"},[_c('v-row',[_c('v-col',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('span',{staticClass:"grey--text"},[_vm._v("Folders:")])])],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('strong',[_vm._v("Appraisal:")])]),_c('v-list-item',[_c('strong',[_vm._v("Final Inspection:")])]),_c('v-list-item',[_c('strong',[_vm._v("Insurance:")])]),_c('v-list-item',[_c('strong',[_vm._v("License:")])]),_c('v-list-item',[_c('strong',[_vm._v("SSR:")])]),_c('v-list-item',[_c('strong',[_vm._v("Invoice:")])]),_c('v-list-item',[_c('strong',[_vm._v("Compliance:")])])],1)],1),_c('v-col',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_vm._v(" "+_vm._s(_vm.appraisal)+" ")]),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.final)+" ")]),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.insurance)+" ")]),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.license)+" ")]),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.ssr)+" ")]),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.invoice)+" ")]),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.compliance)+" ")])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[(_vm.$ascent.canDo('Edit Clients'))?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" Edit ")]):_vm._e()],1)],1)],1),_c('v-divider')],1)],1)],1)],1)],1):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('a-card',{attrs:{"title":'Edit Encompass Folders'}},[_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":_vm.appraisal,"name":"Appraisal","color":"secondary"},model:{value:(_vm.appraisal),callback:function ($$v) {_vm.appraisal=$$v},expression:"appraisal"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"Final Inspection","name":"FinalInspection","color":"secondary"},model:{value:(_vm.final),callback:function ($$v) {_vm.final=$$v},expression:"final"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"Insurance","name":"Insurance","color":"secondary"},model:{value:(_vm.insurance),callback:function ($$v) {_vm.insurance=$$v},expression:"insurance"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"License","name":"License","color":"secondary"},model:{value:(_vm.license),callback:function ($$v) {_vm.license=$$v},expression:"license"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"SSR","name":"SSR","color":"secondary"},model:{value:(_vm.ssr),callback:function ($$v) {_vm.ssr=$$v},expression:"ssr"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"Invoice","name":"Appraisal","color":"secondary"},model:{value:(_vm.invoice),callback:function ($$v) {_vm.invoice=$$v},expression:"invoice"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"rules":"required","label":"Compliance","name":"Compliance","color":"secondary"},model:{value:(_vm.compliance),callback:function ($$v) {_vm.compliance=$$v},expression:"compliance"}})],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"btnText":"Save","url":'/v1/branch/' + _vm.branchId + '/option',"submitData":{
                    option_key: 'encompass-folders',
                    option_value: {
                        Appraisal: _vm.appraisal,
                        Final: _vm.final,
                        Insurance: _vm.insurance,
                        License: _vm.license,
                        SSR: _vm.ssr,
                        Invoice: _vm.invoice,
                        Compliance: _vm.compliance,
                    },
                }},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.save}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }