














































































































































































































import { computed, defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { files } from '@/plugins/Files'
import { order } from '@/plugins/order/Order'
import { orderContacts } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts.vue'
import DetailLinkComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailLinkComponent.vue'
import DetailEditComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailEditComponent.vue'
import CustomerVendorComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerVendorComponent.vue'
import CustomerClientComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerClientComponent.vue'
import DetailWithLabelComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailWithLabelComponent.vue'
import { openComplexity } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/PropertyComplexity.vue'
import {
    complexScoreLabel,
    complexScoreValue,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'
import { billingMethods } from '@/types'
import DetailMenuComponent, {
    show,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailMenuComponent.vue'
import { requiresFeeModification } from '@/plugins/order/OrderModification'
import { openBorrowerOutboundsDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderBorrower/BorrowerOutboundsDialog.vue'
import CustomerRequiresModRequest from '@/components/OrderScreens/Customer/CustomerEditComponents/CustomerRequiresModRequest.vue'
import { map } from 'lodash'
import DetailAddComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailAddComponent.vue'
import AscentHelper from '@/helpers/ascent-helper'
import CustomerAssignAction from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerAssignAction.vue'
import { openEditClientFileNumber } from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderClient/Partials/EditClientFileNumberDialog.vue'
import { openEditAccountingOffice } from '@/components/OrderScreens/Office/OrderComponents/OfficePropertyDetails/EditAccountingOfficeDialog.vue'
import PaidStatusChip from '@/components/General/PaidStatusChip.vue'
import { user } from '@/plugins/User'
import {
    entities,
    getEntities,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderEntities/Entities'

type billingLookup = {
    [x in billingMethods]: string
}
export const detailItem: Ref<undefined | string> = ref(undefined)
export const detailTitle: Ref<string> = ref('')

export default defineComponent({
    name: 'CustomerOrderDetails',
    components: {
        CustomerAssignAction,
        CustomerRequiresModRequest,
        DetailMenuComponent,
        DetailWithLabelComponent,
        CustomerClientComponent,
        CustomerVendorComponent,
        DetailEditComponent,
        DetailLinkComponent,
        DetailAddComponent,
        PaidStatusChip,
    },
    setup() {
        document.addEventListener('keydown', (ev: KeyboardEvent) => {
            if ((ev.target as Element).classList.contains('fr-view')) return
            if (ev.code === 'ArrowLeft' && detailItem.value) {
                detailItem.value = undefined
            }
        })

        const billingMethod = computed(() => {
            if (!order.value) return ''
            const items: billingLookup = {
                'borrower-paid': 'Borrower Paid',
                'net-30': 'Invoice',
                'mercury-card': 'Mercury Card',
                'credit-card': 'Credit Card',
                'deferred-card': 'Deferred Mercury Card',
                split: 'Split',
                'company-credit-card': 'Company Card',
                'valuepad-card': 'Valuepad Card',
                cod: 'COD',
            }

            return items[order.value?.billingMethod as billingMethods]
        })

        const detailItemTitle = computed(() => {
            if (detailItem.value === 'CustomerOrderEntities') return 'Entities'
            if (detailItem.value === 'CustomerOrderFiles') return 'Files'
            if (detailItem.value === 'CustomerOrderDuplicates') return 'Duplicates'
            if (detailItem.value === 'CustomerOrderContacts') return 'Contacts'
            if (detailItem.value === 'CustomerEditOrderInstructions') return detailTitle.value
            if (detailItem.value === 'CustomerOrderConditions') return 'Conditions'
            if (detailItem.value === 'CustomerOrderInvoice') return 'Order Fees'
            if (detailItem.value === 'ClientDirectives') return 'Directives'
            if (detailItem.value === 'CustomerEditOrderInvoice') return 'Edit Invoice'
            if (detailItem.value === 'ClientEdit') return 'Client Details'
            if (detailItem.value === 'ChangeAssigner')
                return 'Change ' + AscentHelper.translate('lang.assigner', 'Assigner')
            if (detailItem.value === 'ChangeCSS') return 'Change ' + AscentHelper.translate('lang.rep', 'CSS')
            if (detailItem.value === 'ChangeTeam') return 'Change ' + AscentHelper.translate('lang.team', 'Team')
            if (detailItem.value === 'CommercialOrderDetails') return 'Commercial Details'
            return ''
        })

        const detailItemAddComponent = computed(() => {
            if (detailItem.value === 'CustomerOrderEntities') return 'CustomerOrderAddEntity'
            if (detailItem.value === 'CustomerOrderFiles') return 'CustomerOrderAddFile'
            if (detailItem.value === 'CustomerOrderContacts') return 'CustomerOrderAddContact'
            if (detailItem.value === 'CustomerEditOrderInvoice') return 'AddProductDialog'
            if (detailItem.value === 'CommercialOrderDetails') return 'EditCommercial'
            return ''
        })

        onMounted(() => {
            detailItem.value = undefined
            getEntities()
        })

        const getSource = computed(() => {
            if (order.value?.source === 'ascent') {
                return 'Jaro - ' + order.value?.incremental?.integration?.details.parent_id
            }
            if (
                !['encompass', 'form', 'guild'].includes(order.value?.source as string) &&
                order.value?.incremental?.integration?.integration_id
            ) {
                return order.value.source + ' - ' + order.value.incremental.integration.integration_id
            }
            return order.value?.source
        })

        const handleCloseDetailItem = () => {
            if (detailItem.value === 'CustomerOrderEntities') {
                getEntities()
            }
            detailItem.value = undefined
        }

        const openInstructions = () => {
            detailTitle.value = 'Edit Instructions'
            detailItem.value = 'CustomerEditOrderInstructions'
        }

        const borrowers = computed(() => {
            if (!order.value?.incremental?.borrowers) return ''
            const borrowers = map(order.value?.incremental?.borrowers, 'name')
            return borrowers.join(', ')
        })

        const officeClientFileNumber = computed(() => {
            if (!order.value) return false
            if (!order.value.incremental) return false
            if (!order.value.incremental.integration) return false
            if (!order.value.incremental.integration.type || order.value.incremental.integration.type !== 'Form')
                return false
            if (!order.value.incremental.integration.integration_id) return false
            return order.value.incremental.integration.integration_id
        })

        return {
            files,
            order,
            detailItem,
            detailTitle,
            openComplexity,
            complexScoreLabel,
            complexScoreValue,
            billingMethod,
            show,
            getSource,
            requiresFeeModification,
            borrowers,
            detailItemTitle,
            detailItemAddComponent,
            entities,
            openBorrowerOutboundsDialog,
            orderContacts,
            openInstructions,
            handleCloseDetailItem,
            officeClientFileNumber,
            openEditClientFileNumber,
            openEditAccountingOffice,
            user,
        }
    },
})
