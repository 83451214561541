<template>
    <div>
        <div class="container">
            <div v-if="this.status === 'Not Started'" class="button red" />
            <div v-if="this.status === 'Pending'" class="button yellow" />
            <div v-if="this.status === 'Completed'" class="button green" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LenderOnboardingStatusIndicator',

    props: {
        status: {
            type: String,
            default: '',
        },
    },
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: flex-end;
    width: 80px;
}
.red {
    background-color: red;
}

.button {
    border-radius: 50%;
    height: 15px;
    width: 15px;
}

.yellow {
    background-color: yellow;
}

.green {
    background-color: green;
}
</style>
