



































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { appraiserValue, appraiserOptions, AppraiserOptionsSettings } from '@/pages/Appraiser/Profile/AppraiserProfile'
import EditAppraiserStatusDialog, {
    openAppraiserStatusDialog,
} from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserStatusPartials/EditAppraiserStatusDialog.vue'
import TerminateHoldAppraiserDialog, {
    openTerminateHoldDialog,
} from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserStatusPartials/TerminateHoldAppraiserDialog.vue'
import RemoveHoldTerminationDialog, {
    openRemoveHoldDialog,
} from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserStatusPartials/RemoveHoldTerminationDialog.vue'

/**
 * @name AppraiserStatus
 * Component in the AMC appraiser settings that holds the status and status edits for an appraiser
 *
 * @SetupData appraiserOptionsLocal - local version of the global appraiser options that is being imported in
 * @SeteupComputed canRemoveHoldTermination - computed to determine if the option to remove hold/termination is shown or the options to change status or terminate appraiser is shown
 * @SetupMethod - statusSaved - updates the status when the status is saved
 */

export default defineComponent({
    components: {
        EditAppraiserStatusDialog,
        TerminateHoldAppraiserDialog,
        RemoveHoldTerminationDialog,
    },
    setup() {
        const appraiserOptionsLocal = ref({
            status: '',
        })
        watch(appraiserOptions && appraiserValue, () => {
            if (
                appraiserValue.value &&
                appraiserValue.value.amc_status &&
                appraiserValue.value.amc_status.amc_appraiser_status
            ) {
                appraiserOptions.value.status = appraiserValue.value.amc_status.amc_appraiser_status
                appraiserOptionsLocal.value = {
                    status: appraiserValue.value.amc_status.amc_appraiser_status,
                }
            }
        })

        if (
            appraiserValue.value &&
            appraiserValue.value.amc_status &&
            appraiserValue.value.amc_status.amc_appraiser_status
        ) {
            appraiserOptions.value.status = appraiserValue.value.amc_status.amc_appraiser_status
            appraiserOptionsLocal.value.status = appraiserValue.value.amc_status.amc_appraiser_status
        }

        const statusSaved = () => {
            appraiserOptionsLocal.value = JSON.parse(JSON.stringify(appraiserOptions.value)) as AppraiserOptionsSettings
        }

        const canRemoveHoldTermination = computed(() => {
            return appraiserOptionsLocal.value.status == 'Hold' || appraiserOptionsLocal.value.status == 'Terminated'
        })

        return {
            lender,
            appraiserOptions,
            appraiserOptionsLocal,
            statusSaved,
            openAppraiserStatusDialog,
            openTerminateHoldDialog,
            canRemoveHoldTermination,
            openRemoveHoldDialog,
        }
    },
})
