





















































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { appraiserSettingsId, appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { AppraiserFile } from '@/types'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import AddAppraiserFileDialog from '@/pages/Appraiser/Profile/EditProfile/Partials/Subpartials/AddAppraiserFileDialog.vue'

export default defineComponent({
    components: { AddAppraiserFileDialog },
    props: {
        option: {
            type: Object,
        },
    },

    setup() {
        const files = ref([])
        const getFiles = () => {
            $axios.get('/v1/appraiser/' + appraiserSettingsId.value + '/files?type=customer').then(({ data }) => {
                files.value = data
            })
        }
        onMounted(() => {
            getFiles()
        })

        return {
            appraiserOptions,
            files,
            getFiles,
        }
    },
    methods: {
        getFile(file: AppraiserFile) {
            this.$axios.get('/v1/appraiser/' + this.$route.params.id + '/files/' + file.id).then((response) => {
                window.open(response.data, '_blank')
            })
        },
        deleteFile(file: AppraiserFile) {
            confirm('Delete File', 'Are you sure you want to delete this file?').then((confirm) => {
                if (confirm) {
                    this.$axios
                        .delete('/v1/appraiser/' + this.$route.params.id + '/files/' + file.id)
                        .then(() => {
                            showSnackbar('Successfully deleted.')
                        })
                        .catch(() => {
                            showError('Unsuccessfully deleted.')
                        })
                }
            })
        },
    },
})
