





























































import { ValidationObserver } from 'vee-validate'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from 'axios'

/**
 * @name 'Office Lender Billing Terms'
 * Component description: Is a Lender Option for the Office Billing. Will Display office billing term values with the option to edit in a dialog;
 * @SetupData onMounted() => will get office billing values based off the customer id and set the correct values;
 * @SetupData saveChanges() => Will save the updated option and reset the displayed values to the correct values while closing the dialog;
 * @SetupData billing: The value that is the v-model when editing and saving ;
 * @SetupData billingLocal: The value that is displayed and is reset every time a new option gets saved;
 * @SetupData dialog: The boolean value that will determine if the editing dialog is open or closed;
 * @SetupData creditOptions: The static options a user can set for the billing terms;
 */
export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const dialog = ref<boolean>(false)
        const billing = ref()
        const creditOptions = [
            'Credit Extended',
            'Pay in Advance',
            'Require Retainer',
            'ACH Direct Deposit',
            'Credit Card',
            'Check',
            'N/A',
            'Online Pre-Payment',
            'Personal Deal',
            'COD',
        ]
        const billingLocal = ref()

        onMounted(() => {
            axios.get(`/v1/lender/` + lender.value.id + `/option/office-billing`).then((response) => {
                billing.value = response.data.option_value
                billingLocal.value = response.data.option_value
            })
        })

        const saveChanges = () => {
            dialog.value = false
            billingLocal.value = billing.value
            showSnackbar('Option saved successfully')
        }

        return {
            billing,
            billingLocal,
            saveChanges,
            dialog,
            lender,
            creditOptions,
        }
    },
})
