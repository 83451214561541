










































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { DateTime } from 'luxon'
import { ValidationObserver } from 'vee-validate'
import { order, updateOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { Milestone } from '@/types'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

/**
 * @name WaitingStep
 * Workflow step over inspection has been scheduled. Allows user to reschedule inspection if needed.
 *
 * @SetupData date - date of new inspection date
 * @SetupData time - time of new inspection
 * @SetupData showInspectionDate - determines if the module of changing inspection date is shown or not.
 * @SetupData isSecondInspection - determines if is second inspection or not
 * @SetupData reason - reason for rescheduling the inspection
 *
 * @SetupComputed inspection_date - splits up date and returns in a new format
 * @SetupMethod getInspectionMilestone - looks through milestones on order and returns inspection date if it is there
 * @SetupMethod saveInspection - posts to '/v1/order/order.id/workflow/reschedule' with new inspection date. Refreshes order after posting.
 * @SetupMethod cancelInspection - posts to '/v1/order/' + order.value.id + '/workflow/schedule-cancel' and cancels the inspection
 *
 * @OnMounted - gets the inspection dates if there.
 */

export default defineComponent({
    components: {
        ValidationObserver,
    },
    props: {
        second_inspection: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const modal = ref(false)
        const date = ref('')
        const time = ref('')
        const loading = ref(false)
        const isSecondInspection = ref(false)
        const reason = ref('')

        const why_label = computed(() => {
            if (props.second_inspection) {
                return 'Why is a second inspection needed?'
            } else {
                return 'Why did inspection date change?'
            }
        })

        const inspection_date = computed(() => {
            if (!date.value) return ''

            const [year, month, day] = date.value.split('-')
            return `${month}/${day}/${year}`
        })

        const getInspectionMilestone = () => {
            if (props.second_inspection) return
            if (!order.value) return
            if (!order.value.incremental) return
            const inspectionMilestone = order.value.incremental.milestones.find(
                (milestone: Milestone) =>
                    (milestone.milestone_type == 'Inspection Date' ||
                        milestone.milestone_type == 'Second Inspection Date') &&
                    milestone.type == 'current',
            )
            if (inspectionMilestone === undefined) return

            const timestamp = DateTime.fromISO(inspectionMilestone.milestone_value, { zone: 'UTC' })
            const property = timestamp.setZone('America/Los_Angeles')
            date.value = property.toFormat('yyyy-LL-dd')
            time.value = property.toFormat('HH:mm')
            isSecondInspection.value = inspectionMilestone.milestone_type == 'Second Inspection Date'
        }

        const secondBeforeFirst = () => {
            if (!order.value || !order.value.incremental || !date.value) return false
            const dateOfInspection = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Inspection Date'
            })
            if (!dateOfInspection) return false
            const date1 = new Date(dateOfInspection.milestone_value)
            const date2 = new Date(date.value + 'T' + time.value + ':00')
            if (date2 < date1) {
                return true
            }
            return false
        }

        const saveSecondInspection = () => {
            if (!order.value) return
            if (secondBeforeFirst()) {
                showError('Oops, Second Inspection Date is earlier then first inspection.')
                return
            }
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/schedule-second', {
                    appointment: date.value + ' ' + time.value,
                    note: reason.value,
                })
                .then(() => {
                    if (!order.value) return
                    modal.value = false
                    date.value = ''
                    time.value = ''
                    reason.value = ''
                    loading.value = false
                    showSnackbar('Second Inspection Date Scheduled Successfully')
                    updateOrder()
                    emit('close')
                })
                .catch(() => {
                    loading.value = false
                })
        }

        const saveInspection = () => {
            if (!order.value) return
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/reschedule', {
                    appointment: date.value + ' ' + time.value,
                    note: reason.value,
                    is_second_inspection: isSecondInspection.value,
                })
                .then(() => {
                    if (!order.value) return
                    modal.value = false
                    date.value = ''
                    time.value = ''
                    reason.value = ''
                    loading.value = false
                    showSnackbar('Inspection Date Updated Successfully')
                    updateOrder()
                    emit('close')
                })
                .catch(() => {
                    loading.value = false
                })
        }

        const inspectionSaved = () => {
            updateOrder()
            modal.value = false
            date.value = ''
            time.value = ''
            reason.value = ''
            loading.value = false
            showSnackbar('Second Inspection Date Scheduled Successfully')
            emit('close')
        }

        const cancelInspection = () => {
            confirm('Cancel Inspection?', 'Are you sure you want to cancel this inspection?').then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    $axios
                        .post('/v1/order/' + order.value.id + '/workflow/schedule-cancel')
                        .then(() => {
                            if (!order.value) return
                            if (!order.value.incremental) return
                            updateOrder()
                            order.value.inspection = null
                            order.value.incremental.inspection = null
                            getNotes(order.value.id)
                            showSnackbar('Inspection Cancelled')
                        })
                        .catch(() => {
                            showError('Oops, something went wrong!')
                        })
                }
            })
        }

        onMounted(() => getInspectionMilestone)

        const expanded = ref(false)
        return {
            modal,
            date,
            time,
            reason,
            loading,
            inspection_date,
            saveInspection,
            expanded,
            cancelInspection,
            why_label,
            secondBeforeFirst,
            inspectionSaved,
            saveSecondInspection,
        }
    },
})
