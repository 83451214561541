














import { defineComponent, PropType } from '@vue/composition-api'
import { AppraiserPanel } from '@/types'
import { appraiserStatus, appraiserStatusColor } from '@/pages/Appraiser/Appraiser'
import { quotesArray } from '@/pages/AMC/Quotes/Quotes.ts'

/**
 * @name AssignedPartial
 * Partial in Assign Table that shows the assign status of current appraiser
 *
 * @SetupMethod getAssignStats - takes in current stats and appraiser. Determines that status of that appraiser on the order
 * @SetupMethod getAssignColor - gets color from the assign status
 */

export default defineComponent({
    props: {
        /**
         * Specific appraiser in Appraiser Panel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup() {
        const getAssignStats = (currentStats: string | null, appraiser: AppraiserPanel) => {
            const quoteMapping = {
                Decline: 'Declined - Quote',
                Pending: 'Pending Quote',
                Excluded: 'Quoted',
                Included: 'Quoted',
                Sent: 'Quote To Client',
            }
            if (currentStats === undefined) return
            if (currentStats === null) {
                const quoteType = quotesArray.value.find((quote) => quote.quote.id === appraiser.has_quote)
                if (!quoteType) return ''
                return quoteMapping[quoteType.quote.status]
            }

            const stats = JSON.parse(currentStats)
            const priority = [
                'Better Quote',
                'Recusal',
                'Unresponsive',
                'Complaint',
                'Amc Reassigned',
                'Declined',
                'Conditional Accept',
                'Accepted',
                'Assigned',
            ]
            const assignStatses = [] as string[]

            stats.forEach((stat: { [key: string]: string }) => {
                for (const key in stat) {
                    assignStatses.push(key)
                }
            })

            let foundPriority = priority.find((status) => assignStatses.includes(status))
            if (
                foundPriority === 'Better Quote' ||
                foundPriority === 'Unresponsive' ||
                foundPriority === 'Recusal' ||
                foundPriority === 'Complaint' ||
                foundPriority === 'Amc Reassigned'
            )
                foundPriority = 'Reassigned'

            return foundPriority
        }

        const getAssignColor = (status: string) => {
            if (status === 'Declined' || status === 'Declined - Quote') return 'error'
            if (status === 'Conditional Accept') return 'warning'
            if (status === 'Reassigned') return '#FFD54F'
            if (status === 'Pending Quote') return 'info'
            if (status === 'Quote to Client') return 'secondary'
            return 'primary'
        }

        return {
            appraiserStatus,
            appraiserStatusColor,
            getAssignStats,
            getAssignColor,
        }
    },
})
