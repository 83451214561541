

































import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import {
    orderStartDate,
    updateSearchTable,
    completedStartDate,
    orderEndDate,
    completedEndDate,
    registerStartDate,
    registerEndDate,
    updateAppraiserTable,
    registerDirectoryStartDate,
    registerDirectoryEndDate,
    updateAppraiserDirectoryTable,
} from '@/components/OrderTable/Classes/OrderTable'
import { user } from '@/plugins/User'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
        return {
            user,
        }
    },
    components: {
        ValidationProvider,
    },
    props: {
        rules: {
            type: [Object, String],
            default: '',
        },
        value: {
            type: null,
        },
        orderStart: {
            type: String,
            default: '',
        },
        clear: {
            type: String,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        directory: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerValue: null,
            datemenu: false,
        }
    },
    methods: {
        allowedDates(val: string) {
            if (this.orderStart === '') return true
            return new Date(val).getTime() >= new Date(this.orderStart).getTime()
        },
        updateDates(val: string) {
            if (val === 'registerDirectoryStart') registerDirectoryStartDate.value = ''
            if (val === 'registerDirectoryEnd') registerDirectoryEndDate.value = ''
            if (val === 'completedStartDate') completedStartDate.value = ''
            if (val === 'completedEndDate') completedEndDate.value = ''
            if (val === 'orderStartDate') orderStartDate.value = ''
            if (val === 'orderEndDate') orderEndDate.value = ''
            if (val === 'registerStart') registerStartDate.value = ''
            if (val === 'registerEnd') registerEndDate.value = ''
            updateSearchTable()

            if (this.user && this.user.type !== 'client') {
                if (this.directory) updateAppraiserDirectoryTable()
                else updateAppraiserTable()
            }
        },
    },
    watch: {
        innerValue(newVal) {
            this.$emit('input', newVal)
        },
        value(newVal) {
            this.innerValue = newVal
        },
    },
    created() {
        if (this.value) {
            this.innerValue = this.value
        }
    },
})
