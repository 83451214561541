























import { defineComponent, ref } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadActionButtons from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadActionButtons.vue'
import PreloadPropertyDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSubjectInfo/PreloadPropertyDetails.vue'
import PreloadPublicRecordDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSubjectInfo/PreloadPublicRecordDetails.vue'
import { reviewStep } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadActionButtons,
        PreloadPropertyDetails,
        PreloadPublicRecordDetails,
    },
    setup() {
        const editData = ref(false)
        const editCancelled = ref(false)

        const cancelEdit = () => {
            editCancelled.value = true
            editData.value = false
        }

        const openEditData = () => {
            editData.value = true
            editCancelled.value = false
        }

        const saveEdit = () => {
            editData.value = false
        }
        return {
            editData,
            openEditData,
            cancelEdit,
            saveEdit,
            reviewStep,
            editCancelled,
        }
    },
})
