
















import { defineComponent } from '@vue/composition-api'
import { customerUsers } from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/EscalationTags'

export default defineComponent({
    setup() {
        return {
            customerUsers,
        }
    },
    methods: {
        findUser(id: number) {
            const user = this.customerUsers.find((elem) => elem.id === id)
            if (!user) return
            return user
        },
    },
})
