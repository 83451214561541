import { Ref, ref } from '@vue/composition-api'
import { AscentApi, CompFacet, FixedActionArgs, DataResult } from '@/helpers/AscentDataAdapter'
import { DataStateChangeEventArgs } from '@syncfusion/ej2-grids'
import { OrderCache, PropertyDetails } from '@/types'

export const dataSource = ref({
    count: 0,
    result: [],
} as DataResult)

export const loading = ref(true)
export const updating = ref(false)

export const AdvSearchArray = ref({} as { [x: string]: string[] })
export const AdvSearchData = ref({} as { [x: string]: object })
export const dataSourceSearch = ref({
    count: 0,
    result: [],
} as DataResult)
export const properties = ref([] as object[])
export const subject = ref({} as PropertyDetails)
export const lastUpdateVarComplex = ref('')
export const facetRanges = ref({} as { [key: string]: number[] })
export const keyArray = ref([] as string[])
export const valueArray = ref([] as string[])
export const facetValue = ref<string[]>([])
export const orderToken = ref('')
export const complexLatLng = ref(undefined as { lat: number; lng: number } | undefined)
export const orderProperty = ref(undefined as OrderCache | undefined)
export const expanded = ref([0, 1, 2] as number[])
export const compSearch = ref('')
type Coordinates = number[]

export const polygon = ref([] as Coordinates[])

export const filters = ref([] as CompFacet[])
export const clearAdvFilters = () => {
    facetValue.value = []
}

export const ComplexQuery = ref(undefined as AscentApi | undefined)

export const chunkedFilters: Ref<CompFacet[] | CompFacet[][]> = ref([])

const chunks = (a: CompFacet[], chunkCount: number) => {
    if (a.length > 0) {
        const size = Math.ceil(a.length / chunkCount)
        return Array.from(new Array(Math.ceil(a.length / size)), (_, i) => a.slice(i * size, i * size + size))
    }
    return a
}

const chunkByScreenSize = () => {
    let totalChunks = 1
    const screenWidth = document.body.offsetWidth
    if (screenWidth > 1263) {
        totalChunks = 2
    }
    const filtersArr = JSON.parse(JSON.stringify(filters.value))
    if (filtersArr.length > 0) {
        chunkedFilters.value = chunks(filtersArr, totalChunks)
    }
}

const processResult = (response: DataResult) => {
    subject.value = response.subject as PropertyDetails
    orderProperty.value = response.order
    complexLatLng.value = {
        lat: parseFloat(orderProperty.value?.order.address.latitude as string),
        lng: parseFloat(orderProperty.value?.order.address.longitude as string),
    }
    filters.value = response.facets as CompFacet[]
    if (!Object.keys(facetRanges.value).length) {
        filters.value.forEach((elem) => {
            if (typeof elem.min !== 'undefined') facetRanges.value[elem.key] = [elem.min, elem.max as number]
        })
    }
    if (lastUpdateVarComplex.value.length > 0) {
        for (const key in response.facetsDistribution) {
            if (key === lastUpdateVarComplex.value) {
                Object.keys(response.facetsDistribution).forEach((newKey) => {
                    if (key !== newKey) {
                        AdvSearchData.value[newKey] = (response.facetsDistribution as { [x: string]: object })[newKey]
                    }
                })
            }
        }
    }
    if (lastUpdateVarComplex.value.length === 0) {
        AdvSearchData.value = response.facetsDistribution as {}
    }

    dataSourceSearch.value = {
        result: response.hits,
        count: response.found,
    }
    properties.value = response.hits as object[]
    chunkByScreenSize()
    loading.value = false
}

export const updateCompTable = async (field = '') => {
    if (!ComplexQuery.value) return
    updating.value = true
    ComplexQuery.value
        .setParams({
            facetFilters: Object.values(AdvSearchArray.value),
            q: compSearch.value,
            poly: polygon.value,
            query_by: field,
            location: complexLatLng.value,
        })
        .update()
        .then((response) => {
            if (response === undefined) {
                updating.value = false
                return
            }
            processResult(response)
        })
}

export const actionHandlingOrderCompSearch = async (args: FixedActionArgs) => {
    if (!args || !ComplexQuery.value) return
    loading.value = true
    ComplexQuery.value
        .setParams({
            facetFilters: Object.values(AdvSearchArray.value),
            q: compSearch.value,
            poly: polygon.value,
        })
        .execute(args as DataStateChangeEventArgs)
        .then((response) => {
            if (response === undefined) {
                loading.value = false
                return
            }
            processResult(response)
        })
}

export const filterItems = (value: string[], array: {}) => {
    if (!value) return
    keyArray.value = keyArray.value.concat(value)
    const merged = Object.values(array).concat.apply([], Object.values(array)) as string[]

    keyArray.value = merged
    valueArray.value = []
    merged.map((elem) => {
        let newString = []
        if (elem.includes('>')) {
            newString = elem.split('>')
        } else {
            newString = elem.split('=')
        }
        valueArray.value.push(newString[0].split(/(?=[A-Z])/).join(' '))
        facetValue.value = keyArray.value
    })
}

export const clearSearchFilters = () => {
    valueArray.value = []
    AdvSearchArray.value = {}
    lastUpdateVarComplex.value = ''
    keyArray.value = []
    filters.value = []
    clearAdvFilters()
    updateCompTable()
}
