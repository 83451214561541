var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('v-data-table',{staticClass:"elevation-0 mb-3",attrs:{"items":_vm.items,"hide-default-footer":"","headers":_vm.headers,"item-key":"appraiser.id","disable-pagination":""},scopedSlots:_vm._u([{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [(item.quote.complete != null && item.quote.complete_type === 'date')?_c('span',[_vm._v(" "+_vm._s(_vm.DateTime.fromSQL(item.quote.complete, { zone: 'local', }).toFormat('EEE, MMM d'))+" ")]):_vm._e(),(item.quote.complete != null && item.quote.complete_type === 'businessDays')?_c('span',[_vm._v(" "+_vm._s(item.quote.complete)+" Business Days ")]):_vm._e()]}},{key:"item.client_deadline",fn:function(ref){
var item = ref.item;
return [(item.quote.client_deadline != null && _vm.order.propertyType === 'Residential')?_c('span',[_vm._v(" "+_vm._s(_vm.DateTime.fromSQL(item.quote.client_deadline, { zone: 'local', }).toFormat('EEE, MMM d'))+" ")]):_vm._e(),(item.quote.client_deadline != null && _vm.order.propertyType === 'Commercial')?_c('span',[_vm._v(" "+_vm._s(item.quote.client_deadline)+" Business Days ")]):_vm._e()]}},{key:"item.quote.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quote.fee)+" ")]}},{key:"item.quote.amc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quote.amc)+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"small mb-0"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":3,"ellipsis":"..."},domProps:{"innerHTML":_vm._s(item.quote.note)},scopedSlots:_vm._u([{key:"after",fn:function(ref){
var toggle = ref.toggle;
var clamped = ref.clamped;
var expanded = ref.expanded;
return (clamped || expanded)?_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"primary"},on:{"click":toggle}},[(clamped)?_c('span',[_vm._v("Expand")]):_vm._e(),(expanded)?_c('span',[_vm._v("Collapse")]):_vm._e()]):_vm._e()}}],null,true)})],1)]}},{key:"item.include",fn:function(ref){
var item = ref.item;
return [(item.quote.status === 'Excluded')?_c('v-menu',{attrs:{"value":_vm.open === item,"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"warning darken-1","dark":""},on:{"click":function($event){_vm.open = item}}},'v-btn',attrs,false),[_vm._v("Excluded")])]}}],null,true)},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-text',[_c('p',[_vm._v("Client Deadline:")]),(_vm.order.propertyType === 'Residential')?_c('v-date-picker',{attrs:{"rules":"required","min":_vm.DateTime.local().toISODate()},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}):_vm._e(),(_vm.order.propertyType === 'Commercial')?_c('a-text-field',{attrs:{"rules":"required|numeric","label":"Business Days","hint":"Total client deadline business days","prepend-inner-icon":"$vuetify.icons.calendar"},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}):_vm._e(),_c('a-text-field',{attrs:{"rules":'required|numeric',"color":"secondary","label":"AMC Fee","prepend-inner-icon":"$vuetify.icons.dollarSign","name":"fee"},model:{value:(_vm.amc),callback:function ($$v) {_vm.amc=$$v},expression:"amc"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('a-btn-cancel',{on:{"click":function($event){_vm.open = false}}}),_c('a-btn-confirm',{attrs:{"disabled":invalid || _vm.client == null},on:{"click":function($event){return _vm.include(item, invalid)}}},[_vm._v(" Save ")])],1)],1)]}}],null,true)})],1):_vm._e(),(item.quote.status === 'Included')?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.exclude(item)}}},[_vm._v("Included")]):_vm._e(),(item.quote.status === 'Pending')?_c('v-btn',{attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.completeQuote(item)}}},[_vm._v(" Complete ")]):_vm._e(),(item.quote.status === 'Sent')?_c('span',[_vm._v("Sent to Client - "+_vm._s(item.quote.details))]):_vm._e()]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('tr',[_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pa-0",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('a-editor',{attrs:{"title":"Note to Client","placeholder":"Note to Client","label":"note","uuid":"QuoteTableEditor"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('v-col',{staticClass:"text-center mb-6",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"secondary","block":"","depressed":"","disabled":invalid ||
                                        !_vm.items.filter(function (elem) { return elem.quote.status === 'Included'; }).length > 0},on:{"click":function($event){$event.stopPropagation();return handleSubmit(_vm.returnQuotes)}}},[_vm._v(" Return Quotes to Client ")])],1)],1)],1)])]}}],null,true)})]}}],null,true)}),_c('complete-quote',{ref:"completeQuote"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }