


















































































import { defineComponent, ref, onBeforeMount } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadActionButtons from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadActionButtons.vue'
import { preloadData, reviewStep } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadActionButtons,
    },
    setup() {
        const editData = ref(false)
        const editLender = ref('')
        const editLenderAddress = ref('')
        const hasSublender = ref(false)

        const setSublenderData = () => {
            if (preloadData.value.primary.subLenderName) {
                hasSublender.value = true
            }

            if (!order.value || !order.value.incremental || !order.value.incremental.sublender) {
                return
            }

            hasSublender.value = true

            if (!preloadData.value.primary.subLenderName) {
                preloadData.value.primary.subLenderName = order.value.incremental.sublender.name
            }

            if (
                !order.value.incremental.sublender.address ||
                order.value.incremental.sublender.address.full_address_formatted == undefined
            ) {
                return
            }

            if (!preloadData.value.primary.subLenderAddress) {
                preloadData.value.primary.subLenderAddress =
                    order.value.incremental.sublender.address.full_address_formatted
            }
        }

        const openEditData = () => {
            editLender.value = hasSublender.value
                ? preloadData.value.primary.subLenderName
                : preloadData.value.primary.lenderName
            editLenderAddress.value = hasSublender.value
                ? preloadData.value.primary.subLenderAddress
                : preloadData.value.primary.lenderAddress
            editData.value = true
        }

        const removeEdit = () => {
            editLender.value = ''
            editLenderAddress.value = ''
            editData.value = false
        }

        const saveEdit = () => {
            if (hasSublender.value) {
                preloadData.value.primary.subLenderName = editLender.value
                preloadData.value.primary.subLenderAddress = editLenderAddress.value
                removeEdit()
                return
            }

            preloadData.value.primary.lenderName = editLender.value
            preloadData.value.primary.lenderAddress = editLenderAddress.value

            removeEdit()
        }

        onBeforeMount(() => {
            setSublenderData()
        })

        return {
            preloadData,
            editData,
            removeEdit,
            editLender,
            editLenderAddress,
            openEditData,
            saveEdit,
            reviewStep,
            order,
            hasSublender,
        }
    },
})
