














































































































































































































import { ValidationObserver } from 'vee-validate'
import { defineComponent } from '@vue/composition-api'
import { Quote, CreditCard as CreditCardType } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import CreditCard from '@/components/General/CreditCard.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'

interface Product {
    quote?: Quote
    appraiser_value: string
    amc_value: string
}

interface Detail {
    token: string
    card: CreditCardType
    expMonth: string
    expYear: string
}

export default defineComponent({
    components: { ValidationObserver, CreditCard },
    setup() {
        return { order }
    },
    props: {},
    data() {
        return {
            answer: '',
            chargeAmount: 0,
            charged: 0,
            loading: false,
            card: {
                id: 0,
            },
            error: null,
            charging: false,
            validatingCard: false,
            headers: [
                {
                    text: 'Type',
                    value: 'source',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Date',
                    value: 'created_at',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    align: 'right',
                    sortable: false,
                },
            ],
        }
    },
    mounted() {
        if (!order.value || !order.value?.incremental) return
        order.value.incremental.cards.unshift({
            cc_label: 'Add New Card',
            id: 0,
        })
    },
    computed: {
        remainingClientFee(): number | string | undefined {
            if (!order.value) return undefined
            if (order.value.paid == null) return AscentHelper.currency(order.value.fee)
            if (order.value.fee < order.value.paid) return 0
            return AscentHelper.currency(order.value.fee - order.value.paid)
        },
        amcTotal(): number | null {
            if (!order.value || !order.value?.incremental) return null
            if (orderProducts.value.length == 0) return 0
            const quoted = orderProducts.value.find((elem) => {
                return elem.quote
            })
            if (typeof quoted != 'undefined') return null
            return orderProducts.value.reduce((accumulator: number, currentValue) => {
                return accumulator + parseFloat(currentValue.amc_value as string)
            }, 0)
        },
        appraiserTotal(): number | null {
            if (!order.value || !order.value?.incremental) return null
            if (orderProducts.value.length == 0) return 0
            const quoted = orderProducts.value.find((elem) => {
                return elem.quote
            })
            if (typeof quoted != 'undefined') return null
            return orderProducts.value.reduce((accumulator: number, currentValue) => {
                return accumulator + parseFloat(currentValue.appraiser_value as string)
            }, 0) as unknown as number | null
        },
        total(): number {
            if (this.amcTotal && this.appraiserTotal) return this.amcTotal + this.appraiserTotal
            return 0
        },
    },
    methods: {
        cardAdded(details: Detail) {
            if (!order.value) return
            this.loading = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/card', {
                    token: details.token,
                    card: details.card,
                    expMonth: details.expMonth,
                    expYear: details.expYear,
                })
                .then(() => {
                    this.loading = false
                    refreshOrder()
                    this.card = {
                        id: 0,
                    }
                    showSnackbar('Card Saved')
                })
        },
        chargeCard() {
            this.charging = true
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/payment/' + this.card.id + '/charge', {
                    amount: this.chargeAmount,
                })
                .then((response) => {
                    if (response.data.result) {
                        showSnackbar(
                            'Card Charged Successfully for $' +
                                this.chargeAmount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }),
                        )
                        this.error = null
                        refreshOrder()
                        this.card = {
                            id: 0,
                        }
                        this.charged = this.charged + this.chargeAmount
                        this.chargeAmount = 0

                        if (this.remainingClientFee === 0) this.processClientApproval()
                    } else {
                        this.error = response.data.message
                        this.charging = false
                    }
                })
                .catch(() => {
                    this.charging = false
                })
        },
        processClientApproval() {
            if (!order.value) return
            this.$axios.post('/v1/order/' + order.value.id + '/workflow/client-approve').then(() => {
                if (!order.value) return
                refreshOrder()
                showSnackbar('Approval Submitted')
            })
        },
        cancelOrder() {
            confirm('Cancel Order?', 'Are you sure you want to cancel this order immediately?').then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    this.$axios.post('/v1/order/' + order.value.id + '/workflow/client-decline').then(() => {
                        refreshOrder()
                        showSnackbar('Order Cancellation Submitted')
                    })
                }
            })
        },
        addCard() {
            this.validatingCard = true
            const credit = this.$refs.credit as Vue & { addCard: () => void }
            credit.addCard()
        },
    },
})
