






import { defineComponent } from '@vue/composition-api'
import {
    selectedComps,
    selectedCompsAll,
    Comparable,
    currentProperty,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'

export default defineComponent({
    props: {},

    setup() {
        const updateColor = (item: Comparable) => {
            currentProperty.value = item
            selectedComps.value = item.id
            selectedCompsAll.value = item
        }

        return {
            updateColor,
        }
    },
})
