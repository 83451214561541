


















import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { Milestone } from '@/types'
import EditMilestoneDialog, {
    openEditMilestone,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/EditMilestoneDialog.vue'

/**
 * @name AppraiserSecondInspection
 * Component for appraiser to show second inspection if there is one. Also allows appraiser to reschedule the second inspection
 *
 * @SetupComputed secondInspectionMilestone - Checks milestones on the incremental order to find if there is a second inspection scheduled or not
 */

export default defineComponent({
    components: { EditMilestoneDialog },
    setup() {
        const secondInspectionMilestone = computed(() => {
            if (!order.value || !order.value?.incremental) return
            const date = order.value?.incremental.milestones.find(
                (milestone: Milestone) =>
                    milestone.type == 'current' && milestone.milestone_type == 'Second Inspection Date',
            )

            if (date) {
                const otherDate = new Date(date.milestone_value)
                return otherDate.getTime()
            }

            return 0
        })

        return {
            secondInspectionMilestone,
            order,
            openEditMilestone,
        }
    },
})
