


















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { Appraiser, AppraiserPanel, PanelAppraiser } from '@/types'
import {
    allowSelect,
    selectedAppraiser,
    deadlinesRequired,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { openAssignDialog } from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignDialog.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    name: 'OfficeAssignButton',
    props: {
        /**
         * Appraiser details
         */
        appraiser: {
            type: Object as PropType<Appraiser>,
        },
        /**
         * Appraiser details in the Appraiser Panel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup(props) {
        const disabled = computed(() => {
            return Boolean(deadlinesRequired.value && (!order.value?.appraiserDeadline || !order.value?.clientDeadline))
        })

        const isAppraiserEligible = computed(() => {
            return !(!props.item || !props.item.eligible || !props.item.appraiser)
        })

        const eligibilityDetails = computed(() => {
            if (!props || !props.item || !props.item.eligible) return ''
            return props.item.eligible.details
        })

        const assignOrder = () => {
            if (!props || !props.item || !props.item.appraiser) return
            selectedAppraiser.value = props.item.appraiser.id
            openAssignDialog({
                appraiser: props.item.appraiser as PanelAppraiser,
                details: eligibilityDetails.value as string,
            })
        }

        return {
            allowSelect,
            eligibilityDetails,
            isAppraiserEligible,
            assignOrder,
            disabled,
        }
    },
})
