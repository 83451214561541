import { computed, ref, watch } from '@vue/composition-api'
import { order, refreshOrder } from '@/plugins/order/Order'
import { files } from '@/plugins/Files'
import $axios from '@/plugins/axios'
import { PreloadData } from '@/types'
import { dialog } from '@/components/OrderScreens/Office/OrderComponents/Workflows/PreloadReviewDialog.vue'

export const step = ref(1)
export const reviewStep = ref(false)
export const finalReview = ref(false)
export const loadReview = ref(false)
export const reviewerGetNext = ref(false)
export const preloadCount = ref(0)
export const preloadReviewSteps = ref(false)
export const newSteps = [
    {
        name: 'Subject Info',
        component: 'PreloadSubjectInfo',
    },
    {
        name: 'Contract',
        component: 'PreloadContract',
    },
    {
        name: 'Sale History',
        component: 'PreloadSaleHistory',
    },
    {
        name: 'Zoning',
        component: 'PreloadZoning',
    },
    {
        name: 'Review and Submit',
        component: 'PreloadReview',
    },
    {
        name: 'Final',
        component: 'PreloadFinalScreen',
    },
]

export const initialSteps = ref(JSON.parse(JSON.stringify(newSteps)))

export const preloadSteps = computed(() => {
    loadReview.value = false
    if (!preloadReviewSteps.value) initialSteps.value = JSON.parse(JSON.stringify(newSteps))
    if (!order.value) return initialSteps.value
    if (
        (order.value.loanPurpose && order.value.loanPurpose !== 'Purchase') ||
        (order.value.loan_purpose && order.value.loan_purpose !== 'Purchase')
    ) {
        initialSteps.value.splice(1, 1)
    }
    return initialSteps.value
})

watch(step, () => {
    if (step.value >= preloadSteps.value.length - 1) reviewStep.value = true
    if (step.value < preloadSteps.value.length - 1) reviewStep.value = false
    if (step.value == preloadSteps.value.length) finalReview.value = true
})

export const purchaseContractUrl = ref('')

export const getPurchaseContractUrl = () => {
    if (!order.value) return
    const purchaseContract = files.value.find((elem) => elem.type == 'Purchase Contract')
    if (!purchaseContract) return
    $axios.get(`/v1/file/${purchaseContract.id}`).then((response) => {
        purchaseContractUrl.value = response.data.url
    })
}

export const getPreloadCounts = () => {
    $axios.get('v1/order/action/get-preload-count').then((response) => {
        preloadCount.value = response.data
    })
}

export const preloadData = ref({} as PreloadData)

const resetPreload = () => {
    preloadData.value = {
        primary: {
            address: '',
            city: '',
            state: '',
            zipcode: '',
            borrowers: [],
            county: '',
            parcelNumber: '',
            neighborhoodName: '',
            mapReference: '',
            associationYN: '',
            associationFee: '',
            AssociationFeeFrequency: '',
            assignmentType: [],
            lenderName: '',
            lenderAddress: '',
            subLenderName: '',
            subLenderAddress: '',
            isOfferedForSaleInLast12Months: '',
            datasource: '',
            listPrice: '',
            listingContractDate: '',
            mlsListingNumber: '',
            listingId: '',
            listingType: '',
            standardStatus: '',
            closeDate: '',
            closePrice: '',
            daysOnMarket: '',
            modificationTimestamp: '',
            pricePerSquareFeet: '',
            salesConcessions: '',
            concessionsComments: '',
            viewYN: '',
            view: '',
            designStyle: '',
            numberOfStories: '',
            attachmentType: '',
            condition: '',
            totalRoomCount: '',
            totalBedroomCount: '',
            totalBathroomFullCount: '',
            totalBathroomHalfCount: '',
            mainLevelBedrooms: '',
            aboveGradeFinishedArea: '',
            aboveGradeFinishedAreaUnits: '',
            basementTotalArea: '',
            basementFinishedArea: '',
            basementType: '',
            basementExitType: '',
            heatingType: '',
            coolingType: '',
            heating: '',
            cooling: '',
            garageYN: '',
            garageSpaces: '',
            carStorageType: '',
            site: '',
            grossLivingArea: '',
            actualAge: '',
            pool: '',
            porchPatioDeck: '',
            fireplacesTotal: '',
            fireplaceYN: '',
            fireplaceFeatures: '',
            qualityConstruction: '',
            mlsBoard: '',
            taxYear: '',
            reTaxes: '',
            assessments: '',
            propertyRights: '',
            propertyRightsDetails: '',
            pud: '',
            sub_street: '',
            propertyType: '',
        },
        publicRecord: {
            lotSizeSquareFeet: '',
            assessedValue: '',
            assessedYear: '',
            improvementsValue: '',
            landValue: '',
            bathroomsTotalInteger: '',
            bedroomsTotal: '',
            belowGradeFinishedArea: '',
            belowGradeTotalArea: '',
            garageSpaces: '',
            garageArea: '',
            carStorageType: '',
            lastSaleDate: '',
            lastSaleRecordingDate: '',
            lastSalePrice: '',
            lastSaleBuyerName: '',
            lastSaleSellerName: '',
            lastSaleType: '',
            livingArea: '',
            storiesDescription: '',
            ownerName: '',
            ownerOccupied: false,
            architecturalStyle: '',
            propertySubType: '',
            distressYN: '',
            saleDocType: '',
            censusTractId: '',
            roomsTotal: '',
            yearBuilt: '',
            parcelNumber: '',
            taxLegalDescription: '',
            legalSubdivisionName: '',
            lastSaleRecordingDocumentId: '',
            legalDescription: '',
        },
        zoneomicData: {
            zone: '',
            zoneName: '',
        },
        comparables: {
            TotalComparables: '',
            AverageCurrentListingPrice: '',
            AverageSoldPrice: '',
        },
        listing_history: [],
        contractData: {
            contractAnalyzed: '',
            reasonContractNotAnalyzed: '',
            contractPrice: '',
            dateOfContract: '',
            ownerSellingProperty: '',
            dataSources: '',
            financialAssistance: '',
            financialAssistanceAmount: '',
            itemsToBePaid: '',
            contractSignature: 'No',
        },
        sale_history: [],
        zoning: {
            dimensions: '',
            area: '',
            shape: '',
            classification: '',
            description: '',
            compliance: '',
            complianceDescription: '',
        },
        plat_map: {
            LotShape: '',
            LotSizeSquareFeet: '',
            LotSizeAcres: '',
            MajorDimensionsString: '',
            url: '',
        },
    }
}

export const closePreload = () => {
    if (!reviewerGetNext.value) refreshOrder()
    if (reviewerGetNext.value) getPreloadCounts()
    resetPreload()
    dialog.value = false
    loadReview.value = false
    reviewStep.value = false
    finalReview.value = false
    step.value = 1
    preloadReviewSteps.value = false
    initialSteps.value = JSON.parse(JSON.stringify(newSteps))
}
