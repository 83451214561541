




































































































































































































































import RefundCard from '@/components/OrderScreens/Amc/AMCActions/Payment/RefundCard.vue'
import CancelCardPayment from '@/components/OrderScreens/Amc/AMCActions/Payment/CancelCardPayment.vue'
import CaptureCardPayment from '@/components/OrderScreens/Amc/AMCActions/Payment/CaptureCardPayment.vue'
import EditCheck from '@/components/OrderScreens/Amc/AMCActions/Payment/EditCheck.vue'
import EditACH from '@/components/OrderScreens/Amc/AMCActions/Payment/EditACH.vue'
import { computed, defineComponent } from '@vue/composition-api'
import Vue from 'vue'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    components: {
        RefundCard,
        EditCheck,
        EditACH,
        CancelCardPayment,
        CaptureCardPayment,
    },
    setup() {
        const allowRefund = computed(() => {
            if (
                !order.value ||
                !order.value.incremental ||
                !order.value.incremental.appraiser ||
                !order.value.incremental.appraiser.options
            )
                return false
            return order.value.incremental.appraiser.options.find((opt) => {
                return opt.option_key === 'allow_card_refund'
            })?.option_value
        })

        return {
            allowRefund,
        }
    },
    data() {
        return {
            dialog: false,
            payment: null as unknown as null | PaymentItem,
            user: user.value,
        }
    },
    methods: {
        open(payment: PaymentItem) {
            this.payment = payment
            this.dialog = true
        },
        close() {
            this.dialog = false
            this.payment = null
        },
        editCheck(item: PaymentItem) {
            const check = this.$refs.check as Vue & { open: (item: PaymentItem) => void }
            check.open(item)
            this.dialog = false
        },
        editACH(item: PaymentItem) {
            const ach = this.$refs.ach as Vue & { open: (item: PaymentItem) => void }
            ach.open(item)
            this.dialog = false
        },
        refundCard(item: PaymentItem) {
            const refund = this.$refs.refund as Vue & { open: (item: PaymentItem) => void }
            refund.open(item)
            this.dialog = false
        },
        cancelCardPayment(item: PaymentItem) {
            const cancel = this.$refs.cancel as Vue & { open: (item: PaymentItem) => void }
            cancel.open(item)
            this.dialog = false
        },
        captureCardPayment(item: PaymentItem) {
            const capture = this.$refs.capture as Vue & { open: (item: PaymentItem) => void }
            capture.open(item)
            this.dialog = false
        },
    },
})
