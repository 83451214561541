























































import { defineComponent, ref, watch } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserSettingsId, appraiserOptions, appraiserValue } from '@/pages/Appraiser/Profile/AppraiserProfile'
import ReviewsRole from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserRolePartials/ReviewsRole.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import axios from '@/plugins/axios'

const error = ref('')

export default defineComponent({
    components: { ReviewsRole },
    props: {
        option: {
            type: Object,
        },
    },

    setup(props, { root }) {
        const appraiserRoles = [
            { title: 'Preload', optionValue: 'preload', reviewsRole: { enabled: false } },
            {
                title: 'Data Analyst',
                optionValue: 'data_analyst',
                reviewsRole: { enabled: true, role: 'data', name: 'Data Analyst' },
            },
            {
                title: 'Inspections',
                optionValue: 'inspection',
                reviewsRole: { enabled: true, role: 'inspection', name: 'Inspection' },
            },
            {
                title: 'Reviews',
                optionValue: 'reviews',
                reviewsRole: { enabled: true, role: 'reviews', name: 'Reviews' },
            },
            { title: 'A-Z', optionValue: 'full', reviewsRole: { enabled: false } },
            { title: 'Supervisor', optionValue: 'supervisor', reviewsRole: { enabled: false } },
            { title: 'Trainee', optionValue: 'is_trainee', reviewsRole: { enabled: false } },
        ]

        watch(appraiserOptions, () => {
            if (props.option && props.option.appraiser_roles) {
                appraiserOptions.value.appraiser_roles = props.option.appraiser_roles
            }
        })

        if (props.option && props.option.appraiser_roles) {
            appraiserOptions.value.appraiser_roles = props.option.appraiser_roles
        }

        const save = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'appraiser_roles',
                    option_value: appraiserOptions.value.appraiser_roles,
                })
                .then(() => {
                    showSnackbar('Option updated successfully')
                })
        }

        const createUser = () => {
            confirm(
                'Add user for this appraiser?',
                `Are you sure you want to add a user for this appraiser? We'll use the same name and email to create the user account.`,
            ).then((confirm) => {
                if (confirm) {
                    axios
                        .post('v1/appraiser/' + root.$route.params.id + '/action/invite-appraiser')
                        .then((response) => {
                            if (response.data.result == 'error') {
                                showError(response.data.message)
                            }
                            if (response.data.email) {
                                showSnackbar('Invite successfully sent to ' + response.data.email)
                            }
                        })
                        .catch(() => {
                            showError('Something went wrong!')
                        })
                }
            })
        }

        return {
            error,
            save,
            appraiserOptions,
            appraiserValue,
            createUser,
            appraiserRoles,
        }
    },
})
