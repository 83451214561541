










import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import ChevronProgressItem from '@/components/General/ChevronProgressBar/ChevronProgressItem.vue'
import {
    orderStatuses,
    progressBarInfo,
} from '@/components/OrderScreens/Office/OrderComponents/OrderStatusBar/OrderStatusBar'

export default defineComponent({
    components: {
        ChevronProgressItem,
    },
    setup() {
        return {
            order,
            progressBarInfo,
            orderStatuses,
        }
    },
})
