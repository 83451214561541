















































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { DateTime } from 'luxon'
import { ValidationObserver } from 'vee-validate'
import $axios from '@/plugins/axios'
import { Milestone } from '@/types'
import DateTimeDialog, {
    date,
    time,
} from '@/components/OrderScreens/Appraiser/AppraiserMobileComponents/DateTimeDialog.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'

/**
 * @name AppraiserWaitingStep
 * Waiting step for Appraiser Order Screen after the inspection is scheduled. Allows appraiser to change the inspection date if needed.
 *
 * @SetupData modal - opens the time dialog
 * @SetupData showInspectionDate - Determines if the appraiser wants to reschedule the inspection
 * @SetupData isSecondInspection - Determines if order is in second inspection
 * @SetupData reason - Reason for having to reschedule the inspection
 *
 * @SetupMethod getInspectionMilestone - checks order milestones to grab the date for the inspection.
 * @SetupMethod saveInspection - Posts to /v1/order/order.id/workflow/reschedule with the appointment date and time. Refreshes order after.
 */

export default defineComponent({
    components: {
        ValidationObserver,
        DateTimeDialog,
    },
    setup() {
        const modal = ref(false)
        const loading = ref(false)
        const showInspectionDate = ref(false)
        const isSecondInspection = ref(false)
        const reason = ref('')

        const getInspectionMilestone = () => {
            if (!order.value || !order.value?.incremental) return
            const inspectionMilestone = order.value.incremental.milestones.find(
                (milestone: Milestone) =>
                    (milestone.milestone_type == 'Inspection Date' ||
                        milestone.milestone_type == 'Second Inspection Date') &&
                    milestone.type == 'current',
            )
            if (inspectionMilestone === undefined) return

            const timestamp = DateTime.fromISO(inspectionMilestone.milestone_value, { zone: 'UTC' })
            const property = timestamp.setZone('America/Los_Angeles')
            date.value = property.toFormat('yyyy-LL-dd')
            time.value = property.toFormat('HH:mm')
            isSecondInspection.value = inspectionMilestone.milestone_type == 'Second Inspection Date'
        }

        const saveInspection = () => {
            if (!order.value) return
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/reschedule', {
                    appointment: date.value + ' ' + time.value,
                    note: reason.value,
                    is_second_inspection: isSecondInspection.value,
                })
                .then(() => {
                    modal.value = false
                    reason.value = ''
                    loading.value = false
                    showInspectionDate.value = false

                    refreshOrder()
                    showSnackbar('Inspection Date Updated Successfully')
                })
                .catch(() => {
                    loading.value = false
                })
        }

        onMounted(() => getInspectionMilestone)

        return {
            modal,
            date,
            time,
            reason,
            loading,
            showInspectionDate,
            saveInspection,
        }
    },
})
