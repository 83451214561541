





























import { defineComponent, ref } from '@vue/composition-api'
import AddressFields from '@/global/Form/Combined/AddressFields.vue'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { Address } from '@/types'

export const dialog = ref(false)

export default defineComponent({
    components: {
        AddressFields,
        ValidationObserver,
    },
    setup(_, { emit }) {
        const name = ref('')
        const address = ref({} as Address | object)
        const form = ref({} as InstanceType<typeof ValidationObserver>)

        const sublenderAdded = () => {
            dialog.value = false
            name.value = ''
            address.value = {}
            form.value.reset()
            showSnackbar('Sublender added successfully!')
            emit('sublender-added')
        }

        return {
            dialog,
            name,
            address,
            sublenderAdded,
            lender,
            form,
        }
    },
})
