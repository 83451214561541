<template>
    <a :href="'/order/' + data.id" target="_blank" class="primary--text" v-if="openInNewDialog">
        <strong>{{ data.customer_order_id }}</strong>
    </a>
    <a href="#" class="primary--text" @click.prevent="viewOrder" v-else>
        <strong>{{ data.customer_order_id }}</strong>
    </a>
</template>

<script lang="js">

import {defineComponent} from "@vue/composition-api"
import {getOrder} from '@/plugins/order/Order'

/**
 * @name ColumnOrderId
 * Id column for the order table. Will open up order when id is clicked.
 */

export default defineComponent({
    props: {
        openInNewDialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            data: {}
        }
    },
    methods: {
        viewOrder() {
            getOrder(this.data)
        },
    }
})
</script>
