
























import { defineComponent, ref } from '@vue/composition-api'
import { loadIncremental, order } from '@/plugins/order/Order'
import AppraiserSelect from '@/components/General/AutoCompletes/AppraiserSelect.vue'
import { Appraiser } from '@/types'
import $axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export const traineeDialog = ref(false)
export const openTraineeDialog = () => {
    traineeDialog.value = true
}

export default defineComponent({
    computed: {
        order() {
            return order
        },
    },
    components: { AppraiserSelect },
    setup() {
        const trainee = ref({} as Appraiser)
        const saveTraineeAppraiser = (trainee: Appraiser) => {
            if (!order.value || !trainee.id) return
            $axios
                .post('/v1/order/' + order.value.id + '/action/assign-trainee-appraiser/' + trainee.id)
                .then((response) => {
                    if (!order.value || !order.value.incremental) return

                    showSnackbar(response.data.message)

                    if (traineeDialog.value) {
                        traineeDialog.value = false
                    }
                    loadIncremental()
                })
        }

        return {
            traineeDialog,
            openTraineeDialog,
            trainee,
            saveTraineeAppraiser,
        }
    },
})
