


























































import { defineComponent, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import FeeScheduleSelect from '@/components/General/AutoCompletes/FeeScheduleSelect.vue'
import { Fee } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { Lender } from '@/types'

export default defineComponent({
    components: {
        FeeScheduleSelect,
    },
    setup() {
        const feeSchedule = ref<Fee>((lender.value as Lender).fee_schedule)
        const dialog = ref<boolean>(false)

        const save = () => {
            lender.value.fee_schedule = feeSchedule.value
            dialog.value = false
            showSnackbar('Fee Schedule Updated Successfully')
        }

        return {
            lender,
            dialog,
            feeSchedule,
            save,
        }
    },
})
