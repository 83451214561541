

































































































import axios from 'axios'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { order, directOrder } from '@/plugins/order/Order'
import OrderToolbar from '@/AppLayout/order/OrderToolbar.vue'
import OrderStatusBar from '@/components/OrderScreens/Office/OrderComponents/OrderStatusBar/OrderStatusBar.vue'
import ClientOrderDetails from '@/components/OrderScreens/Client/OrderComponents/ClientOrderDetails.vue'
import ClientOrderFiles from '@/components/OrderScreens/Client/OrderComponents/ClientOrderFiles.vue'
import ClientOrderFeesAndPayments from '@/components/OrderScreens/Client/OrderComponents/ClientOrderFeesAndPayments.vue'
import ClientOrderNotes from '@/components/OrderScreens/Client/OrderComponents/ClientOrderNotes.vue'
import ClientOrderAllContacts from '@/components/OrderScreens/Client/OrderComponents/ClientOrderAllContacts.vue'
import ClientActionComponent from '@/components/OrderScreens/Client/OrderComponents/ClientActionComponent.vue'
import ClientContactsAndParticipants from '@/components/OrderScreens/Client/OrderComponents/ClientContactsAndParticipants.vue'
import ClientOrderInstructions from '@/components/OrderScreens/Client/OrderComponents/ClientOrderInstructions.vue'
import ClientOrderEntities from '@/components/OrderScreens/Client/OrderComponents/ClientOrderEntities.vue'
import {
    entities,
    getEntities,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderEntities/Entities'

/**
 * @name ClientOrderScreen
 * Order Screen for clients/lenders to communicate with the AMC.
 */
export default defineComponent({
    name: 'ClientOrderScreen',
    components: {
        ClientContactsAndParticipants,
        ClientActionComponent,
        ClientOrderAllContacts,
        OrderToolbar,
        ClientOrderDetails,
        ClientOrderEntities,
        ClientOrderFiles,
        ClientOrderFeesAndPayments,
        ClientOrderNotes,
        ClientOrderInstructions,
        OrderStatusBar,
    },
    setup() {
        const orderAdditionalProducts = ref(null)

        const getActionComponent = computed(() => {
            if (!order.value) return
            switch (order.value.status) {
                default:
                    return ''
            }
        })

        const getAdditionalProducts = () => {
            axios
                .get('/v1/settings/order_additional_products')
                .then((res) => {
                    if (res.data && res.data.result) {
                        orderAdditionalProducts.value = res.data.result
                    }
                })
                .catch(() => {
                    orderAdditionalProducts.value = null
                })
        }

        onMounted(() => {
            getAdditionalProducts()
            getEntities()
        })

        return {
            orderAdditionalProducts,
            getActionComponent,
            order,
            directOrder,
            entities,
        }
    },
})
