
































































































import { computed, defineComponent, reactive } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import axios from '@/plugins/axios'
import AscentHelper from '@/helpers/ascent-helper'
import { Milestone } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
const state = reactive({
    type: '',
    date: 0,
    hour: 12,
    minute: '00',
    time: 'AM',
    withBlackoutDates: false,
})
import { order, refreshOrder, updateOrder } from '@/plugins/order/Order'
import { user } from '@/plugins/User'

export const openEditMilestone = (type: string, date: number, blackoutDates: boolean) => {
    state.type = type
    state.withBlackoutDates = blackoutDates
    state.date = AscentHelper.formatUnix(date, 'y-MM-dd', false)
    if (type == 'Second Inspection Date') {
        const tempTime = AscentHelper.formatUnix(date, 't').split(' ')
        state.time = tempTime[1]
        state.hour = parseInt(tempTime[0].split(':')[0])
        state.minute = tempTime[0].split(':')[1]
    }
    // if(state.type == 'Inspection Date') {
    //   const property = date.setZone(this.order.timezone)
    //   this.hour = parseInt(property.toFormat('h'))
    //   this.minute = property.toFormat('mm') === '00' ? '00' : parseInt(property.toFormat('mm'))
    //   this.time = property.toFormat('a')
    //   this.editDate = date.toISODate()
    // }
}

/**
 * @name CustomerEditMilestone
 * Dialog in the AmcOrderScreen that edits a milestone on the order
 *
 * @StateData type - type of milestone that is being edited
 * @StateData date - new date of milestone
 * @StateData hour - new hour of milestone
 * @StateData minute - new minute of milestone
 * @StateData time - am or pm of milestone
 * @StateData withBlackoutDates - determines if blackout dates are accepted or not
 *
 * @SetupComputed appraiserAfterClient - checks if type is Client Deadline and returns if the appraiser deadline is after the client deadline
 * @SetupMethod submit - refreshes order screen after the milestone has been patched to the backend
 * @SetupMethod deleteMilestone - deletes milestone from the order
 * @SetupMethod allowedDates - returns allowed dates user is allowed to choose from
 * @SetupMethod getLocalTime - returns local time of milestone
 */

export default defineComponent({
    name: 'CustomerEditMilestone',
    components: {
        ValidationObserver,
    },
    setup(_, { emit }) {
        const appraiserAfterClient = computed(() => {
            if (state.type === 'Client Deadline') {
                if (!order.value || !order.value?.appraiserDeadline) return
                // if(AscentHelper.canDo('Approve Same Day Due Dates') && (new Date(state.date).getDate() === new Date(order.value.appraiserDeadline).getDate())) return true
                if (new Date(state.date).getDate() === new Date(order.value.appraiserDeadline).getDate()) return true
                if (new Date(state.date) <= new Date(order.value.appraiserDeadline)) return false
            }
            return true
        })

        const appraiserBeforeClient = computed(() => {
            if (state.type === 'Appraiser Deadline') {
                if (!order.value || !order.value?.clientDeadline) return

                if (new Date(state.date) == new Date(order.value.clientDeadline)) return true
                if (new Date(state.date) > new Date(order.value.clientDeadline)) return false
            }
            return true
        })

        const submit = () => {
            const dateString = state.date
            const date = new Date(dateString)
            if (!order.value) return
            switch (state.type) {
                case 'Appraiser Deadline':
                    order.value.appraiserDeadline = date.getTime()
                    break
                case 'Client Deadline':
                    order.value.clientDeadline = date.getTime()
                    break
            }
            refreshOrder()
            emit('close')
        }

        const deleteMilestone = () => {
            let foundMilestone: Milestone = {
                id: 0,
                milestone_type: '',
                milestone_value: '',
            }

            if (state.type === 'Second Inspection Date') {
                foundMilestone = order.value?.incremental?.milestones.find(
                    (milestone: Milestone) => milestone.milestone_type === 'Second Inspection Date',
                ) as Milestone
            }
            axios.delete('/v1/milestone/' + foundMilestone.id).then(() => {
                updateOrder()
                showSnackbar('Inspection date deleted successfully!')
            })
        }

        const allowedDates = (date: number) => {
            if (state.withBlackoutDates) {
                return AscentHelper.isDateBlackedOut(date)
            }
            return true
        }

        const getLocalTime = () => {
            if (['Inspection Date', 'Second Inspection Date'].includes(state.type)) return
            return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
        }

        return {
            state,
            allowedDates,
            openEditMilestone,
            deleteMilestone,
            submit,
            order,
            appraiserAfterClient,
            appraiserBeforeClient,
            user,
            getLocalTime,
        }
    },
})
