


































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { Milestone } from '@/types'

function reset() {
    return {
        dialog: false,
        loading: false,
        reason: null,
        date: '',
        time: null,
        modal: false,
    }
}

/**
 * @name ScheduleSecondInspectionDialog
 * Dialog to schedule a second inspection after first inspection was already done
 */

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
    components: { ValidationObserver },
    data() {
        /**
         * Data includes a reason for why they are scheduling the second inspection
         * Date: when it is scheduled for
         * Time: Time of the second inspection
         * modal: true or false to open the date picker
         */
        return reset()
    },
    computed: {
        secondBeforeFirst() {
            if (!order.value || !order.value.incremental || !this.date) return false
            const dateOfInspection = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Inspection Date'
            })
            if (!dateOfInspection) return false
            const date1 = new Date(dateOfInspection.milestone_value)
            const date2 = new Date(this.date + 'T' + this.time + ':00')
            if (date2 < date1) {
                return true
            }
            return false
        },
    },
    methods: {
        /**
         * Opens dialog
         */
        open() {
            this.dialog = true
        },
        /**
         * Called after the inspection is saved. Refreshes order and resets dialog
         * Endpoint used: "'/v1/order/' + order.id + '/workflow/schedule-second'"
         */
        inspectionSaved() {
            refreshOrder()
            Object.assign(this.$data, reset())
            showSnackbar('Second Inspection Date Scheduled Successfully')
        },
    },
})
