import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/ascent/design',
        name: 'Jaro Style Guide',
        component: () => import('@/pages/Ascent/AscentStyleGuide.vue'),
        meta: { requiresAuth: true },
    },
]

export default routes
