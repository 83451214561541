export const namespaced = true

import $axios from '@/plugins/axios'
import { ref } from '@vue/composition-api'
import { File } from '@/types'

export const files = ref([] as File[])

export const getFiles = async (id: number) => {
    return await $axios.get('/v1/order/' + id + '/file').then((response) => {
        if (!response || !response.data || typeof response.data !== 'object') return
        files.value = response.data
        return
    })
}
