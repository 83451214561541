






























































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { File } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import Vue from 'vue'
import FileUpload from '@/components/General/FileUpload.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { ValidationObserver, FileUpload },

    setup() {
        return {
            lender,
        }
    },
    data() {
        let logo = ''

        if (lender.value) {
            const logoOption = lender.value.options?.find((option) => {
                return option.option_key === 'logo'
            })

            if (logoOption) {
                logo = (logoOption.option_value as string) ?? ''
            }
        }

        return {
            dialog: false,
            updating: false,
            show: false,
            file: '',
            fileError: false,
            fileAdded: false,
            allowed: ['.jpg', '.png', '.jpeg'],
            logo,
            logoOptionId: 0,
        }
    },

    methods: {
        setFileAdded() {
            this.fileAdded = true
            this.show = true
        },
        async saveLogo() {
            if (this.fileAdded) {
                const ClientLogoUpload = this.$refs.ClientLogoUpload as Vue & { triggerUpload: () => void }
                ClientLogoUpload.triggerUpload()
                return
            }
            this.save()
        },
        save() {
            if (this.file == null) {
                this.fileError = true
                return false
            }
            const host = process.env.VUE_APP_CUSTOMER_ASSETS_HOST || 'https://assets.jarodesk.com'
            this.$axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'logo',
                    option_value: host + '/' + this.file,
                })
                .then(() => {
                    this.show = false
                    this.logo = host + '/' + this.file
                    this.$emit('options-updated')
                    this.dialog = false
                    showSnackbar('Logo saved successfully')
                })
        },
        fileUploaded(file: File) {
            this.file = file.path

            this.save()
        },
    },
})
