
































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { order, directOrder } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import axios from '@/plugins/axios'

/**
 * @name OrderAdditionalProduct
 * Dialog to allow user to order another product on the report after the report is completed
 */

export default defineComponent({
    name: 'OrderAdditionalProduct',
    setup() {
        const baseUrl = computed(() => {
            if (user.value?.type === 'office') {
                return '/office/order/' + order.value?.id
            }

            return '/client/order/' + order.value?.id
        })
        const orderAdditionalProducts = ref(null)
        onMounted(() => {
            axios
                .get('/v1/settings/order_additional_products')
                .then((res) => {
                    if (res.data && res.data.result && user.value?.type === 'client') {
                        orderAdditionalProducts.value = res.data.result
                    }
                })
                .catch(() => {
                    orderAdditionalProducts.value = null
                })
        })
        return { order, baseUrl, directOrder, orderAdditionalProducts }
    },
})
