







import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

/**
 * @name ClientOrderInstructions
 * Component in Client Order Screen that shows the instructions on the order.
 */

export default defineComponent({
    name: 'ClientOrderInstructions',
    setup() {
        return { order }
    },
})
