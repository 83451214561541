<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="accentBlack" v-bind="attrs" v-on="on" title="Add Fee" class="float-right mr-3">
                <v-icon dark>$vuetify.icons.plus</v-icon>
            </v-btn>
        </template>

        <ValidationObserver ref="form" v-slot="{ invalid }">
            <ACard title="Add Product">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <a-select-field
                                v-model="line.form"
                                rules="required"
                                label="Product"
                                :items="fees.other"
                                name="product"
                                color="secondary"
                                item-value="id"
                                item-text="name"
                                return-object
                                @change="updateFee"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" v-if="!usesTotalFees">
                            <a-text-field
                                v-model="line.appraiser_value"
                                :rules="'required'"
                                label="Appraiser Fee"
                                name="appraiser fee"
                                color="secondary"
                                prepend-inner-icon="$vuetify.icons.dollarSign"
                                outlined
                            />
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" sm="5" v-if="!usesTotalFees">
                            <a-text-field
                                v-model="line.amc_value"
                                :rules="'required'"
                                label="Our Fee"
                                name="our fee"
                                color="secondary"
                                prepend-inner-icon="$vuetify.icons.dollarSign"
                                outlined
                            />
                        </v-col>
                        <v-col cols="12" v-if="usesTotalFees">
                            <a-text-field
                                v-model="line.appraiser_value"
                                :rules="'required'"
                                label="Total Fee"
                                name="total fee"
                                color="secondary"
                                prepend-inner-icon="$vuetify.icons.dollarSign"
                                outlined
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <a-btn-cancel @click="dialog = false" />
                    <v-btn color="primary" :loading="saving" :disabled="invalid" @click="handleEmit">Add Fee</v-btn>
                </v-card-actions>
            </ACard>
        </ValidationObserver>
    </v-dialog>
</template>

<script lang="js">
import {ValidationObserver} from 'vee-validate'
import {order, fees, getFeeList } from "@/plugins/order/Order";
import {onMounted, ref, computed} from "@vue/composition-api";
import $axios from '@/plugins/axios'
import AscentHelper from "@/helpers/ascent-helper"

export default {
    components: {
        ValidationObserver
    },

    setup(_, {emit}) {

      const dialog = ref(false)
      const saving = ref(false)
      const type = ref(null)
      const line = ref({
        id: null,
        order_id: null,
        product_id: null,
        product_name: null,
        amc_value: null,
        form: null,
        appraiser_value: null
      })

      const usesTotalFees = computed(() => {
          return AscentHelper.customerFeatureEnabled('orderScreen.invoice.feeSummaryType') === 'total_fees'
      })

      const handleEmit = () => {
          if(line.value.amc_value === null) line.value.amc_value = 0
          if(line.value.appraiser_value === null) line.value.appraiser_value = 0
          emit('fee-added', line.value)
          dialog.value = false
      }

      const open = (() => {
        dialog.value = true
        line.value = {
          id: null,
          order_id: null,
          product_id: null,
          product_name: null,
          amc_value: null,
          form: null,
          appraiser_value: null
        }
      })

      const updateFee = ((value) => {
        if (!order.value) return
        $axios.get('/v1/order/' + order.value.id + '/product/' + value.id).then((response) => {
          if (!response.data.quote) {
            line.value.amc_value = response.data.amc
            line.value.appraiser_value = response.data.appraiser
          }

        })
      })

      onMounted( ()  => {
        getFeeList()
      })

      return{
        fees,
        open,
        updateFee,
        dialog,
        saving,
        type,
        line,
        usesTotalFees,
        handleEmit,
      }
    },
}
</script>
