var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"mr-4"},[(!_vm.entryContactsReviewed)?_c('v-list-item',{staticClass:"mx-8 mb-4"},[_c('v-list-item-title',[_c('v-alert',{staticClass:"mr-4 mb-0",attrs:{"text":"","type":"warning","color":"warning"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(
                            _vm.$ascent.userOrCustomerFeatureEnabled(
                                'orderScreen.newOrderScreen',
                                'newOrderScreen',
                                true
                            ) &&
                            !_vm.snapshotOrder &&
                            !['appraiser', 'client'].includes(_vm.user.type)
                        )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openContacts()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.edit")])],1):_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":function($event){_vm.sidebarItem = 'contacts'}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.edit")])],1)]},proxy:true}],null,false,1273739200)},[_vm._v(" No entry contacts found on this order. ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function () {
                        _vm.reviewNotes.push({ note: 'Request Entry Contact', removable: true })
                        _vm.contactNoteAdded = true
                    }}},[_c('v-icon',[_vm._v("$vuetify.icons.plus")])],1)],1)],1):_vm._e(),(!_vm.fhaHasPurchaseContract)?_c('v-list-item',{staticClass:"mx-8 mb-4"},[_c('v-list-item-title',[_c('v-alert',{staticClass:"mr-4 mb-2",attrs:{"text":"","type":"warning","color":"warning"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"small":"","text":"","color":"warning"},on:{"click":function($event){_vm.newFileDialog = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$vuetify.icons.plus")]),_vm._v(" Add File ")],1)]},proxy:true}],null,false,320078631)},[_vm._v(" HARD STOP: FHA Order doesn't have purchase contract. ")]),_c('v-alert',{staticClass:"mr-4 mb-0",attrs:{"text":"","type":"info"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"small":"","text":"","color":"info"},on:{"click":function($event){_vm.clientNoteDialog = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$vuetify.icons.send")]),_vm._v(" Send Note ")],1)]},proxy:true}],null,false,2129378397)},[_vm._v(" Please send a note to the client to get purchase contract. ")]),_c('ReviewSendNoteDialog')],1),_c('v-list-item-action')],1):_vm._e(),(!_vm.filesReviewed && _vm.steps[_vm.currentReviewStep - 1].id === 'files')?_c('v-alert',{staticClass:"my-2",attrs:{"text":"","type":"warning","color":"warning"}},[_vm._v(" Please Review All Files ")]):_vm._e(),(_vm.unreadNotes.length && _vm.steps[_vm.currentReviewStep - 1].id === 'notes')?_c('v-alert',{staticClass:"mr-4 mb-0",attrs:{"text":"","type":"warning","color":"warning"}},[_vm._v(" Please Mark All Notes Read ")]):_vm._e(),(
            _vm.steps[_vm.currentReviewStep - 1].id === 'fees' &&
            !['split', 'borrower-paid', 'mercury-card', 'valuepad-card'].includes(_vm.order.billingMethod) &&
            !(_vm.order.billingMethod == 'deferred-card' && _vm.order.source == 'mercury') &&
            !_vm.lenderBilling.includes(_vm.order.billingMethod) &&
            !(_vm.order.billingMethod === 'credit-card' && _vm.lenderBilling.includes('borrower-paid'))
        )?_c('v-alert',{staticClass:"my-2",attrs:{"text":"","type":"warning","color":"warning"}},[_c('span',[_vm._v("Lender doesn't allow selected billing type. Please change billing type to continue.")]),_c('br'),_c('p',{staticClass:"mt-4 mb-0"},[_vm._v(" Current order billing type: "),_c('b',[_vm._v(_vm._s(_vm.order.billingMethod))])])]):_vm._e(),(_vm.borrowerEmailNeeded && _vm.steps[_vm.currentReviewStep - 1].id === 'fees')?_c('v-list-item',[_c('v-list-item-title',[_c('v-alert',{staticClass:"my-2",attrs:{"text":"","type":"warning","color":"warning"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"color":"warning","icon":""},on:{"click":function($event){_vm.sidebarItem = 'contacts'}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.edit")])],1)]},proxy:true}],null,false,2510923317)},[_vm._v(" No Borrower Email Found, Please Update ")])],1)],1):_vm._e(),(!_vm.billingAlertsReviewed && _vm.steps[_vm.currentReviewStep - 1].id === 'fees')?_c('v-list-item',[_c('v-list-item-title',[_c('v-alert',{attrs:{"text":"","type":"warning","color":"warning"}},[_vm._v("Please Review Billing Alerts")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }