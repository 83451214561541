


























































interface File {
    id: string
    progress: {
        uploadComplete: boolean
    }
}

import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
import AwsS3 from '@uppy/aws-s3'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
    props: {
        url: {
            default: 'misc',
            type: String,
        },
        max: {
            default: 1,
            type: Number,
        },
        min: {
            default: 1,
            type: Number,
        },
        auto: {
            default: false,
            type: Boolean,
        },
        element: {
            type: String,
            required: true,
        },
        hash: {
            type: Boolean,
            default: false,
        },
        fileError: {
            type: Boolean,
            default: false,
        },
        allowed: {
            type: Array as PropType<string[]>,
            default: null,
        },
        location: {
            type: String,
            default: '/v1/files/getSignedURL',
        },
        remove: {
            default: false,
            type: Boolean,
        },
        readonly: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            uppy: Uppy() as Uppy.Uppy,
            progress: 0,
            files: [] as File[],
        }
    },

    watch: {
        allowed(newVal, oldVal) {
            if (newVal != oldVal) {
                this.setUpload()
            }
        },
    },
    mounted() {
        this.setUpload()
    },
    methods: {
        setUpload() {
            return new Promise<void>((resolve) => {
                this.uppy.close()

                const url = this.url
                const hash = this.hash
                const element = this.element
                const signUrl = this.location
                const $axios = this.$axios

                this.uppy = Uppy({
                    restrictions: {
                        maxNumberOfFiles: this.max,
                        minNumberOfFiles: this.min,
                        allowedFileTypes: this.allowed,
                    },
                    autoProceed: this.auto,
                })
                    .use(DragDrop, {
                        target: '#' + element,
                    })
                    .use(AwsS3, {
                        getUploadParameters(file: { path: string }) {
                            return $axios
                                .post(signUrl, {
                                    url: url,
                                    file: file,
                                    hash: hash,
                                })
                                .then((response) => {
                                    file.path = response.data.path
                                    return {
                                        url: response.data.url,
                                        method: response.data.method,
                                        fields: response.data.fields,
                                        headers: response.data.headers,
                                    }
                                })
                        },
                    })
                this.uppy.on('file-added', (file: File) => {
                    this.$emit('file-added', file)
                    this.files.push(file)
                })

                this.uppy.on('upload-success', (file: File, data: string) => {
                    this.$emit('upload-success', file, data)

                    const index = this.files.find((element) => {
                        return element.id === file.id
                    }) as File

                    this.files[this.files.indexOf(index)].progress.uploadComplete = true
                })

                this.uppy.on('complete', () => {
                    this.$emit('uploads-complete')
                })

                this.uppy.on('upload-progress', (file, progress) => {
                    if (progress.bytesUploaded !== progress.bytesTotal) {
                        this.progress = Math.round((progress.bytesUploaded / progress.bytesTotal) * 100)
                    } else {
                        this.progress = 100
                    }
                })
                resolve()
            })
        },
        triggerUpload() {
            this.uppy.upload()
        },
        removeFile(file: File, index: number) {
            this.files.splice(index, 1)
            this.uppy.removeFile(file.id)
            this.$emit('remove-file', file)
        },
        resetFiles() {
            this.uppy.reset()
            this.files = []
        },
        checkFiles() {
            return this.files.length
        },
    },
})
