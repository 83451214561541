






































































































































interface CancelFee {
    cancel: boolean
    clientFee: number
    amcFee: number
    appraiserFee: number
}

import { ValidationObserver } from 'vee-validate'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import axios from 'axios'
import { TeamAllocation } from '@/types'

const dialog = ref(false)
export const openCustomerCancelOrderDialog = () => {
    dialog.value = true
}

/**
 * @name CustomerCancelOrderDialog
 * Dialog in order screen that allows user to cancel the order
 *
 */

export default defineComponent({
    components: { ValidationObserver },
    emits: ['orderCancelled'],
    setup(_, { emit }) {
        const loading = ref(false)
        const note = ref('')
        const cancelFee = ref({
            cancel: false,
            amcFee: 0,
            appraiserFee: 0,
            clientFee: 0,
        } as CancelFee)
        const reset = () => {
            note.value = ''
            dialog.value = false
            loading.value = false
            cancelFee.value = {
                cancel: false,
                amcFee: 0,
                appraiserFee: 0,
                clientFee: 0,
            }
        }

        const orderAppraisers = ref([] as TeamAllocation[])

        const cancelOrder = () => {
            confirm('Cancel Order', 'Are you sure you want to cancel this order?').then((confirm) => {
                if (confirm && order.value) {
                    loading.value = true
                    axios
                        .post('/v1/order/' + order.value.id + '/action/cancel', {
                            fee: cancelFee.value,
                            reason: note.value,
                        })
                        .then(() => {
                            reset()
                            emit('orderCancelled')
                            refreshOrder()
                            showSnackbar('Order cancelled successfully.')
                        })
                        .catch(() => {
                            loading.value = false
                            showError('Oops, something went wrong!')
                        })
                }
            })
        }

        const officeCancelOrder = () => {
            confirm('Cancel Order', 'Are you sure you want to cancel this order?').then((confirm) => {
                if (confirm && order.value) {
                    cancelFee.value.appraiserFee = orderAppraisers.value.reduce((total, appraiser) => {
                        return total + parseFloat(appraiser.fee as string)
                    }, 0)
                    cancelFee.value.amcFee = cancelFee.value.clientFee - cancelFee.value.appraiserFee
                    loading.value = true
                    axios
                        .post('/v1/order/' + order.value.id + '/action/cancel', {
                            fee: cancelFee.value,
                            reason: note.value,
                            appraisers: orderAppraisers.value,
                        })
                        .then(() => {
                            reset()
                            refreshOrder()
                            showSnackbar('Order cancelled successfully.')
                            emit('orderCancelled')
                        })
                        .catch(() => {
                            loading.value = false
                            showError('Oops, something went wrong!')
                        })
                }
            })
        }

        watch(
            () => order.value?.incremental,
            () => {
                orderAppraisers.value = order.value?.incremental?.order_appraisers as TeamAllocation[]
            },
        )

        return { user, note, dialog, loading, cancelFee, cancelOrder, officeCancelOrder, order, orderAppraisers }
    },
})
