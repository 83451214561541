










import { defineComponent, PropType } from '@vue/composition-api'
import { DynamicField } from '@/types/DynamicForm'
import LenderLogo from '@/components/General/LenderOptions/LenderLogo.vue'
import ARadioField from '@/components/DynamicForm/ARadioField.vue'
import SublenderSelect from '@/components/General/AutoCompletes/SublenderSelect.vue'
import CommercialDetails from '@/pages/Client/CreateOrderForm/CommercialDetails.vue'
import AmcUserSelect from '@/components/General/AutoCompletes/AmcUserSelect.vue'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'
import LenderNewClientTag from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderCompanyInfo/LenderCompanyInfoPartials/LenderNewClientTag.vue'
import LenderFeeSchedule from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderCompanyInfo/LenderCompanyInfoPartials/LenderFeeSchedule.vue'
import LenderMsaUpload from '@/components/General/LenderOptions/LenderMSAUpload.vue'
import LenderSalesRep from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderCompanyInfo/LenderCompanyInfoPartials/LenderSalesRep.vue'
import LenderAddressAuditLog from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderCompanyInfo/LenderCompanyInfoPartials/LenderAddressAuditLog.vue'

export default defineComponent({
    name: 'DynamicFieldEdit',
    components: {
        LenderLogo,
        ARadioField,
        SublenderSelect,
        CommercialDetails,
        AmcUserSelect,
        StateSelect,
        LenderNewClientTag,
        LenderFeeSchedule,
        LenderMsaUpload,
        LenderSalesRep,
        LenderAddressAuditLog,
    },
    props: {
        config: {
            type: Object as PropType<DynamicField>,
            default: () => ({}),
        },
        errors: Object,
    },
    emits: ['updateValue'],
    setup(props, { emit }) {
        const field = props.config

        const updateValue = (field: DynamicField, val: string | number | boolean) => {
            field.val = val
            emit('updateValue', field)
        }

        return {
            field,
            updateValue,
        }
    },
})
