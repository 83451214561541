














































import { defineComponent } from '@vue/composition-api'
import PreloadOrderDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadOrderDetails/PreloadOrderDetails.vue'
import PreloadSubjectInfo from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSubjectInfo/PreloadSubjectInfo.vue'
import PreloadContract from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadContract/PreloadContract.vue'
import PreloadSaleHistory from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSaleHistory/PreloadSaleHistory.vue'
import PreloadPublicRecordInformation from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadPublicRecordInformation/PreloadPublicRecordInformation.vue'
import PreloadAdditionalInformation from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadAdditionalInformation/PreloadAdditionalInformation.vue'
import PreloadZoning from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadZoning/PreloadZoning.vue'
import PreloadComparablesInformation from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadComparablesInformation.vue'
import {
    closePreload,
    preloadSteps,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    components: {
        PreloadOrderDetails,
        PreloadSubjectInfo,
        PreloadContract,
        PreloadSaleHistory,
        PreloadPublicRecordInformation,
        PreloadAdditionalInformation,
        PreloadZoning,
        PreloadComparablesInformation,
    },
    setup() {
        return {
            closePreload,
            preloadSteps,
            order,
        }
    },
})
