



























































































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { CompletedAppraisalEmails, LenderOptions } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)

        const local = ref({
            excludeLoanOfficer: false,
            first_time: false,
            emails: [],
        } as CompletedAppraisalEmails)

        if (props.option !== undefined) {
            local.value = props.option.option_value as CompletedAppraisalEmails
        }

        const emails = ref(JSON.parse(JSON.stringify(local.value.emails)))

        const save = () => {
            const tempLocal = { ...local.value, emails: emails.value }

            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'completed-appraisal-emails',
                    option_value: tempLocal,
                })
                .then(() => {
                    local.value = tempLocal
                    dialog.value = false
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            emails,
            save,
            dialog,
            local,
        }
    },
})
