













































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import DynamicField from '@/components/DynamicForm/DynamicField.vue'
import { mixed, SimpleDynamicField } from '@/types/SimpleDynamicForm'

export default defineComponent({
    name: 'ATableField',
    components: { DynamicField },
    props: {
        value: {
            type: Array as PropType<Record<string, unknown>[]>,
            default: () => {
                return []
            },
            required: true,
        },
        subComponents: {
            type: Array as PropType<SimpleDynamicField[]>,
            default: () => {
                return []
            },
            required: true,
        },
        label: String,
        maxItems: {
            type: Number as PropType<number>,
            default: Infinity,
        },
    },
    setup(props, { emit }) {
        const values = ref(props.value)
        const fields = computed(() => {
            return values.value.map((elem) => {
                const copy = [...props.subComponents]
                const val = copy.map((sub) => {
                    const newSub = { ...sub }
                    newSub.val = elem[sub.key]
                    return newSub
                })

                return {
                    components: val,
                }
            })
        })

        const updateValue = (index: number, key: string, val: mixed) => {
            values.value[index][key] = val
            emit('input', values.value)
        }

        const addItem = () => {
            const copy = [...props.subComponents]
            const item = {} as Record<string, mixed>
            copy.forEach((sub) => {
                item[sub.key] = ''
            })
            values.value.push(item)
        }

        return {
            fields,
            values,
            addItem,
            updateValue,
        }
    },
})
