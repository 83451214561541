
































































import { defineComponent, onMounted, watch, ref } from '@vue/composition-api'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

/**
 * @name LenderOptionTechFee
 * Lender option in the Office Lender Options. It is a lender option that lets us set a tech fee for their orders to be auto applied.
 *
 * @Setup billing - billing that will be set in the dialog
 * @SetupData billingLocal - local billing to display to user
 * @onMounted - grabs the tech fee if there is one and sets the variables
 *
 * @SetupWatch - watching billing and doesn't let it be null
 * @Setup saveChanges - updates the component after option is saved.
 */

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const dialog = ref(false)
        const billing = ref(0.0)
        const billingLocal = ref(0)

        onMounted(() => {
            axios.get(`/v1/lender/` + lender.value.id + `/option/tech-fee`).then((response) => {
                billing.value = response.data.option_value
                billingLocal.value = response.data.option_value
            })
        })

        watch(billing, () => {
            if (billing.value == null) billing.value = 0
        })

        const saveChanges = () => {
            dialog.value = false
            billingLocal.value = billing.value
            showSnackbar('Option saved successfully')
        }
        return {
            dialog,
            billing,
            billingLocal,
            saveChanges,
            lender,
        }
    },
})
