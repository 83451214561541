




import { computed, defineComponent } from '@vue/composition-api'
import ClientOrderClientApproval from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientOrderClientApproval.vue'
import ClientRevisionAction from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientRevisionAction.vue'
import { order } from '@/plugins/order/Order'

/**
 * @name ClientActionComponent
 * Component in Client Order Screen that determines the workflow component to show depending on the order status
 */

export default defineComponent({
    components: {
        ClientOrderClientApproval,
        ClientRevisionAction,
    },
    setup() {
        const getActionComponent = computed(() => {
            if (!order.value) return
            switch (order.value.status) {
                case 'Completed':
                    return 'ClientRevisionAction'
                case 'Client Approval':
                    return 'ClientOrderClientApproval'
                default:
                    return ''
            }
        })

        return {
            getActionComponent,
        }
    },
})
