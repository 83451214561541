




import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
    props: {
        source: { type: String, required: true },
    },
    setup(props) {
        const formattedSource = computed(() => JSON.stringify(props.source, null, 2))
        return { formattedSource }
    },
})
