










































































































































import { defineComponent, ref } from '@vue/composition-api'
import CountySelect from '@/components/General/AutoCompletes/CountySelect.vue'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'
import { appraiserSettingsId, appraiserOptions, canEdit } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { ValidationObserver } from 'vee-validate'
import ACardAction from '@/global/ACardAction.vue'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { County } from '@/types'
import SupervisorSelect from '@/components/General/AutoCompletes/SupervisorSelect.vue'

export default defineComponent({
    name: 'ReviewsRole',
    props: {
        /**
         * The title of the Dialog
         */
        name: {
            type: String,
            default: 'Configure Role',
        },
        /**
         * The type of role that is being configured
         */
        role: {
            type: String,
            default: 'reviews',
        },
        /**
         * Determines if they can choose a state and county.
         */
        state: {
            type: Boolean,
            default: false,
        },
        option: {
            type: Object,
        },
    },
    components: { CountySelect, ValidationObserver, ACardAction, StateSelect, SupervisorSelect },
    setup(props) {
        const dialog = ref(false)
        const localCounties = ref([] as County[])

        const getRoles = () => {
            $axios.get('v1/appraiser/' + appraiserSettingsId.value + '/role/' + props.role).then((response) => {
                if (!response.data) return
                if (response.data && props.role === 'reviews') {
                    appraiserOptions.value.appraiser_roles.countiesReviews = response.data
                    return
                }
                if (response.data && props.role === 'data') {
                    appraiserOptions.value.appraiser_roles.counties = response.data
                    return
                }
            })
        }

        const findCounty = (states: string[], countyId: number) => {
            const county = localCounties.value.find((county) => county.id == countyId)
            if (!county) return false
            return states.find((state) => (state as string) == (county.state as string))
        }

        const updateCounty = (data: string[]) => {
            appraiserOptions.value.appraiser_roles.counties = (
                appraiserOptions.value.appraiser_roles.counties as number[]
            ).filter((elem: number) => findCounty(data, elem))
            appraiserOptions.value.appraiser_roles.countiesReviews = (
                appraiserOptions.value.appraiser_roles.countiesReviews as number[]
            ).filter((elem: number) => findCounty(data, elem))
        }

        const loadCounties = (counties: County[]) => {
            localCounties.value = counties
        }

        const save = () => {
            if (props.state) {
                let county = appraiserOptions.value.appraiser_roles.counties
                if (props.role === 'reviews') county = appraiserOptions.value.appraiser_roles.countiesReviews
                $axios
                    .post('v1/appraiser/' + appraiserSettingsId.value + '/role/' + props.role, {
                        counties: county,
                    })
                    .then(() => {
                        showSnackbar('Option updated successfully')
                        dialog.value = false
                        return
                    })
                showSnackbar('Option updated successfully')
                dialog.value = false
            }
        }
        return {
            dialog,
            appraiserOptions,
            appraiserSettingsId,
            save,
            getRoles,
            updateCounty,
            canEdit,
            loadCounties,
        }
    },
})
