
















































import { defineComponent } from '@vue/composition-api'
import { orderFilter } from '@/components/OrderTable/Classes/OrderTable'
import ReminderActions from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderReminders/ReminderActions.vue'
import { orderOpen } from '@/plugins/order/Order'

/**
 * @name ReminderDetailTemplate
 * Template in the order table that will allow user to have a dropdown that shows them the reminders for a specific order
 */

export default defineComponent({
    components: { ReminderActions },
    setup() {
        return {
            orderFilter,
            orderOpen,
        }
    },
})
