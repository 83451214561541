
























import { Appraiser } from '@/types'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        label: {
            type: String,
            default: 'Appraiser',
        },
        type: {
            type: String,
            default: 'appraiser',
        },
        appraiserSearchString: {
            type: String,
            default: '',
        },
        searchTraineeAppraisers: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            items: [],
        }
    },
    watch: {
        search(val: string): void {
            val && val !== this.select && this.searchAppraiser(val)
        },
    },
    mounted() {
        this.searchAppraiser(this.appraiserSearchString)
    },
    methods: {
        searchAppraiser(search: string): void {
            if (search.length >= 3 || this.searchTraineeAppraisers) {
                this.loading = true
                this.$axios
                    .post('/v1/search', {
                        val: search,
                        type: this.type,
                        object: true,
                        limit: 250,
                    })
                    .then(({ data }) => {
                        data = data.filter((newData: Appraiser, index: number, array: Appraiser[]) => {
                            const appraiserIdx = array.findIndex((elm: Appraiser) => {
                                return elm.company === newData.company && elm.email === newData.email
                            })
                            return index === appraiserIdx || this.searchTraineeAppraisers
                        })

                        // TODO: This line breaks autocompletes in some instances, Commenting out until Ksenia takes a look
                        // // show selected values if they are missing in search data
                        // data = data.concat(this.$attrs.value)

                        this.items = data.sort((a: { name: string }, b: { name: string }) => {
                            return a.name < b.name || a.name == null ? -1 : 1
                        })
                        this.loading = false
                    })
            }
        },
    },
})
