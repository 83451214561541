




























import LowAppraisalEmails from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/LowAppraisalEmails.vue'
import instructions from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/instructions.vue'
import CompletedAppraisalEmails from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/CompletedAppraisalEmails.vue'
import BorrowerDelivery from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/BorrowerDelivery.vue'
import veros from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/veros.vue'
import QualityName from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/QualityName.vue'
import CustomQuality from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/CustomQuality.vue'
import QualityAndDeliveryNotes from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/QualityAndDeliveryNotes.vue'

import { defineComponent, onMounted, ref } from '@vue/composition-api'
import axios from '@/plugins/axios'
import { lender, LenderQualityOptions } from '@/components/General/LenderOptions/LenderOptions'
import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import { Task } from '@/types'
import LenderQualityReviewSettings from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/LenderQualityReviewSettings.vue'
import ReconsiderationOfValue from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/ReconsiderationOfValue.vue'
import ExcludeDeliveryFiles from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/ExcludeDeliveryFiles.vue'
import LenderQualityChecklist from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/LenderQualityChecklist.vue'
import ChangeOrderStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderQualityAndDelivery/LenderQualityPartials/ChangeOrderStatus.vue'

export default defineComponent({
    components: {
        LenderQualityReviewSettings,
        QualityName,
        LenderOnboardingStatus,
        veros,
        LowAppraisalEmails,
        instructions,
        CompletedAppraisalEmails,
        BorrowerDelivery,
        ReconsiderationOfValue,
        CustomQuality,
        QualityAndDeliveryNotes,
        ExcludeDeliveryFiles,
        LenderQualityChecklist,
        ChangeOrderStatus,
    },

    setup(_, { emit }) {
        const task = ref<Task>({ id: 0, status: '', user_id: 0 })
        const loaded = ref<boolean>(false)
        const options = ref({})
        const mercury = ref()

        const updateStatus = (status: Event) => {
            emit('updateStatus', status)
        }

        const getTasks = () => {
            axios.get(`/v1/lender/${lender.value.id}/action/get-quality-settings`).then((res) => {
                task.value = res.data.task
                options.value = res.data.options
                loaded.value = true
                mercury.value = res.data.mercury
            })
        }

        onMounted(getTasks)

        return {
            updateStatus,
            LenderQualityOptions,
            loaded,
            task,
            options,
            mercury,
        }
    },
})
