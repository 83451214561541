






















import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { order, refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    props: {
        tag: {
            type: Object,
        },
    },
    setup(props) {
        const expanded = AscentHelper.userOrCustomerFeatureEnabled('orderScreen.newOrderScreen', 'newOrderScreen', true)
            ? true
            : ref(false)
        const loading = ref(false)
        const deliverReport = () => {
            if (!props.tag) return
            loading.value = true
            $axios.post(`v1/task/${props.tag.id}/complete`).then(() => {
                showSnackbar('Task Updated Successfully!')
                loading.value = false
                refreshOrder()
            })
        }

        return {
            expanded,
            loading,
            order,
            deliverReport,
        }
    },
})
