


























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: {},
    setup() {
        const snapshot = ref(false)

        const getSnapShot = () => {
            $axios.get('v1/lender/' + lender.value.id + '/option/snapshot-review').then((response) => {
                snapshot.value = response.data.option_value
            })
        }

        const saveSnapShot = () => {
            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'snapshot-review',
                    option_value: snapshot.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        onMounted(() => {
            getSnapShot()
        })

        return {
            snapshot,
            saveSnapShot,
        }
    },
})
