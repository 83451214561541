








































import { defineComponent, computed } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper'
import { order } from '@/plugins/order/Order'
import { openEditMilestone } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/EditMilestoneDialog.vue'

export default defineComponent({
    props: {
        showAlert: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const appraiserDeadlineFormatted = computed(() =>
            order.value?.appraiserDeadline
                ? AscentHelper.formatUnix(order.value.appraiserDeadline, 'LL/dd/yyyy', false)
                : '-',
        )
        const clientDeadlineFormatted = computed(() =>
            order.value?.clientDeadline
                ? AscentHelper.formatUnix(order.value.clientDeadline, 'LL/dd/yyyy', false)
                : '-',
        )

        return {
            order,
            appraiserDeadlineFormatted,
            clientDeadlineFormatted,
            openEditMilestone,
        }
    },
})
