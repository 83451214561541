












































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { LenderOptions } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const useDefault = ref(true)
        const paymentName = ref(lender.value.form_name)
        const paymentNameEdit = ref(JSON.parse(JSON.stringify(paymentName.value)))

        if (props.option !== undefined && props.option.option_value) {
            paymentName.value = props.option.option_value as string
            useDefault.value = false
        }

        const save = () => {
            const name = useDefault.value ? '' : paymentNameEdit.value

            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'payment-name',
                    option_value: name,
                })
                .then(() => {
                    paymentName.value = useDefault.value ? lender.value.form_name : paymentNameEdit.value
                    dialog.value = false
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            lender,
            paymentName,
            save,
            paymentNameEdit,
            dialog,
            useDefault,
        }
    },
})
