var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"tile":"","color":"accentWhite","id":"clientOrderScreen","min-height":"100vh"}},[_c('OrderToolbar',{attrs:{"fixed":"","show-order-tags":true,"show-order-actions":true}}),_c('v-spacer',{staticClass:"py-6"}),(_vm.order && _vm.order.incremental)?_c('v-card-text',[_c('v-row',{class:'mt-16'},[_c('v-col',[_c('ClientOrderInstructions')],1)],1),_c('OrderStatusBar'),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[(
                            _vm.$ascent.canDo('Work') &&
                            _vm.$ascent.canDo('Order') &&
                            !_vm.$ascent.featureEnabled('preventClientsFromOrderingAdditionalProducts') &&
                            _vm.directOrder
                        )?_c('v-col',{attrs:{"cols":"12"}},[(_vm.order.client_completed !== null)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","block":""}},'v-btn',attrs,false),on),[_vm._v(" Order Additional Products ")])]}}],null,false,379055160)},[_c('v-list',[(
                                        _vm.orderAdditionalProducts === null ||
                                        _vm.orderAdditionalProducts.includes('final')
                                    )?_c('v-list-item',{attrs:{"href":'/client/order/' + _vm.order.id + '?product=final'}},[_c('v-list-item-title',[_vm._v("Final Inspection (1004D Final Inspection)")])],1):_vm._e(),(
                                        _vm.orderAdditionalProducts === null ||
                                        _vm.orderAdditionalProducts.includes('recert')
                                    )?_c('v-list-item',{attrs:{"href":'/client/order/' + _vm.order.id + '?product=recert'}},[_c('v-list-item-title',[_vm._v(" Recertification of Value/1004D Appraisal Update ")])],1):_vm._e(),(
                                        _vm.orderAdditionalProducts === null ||
                                        _vm.orderAdditionalProducts.includes('product')
                                    )?_c('v-list-item',{attrs:{"href":'/client/order/' + _vm.order.id}},[_c('v-list-item-title',[_vm._v("Order Additional Product")])],1):_vm._e()],1)],1):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('ClientOrderFeesAndPayments')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ClientOrderDetails')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ClientOrderFiles')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ClientContactsAndParticipants')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ClientOrderAllContacts')],1),(_vm.entities.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('ClientOrderEntities',{attrs:{"entities":_vm.entities}})],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$ascent.canDo('Work'))?_c('ClientActionComponent'):_vm._e()],1),_c('v-col',[_c('ClientOrderNotes')],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }