









































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { user } from '@/plugins/User'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, updateOrder } from '@/plugins/order/Order'

/**
 * @name ReconsiderationAction
 * Workflow step in AMC order screen to handle reconsideration requests
 */

export default defineComponent({
    props: {},
    components: { ValidationObserver },
    setup() {
        return { user }
    },
    data() {
        return {
            actionLoading: false,
            /**
             * Rejects or proceeds with reconsideration request
             */
            answer: '',
            /**
             * Reason for declining reconsideration
             */
            declineReason: '',
            loading: false,
            /**
             * rejection reason, chosen from canned responses
             */
            rejectreason: '',
            /**
             * Canned responses for rejecting the ROV
             */
            rejectreasons: [
                {
                    label: 'Compliance Commentary',
                    value:
                        'The dispute in its presented format has a few compliance issues. The dispute process must be free from AIR and Dodd Frank violations, any suggestion to an outcome of value, predicting a value or suggesting an adjustment to lean toward an indicated value would need to be removed from the dispute for us to be able to submit this. Basically, we can’t suggest values or adjustments as this is against industry standards. We need to ask that all the references for numbers be removed, I would be more than happy to do this for you. If you choose to have the agent remove this, some of the commentary can stay, but it will need to be re-worded to be compliant. All statements with suggested values or adjustments “other appraisers in the area use” OR any subjective comments, such as “driveway is steep and no useable” that’s subjective.' +
                        '\n\n' +
                        'I don’t have access to the local database and as the AMC I am required to take a limited role in the dispute process, I can make suggestions and required comments that are of noncompliance to be removed, but I can’t complete the dispute on behalf of the client or pulled actual data.' +
                        '\n\n' +
                        'I am sorry, I was not able to be of more help. Please don’t ever hesitate to reach out to me. If you wish to proceed with the dispute process, please make the above corrections and we will be happy to submit the dispute.',
                },
                {
                    label: 'Closed Sales',
                    value:
                        'Most successful disputes are completed using closed sales most similar to the subject as of the effective date and avoid pointing out flaws with the order itself. Provide each suggested comparable as well as an explanation as to why they are more comparable than the comparables used within the order report. The suggested sales should be within a reasonable boundaries and time frame that support he suggested opinion of value. In this case, you will want to find sales similar to the subject, +/- 15% of the GLA, similar bedroom, bathroom within the immediate neighborhood that have sold within the past 6 months for the appraiser to consider. Also, to prepare the best supporting data in the dispute, you will want to state facts. The facts can build the strongest dispute. For example, the subjects property spent XYZ in upgrades to be remodeled while the other properties have not been updated with the prior sales and include a copy of the cost breakdown for the improvements done by the owner since the purchase of the property.' +
                        '\n\n' +
                        'I don’t have access to the local database and as the AMC I am required to take a limited role in the dispute process, I can make suggestions and required comments that are of noncompliance to be removed, but I can’t complete the dispute on behalf of the client or pulled actual data.' +
                        '\n\n' +
                        'I am sorry, I was not able to be of more help. Please don’t ever hesitate to reach out to me. If you wish to proceed with the dispute process, please make the above corrections and we will be happy to submit the dispute.',
                },
                {
                    label: 'ROV Listings',
                    value:
                        'Thank you for the dispute, please remove all sales that have not closed as of the effective date. Pending sales can’t be weighted in the overall opinion of market value. The final value can only be based on closed sales as of the effective date. Listings are only used for the underwriter marketability. To prepare the best dispute possible, You will want to state facts. The facts can build the strongest dispute, due to compliance, you can’t use one report against each other, but you can use facts from the first report. For example, the subjects property spent XYZ in upgrades to be remodeled while the other properties have not been updated with the prior sales and include a copy of the cost breakdown for the improvements done by the owner since the purchase of the property. Majority of successful disputes have suggested sales that are more similar to the subject that have sold within the reasonable boundaries and time frame that support suggested opinion of value. As the AMC I am required to take a limited role in the dispute process, I can make suggestions and required comments that are of noncompliance to be removed, but I can’t complete the dispute on behalf of the client or pulled actual data.' +
                        '\n\n' +
                        'I don’t have access to the local database and as the AMC I am required to take a limited role in the dispute process, I can make suggestions and required comments that are of noncompliance to be removed, but I can’t complete the dispute on behalf of the client or pulled actual data.' +
                        '\n\n' +
                        'I am sorry, I was not able to be of more help. Please don’t ever hesitate to reach out to me. If you wish to proceed with the dispute process, please make the above corrections and we will be happy to submit the dispute.',
                },
            ],
        }
    },
    computed: {
        /**
         * Url for reconsideration
         */
        reconsiderationURL(): string {
            if (!order.value) return ''
            if (!order.value.incremental) return ''
            return '/appraisal/reconsideration/' + order.value.id + '/' + order.value.incremental.token
        },
    },
    methods: {
        /**
         * Submits reconsideration with answer
         */
        submit() {
            if (!order.value) return
            this.loading = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/reconsideration-' + this.answer.toLowerCase(), {
                    declinereason: this.declineReason,
                })
                .then(() => {
                    this.loading = false
                    showSnackbar('Submitted Successfully')
                    updateOrder()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        insertCannedResponse() {
            this.declineReason = this.rejectreason
        },
        /**
         * Converts order to a uw request. Refreshes order.
         */
        convertToUWRequest() {
            confirm(
                'Are you sure?',
                'Converting this to a UW request will change the status and update the statistics for revisions.',
            ).then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    this.actionLoading = true
                    this.$axios
                        .post('/v1/order/' + order.value.id + '/workflow/convert-to-uw-request')
                        .then(() => {
                            this.actionLoading = false
                            updateOrder()
                        })
                        .catch(() => {
                            this.actionLoading = false
                        })
                }
            })
        },
    },
})
