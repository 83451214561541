
















































import { computed, defineComponent, onMounted, watch, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import AppraiserTeamAllocate from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/TeamAllocation/AppraiserTeamAllocate.vue'
import { RoleSetting, TeamAllocation } from '@/types'
import $axios from '@/plugins/axios'
import { order, refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import Deadlines from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/Deadlines.vue'

export const primaryAppraiser = ref({})

export default defineComponent({
    components: {
        ValidationObserver,
        AppraiserTeamAllocate,
        Deadlines,
    },
    setup() {
        const teamAllocations = ref([] as TeamAllocation[])
        const badFee = ref(false)
        const loading = ref(false)

        const assignTeam = (override: boolean) => {
            const feeTotal = teamAllocations.value.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.fee as string)
            }, 0)
            if (
                order.value &&
                order.value.incremental &&
                order.value.incremental.total &&
                feeTotal > order?.value?.incremental?.total?.total &&
                !override
            ) {
                return (badFee.value = true)
            }
            badFee.value = false
            if (!order.value) return
            loading.value = true
            $axios
                .post(`/v1/order/${order.value.id}/action/office-allocate-order`, { roles: teamAllocations.value })
                .then(() => {
                    showSnackbar('Assigned successfully!')
                    refreshOrder()
                    loading.value = false
                })
        }

        watch(teamAllocations, () => {
            primaryAppraiser.value = teamAllocations.value[0]
        })

        const disableButton = computed(() => {
            if (!order.value) return true
            if (!order.value.incremental) return true
            if (teamAllocations.value.length === 0) return true
            for (const allocation of teamAllocations.value) {
                if (
                    allocation.required &&
                    (!allocation.appraiser_id || allocation.fee === undefined || allocation.fee === null)
                )
                    return true
            }
            if (!order.value?.appraiserDeadline) return true
            if (!order.value?.clientDeadline) return true
            return false
        })

        onMounted(() => {
            $axios.get('/v1/settings/appraiser-roles').then((response) => {
                response.data.result.forEach((role: RoleSetting) => {
                    teamAllocations.value.push({
                        role: role.role,
                        appraiser_id: undefined,
                        fee: 0,
                        required: role.required,
                    })
                })
                if (typeof order.value?.appraiser_id === 'number') {
                    teamAllocations.value.map((elem) => {
                        if (elem.role === 'Primary') {
                            elem.appraiser_id = order.value?.appraiser_id as number
                        }
                    })
                }
            })
        })

        return {
            teamAllocations,
            assignTeam,
            loading,
            disableButton,
            primaryAppraiser,
            badFee,
            order,
        }
    },
})
