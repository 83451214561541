var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"float-right",attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[(
            _vm.$ascent.canDo('Work') &&
            (_vm.order.source !== 'mortgagedirector' ||
                !_vm.$ascent.featureEnabled('monitoring.CorporateCreditCardApproval'))
        )?[_c('v-btn',{attrs:{"icon":"","color":"accentBlack"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.newFileDialog = true}}},[_vm._v("$vuetify.icons.plus")])],1)]:_vm._e(),(
            _vm.$ascent.canDo('Work') &&
            _vm.order.source === 'mortgagedirector' &&
            _vm.$ascent.featureEnabled('monitoring.CorporateCreditCardApproval')
        )?[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","rounded":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"accentBlack"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("$vuetify.icons.plus")])],1)]}}],null,false,3878659658)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.newMortgageDirectorFileDialog = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Import from Mortgage Director")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.newFileDialog = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Upload File")])],1)],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }