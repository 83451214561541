




























































































import { defineComponent, computed } from '@vue/composition-api'
import { Phone } from '@/types'
import { order } from '@/plugins/order/Order'
import OrderDetailListItem from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/OrderDetailListItem.vue'
import AppraiserOrderTags from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderAppraiser/OrderAppraiserPartials/AppraiserOrderTags.vue'
import AppraiserOtherOrders from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderAppraiser/OrderAppraiserPartials/AppraiserOtherOrders.vue'
import { openAssign } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AppraiserStaggerDays from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderAppraiser/OrderAppraiserPartials/AppraiserStaggerDays.vue'
import $axios from '@/plugins/axios'
import { setImpersonateToken } from '@/helpers/token-helper'
import { user } from '@/plugins/User'
import CustomerTraineeAppraiser from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerTraineeAppraiser.vue'

export default defineComponent({
    name: 'CustomerSingleVendorDetails',
    components: {
        AppraiserStaggerDays,
        OrderDetailListItem,
        AppraiserOrderTags,
        AppraiserOtherOrders,
        CustomerTraineeAppraiser,
    },
    setup(_, { root }) {
        const getIcon = (phone: Phone, type: string) => {
            switch (type) {
                case 'mobile':
                    return ['Mobile'].indexOf(phone.type) > -1
                case 'office':
                    return ['Office', 'WORK', 'Direct'].indexOf(phone.type) > -1
                default:
                    return ['Mobile', 'Office', 'WORK', 'Direct'].indexOf(phone.type) == -1
            }
        }

        const editAppraiser = () => {
            if (!order.value) return
            if (order.value?.appraiser_id) {
                window.open('/appraiser/' + order.value?.appraiser_id + '/edit', '_blank')
            }
        }

        const appraiserFeeValue = computed(() => {
            return String(
                order.value?.incremental?.order_appraisers?.find((appraiser) => {
                    return appraiser.role === 'Primary'
                })?.fee ??
                    order.value?.incremental?.total?.appraiser_value ??
                    0,
            )
        })

        const impersonateAppraiser = () => {
            $axios
                .post('/v1/ascent/impersonate/appraiser/' + order.value?.appraiser_id, {
                    path: root.$route.path,
                })
                .then((response) => {
                    if (response.data.data.token) {
                        setImpersonateToken(response.data.data.token)
                    }
                    window.location = response.data.data.path
                })
        }
        return {
            getIcon,
            order,
            user,
            editAppraiser,
            openAssign,
            impersonateAppraiser,
            appraiserFeeValue,
        }
    },
})
