













































import { defineComponent, computed, PropType, ref } from '@vue/composition-api'
import { appraiserPanelNotes } from '@/plugins/Notes'
import AppraiserPanelNotes from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/AppraiserPanelNotes.vue'
import { AppraiserPanel } from '@/types'

/**
 * @name PanelNotePartial
 * Partial in Assign Table that shows if there are notes taken for this appraiser
 *
 * @SetupComputed - gets color of the icon depending if notes have been read or not.
 */

export default defineComponent({
    components: {
        AppraiserPanelNotes,
    },
    props: {
        /**
         * Appraiser in appraisal panel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
        fromJaroPanel: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const showNotesCard = ref(false)
        const noteIconColor = computed(() => {
            const notes = appraiserPanelNotes.value.filter(
                (elem) =>
                    elem.appraiser_id === props.item?.appraiser?.id &&
                    elem.source === 'Prior Appraiser' &&
                    elem.target === 'AMC',
            )
            if (notes.find((note) => !note.read)) return '#FFA000'
            return 'secondary'
        })

        const openAppraiserPanelNotes = () => {
            if (!props.fromJaroPanel) return
            showNotesCard.value = true
        }

        const filteredNotes = computed(() => {
            if (!props.item?.appraiser?.id || !appraiserPanelNotes.value) return []
            return appraiserPanelNotes.value.filter((note) => {
                return note.appraiser_id === props.item?.appraiser.id
            })
        })

        return {
            noteIconColor,
            appraiserPanelNotes,
            showNotesCard,
            openAppraiserPanelNotes,
            filteredNotes,
        }
    },
})
