















































































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order, updateOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import OfficeDecline from '@/components/OrderScreens/Office/OrderComponents/Workflows/ConditionalWorkflows/OfficeDecline.vue'
import OfficeConditionalAccept from '@/components/OrderScreens/Office/OrderComponents/Workflows/ConditionalWorkflows/OfficeConditionalAccept.vue'
import LoanNumbers from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/LoanNumbers.vue'
import OrderDetailsBorrowers from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderDetailsBorrowers.vue'
import OrderDetailsProductsDirectives from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderDetailsProductsDirectives.vue'
import InspectionDates from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/InspectionDates.vue'

export default defineComponent({
    components: {
        InspectionDates,
        ValidationObserver,
        OfficeDecline,
        OfficeConditionalAccept,
        LoanNumbers,
        OrderDetailsBorrowers,
        OrderDetailsProductsDirectives,
    },
    setup() {
        return { order }
    },
    data() {
        return {
            answer: '',
            message: '',
            reason: '',
            agreement: null,
            agree: false,
            loading: false,
            settings: {
                text: null,
            },
            allowsConditionalAcceptance: [
                'appraisalport',
                'appraisalvision',
                'ascent',
                'connexions',
                'evo',
                'form',
                'lenderx',
                'reggora',
                'shield',
                'valuelink',
                'valuepad',
            ],
        }
    },
    methods: {
        submit() {
            this.loading = true
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/action/office-accept', {
                    reason: this.reason,
                })
                .then(() => {
                    this.loading = false
                    updateOrder()
                    showSnackbar('Submitted Successfully')
                })
                .catch(({ response }) => {
                    let message = ''
                    if (response.data.errors && response.data.errors[0][0]) {
                        message = response.data.errors[0][0]
                    } else {
                        message = response.data.message ?? response
                    }

                    if (message.toLowerCase().includes('pick up card')) {
                        if (message.includes('- SF')) {
                            message =
                                message +
                                '. Our processor has sent a verification to confirm this transaction. After responding, please try again.'
                        } else {
                            message =
                                message +
                                '. Our processor has indicated this card has been reported as lost or stolen. Please use a different card and try again.'
                        }
                    }

                    if (message.includes('pending agreements')) {
                        this.message = message
                    } else {
                        this.$emit('acceptanceError', message)
                    }

                    this.loading = false
                })
        },
        toAppraiserAgreement() {
            if (!order.value || !order.value.appraiser_id) return
            window.open(`/appraiser/${order.value.appraiser_id}/clients`, '_blank')
        },
    },
})
