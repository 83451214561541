













































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { IntegrationOption, SublenderOptions } from '@/types'
import { lender, sublender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<SublenderOptions>,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const dialog = ref(false)
        const optionLocal = ref<IntegrationOption>({})

        if (props.option && props.option.option_value) {
            optionLocal.value = JSON.parse(JSON.stringify(props.option.option_value)) as IntegrationOption
        }

        const save = () => {
            dialog.value = false
            showSnackbar(`${props.name} saved successfully`)
        }

        const closeDialog = () => {
            dialog.value = false
            optionLocal.value = {}
        }

        const removeIntegration = () => {
            if (!sublender.value) {
                return
            }

            confirm(
                'Remove Integration?',
                'Are you sure you want to remove this integration from this sublender?',
            ).then((confirm) => {
                if (confirm && sublender.value && props.option) {
                    $axios.delete(`/v1/sublender/${sublender.value.id}/option/${props.option.id}`).then(() => {
                        closeDialog()
                        emit('sublender-option-edited')
                        showSnackbar('Integration successfully removed')
                    })
                }
            })
        }

        return {
            save,
            removeIntegration,
            dialog,
            optionLocal,
            lender,
            closeDialog,
            sublender,
        }
    },
})
