















































import UserNav from '@/AppLayout//navigation/UserNav.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { impersonated, user, stopImpersonation } from '@/plugins/User'
import { mini, mobileNavOpen } from '@/AppLayout/navigation/LeftNav.vue'
import DashboardNav from '@/AppLayout/navigation/DashboardNav.vue'
import { currentDashboard, dashboards } from '@/components/General/Dashboard/Dashboards'

export const openMobile = ref(false)

export default defineComponent({
    props: {},
    name: 'AppHeader',
    components: {
        UserNav,
        DashboardNav,
    },
    setup() {
        const switchBack = async () => {
            stopImpersonation().then().catch()
        }

        const mobile = () => {
            mobileNavOpen.value = !mobileNavOpen.value
            mini.value = true
            openMobile.value = !openMobile.value
        }

        return {
            impersonated,
            user,
            switchBack,
            mobile,
            openMobile,
            currentDashboard,
            dashboards,
        }
    },
})
