



























































import { ValidationObserver } from 'vee-validate'
import { defineComponent } from '@vue/composition-api'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { user } from '@/plugins/User'

function reset() {
    return {
        note: '',
        dialog: false,
        loading: false,
        cancelFee: {
            cancel: false,
            amcFee: null,
            appraiserFee: null,
        },
    }
}

/**
 * @name CancelOrderDialog
 * Dialog in order screen that allows user to cancel the order
 *
 * @ResetData note - note to add for cancelling order
 * @ResetData cancelFee - object that will put fees on order for cancelling the order
 */

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        return { user }
    },
    data() {
        return reset()
    },
    methods: {
        open() {
            this.dialog = true
        },
        /**
         * Will post to backend and cancel the order with reason and fee. Refreshes order after cancelled.
         */
        cancelOrder() {
            confirm('Cancel Order', 'Are you sure you want to cancel this order?').then((confirm) => {
                if (confirm && order.value) {
                    this.loading = true
                    this.$axios
                        .post('/v1/order/' + order.value.id + '/action/cancel', {
                            fee: this.cancelFee,
                            reason: this.note,
                        })
                        .then(() => {
                            refreshOrder()
                            Object.assign(this.$data, reset())
                            showSnackbar('Order cancelled successfully.')
                            this.$emit('orderCancelled')
                            this.loading = false
                        })
                        .catch(() => {
                            this.loading = false
                            showError('Oops, something went wrong!')
                        })
                }
            })
        },
    },
})
