var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-app-bar',{attrs:{"dense":"","flat":"","color":"accentWhitedarken1"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0"},[_c('v-icon',{attrs:{"left":"","color":"accentBlack","dense":""}},[_vm._v("$vuetify.icon.fileAlt")]),_vm._v(" Files ")],1),_c('v-spacer'),(
                    _vm.$ascent.canDo('Work') &&
                    (_vm.order.source !== 'mortgagedirector' ||
                        !_vm.$ascent.featureEnabled('monitoring.CorporateCreditCardApproval'))
                )?[_c('v-btn',{attrs:{"icon":"","color":"accentBlacklighten1"},on:{"click":function($event){_vm.newFileDialog = true}}},[_c('v-icon',[_vm._v("$vuetify.icon.plus")])],1)]:_vm._e(),(
                    _vm.$ascent.canDo('Work') &&
                    _vm.order.source === 'mortgagedirector' &&
                    _vm.$ascent.featureEnabled('monitoring.CorporateCreditCardApproval')
                )?[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","rounded":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"accentBlack"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("$vuetify.icons.plus")])],1)]}}],null,false,3878659658)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.newMortgageDirectorFileDialog = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Import from Mortgage Director")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.newFileDialog = true}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Upload File")])],1)],1)],1)],1)]:_vm._e()],2)]},proxy:true}])},[_c('v-card-text',[(_vm.hasInactive)?_c('v-row',[_c('v-col',{staticClass:"px-0 text-right"},[_c('a-btn',{attrs:{"color":"blue-grey lighten-5","x-small":""},on:{"click":function($event){_vm.showInactive = !_vm.showInactive}}},[_vm._v(" "+_vm._s(_vm.showInactive ? 'Hide Inactive' : 'Show Inactive')+" ")])],1)],1):_vm._e(),(_vm.order.incremental)?_c('v-row',[_c('v-col',{staticClass:"px-0"},[_c('v-list',{staticClass:"pa-0 no-hover auto-height",attrs:{"dense":"","color":"transparent"}},[(
                            _vm.order.incremental &&
                            _vm.order.incremental.reconsideration &&
                            _vm.order.incremental.reconsideration !== null &&
                            _vm.activeFiles.find(function (elem) { return elem.type === 'Reconsideration PDF'; }) === undefined
                        )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.pdfUrl +
                                        '/order/reconsideration/' +
                                        _vm.order.id +
                                        '/' +
                                        _vm.order.incremental.token +
                                        '/',"target":"_blank"}},[_vm._v(" Reconsideration PDF ")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","primary":""},on:{"click":function($event){return _vm.downloadPDF(_vm.pdfUrl + '/pdf-download/reconsideration/' + _vm.order.incremental.token)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("$vuetify.icon.fileDownload")])],1)],1)],1):_vm._e(),(_vm.activeFiles.find(function (elem) { return elem.type === 'Invoice'; }) === undefined)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.pdfUrl + '/pdf/invoice/' + _vm.order.incremental.token,"target":"_blank"}},[_vm._v(" Invoice ")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","primary":""},on:{"click":function($event){return _vm.downloadPDF(_vm.pdfUrl + '/pdf-download/invoice/' + _vm.order.incremental.token)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("$vuetify.icon.fileDownload")])],1)],1)],1):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.autoComplianceFiles') && _vm.isCompleted)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.pdfUrl + '/pdf/compliance/' + _vm.order.incremental.token,"target":"_blank"}},[_vm._v(" Compliance Cert ")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","primary":""},on:{"click":function($event){return _vm.downloadPDF(_vm.pdfUrl + '/pdf-download/compliance/' + _vm.order.incremental.token)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("$vuetify.icon.fileDownload")])],1)],1)],1):_vm._e(),_vm._l((_vm.filteredFiles),function(file){return _c('ClientFileComponent',{key:file.id,attrs:{"file":file},on:{"editFile":function($event){return _vm.open($event)}}})})],2)],1)],1):_vm._e(),_c('EditFile'),_c('ClientAddOrderFileDialog'),_c('MortgageDirectorImportFileDialog')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }