













































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'
import { fileStatuses } from '@/plugins/Data'
import { MortgageDirectorFiles } from '@/types'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'
import { omit } from 'lodash'

const fileStatusesSelection = ref('All')
export const newMortgageDirectorFileDialog = ref(false)

export default defineComponent({
    name: 'MortgageDirectorImportFileDialog',
    components: {
        ValidationObserver,
    },
    setup() {
        const added = ref(false)
        const files = ref<MortgageDirectorFiles>({})
        const loading = ref(false)
        const loadingMessage = ref('Searching Mortgage Director for files...')
        const selectedFiles = ref<MortgageDirectorFiles>({})
        const selectedFileNames = ref<string[]>([])

        const cancel = () => {
            newMortgageDirectorFileDialog.value = false
            files.value = {}
            selectedFiles.value = {}
            selectedFileNames.value = []
            fileStatusesSelection.value = 'All'
            loadingMessage.value = 'Searching Mortgage Director for Files...'
        }

        const getFiles = () => {
            if (!order.value || !order.value.id) return

            loading.value = true

            axios
                .get('/v1/order/action/' + order.value.id + '/mortgage-director-files')
                .then((response) => {
                    files.value = response.data.files
                    loading.value = false
                })
                .catch(() => {
                    loading.value = false
                })
        }

        const selectFile = (fileName: string, fileIds: number[]) => {
            if (selectedFileNames.value.includes(fileName)) {
                selectedFiles.value = omit(selectedFiles.value, [fileName])
                selectedFileNames.value = selectedFileNames.value.filter((name) => name !== fileName)
                return
            }

            selectedFiles.value[fileName as keyof object] = fileIds
            selectedFileNames.value.push(fileName)
        }

        const importFiles = () => {
            if (!order.value || !order.value.id) return

            loadingMessage.value = 'Preparing to import files from Mortgage Director...'
            loading.value = true

            axios
                .post('/v1/order/action/' + order.value.id + '/mortgage-director-files', {
                    files: selectedFiles.value,
                    status: fileStatusesSelection.value,
                })
                .then((response) => {
                    if (response.data?.result && response.data.result === 'error') {
                        showError(response.data.message ?? 'An error occurred. Please try again.')
                    }
                    if (response.data?.result && response.data.result === 'success') {
                        showSnackbar('The selected files have been queued for import.')
                    }
                    loading.value = false
                    cancel()
                })
                .catch((error) => {
                    const message = error.response?.data?.message ?? error
                    showError(message)
                    loading.value = false
                    cancel()
                })
        }

        fileStatuses.splice(4, 1)

        watch(
            () => newMortgageDirectorFileDialog.value,
            () => {
                if (newMortgageDirectorFileDialog.value) {
                    getFiles()
                }
            },
        )

        return {
            added,
            cancel,
            files,
            fileStatuses,
            fileStatusesSelection,
            importFiles,
            loading,
            loadingMessage,
            newMortgageDirectorFileDialog,
            selectFile,
            selectedFiles,
            selectedFileNames,
            user,
        }
    },
})
