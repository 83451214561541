import { computed, ComputedRef, ref } from '@vue/composition-api'
import { Client, CommercialProperyType } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import camelCase from 'lodash/camelCase'

const namespaced = true
export const fileTypes = [
    'Addendum',
    'Additional',
    'Appraisal ENV',
    'Budget',
    'Bids',
    'Condo Cert',
    'Disclosures',
    'EAD SSR',
    'Engagement Letter',
    'Final Inspection',
    'Financial Statements',
    'Flood Certificate',
    'FNM SSR',
    'FRE SSR',
    'HUD Compliance Inspection',
    'Hazard Disclosure',
    'Invoice',
    'Lease',
    'Legal Description',
    'Plans and Specs',
    'Prelim',
    'Prior Appraisal',
    'Product Instruction',
    'Property Data',
    'Purchase Contract',
    'Rent Roll',
    'Reconsideration PDF',
    'Reconsideration Support',
    'Revised Appraisal',
    'Revision or UW Request',
    'Sample',
    'Survey',
    'Tax Returns',
    'Title',
    'UAD Validation',
]

export const systemFileTypes = ref([
    'Additional',
    'Appraisal ENV',
    'EAD SSR',
    'FNM SSR',
    'FRE SSR',
    'PDR Floor Plan',
    'Reconsideration PDF',
])

export const fileStatuses = ['All', 'Admin', 'Appraiser', 'Client', 'Inactive']

export const contactTypes = ["Buyer's Agent", "Seller's Agent", 'Current Tenant', 'Borrower', 'Other']

export const financeTypes = ['Purchase', 'Refinance', 'Home Equity', 'Reverse Mortgage', 'Other']
export const officeFinanceTypes = [
    'Purchase',
    'Refinance',
    'Equity Line of Credit',
    'Construction',
    'Asset Management',
    'REO',
    'Estate',
    'Litigation',
    'Value Opinion',
    'Review',
    'Update',
    'Other-See Notes',
    'Internal Decision Maker',
    'Loan Underwriting',
    'Tax Appeal',
    'Fixed Equity',
]
export const propertyTypes = [
    'SFR',
    'Condominium',
    'Manufactured',
    'Townhouse',
    'Duplex',
    'Co-Op',
    'Commercial',
    'Other',
    'PUD',
    'Quadraplex',
    'Triplex',
    'Attached',
    'Detached',
]

export const commercialPropertyTypes: { [key in CommercialProperyType]: string[] } = {
    Land: [
        'Agriculture',
        'Cave',
        'Cemetery',
        'Commercial',
        'Contaminated',
        'Easement',
        'Energy',
        'Housing',
        'Industrial',
        'Mixed Use',
        'Park & Open Space',
        'Planned Unit Development (PUD)',
        'Public Service',
        'Religious',
        'Recreation',
        'Timberland',
        'Water Related',
        'Wasteland',
        'Wilderness',
        'Other',
    ],
    Agriculture: [
        'Aquaculture',
        'Auction/Market',
        'Dairy',
        'Equestrian Facility',
        'Feedlot',
        'Grain Elevator',
        'Greenhouse/Nursery',
        'Livestock Production',
        'Ranch',
        'Winery',
        'Winery & Vineyard',
        'Other Agriculture',
    ],
    'Assembly/Meeting Place': ['Religious Facilities'],
    'Commercial & Retail': [
        'Automotive',
        'Bank',
        'Condominium/Co-op Bldg',
        'Condominium/Co-op Unit',
        'Convenience Store',
        'Day Care Facility',
        'Garden Center',
        'General Purpose',
        'General Retail',
        'Laundromat, Self-Service',
        'Restaurant',
        'Showroom',
        'Street Retail',
        'Tavern, Bar, Nightclub, Micro-Brewery',
        'Other Commercial & Retail',
    ],
    'Energy Generation': ['Alternative Fuels', 'Conventional Fuels', 'Renewable Fuels', 'Other Energy Generation'],
    Healthcare: [
        'Acute Care Hospital',
        'Ambulatory Surgery Center',
        'Behavioral Care Facility',
        'Clinical Laboratory',
        'Hospital/Medical Center',
        'Rehabilitation Center',
        'Urgent Care Center',
        'Other Healthcare',
    ],
    Housing: [
        'Condominium -Co-Op Building',
        'Condominium - Co-Op Unit',
        'Group',
        'Single Unit',
        'Multiple Units',
        'Senior',
        'Student',
        'Other Housing',
    ],
    Industrial: [
        'Business Park, Industrial',
        'Commercial Laundry',
        'Communication/Data Center',
        'Condominium Bldg',
        'Condominium Unit',
        'Flex Space',
        'Manufacturing',
        'Processing',
        'Large Ground Vehicles',
        'Research & Development',
        'Saw Mill',
        'Self-Storage / Mini-Storage',
        'Tank Farm / Petroleum Storage',
        'Warehouse',
        'Other Industrial',
    ],
    Infrastructure: [
        'Dam',
        'Dikes & Floodgates',
        'Electrical Storage & Conveyance',
        'Natural Gas Storage & Conveyance',
        'Roads, Bridges, & Related Items',
        'Solid Waste Incinerators',
        'Solid Waste Recycling',
        'Waste Transfer Station',
        'Wastewater Treatment',
        'Water Cleansing, Storage, and Convenyance',
        'Other Infrastructure',
    ],
    'Lodging & Hospitality': [
        'All Suites',
        'Bed & Breakfast',
        'Casino Hotel',
        'Convention Hotel',
        'Economy Hotel/Motel',
        'Extended Stay',
        'Full Service',
        'Limited Service',
        'Luxury',
        'Resort & Spa',
        'Other Lodging & Hospitality',
    ],
    'Military Facility': ['Armory', 'Other Military Facilities'],
    'Mixed Use': ['Mixed Use Building', 'Mixed Use Development'],
    Office: [
        'Business Park, Office',
        'Condominium Building',
        'Condominium Unit',
        'Creative/Loft',
        'Medical',
        'General Purpose',
        'Research & Development',
        'Other Office',
    ],
    'Public Service': [
        'Courthouse',
        'Educational',
        'Embassy Compound',
        'Fire House',
        'Jail/Correctional Facility',
        'Library',
        'Museum/Gallery',
        'Police Station',
        'Post Office',
        'Zoo/Nature Facility/Aquarium',
        'Other Public Service',
    ],
    Recreation: [
        'Amusement Facility',
        'Casino/Gaming Facility',
        'Cinema',
        'Equestrian Center',
        'Fitness, Courts, and Spa Facilities',
        'Golf Related',
        'Racetrack',
        'Shooting Range',
        'Skating Facility',
        'Ski Resort',
        'Sports Arena/Stadium',
        'Theater/Performing Arts Facility',
        'Other Recreation',
    ],
    'Shopping Centers': [
        'Convenience/Strip Center',
        'Neighborhood Center',
        'Community Shopping Center',
        'Regional Center',
        'Super-Regional Center/Mall',
        'Specialty Centers',
        'Other Shopping Centers',
    ],
    'Special Purpose': [
        'Cement, Rock, Gravel Plant',
        'Death Related',
        'Kennel',
        'Marina',
        'Movie Studio',
        'Pier',
        'Outdoor Sign',
        'Communications Tower',
        'Veterinary Clinic',
        'Watercraft Repair & Storage',
        'Other Special Purpose',
    ],
    Transportation: ['Air Transport', 'Ground Transport', 'Water Transport', 'Other Transport'],
}

export const phoneTypes = ['Home', 'Mobile', 'Office', 'Other']

export const occupancyTypes = ['Owner Occupied', 'Tenant', 'Vacant', 'Unknown']

export const officeOccupancyTypes = ['Owner Occupied', 'Tenant', 'Vacant', 'Unknown']

export const expMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

export const expYears = [
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    '2031',
    '2032',
    '2033',
    '2034',
    '2035',
]

export const tagItems = [
    { title: 'Jumbo', color: '#2196F3', deletable: true, visible: ['All'] },
    { title: 'New Client', color: '#8434A3', deletable: true, visible: ['All'] },
    { title: 'Construction', color: '#009688', deletable: true, visible: ['All'] },
    { title: 'Payment Needed', color: '#1B5E20', deletable: true, visible: ['All'] },
    { title: 'Update DD', color: '#FF3D00', deletable: true, visible: ['All'] },
    { title: 'QC', color: '#00ACC1', deletable: true, visible: ['All'] },
    { title: 'Quote', color: '#808080', deletable: true, visible: ['All'] },
    { title: 'Scheduling Issue', color: '#FF8F00', deletable: true, visible: ['All'] },
    { title: 'Special Directives', color: '#EF6C00', visible: ['All'] },
    { title: 'Special Handling', color: '#5FCA8F', deletable: true, visible: ['All'] },
    { title: 'Waiting on Client', color: '#FF6DFF', deletable: true, visible: ['All'] },
    { title: 'Waiting on POC', color: '#FF6F00', deletable: true, visible: ['All'] },
    { title: 'Waiting on Appraiser', color: '#D4A017', deletable: true, visible: ['All'] },
    { title: 'QC Payment Hold', color: '#5E35B1', deletable: true, visible: ['QC Review'] },
    { title: 'Pre-Quote', color: '#8c0e51', deletable: true, visible: ['All'] },
]

export const filteredTagItem = ref(tagItems)

export const noteTags = [
    { tag: 'Accounting', color: '#2E7D32' },
    { tag: 'Appraiser Options/Decline', color: '#00838F' },
    { tag: 'Due Date', color: '#FF8F00' },
    { tag: 'Important', color: '#C62828' },
    { tag: 'Manager Message', color: '#6A1B9A' },
    { tag: 'Past Due Appraiser ETA', color: '#E65100' },
    { tag: 'Payment', color: '#1B5E20' },
    { tag: 'Client Approval', color: '#880E4F' },
    { tag: 'Pre-Quote', color: '#0277BD' },
    { tag: 'QC', color: '#455A64' },
    { tag: 'Special', color: '#5FCA8F' },
]

export const noteColors = {
    '#2E7D32': 'rgb(46, 125, 50, .1)',
    '#00838F': 'rgb(0, 131, 143, .1)',
    '#FF8F00': 'rgb(255, 143, 0, .1)',
    '#C62828': 'rgb(198, 40, 40, .1)',
    '#6A1B9A': 'rgb(106, 27, 154, .1)',
    '#E65100': 'rgb(230, 81, 0, .1)',
    '#FF00FF': 'rgb(255, 0, 255, .1)',
    '#0277BD': 'rgb(2, 119, 189, .1)',
    '#455A64': 'rgb(69, 90, 100, .1)',
    '#5FCA8F': 'rgb(95, 202, 143, .1)',
    '#D84315': 'rgb(216, 67, 21, .1)',
    '#FFC400': 'rgb(255, 196, 0, .1)',
    '#03A9F4': 'rgb(3, 169, 244, .1)',
} as Record<string, string>

export const masks = {
    phone: '(###) ###-####',
    phoneExt: '(###) ###-#### x######',
    card: '#### #### #### ####',
    exp: '##/##',
    ssn: '###-##-####',
}

export const clientTypes = ['Corporate', 'Branch Manager', 'Loan Officer', 'LO Assistant', 'Processor']

export const clientRoles = ['Manager', 'Individual', 'Limited', 'View Only']

export interface PermissionList {
    permission: string
    disabled?: (client: Client) => boolean
    requiresPageEnabled?: string
}

const permissions: PermissionList[] = [
    {
        permission: 'View All Group Orders',
        disabled: (client: Client) => client.branch?.lender?.brokers || client.branch?.lender?.company_id == null,
    },
    { permission: 'View All Lender Orders' },
    { permission: 'View All Branch Orders' },
    { permission: 'Manage Company Users' },
    { permission: 'Manage Branch Users' },
    { permission: 'Invoice' },
    { permission: 'Order' },
    { permission: 'Order for Others' },
    { permission: 'Order Snapshot Review' },
    {
        permission: 'Client Fee Estimator',
        requiresPageEnabled: 'clients.clientFeeEstimator',
    },
]

export const clientPermissions: ComputedRef<PermissionList[]> = computed(() => {
    return permissions.filter((elem) =>
        elem.permission === 'Order'
            ? AscentHelper.customerFeatureEnabled('clients.showClientOrderPermission')
            : AscentHelper.customerFeatureEnabled('clients.permissions.' + camelCase(elem.permission), true),
    )
})

export const clientNotifications = [
    { event: 'date_modification_request', label: 'Date Modification Requested' },
    { event: 'fee_modification_request', label: 'Fee Modification Requested' },
    { event: 'order_created', label: 'Order Created' },
    { event: 'underwriting_revision_rejected', label: 'UW Revision Rejected' },
    { event: 'scheduled', label: 'Inspection Scheduled' },
    { event: 'rescheduled', label: 'Inspection Rescheduled' },
    { event: 'schedule_second', label: 'Second Inspection Scheduled' },
]

export const clientAMCNotifications = [{ event: 'appraiser_accepted', label: 'Order Accepted By Vendor' }]

export const clientOfficeNotifications = [
    { event: 'office_accepted', label: 'Order Accepted By Vendor' },
    { event: 'office_conditionally_accept', label: 'Order Conditionally Accepted By Vendor' },
    { event: 'office_decline', label: 'Order Declined By Vendor' },
]

export const paymentTypes = [
    { id: 'credit-card', text: 'Credit Card' },
    { id: 'borrower-paid', text: 'Borrower' },
    { id: 'net-30', text: 'Invoice' },
    { id: 'split', text: 'Split' },
    { id: 'mercury-card', text: 'Mercury Card' },
    { id: 'deferred-card', text: 'Deferred - Waiting on Borrower' },
    { id: 'cod', text: 'COD' },
]

export const getSearchBackground = (search: string) => {
    if (search != null && search != '') return 'white'
    return 'accentWhite'
}

export default {
    namespaced,
}
