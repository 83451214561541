











import { defineComponent } from '@vue/composition-api'
import { orderCommercial } from '@/plugins/order/Order'
import EditOfficeCommercialDialog, {
    openEditOfficeCommercialDialog,
} from '@/components/OrderScreens/Office/OrderComponents/OfficeCommercial/EditOfficeCommercialDialog.vue'

export default defineComponent({
    components: {
        EditOfficeCommercialDialog,
    },
    setup() {
        return {
            orderCommercial,
            openEditOfficeCommercialDialog,
        }
    },
})
