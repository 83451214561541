var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"text-right"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"6"}},[(_vm.$ascent.canView('Appraisers'))?_c('v-btn',{staticClass:"mb-3",attrs:{"depressed":"","small":"","color":"grey lighten-3","width":"150"},on:{"click":function($event){$event.stopPropagation();return _vm.editAppraiser.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$vuetify.icons.externalLinkSquare")]),_vm._v(" View Profile ")],1):_vm._e(),(_vm.appraiser.allOtherOrders)?_c('v-chip',{staticStyle:{"width":"150px"},attrs:{"color":"primarylighten5 mt-2","small":""},on:{"click":function($event){return _vm.openAppraiserOrdersDialog(
                        _vm.appraiser.appraiserOrders,
                        _vm.item.appraiser.name,
                        _vm.appraiser.allOtherOrders
                    )}}},[_c('v-avatar',{staticClass:"white--text mr-2",attrs:{"color":"primary","dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.appraiser.allOtherOrders)+" ")]),_vm._v(" Other orders ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(
                    _vm.allowSelect &&
                    !_vm.isSameAppraiser &&
                    !_vm.isConditional &&
                    _vm.order &&
                    _vm.order.status !== 'Payment Hold' &&
                    _vm.order.status !== 'Hold'
                )?_c('v-btn',{attrs:{"small":"","color":_vm.isAppraiserEligible ? 'secondary' : 'error',"depressed":""},on:{"click":_vm.assignOrder}},[_vm._v(" "+_vm._s(_vm.isAppraiserEligible ? 'Assign Appraiser' : 'Submit Override')+" ")]):_vm._e(),(!_vm.isAppraiserEligible)?_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.eligibilityDetails)+" ")]):_vm._e(),(_vm.appraiserStagger !== -1)?_c('v-chip',{staticClass:"mt-2",attrs:{"color":"warning","small":""}},[_vm._v(" Stagger "+_vm._s(_vm.item.appraiser.options[_vm.appraiserStagger].stagger)+" days. ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }