import { get } from 'lodash'
import { DateTime } from 'luxon'
import $axios from '@/plugins/axios'
import FileDownload from 'js-file-download'
import Dinero from 'dinero.js'
import CanView from '@/store/Helpers/CanView'
import CanDo from '@/store/Helpers/CanDo'
import { user, customerPages, currentCustomer, enabledCustomerFeatures, userFeatures } from '@/plugins/User'
import { customerFees, enabledFeatures, order } from '@/plugins/order/Order'
import OrderHelper from '@/helpers/OrderHelper'

export default class AscentHelper {
    static getAscentCache() {
        const ascentCacheJSON = localStorage.getItem('ac')
        if (ascentCacheJSON) {
            return JSON.parse(ascentCacheJSON)
        }

        return false
    }

    static formatDateAscent(date, format, local = true) {
        if (date == null) {
            return null
        }
        if (local) {
            return DateTime.fromSQL(date, { zone: 'UTC' }).toLocal().toFormat(format)
        }

        return DateTime.fromSQL(date).toFormat(format)
    }

    static updateAscentCache() {
        const axiosConfig = {
            withCredentials: false,
            validateStatus: (status) => {
                return !(status === 304)
            },
        }
        const ascentCache = JSON.parse(localStorage.getItem('ac'))
        // Add this header to check if we need to download a new version
        if (ascentCache) {
            axiosConfig.headers = { 'If-None-Match': ascentCache.version }
        }

        if (user.value.customer_id !== null && AscentHelper.isCustomer()) {
            $axios
                .get(
                    'https://ascent-public-files.s3.amazonaws.com/ascent-cache/blackout-dates-' +
                        user.value.customer_id +
                        '.json',
                    axiosConfig,
                )
                .then((response) => {
                    const newAscentCache = response.data
                    newAscentCache.version = response.headers.etag
                    localStorage.setItem('ac', JSON.stringify(newAscentCache))
                })
                .catch((error) => {
                    if (error.response && error.response.status === 304) {
                        // File hasn't changed, so do nothing
                    }
                })
        }
    }

    static isDateBlackedOut(date) {
        const ascentCache = AscentHelper.getAscentCache()
        if (ascentCache && ascentCache.blackoutDates) {
            return !ascentCache.blackoutDates.includes(date)
        }

        return true
    }

    static formatIsoDate(date, format, local = true) {
        let returnDate = null
        if (date == null) {
            return null
        }
        if (local) {
            returnDate = DateTime.fromISO(date, { zone: 'UTC' }).toLocal().toFormat(format)
        } else {
            returnDate = DateTime.fromISO(date, { zone: 'UTC' }).toFormat(format)
        }

        if (returnDate === 'Invalid DateTime') {
            returnDate = this.formatDateAscent(date, format, local)
        }

        return returnDate
    }

    static ucfirst(string) {
        if (typeof string != 'string') {
            return ''
        }
        return string.toLowerCase().replace(/\b[a-z]/, function (letter) {
            return letter.toUpperCase()
        })
    }

    static ucwords(string) {
        if (typeof string != 'string') {
            return ''
        }
        return string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase()
        })
    }

    static space(string) {
        if (typeof string != 'string') {
            return ''
        }
        return string.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`).replace(/_/g, ' ')
    }

    static formatUnix(date, format = 'MM/dd/yyyy', local = true) {
        let returnDate = null
        if (date == null) {
            return null
        }
        if (local) {
            returnDate =
                date.toString().length > 10
                    ? DateTime.fromMillis(date, { zone: 'UTC' }).toLocal().toFormat(format)
                    : DateTime.fromSeconds(date, { zone: 'UTC' }).toLocal().toFormat(format)
        } else {
            returnDate =
                date.toString().length > 10
                    ? DateTime.fromMillis(date, { zone: 'UTC' }).toFormat(format)
                    : DateTime.fromSeconds(date, { zone: 'UTC' }).toFormat(format)
        }

        if (returnDate === 'Invalid DateTime') {
            returnDate = this.formatDateAscent(date, format, local)
        }

        return returnDate
    }

    static canDo(permission) {
        return CanDo(permission)
    }

    static canView(permission) {
        return CanView(permission)
    }

    static pageEnabled(page) {
        return get(customerPages.value, page, false)
    }

    static featureEnabled(feature, defaultValue = false) {
        return get(enabledFeatures.value, feature, defaultValue)
    }

    static customerFeatureEnabled(feature, defaultValue) {
        return get(enabledCustomerFeatures.value, feature, defaultValue)
    }

    static orderCustomerFeatureEnabled(customerFeatures, feature, defaultValue = false) {
        if (!customerFeatures) return defaultValue
        return get(customerFeatures, feature, defaultValue)
    }

    static translate(feature, defaultValue) {
        return get(enabledCustomerFeatures.value, feature, defaultValue)
    }

    static customerFee(fee, defaultValue = 0) {
        return get(customerFees.value, fee, defaultValue)
    }

    static userFeatureEnabled(feature, defaultValue = false) {
        return get(userFeatures.value, feature, defaultValue)
    }

    static userOrCustomerFeatureEnabled(customer, user, defaultValue = false) {
        return (
            AscentHelper.customerFeatureEnabled(customer, defaultValue) ||
            AscentHelper.userFeatureEnabled(user, defaultValue)
        )
    }

    static isCustomer() {
        return ['amc', 'desk', 'office'].indexOf(user.value?.type) > -1
    }

    static parsePercent = (value) => {
        if (isNaN(parseFloat(value))) {
            return 0
        }
        return Math.round((parseFloat(value) + Number.EPSILON) * 100)
    }

    static formatPercent = (percent) => {
        return AscentHelper.parsePercent(percent) / 100 + '%'
    }

    static parseCurrency = (value) => {
        if (isNaN(parseFloat(value))) {
            return 0
        }
        return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100
    }

    static currency(value, format = '$0,0.00') {
        if (isNaN(parseFloat(value))) {
            return ''
        }

        if (typeof value == 'string' && value.includes(',')) {
            value = value.replace(/,/, '')
        }

        if (typeof value == 'string' && value.includes('$')) {
            value = value.replace(/$/, '')
        }

        const price = Dinero({ amount: Math.round((parseFloat(value) + Number.EPSILON) * 100) })
        return price.toFormat(format)
    }

    static shortCurrency(value) {
        if (isNaN(parseFloat(value))) {
            return ''
        }

        return new Intl.NumberFormat('en-US', {
            notation: 'compact',
            compactDisplay: 'short',
        }).format(value)
    }

    static capitalize(value) {
        return value
    }

    static formatNumber(value) {
        if (isNaN(parseFloat(value))) {
            return 0
        }
        return new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2,
        }).format(value)
    }

    static phone(phone) {
        if (phone == null) {
            return ''
        }
        const numbers = phone.replace(/[^0-9]/g, '')
        if (numbers.length === 10) {
            return numbers.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        }
        const main = numbers.substring(0, 10)
        const ext = numbers.slice(numbers.length - (numbers.length - 10))
        return main.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') + ' x' + ext
    }

    static isPaid(order) {
        if (!order) return false
        if (order.paidStatus === 'Paid') {
            return true
        }
        if (order.billingMethod === 'net-30') {
            return true
        }
        if (order.billingMethod === 'split' && order.billingDetails != null && order.billingDetails.splits) {
            if (
                order.due <=
                order.billingDetails.splits.reduce((total, elem) => {
                    if (elem.method === 'net-30') {
                        return total + parseFloat(elem.amount)
                    }
                    return total
                }, 0)
            ) {
                return true
            }
        }
        return false
    }

    static canBePaid(order, incremental) {
        if (!order) return false
        if (!incremental) return false
        if (order.billingMethod === 'credit-card' && incremental.cards.length > 0) {
            return true
        }
        if (order.billingMethod === 'mercury-card' || order.billingMethod === 'deferred-card') {
            return true
        }
        if (order.billingMethod === 'valuepad-card') {
            return true
        }
        if (order.billingMethod === 'borrower-paid') {
            return true
        }
        if (order.billingMethod === 'split' && order.billingDetails != null && order.billingDetails.splits) {
            if (
                order.due >
                order.billingDetails.splits.reduce((total, elem) => {
                    if (elem.method === 'net-30') {
                        return total + parseFloat(elem.amount)
                    }
                    return total
                }, 0)
            ) {
                return true
            }
        }
        return false
    }

    static downloadFile(data, name) {
        FileDownload(data, name)
    }

    static getUserType() {
        return user.value?.type
    }

    static getCurrentCustomer() {
        return currentCustomer.value
    }

    static wholeNumberFormatting(num) {
        return num % 1 === 0 ? '$0,0' : '$0,0.00'
    }

    static checkXml(data) {
        if (typeof data['VALUATION_RESPONSE'] == 'undefined') {
            return {
                error: true,
                message:
                    'Our Automated check could not validate that the XML contains an appraisal. Please upload a different file.',
            }
        }
        if (
            data['VALUATION_RESPONSE']['REPORT']['_attributes'].AppraisalFormType !== 'FNM1004D' &&
            OrderHelper.is1004D.value
        ) {
            return {
                error: true,
                message:
                    'It appears you are trying to upload the full appraisal report to this order. Please upload just the final inspection report.',
            }
        }
        const reportAddress = data['VALUATION_RESPONSE']['PROPERTY']['_attributes']['_StreetAddress']
            .replace(/\W/g, '')
            .toLowerCase()
            .substr(0, 5)
        if (!order.value) return { error: true, message: 'No order loaded' }
        const property = order.value.address.street.replace(/\W/g, '').toLowerCase().substr(0, 5)
        if (reportAddress !== property) {
            return {
                error: true,
                message:
                    'Our automated check could not match the street address on the report to this order. The street address in the report is ' +
                    data['VALUATION_RESPONSE']['PROPERTY']['_attributes']['_StreetAddress'] +
                    ' and this order is ' +
                    order.value.address.street +
                    '. Please upload a different report. ',
            }
        }
        return { error: false, message: 'Success' }
    }

    static checkAllowedCardTypes(disallowed = [], cardNumber = '') {
        const cardTypeMessages = {
            amex: 'American Express cards are not allowed. Please try another card.',
            visa: 'Visa cards are not allowed. Please try another card.',
            mastercard: 'Mastercard cards are not allowed. Please try another card.',
            discover: 'Discover cards are not allowed. Please try another card.',
        }

        for (const cardType of disallowed) {
            const firstDigit = cardNumber.charAt(0)
            if (
                (cardType === 'amex' && firstDigit === '3') ||
                (cardType === 'visa' && firstDigit === '4') ||
                (cardType === 'mastercard' && firstDigit === '5') ||
                (cardType === 'discover' && firstDigit === '6')
            ) {
                return { allowed: false, message: cardTypeMessages[cardType] }
            }
        }

        return { allowed: true, message: '' }
    }

    static capitalizeFirstLetterOfString(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
}
