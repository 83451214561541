




































































































import Vue from 'vue'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { order, refreshOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { duplicateFiles, getDuplicateFiles } from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import { File } from '@/types'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
export default defineComponent({
    name: 'CustomerOrderDuplicates',
    setup() {
        const expanded = ref(false)
        const dialog = ref(false)
        const selected = ref<File[]>([])

        const headers = [
            { text: 'Order ID', align: 'start', sortable: false, value: 'order_id', class: 'sticky-header' },
            { text: 'Product Type', align: 'center', sortable: false, value: 'type', class: 'sticky-header' },
            { text: 'Client Name', align: 'center', sortable: false, value: 'clientName', class: 'sticky-header' },
            { text: 'Appraiser', align: 'center', sortable: false, value: 'appraiserName', class: 'sticky-header' },
            { text: 'Status', align: 'center', sortable: false, value: 'status', class: 'sticky-header' },
            { text: '', align: 'center', sortable: false, value: 'actions', id: 'actions', class: 'sticky-header' },
            { text: '', value: 'data-table-expand', class: 'sticky-header' },
        ]

        const removeDuplicate = (id: number) => {
            confirm(
                'Remove Duplicate?',
                'Are you sure you want to remove this duplicate? This action cannot be undone and it will not show as a duplicate while the order is in progress.',
                { confirm: 'Remove Duplicate', color: 'error', cancel_color: 'accentBlack' },
            ).then((confirm) => {
                if (confirm) {
                    $axios.delete('/v1/duplicate/' + id).then(() => refreshOrder())
                }
            })
        }

        const removeDuplicates = () => {
            confirm(
                'Remove Duplicates?',
                'Are you sure you want to remove the selected duplicates? This action cannot be undone and they will not show as a duplicate while the order is in progress.',
                { confirm: 'Remove Duplicates', color: 'error', cancel_color: 'accentBlack' },
            ).then((confirm) => {
                if (confirm) {
                    selected.value.forEach((f: File) => {
                        $axios.delete('/v1/duplicate/' + f.id)
                    })
                    refreshOrder()
                }
            })
        }

        const openFile = (file: File) => {
            $axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        }

        const addFileToOrder = (file: File, fileIndex: number, duplicateIndex: number) => {
            if (!order.value) return
            $axios
                .post('/v1/order/' + order.value.id + '/action/add-existing-file', {
                    id: file.id,
                })
                .then((response) => {
                    showSnackbar(response.data)
                    duplicateFiles.value[duplicateIndex][fileIndex].addedToOrder = true
                    Vue.set(duplicateFiles.value, duplicateIndex, duplicateFiles.value[duplicateIndex])
                })
                .catch((error) => {
                    showError(error.response.data.message)
                })
        }

        const getColor = (status: string) => {
            switch (status) {
                case 'All':
                    return 'successdarken2'
                case 'Admin':
                    return 'teal darken-3'
                case 'Appraiser':
                    return 'yellow darken-4'
                case 'Client':
                    return 'purple darken-3'
                case 'Inactive':
                    return 'red darken-3'
            }
        }

        onMounted(() => {
            getDuplicateFiles()
        })

        return {
            expanded,
            order,
            dialog,
            selected,
            headers,
            removeDuplicate,
            duplicateFiles,
            openFile,
            addFileToOrder,
            getColor,
            removeDuplicates,
        }
    },
})
