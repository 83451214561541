<template>
    <span>
        <charge-card
            v-if="
                (data.billingMethod === 'credit-card' || data.billingMethod === 'borrower') &&
                data.source !== 'mercury' &&
                data.due > 0 &&
                data.billingMethod !== 'split'
            "
            :id="data.id"
            :due="data.due"
            :total="data.fee"
            @charged="getAppraisals"
        />
        <charge-mercury-card
            v-if="data.billingMethod === 'mercury-card' && data.due > 0"
            :id="data.id"
            :due="data.due"
            :total="data.fee"
            @charged="getAppraisals"
        />
        <charge-valuepad-card
            v-if="data.billingMethod === 'valuepad-card' && data.due > 0"
            :id="data.id"
            :due="data.due"
            :total="data.fee"
            @charged="getAppraisals"
        />
        <split-payment-charge v-if="data.billingMethod === 'split'" :local-order="data" />
    </span>
</template>

<script lang="js">
import {defineComponent} from "@vue/composition-api"
import {mapFields} from "vuex-map-fields"
import ChargeCard from "@/components/OrderScreens/Amc/AMCActions/Payment/ChargeCard.vue"
import ChargeMercuryCard from "@/components/OrderScreens/Amc/AMCActions/Payment/ChargeMercuryCard.vue"
import ChargeValuepadCard from "@/components/OrderScreens/Amc/AMCActions/Payment/ChargeValuepadCard.vue"
import SplitPaymentCharge from "@/components/OrderScreens/Amc/AMCActions/Payment/SplitPaymentCharge.vue"
import {getBillingOrders} from '@/pages/AMC/Accounting/ClientBilling/ClientBilling'

/**
 * @name ColumnClientBillingTools
 * Quick way for users to bill
 *
 * Not currently in production
 */

export default defineComponent({
        components: {
            ChargeCard,
            ChargeMercuryCard,
            ChargeValuepadCard,
            SplitPaymentCharge
        },
        methods: {
            getAppraisals() {
                getBillingOrders()
            }
        },
        computed: {
            ...mapFields('ClientBilling', [
                'bulk',
            ]),
        }
    })
</script>
