
























































































































































































import { defineComponent, computed } from '@vue/composition-api'
import { DateTime } from 'luxon'
import AssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignPanels.vue'
import QuoteTable from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/QuoteTable.vue'
import SendQuoteAssignTable from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/SendQuoteAssignTable.vue'
import { getAllQuotes, quotesArray, sendQuotes as quotes } from '@/pages/AMC/Quotes/Quotes.ts'
import {
    allAppraisers,
    selectedAppraiser,
    allowSelect,
    GET_PANELS,
    setClientDeadline,
    setAppraiserDeadline,
    // appraiserDeadline,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AscentHelper from '@/helpers/ascent-helper'
import { order } from '@/plugins/order/Order'
import { orderProducts } from '@/plugins/order/OrderProducts'
import OverridePanelAssignDialog, {
    overrideDialog,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/OverridePanelAssignDialog.vue'
import { Appraiser } from '@/types'
import AssignDialog, { openAssignDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignDialog.vue'
import { requiresDateModification } from '@/plugins/order/OrderModification'

/**
 * @name AssignAppraiser
 * AssignAppraiser workflow step for Amc that has the assign panels for the order, the quotes table, and the deadlines for the appraiser and the client
 *
 * @SetupData completeQuotes - returns array of appraisers where quote is null
 */

export const openOverrideDialogAction = () => {
    overrideDialog.value = true
}

export const sendToAssignDialogAction = (overrideAppraiser: Appraiser, appraiserDue: string, clientDue: string) => {
    overrideDialog.value = false
    openAssignDialog({
        appraiser: overrideAppraiser,
        appraiser_deadline: appraiserDue,
        client_deadline: clientDue,
        details: '',
        eligible: true,
        manually_overridden: true,
    })
}

export default defineComponent({
    setup() {
        const completeQuotes = computed(() => {
            if (allAppraisers.value.length === 0) return []
            return allAppraisers.value.filter((elem) => {
                return elem.appraiser.quote != null
            })
        })

        return {
            selectedAppraiser,
            allAppraisers,
            quotesArray,
            completeQuotes,
            quotes,
            overrideDialog,
            order,
        }
    },
    components: {
        AssignPanels,
        QuoteTable,
        SendQuoteAssignTable,
        OverridePanelAssignDialog,
        AssignDialog,
    },
    data() {
        return {
            client: '',
            appraiser: '',
            error: false,
            panel_id: null,
            DateTime: DateTime,
            availableAmcs: [],
            focusedIndex: 0,
            appraiserDateMenu: false,
            clientDateMenu: false,
        }
    },
    computed: {
        selected() {
            return this.selectedAppraiser
        },
        cannotAssign() {
            if (order.value?.clientDeadline === null) return false
            return (
                requiresDateModification.value &&
                order.value &&
                AscentHelper.formatUnix(order.value.clientDeadline, 'yyyy-MM-dd', false) !== this.client
            )
        },
    },
    mounted() {
        GET_PANELS({
            type: 'order',
            id: order?.value?.id as number,
        }).then(() => {
            if (orderProducts.value.find((el) => el.product_name === '1004D Final Inspection')) {
                selectedAppraiser.value = order.value?.incremental?.duplicates?.find(
                    (el) => el.duplicate_cache?.status === 'Completed',
                )?.duplicate_cache?.appraiser_id
            }
        })
        getAllQuotes('order', order?.value?.id as number)
        allowSelect.value = true
        this.setDates()
        if (order.value?.incremental?.customer.type === 'desk') {
            this.getAvailableAmcs()
        }
    },
    methods: {
        setDates(): void {
            if (order.value && order.value.appraiserDeadline != null) {
                setAppraiserDeadline(AscentHelper.formatUnix(order.value.appraiserDeadline, 'yyyy-MM-dd', false))
                this.appraiser = AscentHelper.formatUnix(order.value.appraiserDeadline, 'yyyy-MM-dd', false)
            }
            if (order.value && order.value.clientDeadline != null) {
                setClientDeadline(AscentHelper.formatUnix(order.value.clientDeadline, 'yyyy-MM-dd', false))

                this.client = AscentHelper.formatUnix(order.value.clientDeadline, 'yyyy-MM-dd', false)
            }
            this.syncAppraiser(this.client !== '' ? this.client : DateTime.local().toISODate())
        },
        syncAppraiser(value: string) {
            if (DateTime.fromISO(value).weekday === 1) {
                // Monday
                this.appraiser = DateTime.fromISO(value).minus({ days: 3 }).toISODate()
            } else {
                this.appraiser = DateTime.fromISO(value).minus({ days: 1 }).toISODate()
            }

            this.updateClientDeadline()
            this.updateAppraiserDeadline(this.appraiser)
        },
        updateAppraiserDeadline(value: string) {
            setAppraiserDeadline(value)
        },
        updateClientDeadline() {
            setClientDeadline(this.client)
        },
        checkDeadlineDates(val: string) {
            // if(AscentHelper.canDo('Approve Same Day Due Dates')) return AscentHelper.isDateBlackedOut(val)
            // return AscentHelper.isDateBlackedOut(val) && val !== appraiserDeadline.value
            return AscentHelper.isDateBlackedOut(val)
        },
        getAvailableAmcs() {
            if (!order.value) return
            this.$axios.post(`/v1/get-amc-assign-order/state/${order.value.address?.state}`).then((response) => {
                this.availableAmcs = response.data
            })
        },

        assignAmc(amc: Appraiser) {
            openAssignDialog({
                appraiser: amc,
                appraiser_deadline: this.appraiser,
                client_deadline: this.client,
                details: '',
                eligible: true,
                manually_overridden: false,
            })
        },
    },
})
