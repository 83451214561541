




















































import { defineComponent, PropType } from '@vue/composition-api'

/**
 * @name 'Notification Group'
 * Is all the notifications for the user, the order and ticket notifications.
 */
export default defineComponent({
    props: {
        /**
         * Title of the notification group
         */
        title: {
            type: String,
            default: 'Notifications',
        },
        /**
         * An Array of the notifications
         */
        items: {
            type: Array as PropType<string[]>,
            default: () => {
                return []
            },
        },
        limit: {
            type: Number,
            default: null,
        },
        unread: {
            type: Boolean,
            default: false,
        },
        /**
         * Indicates to toggle the read status
         */
        toggleRead: {
            type: Boolean,
            default: true,
        },
        /**
         * value to indicate the mass Read values
         */
        massRead: {
            type: Boolean,
            default: true,
        },
        /**
         * Color of the notification icon
         */
        color: {
            type: String,
            default: 'primary',
        },
    },
    data() {
        return {
            /**
             * The tooltip hover
             */
            hover: -1,
        }
    },
    computed: {
        /**
         * Toggles the filtered items
         */
        itemsFiltered(): string[] {
            return this.limit ? this.items.slice(0, this.limit) : this.items
        },
        /**
         * Indicates if they can mark all items as read
         */
        canMarkAll(): boolean {
            if (this.unread === true && this.toggleRead === true) {
                return this.massRead
            }

            return false
        },
        /**
         * Style of the header
         */
        headerStyle() {
            return {
                color: 'primary',
            }
        },
    },
    methods: {
        /**
         * Emits the event to mark all as read
         */
        emitMarkAll() {
            this.$emit('mark-all')
        },
        /**
         * Emits the event to mark notification as read
         */
        emitReadClick(item: string) {
            this.$emit('read-toggle', item)
        },
        /**
         * Emits the event to view the notification
         */
        emitViewClick(item: string) {
            this.$emit('view', item)
        },
    },
})
