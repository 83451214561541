var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order)?_c('a-card',{staticClass:"px-0 border-bottom-5 overflow-y-scroll",attrs:{"tile":"","color":"transparent","elevation":0}},[(_vm.order.incremental)?_c('v-row',[_c('v-card-text',[_c('v-data-table',{staticClass:"scroll-table",attrs:{"headers":_vm.headers,"items":_vm.filteredFiles,"item-key":"id","hide-default-header":"","hide-default-footer":"","items-per-page":-1,"fixed-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{staticClass:"px-1 sticky-header",attrs:{"colspan":"2"}},[_c('v-checkbox',{staticClass:"ml-1",staticStyle:{"font-weight":"normal"},attrs:{"color":"primary","label":_vm.selectAllLabel},on:{"change":_vm.toggleAllRows},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1),_c('th',{staticClass:"pr-0 sticky-header",attrs:{"colspan":headers.length - 2}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],staticClass:"mt-4 pt-1 ml-5 mb-5 float-right"},[_c('v-icon',{staticClass:"mr-3",on:{"click":function($event){return _vm.openBulkShareFile(_vm.selected)}}},[_vm._v(" $vuetify.icons.shareNodes ")]),_c('v-icon',{staticClass:"mr-3",on:{"click":function($event){return _vm.download(_vm.selected)}}},[_vm._v(" $vuetify.icons.download ")]),_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.bulkDelete}},[_vm._v(" $vuetify.icons.trashCan ")])],1),(_vm.hasInactive)?_c('v-switch',{staticClass:"float-right font-normal",attrs:{"dense":"","inset":"","label":"Archived"},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}}):_vm._e()],1)])])]}},{key:"item.selectedRow",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ml-1",attrs:{"value":item.id,"color":"primary"},on:{"click":function($event){return _vm.toggleRow()}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"caption",style:(_vm.getStyle(item.status)),on:{"click":function($event){$event.preventDefault();return _vm.openFile(item)}}},[_vm._v(" "+_vm._s(item.filename)+" ")]),_c('br'),_c('span',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.transformFileDescriptionString(item))}}),_c('br')]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"small",attrs:{"x-small":""}},[_vm._v(_vm._s(item.type))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","dark":"","color":_vm.getColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-col',{staticClass:"mx-0 px-1 pt-4 align-center",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"accentBlack","icon":"","small":""}},[_c('v-icon',{staticClass:"m-1",attrs:{"small":""},on:{"click":function($event){return _vm.download([item.id])}}},[_vm._v(" $vuetify.icons.download ")])],1)],1),_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"6"}},[_c('a-btn-menu',{staticClass:"mx-0 px-1",attrs:{"color":"accentBlack"}},[(_vm.$ascent.canDo('Work'))?_c('div',[(item.status !== 'Inactive')?_c('v-list-item',{on:{"click":function($event){return _vm.open(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit File")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.send(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Share File")])],1)],1),(
                                            item.type === 'Order Form PDF' &&
                                            item.status !== 'Inactive' &&
                                            _vm.order.incremental.customer.type !== 'office'
                                        )?_c('div',[_c('v-list-item',{on:{"click":function($event){return _vm.regenerateFile(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Regenerate "+_vm._s(item.type))])],1)],1),_c('v-list-item',{on:{"click":_vm.editOrderForm}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit Lender Details")])],1)],1)],1):_vm._e(),_c('br'),_c('v-list-item',{on:{"click":function($event){return _vm.deleteFile(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1):_vm._e()])],1)],1)]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-list',{staticStyle:{"width":"100%"}},[(
                    _vm.$ascent.featureEnabled('orderScreen.autoInvoice') &&
                    _vm.files.find(function (elem) { return elem.type === 'Invoice'; }) === undefined
                )?_c('invoice-file',{on:{"download":_vm.downloadPDF}}):_vm._e(),(
                    _vm.order.incremental.reconsideration &&
                    _vm.files.find(function (elem) { return elem.type === 'Reconsideration PDF'; }) === undefined
                )?_c('reconsideration-file',{on:{"download":_vm.downloadPDF}}):_vm._e(),(
                    _vm.$ascent.featureEnabled('orderScreen.useEngagementLetter') &&
                    _vm.files.find(function (elem) { return elem.type === 'Order Form PDF'; }) === undefined
                )?_c('order-pdf',{on:{"download":_vm.downloadPDF}}):_vm._e(),(_vm.$ascent.featureEnabled('orderScreen.autoComplianceFiles') && _vm.isCompleted)?_c('compliance-file',{on:{"download":_vm.downloadPDF}}):_vm._e()],1)],1),_c('EditFile'),_c('EditBulkFileDialog'),_c('SendInvoiceDialog'),_c('edit-order-form',{ref:"editOrderForm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }