










































import { defineComponent, ref } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadOrderDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadOrderDetails/PreloadOrderDetails.vue'
import PreloadSubjectInfo from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSubjectInfo/PreloadSubjectInfo.vue'
import PreloadContract from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadContract/PreloadContract.vue'
import PreloadSaleHistory from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSaleHistory/PreloadSaleHistory.vue'
import PreloadZoning from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadZoning/PreloadZoning.vue'
import $axios from '@/plugins/axios'
import {
    step,
    preloadData,
    preloadSteps,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import { order } from '@/plugins/order/Order'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import EditOfficeOrderInstructionsDialog, {
    openEditInstructions,
} from '@/components/OrderScreens/Office/OrderComponents/OrderInstructions/EditOfficeOrderInstructionsDialog.vue'
import OfficeOrderInstructions from '@/components/OrderScreens/Office/OrderComponents/OrderInstructions/OfficeOrderInstructions.vue'

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadOrderDetails,
        PreloadSubjectInfo,
        PreloadContract,
        PreloadSaleHistory,
        PreloadZoning,
        OfficeOrderInstructions,
        EditOfficeOrderInstructionsDialog,
    },
    setup() {
        const previousPreload = () => {
            step.value -= 1
        }

        const stripTags = (message: string) => {
            return message
                .replace(/<\/p>/g, ' ')
                .replace(/<br>/g, ' ')
                .replace(/<[^>]+>/g, '')
        }

        const loading = ref(false)

        const submitPreload = async () => {
            if (!order.value) return
            loading.value = true
            if (preloadData.value && preloadData.value.primary?.propertyRightsDetails) {
                preloadData.value.primary.propertyRightsDetails = stripTags(
                    preloadData.value.primary.propertyRightsDetails,
                )
            }
            await $axios
                .post(`/v1/order/${order.value.id}/action/save-preload`, {
                    preload: preloadData.value,
                })
                .finally(() => {
                    showSnackbar('Preload Submitted Successfully')
                    step.value += 1
                    loading.value = false
                })
                .catch(({ response }) => {
                    loading.value = false
                    if (response.data.message) return showError(response.data.message)
                    return showError('Something went wrong')
                })
        }

        return {
            previousPreload,
            submitPreload,
            loading,
            preloadSteps,
            order,
            openEditInstructions,
        }
    },
})
