





import { defineComponent, computed } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    name: 'PaymentLinkAction',
    setup() {
        const url = computed(() => {
            if (!order.value) return ''
            if (!order.value.incremental) return ''
            const token = order.value.incremental.token
            const tld = process.env.VUE_APP_ENV === 'production' ? 'com' : 'test'
            return order.value.customer_id === 6 && process.env.VUE_APP_ENV !== 'dev'
                ? `https://movement.myappraisalorder.${tld}/appraisal/payment/` + token
                : '/appraisal/payment/' + token
        })

        return {
            order,
            url,
        }
    },
})
