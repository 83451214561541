


























import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order } from '@/plugins/order/Order'
import { Appraiser } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { closeOrder } from '@/plugins/order/Order'
import { open as openEditOrderInvoiceDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderInvoiceDialog.vue'
import { openEditMilestone } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/EditMilestoneDialog.vue'

interface DialogContent {
    appraiser: Appraiser
    appraiser_deadline: string
    client_deadline: string
    eligible: boolean
    details: string
}

const dialog = ref(false)
const reason = ref('')

export const openDecline = () => {
    dialog.value = true
}
export default defineComponent({
    components: {
        ValidationObserver,
    },
    setup() {
        const submit = () => {
            closeOrder()
            showSnackbar('Order Declined')
        }
        return { order, openEditOrderInvoiceDialog, openEditMilestone, dialog, reason, submit }
    },
})
