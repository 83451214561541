




import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    setup() {
        const requestFile = () => {
            if (!order.value) return
            axios.post('/v1/order/' + order.value.id + '/request-tdcx').then((response) => {
                showSnackbar(response.data.message ?? 'TDCX File Is Generating')
            })
        }
        return {
            requestFile,
        }
    },
})
