







import { defineComponent, onMounted, onUnmounted } from '@vue/composition-api'
import OfficeEvaluate from '@/components/OrderScreens/Office/OrderComponents/Workflows/ConditionalWorkflows/OfficeEvaluate.vue'
import { order } from '@/plugins/order/Order'
import OfficeAssignPanel from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignPanel.vue'
import { allowSelect } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'

export default defineComponent({
    components: {
        OfficeEvaluate,
        OfficeAssignPanel,
    },
    setup() {
        onMounted(() => {
            allowSelect.value = false
        })

        onUnmounted(() => {
            allowSelect.value = true
        })

        return {
            order,
        }
    },
})
