











































































import { user } from '@/plugins/User'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { JaroPanel } from '@/types'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import draggable from 'vuedraggable'

interface DraggableMove {
    moved: {
        element: {
            id: number
            name: string
            priority: number
        }
        newIndex: number
        oldIndex: number
    }
}

export default defineComponent({
    components: { ValidationObserver, draggable },
    props: {
        option: {
            type: Object,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const optionEnabled = ref(props.option?.option_value.enabled || false)
        const panelPriorities = ref<{ id: number; name: string; priority: number }[]>([])

        const saveChanges = () => {
            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'jaro-panel-priority',
                    option_value: {
                        enabled: optionEnabled.value,
                        priorities: panelPriorities.value
                    },
                })
                .then(() => {
                    showSnackbar('Panel priority saved successfully')
                    dialog.value = false
                })
                .catch(() => {
                    showSnackbar('An error occurred while trying to save panel priority.')
                })
        }

        const updatePanelOrder = (eventTarget: DraggableMove) => {
            if (eventTarget.moved) {
                panelPriorities.value.forEach((panel, idx) => (panel.priority = idx))
            }
        }

        onBeforeMount(() => {
            panelPriorities.value = props.option?.option_value.priorities || []

            if (panelPriorities.value.length === 0) {
                axios.get('/v1/customer/' + user.value?.customer_id + '/jaroPanel').then((r) => {
                    panelPriorities.value = r.data.map((panel: JaroPanel, idx: number) => {
                        return { id: panel.id, name: panel.name, priority: idx }
                    })
                })
            }
        })

        return {
            dialog,
            lender,
            optionEnabled,
            panelPriorities,
            saveChanges,
            updatePanelOrder,
        }
    },
})
