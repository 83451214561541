









































import { defineComponent } from '@vue/composition-api'
import { File } from '@/types'

function reset() {
    return {
        newFile: false,
        added: false,
        loading: false,
        file_type: null,
        allowed: ['.pdf', '.doc', '.docx', '.xml', '.xlsx', '.xls', '.env', '.pdco'],
    }
}

import { ValidationObserver } from 'vee-validate'
import FileUpload from '@/components/General/FileUpload.vue'
import { getFiles } from '@/plugins/Files'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'

/**
 * @name AddAppraiserFileDialog
 * Dialog in Appraiser Order Screen to allow the appraiser upload a file onto the order
 */

export default defineComponent({
    name: 'AddOrderFileDialog',
    components: {
        ValidationObserver,
        FileUpload,
    },
    data() {
        return reset()
    },
    computed: {
        /**
         * The url for the specific order
         */
        url() {
            return 'order/' + order.value?.id
        },
        tryingToUploadReport() {
            if (
                !user.value ||
                !order.value ||
                user.value.type !== 'appraiser' ||
                !['Waiting Inspection', 'Complete'].includes(order.value.status)
            )
                return { active: false, message: '' }
            let appraiserReportErrorMessage
            switch (order.value.status) {
                case 'Waiting Inspection':
                    appraiserReportErrorMessage =
                        'You\'ll need to change the inspection date to the past by clicking the "Change Inspection Date" button in the order workflow.'
                    break
                default:
                    appraiserReportErrorMessage =
                        "You'll need to upload the report using the tool in the order workflow."
                    break
            }
            return { active: true, message: appraiserReportErrorMessage }
        },
    },
    methods: {
        /**
         * Triggers the fileUpload component to upload the file to S3
         */
        uploadFile() {
            this.loading = true

            const fileUpload = this.$refs.fileUpload as Vue & { triggerUpload: () => void }
            fileUpload.triggerUpload()
        },
        /**
         * Will cancel upload and reset files
         */
        cancel() {
            const fileUpload = this.$refs.fileUpload as Vue & { resetFiles: () => void }
            fileUpload.resetFiles()
            this.newFile = false
        },
        /**
         * After the upload, will save the file path to the specific order. Posts to '/v1/order/' + order.value.id + '/file'
         * @param {File} file - File that has uploaded to S3
         */
        saveFile(file: File) {
            if (order.value) {
                this.$axios
                    .post('/v1/order/' + order.value.id + '/file', {
                        type: 'Additional',
                        path: file.path,
                        status: 'ALL',
                    })
                    .then(() => {
                        Object.assign(this.$data, reset())
                        if (order.value) {
                            getFiles(order.value.id)
                        }
                        const fileUpload = this.$refs.fileUpload as Vue & { resetFiles: () => void }
                        fileUpload.resetFiles()
                        showSnackbar('File Uploaded Successfully')
                        const form = this.$refs.form as Vue & { reset: () => void }
                        form.reset()
                    })
            }
        },
    },
})
