var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('AssignTableFilters'),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"table c-table__striped no-hover no-borders mb-3",attrs:{"items":_vm.filteredItems,"hide-default-footer":"","headers":_vm.headers,"item-key":"index","expanded":_vm.expanded,"disable-pagination":"","dense":"","search":_vm.search,"fixed-header":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('AssignTablePrepend',{attrs:{"appraisers":_vm.items}})]},proxy:true},(_vm.$ascent.featureEnabled('quoting', false))?{key:"item.send_quote",fn:function(ref){
var item = ref.item;
return [_c('SendQuotePartial',{attrs:{"item":item},on:{"open-quote":_vm.openQuoteDialog}})]}}:null,{key:"item.assigned",fn:function(ref){
var item = ref.item;
return [_c('AssignedPartial',{attrs:{"item":item}})]}},(_vm.order)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('EmailPhoneAction',{attrs:{"item":item}})]}}:null,{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('PanelNotePartial',{attrs:{"item":item}})]}},{key:"item.appraiser.name",fn:function(ref){
var item = ref.item;
return [_c('AppraiserNamePartial',{attrs:{"item":item}})]}},{key:"item.appraiser.rating",fn:function(ref){
var item = ref.item;
return [_c('AppraiserRating',{attrs:{"item":item}})]}},{key:"item.appraiser.status",fn:function(ref){
var item = ref.item;
return [_c('AppraiserStatusPartial',{attrs:{"item":item}})]}},{key:"item.assign",fn:function(ref){
var item = ref.item;
return [_c('EligibilityPartial',{attrs:{"item":item}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('ExpandedTablePartial',{attrs:{"details":_vm.details,"item":item}})]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"dense":"","outlined":"","text":"","color":"primary"}},[_vm._v("Not Set")])]},proxy:true},{key:"item.expand",fn:function(ref){
var item = ref.item;
return [_c('ExpandPartial',{attrs:{"item":item,"expanded":_vm.expanded},on:{"expand-opened":_vm.expand,"expand-collapse":_vm.collapse}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('quote-enter',{ref:"quoteEnter"}),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],attrs:{"color":"error","text":"","type":"warning"}},[_vm._v(" Please select both deadlines and an appraiser before assigning ")])],1)],1),_c('panel-map')],1)}
var staticRenderFns = []

export { render, staticRenderFns }