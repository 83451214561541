






import { defineComponent } from '@vue/composition-api'

import { openEdit } from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/EditEscalationTagDialog.vue'
export default defineComponent({
    setup() {
        return {
            openEdit,
        }
    },
})
