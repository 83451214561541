









































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import Vue from 'vue'
import { appraiserPanelNotes } from '@/plugins/Notes'
import PanelMap from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/PanelMap.vue'
import QuoteEnter from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/QuoteEnter.vue'
import { order } from '@/plugins/order/Order'
import { AppraiserPanel, AppraiserPanelDetails, AppraiserTag } from '@/types'
import AssignTableFilters, {
    filters,
    currentTag,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/AssignTableFilters.vue'
import EmailPhoneAction from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/EmailPhoneAction.vue'
import PanelNotePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/PanelNotePartial.vue'
import AppraiserStatusPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AppraiserStatusPartial.vue'
import AssignedPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AssignedPartial.vue'
import AppraiserNamePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AppraiserNamePartial.vue'
import EligibilityPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/EligibilityPartial.vue'
import ExpandedTablePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedTablePartial.vue'
import SendQuotePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/SendQuotePartial.vue'
import AssignTablePrepend, {
    search,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AssignTablePrepend.vue'
import ExpandPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandPartial.vue'
import AppraiserRating from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AppraiserRating.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

import AscentHelper from '@/helpers/ascent-helper'

export const headers = ref([
    { text: 'Assign Status', value: 'assigned', width: '6%' },
    { text: 'Actions', value: 'action', width: '80px' },
    { text: 'Notes', value: 'note', width: '4%' },
    { text: 'Name', value: 'appraiser.name', width: '14%' },
    { text: 'Rating', value: 'appraiser.rating', width: '4%' },
    { text: 'Status', value: 'appraiser.status', width: '10%' },
    { text: 'Panel', value: 'panel', width: '8%' },
    { text: 'Turn Time', value: 'turn_time', width: '10%' },
    { text: 'Avail (%)', value: 'availability', width: '11%' },
    {
        text: 'Decl (%) / On-time (%)',
        value: 'stats',
        width: '14%',
    },
    { text: 'Distance', value: 'distance', width: '8%' },
    { text: '', sortable: false, value: 'assign', width: '10%' },
    { text: '', value: 'expand', align: 'end', width: '3%' },
])

/**
 * @name AssignTable
 * Table to show all appraisers in a panel for amc to choose from and assign to an order
 *
 * @SetupData headers - headers of the appraiser table
 * @SetupData expanded - array of appraisers where their info is expanded
 * @SetupData details - object of details of the appraisers where they are expanded. Key is the appraiser_id
 */

export default defineComponent({
    setup() {
        const expanded = ref([] as AppraiserPanel[])
        const details = ref({} as { [appraiserId: number]: AppraiserPanelDetails })

        return {
            order,
            filters,
            expanded,
            headers,
            search,
            details,
            currentTag,
        }
    },
    components: {
        ExpandPartial,
        PanelMap,
        QuoteEnter,
        AssignTableFilters,
        EmailPhoneAction,
        PanelNotePartial,
        AppraiserStatusPartial,
        AssignedPartial,
        AppraiserNamePartial,
        EligibilityPartial,
        ExpandedTablePartial,
        SendQuotePartial,
        AssignTablePrepend,
        AppraiserRating,
    },
    props: {
        /**
         * Appraiser Panel
         */
        items: {
            type: [] as PropType<AppraiserPanel[]>,
            default() {
                return []
            },
        },
    },
    data() {
        return {
            hasExpanded: false as boolean,
            error: false,
        }
    },
    mounted() {
        if (!AscentHelper.featureEnabled('quoting', false)) return
        if (headers.value[0].text === 'Quote') return
        headers.value.unshift({ text: 'Quote', value: 'send_quote', width: '3%' })
    },
    computed: {
        /**
         * Returns a filtered array of the panel depending on the filters the user clicks
         */
        //

        filteredItems(): AppraiserPanel[] {
            if (this.filters.length == 0 && (this.currentTag === '' || this.currentTag == null)) return this.items
            if (!this.items) return []
            return this.items.filter((item) => {
                if (!item.appraiser) return
                return (
                    (this.filters.length === 0 ||
                        this.filters.some((r) => {
                            if (!item.appraiser) return
                            return (
                                (item.appraiser.appraiser_tags != null &&
                                    item.appraiser.appraiser_tags.some((elem: AppraiserTag) => {
                                        return elem.tag === r
                                    })) ||
                                this.filters.includes(item.appraiser.type)
                            )
                        })) &&
                    (this.currentTag === '' ||
                        this.currentTag == null ||
                        (item.appraiser.appraiser_tags != null &&
                            item.appraiser.appraiser_tags.some((elem: AppraiserTag) => {
                                return elem.tag === this.currentTag
                            })))
                )
            })
        },
    },
    methods: {
        /**
         * Opens up dialog for quote
         * @param item - Appraiser
         */
        openQuoteDialog(item: AppraiserPanel) {
            const quoteEnter = this.$refs.quoteEnter as InstanceType<typeof QuoteEnter>
            quoteEnter.open(item)
        },
        /**
         * Gets appraiser panel info for specific appraiser, and sets those details onto the details object, grabs appraiser's orders, and pushes them onto the expanded array
         * @param item - appraiser in appraiser panel
         */
        async expand(item: AppraiserPanel) {
            if (!item || !item.appraiser) return

            await this.$axios.get('/v1/panel/appraiser/' + item.appraiser.id).then((response) => {
                if (!response.data) {
                    showSnackbar('Something went wrong!')
                    return
                }
                if (!item || !item.appraiser) return
                if (appraiserPanelNotes.value)
                    response.data.notes = appraiserPanelNotes.value.filter((elem) => {
                        if (!item || !item.appraiser || !elem.appraiser_id) return false
                        return (
                            elem.appraiser_id === item?.appraiser.id &&
                            (elem.target === 'Assign Phone' ||
                                elem.target === 'Assign Email' ||
                                (elem.source == 'Prior Appraiser' && elem.target == 'AMC') ||
                                (elem.source === 'AMC' && elem.target === 'Prior Appraiser'))
                        )
                    })
                Vue.set(this.details, item?.appraiser?.id as number, response.data)
                if (!item) return
            })

            await this.$axios.get(`/v1/appraiser/${item.appraiser.id}/orders`).then((response) => {
                this.details[item.appraiser.id].appraiserOrders = response.data.orders
                this.details[item.appraiser.id].allOtherOrders = response.data.totalCount
            })

            this.expanded.push(item)
        },
        /**
         * Removes appraiser from the expanded array
         * @param item - appraiser in appraiser panel
         */
        collapse(item: AppraiserPanel) {
            this.expanded.splice(
                this.expanded.findIndex((elem) => elem.index == item.index),
                1,
            )
        },
    },
})
