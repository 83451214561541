






































import { defineComponent } from '@vue/composition-api'
import { viewFilters } from '@/components/OrderTable/Classes/OrderTable'

export default defineComponent({
    setup() {
        const resetSelection = () => {
            viewFilters.value.propertyType = ['All Types']
        }

        const selectAllWhenNoneSelected = (propertyType: string) => {
            if (viewFilters.value.propertyType.length === 0 && propertyType !== 'All Types')
                viewFilters.value.propertyType = ['All Types']
            if (propertyType === 'All Types') viewFilters.value.propertyType = []
        }

        return {
            resetSelection,
            selectAllWhenNoneSelected,
            viewFilters,
        }
    },
})
