import { orderConfiguration } from '@/pages/Client/NewOrderMethods'
import {
    commercialPropertyTypes,
    contactTypes,
    financeTypes,
    occupancyTypes,
    officeFinanceTypes,
    propertyTypes as residentialPropertyTypes,
} from '@/plugins/Data'
import { user } from '@/plugins/User'
import { CustomOrderData } from '@/types/CustomOrderData'
import { computed, ref } from '@vue/composition-api'
import axios from 'axios'

type searchKey = 'col' | 'label'

export const customerDefinedTypes = ref<CustomOrderData[]>([])

export const getCustomOrderDataSetting = () => {
    axios.get('v1/settings/custom-order-data').then((r) => {
        customerDefinedTypes.value = r.data.result.filter((typeObj: CustomOrderData) => typeObj.enabled)
    })
}

export const findCustomerDefinedTypes = (searchKey: searchKey, searchStr: string) => {
    return (
        customerDefinedTypes.value.find((typesObj: CustomOrderData) => typesObj[searchKey].includes(searchStr))?.data ||
        []
    )
}

export const filterCustomerDefinedTypes = (searchKey: searchKey, searchStr: string) => {
    return (
        customerDefinedTypes.value.filter((typesObj: CustomOrderData) => typesObj[searchKey].includes(searchStr)) || []
    )
}

export const financeTypeSelections = computed(() => {
    const customerDefinedFinanceTypes = findCustomerDefinedTypes('col', 'loan_purpose')
    if (customerDefinedFinanceTypes.length > 0) return customerDefinedFinanceTypes.sort()
    return user.value && user.value.type === 'office' ? officeFinanceTypes.sort() : financeTypes.sort()
})

export const occupancyTypeSelections = computed(() => {
    const occupancy = findCustomerDefinedTypes('col', 'occupancy')
    if (occupancy.length > 0) return occupancy
    return occupancyTypes
})

export const otherContactTypeSelections = computed(() => {
    const customerDefinedContactTypes = findCustomerDefinedTypes('col', 'contact_type')
    if (customerDefinedContactTypes.length > 0) return customerDefinedContactTypes
    return contactTypes.filter((el) => el !== 'Borrower')
})

export const orderTypeSelections = computed(() => {
    const orderTypes = findCustomerDefinedTypes('col', 'propertyType')
    if (orderTypes.length > 0) return orderTypes
    return ['Residential', 'Commercial']
})

export const commercialLandTypes = computed(() => {
    const landTypes = filterCustomerDefinedTypes('col', 'residence_type')
    if (landTypes.length > 0)
        return Object.keys(commercialPropertyTypes).filter((landType: string) => {
            const landTypeObj = landTypes.find((typeObj: CustomOrderData) => typeObj.label.includes(landType)) || null
            if (!landTypeObj) return false
            return landTypeObj.enabled && !landTypeObj.hide
        })
    return Object.keys(commercialPropertyTypes)
})

export const getResidenceTypeSelections = (commercialType = '') => {
    if (commercialType.length > 0) {
        const customTypes = findCustomerDefinedTypes('label', commercialType)
        if (customTypes.length > 0) return customTypes
        if (Object.keys(commercialPropertyTypes).includes(commercialType)) {
            const entry = Object.entries(commercialPropertyTypes).find((typeObj) => commercialType === typeObj[0]) || null
            return !entry ? [] : (entry[1] || [])
        }
    }
    if (Object.keys(orderConfiguration.value).indexOf('propertyTypes') !== -1) {
        return orderConfiguration.value.propertyTypes
    }

    const customTypes = findCustomerDefinedTypes('label', 'Residential')
    if (customTypes.length > 0) return customTypes

    return residentialPropertyTypes
}
