
































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import AEditor from '@/global/Form/AEditor.vue'
import DatePicker from '@/components/General/DatePicker.vue'

export default defineComponent({
    components: {
        AEditor,
        DatePicker,
    },
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const contractPrice = ref('')
        const dateOfContract = ref('')
        const ownerSellingProperty = ref('')
        const dataSources = ref('')

        watch(props, () => {
            if (!props.editData && !props.cancelEdit) {
                preloadData.value.contractData.contractPrice = contractPrice.value
                preloadData.value.contractData.dateOfContract = dateOfContract.value
                preloadData.value.contractData.ownerSellingProperty = ownerSellingProperty.value
                preloadData.value.contractData.dataSources = dataSources.value
            }
            if (props.editData) {
                contractPrice.value = preloadData.value.contractData.contractPrice
                dateOfContract.value = preloadData.value.contractData.dateOfContract
                ownerSellingProperty.value = preloadData.value.contractData.ownerSellingProperty
                dataSources.value = preloadData.value.contractData.dataSources
            }
        })
        return {
            preloadData,
            contractPrice,
            dateOfContract,
            ownerSellingProperty,
            dataSources,
        }
    },
})
