
































import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'
import { refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    setup() {
        const resetReconsideration = () => {
            confirm(
                'Remove ROV?',
                'Are you sure you want to remove the reconsideration of value from this order?',
            ).then((confirm) => {
                if (confirm) {
                    if (!order.value || !order.value?.incremental) return
                    $axios.delete(`/v1/order/reconsideration/${order.value.id}`).then(() => {
                        showSnackbar('Reconsideration of Value successfully deleted')
                        refreshOrder()
                    })
                }
            })
        }

        return { order, resetReconsideration }
    },
})
