












import { defineComponent } from '@vue/composition-api'
import { LenderCode, viewLenderCode } from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/OrderInvoice.vue'

export default defineComponent({
    name: 'LenderInvoiceCode',
    setup() {
        return {
            viewLenderCode,
            LenderCode,
        }
    },
})
