










































































































































































































import { PDRPDCDetails } from '@/types/orderDetails'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import AppraiserSecondInspection from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/Partials/AppraiserSecondInspection.vue'
import { getPdrPdcData, order, appraiserFee, representativePhone } from '@/plugins/order/Order'
import { Milestone } from '@/types'
import { DateTime } from 'luxon'
import { appraiser } from '@/pages/Appraiser/Appraiser'
import ClientTags from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/ClientTags.vue'
import { user, role } from '@/plugins/User'
import RequestModificationDialog, {
    openModRequest,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/RequestModificationDialog.vue'
import axios from 'axios'
import CustomerTraineeAppraiser from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerTraineeAppraiser.vue'
import PaidStatusChip from '@/components/General/PaidStatusChip.vue'

/**
 * @name AppraiserOrderDetails
 * Section in Appraiser Order Screen that gives the details to appraiser. Details include dates, fee, loan numbers.
 *
 * @SetupComputed inspectionDate - Checks the milestones on the incremental order for the inspection date. And formats the date to show to the appraiser if there.
 *
 */

export default defineComponent({
    name: 'AppraiserOrderDetails',
    components: {
        CustomerTraineeAppraiser,
        AppraiserSecondInspection,
        ClientTags,
        RequestModificationDialog,
        PaidStatusChip,
    },
    setup() {
        const pdrPdcData = ref({} as PDRPDCDetails)

        const inspectionDate = computed(() => {
            if (!order.value || !order.value.incremental) return
            const date = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Inspection Date'
            })
            if (!date) return
            const property = DateTime.fromISO(date.milestone_value, {
                zone: 'UTC',
            }).setZone(order.value.timezone)
            const local = DateTime.fromISO(date.milestone_value, {
                zone: 'UTC',
            }).setZone('local')
            return property.toFormat('EEE, MMM d, t (ZZZZ)') + ' / ' + local.toFormat('t (ZZZZ)')
        })

        const assignedDate = computed(() => {
            if (!order.value || !order.value.incremental) return
            const date = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Assigned'
            })
            if (!date) return
            const property = DateTime.fromISO(date.milestone_value, {
                zone: 'UTC',
            }).setZone(order.value.timezone)
            return property.toFormat('EEE, MMM d, t (ZZZZ)')
        })

        const acceptedDate = computed(() => {
            if (!order.value || !order.value.incremental) return
            const date = order.value.incremental.milestones.find((milestone: Milestone) => {
                return milestone.milestone_type === 'Accepted'
            })
            if (!date) return
            const property = DateTime.fromISO(date.milestone_value, {
                zone: 'UTC',
            }).setZone(order.value.timezone)
            return property.toFormat('EEE, MMM d, t (ZZZZ)')
        })

        const openModRequestDialog = () => {
            openModRequest('vendor')
        }

        const hasOpenModRequest = computed(() => {
            if (!order.value) return false
            if (!order.value.incremental) return false
            return (
                order.value.tags.filter((tag) => {
                    return tag.tag === 'Mod Fee Req'
                }).length > 0
            )
        })

        const isAmcAppraiser = computed(() => {
            if (!user.value || !user.value.appraiser) return false
            return user.value.appraiser.type === 'amc'
        })

        const setPdrPdcData = () => {
            if (!order.value?.lender_id) {
                pdrPdcData.value = getPdrPdcData()
                return
            }
            axios.get('/v1/lender/' + order.value?.lender_id + '/option/bace-id').then((resp) => {
                if (!resp.data.option_value) {
                    pdrPdcData.value = getPdrPdcData()
                    return
                }
                pdrPdcData.value = getPdrPdcData(resp.data?.option_value)
            })
        }
        onMounted(() => {
            setPdrPdcData()
        })

        const orderLenderAddress = computed(() => {
            if (!order.value || !order.value?.incremental) return ''
            if (order.value.incremental.sublender) {
                return order.value?.incremental.sublender.address?.full_address_formatted
            }

            return order.value?.incremental.lender.address?.full_address_formatted
        })

        return {
            order,
            representativePhone,
            inspectionDate,
            acceptedDate,
            assignedDate,
            appraiserFee,
            user,
            role,
            openModRequestDialog,
            hasOpenModRequest,
            isAmcAppraiser,
            pdrPdcData,
            orderLenderAddress,
            appraiser,
        }
    },
})
