
















































import { defineComponent } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    components: {
        PreloadStepperHeader,
    },
    setup() {
        return {
            preloadData,
        }
    },
})
