








































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, updateOrder } from '@/plugins/order/Order'

export const orderHoldDialog = ref(false)

/**
 * @name OrderHoldDialog
 * Dialog on Order Screen that allows Amc users to place an order on hold
 *
 * @SetupData appraiserNotes - notes that will be sent to appraiser
 * @SetupMethod placeHold - places hold on the dialog and refreshes the order
 */

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const appraiserNotes = ref(null)
        const loading = ref(false)
        const appraisalPortReasons: { [x: string]: number } = {
            'Access Issues Tenant Occupied': 1,
            'Assignment Fee Acceptance Conditions': 3,
            'Borrower Contact Not Returning Calls': 4,
            'Borrower Contact Out Of Town': 5,
            'Borrower Contact Requested Hold': 6,
            'Borrower Contact Uncooperative': 7,
            'Construction Repairs Not Complete': 8,
            'Contact Info Missing Incorrect': 9,
            'Coop Condo Info Needed': 10,
            'Corrections Comments Needed': 11,
            'FHA Case Number Missing': 12,
            'Request Deed': 25,
            'Request Original Appraisal': 21,
            'Request Renovation Contractor Bid': 22,
            'Request Revised Purchase Contract': 27,
            'Request Revised Work Write Up': 23,
            'Request Survey': 26,
            'Request Title': 24,
            Reviewer: 16,
            'Plans And Specifications Needed': 13,
            'Property Contact No Show': 19,
            'Purchase Contract Not Provided': 14,
            'Weather Issue': 15,
        }

        const appraisalPortSelection = ref('')
        const reason = Object.keys(appraisalPortReasons)
        let reasonCode = 1

        const cancel = (): void => {
            orderHoldDialog.value = false
            appraisalPortSelection.value = ''
        }

        const placeHold = (): void => {
            if (!order.value) return
            if (order.value.source === 'appraisalport') {
                reasonCode = appraisalPortReasons[appraisalPortSelection.value]
            }
            loading.value = true
            axios
                .post(
                    '/v1/order/' + order.value.id + '/workflow/hold',
                    order.value.source === 'appraisalport'
                        ? {
                              payment: false,
                              note: appraiserNotes.value,
                              code: reasonCode,
                          }
                        : {
                              payment: false,
                              note: appraiserNotes.value,
                          },
                )
                .then(() => {
                    updateOrder()
                    showSnackbar('Order placed on hold successfully')
                    loading.value = false
                    orderHoldDialog.value = false
                })
        }

        return {
            appraiserNotes,
            appraisalPortReasons,
            appraisalPortSelection,
            cancel,
            loading,
            orderHoldDialog,
            order,
            placeHold,
            reason,
        }
    },
})
