var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 mt-3 mb-3",attrs:{"headers":_vm.headers,"items":_vm.conditionList,"disable-pagination":"","hide-default-footer":"","item-key":"condition","hide-default-header":_vm.conditionList.length === 0},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mb-0",attrs:{"value":true,"type":"info","text":"","icon":"$vuetify.icons.exclamation"}},[_vm._v(" Sorry, no conditions added. ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.order)?_c('tr',{class:[item.appraiser_complete && item.verified ? 'bg-ascent-lightgray' : '']},[_c('td',[(_vm.user && _vm.user.type !== 'appraiser')?_c('span',{staticClass:"d-flex align-center"},[(
                            item.appraiser_complete &&
                            !item.verified &&
                            (_vm.order.status !== 'QC Review' || _vm.order.status !== 'Reconsideration Review')
                        )?_c('v-alert',{staticClass:"ma-2 small",staticStyle:{"font-size":"small","width":"100%"},attrs:{"type":"warning","dense":"","text":"","small":""}},[_vm._v(" Pending Completion ")]):_vm._e(),(
                            item.appraiser_complete &&
                            !item.verified &&
                            (_vm.order.status === 'QC Review' || _vm.order.status === 'Reconsideration Review')
                        )?_c('v-btn',{attrs:{"ripple":"","icon":"","small":"","color":"success"},on:{"click":function($event){return _vm.verifyCondition(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.success")])],1):_vm._e(),(
                            item.appraiser_complete &&
                            !item.verified &&
                            (_vm.order.status === 'QC Review' || _vm.order.status === 'Reconsideration Review')
                        )?_c('v-btn',{attrs:{"ripple":"","icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.failCondition(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.times")])],1):_vm._e(),(
                            (!item.appraiser_complete &&
                                (_vm.order.status === 'QC Review' ||
                                    _vm.order.status === 'UW Request' ||
                                    _vm.order.status === 'UW Revision' ||
                                    _vm.order.status === 'QC Revision' ||
                                    _vm.order.status === 'Reconsideration Review')) ||
                            (item.appraiser_complete &&
                                !item.verified &&
                                (_vm.order.status !== 'QC Review' || _vm.order.status !== 'Reconsideration Review'))
                        )?_c('v-btn',{staticClass:"ma-0",attrs:{"icon":"","small":"","ripple":""},on:{"click":function($event){return _vm.removeCondition(item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("$vuetify.icons.trashAlt")])],1):_vm._e(),(item.appraiser_complete && item.verified)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" $vuetify.icons.thumbsUp ")]):_vm._e()],1):_vm._e()]),_c('td',{domProps:{"innerHTML":_vm._s(item.condition)}})]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }