var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ACard',{attrs:{"title":_vm.localType === 'email' ? 'Email Appraiser' : 'Phone Notes'}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.localType === 'email')?_c('p',[_vm._v("Email to "+_vm._s(_vm.localAppraiser.name))]):_vm._e(),(_vm.localType === 'phone')?_c('p',[_vm._v("Notes for "+_vm._s(_vm.localAppraiser.name))]):_vm._e()]),_c('v-col',[_c('AEditor',{attrs:{"uuid":"AssignPanelNotes","outlined":"","name":"note","label":_vm.localType === 'email' ? 'Email for appraiser' : 'Phone notes',"editor":"cssChange"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.addFiles)?_c('AttachFileEmail',{on:{"files-updated":_vm.filesUpdated,"order-pdf-updated":_vm.orderPdfUpdated}}):_vm._e()],1)],1)],1),_c('a-card-action',{attrs:{"btnText":_vm.localType === 'email' ? 'Send Email' : 'Save Note',"url":'v1/order/' + _vm.order.id + '/appraiser/' + _vm.localAppraiser.id + '/action/add-appraiser-assign-note',"submitData":{
                    type: _vm.localType,
                    note: _vm.note,
                    'file-ids': _vm.fileIds,
                    'send-order-pdf': _vm.sendOrderPdf,
                },"handleSubmit":handleSubmit,"disable-submit":!_vm.note},on:{"cancel":_vm.closeDialog,"success":_vm.noteAdded}},[_c('template',{slot:"secondaryAction"},[(_vm.localType === 'email')?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.addFiles = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$vuetify.icons.paperclip")]),_vm._v(" Attach Files ")],1):_vm._e()],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }