

















































import { computed, defineComponent } from '@vue/composition-api'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import { refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getAllTeams, teams } from '@/plugins/Amc.ts'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        saving: false,
        team: null,
        note: '',
    }
}

export default defineComponent({
    components: {
        ValidationObserver,
        ACardAction,
    },
    setup() {
        const filteredTeams = computed(() => {
            if (!order.value || !order.value.incremental || teams.value.length === 0) return []
            return teams.value.filter((team) => team.id !== order.value?.incremental?.team?.id)
        })

        return {
            filteredTeams,
            order,
        }
    },
    data() {
        return reset()
    },
    mounted() {
        getAllTeams()
    },
    methods: {
        changeTeam() {
            showSnackbar('Order team changed.')
            refreshOrder()
            Object.assign(this.$data, reset())
            const form = this.$refs.form as InstanceType<typeof ValidationObserver>
            form.reset()
            this.$emit('closeDetailItem')
        },
    },
})
