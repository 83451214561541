import Vue from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import router from '@/pages/router'
import * as Sentry from '@sentry/vue'
import {
    flushToken,
    getSession,
    getToken,
    hasImpersonateToken,
    setRedirectPath,
    setSession,
} from '@/helpers/token-helper'
import { stopImpersonation } from '@/plugins/User'
import { ref } from '@vue/composition-api'

axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.withCredentials = false

const leavingImpersonation = ref(false)

if (process.env.VUE_APP_USE_AUTH !== 'false') {
    axios.interceptors.request.use(
        (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig | void> => {
            if (
                config.url &&
                !config.url.includes('/sanctum/csrf-cookie') &&
                !config.url.includes(`${process.env.VUE_APP_AUTH_URL}`) &&
                !config.url.includes('amazonaws.com')
            ) {
                const session = getSession()
                if (session.session) {
                    config.headers = { ...(config.headers || {}), 'X-SESSION-TOKEN': session.session }
                }

                if (session.xsrf) {
                    config.headers = { ...(config.headers || {}), 'X-XSRF-TOKEN': session.xsrf }
                }

                return getToken()
                    .then((token) => {
                        if (token && token.accessToken) {
                            config.headers = { ...(config.headers || {}), Authorization: 'Bearer ' + token.accessToken }
                            return config
                        }
                        return config
                    })
                    .catch(() => {
                        return config
                    })
            }
            return config
        },
    )
}

let count = 0

axios.interceptors.response.use(
    (response) => {
        setSession(response.headers || {})
        count = 0
        Sentry.addBreadcrumb({
            category: 'xhr',
            message: 'request data',
            data: response.data,
            level: 'info',
        })
        return response
    },
    (error) => {
        const status = error && error.response ? error.response.status : null
        const response = error && error.response ? error.response.data : null
        const request = error && error.request ? error.request.data : null

        if (
            status === 404 ||
            status === 400 ||
            status === 403 ||
            (status === 401 &&
                Object.keys(router.currentRoute.meta).includes('requiresAuth') &&
                !router.currentRoute.meta.requiresAuth)
        ) {
            return Promise.reject(error)
        }

        if (status === 401) {
            if (
                Object.keys(router.currentRoute.meta).includes('requiresAuth') &&
                router.currentRoute.meta.requiresAuth &&
                window.location.pathname !== '/login' &&
                (error.config.url.includes('user-v2') || !localStorage.getItem('jaro_auth'))
            ) {
                if (hasImpersonateToken() && !leavingImpersonation.value) {
                    leavingImpersonation.value = true
                    stopImpersonation()
                        .then(() => {
                            leavingImpersonation.value = false
                        })
                        .catch(() => {
                            leavingImpersonation.value = false
                        })
                } else if (!hasImpersonateToken() && !leavingImpersonation.value) {
                    setRedirectPath(window.location.pathname)
                    flushToken()
                    router.push('/login').catch()
                } else {
                    return Promise.reject(error)
                }
            }

            if (error.config.url.includes('user-v2') || !localStorage.getItem('jaro_auth')) {
                setRedirectPath(window.location.pathname)
                flushToken()
                router.push('/login').catch()
            } else {
                return Promise.reject(error)
            }
        }

        if (status === 418) {
            flushToken()
            window.location.href = process.env.VUE_APP_AUTH_URL + '/oauth/logout?status=unauthorized'
        }

        if (status === 419 && count === 0) {
            return axios.get('/sanctum/csrf-cookie').then(() => {
                count++
                axios.request(error.config)
            })
        }

        if (status === 422) {
            return Promise.reject(error)
        }

        if (process.env.VUE_APP_ENV !== 'production') return Promise.reject(error)

        Sentry.addBreadcrumb({
            category: 'xhr',
            message: 'failed request data',
            data: {
                url: error.config.url,
                status,
                response,
                request,
            },
            level: 'error',
        })

        return Promise.reject(error)
    },
)

Object.defineProperty(Vue.prototype, '$axios', { value: axios })

export default axios
