
























import { defineComponent, onMounted } from '@vue/composition-api'
import { currentCustomer, customers, setUserDetails, user, redirectTo } from '@/plugins/User'
import { Customer } from '@/types'
import $axios from '@/plugins/axios'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import { handleConnection } from '@/plugins/Channels'
import { subscribeChangeTenancy } from '@/AppLayout/helpers/Prevent.vue'

export default defineComponent({
    name: 'TenancySwitcher',
    setup() {
        const changeCustomer = (customer: Customer) => {
            localStorage.setItem('change-tenancy-originator', 'true')
            $axios
                .post('/v1/customer/switch', customer)
                .then(({ data }) => {
                    if (user.value) localStorage.removeItem(`user_dashboard_${user.value.id}`)
                    setUserDetails(data)
                    redirectTo(user.value?.type ?? 'default', true)
                })
                .catch(() => {
                    showError('You are not authorized to view this customer')
                })
        }

        const registerEvents = () => {
            handleConnection({
                channelRef: 'tenancy',
                id: user.value?.id as number,
            }).then((connector) => {
                subscribeChangeTenancy(connector)
            })
        }

        onMounted(() => {
            registerEvents()
        })

        return {
            customers,
            currentCustomer,
            changeCustomer,
        }
    },
})
