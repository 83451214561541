





































import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import axios from 'axios'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { PaymentLink } from '@/types'

const paymentLinks = ref([] as PaymentLink[])
export const getPaymentLinks = () => {
    if (!order.value) return
    axios.get('/v1/order/' + order.value.id + '/payment-link').then((response) => {
        paymentLinks.value = response.data
    })
}

export default defineComponent({
    setup() {
        const deletePaymentLink = (link: PaymentLink) => {
            if (!link) return
            if (!order.value) return
            confirm(
                'Delete Payment Link',
                'Are you sure you want to delete this payment link? If the borrower uses the payment link, it will show as expired.',
            ).then((confirm) => {
                if (confirm) {
                    axios
                        .delete('/v1/payment-link/' + link.id)
                        .then((response) => {
                            showSnackbar(response.data)
                            getPaymentLinks()
                        })
                        .catch((error) => {
                            showError(error.response.data ?? error.data ?? error)
                        })
                }
            })
        }

        return { paymentLinks, deletePaymentLink }
    },
})
