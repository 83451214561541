




















































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import FileUpload from '@/components/General/FileUpload.vue'
import { File } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { user } from '@/plugins/User'
import { canEdit } from '@/pages/Appraiser/Profile/AppraiserProfile'

function reset() {
    return {
        file: '' as string,
        fileError: false,
        showDialog: false,
        otherType: '',
        fileTypes: ['Buyer Certificates', 'Green Certification', 'Resume', 'Sample Report', 'Other'],
    }
}

export default defineComponent({
    components: { ValidationObserver, FileUpload },
    props: {
        signature: Boolean,
        customerFile: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const type = ref('')
        onMounted(() => {
            if (props.signature) type.value = 'Signature'
            if (props.customerFile) {
                type.value = 'Other'
            }
        })

        return {
            user,
            type,
            canEdit,
        }
    },
    data() {
        return reset()
    },
    methods: {
        uploadSubmit(): boolean | void {
            if (this.file === '') {
                this.fileError = true
                return false
            }
            Object.assign(this.$data, reset())
            const form = this.$refs.form as InstanceType<typeof ValidationObserver>
            form.reset()
            this.$emit('file-uploaded')
            showSnackbar('File uploaded successfully.', 'success')
        },
        getFile(file: File): void {
            this.file = file.path
        },
        openDialog(): void {
            this.showDialog = true
        },
    },
})
