import Vue, { CreateElement } from 'vue'

import './plugins/composition-api'
import './plugins/axios'
import './plugins/ascent-helper'
import './helpers/OrderHelper'
import './plugins/sentry'

import '@/styles/material.scss'

import router from '@/pages/router'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import snackbar from '@/AppLayout//helpers/snackbar.vue'
import confirm from '@/AppLayout/helpers/confirm.vue'
import VueMeta from 'vue-meta'
import { GridPlugin } from '@syncfusion/ej2-vue-grids/src'
import AscentComponentLibrary from '@ascentdesk/ascent-vue-components'
import '@/plugins/vee-validate.ts'

//Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js'
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min'
import 'froala-editor/js/third_party/font_awesome.min'
import 'froala-editor/js/third_party/image_tui.min'
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)
import ATableField from '@/components/DynamicForm/ATableField.vue'

Vue.config.productionTip = false

Vue.config.productionTip = false

const files = require.context('./global', true, /\.vue$/i)
files.keys().map((key) => {
    Vue.component(key?.split('/')?.pop()?.split('.')?.[0] ?? 'component', files(key).default)
})

Vue.component('ATableField', ATableField)
Vue.use(GridPlugin)
Vue.use(AscentComponentLibrary)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
})
import GmapVue from 'gmap-vue'
import { createApp } from '@vue/composition-api'

Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS,
        libraries: 'places,drawing',
    },
    installComponents: true,
})

import VueGtm from '@gtm-support/vue2-gtm'

try {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
        defer: true,
        compatibility: true,
    })
} catch (e) {
    // GTM library throws if the ID is not valid
    // try/catch is to make sure the exception doesn't stop our initialization script
}

const app = createApp({
    router,
    vuetify,
    render: (h: CreateElement) => {
        return h('div', [
            h(App),
            h(snackbar, { tag: 'snackbar', ref: 'snackbar' }),
            h(confirm, { tag: 'confirm', ref: 'confirm' }),
        ])
    },
})
app.mount('#app')
