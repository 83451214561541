


























































































































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { contactTypes, masks, phoneTypes } from '@/plugins/Data'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import { Contact } from '@/types'

/**
 * @name AddContactDialogBorrower
 * Dialog on preload screen that allows user to add a borrower onto the preload
 *
 * @StateData contact - contact object of what info will be added when contact is added
 * @SetupMethod addContact - adds contact onto the preload data.
 */
export const dialog = ref(false)
export default defineComponent({
    directives: { mask },
    components: {
        ValidationObserver,
    },
    setup() {
        const state = reactive({
            adding: false,
            contact: {
                name: '',
                email: '',
                phones: [{ type: '', phone: '' }],
                contact_type: 'Borrower',
                entry: false,
            } as Contact,
        })
        const error = ref('')

        const addContact = () => {
            state.adding = true
            error.value = ''
            if (!order.value) return
            if (state.contact.phones) {
                for (const phone of state.contact.phones) {
                    if (phone.phone && phone.phone.replace(/\D/g, '').length !== 10) {
                        error.value = 'Each phone number is required to be exactly 10 digits'
                        state.adding = false
                        return
                    }
                }
            }
            preloadData.value.primary.borrowers.push(state.contact)
            state.contact = {
                name: '',
                email: '',
                phones: [{ type: '', phone: '' }],
                contact_type: 'Borrower',
                entry: false,
            }
            showSnackbar('Borrower Added Successfully')
            dialog.value = false
            state.adding = false
        }

        return {
            state,
            addContact,
            contactTypes,
            phoneTypes,
            masks,
            dialog,
            error,
        }
    },
})
