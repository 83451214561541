































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import axios from 'axios'
import { Task } from '@/types'
import AmcUserSelect from '@/components/General/AutoCompletes/AmcUserSelect.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    name: 'LenderOnboardingStatus',
    components: { AmcUserSelect },
    props: {
        task: {
            type: Object as PropType<Task>,
        },
    },
    setup(props, { emit }) {
        const taskLocal = ref({ status: '', user_id: 0, id: 0 })

        if (props.task) {
            taskLocal.value = props.task
        }

        const getIcon = computed(() => {
            if (taskLocal.value.status === 'Not Started') return 'alarm'
            if (taskLocal.value.status === 'Pending') return 'check'

            return 'checkDouble'
        })

        const getColor = computed(() => {
            if (taskLocal.value.status === 'Not Started') return 'red'
            if (taskLocal.value.status === 'Pending') return 'yellow'

            return 'success'
        })

        const updateUser = () => {
            axios
                .patch(`/v1/lender-task/${taskLocal.value.id}`, {
                    user_id: taskLocal.value.user_id,
                    status: 'Pending',
                })
                .then(() => {
                    taskLocal.value.status = 'Pending'
                    emit('updateStatus', taskLocal.value)
                    showSnackbar('User Assigned Successfully!')
                })
        }

        const updateStatus = (status: string) => {
            axios
                .patch(`/v1/lender-task/${taskLocal.value.id}`, {
                    user_id: taskLocal.value.user_id,
                    status,
                })
                .then(() => {
                    taskLocal.value.status = status
                    emit('updateStatus', taskLocal.value)
                    showSnackbar('Onboarding Status Updated Successfully!')
                })
        }

        return {
            getIcon,
            getColor,
            taskLocal,
            updateUser,
            updateStatus,
        }
    },
})
