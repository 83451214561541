














































import { defineComponent, ref, computed } from '@vue/composition-api'
import { Contact, MenuItem } from '@/types'
import { Dialog } from '@/ascent'
import { order } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import ContactFormDialog, {
    editContact,
    createContact,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts/ContactFormDialog.vue'

export const orderContacts = computed(() => {
    if (!order.value || !order.value.incremental) return []
    return [...order.value.incremental.borrowers, ...order.value.incremental.contacts]
})
export default defineComponent({
    components: {
        ContactFormDialog,
    },
    setup() {
        const ContactDialog = ref({} as Dialog)
        const EditContactDialog = ref({} as Dialog)

        const actions: MenuItem[] = [
            {
                label: 'Add Contact',
                action: () => {
                    const dialog = ContactDialog.value?.$refs.syncDialog
                    dialog.show()
                },
            },
        ]
        const getPhoneIcon = (type: string) => {
            switch (type) {
                case 'Office':
                    return '$vuetify.icons.phoneOffice'
                case 'Mobile':
                    return '$vuetify.icons.mobile'
            }
            return '$vuetify.icons.phone'
        }

        const deleteContact = (contact: Contact) => {
            confirm('Are you sure?', 'Are you sure you want to delete this contact? This cannot be undone.', {}).then(
                (confirm) => {
                    if (confirm) {
                        $axios.delete('/v1/contact/' + contact.id).then(() => {
                            if (!order.value) return
                            if (!order.value.incremental) return
                            if (contact.contact_type === 'Borrower') {
                                let borrowers = JSON.parse(JSON.stringify(order.value.incremental.borrowers))
                                borrowers = borrowers.filter((item: Contact) => item.id !== contact.id)
                                order.value.incremental.borrowers = borrowers
                            }

                            if (contact.contact_type !== 'Borrower') {
                                let contacts = JSON.parse(JSON.stringify(order.value.incremental.contacts))
                                contacts = contacts.filter((item: Contact) => item.id !== contact.id)
                                order.value.incremental.contacts = contacts
                            }

                            showSnackbar('Contact Deleted Successfully')
                        })
                    }
                },
            )
        }

        return {
            actions,
            deleteContact,
            editContact,
            ContactDialog,
            EditContactDialog,
            orderContacts,
            createContact,
            getPhoneIcon,
        }
    },
})
