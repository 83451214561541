






import { defineComponent } from '@vue/composition-api'
import CustomerReassignStaffAppraiser from '@/components/OrderScreens/Customer/CustomerOrderComponents/DuplicatedComponents/CustomerReassignStaffAppraiser.vue'
import CustomerReassignVendor from '@/components/OrderScreens/Customer/CustomerOrderComponents/DuplicatedComponents/CustomerReassignVendor.vue'
import { order } from '@/plugins/order/Order'

/**
 * @name ReassignAppraiser
 * Reassign appraiser with reason of why user is reassigning the appraiser
 */
export default defineComponent({
    setup() {
        return {
            order,
        }
    },
    name: 'ReassignAppraiser',
    components: {
        CustomerReassignStaffAppraiser,
        CustomerReassignVendor,
    },
})
