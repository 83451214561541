




























import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { favorites as currentFavorites, user } from '@/plugins/User'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

const dialog = ref(false)
const favorites = ref([])
const amcUsers = ref([])

export const open = async () => {
    await $axios.get('/v1/customer/user').then((response) => {
        amcUsers.value = response.data
    })
    dialog.value = true
    favorites.value = JSON.parse(JSON.stringify(currentFavorites.value))
}
/**
 * @name 'Favorite Users Dialog'
 * Component description: Dialog to set the Favorite users.
 * @SetupMethod submit - Will save the updated favorite users values.
 * @SetupMethod open: Will open the dialog and get the current users favorites.
 * @SetupData loading: boolean if the data has loaded.
 * @SetupData favorites: an array of all the favorite users.
 * @SetupData amcUsers: an array of all the users.
 */
export default defineComponent({
    setup() {
        const submit = (): void => {
            $axios
                .patch('/v1/user/' + user.value?.id + '/favorites', {
                    favorites: favorites.value,
                })
                .then(() => {
                    dialog.value = false
                    currentFavorites.value = JSON.parse(JSON.stringify(favorites.value))
                    showSnackbar('Favorites Updated Successfully')
                })
        }

        return {
            favorites,
            dialog,
            amcUsers,
            submit,
        }
    },
})
