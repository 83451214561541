






























































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { OfficeOrderAppraiser, Phone } from '@/types'
import OrderDetailListItem from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/OrderDetailListItem.vue'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import { openAssign } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { setImpersonateToken } from '@/helpers/token-helper'
import $axios from '@/plugins/axios'

export default defineComponent({
    name: 'DetailVendorWithFee',
    components: {
        OrderDetailListItem,
    },
    props: {
        appraiser: Object as PropType<OfficeOrderAppraiser>,
    },
    setup(_, { root }) {
        const popover = ref(false)
        const getIcon = (phone: Phone, type: string) => {
            switch (type) {
                case 'mobile':
                    return ['Mobile'].indexOf(phone.type) > -1
                case 'office':
                    return ['Office', 'WORK', 'Direct'].indexOf(phone.type) > -1
                default:
                    return ['Mobile', 'Office', 'WORK', 'Direct'].indexOf(phone.type) == -1
            }
        }

        const editAppraiser = () => {
            if (!order.value) return
            if (order.value?.appraiser_id) {
                window.open('/appraiser/' + order.value?.appraiser_id + '/edit', '_blank')
            }
        }

        const impersonateAppraiser = (appraiser_id: number) => {
            $axios
                .post('/v1/ascent/impersonate/appraiser/' + appraiser_id, {
                    path: root.$route.path,
                })
                .then((response) => {
                    if (response.data.data.token) {
                        setImpersonateToken(response.data.data.token)
                    }
                    window.location = response.data.data.path
                })
        }
        return {
            popover,
            getIcon,
            order,
            user,
            editAppraiser,
            openAssign,
            impersonateAppraiser,
        }
    },
})
