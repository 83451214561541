


















import axios from 'axios'

import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'DataMapSelect',
    props: {},
    data() {
        return {
            loading: false,
            search: null,
            items: [] as { id: string; label: string }[],
        }
    },
    mounted() {
        this.getMappedFields()
    },
    methods: {
        getMappedFields() {
            this.loading = true
            axios.post('/v1/search', { type: 'data_map', object: true }).then((response) => {
                this.items = response.data
                this.items.push({ id: 'other', label: 'Other' })
                this.loading = false
            })
        },
    },
})
