import { MenuEventArgs, ContextMenu } from '@syncfusion/ej2-navigations'
import { ref } from '@vue/composition-api'
import { cssUserDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/ChangeCssUser.vue'
import { newFileDialog } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AddOrderFileDialog.vue'
import { open } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderInvoiceDialog.vue'
import { Dialog } from '@/ascent'
import AscentHelper from '@/helpers/ascent-helper'

export const AddContactDialogRef = ref({} as Dialog)

export default class AmcContextMenu {
    static RepMenu: ContextMenu | undefined = undefined
    static ContactsMenu: ContextMenu | undefined = undefined
    static FilesMenu: ContextMenu | undefined = undefined
    static FeesMenu: ContextMenu | undefined = undefined

    static init(type: string) {
        if (type == 'AmcOrderScreen' && AscentHelper.featureEnabled('orderScreen.rep')) {
            AmcContextMenu.RepMenu = new ContextMenu(
                {
                    target: '#rep',
                    items: [{ text: 'Change Assigned CSS' }],
                    select: (arg: MenuEventArgs | undefined) => {
                        if (arg?.item.text === 'Change Assigned CSS') cssUserDialog.value = true
                    },
                },
                '#repMenu',
            )
        }

        AmcContextMenu.ContactsMenu = new ContextMenu(
            {
                target: '#contacts',
                items: [{ text: 'Add Contact' }],
                select: (arg: MenuEventArgs | undefined) => {
                    if (arg?.item.text === 'Add Contact') (AddContactDialogRef.value?.$refs.syncDialog).show()
                },
            },
            '#contactsMenu',
        )

        if (AscentHelper.canDo('Work')) {
            AmcContextMenu.FilesMenu = new ContextMenu(
                {
                    target: '#files',
                    items: [{ text: 'Add File' }],
                    select: (arg: MenuEventArgs | undefined) => {
                        if (arg?.item.text === 'Add File') newFileDialog.value = true
                    },
                },
                '#filesMenu',
            )
        }

        if (AscentHelper.canDo('Edit Forms and Fees')) {
            AmcContextMenu.FeesMenu = new ContextMenu(
                {
                    target: '#fees',
                    items: [{ text: 'Edit Forms and Fees' }],
                    select: (arg: MenuEventArgs | undefined) => {
                        if (arg?.item.text === 'Edit Forms and Fees') open()
                    },
                },
                '#feesMenu',
            )
        }
    }

    static destroyMenus() {
        if (AmcContextMenu.FilesMenu) AmcContextMenu.FilesMenu.destroy()
        if (AmcContextMenu.FeesMenu) AmcContextMenu.FeesMenu.destroy()
        if (AmcContextMenu.RepMenu) AmcContextMenu.RepMenu.destroy()
        if (AmcContextMenu.ContactsMenu) AmcContextMenu.ContactsMenu.destroy()
    }
}
