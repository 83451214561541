





















import Vue from 'vue'
import { DialogPlugin } from '@syncfusion/ej2-vue-popups'
import CustomerOrderContacts from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts.vue'
Vue.use(DialogPlugin)
import { defineComponent, ref } from '@vue/composition-api'
import { Dialog } from '@syncfusion/ej2-popups'
import { createContact } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts/ContactFormDialog.vue'

const contactsDialog = ref({} as Dialog)

export const openContacts = () => {
    contactsDialog.value.show()
}
export default defineComponent({
    components: { CustomerOrderContacts },
    setup() {
        return {
            contactsDialog,
            openContacts,
            createContact,
        }
    },
})
