import { ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { OfficeLender, Sublender, Task } from '@/types'
import { Lender } from '@/types'

export interface OfficeLenderNavItem {
    label: string
    type: string
    task?: string
    title?: string
    status?: string
    onboardingStatus?: string
    options?: string
}
export const lenderId = ref(0)
export const lender = ref({
    id: 0,
    name: '',
    status: '',
    address: {
        street: '',
        sub_street: '',
        city: '',
        state: '',
        zip: '',
    },
    tasks: [] as Task[],
    investors: [] as string[],
} as Lender | OfficeLender)

export const sublender = ref({
    id: 0,
    amcId: 0,
    lenderId: 0,
    name: '',
    address: {
        street: '',
        sub_street: '',
        city: '',
        state: '',
        zip: '',
    },
} as Sublender | undefined)

export const lenderOptions = ref()
export const lenderBillingPageOptions = ref()
export const lenderInfoPageOptions = ref()
export const LenderQualityOptions = ref()

export const sublenderId = ref(0)
export const sublenderOptions = ref()
export const sublenderInfoPageOptions = ref()

export const getLenderSettings = () => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/settings/lender-option')
            .then(({ data }) => {
                lenderOptions.value = data.result
                data.result.map((elem: OfficeLenderNavItem) => {
                    if (elem.label === 'Billing') lenderBillingPageOptions.value = elem.options
                    if (elem.label === 'Company Info') lenderInfoPageOptions.value = elem.options
                    if (elem.label === 'Quality/Delivery') LenderQualityOptions.value = elem.options
                })
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const getSublenderSettings = () => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/settings/sublender-option')
            .then(({ data }) => {
                sublenderOptions.value = data.result
                data.result.map((elem: OfficeLenderNavItem) => {
                    if (elem.label === 'Company Info') sublenderInfoPageOptions.value = elem.options
                })
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}
