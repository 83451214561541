






























































































































































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions, VerosOption } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const optionShow = ref({
            credentials: 'Apex',
            auto: false,
            hold: false,
            ucdp_enabled: '',
            ead_enabled: '',
            ucdp_final: '',
            ead_final: '',
            UCDP: '',
            FRE: '',
            FNM: '',
            EAD: '',
            FHA: '',
            ucdp_on_ead: false,
        } as VerosOption)

        if (props.option !== undefined) {
            optionShow.value = props.option.option_value as VerosOption
        }

        const optionEdit = ref(JSON.parse(JSON.stringify(optionShow.value)))

        const save = () => {
            optionShow.value = JSON.parse(JSON.stringify(optionEdit.value))
            dialog.value = false
            showSnackbar('Veros/UCDP saved successfully')
        }

        return {
            optionShow,
            save,
            dialog,
            optionEdit,
            lender,
        }
    },
})
