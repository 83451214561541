import { ref, computed } from '@vue/composition-api'
import { unreadNotes } from '@/plugins/Notes'

export const queueType = ref()
export const queueTitle = ref('')
export const actions = ref([] as string[])
export const screens = ref([] as string[])
export const checklistItems = ref([] as string[])
export const processInstructions = ref('')
export const notLoad = ref(false)

export interface QueueActions {
    component: string //define the name of the component that will be displayed
    props?: { [x: string]: string }
}

export interface QueueConfiguration {
    queueType: string
    instructions: string
    actions: QueueActions[]
    checklistItems: QueueActions[]
    screens: QueueActions[]
}
export const reminderAdded = ref(false)
export const queueInstructions = ref([] as string[])
export const clientNote = ref(false)
export const appraiserNote = ref(false)
export const internalNote = ref(false)

export const resetQueues = () => {
    reminderAdded.value = false
    clientNote.value = false
    appraiserNote.value = false
    internalNote.value = false
    notLoad.value = false
    queueType.value = null
    actions.value = []
    screens.value = []
    checklistItems.value = []
    processInstructions.value = ''
}

export const getSettings = async (data: QueueConfiguration) => {
    data.actions.map((el: QueueActions) => {
        actions.value.push(el.component)
    })
    data.screens.map((el: QueueActions) => {
        screens.value.push(el.component)
    })
    data.checklistItems.map((el: QueueActions) => {
        checklistItems.value.push(el.component)
    })
    processInstructions.value = data.instructions
}

export const disableFinish = computed(() => {
    if (
        queueType.value !== 'qc-follow-up' &&
        queueType.value !== 'rov-follow-up' &&
        queueType.value !== 'critical-comments' &&
        queueType.value !== 'appraiser-late'
    )
        return false
    if (
        !appraiserNote.value &&
        !clientNote.value &&
        (queueType.value == 'qc-follow-up' || queueType.value == 'rov-follow-up')
    )
        return true
    if (unreadNotes.value.length > 0 && queueType.value === 'critical-comments') return true
    if (
        (queueType.value === 'appraiser-late' && !internalNote.value && !appraiserNote.value) ||
        (queueType.value === 'appraiser-late' && !reminderAdded.value)
    )
        return true

    return false
})

export const updateReminderAdded = () => {
    reminderAdded.value = true
}
