import { computed, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'

export const branchOptions = ref(
    {} as {
        [key: string]: string
    },
)

export const RESET = (): void => {
    // const newState = branchOptions
    // Object.keys(newState).forEach((key) => {
    //     branchOptionskey.value = newState.key.value
    // })
    branchOptions.value = {}
}

export const setBranchOptions = (options: string): void => {
    //Original function below
    // const options = {}
    //
    // branchOptions.value.forEach((option: BranchOptions) => {
    //     options[option.option_key as string] = option.option_value
    // })
    // branchOptions.value = options

    branchOptions.value.key = options
}

export const licensedAppraiserBranchProhibited = computed(() => {
    if (branchOptions.value['prevent-licensed'] !== undefined) {
        return branchOptions.value['prevent-licensed']
    }

    return false
})

export const getBranchOptions = (id: string | number) => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/branch/' + id + '/option')
            .then((response) => {
                RESET()
                setBranchOptions(response.data)
                resolve(response.data)
            })
            .catch((reason) => {
                reject(reason.data)
            })
    })
}
