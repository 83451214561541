





































































































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order } from '@/plugins/order/Order'
import { Appraiser, AppraiserOptionPanel, PanelAppraiser, AppraiserOption } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { loadIncremental, updateOrder } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'
import { assignOpen } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import axios from '@/plugins/axios'
import { UsageFee } from '@/types'

interface DialogContent {
    appraiser: Appraiser | PanelAppraiser
    appraiser_deadline: string
    client_deadline: string
    eligible: boolean
    details: string
    manually_overridden: boolean
}

const dialogContent = ref({
    appraiser: undefined as undefined | Appraiser | PanelAppraiser,
    appraiser_deadline: '',
    client_deadline: '',
    eligible: false,
    details: '',
    manually_overridden: false,
} as DialogContent)
const dialog = ref(false)
const error = ref(false)

export const openAssignDialog = (content: DialogContent) => {
    dialogContent.value = content
    if (!dialogContent.value.appraiser_deadline || !dialogContent.value.client_deadline) error.value = true
    dialog.value = true
}

/**
 * @name AssignDialog
 * Dialog to assign the appraiser to the order
 *
 * @SetupData dialogContent - content of the dialog, specifically the appraiser, deadlines for the client and appraiser, and if appraiser is eligible
 *@SetupMethod openAssignDialog - opens dialog and sets dialogContent
 *
 * @SetupComputed appraiserStagger - determines if appraiser has a set amount of days they need to be staggered from client deadline
 * @SetupMethod getBlackoutDates - gets the blackout dates
 * @SetupMethod getBusinessDatesCounts - takes in start and end date, and returns counts
 * @SetupMethod staggerDays - determines if the appraiser deadline falls in the correct amount of time from the appraiser's stagger
 */

export default defineComponent({
    /**
     * Boolean to determine if this is being done in the assignWorkflow
     */
    props: {
        assignWorkflow: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        ValidationObserver,
    },
    setup() {
        const appraiserStagger = computed(() => {
            if (!dialogContent.value.appraiser || !dialogContent.value.appraiser.options) return -1
            return (dialogContent.value.appraiser as PanelAppraiser).options.findIndex(
                (elem: AppraiserOptionPanel) => elem.stagger,
            )
        })

        const clickFeeRequired = ref(false)
        const hasExistingClickFee = ref(false)
        const ownClientTechFee = ref(!!order?.value?.incremental?.details?.details.ownClickFee)

        const customerClickFeesEnabled = computed(() => {
            return AscentHelper.customerFeatureEnabled('orderScreen.clickFee')
        })

        const appraiserClickFeesEnabled = computed(() => {
            if (!dialogContent.value.appraiser) return false
            if ((dialogContent.value.appraiser as Appraiser).amc_status?.relationship.clickFee) {
                return (
                    (dialogContent.value.appraiser as Appraiser).amc_status?.relationship.clickFee.billable_type ===
                    'appraiser'
                )
            }
            if (!dialogContent.value.appraiser.options) return false
            if (dialogContent.value.appraiser.options.length === 0) return false
            const appraiserClickFeeEnabled =
                (dialogContent.value.appraiser.options as AppraiserOption[])?.find((option) => {
                    return option.click_fees_enabled === true
                }) !== undefined
            return appraiserClickFeeEnabled
        })

        const checkIfClickFeeIsRequired = async () => {
            const clickFee = AscentHelper.customerFee('order')
            const clickFeeEnabled = AscentHelper.featureEnabled('orderScreen.clickFee')
            if (clickFee > 0 && clickFeeEnabled && !hasExistingClickFee.value && appraiserClickFeesEnabled.value) {
                clickFeeRequired.value = true
            }
        }

        const checkExistingClickFee = () => {
            if (!order.value) return
            axios
                .get('/v1/order/' + order.value?.id + '/usage-fees?type=Click%20Fee')
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        const customerClickFees: UsageFee[] = response.data.filter(
                            (fee: UsageFee) => fee.invoice_product_id === 1 && fee.billable_type === 'customer',
                        )
                        const appraiserClickFees: UsageFee[] = response.data.filter(
                            (fee: UsageFee) =>
                                fee.invoice_product_id === 1 &&
                                fee.billable_type === 'appraiser' &&
                                fee.billable_id === order.value?.appraiser_id,
                        )
                        const totalCustomerClickFee = customerClickFees.reduce((acc, curr) => acc + curr.amount, 0)
                        const totalAppraiserClickFee = appraiserClickFees.reduce((acc, curr) => acc + curr.amount, 0)
                        if (totalCustomerClickFee > 0 || totalAppraiserClickFee > 0) {
                            hasExistingClickFee.value = true
                        }
                    }
                    checkIfClickFeeIsRequired()
                })
                .catch(() => {
                    checkIfClickFeeIsRequired()
                })
        }

        const getBlackoutDates = () => {
            const ascentCache = AscentHelper.getAscentCache()
            if (ascentCache && ascentCache.blackoutDates) {
                return ascentCache.blackoutDates
            }

            return []
        }

        const getBusinessDatesCount = (startDate: Date, endDate: Date) => {
            let count = 0
            const curDate = new Date(startDate.getTime())
            while (curDate <= endDate) {
                const dayOfWeek = curDate.getDay()
                if (!(dayOfWeek in [0, 6])) count++
                curDate.setDate(curDate.getDate() + 1)
            }
            getBlackoutDates().map((elem: string) => {
                const fDate = Date.parse(dialogContent.value.appraiser_deadline)
                const lDate = Date.parse(dialogContent.value.client_deadline)
                const cDate = Date.parse(elem)
                if (cDate <= lDate && cDate >= fDate) {
                    count--
                }
            })
            return count
        }

        const staggerDays = computed(() => {
            if (!dialogContent.value.appraiser) return false
            if (!dialogContent.value.appraiser.options) return false
            if (!dialogContent.value.appraiser.options[appraiserStagger.value]) return false
            const stagger = (dialogContent.value.appraiser as PanelAppraiser).options[appraiserStagger.value].stagger
            if (!stagger) return false
            const startDate = new Date(dialogContent.value.appraiser_deadline)
            const endDate = new Date(dialogContent.value.client_deadline)
            const numOfDates = getBusinessDatesCount(startDate, endDate)
            return numOfDates < stagger
        })

        return {
            order,
            dialogContent,
            dialog,
            error,
            staggerDays,
            appraiserStagger,
            appraiserClickFeesEnabled,
            customerClickFeesEnabled,
            hasExistingClickFee,
            ownClientTechFee,
            checkIfClickFeeIsRequired,
            checkExistingClickFee,
            clickFeeRequired,
        }
    },
    mounted() {
        this.checkExistingClickFee()
    },
    watch: {
        dialogContent() {
            this.checkExistingClickFee()
        },
    },
    data() {
        return {
            resolve: null,
            loading: false,
            /**
             * Reason for override if appraiser is not eligible
             */
            overrideReason: null,
        }
    },
    methods: {
        /**
         * Confirms the assignment and assigns the order to the appraiser
         */
        confirm() {
            if (!order.value) return
            if (!this.assignWorkflow) return this.$emit('confirm-assign')
            this.loading = true
            // Send request to endpoint to own client tech fee, if enabled
            if (this.ownClientTechFee) {
                this.$axios.post('/v1/order/' + order.value.id + '/action/own-click-fee', { ownClickFee: true })
            }
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/assign', {
                    appraiser: this.dialogContent.appraiser_deadline,
                    client: this.dialogContent.client_deadline,
                    selected: this.dialogContent.appraiser.id,
                    eligible: this.dialogContent.eligible,
                    details: this.dialogContent.details,
                    override_reason: this.overrideReason,
                    manually_overridden: this.dialogContent.manually_overridden,
                })
                .then(() => {
                    if (!order.value) return
                    loadIncremental()
                    ;(order.value.appraiser_id = this.dialogContent.appraiser.id),
                        (order.value.appraiserName = this.dialogContent.appraiser.name),
                        (order.value.appraiserDeadline = new Date(this.dialogContent.appraiser_deadline).getTime()),
                        (order.value.clientDeadline = new Date(this.dialogContent.client_deadline).getTime())
                    updateOrder()
                    assignOpen.value = false

                    if (!this.dialogContent.eligible) {
                        showSnackbar('Override Submitted')
                    } else {
                        showSnackbar('Appraiser Assigned Successfully!')
                    }
                    this.loading = false
                    this.dialog = false
                })
                .catch(() => {
                    this.loading = false
                    this.dialog = false
                })
        },
        /**
         * Closes the dialog
         */
        cancel() {
            this.dialog = false
            this.loading = false
            this.error = false
        },
    },
})
