

















































import { defineComponent, ref, watch } from '@vue/composition-api'
import { LenderNavItem } from '@/pages/AMC/Lenders/LenderListing/Partials/LenderNavItems'
import { Lender } from '@/types'
import {
    getSublenderSettings,
    sublenderOptions,
    sublender,
    sublenderId,
} from '@/components/General/LenderOptions/LenderOptions'
import SublenderContact from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderSublenders/SublenderCompanyInfo.vue'
import SublenderIntegration from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderSublenders/SublenderIntegration.vue'
import { user } from '@/plugins/User'

const navItemsWithStatus = ref([] as LenderNavItem[])
const dialog = ref(false)
export const openEdit = (selectedSublender: Lender) => {
    if (selectedSublender.address === null) {
        selectedSublender.address = {
            street: '',
            sub_street: '',
            city: '',
            state: '',
            zip: '',
        }
    }
    sublender.value = JSON.parse(JSON.stringify(selectedSublender))
    sublenderId.value = selectedSublender.id
    dialog.value = true
    getSublenderSettings().then(() => {
        navItemsWithStatus.value = sublenderOptions.value
    })
}

export default defineComponent({
    components: { SublenderContact, SublenderIntegration },
    name: 'SublenderEditDialog',
    setup() {
        const currentTab = ref(0)

        watch(dialog, () => {
            if (dialog.value === false) {
                currentTab.value = 0
            }
        })

        const closeDialog = (): void => {
            dialog.value = false
        }

        return {
            currentTab,
            dialog,
            navItemsWithStatus,
            sublender,
            sublenderId,
            closeDialog,
            user,
        }
    },
})
