










import { computed, defineComponent } from '@vue/composition-api'
import CustomerAddTraineeAppraiserDialog, {
    openTraineeDialog,
} from '@/components/OrderScreens/Customer/CustomerOrderComponents/DuplicatedComponents/Dialogs/CustomerAddTraineeAppraiserDialog.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    components: { CustomerAddTraineeAppraiserDialog },
    setup() {
        const traineeName = computed(() => {
            if (!order.value || !order.value.incremental || !order.value.incremental.order_appraisers) {
                return ''
            }

            return (
                order.value.incremental.order_appraisers.find((el) => {
                    return el.role == 'Trainee'
                })?.appraiser.name ?? ''
            )
        })

        return {
            openTraineeDialog,
            traineeName,
        }
    },
})
