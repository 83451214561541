














import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { openDialog } from '@/components/OrderScreens/Office/OrderComponents/Workflows/PreloadReviewDialog.vue'

export default defineComponent({
    setup() {
        const preloadCompleted = computed(() => {
            if (!order.value || !order.value.incremental || !order.value.incremental.order_appraisers) return false
            return !!order.value.incremental.order_appraisers.find((elem) => elem.role == 'Preload')
        })

        const openKitReport = () => {
            if (!order.value?.incremental?.report_uuid) return
            window.open(
                `https://kit-dev.jarodesk.com/beta/#/report/form/${order.value?.incremental?.report_uuid}`,
                '_blank',
            )
        }

        const showJaroKitBetaLink = computed(() => {
            if (
                !order.value ||
                !order.value?.incremental ||
                !order.value?.incremental?.report_uuid ||
                !order.value?.incremental?.appraiser ||
                !order.value?.incremental?.appraiser?.customer_id ||
                !order.value?.incremental?.appraiser?.options ||
                order.value?.incremental?.appraiser?.options?.length === 0 ||
                ['Accept', 'Cancelled'].includes(order.value?.incremental?.status)
            ) {
                return false
            }

            const option = order.value.incremental.appraiser.options.find(
                (opt) => opt.option_key === 'jarokit_beta' && opt.customer_id === order.value?.incremental?.customer_id,
            )

            return option?.option_value ?? false
        })

        return {
            preloadCompleted,
            openDialog,
            openKitReport,
            showJaroKitBetaLink,
        }
    },
})
