import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/appraiser/dashboard',
        name: 'Appraiser Dashboard',
        component: () => import('@/pages/Appraiser/AppraiserDashboard/AppraiserDashboardPage.vue'),
        meta: { requiresAuth: true, userType: ['appraiser'], title: 'Vendor Dashboard' },
    },
    {
        path: '/appraiser/profiles-list',
        name: 'Manage Appraiser Profiles',
        component: () => import('@/pages/Appraiser/Profile/AppraiserProfilesListPage.vue'),
        meta: { requiresAuth: true, userType: ['appraiser'] },
    },
    {
        path: '/appraiser/profile',
        component: () => import('@/pages/Appraiser/Profile/AppraiserProfilePages.vue'),
        meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'desk', 'office'], isSubNav: true },
        children: [
            {
                path: '/appraiser/:id/edit',
                name: 'Appraiser Profile',
                component: () => import('@/pages/Appraiser/Profile/EditProfile/VendorGeneralProfile.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'desk', 'office'], title: 'Vendor Profile' },
            },
            {
                path: '/appraiser/:id/office',
                name: 'Appraiser Office',
                component: () => import('@/pages/Appraiser/Profile/Office/AppraiserOfficeCreatePage.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'desk', 'office'], title: 'Vendor Office' },
            },
            {
                path: '/appraiser/:id/notifications',
                name: 'Notifications',
                component: () => import('@/pages/Appraiser/Profile/Notifications/AppraiserNotificationsPage.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'desk', 'office'] },
            },
            {
                path: '/appraiser/:id/tax',
                name: 'Taxes',
                component: () => import('@/pages/Appraiser/Profile/TaxInformation/AppraiserTaxInformationPage.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'desk', 'office'] },
            },
            {
                path: '/appraiser/:id/work-area',
                name: 'Work Area',
                component: () => import('@/pages/Appraiser/Profile/WorkArea/WorkArea.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'desk', 'office'] },
            },
            {
                path: '/appraiser/:id/vacation',
                name: 'Vacation',
                component: () => import('@/pages/Appraiser/Profile/Vacation/AppraiserVacationManagementPage.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'desk', 'office'] },
            },
            {
                path: '/appraiser/:id/payment-profile',
                name: 'Payment Profile',
                component: () => import('@/pages/Appraiser/Profile/PaymentProfile/AppraiserPaymentProfilePage.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'amc', 'office'] },
            },
            {
                path: '/appraiser/:id/clients',
                name: 'My Clients',
                component: () => import('@/pages/Appraiser/Profile/AppraiserClients/AppraiserClientsPage.vue'),
                meta: { requiresAuth: true, userType: ['appraiser', 'office'] },
            },
            {
                path: '/appraiser/:id/settings',
                name: 'Appraiser Settings',
                component: () => import('@/pages/Appraiser/Profile/SharedProfile/AppraiserAmcSettingsPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'desk', 'office'], title: 'Vendor Settings' },
            },
            {
                path: '/appraiser/:id/appraiser-panels',
                name: 'Appraiser Panels',
                component: () => import('@/pages/Appraiser/Profile/AppraiserPanels/AppraiserPanelsPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'desk', 'office'], title: 'Vendor Panels' },
            },
            {
                path: '/appraiser/:id/audit',
                name: 'Appraiser Audit',
                component: () => import('@/pages/Appraiser/Profile/Audit/AppraiserAuditPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'desk', 'office'], title: 'Vendor Audit' },
            },
            {
                path: '/appraiser/:id/appraiser-exclusion',
                name: 'Appraiser Exclusion Results',
                component: () =>
                    import('@/pages/Appraiser/Profile/AMC/AppraiserExclusionResults/AppraiserExclusionResults.vue'),
                meta: { requiresAuth: true, userType: ['amc'], title: 'Vendor Exclusion Results' },
            },
            {
                path: '/appraiser/:id/appraiser-history',
                name: 'Appraiser Order History',
                component: () => import('@/pages/Appraiser/Profile/AMC/AppraiserHistory/AppraiserHistoryPage.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['amc', 'appraiser', 'desk', 'office'],
                    title: 'Vendor Order History',
                },
            },
            {
                path: '/appraiser/:id/integration-settings',
                name: 'Vendor Integration Settings',
                component: () => import('@/pages/Appraiser/Profile/Secrets/VendorSecretsManager.vue'),
                meta: { requiresAuth: true, userType: ['appraiser'], title: 'Vendor Integration Settings' },
            },
            {
                path: '/appraiser/:id/payments',
                name: 'Vendor Billing',
                component: () => import('@/pages/Appraiser/Profile/Billing/AppraiserBillingPage.vue'),
                meta: { requiresAuth: true, userType: ['amc', 'appraiser'], title: 'Vendor Billing' },
            },
        ],
    },
    {
        path: '/appraiser/office',
        name: 'Appraiser Manage Office',
        component: () => import('@/pages/Appraiser/Office/AppraiserManageOfficePage.vue'),
        meta: { requiresAuth: true, userType: ['appraiser', 'amc'] },
    },
    {
        path: '/appraiser/join-office',
        name: 'Appraiser Office Invite',
        component: () => import('@/pages/Appraiser/Office/AppraiserJoinOfficePage.vue'),
        meta: { requiresAuth: true, userType: ['appraiser'] },
    },
    {
        path: '/appraiser/billing',
        name: 'Appraiser Billing',
        component: () => import('@/pages/Appraiser/Billing/Router/AppraiserBillingRouter.vue'),
        meta: { requiresAuth: true, userType: ['appraiser'] },
    },
    {
        path: '/appraiser/:id/billing',
        name: 'Appraiser Billing Page',
        component: () => import('@/pages/Appraiser/Billing/AppraiserBillingPage.vue'),
        meta: { requiresAuth: true, userType: ['appraiser'] },
    },
    {
        path: '/appraiser/order-search',
        name: 'Appraiser Order Search',
        component: () => import('@/pages/AMC/Settings/OrderSearch.vue'),
        meta: { requiresAuth: true, userType: ['appraiser'], title: ' Order Search' },
    },
    {
        path: '/vendor/invoices',
        name: 'Vendor Invoices',
        component: () => import('@/pages/Appraiser/Billing/AppraiserInvoicesPage.vue'),
        meta: { requiresAuth: true, userType: ['appraiser'] },
    },
    {
        path: '/appraiser-notification-invite/:data',
        name: 'Appraiser Notification Invite',
        component: () => import('@/pages/Appraiser/Profile/Notifications/AppraiserNotificationInvitePage.vue'),
        meta: { requiresAuth: false },
    },
]

export default routes
