





















































































































































import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { DefaultBillingMethodOption, Form, LenderOptions } from '@/types'
import EditSpecificProductDefaultBillingDialog, {
    specificProductDialog,
} from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/EditSpecificProductDefaultBillingDialog.vue'
import axios from 'axios'
import EditSpecificProductBillingOptionsDialog, {
    optionsProductDialog,
} from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/EditSpecificProductBillingOptionsDialog.vue'

export default defineComponent({
    components: {
        EditSpecificProductDefaultBillingDialog,
        EditSpecificProductBillingOptionsDialog,
    },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref({
            default: 'none',
            products: [],
            productBillingOptions: [],
        } as DefaultBillingMethodOption)
        const originalProducts = ref({} as DefaultBillingMethodOption['products'])
        const originalProductBillingOptions = ref([] as DefaultBillingMethodOption['productBillingOptions'])
        const mainProducts = ref([] as Form[])

        if (props.option) {
            optionLocal.value = props.option?.option_value as DefaultBillingMethodOption
            originalProducts.value = JSON.parse(JSON.stringify(optionLocal.value.products))

            originalProductBillingOptions.value = JSON.parse(
                JSON.stringify(optionLocal.value?.productBillingOptions ?? { id: 0, products: [], billingOptions: [] }),
            )
        }
        const openProductSpecificDialog = () => {
            specificProductDialog.value = true
        }
        const openProductOptionDialog = () => {
            optionsProductDialog.value = true
        }
        const defaultBillingOptionItems = [
            { title: 'None', value: 'none' },
            { title: 'Borrower', value: 'borrower-paid' },
            { title: 'Bill To', value: 'net-30' },
            { title: 'Company Credit Card', value: 'company-credit-card' },
        ]

        const productBillingOptionItems = [
            { title: 'Borrower', value: 'borrower_paid' },
            { title: 'Bill To Branch', value: 'branch' },
            { title: 'Bill To', value: 'net-30' },
            { title: 'Company Credit Card', value: 'company_card' },
            { title: 'Saved Credit Card', value: 'credit_card' },
            { title: 'Card up Front', value: 'card_up_front' },
            { title: 'Invoice With Authorization Code', value: 'invoice_with_auth' },
            { title: 'Invoice by Permission', value: 'invoice_with_permission' },
        ]

        const hintMessage =
            "Overrides all orders placed for this lender to have the selected billing method. If 'None' is selected, will not override. If a specific product has an override selected below, any matched orders for that product will be overridden regardless of this option."

        const removeProduct = (key: number) => {
            optionLocal.value.products.splice(key, 1)
        }

        const removeProductBillingOption = (key: number) => {
            if (optionLocal.value.productBillingOptions === undefined) return
            optionLocal.value.productBillingOptions.splice(key, 1)
        }

        const addProduct = () => {
            optionLocal.value.products.push({ id: undefined, value: undefined })
        }

        const addProductBillingOptions = () => {
            if (!optionLocal.value.productBillingOptions) {
                optionLocal.value.productBillingOptions = []
            }

            const id = optionLocal.value.productBillingOptions.length
            optionLocal.value.productBillingOptions.push({ id: id, billingOptions: [], products: [] })
        }

        const cancel = () => {
            optionLocal.value.products = JSON.parse(JSON.stringify(originalProducts.value))
            specificProductDialog.value = false
        }

        const cancelProductBillingOptions = () => {
            if (originalProductBillingOptions.value && optionLocal.value.productBillingOptions) {
                optionLocal.value.productBillingOptions = JSON.parse(
                    JSON.stringify(originalProductBillingOptions.value),
                )
            }
            optionsProductDialog.value = false
        }

        const getProductName = (id: number) => {
            return mainProducts.value.find((product) => {
                return product.id === id
            })?.name
        }

        const getBillingName = (value: string, option: boolean) => {
            if (option) {
                return productBillingOptionItems.find((options) => {
                    return options.value === value
                })?.title
            }
            return defaultBillingOptionItems.find((option) => {
                return option.value === value
            })?.title
        }

        const saveDefaultBillingMethod = () => {
            specificProductDialog.value = false

            $axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'default-billing-method',
                    option_value: optionLocal.value,
                })
                .then(() => {
                    originalProducts.value = optionLocal.value.products
                    showSnackbar('Default Billing Option updated successfully')
                })
        }

        const saveProductBillingOptions = () => {
            optionsProductDialog.value = false
            originalProducts.value = originalProducts.value.filter((el) => {
                return el.id && el.value
            })

            optionLocal.value.products = originalProducts.value
            $axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'default-billing-method',
                    option_value: optionLocal.value,
                })
                .then(() => {
                    originalProductBillingOptions.value = optionLocal.value.productBillingOptions
                    showSnackbar('Product Billing Options updated successfully')
                })
        }
        onMounted(() => {
            axios.get('/v1/form').then((response) => {
                mainProducts.value = response.data.filter((form: Form) => {
                    return form.type === 'Main Product'
                })
            })
        })
        return {
            optionLocal,
            defaultBillingOptionItems,
            productBillingOptionItems,
            saveDefaultBillingMethod,
            saveProductBillingOptions,
            openProductSpecificDialog,
            openProductOptionDialog,
            removeProduct,
            removeProductBillingOption,
            addProduct,
            addProductBillingOptions,
            cancel,
            cancelProductBillingOptions,
            originalProducts,
            hintMessage,
            mainProducts,
            getProductName,
            getBillingName,
        }
    },
})
