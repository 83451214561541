var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order && _vm.order.incremental)?_c('ACard',{class:_vm.user.type === 'office' ? 'office-view' : 'mb-3',scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('OrderServiceProviderToolBar')]},proxy:true}],null,false,1779517496)},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{attrs:{"dense":""}},[(
                            _vm.user &&
                            _vm.user.type === 'office' &&
                            _vm.$ascent.featureEnabled('orderScreen.appraiserDeadline', true)
                        )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text--black font-weight-bold"},[_vm._v(" Appraiser Deadline ")]),(_vm.order.appraiserDeadline)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$ascent.formatUnix(_vm.order.appraiserDeadline, 'MM/dd/yyyy', false))+" ")]):_vm._e()],1),(_vm.$ascent.canDo('Work'))?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openEditMilestone('Appraiser Deadline', _vm.order.appraiserDeadline, true)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icon.edit")])],1)],1):_vm._e()],1):_vm._e()],1),(!_vm.order.incremental.order_appraisers.length)?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v(" No service provider has been assigned to this order yet! ")]):_vm._e(),(_vm.order.incremental.order_appraisers.length)?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.order.incremental.order_appraisers),function(appraiser){return _c('v-list-item',{key:appraiser.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(appraiser.role)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(appraiser.appraiser.name)+" ")])],1),(_vm.user && _vm.user.type === 'office' && _vm.$ascent.canDo('Edit Forms and Fees'))?_c('v-list-item-action',[_vm._v(" $"+_vm._s(appraiser.fee.toFixed(2))+" "),(
                                    appraiser.role !== 'Primary' &&
                                    _vm.$ascent.featureEnabled('orderScreen.orderAppraiserStatus')
                                )?_c('v-chip',{attrs:{"color":_vm.appraiserStatuses[appraiser.role] ? 'success' : 'warning',"x-small":""}},[_vm._v(" "+_vm._s(_vm.appraiserStatuses[appraiser.role] ? 'Completed' : 'Incomplete')+" ")]):_vm._e()],1):_vm._e()],1)}),(_vm.primaryAppraiser && _vm.primaryAppraiser.appraiser && _vm.primaryAppraiser.appraiser.tags)?_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"text-wrap"},[(_vm.primaryAppraiser.status === 'Vacation')?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"red","x-small":"","text-color":"white","pill":"","dark":""}},[_vm._v(" On Vacation ")]):_vm._e(),_vm._l((_vm.primaryAppraiser.appraiser.tags.option_value),function(tag,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"x-small":"","text-color":"white","color":tag.color,"label":"","dark":""}},[_vm._v(" "+_vm._s(tag.tag)+" ")])})],2)],1):_vm._e()],2):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }