
























































import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import ChargeMercuryCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeMercuryCard.vue'
import ChargeValuepadCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeValuepadCard.vue'
import ChargeCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeCard.vue'
import ChargeCompanyCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeCompanyCard.vue'
import AddCreditCard from '@/components/OrderScreens/Amc/AMCActions/Payment/AddCreditCard.vue'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'

export default defineComponent({
    name: 'CreditCardActions',
    components: {
        ChargeCard,
        ChargeMercuryCard,
        ChargeValuepadCard,
        AddCreditCard,
        ChargeCompanyCard,
    },
    setup() {
        const companyCreditCard = computed(() => {
            return lenderOptions.value['lender-saved-card']
        })

        return {
            order,
            companyCreditCard,
        }
    },
})
