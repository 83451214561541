
































import { defineComponent, ref } from '@vue/composition-api'
import {
    refreshDashboardCounts,
    users,
    filterViews,
    oneEnabled,
    singleFilter,
} from '@/components/OrderTable/Classes/TableFilters'
import { updateOrders, viewFilters, viewTeam, viewUser } from '@/components/OrderTable/Classes/OrderTable'
import { name } from '@/components/OrderTable/Classes/OrderTable'
import { updateQuoteTable } from '@/pages/AMC/Quotes/Quotes'
import UserSelectFilter from '@/components/OrderTable/TablePartials/FilterViews/UserSelectFilter.vue'
import LocationSelectFilter from '@/components/OrderTable/TablePartials/FilterViews/LocationSelectFilter.vue'
import LenderSelectFilter from '@/components/OrderTable/TablePartials/FilterViews/LenderSelectFilter.vue'
import PropertyTypeSelectFilter from '@/components/OrderTable/TablePartials/FilterViews/PropertyTypeSelectFilter.vue'
import AppraiserSelectFilter from '@/components/OrderTable/TablePartials/FilterViews/AppraiserSelectFilter.vue'
import TeamSelectFilter from '@/components/OrderTable/TablePartials/FilterViews/TeamSelectFilter.vue'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

export const dialog = ref(false)

/**
 * @name TableUserSelectDialog
 * Dialog on Order Table that allows user to select different users to see their orders on the Order Table
 *
 * @SetupData favorites - Array of users that the user selected as their favorites
 * @SetupData user - Object of current user
 * @SetupMethod refreshDash - Resets the dashboard with orders that the user has selected
 */

export default defineComponent({
    props: {
        tableType: {
            type: String,
        },
    },
    components: {
        UserSelectFilter,
        LenderSelectFilter,
        LocationSelectFilter,
        PropertyTypeSelectFilter,
        AppraiserSelectFilter,
        TeamSelectFilter,
    },
    setup(props) {
        const refreshDash = () => {
            dialog.value = false
            if (AscentHelper.customerFeatureEnabled('assignType') === 'team') {
                localStorage.setItem(
                    `team-select-${user.value?.id}_${user.value?.customer_id}`,
                    JSON.stringify(viewTeam.value),
                )
            }
            localStorage.setItem(
                `user-select-${user.value?.id}_${user.value?.customer_id}`,
                JSON.stringify(viewUser.value),
            )
            localStorage.setItem(
                `view-filters-${user.value?.id}_${user.value?.customer_id}`,
                JSON.stringify(viewFilters.value),
            )
            if (name.value === 'Quote Table') return updateQuoteTable()
            if (!props.tableType) {
                refreshDashboardCounts()
                updateOrders()
            }
        }

        return {
            viewUser,
            users,
            dialog,
            refreshDash,
            filterViews,
            singleFilter,
            oneEnabled,
        }
    },
})
