var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('span',{staticClass:"grey--text"},[_vm._v("Special Directives")])])],1)],1),_c('v-col',{staticClass:"pa-8",domProps:{"innerHTML":_vm._s(_vm.optionShow)}}),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[(_vm.$ascent.canDo('Edit Clients'))?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondarydarken4"}},'v-btn',attrs,false),on),[_vm._v(" Edit ")]):_vm._e()],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('a-card',{attrs:{"title":"Edit Special Directives"}},[_c('v-card-text',[(_vm.ready)?_c('a-editor',{ref:"editor",attrs:{"uuid":"Instructions","tag":"textarea"},model:{value:(_vm.optionEdit),callback:function ($$v) {_vm.optionEdit=$$v},expression:"optionEdit"}}):_vm._e()],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"btnText":"Save Tag Option","url":("/v1/lender/" + (_vm.lender.id) + "/option"),"method":"POST","submitData":{
                    option_key: 'instructions',
                    option_value: _vm.optionEdit,
                }},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.saveChanges},scopedSlots:_vm._u([{key:"secondaryAction",fn:function(){return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('revision', 'instructions')}}},[_vm._v(" Revision History ")])]},proxy:true}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }