







































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { Client, Lender, Participant, Sublender } from '@/types'
import { order, refreshOrder } from '@/plugins/order/Order'
import Vue from 'vue'
import { user } from '@/plugins/User'
import BranchSelect from '@/components/General/AutoCompletes/BranchSelect.vue'

interface CarbonCopyList {
    label: string
    name: string
    type: string
    email: string
    participant_type: string
    participant: Client
}

export default defineComponent({
    name: 'ClientEdit',
    components: { BranchSelect, ValidationObserver },
    props: {},
    data() {
        return {
            dialog: false,
            contactId: null,
            contactType: null,
            loading: false,
            processors: [] as Client[],
            newClient: {
                participants: [] as Participant[],
                officer: null as number | null,
                officerName: '',
                processor: null as number | null,
                branch: null as number | null,
            },
            carboncopylist: [] as CarbonCopyList[],
            officers: [] as Client[],
            currentLender: ref(order.value?.incremental?.lender_id),
            currentSubLender: ref(order.value?.incremental?.sublender?.id),
            lenders: [] as Lender[],
            sublenders: [] as Sublender[],
        }
    },
    mounted() {
        if (!order.value) return
        this.$axios
            .get(`/v1/client/${order.value?.client_id}/action/get-client-details`)
            .then(({ data }) => {
                if (!order.value) return
                this.carboncopylist = data.carbonCopy
                this.officers = data.officers
                this.processors = data.processors
                this.newClient.officer = order.value.officer_id
                this.newClient.branch = order.value.branch_id

                if (order.value.client_id != null) this.newClient.processor = order.value.client_id
                this.mapParticipants()
                this.dialog = true
            })
            .catch(() => {
                if (!order.value) return
                if (order.value.client_id != null) this.newClient.processor = order.value.client_id
                this.mapParticipants()
            })

        if (user && user.value?.type != 'office') return

        watch(this.currentLender, () => {
            this.currentSubLender.value = undefined
            this.$axios.get('v1/lender/' + this.currentLender.value + '/sublenders').then((response) => {
                this.sublenders.values = response.data
            })
        })

        this.$axios.get('/v1/lender', { params: { noPagination: true } }).then((response) => {
            this.lenders = response.data
        })

        if (!this.currentLender.value) return

        this.$axios.get('v1/lender/' + this.currentLender.value + '/sublenders').then((response) => {
            this.sublenders = response.data
        })
    },
    methods: {
        submit() {
            setTimeout(() => {
                if (!order.value) return
                this.loading = true
                const isValid = ref(true)

                this.newClient.participants = this.newClient.participants.filter((elem) => {
                    if (elem?.participant?.email) {
                        return this.validateEmail(elem.participant.email)
                    }

                    if (elem?.email) {
                        return this.validateEmail(elem.email)
                    }

                    if (!this.validateEmail(elem)) {
                        isValid.value = false
                        this.loading = false
                        showError('Sorry, but ' + elem + ' is not a valid email address.')
                    }

                    return this.validateEmail(elem)
                })

                if (!isValid.value) return

                this.$axios
                    .post('/v1/order/' + order.value.id + '/company', {
                        carboncopylist: this.newClient.participants,
                        loanofficer: this.newClient.officer,
                        processor: this.newClient.processor,
                        branch: this.newClient.branch,
                    })
                    .then(() => {
                        this.loading = false
                        const form = this.$refs.form as Vue & { reset: () => void }
                        form.reset()
                        refreshOrder()
                        this.closeDialog()
                        showSnackbar('Client Saved')
                    })
                    .catch((res) => {
                        showError(res.response.data.message)
                        this.loading = false
                    })

                if (user && user.value?.type != 'office') return

                this.$axios
                    .patch('/v1/order/' + order.value.id + '/edit/lender', {
                        order: {
                            lender_id: this.currentLender.value,
                            sublender_id: this.currentSubLender.value,
                        },
                    })
                    .then(() => {
                        refreshOrder()
                    })
                    .catch((res) => {
                        showError(res.response.data.message)
                        this.loading = false
                    })
            }, 2)
        },
        validateEmail(email: Participant | string) {
            const re = /^\S+@\S+\.\S+$/
            return re.test(email as string)
        },
        mapParticipants() {
            this.newClient.participants = order.value?.incremental?.participants.map((elem) => {
                if (elem.participant_type == 'follower') {
                    elem.label = elem.participant.email
                } else {
                    elem.label = elem.participant.name + ' - ' + elem.participant.type
                }
                return elem
            }) as Participant[]
        },
        closeDialog() {
            this.dialog = false
            this.$emit('dialog', this.dialog)
        },
    },
    computed: {
        carbon(): CarbonCopyList[] {
            if (!this.carboncopylist) return []
            return this.carboncopylist.map((elem) => {
                elem.label = elem.name + ', ' + elem.type + ' (' + elem.email + ')'
                return elem
            })
        },
    },
})
