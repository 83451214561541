




















import { defineComponent } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadPropertyInformation from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadPublicRecordInformation/PreloadPropertyInformation.vue'
import PreloadPropertyFeatures from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadPublicRecordInformation/PreloadPropertyFeatures.vue'
import PreloadPropertySize from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadPublicRecordInformation/PreloadPropertySize.vue'
import PreloadPropertyRooms from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadFinalScreen/PreloadPublicRecordInformation/PreloadPropertyRooms.vue'

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadPropertyInformation,
        PreloadPropertyFeatures,
        PreloadPropertySize,
        PreloadPropertyRooms,
    },
    setup() {
        return {}
    },
})
