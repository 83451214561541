




import { defineComponent, computed } from '@vue/composition-api'
import pretty from 'pretty'

export default defineComponent({
    props: {
        source: { type: String, required: true },
    },
    setup(props) {
        const formattedSource = computed(() => pretty(props.source))

        return { formattedSource }
    },
})
