






































import ClientPaymentDetails from '@/components/OrderScreens/Amc/AMCActions/Payment/ClientPaymentDetails.vue'
import { order } from '@/plugins/order/Order'
import { role, user } from '@/plugins/User'
import Vue from 'vue'
import { computed, defineComponent } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper'
import { Payment } from '@/types'

/**
 * @name ClientOrderPayments
 * List of payments that have been made from the client
 * Data in setup: order, role, user
 */

export default defineComponent({
    setup() {
        const canCreateClientPayment = computed(() => {
            if (!user.value) return false
            if (!order.value?.incremental) return false
            if (order.value?.incremental.source_system === 'closing' && order.value?.billingMethod !== 'net-30')
                return false
            return AscentHelper.canDo('Add Client Payment')
        })

        const getPaymentDetails = (payment: Payment) => {
            if (
                payment.source !== 'Pending Credit Card' &&
                payment.source !== 'Credit Card' &&
                payment.source !== 'Company Card'
            )
                return ''
            if (typeof payment.details.card === 'string' || typeof payment.details.card === 'undefined') return ''
            return (
                payment.details.card.name +
                ' - ' +
                String(payment.details.card.last_four).substring(payment.details.card.last_four.length - 4)
            )
        }
        return {
            order,
            role,
            user,
            canCreateClientPayment,
            getPaymentDetails,
        }
    },
    components: {
        ClientPaymentDetails,
    },
    methods: {
        /**
         * Opens a specific payment to get more details
         * @param item
         */
        openPayment(item: PaymentItem) {
            const details = this.$refs.details as Vue & { open: (item: PaymentItem) => void }
            details.open(item)
        },
        openDialog() {
            this.$emit('open-client-payment')
        },
    },
})
