
























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import AppraiserScheduleIssue from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/AppraiserScheduleIssue.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import DateTimeDialog, {
    date,
    time,
} from '@/components/OrderScreens/Appraiser/AppraiserMobileComponents/DateTimeDialog.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { user } from '@/plugins/User'

/**
 * @name AppraiserScheduleAction
 * Workflow step in the appraiser workflow steps to allow appraiser to schedule inspection
 *
 * @SubmitMethod - scheduleAppraisal: Saves inspection date and time to the order. Posts to /v1/order/order.id/workflow/schedule. Refreshes order after inspection is scheduled
 */

export default defineComponent({
    components: {
        DateTimeDialog,
        ValidationObserver,
        AppraiserScheduleIssue,
    },
    setup() {
        const modal = ref(false)
        const loading = ref(false)

        const scheduleAppraisal = (): void => {
            loading.value = true
            if (!order.value) return
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/schedule', {
                    appointment: date.value + ' ' + time.value,
                })
                .then(() => {
                    loading.value = false

                    refreshOrder()

                    showSnackbar('Appointment Scheduled Successfully')
                })
                .catch(() => {
                    loading.value = false
                })
        }

        const settingEnabled = ref(false)

        const setScheduleSetting = (answer: boolean) => {
            $axios
                .patch('/v1/appraiser/' + user.value?.appraiser?.id + '/settings/1', {
                    settings: {
                        schedule_invite: {
                            enabled: answer,
                        },
                    },
                })
                .then(() => {
                    settingEnabled.value = true
                    showSnackbar('Notification Preference Updated Successfully')
                })
        }

        onMounted(() => {
            $axios.get('/v1/appraiser/' + user.value?.appraiser?.id + '/settings/').then(({ data }) => {
                if (data.settings && data.settings.schedule_invite) {
                    settingEnabled.value = true
                }
            })
        })

        return {
            modal,
            date,
            time,
            user,
            loading,
            setScheduleSetting,
            settingEnabled,
            scheduleAppraisal,
        }
    },
})
