var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',{staticClass:"accentWhite",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center mx-8"},[_c('v-col',{staticClass:"my-4"},[_c('h2',[_vm._v("Integration")])])],1),(_vm.loaded)?_c('a-card',{staticClass:"mx-8"},[(!_vm.$ascent.canDo('Edit Clients') && !Object.keys(_vm.options).length)?_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_vm._v("No Integrations Added")])],1):_vm._e(),(_vm.$ascent.canDo('Edit Clients'))?_c('v-row',[_c('v-col',{attrs:{"align":Object.keys(_vm.options).length ? 'end' : 'center'}},[_c('v-btn',_vm._g(_vm._b({class:Object.keys(_vm.options).length && 'mr-8 mt-4',attrs:{"disabled":!_vm.integrations.length,"text":"","color":"secondarydarken4"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(Object.keys(_vm.options).length ? 'Add Integration' : 'Please Add An Integration')+" ")])],1)],1):_vm._e(),_vm._l((_vm.options),function(value,key,index){return _c('v-row',{key:key},[_c('v-col',[_c(key,{tag:"component",attrs:{"option":_vm.options[key],"componentName":"integration"}}),(index !== Object.keys(_vm.options).length - 1)?_c('v-divider'):_vm._e()],1)],1)})],2):_vm._e(),_c('v-container',[_c('v-row',{staticClass:"ma-16"}),_c('v-row',{staticClass:"ma-16"}),_c('v-row',{staticClass:"ma-16"}),_c('v-row',{staticClass:"ma-16"})],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('a-card',{attrs:{"title":"Add Integration"}},[_c('v-card-text',[_c('a-select-field',{attrs:{"items":_vm.integrations,"label":"Select Integration","persistent-hint":""},model:{value:(_vm.integrationSelect),callback:function ($$v) {_vm.integrationSelect=$$v},expression:"integrationSelect"}})],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"btnText":"Save","url":'/v1/sublender/' + _vm.sublender.id + '/option',"submitData":{
                    option_key: _vm.integrationSelect,
                    option_value: { company: '' },
                }},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.save}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }