





































import { defineComponent, ref } from '@vue/composition-api'
import { lastUpdateVar } from '@/pages/AMC/Settings/AdvancedSearch.vue'
import { valueArray, keyArray } from './OrderSearchFilter.vue'

interface Facet {
    count: number
    label: string | number
}

export const facetValue = ref<string[]>([])
export const clearAdvFilters = () => {
    facetValue.value = []
}
export default defineComponent({
    name: 'FacetSearch',
    props: {
        title: String,
        item: [Object, Array],
    },

    setup() {
        const filter = ref('')

        const showAll = ref(false)

        const escapeString = (str: string) => {
            return str.replace("'", "\\'")
        }

        const filterItems = (items: { [x: string]: number }, title?: string) => {
            if (title === 'orderStatus') {
                const statusObj = { 'All Pending': 'n/a', 'All Completed': 'n/a' }
                items = Object.assign(statusObj, items)
            }
            const keys = Object.keys(items)
            if (filter.value) {
                const filterKey = keys.filter((data) => data.toLowerCase().includes(filter.value.toLowerCase()))
                if (showAll.value === true) {
                    return filterKey.map((key: string) => {
                        return {
                            count: items[key],
                            label: key,
                        }
                    })
                }
                return filterKey
                    .map((key: string) => {
                        return {
                            count: items[key],
                            label: key,
                        }
                    })
                    .slice(0, 10)
            }
            if (showAll.value === true) {
                return keys
                    .map((key: string) => {
                        return {
                            count: items[key],
                            label: key,
                        } as Facet
                    })
                    .sort((a: Facet, b: Facet) => {
                        return b.count - a.count
                    })
            }
            if (title === 'orderStatus') {
                return keys
                    .map((key: string) => {
                        return {
                            count: items[key],
                            label: key,
                        } as Facet
                    })
                    .slice(0, 10)
            }
            return keys
                .map((key: string) => {
                    return {
                        count: items[key],
                        label: key,
                    } as Facet
                })
                .sort((a: Facet, b: Facet) => {
                    const arrToSort = valueArray.value.map((value: string) => value.replace(/['"]+/g, ''))
                    if (arrToSort.includes(a.label as string) || arrToSort.includes(b.label as string)) {
                        const aSortValue = arrToSort.includes(a.label as string) ? a.count : -1
                        const bSortValue = arrToSort.includes(b.label as string) ? b.count : -1
                        return bSortValue - aSortValue
                    }

                    return b.count - a.count
                })
                .slice(0, 10)
        }

        const expandRows = (items: { [x: string]: number }) => {
            showAll.value = true
            filterItems(items)
        }

        return {
            escapeString,
            filterItems,
            facetValue,
            filter,
            lastUpdateVar,
            expandRows,
            showAll,
            valueArray,
            keyArray,
        }
    },
    methods: {},
})
