<template>
    <v-navigation-drawer v-if="openDrawer != null" :value="openDrawer != null" app clipped right>
        <notifications-list :item-groups="itemGroups" :view-all="viewAll" @close-drawer="closeDrawer" />
    </v-navigation-drawer>
</template>

<script>
import NotificationsList from '@/pages/System/Notifications/NotificationsList'
import {
    getShowNotifications,
    groupedNotifications,
    showNotifications,
} from '@/pages/System/Notifications/notifications'
import { approvals, groupedApprovalNotifications } from '@/pages/System/Notifications/NotificationPartials/Approvals'
/**
 * @name 'Right Nav'
 * Component description: Will display the right nav which is the notifications
 * @SetupData closeDrawer() => : Will close the right nav
 */
export default {
    components: {
        NotificationsList,
    },
    setup() {
        const closeDrawer = () => {
            showNotifications.value = false
            approvals.value.showNotifications = false
        }

        return { closeDrawer }
    },
    data() {
        return {
            itemGroups: [],
            viewAll: this.viewAllNotifications,
            viewAllNotifications: {
                text: 'See All Notifications',
                route: '/notifications',
            },
            viewAllApprovals: {
                text: 'See All Approvals',
                route: '/approvals',
            },
        }
    },
    computed: {
        /**
         * Will open the right nav to show the notifications and approvals.
         */
        openDrawer() {
            if (getShowNotifications.value) {
                return 'notifications'
            }

            if (approvals.value.showNotifications) {
                return 'approvals'
            }

            return null
        },
        /**
         * Will return the notifications
         */
        userNotifications() {
            return groupedNotifications.value
        },
        /**
         * Will return the Approval Notifications
         */
        approvalNotifications() {
            return groupedApprovalNotifications()
        },
    },
    watch: {
        /**
         * Will open the right nav with the notifications and approvals
         */
        openDrawer() {
            const timeout = !this.openDrawer ? 500 : 0

            setTimeout(() => {
                if (getShowNotifications.value) {
                    this.itemGroups = this.userNotifications
                    this.viewAll = this.viewAllNotifications
                }

                if (approvals.value.showNotifications) {
                    this.itemGroups = this.approvalNotifications
                    this.viewAll = this.viewAllApprovals
                }
            }, timeout)
        },
        /**
         * Will return the notifications
         */
        userNotifications() {
            if (this.openDrawer === 'notifications') {
                this.itemGroups = this.userNotifications
            }
        },
        /**
         * Will return the Approval Notifications
         */
        approvalNotifications() {
            if (this.openDrawer === 'approvals') {
                this.itemGroups = this.approvalNotifications
            }
        },
    },
}
</script>
