











































































import { defineComponent } from '@vue/composition-api'
import { order, representativePhone } from '@/plugins/order/Order'
import ClientOrderDates from '@/components/OrderScreens/Client/OrderComponents/ClientOrderDates.vue'

/**
 * @name ClientOrderDetails
 * Component in Client Order Screen that gives details about the order to the client
 */

export default defineComponent({
    name: 'ClientOrderDetails',
    components: { ClientOrderDates },

    setup() {
        return {
            order,
            representativePhone,
        }
    },
})
