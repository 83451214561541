import { FormDetails } from '@/ascent'
import { computed, ref } from '@vue/composition-api'
import { AppraiserQuoteResponse, AppraiserTag, ProductForm, Quote, Tag, AppraiserRoleName } from '@/types'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { sendQuotes } from '@/pages/AMC/Quotes/Quotes'
import { user } from '@/plugins/User'
import { orderProducts } from '@/plugins/order/OrderProducts'
import { calcVendorFee } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/CalcAppraiserProductFee'
import AscentHelper from '@/helpers/ascent-helper'
import { getValidJaroPanels } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'

interface OpenPanel {
    id: number
    type: string
}

interface AppraiserPanel {
    appraiser: AppraiserQuoteResponse
    availability: string
    distance: number | string
    panel: number
    status: string
    index?: number
}

interface SetPanel {
    panel: string
    appraisers: AppraiserPanel[]
}

export const tabs = [
    { tab: '#branch-panel', type: 'branch', name: 'Branch Panel' },
    { tab: '#lender-panel', type: 'lender', name: 'Lender Panel' },
    { tab: '#primary-panel', type: 'primary', name: 'Primary Panel' },
    {
        tab: '#secondary-panel',
        type: 'secondary',
        name: 'Secondary Panel',
    },
]

export const tabsSelection = ref({
    branch: [],
    lender: [],
    primary: [],
    secondary: [],
} as { [key: string]: AppraiserPanel[] })
export const currentTab = ref('')
export const allAppraisers = ref([] as AppraiserPanel[])
export const selectedAppraiser = ref(undefined as undefined | number | null)
export const allowSelect = ref(true)
export const quotes = ref([] as Quote[])
export const appraiserDeadline = ref('')
export const clientDeadline = ref('')
export const panelDialog = ref(false)
export const trainee = ref(false)
export const panelTabs = computed(() => tabs.filter((elem) => tabsSelection.value[elem.type].length > 0))
export const closedPanel = ref(false)

export const availableTab = ref(true)

const getOption = () => {
    if (!order.value) return
    if (!order.value.incremental) return
    $axios.get(`/v1/lender/${order.value.incremental.lender_id}/option/trainee-appraiser`).then((response) => {
        if (response && response.data) return (trainee.value = response.data.option_value)
        return (trainee.value = false)
    })
}

export const activeTab = computed(() => {
    if (currentTab.value !== '') return currentTab.value
    if (tabsSelection.value.branch.length > 0) return 'branch-panel'
    if (tabsSelection.value.lender.length > 0) return 'lender-panel'
    if (tabsSelection.value.primary.length > 0) return 'primary-panel'
    if (tabsSelection.value.secondary.length > 0) return 'secondary-panel'
    return ''
})

export const getPanel = (type: string) => {
    return tabsSelection.value[type]
}

export const setPanel = (update: SetPanel) => {
    if (user.value && user.value.type == 'office' && update.panel != 'primary' && update.panel != 'lender') return

    const staffAppraiser = update.appraisers.filter((elem) => {
        if (!elem.appraiser.appraiser_tags) return false
        return (elem.appraiser.appraiser_tags as AppraiserTag[]).find((el) => el.tag === 'Staff')
    })
    const otherAppraisers = update.appraisers.filter((elem) => {
        if (!elem.appraiser.appraiser_tags) return true
        return !(elem.appraiser.appraiser_tags as AppraiserTag[]).find((el) => el.tag === 'Staff')
    })
    const panelAppraisers = [...staffAppraiser, ...otherAppraisers]
    panelAppraisers.forEach((elem, index) => {
        elem.index = index
    })
    tabsSelection.value[update.panel] = panelAppraisers
    if (update.appraisers.length > 0) {
        allAppraisers.value = allAppraisers.value.concat(update.appraisers)
    }
}

const setPanelError = (error: SetPanel) => {
    tabsSelection.value[error.panel] = error.appraisers
}

const getClosedPanel = () => {
    if (!order.value) return
    $axios.get('/v1/branch/' + order.value?.branch_id + '/option/closed-panel').then((response) => {
        if (response.data.option_value) {
            closedPanel.value = response.data.option_value
        }
    })
}

const getBranchPanel = (details: OpenPanel) => {
    closedPanel.value = false
    availableTab.value = true
    $axios
        .post('/v1/' + details.type + '/' + details.id + '/panel', {
            type: 'branch',
        })
        .then((response) => {
            if (response.data.result === 'error') {
                setPanelError({ panel: 'branch', appraisers: response.data.message })
                return
            }

            setPanel({
                panel: 'branch',
                appraisers: response.data.appraisers,
            })
            if (response.data.appraisers.length > 0) {
                getClosedPanel()
            }
        })
}

const getLenderPanel = (details: OpenPanel) => {
    $axios
        .post('/v1/' + details.type + '/' + details.id + '/panel', {
            type: 'lender',
        })
        .then((response) => {
            if (response.data.result == 'error') {
                setPanelError({ panel: 'lender', appraisers: response.data.message })
                return
            }
            setPanel({
                panel: 'lender',
                appraisers: response.data.appraisers,
            })
        })
}

const getPrimaryPanel = (details: OpenPanel) => {
    $axios
        .post('/v1/' + details.type + '/' + details.id + '/panel', {
            type: 'primary',
        })
        .then((response) => {
            if (response.data.result == 'error') {
                setPanelError({ panel: 'primary', appraisers: response.data.message })
                return
            }
            const appraisers = response.data.appraisers

            if (
                response.data &&
                response.data.appraisers &&
                !order.value?.address.latitude &&
                !order.value?.address.longitude
            ) {
                appraisers.map((elem: AppraiserPanel) => {
                    if (!elem.distance) {
                        elem.distance = 'Not Available'
                    }
                })
            }

            setPanel({
                panel: 'primary',
                appraisers: appraisers,
            })
        })
}

const getSecondaryPanel = (details: OpenPanel) => {
    $axios
        .post('/v1/' + details.type + '/' + details.id + '/panel', {
            type: 'secondary',
        })
        .then((response) => {
            if (response.data.result == 'error') {
                setPanelError({ panel: 'secondary', appraisers: response.data.message })
                return
            }
            setPanel({
                panel: 'secondary',
                appraisers: response.data.appraisers,
            })
        })
}

export const GET_PANELS = (details: OpenPanel) => {
    return new Promise((resolve) => {
        getPrimaryPanel(details)
        if (user.value?.type !== 'office') {
            getBranchPanel(details)
            getLenderPanel(details)
            getSecondaryPanel(details)
        }

        if (details.type === 'order' && order.value && selectedAppraiser.value) {
            selectedAppraiser.value = order.value.appraiser_id
        }
        getOption()

        resolve(true)
    })
}

export const openDialog = (details: OpenPanel) => {
    return new Promise((resolve) => {
        GET_PANELS(details).then(() => {
            panelDialog.value = true
            allowSelect.value = false
            resolve(true)
        })
    })
}

export const AppraisalPanelReset = (): void => {
    tabsSelection.value = {
        branch: [],
        lender: [],
        primary: [],
        secondary: [],
        directory: [],
    }
    currentTab.value = ''
    allAppraisers.value = []
    selectedAppraiser.value = undefined
    allowSelect.value = true
    sendQuotes.value = []
    quotes.value = []
    appraiserDeadline.value = ''
    clientDeadline.value = ''
    panelDialog.value = false
}

export const setClientDeadline = (value: string): void => {
    clientDeadline.value = value
}

export const setAppraiserDeadline = (value: string): void => {
    appraiserDeadline.value = value
}

const getOfficePrimary = (details: OpenPanel) => {
    $axios
        .post('/v1/' + details.type + '/' + details.id + '/assign/get-office-team-panel', {
            panel: 'full',
        })
        .then((response) => {
            setPanel({
                panel: 'primary',
                appraisers: response.data.appraisers,
            })
        })
}

export const GET_OFFICE_PANELS = (details: OpenPanel) => {
    return new Promise((resolve) => {
        getOfficePrimary(details)
        if (details.type === 'order' && order.value && selectedAppraiser.value) {
            selectedAppraiser.value = order.value.appraiser_id
        }
        resolve(true)
    })
}

export const GET_APPRAISER_FEE = async (role: AppraiserRoleName, appraiser_id: number): Promise<number | undefined> => {
    return await $axios
        .get('v1/appraiser/' + appraiser_id + '/fees')
        .then(({ data }) => {
            if (orderProducts.value && orderProducts.value.length > 0) {
                const innovationFee = orderProducts.value.find(
                    (elem) => elem.product_name === 'Innovation Fee',
                ) as ProductForm
                const mainProduct = orderProducts.value.find((elem) => elem.formType === 'Main Product') as ProductForm

                let mainDetails = null
                if (mainProduct.form && mainProduct.form.detail && mainProduct.form.detail.details) {
                    mainDetails = mainProduct.form?.detail?.details as FormDetails
                }

                if (
                    AscentHelper.customerFee('resCommissionable') > 0 &&
                    mainDetails &&
                    (!('commercial' in mainDetails) || mainDetails.commercial == false)
                ) {
                    return AscentHelper.customerFee('resCommissionable')
                }

                return AscentHelper.parseCurrency(
                    orderProducts.value.reduce((accumulator, elem) => {
                        return accumulator + calcVendorFee(role, elem, data, innovationFee, mainProduct)
                    }, 0),
                )
            }
            return 0
        })
        .catch(() => {
            return 0
        })
}

export const isDeclined = computed(() => {
    return order.value?.tags?.find((elem: Tag) => elem.tag === 'Declined') !== undefined
})
export const isConditional = computed(() => {
    return order.value?.tags?.find((elem: Tag) => elem.tag === 'Conditional') !== undefined
})

export const assignOpen = ref(false)
export const openAssign = () => {
    assignOpen.value = true
    if (AscentHelper.customerFeatureEnabled('usesJaroPanels', false)) {
        getValidJaroPanels({ order_id: order.value?.id, customer_id: order.value?.customer_id }).then(() => {
            return
        })
        return
    }
    if (user.value?.type === 'office') {
        GET_OFFICE_PANELS({
            type: 'order',
            id: order?.value?.id as number,
        }).then(() => {
            if (orderProducts.value.find((el) => el.product_name === '1004D Final Inspection')) {
                selectedAppraiser.value = order.value?.incremental?.duplicates?.find(
                    (el) => el.duplicate_cache?.status === 'Completed',
                )?.duplicate_cache?.appraiser_id
            }
        })
        return
    }
    GET_PANELS({
        type: 'order',
        id: order?.value?.id as number,
    })
}

export const deadlinesRequired = ref(false)
