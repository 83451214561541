














































































































import ApprovalNotifications from '@/AppLayout/navigation/ApprovalNotifications.vue'
import UserNotifications from '@/pages/System/Notifications/UserNotifications.vue'
import Favorites, { open } from '@/pages/AMC/Dashboard/Partials/FavoriteUsersDialog.vue'
import FavoriteTeams, { openFavoriteTeams } from '@/pages/AMC/Dashboard/Partials/FavoriteTeamsDialog.vue'
import { currentCustomer, user, role, impersonated } from '@/plugins/User'
import $axios from '@/plugins/axios'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import TenancySwitcher from '@/AppLayout/navigation/TenancySwitcher.vue'
import { flushToken, getToken, checkAscentImpersonationToken } from '@/helpers/token-helper'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import { getAllTeams, teams } from '@/plugins/Amc'
import AscentHelper from '@/helpers/ascent-helper'

/**
 * @name 'User Nav'
 * Component description: The user nav at the top right of the site.
 * Has the routes and paths of the user settings
 * @SetupData logout() => : Logs the user out
 */
export default defineComponent({
    setup() {
        const isImpersonatedFromAscentUser = ref(false)

        const showVaporUILink = computed(() => {
            if (!user.value) return false
            return user.value.ascent
        })

        const openVaporUI = () => {
            getToken()
                .then((token) => {
                    if (token && token.accessToken) {
                        window.open(process.env.VUE_APP_API + '/vapor-ui?access_token=' + token.accessToken, '_blank')
                        return
                    }
                    showError('Unable to find access token. Please try again.')
                })
                .catch((error) => {
                    showError(error)
                })
        }

        const redirectToAuthLogout = () => {
            user.value = undefined
            window.location.href = process.env.VUE_APP_AUTH_URL + '/oauth/logout'
        }

        const logout = () => {
            flushToken()
            $axios
                .post('/v1/flush-cookies', {})
                .then(() => {
                    redirectToAuthLogout()
                })
                .catch(() => {
                    redirectToAuthLogout()
                })
        }

        const isTeamLead = computed(() => {
            return !!teams.value.find((elem) => elem.owner && elem.owner.id == user.value?.id)
        })

        onMounted(() => {
            if (AscentHelper.isCustomer()) {
                getAllTeams()
            }
            if (checkAscentImpersonationToken()) {
                isImpersonatedFromAscentUser.value = true
            }
        })

        return {
            user,
            open,
            logout,
            role,
            isTeamLead,
            openVaporUI,
            showVaporUILink,
            impersonated,
            isImpersonatedFromAscentUser,
            openFavoriteTeams,
            currentCustomer,
        }
    },
    components: {
        TenancySwitcher,
        ApprovalNotifications,
        UserNotifications,
        Favorites,
        FavoriteTeams,
    },
    data() {
        return {
            notificationsMenu: false,
        }
    },
})
