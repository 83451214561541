










































































import { computed, ComputedRef, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import ClientOrderListOfPaymentsDialog from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientOrderListOfPaymentsDialog.vue'

/**
 * @name ClientOrderFeesAndPayments
 * Component in Client Order Screen that shows the fees on the order and if they have been paid or not.
 *
 * @SetupComputed clientAmountDue - returns the fee on the order that is due
 * @SetupComputed clientAmountPaid - returns the value that has been paid on the order
 * @SetupComputed totalDue - returns how much is due. Taking the fee and the amount paid.
 * @SetupComputed totalDueClass - returns color of if order is paid or not
 *
 */

export default defineComponent({
    name: 'ClientOrderFeesAndPayments',
    components: {
        ClientOrderListOfPaymentsDialog,
    },
    setup() {
        const clientAmountDue: ComputedRef<number> = computed(() => {
            if (!order.value || order.value.fee == null) return 0
            return order.value.fee
        })
        const clientAmountPaid: ComputedRef<number> = computed(() => {
            if (!order.value || order.value.paid == null) return 0
            return order.value.paid
        })
        const totalDue = computed(() => {
            return clientAmountDue.value - clientAmountPaid.value
        })
        const totalDueClass = computed(() => {
            if (order.value?.paidStatus === 'Paid') {
                return 'success'
            } else if (clientAmountDue.value > clientAmountPaid.value) {
                return 'error'
            }
            return 'error'
        })

        const defaultItems = [
            { value: 'borrower-paid', text: 'Borrower', disabled: true },
            { value: 'net-30', text: 'Invoice', disabled: true },
            { value: 'credit-card', text: 'Credit Card', disabled: true },
            { value: 'split', text: 'Split', disabled: true },
        ]

        const billingMethod = computed(() => {
            if (!order.value) return ''
            return defaultItems.find((item) => item.value === order.value?.billingMethod)?.text
        })

        return {
            clientAmountDue,
            clientAmountPaid,
            totalDue,
            billingMethod,
            totalDueClass,
            order,
        }
    },
})
