









import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        value: [String, Number, Boolean],
        label: String,
    },
})
