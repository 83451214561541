































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import { branchId, branch } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const dialog = ref(false)
        const appraisal = ref('Appraisal')
        const final = ref('Appraisal')
        const insurance = ref('Appraisal')
        const license = ref('Appraisal')
        const ssr = ref('Appraisal')
        const invoice = ref('Appraisal')
        const compliance = ref('Appraisal')

        onMounted(() => {
            $axios.get('/v1/branch/' + branchId.value + '/option/encompass-folders').then((response) => {
                if (response.data.option_value) {
                    appraisal.value = response.data.option_value.Appraisal
                    final.value = response.data.option_value.Final
                    insurance.value = response.data.option_value.Insurance
                    license.value = response.data.option_value.License
                    ssr.value = response.data.option_value.SSR
                    invoice.value = response.data.option_value.Invoice
                    compliance.value = response.data.option_value.Compliance
                }
            })
        })

        const save = () => {
            dialog.value = false
            showSnackbar('Encompass Buckets Saved')
        }

        return {
            open,
            dialog,
            appraisal,
            save,
            final,
            insurance,
            license,
            ssr,
            invoice,
            compliance,
            branchId,
            branch,
        }
    },
})
