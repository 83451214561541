




































































































































































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { LenderOptions, ReconsiderationOfValue } from '@/types'
import AEditor from '@/global/Form/AEditor.vue'
import FileUpload from '@/components/General/FileUpload.vue'
import axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { File } from '@/types'

const labels = {
    ascent: 'Jaro Online Form',
    apex: 'Our Custom PDF',
    lender: 'Lender PDF',
}
export default defineComponent({
    components: { AEditor, ValidationObserver, FileUpload },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const showUploader = ref(false)
        const rovLink = ref(false)
        const dialog = ref(false)
        const loading = ref(false)
        const added = ref(false)
        const optionShow = ref({
            type: '',
            settings: '',
            file: '',
            rovLink: false,
        })

        if (props.option !== undefined) {
            optionShow.value = props.option.option_value as ReconsiderationOfValue
        }

        const optionEdit = ref(JSON.parse(JSON.stringify(optionShow.value)))

        const saveChanges = () => {
            dialog.value = false
            optionShow.value = JSON.parse(JSON.stringify(optionEdit.value))

            showSnackbar('Option saved successfully')
        }

        const closeDialog = () => {
            dialog.value = false
            optionEdit.value = JSON.parse(JSON.stringify(optionShow.value))
        }

        const getFile = (file: File) => {
            axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'reconsideration-of-value',
                    option_value: {
                        type: 'lender',
                        settings: optionShow.value.settings,
                        file: 'https://d1ci5iplv58a4s.cloudfront.net/' + file.path,
                    },
                })
                .then(() => {
                    optionEdit.value.file = 'https://d1ci5iplv58a4s.cloudfront.net/' + file.path
                    optionShow.value.file = optionEdit.value.file
                    showUploader.value = false
                    showSnackbar('File uploaded successfully')
                })
        }

        const openFile = () => {
            window.open(optionEdit.value.file, '_blank')
        }

        const deleteFile = () => {
            axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'reconsideration-of-value',
                    option_value: {
                        type: optionEdit.value.type,
                        settings: optionEdit.value.settings,
                        file: '',
                    },
                })
                .then(() => {
                    optionEdit.value.file = ''
                    showSnackbar('File deleted successfully')
                    optionShow.value.file = ''
                })
        }

        return {
            openFile,
            deleteFile,
            showUploader,
            loading,
            added,
            getFile,
            labels,
            dialog,
            lender,
            optionShow,
            optionEdit,
            saveChanges,
            closeDialog,
            rovLink,
        }
    },
})
