

































import { defineComponent, ref } from '@vue/composition-api'
import { currentDashboard, dashboards, setFilterViews } from '@/components/General/Dashboard/Dashboards'
import { orderFilter } from '@/components/OrderTable/Classes/OrderTable'
import { activeFilter, filterConditions, findFilterConditions } from '@/components/OrderTable/Classes/TableFilters'
import { Dashboard } from '@/types/Dashboard'
import { view } from '@/components/General/DashboardFilters/DashboardFilters'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        const loading = ref(false)

        const getDashboard = async (dashboard: Dashboard) => {
            if (!user.value) return
            loading.value = true
            localStorage.setItem(`user_dashboard_${user.value.id}_${user.value.customer_id}`, JSON.stringify(dashboard))
            view.value = 'Table'
            orderFilter.value = dashboard.defaultFilter
            activeFilter.value = orderFilter.value
            filterConditions.value = []
            setFilterViews()
            findFilterConditions(activeFilter.value, 'filters')
            loading.value = false
        }

        return {
            currentDashboard,
            dashboards,
            getDashboard,
            loading,
            order,
        }
    },
})
