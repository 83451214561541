var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ACard',[_c('template',{slot:"top"},[_c('v-app-bar',{attrs:{"flat":"","color":"accentWhite text-white","dense":""}},[_c('v-toolbar-title',{staticClass:"title pl-0"},[_vm._v("Approval")])],1)],1),_c('v-container',{attrs:{"fluid":"","pa-4":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-bold text--primary"},[_vm._v(" "+_vm._s(_vm.remainingClientFee)+" "),_c('span',{staticClass:"small"},[_vm._v("due")])]),_c('p',{staticClass:"small mt-2"},[_vm._v(" "+_vm._s(_vm.order.productList)+" / "+_vm._s(_vm.order.loanPurpose)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('a-radio-group',{attrs:{"rules":"required","label":"Approve order?","row":"","color":"success","name":"response"},on:{"change":function($event){_vm.reason = ''}},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}},[_c('v-radio',{attrs:{"label":"Approve","value":"Approve"}}),_c('v-radio',{attrs:{"label":"Cancel","value":"Cancel"}})],1)],1)],1),(_vm.answer === 'Approve')?_c('v-row',[(_vm.remainingClientFee !== 0)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card',{attrs:{"tile":"","flat":""}},[(_vm.order.incremental.cards.length > 0)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"info","text":""}},[_vm._v(" Choose which card and amount to charge, add a new card, or bill to branch. ")]),_c('a-select-field',{attrs:{"items":_vm.order.incremental.cards,"item-text":"cc_label","return-object":"","label":"Credit Card Last Four","name":"credit card","outlined":"","rules":"required"},model:{value:(_vm.card),callback:function ($$v) {_vm.card=$$v},expression:"card"}})],1),(_vm.card.id !== 0 && _vm.card.cc_label === 'Add New Card')?_c('v-col',{attrs:{"cols":"12"}},[_c('credit-card',{ref:"credit",attrs:{"show":false,"help":false,"customer-id":_vm.order.customer_id,"disallow":_vm.$ascent.customerFeatureEnabled('creditCard.disallow', []),"show-address":_vm.$ascent.customerFeatureEnabled('showCreditCardAddress')},on:{"card-added":_vm.cardAdded}}),_c('v-btn',{attrs:{"color":"primary","depressed":"","dark":"","loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.addCard)}}},[_vm._v(" Add Card ")])],1):_vm._e(),(_vm.card.id !== 0 && _vm.card.cc_label !== 'Add New Card')?_c('v-col',{attrs:{"cols":"12"}},[_c('a-text-field',{attrs:{"name":"amount","label":"Amount","rules":'required|max_value:' + _vm.remainingClientFee.substr(1),"prepend-icon":"$vuetify.icons.dollarSign","autofocus":""},model:{value:(_vm.chargeAmount),callback:function ($$v) {_vm.chargeAmount=$$v},expression:"chargeAmount"}})],1):_vm._e()],1),(_vm.error != null)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","dense":"","type":"error","icon":"$vuetify.icons.exclamation-triangle"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)],1):_vm._e()],1):_vm._e(),(_vm.card.id !== 0)?_c('v-card-actions',[(
                                        _vm.chargeAmount > 0 &&
                                        _vm.card.cc_label !== 'Add New Card' &&
                                        _vm.card.cc_label !== 'Bill To Branch'
                                    )?_c('div',[_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":_vm.charging},on:{"click":function($event){return handleSubmit(_vm.chargeCard)}}},[(_vm.remainingClientFee - _vm.chargeAmount === 0)?_c('span',{staticClass:"mr-1"},[_vm._v(" Approve & ")]):_vm._e(),_vm._v(" Charge Card for "+_vm._s(_vm.chargeAmount)+" ")]),_c('p',{staticClass:"small mt-2"},[_vm._v(" NOTE: Clicking this button will immediately charge the card for the amount above. ")])],1):_vm._e()]):_vm._e()],1)]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":"","color":"accentBlacklighten3"}},[_c('v-card-title',[_vm._v("Payments")]),_c('v-card-text',[_c('v-data-table',{staticClass:"c-table__invoice",attrs:{"headers":_vm.headers,"items":_vm.order.incremental.payments,"hide-default-footer":""},scopedSlots:_vm._u([{key:_vm.no-_vm.data,fn:function(){return [_c('v-alert',{attrs:{"color":"info","text":"","dense":""}},[_c('p',{staticClass:"small"},[_vm._v("No payments recorded.")])])]},proxy:true},{key:"item",fn:function(ref){
                                    var item = ref.item;
return [_c('tr',[_c('td',{},[_c('a',{attrs:{"href.prevent":"#"}},[_vm._v(_vm._s(item.source))])]),_c('td',{},[_vm._v(" "+_vm._s(_vm.$ascent.formatIsoDate(item.created_at, 'EEE, MMM d'))+" ")]),_c('td',{staticClass:"text-right"},[(item.amount >= 0)?_c('span',[_vm._v("- $"+_vm._s(item.amount))]):_c('span',[_vm._v("+ $"+_vm._s(item.amount))])])])]}}],null,true)})],1),(_vm.remainingClientFee !== 0)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":"","small":"","loading":_vm.charging},on:{"click":_vm.processClientApproval}},[_vm._v(" Approve & Bill Branch for "+_vm._s(_vm.remainingClientFee)+" ")])],1):_vm._e()],1)],1)],1):_vm._e()],1),(_vm.answer === 'Cancel')?_c('v-card-actions',[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"depressed":"","color":"primary"},on:{"click":function($event){return handleSubmit(_vm.cancelOrder)}}},[_vm._v(" Cancel Order ")])],1):_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }