








































import { defineComponent, ref } from '@vue/composition-api'
export const dialog = ref(false)

export default defineComponent({
    props: {
        emails: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    setup(props) {
        const emailsRef = ref(props.emails)
        const disabled = ref(true)

        return {
            disabled,
            emailsRef,
            dialog,
        }
    },
})
