import $axios from '@/plugins/axios'
import { computed, ref } from '@vue/composition-api'
import { Note, CarbonCopy, ExtraNoteField } from '@/types'
import { order, refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export const scrollToBottom = () => {
    const vDialog = document.querySelector('.messages')
    if (typeof vDialog !== 'undefined') {
        vDialog?.scrollTo({
            left: 0,
            top: vDialog.scrollHeight,
            behavior: 'smooth',
        })
    }

    const vDialog2 = document.getElementById('notesScrollBox')
    if (typeof vDialog2 !== 'undefined') {
        vDialog2?.scrollTo({
            left: 0,
            top: vDialog2.scrollHeight,
            behavior: 'smooth',
        })
    }
}

export const newNote = ref({
    note: null as string | null,
    source: 'AMC',
    target: null as string | null,
    tag: null,
    cc: [] as (CarbonCopy | string)[],
    read: false,
    extra: {} as ExtraNoteField,
})
export const notes = ref([] as Note[])
export const audit = ref(false)
export const tagsFilter = ref([] as string[])
export const type = ref('All')
export const lookup = ref({
    Internal: 'Internal',
    Client: 'Client',
    'Prior Appraiser': 'Appraiser',
    Appraiser: 'Appraiser',
} as { [key: string]: string })
export const types = ref(['All', 'Internal', 'Client', 'Appraiser'])

export const showAllNotes = ref(false)

export const filteredNotes = computed(() => {
    return notes.value.filter((elem) => {
        if (elem.read === false && !tagsFilter.value.length) return true

        if (tagsFilter.value.length !== 0 && (elem.tag == null || !tagsFilter.value.includes(elem.tag.tag as string))) {
            return false
        }
        if (elem.tag) return true
        if (!showAllNotes.value && !tagsFilter.value.length) {
            return (
                [
                    'AMC',
                    'Status',
                    'Client',
                    'Client Audit',
                    'Appraiser',
                    'Appraiser Audit',
                    'Error',
                    'Prior Appraiser',
                    'Escalation',
                ].indexOf(elem.target) > -1
            )
        }

        if (!tagsFilter.value.length) return true
    })
})

export const appraiserPanelNotes = computed(() => {
    return notes.value.filter(
        (elem) =>
            elem.target == 'Assign Email' ||
            elem.target == 'Assign Phone' ||
            (elem.source == 'Prior Appraiser' && elem.target == 'AMC') ||
            (elem.source === 'AMC' && elem.target === 'Prior Appraiser'),
    )
})

export const unreadNotes = computed(() => {
    return notes.value.filter((elem) => {
        if (elem.read === false) return true
    })
})

export const qualityNotes = computed(() => {
    return notes.value.filter((elem) => {
        if (elem.tag != null && 'QC' === elem.tag.tag) return true
        if (elem.tag != null && 'Manager Message' === (elem.tag.tag as string)) return true
        if (!elem.read) return true
    })
})

export const paymentNotes = computed(() => {
    return notes.value.filter((elem) => {
        if (elem.tag != null && 'Payment' === elem.tag.tag) return true
        if (elem.tag != null && 'Accounting' === elem.tag.tag) return true
        return false
    })
})

export const resetNote = () => {
    newNote.value = {
        note: null,
        source: 'AMC',
        target: null,
        tag: null,
        cc: [],
        read: false,
        extra: {},
    }
}

export const addNote = (note: Note) => {
    notes.value.push(note)
}

export const setNotes = (newNotes: Note[]) => {
    notes.value = newNotes
    setTimeout(() => {
        scrollToBottom()
    }, 500)
}

const addTagFilter = (newTag: string) => {
    if (tagsFilter.value.length === 0 && newTag !== 'All Tags') {
        tagsFilter.value.push(newTag)
    } else if (newTag === 'All Tags' || newTag === null) {
        tagsFilter.value = []
    } else {
        tagsFilter.value = []
        tagsFilter.value.push(newTag)
    }
}

const changeReadMutation = (update: { old: Note; newNote: Note }) => {
    Object.assign(notes.value[notes.value.indexOf(update.old)], update.newNote)
}

export const getNotes = async (id: number) => {
    return await $axios.get('/v1/order/' + id + '/note', { params: { beta: true } }).then((response) => {
        if (!response) return
        setNotes(response.data)
        return
    })
}

export const getUnreadNotes = async (id: number) => $axios.get('/v1/order/' + id + '/unread-notes')

export const changeRead = (note: Note) => {
    return new Promise((resolve, reject) => {
        $axios
            .patch('/v1/note/' + note.id, {
                read: !note.read,
            })
            .then((response) => {
                changeReadMutation({ old: note, newNote: response.data })
                resolve(true)
            })
            .catch(() => {
                reject('Could Not Process Request')
            })
    })
}

export const markAllUnreadRead = () => {
    $axios
        .post('v1/order/action/' + order.value?.id + '/complete', {
            queueType: 'unread',
        })
        .then(() => {
            showSnackbar('All messages marked read successfully!')
            refreshOrder()
        })
}

export const filterNotesByTag = (tag: string) => {
    addTagFilter(tag)
}

export const ResetAllNotes = (): void => {
    newNote.value = {
        note: '',
        source: 'AMC',
        target: null,
        tag: null,
        cc: [],
        read: false,
        extra: {},
    }
    notes.value = []
    audit.value = false
    tagsFilter.value = []
    type.value = 'All'
    lookup.value = {
        Internal: 'Internal',
        Client: 'Client',
        'Prior Appraiser': 'Appraiser',
        Appraiser: 'Appraiser',
    }
    types.value = ['All', 'Internal', 'Client', 'Appraiser']
}

export const scrollToNote = (note: number) => {
    const noteRef = document.getElementById(note.toString())
    const vDialog = document.querySelector('.messages')
    if (typeof vDialog !== 'undefined' && noteRef) {
        vDialog?.scrollTo({
            left: 0,
            top: noteRef?.offsetTop - noteRef.offsetHeight,
            behavior: 'smooth',
        })
    }
}
