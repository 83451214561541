














import { defineComponent, ref } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadActionButtons from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadActionButtons.vue'
import PreloadListingHistory from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSaleHistory/PreloadListingHistory.vue'
import PreloadSalesDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSaleHistory/PreloadSalesDetails.vue'
import { reviewStep, finalReview } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadActionButtons,
        PreloadListingHistory,
        PreloadSalesDetails,
    },
    setup() {
        const editData = ref(false)
        const editCancelled = ref(false)

        const cancelEdit = () => {
            editCancelled.value = true
            editData.value = false
        }

        const openEditData = () => {
            editData.value = true
            editCancelled.value = false
        }

        const saveEdit = () => {
            editData.value = false
        }

        return {
            editData,
            openEditData,
            cancelEdit,
            saveEdit,
            reviewStep,
            editCancelled,
            finalReview,
        }
    },
})
