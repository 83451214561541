<template>
    <v-data-table
        :headers="headers"
        :items="conditionList"
        disable-pagination
        hide-default-footer
        item-key="condition"
        class="elevation-1 mt-3 mb-3"
        :hide-default-header="conditionList.length === 0"
    >
        <template v-slot:no-data>
            <v-alert :value="true" type="info" text icon="$vuetify.icons.exclamation" class="mb-0">
                Sorry, no conditions added.
            </v-alert>
        </template>
        <template v-slot:item="{ item }">
            <tr v-if="order" :class="[item.appraiser_complete && item.verified ? 'bg-ascent-lightgray' : '']">
                <td>
                    <span class="d-flex align-center" v-if="user && user.type !== 'appraiser'">
                        <v-alert
                            v-if="
                                item.appraiser_complete &&
                                !item.verified &&
                                (order.status !== 'QC Review' || order.status !== 'Reconsideration Review')
                            "
                            type="warning"
                            dense
                            text
                            small
                            class="ma-2 small"
                            style="font-size: small; width: 100%"
                        >
                            Pending Completion
                        </v-alert>
                        <v-btn
                            v-if="
                                item.appraiser_complete &&
                                !item.verified &&
                                (order.status === 'QC Review' || order.status === 'Reconsideration Review')
                            "
                            ripple
                            icon
                            small
                            color="success"
                            @click="verifyCondition(item)"
                        >
                            <v-icon small>$vuetify.icons.success</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="
                                item.appraiser_complete &&
                                !item.verified &&
                                (order.status === 'QC Review' || order.status === 'Reconsideration Review')
                            "
                            ripple
                            icon
                            small
                            color="error"
                            @click="failCondition(item)"
                        >
                            <v-icon small>$vuetify.icons.times</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="
                                (!item.appraiser_complete &&
                                    (order.status === 'QC Review' ||
                                        order.status === 'UW Request' ||
                                        order.status === 'UW Revision' ||
                                        order.status === 'QC Revision' ||
                                        order.status === 'Reconsideration Review')) ||
                                (item.appraiser_complete &&
                                    !item.verified &&
                                    (order.status !== 'QC Review' || order.status !== 'Reconsideration Review'))
                            "
                            icon
                            small
                            ripple
                            class="ma-0"
                            @click="removeCondition(item.id)"
                        >
                            <v-icon small color="error">$vuetify.icons.trashAlt</v-icon>
                        </v-btn>
                        <v-icon v-if="item.appraiser_complete && item.verified" color="success">
                            $vuetify.icons.thumbsUp
                        </v-icon>
                    </span>
                </td>
                <td v-html="item.condition"></td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import { user } from '@/plugins/User'
import { verifyCondition, failCondition, removeCondition } from '@/plugins/order/OrderConditions'
import { order } from '@/plugins/order/Order'
import { computed } from '@vue/composition-api'

/**
 * @name ConditionList
 * List of conditions on the order and actions user can take on conditions
 *
 * @SetupComputed conditionList - filters conditions down to those that appraiser has not yet completed.
 */

export default {
    setup() {
        const conditionList = computed(() => {
            if (user.value !== undefined && user.value.type === 'appraiser') {
                return order.value.incremental.conditions.filter((elem) => !elem.appraiser_completed)
            }
            if (order.value.incremental.conditions) return order.value.incremental.conditions
            return []
        })
        return { order, conditionList, user }
    },
    data() {
        return {
            /**
             * Headers for the condition table
             */
            headers: [
                {
                    text: '',
                    value: 'verify',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Conditions',
                    align: 'left',
                    value: 'condition',
                    sortable: false,
                },
            ],
            /**
             * Selected conditions
             */
            selected: [],
        }
    },

    methods: {
        verifyCondition,
        failCondition,
        removeCondition,
        toggleAll() {
            if (this.selected.length) this.selected = []
            else this.selected = JSON.parse(JSON.stringify(this.conditions))
        },
        /**
         * Sorts the column of the condition
         * @param column - column in the conditions table
         */
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },
    },
}
</script>

<style>
tr.v-data-table__empty-wrapper td {
    padding: 0 !important;
}
.v-data-table__wrapper p {
    margin-bottom: 0;
}
</style>
