





















import { defineComponent } from '@vue/composition-api'
import { Appraiser } from '@/types'

export default defineComponent({
    props: {
        label: {
            type: String,
            default: 'Appraiser',
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            items: [],
        }
    },
    watch: {
        search(val: string): void {
            val && val !== this.select && this.searchApprasier(val)
        },
    },
    mounted() {
        this.searchApprasier('')
    },
    methods: {
        searchApprasier(search: string): void {
            this.loading = true
            this.$axios
                .post('/v1/search', {
                    val: search,
                    type: 'office_supervisor',
                    object: true,
                    limit: 250,
                })
                .then(({ data }) => {
                    data = data.filter(
                        (newData: Appraiser, index: number, array: Appraiser[]) =>
                            index ===
                            array.findIndex(
                                (elm: Appraiser) => elm.company === newData.company && elm.email === newData.email,
                            ),
                    )
                    this.items = data.sort((a: { name: string }, b: { name: string }) => {
                        return a.name < b.name || a.name == null ? -1 : 1
                    })
                    this.loading = false
                })
        },
    },
})
