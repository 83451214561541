








import { defineComponent, PropType } from '@vue/composition-api'
import { ExtraNoteField } from '@/types'

export default defineComponent({
    name: 'AuditNoteText',
    props: {
        details: {
            type: Object as PropType<ExtraNoteField>,
            default: null,
        },
    },
})
