var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('span',{staticClass:"grey--text"},[_vm._v("Assignment Restrictions")])])],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-list',[_c('v-list-item',[_c('strong',[_vm._v("Experience")])]),_c('v-list-item',[_c('strong',[_vm._v("EO Per Claim")])]),_c('v-list-item',[_c('strong',[_vm._v("EO Limit")])]),_c('v-list-item',[_c('strong',[_vm._v("Preferred Appraisers")])])],1)],1),_c('v-col',[_c('v-list',[_c('v-list-item',[(_vm.settingsDisplay.experience)?_c('span',[_vm._v(_vm._s(_vm.settingsDisplay.experience))]):_vm._e(),(!_vm.settingsDisplay.experience)?_c('span',{staticClass:"grey--text"},[_vm._v("Not Set")]):_vm._e()]),_c('v-list-item',[(_vm.settingsDisplay.eo_per_claim)?_c('span',[_vm._v(" "+_vm._s(_vm.settingsDisplay.eo_per_claim)+" ")]):_vm._e(),(!_vm.settingsDisplay.eo_per_claim)?_c('span',{staticClass:"grey--text"},[_vm._v("Not Set")]):_vm._e()]),_c('v-list-item',[(_vm.settingsDisplay.eo_limit)?_c('span',[_vm._v(_vm._s(_vm.settingsDisplay.eo_limit))]):_vm._e(),(!_vm.settingsDisplay.eo_limit)?_c('span',{staticClass:"grey--text"},[_vm._v("Not Set")]):_vm._e()]),_c('v-list-item',[_vm._v(" "+_vm._s(_vm.settingsDisplay.preferred_appraisers)+" ")])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[(_vm.$ascent.canDo('Edit Clients'))?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondarydarken4"}},'v-btn',attrs,false),on),[_vm._v(" Edit ")]):_vm._e()],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('a-card',{attrs:{"title":"Restriction Settings"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',[_c('v-row',[_c('v-col',[_c('a-text-field',{attrs:{"rules":'numeric',"color":"primary","label":"Years of Experience","name":"experience"},model:{value:(_vm.settings.experience),callback:function ($$v) {_vm.$set(_vm.settings, "experience", $$v)},expression:"settings.experience"}})],1)],1),_c('v-row',[_c('v-col',[_c('a-text-field',{attrs:{"rules":'numeric',"color":"primary","label":"E&O Per Claim Requirements","name":"errors and omissions claim limit"},model:{value:(_vm.settings.eo_per_claim),callback:function ($$v) {_vm.$set(_vm.settings, "eo_per_claim", $$v)},expression:"settings.eo_per_claim"}})],1)],1),_c('v-row',[_c('v-col',[_c('a-text-field',{attrs:{"rules":'numeric',"color":"primary","label":"E&O Aggregate Minimum","name":"errors and omissions aggregate limit"},model:{value:(_vm.settings.eo_limit),callback:function ($$v) {_vm.$set(_vm.settings, "eo_limit", $$v)},expression:"settings.eo_limit"}})],1)],1),_c('v-row',[_c('v-col',[_c('a-select-field',{attrs:{"items":[
                                'Has Preferred Lender Appraisers',
                                'Has Preferred Branch Appraisers',
                                'Has No Preferred Appraisers' ],"label":"Preferred Appraiser Setting","rules":"required","persistent-hint":""},model:{value:(_vm.settings.preferred_appraisers),callback:function ($$v) {_vm.$set(_vm.settings, "preferred_appraisers", $$v)},expression:"settings.preferred_appraisers"}})],1)],1)],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"btnText":"Save Restrictions","url":'/v1/lender/' + _vm.lender.id + '/option',"submitData":{
                    option_key: 'restrictions',
                    option_value: _vm.settings,
                }},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.saveChanges}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }