







































import { ref, defineComponent, reactive } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import ARadioField from '@/components/DynamicForm/ARadioField.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

/**
 * @name OrderJaroProduct
 * Dialog to allow user to order another product on the report after the report is completed
 */

export default defineComponent({
    name: 'OrderJaroProduct',
    components: { ACardAction, ValidationObserver, ARadioField },
    setup() {
        const dialog = ref(false)
        const product = reactive({
            type: 'Review',
            product: '',
            agree: false,
        })
        const productList = ['Review']
        const reviewProducts = ['Jaro K2-Review']

        const ordered = () => {
            dialog.value = false
            showSnackbar(
                'Product Ordered. When the product has finished generating, this order will update automatically',
            )
        }
        return {
            order,
            product,
            productList,
            reviewProducts,
            ordered,
            dialog,
        }
    },
})
