import Echo from 'laravel-echo'
import $axios from '@/plugins/axios'
import Pusher from 'pusher-js'
import VueCompositionApi, { ref } from '@vue/composition-api'
import Vue from 'vue'
import { User } from '@/types'
import { user as storeUser } from '@/plugins/User'
import { PusherPresenceChannel, Channel as EchoChannel } from 'laravel-echo/dist/channel'
import { AuthData } from 'pusher-js/types/src/core/auth/options'

interface ChannelConnection {
    channelRef: string
    id?: number
}

interface Channel {
    channel: string | undefined
    connector: EchoChannel | undefined
    present: ChannelUser[] | undefined
    prefix: string
    type: string
}

export interface ChannelUser {
    name: string
    id: number
    type: string
    color?: string
}

interface SetChannelUser {
    channelRef: string
    users?: ChannelUser[]
    user?: ChannelUser
    userIndex?: number
}

Vue.use(VueCompositionApi)
const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY as string, {
    cluster: 'us3',
    forceTLS: true,
    authorizer: function (channel) {
        return {
            authorize: function (socketId, callback) {
                if (localStorage.getItem('jaro_auth')) {
                    $axios
                        .post('/broadcasting/auth', {
                            socket_id: socketId,
                            channel_name: channel.name,
                        })
                        .then((response) => {
                            callback(null, response.data)
                        })
                        .catch((error) => {
                            callback(error, error)
                        })
                } else {
                    callback(null, {} as AuthData)
                }
            },
        }
    },
})

export const disconnected = ref(false)

pusher.connection.bind('state_change', (state: { current: string }) => {
    if (state.current === 'unavailable') {
        disconnected.value = true
    }
    if (state.current === 'connected') {
        disconnected.value = false
    }
})

const EchoConnection = new Echo({
    broadcaster: 'pusher',
    client: pusher,
})

const defaultChannelState = (channelRef: string) => {
    let channelPrefix
    let channelType

    switch (channelRef) {
        case 'orderBroadcast':
            channelPrefix = 'orderBroadcast.'
            channelType = 'public'
            break
        case 'orderPresence':
            channelPrefix = 'order.'
            channelType = 'presence'
            break
        case 'orderUpdate':
            channelPrefix = 'orderUpdate.'
            channelType = 'private'
            break
        case 'notifications':
            channelPrefix = 'App.Models.User.'
            channelType = 'private'
            break
        case 'approvals':
            channelPrefix = 'amc-approvals.'
            channelType = 'private'
            break
        case 'announcements':
            channelPrefix = 'announcements'
            channelType = 'private'
            break
        case 'qualityUser':
            channelPrefix = 'quality.'
            channelType = 'private'
            break
        case 'escalationApprovals':
            channelPrefix = 'escalation-approvals.'
            channelType = 'private'
            break
        case 'snapshot':
            channelPrefix = 'snapshot.'
            channelType = 'private'
            break
        case 'tenancy':
            channelPrefix = 'App.Models.User.'
            channelType = 'private'
            break
        case 'userRefresh':
            channelPrefix = 'App.Models.User.'
            channelType = 'private'
            break
        default:
            channelPrefix = channelRef + '.'
    }

    return {
        prefix: channelPrefix,
        channel: undefined,
        type: channelType,
        present: [],
        connector: undefined,
    }
}

const channels = ref({
    orderBroadcast: defaultChannelState('orderBroadcast'),
    orderPresence: defaultChannelState('orderPresence'),
    orderUpdate: defaultChannelState('orderUpdate'),
    notifications: defaultChannelState('notifications'),
    approvals: defaultChannelState('approvals'),
    announcements: defaultChannelState('announcements'),
    qualityUser: defaultChannelState('qualityUser'),
    escalationApprovals: defaultChannelState('escalationApprovals'),
    snapshot: defaultChannelState('snapshot'),
    tenancy: defaultChannelState('tenancy'),
    userRefresh: defaultChannelState('userRefresh'),
} as { [key: string]: Channel })

export const getPresentUsers = (channelRef: string): ChannelUser[] => {
    return channels.value[channelRef].present as ChannelUser[]
}

export const getChannelConnector = (channelRef: string) => {
    return channels.value[channelRef].connector
}

const RESET = () => {
    channels.value = {
        orderBroadcast: defaultChannelState('orderBroadcast'),
        orderPresence: defaultChannelState('orderPresence'),
        orderUpdate: defaultChannelState('orderUpdate'),
        notifications: defaultChannelState('notifications'),
        approvals: defaultChannelState('approvals'),
        announcements: defaultChannelState('announcements'),
        qualityUser: defaultChannelState('qualityUser'),
        tenancy: defaultChannelState('tenancy'),
        userRefresh: defaultChannelState('userRefresh'),
    } as { [key: string]: Channel }
}

export const resetChannelRef = (channelRef: string) => {
    if (channels.value[channelRef].channel != undefined) {
        EchoConnection.leave(channels.value[channelRef].channel as string)
    }
    Object.assign(channels.value[channelRef], defaultChannelState(channelRef))
}

const setChannel = (params: { channelRef: string; channel: Channel }) => {
    channels.value[params.channelRef] = params.channel
}

const setPresentUsers = (params: SetChannelUser) => {
    channels.value[params.channelRef].present = params.users
}

const addPresentUser = (params: SetChannelUser) => {
    const channelUsers = channels.value[params.channelRef].present as ChannelUser[]
    channelUsers.push(params.user as ChannelUser)
}

const removePresentUser = (params: SetChannelUser) => {
    const channelUsers = channels.value[params.channelRef].present as ChannelUser[]
    channelUsers.splice(params.userIndex as number, 1)
}

const setConnector = (params: { channelRef: string; connector: EchoChannel }) => {
    channels.value[params.channelRef].connector = params.connector
}

const joinPresenceChannel = (channelRef: string): Promise<PusherPresenceChannel> => {
    return new Promise((resolve) => {
        const connector = EchoConnection.join(channels.value[channelRef].channel as string)
            .here((users: User[]) => {
                const mappedUsers = users
                    .filter((user) => user.type !== 'ascent' || (storeUser.value as User).ascent)
                    .map((user) => {
                        return {
                            id: user.id,
                            name: user.name,
                            type: user.type,
                        } as ChannelUser
                    })
                setPresentUsers({
                    channelRef: channelRef,
                    users: mappedUsers,
                })
            })
            .joining((user: User) => {
                if (user.type === 'ascent' && !(storeUser.value as User).ascent) return
                const mappedUser = {
                    id: user.id,
                    name: user.name,
                    type: user.type,
                }
                addPresentUser({
                    channelRef: channelRef,
                    user: mappedUser,
                })
            })
            .leaving((user: User) => {
                if (user.type === 'ascent' && !(storeUser.value as User).ascent) return
                const leavingUserIndex = (channels.value[channelRef].present as ChannelUser[]).findIndex(
                    (arrayItem: { id: number }) => arrayItem.id === user.id,
                )
                removePresentUser({
                    channelRef: channelRef,
                    userIndex: leavingUserIndex,
                })
            })
        setConnector({
            channelRef: channelRef,
            connector: connector as PusherPresenceChannel,
        })

        resolve(connector as PusherPresenceChannel)
    })
}

const joinPrivateChannel = (channelRef: string): Promise<EchoChannel> => {
    return new Promise((resolve) => {
        const connector = EchoConnection.private(channels.value[channelRef].channel as string)
        setConnector({
            channelRef: channelRef,
            connector: connector,
        })
        resolve(connector)
    })
}

const joinPublicChannel = (channelRef: string): Promise<EchoChannel> => {
    return new Promise((resolve) => {
        const connector = EchoConnection.channel(channels.value[channelRef].channel as string)
        setConnector({
            channelRef: channelRef,
            connector: connector,
        })
        resolve(connector)
    })
}

export const handleConnection = (params: ChannelConnection): Promise<EchoChannel> => {
    return new Promise((resolve) => {
        const channelRef = params.channelRef
        if (channels.value[channelRef].channel != undefined) {
            resetChannelRef(channelRef)
        }

        const channel = channels.value[channelRef]
        channel.channel = channel.prefix + ((params.id as number) > 0 ? params.id : '')

        setChannel({ channelRef: channelRef, channel: channel })
        switch (channels.value[channelRef].type) {
            case 'presence':
                joinPresenceChannel(channelRef).then((connector) => {
                    resolve(connector)
                })
                break
            case 'private':
                joinPrivateChannel(channelRef).then((connector) => {
                    resolve(connector)
                })
                break
            case 'public':
                joinPublicChannel(channelRef).then((connector) => {
                    resolve(connector)
                })
                break
        }
    })
}

export const disconnectAll = () => {
    pusher.connection.bind('disconnected', () => {
        pusher.connect() // reconnect for new session
        pusher.connection.unbind('disconnected')
    })
    pusher.disconnect() // disconnect from all channels
    RESET()
}
