<template>
    <v-container pa-0>
        <v-row>
            <v-col class="py-2" cols="12" align-self="center">
                <a-select-field
                    :items="items"
                    item-disabled="disabled"
                    :value="order && order.incremental.billing_method"
                    name="billing method"
                    label="Bill Type"
                    @change="updateBilling"
                    dense
                    attach
                />
            </v-col>
            <v-col
                cols="12"
                v-if="
                    order.incremental.billing_details &&
                    order.incremental.billing_details.auth_code &&
                    order.incremental.billing_method !== 'split'
                "
            >
                <p v-html="'Auth Code: ' + order.incremental.billing_details.auth_code" />
            </v-col>
            <v-col v-if="order.incremental.billing_method === 'split'">
                <v-list color="transparent" flat>
                    <v-list-item>
                        <v-list-item-title>Splits</v-list-item-title>
                        <v-list-item-action>
                            <a-btn-menu>
                                <v-list-item @click="$refs.splitpayment.open(order.incremental.billing_details)">
                                    <v-icon class="mr-3" small>$vuetify.icons.edit</v-icon>

                                    <v-list-item-subtitle>Edit Split</v-list-item-subtitle>
                                </v-list-item>
                            </a-btn-menu>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                    <v-list-item v-for="(split, index) in order.incremental.billing_details.splits" :key="index">
                        <v-list-item-title>
                            {{
                                paymentTypes.find((elem) => {
                                    return elem.id === split.method
                                }).text
                            }}
                        </v-list-item-title>
                        <v-list-item-action>{{ $ascent.currency(split.amount) }}</v-list-item-action>
                    </v-list-item>
                </v-list>
                <p v-if="order.incremental.billing_details.note" class="text-subtitle-2">Splits Notes:</p>
                <v-clamp
                    autoresize
                    :max-lines="2"
                    ellipsis="..."
                    style="padding: 5px"
                    v-if="order.incremental.billing_details.note"
                    v-html="order.incremental.billing_details.note"
                >
                    <v-btn
                        v-if="clamped || expanded"
                        slot="after"
                        slot-scope="{ toggle, clamped, expanded }"
                        x-small
                        outlined
                        color="primary"
                        @click="toggle"
                    >
                        <span v-if="clamped">Expand</span>
                        <span v-if="expanded">Collapse</span>
                    </v-btn>
                </v-clamp>
            </v-col>
            <add-credit-card ref="addcard" :show="false" />
            <charge-company-card
                ref="companycard"
                :id="order.id"
                :total="order.incremental.total.total"
                :due="$orderHelper.due.value"
                :show="false"
            />
            <split-payment ref="splitpayment" />
        </v-row>
    </v-container>
</template>

<script>
import { paymentTypes } from '@/plugins/Data'
import VClamp from 'vue-clamp'
import AddCreditCard from '@/components/OrderScreens/Amc/AMCActions/Payment/AddCreditCard.vue'
import SplitPayment from '@/components/OrderScreens/Amc/AMCActions/Payment/SplitPayment.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { computed } from '@vue/composition-api'
import ChargeCompanyCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeCompanyCard'

export default {
    components: {
        ChargeCompanyCard,
        VClamp,
        AddCreditCard,
        SplitPayment,
    },
    setup() {
        const defaultItems = [
            { value: 'borrower-paid', text: 'Borrower', disabled: true },
            { value: 'net-30', text: 'Bill To', disabled: true },
            { value: 'credit-card', text: 'Credit Card', disabled: true },
            { value: 'split', text: 'Split', disabled: true },
            ...(lenderOptions.value &&
            lenderOptions.value.billing &&
            lenderOptions.value.billing.some((option) => option === 'cod')
                ? [{ value: 'cod', text: 'COD', disabled: false }]
                : []),
        ]

        const checkIntegration = () => {
            if (order.value.source === 'mercury') {
                defaultItems.push({ value: 'mercury-card', text: 'Mercury Card', disabled: false })
                defaultItems.push({ value: 'deferred-card', text: 'Deferred - Waiting on Borrower', disabled: false })
            }
            if (order.value.source === 'valuepad')
                defaultItems.push({ value: 'valuepad-card', text: 'Valuepad Card', disabled: false })
        }

        const items = computed(() => {
            checkIntegration()
            const net30 = ['net-30', 'invoice_with_auth', 'invoice_with_permission', 'final_inspection', 'branch']
            const card = ['card_up_front', 'credit_card', 'company_card']
            const billingOptions = lenderOptions.value.billing
            if (!billingOptions) return defaultItems
            billingOptions.forEach((option) => {
                defaultItems.map((elem) => {
                    if (option === elem.value) elem.disabled = false
                    if (option === 'borrower' && elem.value.includes('borrower')) elem.disabled = false
                    if (net30.findIndex((ele) => ele === option) > -1 && elem.value === 'net-30') elem.disabled = false
                    if (card.findIndex((elem) => elem === option) > -1 && elem.value === 'credit-card')
                        elem.disabled = false
                    if (order.value?.incremental?.billing_method === 'split' && elem.value !== 'split') {
                        defaultItems.map((el) => {
                            if (el.value === 'split') el.disabled = false
                        })
                    }
                })
            })

            return defaultItems
        })

        return {
            paymentTypes,
            order,
            items,
        }
    },
    data() {
        return {}
    },
    methods: {
        updateBilling(value) {
            if (value === 'credit-card') {
                this.$refs.addcard.open()
                return
            }
            if (value === 'split') {
                this.$refs.splitpayment.open()
                return
            }
            this.saveBilling(value)
        },
        saveBilling(value) {
            if (!order.value) return
            this.$axios
                .patch('/v1/order/' + order.value.id + '/action/update-billing', { billing_method: value })
                .then(() => {
                    if (!order.value?.incremental) return
                    this.$set(order.value, 'incremental.billing_method', value)
                    order.value.incremental.billing_method = value
                    order.value.billingMethod = value
                    showSnackbar('Billing Type Changed Successfully')
                    this.edit = !this.edit
                })
        },
    },
}
</script>
