














import { defineComponent, PropType } from '@vue/composition-api'
import { openAssignPanelNotesDialog } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignPanelNotesDialog.vue'
import ExpandedAppraiserDetails from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/ExpandedAppraiserDetails.vue'
import ExpandedAppraiserStatusDetails from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ExpandedDetailsPartials/ExpandedAppraiserStatusDetails.vue'
import { headers } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/AssignTable.vue'
import OfficeExpandedAssignAppraiser from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/AssignTablePartials/OfficeExpandedAssignAppraiser.vue'

import { AppraiserPanel } from '@/types'

export default defineComponent({
    components: {
        ExpandedAppraiserDetails,
        ExpandedAppraiserStatusDetails,
        OfficeExpandedAssignAppraiser,
    },
    props: {
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
        details: {
            type: Object,
        },
    },
    setup() {
        return {
            openAssignPanelNotesDialog,
            headers,
        }
    },
})
