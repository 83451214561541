import { ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import axios from '@/plugins/axios'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import { Entity } from '@/types'

export const entities = ref<Entity[]>([])

export const getEntities = () => {
    entities.value = []

    if (!order.value) {
        return
    }

    axios
        .get('/v1/order/' + order.value.id + '/entities')
        .then((response) => {
            entities.value = response.data
        })
        .catch(() => {
            showError('Unable to load order entities.')
        })
}
