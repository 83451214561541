









































import { defineComponent, ref, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import ACardAction from '@/global/ACardAction.vue'
import AEditor from '@/global/Form/AEditor.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { loadIncremental, order } from '@/plugins/order/Order'

export default defineComponent({
    components: { AEditor, ACardAction, ValidationObserver },
    setup() {
        const instructions = ref(order.value?.incremental?.instruction?.instructions || '')
        const internalInstructions = ref(order.value?.incremental?.internal_instruction?.instructions || '')
        const payload = computed(() => ({
            order: {
                instruction: {
                    instructions: instructions.value,
                },
                internalInstruction: {
                    instructions: internalInstructions.value,
                },
            },
        }))

        const updateInstructions = () => {
            showSnackbar('Order Instructions Successfully Updated')
            loadIncremental()
        }

        return {
            instructions,
            internalInstructions,
            payload,
            updateInstructions,
            order,
        }
    },
})
