




















import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
        return {}
    },
    components: {
        ValidationProvider,
    },
    props: {
        rules: {
            type: [Object, String],
            default: '',
        },
        value: {
            type: null,
        },
        items: {
            type: [Array],
            default: () => {
                return []
            },
            required: true,
        },
    },
})
