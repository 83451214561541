











import { defineComponent, PropType, ref } from '@vue/composition-api'
import IntegrationWithCompanyID from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/IntegrationWithCompanyID.vue'
import { LenderOptions } from '@/types'
import ReggoraLookup from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/ReggoraLookup.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ReggoraOption } from '@/types'

export default defineComponent({
    components: { IntegrationWithCompanyID, ReggoraLookup },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = props.option
        const reggoraOption = props.option?.option_value as ReggoraOption
        const loading = ref(false)

        const pullReggoraBranches = () => {
            confirm(
                'Pull Reggora Branches?',
                'Are you sure you want to pull branches from Reggora? This will only add branches to Jaro that do not exist yet, never delete branches.',
            ).then((confirm) => {
                if (confirm) {
                    loading.value = true
                    $axios
                        .post('/v1/integration/reggora/action/pull-branches', {
                            reggora_lender_id: reggoraOption.company,
                        })
                        .then((response) => {
                            showSnackbar(response.data.message)
                            loading.value = false
                        })
                        .catch((response) => {
                            showError(response.message)
                            loading.value = false
                        })
                }
            })
        }

        return {
            optionLocal,
            pullReggoraBranches,
            loading,
        }
    },
})
