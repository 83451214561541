












import states, { stateValues } from '@/static/states'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
    props: {
        all: Boolean,
        abbreviated: Boolean,
    },
    setup() {
        const stateOptions = ref(states as { text: string; value: string }[] | string[])
        return {
            stateOptions,
        }
    },

    created() {
        if (this.all) {
            this.stateOptions = ['All States'].concat(states)
        }
        if (this.abbreviated) {
            this.stateOptions = stateValues.map((state) => {
                return {
                    text: state.label,
                    value: state.value,
                }
            })
        }
    },
})
