




































import { computed, defineComponent } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    components: {},
    setup() {
        const appraiserDuplicates = computed(() => {
            if (!order.value?.incremental) return []
            if (!order.value.incremental?.duplicates) return []
            return order.value.incremental.duplicates.filter((elem) => {
                if (!elem.duplicate_cache || !user.value) return
                if (elem?.duplicate_cache?.customer_id === user.value.customer_id) {
                    return (
                        AscentHelper.featureEnabled('appraiserAccessType') != false &&
                        AscentHelper.featureEnabled('appraiserAccessType') == 'full'
                    )
                }

                return elem?.duplicate_cache?.appraiser_id === user.value.appraiser?.id
            })
        })
        return {
            order,
            user,
            appraiserDuplicates,
        }
    },
})
