













































































































































































































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import AEditor from '@/global/Form/AEditor.vue'
import { Contact } from '@/types'
import PreloadAddBorrowerDialog, {
    dialog,
} from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadSubjectInfo/PreloadAddBorrowerDialog.vue'

export default defineComponent({
    components: {
        AEditor,
        PreloadAddBorrowerDialog,
    },
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const borrower = ref([] as Contact[])
        const ownerName = ref('')
        const ownerOccupied = ref(false)
        const parcelNumber = ref('')
        const taxYear = ref('')
        const reTaxes = ref('')
        const taxLegalDescription = ref('')
        const neighborhoodName = ref('')
        const propertyRights = ref('')
        const propertyRightsDetails = ref('')
        const pud = ref('')

        watch(props, () => {
            if (propertyRights.value !== 'Other') propertyRightsDetails.value = ''
            if (!props.editData && !props.cancelEdit) {
                preloadData.value.primary.borrowers = borrower.value
                preloadData.value.publicRecord.ownerName = ownerName.value
                preloadData.value.publicRecord.ownerOccupied = ownerOccupied.value
                preloadData.value.publicRecord.taxLegalDescription = taxLegalDescription.value
                preloadData.value.primary.parcelNumber = parcelNumber.value
                preloadData.value.primary.taxYear = taxYear.value
                preloadData.value.primary.reTaxes = reTaxes.value
                preloadData.value.primary.neighborhoodName = neighborhoodName.value
                preloadData.value.primary.propertyRights = propertyRights.value
                preloadData.value.primary.propertyRightsDetails = propertyRightsDetails.value
                preloadData.value.primary.pud = pud.value
            }
            if (props.editData) {
                borrower.value = preloadData.value.primary.borrowers
                neighborhoodName.value = preloadData.value.primary.neighborhoodName
                parcelNumber.value = preloadData.value.primary.parcelNumber
                taxYear.value = preloadData.value.primary.taxYear
                reTaxes.value = preloadData.value.primary.reTaxes
                propertyRights.value = preloadData.value.primary.propertyRights
                propertyRightsDetails.value = preloadData.value.primary.propertyRightsDetails
                pud.value = preloadData.value.primary.pud
                ownerName.value = preloadData.value.publicRecord.ownerName
                ownerOccupied.value = preloadData.value.publicRecord.ownerOccupied
                taxLegalDescription.value = preloadData.value.publicRecord.taxLegalDescription
            }
        })

        return {
            preloadData,
            borrower,
            ownerName,
            ownerOccupied,
            parcelNumber,
            taxYear,
            reTaxes,
            neighborhoodName,
            propertyRights,
            propertyRightsDetails,
            pud,
            taxLegalDescription,
            dialog,
        }
    },
})
