


















































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import ACardAction from '@/global/ACardAction.vue'
import AEditor from '@/global/Form/AEditor.vue'

const dialog = ref(false)
const instructions = ref('')
const amcInstructions = ref('')

import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { loadIncremental, order } from '@/plugins/order/Order'

export const openEditInstructions = (): void => {
    if (!order.value) return
    if (!order.value.incremental) return
    dialog.value = true

    if (order.value?.incremental?.instruction) instructions.value = order.value.incremental.instruction.instructions
    if (order.value?.incremental?.internal_instruction)
        amcInstructions.value = order.value.incremental.internal_instruction.instructions
}

/**
 * @name EditOrderInstructionsDialog
 * Dialog in the order screen to edit the internal and normal instructions on an order
 *
 * @SetupData instructions - universal instructions that client, amc, and appraisers will see
 * @SetupData amcInstructions - internal instructions specifically for amc users on an order
 * @SetupMethod openEditInstructions - opens dialog and sets the instructions and amcInstructions on the order
 * @SetupMethod closeEditDialog - closes and resets dialog
 * @SetupMethod updateInstructions - saves the instructions and refreshes order
 */

export default defineComponent({
    components: { AEditor, ACardAction, ValidationObserver },
    setup() {
        const closeEditDialog = () => {
            instructions.value = ''
            amcInstructions.value = ''
            dialog.value = false
        }

        const updateInstructions = () => {
            showSnackbar('Order Instructions Successfully Updated')
            loadIncremental()
            closeEditDialog()
        }

        return {
            dialog,
            instructions,
            amcInstructions,
            updateInstructions,
            order,
            closeEditDialog,
        }
    },
})
