




























import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { favoriteTeams as currentFavorites, user } from '@/plugins/User'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { teams } from '@/plugins/Amc'

const dialog = ref(false)
const favoriteTeams = ref([])

export const openFavoriteTeams = () => {
    dialog.value = true
    favoriteTeams.value = JSON.parse(JSON.stringify(currentFavorites.value))
}

/**
 * @name 'Favorite Teams Dialog'
 * Component description: Dialog to set the Favorite Teams.
 * @SetupMethod submit - Will save the updated favorite teams values.
 * @SetupMethod openFavoriteTeams: Will open the dialog and get the current favorite teams.
 * @SetupData loading: boolean if the data has loaded.
 * @SetupData favoriteTeams: an array of all the favorite teams.
 * @SetupData teams: an array of all the teams.
 */
export default defineComponent({
    setup() {
        const submit = (): void => {
            $axios
                .patch('/v1/user/' + user.value?.id + '/favorite-teams', {
                    favorites: favoriteTeams.value,
                })
                .then(() => {
                    dialog.value = false
                    currentFavorites.value = JSON.parse(JSON.stringify(favoriteTeams.value))
                    showSnackbar('Favorite Teams Updated Successfully')
                })
        }

        return {
            favoriteTeams,
            dialog,
            teams,
            submit,
        }
    },
})
