




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'OrderDetailListItem',
    props: {
        icon: String,
        tooltip: String,
        text: String,
        titleClass: String,
    },
})
