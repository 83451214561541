import { render, staticRenderFns } from "./OrderTags.vue?vue&type=template&id=f2bcff7e&scoped=true&"
import script from "./OrderTags.vue?vue&type=script&lang=ts&"
export * from "./OrderTags.vue?vue&type=script&lang=ts&"
import style0 from "./OrderTags.vue?vue&type=style&index=0&id=f2bcff7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2bcff7e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VChip,VIcon,VList,VListItem,VListItemContent,VMenu,VSheet,VSlideGroup,VSlideItem})
