





























import LenderOptionJaroPanelPriority from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderAppraiserPanel/LenderAppraiserPanelPartials/LenderOptionJaroPanelPriority.vue'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import { Task } from '@/types'
import LenderOptionTraineeAppraisers from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderAppraiserPanel/LenderAppraiserPanelPartials/LenderOptionTraineeAppraisers.vue'
import LenderInvestors from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderAppraiserPanel/LenderAppraiserPanelPartials/LenderInvestors.vue'
import LenderOptionRestrictions from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderAppraiserPanel/LenderAppraiserPanelPartials/LenderOptionRestrictions.vue'
import LenderOptionPreventLicensed from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderAppraiserPanel/LenderAppraiserPanelPartials/LenderOptionPreventLicensed.vue'
import LenderAssignmentDirectives from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderAppraiserPanel/LenderAppraiserPanelPartials/LenderAssignmentDirectives.vue'
export default defineComponent({
    components: {
        LenderAssignmentDirectives,
        LenderInvestors,
        LenderOptionRestrictions,
        LenderOptionPreventLicensed,
        LenderOnboardingStatus,
        LenderOptionTraineeAppraisers,
        LenderOptionJaroPanelPriority,
    },
    setup(_, { emit }) {
        const task = ref<Task>({ id: 0, status: '', user_id: 0 })
        const loaded = ref<boolean>(false)

        const options = ref({
            restrictions: undefined,
            'prevent-licensed': undefined,
            'trainee-appraisers': undefined,
            'jaro-panel-priority': undefined,
        })

        const updateStatus = (status: Event) => {
            emit('updateStatus', status)
        }

        const getTasks = () => {
            loaded.value = false
            axios.get(`/v1/lender/${lender.value.id}/action/get-panel-settings`).then((res) => {
                task.value = res.data.task
                options.value = { ...options.value, ...res.data.options }
                loaded.value = true
            })
        }
        onMounted(getTasks)

        return {
            updateStatus,
            options,
            loaded,
            task,
        }
    },
})
