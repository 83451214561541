














import { defineComponent, nextTick, ref } from '@vue/composition-api'
import ViewClientPopover from '@/components/OrderScreens/Customer/CustomerOrderComponents/PopoverComponents/ViewClientPopover.vue'

const showMenu = ref(false)
const x = ref(0)
const y = ref(0)
const component = ref('')

interface MenuItem {
    title: string
    disabled?: boolean
}

export const showPopover = (comp: string, e: MouseEvent) => {
    showMenu.value = false
    x.value = e.clientX + 50
    y.value = e.clientY
    component.value = comp
    nextTick(() => {
        showMenu.value = true
    })
}

export default defineComponent({
    name: 'DetailPopoverComponent',
    components: {
        ViewClientPopover,
    },
    setup() {
        return {
            showMenu,
            x,
            y,
            component,
        }
    },
})
