













































































































































import { computed, defineComponent, watch, PropType, onMounted } from '@vue/composition-api'
import { ProductFee, Product } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'
import { AscentHelperType } from '@/plugins/ascent-helper'

export interface FeeUpdateInput {
    val: string
    column: string
    item: Product
}

/**
 * @name OrderFeeTable
 * Fee table component on the order screen
 *
 * @SetupData headers - headers on the table
 * @SetupComputed amcTotal - takes the products on the props and gets the amc total from those products
 * @SetupComputed appraiserTotal - takes products from props and gets the appraiser total
 * @SetupComputed getFees - emits 'get-fees' with product
 * @SetupComputed total - total of amc fee and appraiser fee
 * @SetupMethod getItems - takes in value, and gets products
 * @SetupMethod updateInput - takes in value, product, and column, and emits to parent component
 * @SetupMethod deleteFee - takes in product and emits product to parent component
 *
 */

export default defineComponent({
    props: {
        products: {
            type: [] as PropType<Product[]>,
            default: () => [],
        },
        appraisersFees: {
            type: [] as PropType<Product[]>,
            default: () => [],
        },
        fees: {
            type: [] as PropType<ProductFee>,
            default: () => [],
        },
        error: {
            type: String,
            default: '',
        },
        userType: {
            type: String,
            default: 'amc',
        },
    },
    setup(props, { emit }) {
        const isSplitFees =
            (AscentHelper as AscentHelperType).customerFeatureEnabled('orderScreen.invoice.feeSummaryType') ===
            'split_fees'

        const productsFormatted = computed(() => {
            const items = [...props.products, ...props.appraisersFees]
            const hasExpenses = items.some((p) => p.expense)

            return [
                ...(hasExpenses ? [{ separator: true, title: 'Revenue' }] : []),
                ...items.filter((p) => !p.expense),
                ...(hasExpenses ? [{ separator: true, title: 'Expenses' }] : []),
                ...items.filter((p) => p.expense),
            ]
        })

        const headers = computed(() => {
            const singleFee = [
                { text: 'Name', value: 'form.name', sortable: false },
                { text: 'Type', value: 'form.type', sortable: false },
                { text: 'Fee', value: 'line_total', sortable: false },
                { text: '', value: 'action', align: 'center', sortable: false },
            ]

            const splitFees = [
                { text: 'Name', value: 'form.name', sortable: false },
                { text: 'Type', value: 'form.type', sortable: false },
                { text: 'AMC Fee', value: 'amc_value', sortable: false },
                { text: 'Appr Fee', value: 'appraiser_value', sortable: false },
                { text: 'Client Fee', value: 'line_total', sortable: false },
                { text: '', value: 'action', align: 'center', sortable: false },
            ]

            return isSplitFees ? splitFees : singleFee
        })

        const amcTotal = computed(() => {
            if (props.products.length === 0) return 0
            const quoted = props.products.find((elem) => {
                return elem.quote
            })

            if (typeof quoted != 'undefined') return 0

            return props.products.reduce((accumulator, currentValue) => {
                if (currentValue.amc_value === null) return accumulator + 0
                return accumulator + parseFloat(currentValue.amc_value as string)
            }, 0)
        })

        const appraiserTotal = computed(() => {
            if (props.products.length === 0) return 0

            const quoted = props.products.find((elem) => elem.quote)

            if (typeof quoted != 'undefined') return 0

            return props.products.reduce((accumulator, currentValue) => {
                if (currentValue.appraiser_value == null) return accumulator

                return accumulator + parseFloat(currentValue.appraiser_value as string)
            }, 0)
        })

        const getFees = (item: Product) => {
            emit('get-fees', item)
        }

        const total = computed(() => amcTotal.value + appraiserTotal.value)

        const getItems = (val: string) => (val === 'Main Product' ? props.fees.main : props.fees.loans)

        const updateInput = (val: string, item: Product, column: string) => {
            if (
                (AscentHelper as AscentHelperType).customerFeatureEnabled('orderScreen.invoice.feeSummaryType') ===
                'total_fees'
            ) {
                item.appraiser_value = item.line_total
                item.amc_value = 0
            }
            emit('update-input', { val, item, column })
        }

        const deleteFee = (item: Product) => {
            emit('delete-fee', item)
        }

        watch(total, () => {
            emit('total-updated', total.value)
        })

        onMounted(() => {
            emit('total-updated', total.value)
        })

        return {
            isSplitFees,
            headers,
            getItems,
            updateInput,
            deleteFee,
            amcTotal,
            appraiserTotal,
            total,
            getFees,
            productsFormatted,
        }
    },
})
