









































import { defineComponent, ref } from '@vue/composition-api'
import AEditor from '@/global/Form/AEditor.vue'
import { ValidationObserver } from 'vee-validate'
import ACardAction from '@/global/ACardAction.vue'
import { appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

const dialog = ref(false)

export const openTerminateHoldDialog = () => {
    dialog.value = true
}

/**
 * @name TerminateHoldAppraiserDialog
 * Dialog to put appraiser on hold or to terminate the appraiser
 *
 * @SetupMethod openTerminatedHoldDialog - opens up the dialog
 * @SetupData statuses - array of statuses for the amc to choose when terminating/putting the appraiser on hold
 * @SetupData reason - reason for putting appraiser on hold
 *
 * @SetupMethod statusUpdated - called after appraiser is saved. Closes dialog and emits to parent component that appraiser status changed
 */

export default defineComponent({
    components: { ACardAction, AEditor, ValidationObserver },
    setup(_, { emit }) {
        const statuses = ['Hold', 'Terminated']

        const reason = ref('')
        const form = ref({} as InstanceType<typeof ValidationObserver>)

        const statusUpdated = () => {
            showSnackbar('Status updated successfully!')
            dialog.value = false
            reason.value = ''
            emit('appraiser-status-saved')
        }

        return {
            dialog,
            statuses,
            reason,
            appraiserOptions,
            form,
            statusUpdated,
        }
    },
})
