






















import { defineComponent, onMounted } from '@vue/composition-api'
import JaroAssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroAssignPanels.vue'
import {
    jaroPanels,
    getValidJaroPanels,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'
import { order } from '@/plugins/order/Order'
import { openAssign } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import JaroAssignPanelsVendorAssignedPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsVendorAssignedPartial.vue'
import { quotesArray, getAllQuotes } from '@/pages/AMC/Quotes/Quotes.ts'
import JaroAssignPanelsHasPendingQuotesAlertPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsHasPendingQuotesAlertPartial.vue'

export default defineComponent({
    components: {
        JaroAssignPanels,
        JaroAssignPanelsVendorAssignedPartial,
        JaroAssignPanelsHasPendingQuotesAlertPartial,
    },
    setup() {
        onMounted(async () => {
            if (!order.value) return
            await getValidJaroPanels({ order_id: order.value.id, customer_id: order.value.customer_id })
            await getAllQuotes('order', order.value.id)
        })

        return {
            jaroPanels,
            order,
            openAssign,
            quotesArray,
        }
    },
})
