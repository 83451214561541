<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" outlined block small @click="open" v-on="on">Charge Valuepad Card</v-btn>
        </template>
        <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-card>
                <v-card-title>
                    Charge Card
                    <v-spacer />
                    <v-btn icon color="primary" @click="dialog = false">
                        <v-icon>$vuetify.icons.times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="error == null">
                        <v-col cols="12">
                            <h3>
                                ${{ due }}
                                <span class="small">Due</span>
                            </h3>
                            <p>Are you sure you want to charge card on file with Mercury?</p>
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                v-model="amount"
                                name="amount"
                                label="Amount"
                                :rules="'required|max_value:' + total"
                                prepend-icon="$vuetify.icons.dollarSign"
                                autofocus
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="error != null" wrap>
                        <v-col cols="12">
                            <v-alert text dense type="error" icon="$vuetify.icons.exclamation-triangle">
                                {{ error }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" depressed :loading="charging" :disabled="invalid" @click="chargeCard">
                        Charge Card
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { refreshOrder } from '@/plugins/order/Order'

export default Vue.extend({
    components: {
        ValidationObserver,
    },
    props: {
        id: Number,
        due: Number,
        total: Number,
    },
    data() {
        return {
            dialog: false,
            charging: false,
            error: null,
            amount: null,
        }
    },
    computed: {},
    methods: {
        open() {
            this.amount = this.due
            this.dialog = true
        },
        chargeCard() {
            this.charging = true
            this.$axios
                .post('/v1/order/' + this.id + '/payment/valuepad/charge', {
                    amount: this.amount,
                })
                .then((response) => {
                    if (response.data.result) {
                        this.dialog = false
                        showSnackbar(
                            'Card Charged Successfully for ' +
                                this.amount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }),
                        )
                        refreshOrder()
                        this.charging = false
                        this.error = null
                        this.$emit('charged')
                    } else {
                        this.error = response.data.message
                        this.charging = false
                    }
                })
                .catch(() => {
                    this.charging = false
                })
        },
    },
})
</script>
