





























































































































import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import axios from 'axios'
import { IronwoodTokenizerResponseDetails, IronwoodResponse, IronwoodValidCardDetails } from '@/types/creditCard'
import { stateValues } from '@/static/states'

interface LocalAddress {
    street: string
    city: string
    state: {
        label: string
        value: string
    }
    zip: string
}

interface Styling {
    color?: string
    'border-radius'?: string
    'background-color'?: string
    border?: string
    padding?: string
}

//Ironwood Tokenizer
declare class Tokenizer {
    constructor(options: {
        apikey: string
        url?: string
        amount?: string | number
        container?: string | HTMLElement
        settings?: {
            id?: string
            apikey?: string
            amount?: string
            user?: {
                showName?: boolean
                showInline?: boolean
            }
            payment?: {
                calculateFees?: boolean
            }
            styles?: {
                body?: Styling
                input?: Styling
            }
            billing?: {
                show?: boolean
                showTitle?: boolean
            }
        }
        onLoad?: () => void
        validCard?: (card: IronwoodValidCardDetails) => void
        achOnChange?: (ach: unknown) => void
        magStripeSwipe?: (magStripe: unknown) => void
        onPaymentChange?: (type: string) => void
        submission?: (response: IronwoodTokenizerResponseDetails) => void
    })

    create(): void
    isSurchargeable(state: string, card: IronwoodValidCardDetails['bin']): boolean
    submit(amount?: string): void
    setExpDate(date: string): void
    updateHeight(height: string): void
}

export default defineComponent({
    name: 'Ironwood',
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        help: {
            type: Boolean,
            default: true,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        clearFields: {
            type: Boolean,
            default: true,
        },
        customerId: {
            type: Number,
            default: null,
        },
        chargeAmount: {
            type: Number,
            default: 0,
        },
        usesSurcharge: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const loading = ref(false)
        const error = ref(false)
        const errorMessage = ref('')
        const tokenizer = ref({} as Tokenizer)
        const randomHex = ref('')
        const pubKey = ref('')
        const cardBin = ref({} as IronwoodValidCardDetails)
        const surcharge = ref({ enabled: false, amount: 0 })
        const surchargeAgree = ref(false)
        const address = ref({} as LocalAddress)
        const cardholderName = ref('')

        const reset = () => {
            loading.value = false
            error.value = false
            address.value = {
                street: '',
                city: '',
                state: { label: '', value: '' },
                zip: '',
            }
            errorMessage.value = ''
        }

        const cancel = () => {
            loading.value = false
            if (props.clearFields) {
                reset()
            }
        }

        const injectScript = (source: string, body?: string) => {
            return new Promise(function (resolve) {
                const scriptTag = document.createElement('script')

                scriptTag.type = 'text/javascript'
                scriptTag.async = true
                if (source !== '') {
                    scriptTag.src = source
                }

                if (body) {
                    const scriptTagContents = document.createTextNode(body)
                    scriptTag.appendChild(scriptTagContents)
                }

                document.head.appendChild(scriptTag)

                scriptTag.addEventListener('load', resolve)
            })
        }

        const getPublicKey = async () => {
            const response = await axios.get('/v1/card/tokens', {
                params: { type: 'ironwood', customer_id: props.customerId },
            })
            pubKey.value = response.data.key ?? ''
        }

        const checkIfSurchargable = () => {
            surcharge.value = { enabled: false, amount: 0 }
            emit('handle-surcharge', surcharge.value)

            if (!props.usesSurcharge) return

            if (!tokenizer.value.isSurchargeable(address.value.state.value, cardBin.value.bin)) {
                return
            }

            if (cardBin.value.fees.surcharge != null && cardBin.value.fees.surcharge !== 0) {
                surcharge.value = {
                    enabled: true,
                    amount: cardBin.value.fees.surcharge,
                }
            }
            emit('handle-surcharge', surcharge.value)
        }

        const addCard = async () => {
            if (surcharge.value.enabled && !surchargeAgree.value) {
                emit('error', {
                    result: false,
                    error: true,
                    message:
                        'To use this card you must agree to the additional surcharge amount. Please check the box and try again.',
                })
                return
            }
            tokenizer.value.submit()
        }

        const submit = (response: IronwoodTokenizerResponseDetails) => {
            if (!response.token) {
                emit('error', {
                    error: true,
                    result: false,
                    message:
                        'There was an issue with the credit card submission. No charge has been attempted. Please refresh and try again.',
                })
                return
            }
            const token = response.token
            const name = cardholderName.value
            loading.value = true

            axios
                .post('/v1/card/tokens/ironwood', {
                    token: token,
                    name: name,
                    customer_id: props.customerId,
                    address: address.value,
                })
                .then((response) => {
                    if (!response || !response.data) {
                        emit('error', {
                            result: false,
                            error: true,
                            message:
                                'There was an error processing the submitted information. Your card has not been charged, please refresh the page and try again.',
                        })
                        return
                    }

                    if (response.data.result != undefined && !response.data.result) {
                        error.value = true
                        errorMessage.value = response.data.message
                        emit('error', {
                            result: response.data.result,
                            error: response.data.error,
                            message: response.data.message,
                        })
                        return
                    }
                    const data = response.data as IronwoodResponse
                    emit('card-added', {
                        card: data.lastFour,
                        token: data.customerId + '|' + data.customerPaymentId,
                        type: 'ironwood',
                        name: name,
                        expYear: '20' + data.expirationDate.split('/')[1],
                        expMonth: data.expirationDate.split('/')[0],
                    })
                })
                .catch((error) => {
                    emit('error', {
                        result: false,
                        error: true,
                        message: error,
                    })
                })

            if (props.clearFields) {
                reset()
            }
            loading.value = false
        }

        const initialize = () => {
            reset()
            getPublicKey().then(() => {
                randomHex.value = 'container-' + (Math.random() + 1).toString(36).substring(7)
                const url =
                    process.env.VUE_APP_ENV === 'production'
                        ? 'https://app.goironpay.com/tokenizer/tokenizer.js'
                        : 'https://sandbox.goironpay.com/tokenizer/tokenizer.js'

                injectScript(url).then(() => {
                    tokenizer.value = new Tokenizer({
                        apikey: pubKey.value,
                        container: `#${randomHex.value}`,
                        amount: props.chargeAmount * 100,
                        submission: (response: IronwoodTokenizerResponseDetails) => {
                            switch (response.status) {
                                case 'success':
                                    submit(response)
                                    break
                                case 'error':
                                    emit('error', {
                                        error: true,
                                        result: false,
                                        message: response.message,
                                    })
                                    break
                                case 'validation':
                                    emit('error', {
                                        error: true,
                                        result: false,
                                        message: response.invalid,
                                    })
                                    break
                            }
                        },
                        validCard: (card) => {
                            cardBin.value = card
                            checkIfSurchargable()
                        },
                        settings: {
                            payment: {
                                calculateFees: props.usesSurcharge,
                            },
                            styles: {},
                        },
                    })
                })
            })
        }

        watch(props, () => {
            if (props.customerId) {
                initialize()
            }
        })

        onMounted(() => {
            if (!props.customerId) return
            initialize()
        })

        return {
            loading,
            error,
            addCard,
            randomHex,
            cancel,
            surcharge,
            surchargeAgree,
            cardBin,
            stateValues,
            address,
            checkIfSurchargable,
            cardholderName,
            errorMessage,
            pubKey,
        }
    },
})
