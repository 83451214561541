










import { defineComponent, ref } from '@vue/composition-api'
import { closedPanel } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'

export default defineComponent({
    setup() {
        const overlay = ref(true)

        overlay.value = closedPanel.value

        return {
            overlay,
        }
    },
})
