










































import { defineComponent, PropType, ref } from '@vue/composition-api'
import axios from 'axios'
import { DateTime } from 'luxon'
import { open } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderReminders/AddReminderDialog.vue'
import { Reminder } from '@/types'
import Vue from 'vue/types/umd'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order } from '@/plugins/order/Order'
import { updateOrders } from '@/components/OrderTable/Classes/OrderTable'

interface Dialog {
    open: () => void
    close: () => void
}

export const snoozeReminder = (reminder: Reminder, value: string) => {
    let reminder_date = ''

    switch (value) {
        case 'Add 30 minutes':
            reminder_date = DateTime.fromISO(DateTime.now(), { zone: 'UTC' }).plus({ minutes: 30 }).toISO()
            break
        case 'Add 1 hour':
            reminder_date = DateTime.fromISO(DateTime.now(), { zone: 'UTC' }).plus({ hours: 1 }).toISO()
            break
        case 'Add 2 hours':
            reminder_date = DateTime.fromISO(DateTime.now(), { zone: 'UTC' }).plus({ hours: 2 }).toISO()
            break
        case 'Tomorrow @ 9AM':
            reminder_date = DateTime.fromISO(DateTime.now().startOf('day'), { zone: 'UTC' }).plus({ days: 1, hours: 9 })
            break
    }

    return new Promise<void>((resolve) => {
        axios.patch('/v1/reminder/' + reminder.id, { reminder_date }).then((response) => {
            if (!order.value || !order.value?.incremental) {
                updateOrders()
                showSnackbar(`Reminder Snoozed: ${value}`)
                return
            }

            order.value.incremental.reminders = order.value?.incremental.reminders.map((item: Reminder) => {
                if (reminder.id === item.id) {
                    return response.data.reminder
                }
                return item
            })

            showSnackbar(`Reminder Snoozed: ${value}`)
            resolve()
        })
    })
}

/**
 * @name ReminderActions
 * Actions for the reminder side component
 *
 * @SetupData menu - turns a-btn menu on and off
 * @SetupData originalReminder - the original reminder
 *
 * @SetupMethod removeReminder - takes in id, index, and reminder. And removes that reminder from the order. Refreshes the orders after.
 * @SetupMethod snoozeReminder - allows user to chose a different time to snooze the reminder to
 */

export default defineComponent({
    props: {
        /**
         * Specific reminder
         */
        reminder: {
            type: Object as PropType<Reminder>,
        },
        /**
         * Index of reminder
         */
        index: {
            type: Number,
        },
    },
    setup(props, { refs }) {
        const reminders = ref([] as Reminder[])
        const menu = ref(false)
        let originalReminder = {} as Reminder

        const removeReminder = (id: number, index: number, reminder: Reminder) => {
            originalReminder = reminder
            confirm('Delete Reminder', 'Are you sure you want to delete this reminder?').then((confirm) => {
                if (confirm) {
                    axios.delete('/v1/reminder/' + id, {}).then(() => {
                        if (!order.value || !order.value.incremental) {
                            updateOrders()
                            return showSnackbar('Reminder Deleted Successfully')
                        }
                        order.value?.incremental.reminders.splice(index, 1)

                        originalReminder.note = ''
                        originalReminder.id = undefined
                        originalReminder.reminder_date = 0
                        originalReminder.order_id = undefined
                        originalReminder.notification_id = undefined
                        const closeDialog = refs.AddReminderDialog as Vue & { close: () => void }
                        if (!closeDialog) {
                            return showSnackbar('Reminder Deleted Successfully')
                        }
                        closeDialog.close()
                        showSnackbar('Reminder Deleted Successfully')
                    })
                }
            })
        }

        const AddReminderDialog = ref({} as Dialog)

        return {
            open,
            menu,
            snoozeReminder,
            reminders,
            removeReminder,
            AddReminderDialog,
        }
    },
})
