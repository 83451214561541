




































import { defineComponent, onMounted } from '@vue/composition-api'
import { getLenders, lenderSelection } from '@/components/OrderTable/Classes/TableFilters'
import { viewFilters } from '@/components/OrderTable/Classes/OrderTable'

/**
 * @name LenderSelectFilter
 * Dialog on Order Table that allows user to select different Lenders to see their orders on the Order Table
 *
 */

export default defineComponent({
    setup() {
        const resetSelection = () => {
            viewFilters.value.lender_id = [-1]
        }

        const selectAllWhenNoneSelected = (state: number) => {
            if (viewFilters.value.lender_id.length === 0 && state !== -1) viewFilters.value.lender_id = [-1]
            if (state === -1) viewFilters.value.lender_id = []
        }

        onMounted(() => {
            getLenders()
        })

        return {
            resetSelection,
            selectAllWhenNoneSelected,
            lenderSelection,
            viewFilters,
        }
    },
})
