

































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order, updateOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from 'axios'

const dialog = ref(false)
export const openCancelEscalation = () => {
    dialog.value = true
}

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const escalationReason = ref('')
        const loading = ref(false)

        const cancelEscalation = async () => {
            if (!order.value) return
            loading.value = true
            await $axios.delete(`/v1/order/${order.value.id}/escalate`, {
                data: {
                    note: escalationReason.value,
                },
            })
            showSnackbar('Order escalate cancelled successfully!')
            escalationReason.value = ''
            updateOrder()
            loading.value = false
            dialog.value = false
        }

        return {
            dialog,
            escalationReason,
            order,
            cancelEscalation,
            loading,
        }
    },
})
