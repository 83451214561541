






import { defineComponent, ref } from '@vue/composition-api'
import { File } from '@/types'
import EditFile, { file } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditFile.vue'

export const dialog = ref(false)

export const open = (selectedFile: File): void => {
    file.value = JSON.parse(JSON.stringify(selectedFile))
    dialog.value = true
}

export default defineComponent({
    components: { EditFile },
    setup() {
        return { dialog }
    },
})
