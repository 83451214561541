





































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import ReviewOrder from '@/components/OrderScreens/Workflow/ReviewOrder.vue'
import AssignAppraiser from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignAppraiser.vue'
import AssignOverride from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignOverride.vue'
import { order, filesAndNotesLoaded } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'
import { openOverrideDialogAction } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignAppraiser.vue'
import EditOrderInvoice from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderInvoice.vue'
import JaroAssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroAssignPanels.vue'
import {
    jaroPanels,
    getValidJaroPanels,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'
import JaroAssignPanelsVendorAssignedPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsVendorAssignedPartial.vue'
import { getAllQuotes, quotesArray } from '@/pages/AMC/Quotes/Quotes'
import QuoteTable from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/QuoteTable.vue'

/**
 * @name AssignAction
 * Workflow in AMC Order Screen. User will Review order if order is not reviewed or AssignAppraiser workflow step will be up if order has been reviewed
 */

export default defineComponent({
    setup() {
        onMounted(async () => {
            if (AscentHelper.customerFeatureEnabled('usesJaroPanels', false)) {
                if (!order.value) return
                await getValidJaroPanels({ order_id: order.value.id, customer_id: order.value.customer_id })
                await getAllQuotes('order', order.value.id)
            }
        })

        const reviewTab = ref('review')

        return {
            order,
            filesAndNotesLoaded,
            openOverrideDialogAction,
            jaroPanels,
            quotesArray,
            reviewTab,
        }
    },
    components: {
        EditOrderInvoice,
        ReviewOrder,
        AssignAppraiser,
        AssignOverride,
        JaroAssignPanels,
        JaroAssignPanelsVendorAssignedPartial,
        QuoteTable,
    },
    computed: {
        /**
         * Shows if order has been reviewed or not
         */
        isOrderReviewed() {
            if (!order.value?.incremental) return false
            if (!AscentHelper.featureEnabled('reviewOrder')) return true
            return Boolean(order.value.incremental.reviewed?.value)
        },
        /**
         * Determines if needs override approval
         */
        isOverrideNeeded() {
            if (!order.value) return
            if (!order.value?.incremental) return
            return order.value.incremental.assign_override_approval
        },
        /**
         * Determines if order is in a hold status
         */
        isOrderOnHold() {
            if (!order.value) return
            return order.value.status === 'Hold' || order.value.status === 'Payment Hold'
        },
    },
})
