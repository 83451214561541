



































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { Client } from '@/types'
import { ValidationObserver } from 'vee-validate'
import ACardAction from '@/global/ACardAction.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'

const dialog = ref(false)
export const openSendInvoiceDialog = () => {
    dialog.value = true
}

export default defineComponent({
    components: {
        ACardAction,
        ValidationObserver,
    },
    setup() {
        const clientEmails = ref([] as Client[])
        const option = ref('')

        const form = ref({} as InstanceType<typeof ValidationObserver>)

        const invoiceSent = () => {
            showSnackbar('Invoice Sent Successfully!')
            dialog.value = false
            option.value = ''
            form.value.reset()
            if (!order.value) return
            getNotes(order.value.id)
        }

        onMounted(() => {
            if (!order.value || !order.value?.incremental) return
            if (order.value?.incremental.client !== null && order.value?.incremental.client.email)
                clientEmails.value.push(order.value?.incremental.client)
            if (order.value?.incremental.officer !== null && order.value?.incremental.officer.email)
                clientEmails.value.push(order.value?.incremental.officer)
            order.value?.incremental.participants.map((elem) => {
                if (!elem.participant.name) elem.participant.name = ' '
                if (elem.participant.name && elem.participant.email) clientEmails.value.push(elem.participant as Client)
            })
            clientEmails.value = clientEmails.value.filter(
                (value, index, arr) => index === arr.findIndex((t) => t.id === value.id && t.email === value.email),
            )
        })
        return {
            dialog,
            order,
            clientEmails,
            option,
            form,
            invoiceSent,
        }
    },
})
