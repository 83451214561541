


































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper'
import { newOrder, lenders, orderConfiguration } from '@/pages/Client/NewOrderMethods'
import CreditCard from '@/components/General/CreditCard.vue'
import NewOrderSplitPayment from '@/pages/Client/CreateOrderForm/NewOrderSplitPayment.vue'
import { ValidationObserver } from 'vee-validate'
import { DefaultBillingMethodOption } from '@/types'

interface CardDetails {
    token: string
    card: string
    name: string
    expMonth: string
    expYear: string
}
/**
 * @name 'New Order Payment'
 * Component description: The payment details when creating a new order
 * @SetupData cardAdded() => : Pushes the new card added to the new order - newOrder.billing.cards
 * @SetupComputed billing () => : Sets the billing methods based off lender options for their billing
 * @SetupComputed shouldShowCard () => : checks to see if there should be a card, if there is then it has the option to add a card
 */
export const addCard = ref(true)

export default defineComponent({
    props: {
        mortgageDirector: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const hasCard = ref(false)

        const paymentEmailsRequired =
            props.mortgageDirector && AscentHelper.customerFeatureEnabled('monitoring.CorporateCreditCardApproval')
        const paymentEmailsRules = ['email', ...(paymentEmailsRequired ? ['required'] : [])].join('|')

        const hasBorrowerPayment = computed(() => {
            if (newOrder.value.billing_method === 'borrower-paid') {
                return true
            }

            if (newOrder.value.billing_method === 'split') {
                const borrowerPayment = newOrder.value.billing.splits.find((split) => split.method === 'borrower-paid')
                return typeof borrowerPayment !== 'undefined'
            }

            return false
        })

        watch(
            () => newOrder.value.borrowers,
            (borrowers) => {
                newOrder.value.paymentEmails = borrowers.reduce(
                    (acc, borrower) =>
                        borrower.email &&
                        (!AscentHelper.customerFeatureEnabled('monitoring.CorporateCreditCardApproval') ||
                            (AscentHelper.customerFeatureEnabled('monitoring.CorporateCreditCardApproval') &&
                                acc.length === 0))
                            ? [...acc, borrower.email]
                            : acc,
                    [] as string[],
                )
            },
            { deep: true, immediate: true },
        )

        function cardAdded(details: CardDetails) {
            addCard.value = false
            newOrder.value.billing.cards.push({
                token: details.token,
                last_four: details.card,
                card_name: details.name,
                expMonth: details.expMonth,
                expYear: details.expYear,
            })
        }

        const billing = computed(() => {
            if (newOrder.value.lender_id == null) return ['borrower', 'card_up_front']
            const lender = lenders.value.find((elem) => {
                return elem.id === newOrder.value.lender_id
            })
            if (typeof lender == 'undefined') return ['borrower', 'card_up_front']

            const billing = lender.options.filter((elem) => {
                return elem.option_key === 'billing'
            })

            const billingProducts = lender.options.filter((elem) => {
                return elem.option_key === 'default-billing-method'
            })
            const productOptions = (billingProducts[0]?.option_value ?? []) as DefaultBillingMethodOption

            if (productOptions.productBillingOptions && productOptions.productBillingOptions.length > 0) {
                const billingOptions = productOptions.productBillingOptions.map((el) => {
                    if (el.products && el.products.length < 1) return false
                    const main = newOrder.value.forms.main as number

                    el.products.includes(main)
                    if (!el.products.includes(main)) return false

                    return el.billingOptions
                })
                if (billingOptions && billingOptions.length > 0 && billingOptions[0] != false) {
                    return billingOptions[0]
                }
            }

            if (billing.length > 0) {
                const billingMethod = JSON.parse(JSON.stringify(billing[0].option_value))
                return billingMethod
            }
            return ['borrower', 'card_up_front']
        })

        const shouldShowCard = computed(() => {
            if (newOrder.value.billing_method === 'credit-card') return addCard.value
            if (hasCard.value) return addCard.value
            return false
        })

        return {
            newOrder,
            billing,
            cardAdded,
            hasBorrowerPayment,
            hasCard,
            paymentEmailsRules,
            shouldShowCard,
            orderConfiguration,
        }
    },
    components: {
        ValidationObserver,
        CreditCard,
        NewOrderSplitPayment,
    },
})
