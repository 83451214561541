





























import { defineComponent, ref, computed, PropType } from '@vue/composition-api'
import { DateTime } from 'luxon'

export default defineComponent({
    props: {
        value: {
            type: Object as PropType<{ from: string; to: string }>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const menu = ref(false)
        const localDates = ref([props.value.from, props.value.to])

        const valueFormatted = computed(() => {
            if (props.value.from === '' && props.value.to === '') return 'All'

            const from = DateTime.fromISO(props.value.from).toFormat('MM/dd/yy')
            const to = DateTime.fromISO(props.value.to).toFormat('MM/dd/yy')
            return `${from} - ${to}`
        })

        const onOk = () => {
            localDates.value = localDates.value.sort()
            emit('input', { from: localDates.value[0], to: localDates.value[1] })
            menu.value = false
        }

        const onCancel = () => {
            localDates.value = [props.value.from, props.value.to]
            emit('input', { from: props.value.from, to: props.value.to })
            menu.value = false
        }

        const onClear = () => {
            localDates.value = ['', '']
            emit('input', { from: '', to: '' })
        }

        return {
            localDates,
            valueFormatted,
            onClear,
            menu,
            onCancel,
            onOk,
        }
    },
})
