






























































import { Form } from '@/ascent'
import AscentHelper from '@/helpers/ascent-helper'
import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import $axios from 'axios'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { DefaultAutoChargeBorrowerOption, LenderOptions } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref({
            disableDefaultAutoCharge: false,
            status: '',
            forms: [],
        } as DefaultAutoChargeBorrowerOption)
        const formSelections = ref()
        const forms = ref([] as Form[])
        const loading = ref(false)
        const corpCCApprovalFeatureEnabled = ref(
            AscentHelper.customerFeatureEnabled('monitoring.CorporateCreditCardApproval', false),
        )

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as DefaultAutoChargeBorrowerOption
        }

        const statuses = [
            { text: 'Order Creation', value: 'order' },
            { text: 'Vendor Accepted', value: 'accept' },
            { text: 'Inspection Completed', value: 'inspection' },
        ]

        const updateOption = () => {
            if (!corpCCApprovalFeatureEnabled.value) {
                optionLocal.value.disableDefaultAutoCharge = false
            }
            if (corpCCApprovalFeatureEnabled.value) {
                optionLocal.value.forms = forms.value.map((form: Form) => form.id as number)
            }

            axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'auto-charge-borrower',
                    option_value: optionLocal.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        onMounted(() => {
            if (corpCCApprovalFeatureEnabled.value) {
                loading.value = true
                $axios
                    .get('/v1/form', {
                        params: {
                            type: 'Main Product',
                        },
                    })
                    .then(({ data }) => {
                        formSelections.value = data
                        loading.value = false
                    })
            }
        })

        return {
            optionLocal,
            statuses,
            forms,
            formSelections,
            loading,
            corpCCApprovalFeatureEnabled,
            updateOption,
        }
    },
})
