
































































import { defineComponent, ref } from '@vue/composition-api'
import { getOrder } from '@/plugins/order/Order'
import { Note, OrderModel } from '@/types'
import { getUnreadNotes, newNote } from '@/plugins/Notes'
import $axios from '@/plugins/axios'
import { updateOrders } from '@/components/OrderTable/Classes/OrderTable'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

/**
 * @name UnreadNoteDetailTemplate
 *Template in the order table that allows user to see unread notes from the table itself. And click in to reply to the notes
 *
 * @SetupData newNote - New Note that user will send
 * @SetupMethod reply - Sets the new note to the type they are sending the note to, and then opens up that specific order
 */

export default defineComponent({
    name: 'UnreadNoteDetailTemplate',
    setup() {
        const reply = (order: OrderModel, unreadNotes: Note[] = []) => {
            if (unreadNotes.length === 0) return

            // Sort the unread notes by 'created_at' to get the most recent one
            unreadNotes.sort((a, b) => new Date(b.created_at ?? 0).getTime() - new Date(a.created_at ?? 0).getTime())

            // Set the target based on the most recent note
            newNote.value.target = (unreadNotes[0].source ?? '').toLowerCase()

            getOrder(order)
        }

        const markRead = (order: OrderModel) => {
            $axios
                .post('v1/order/action/' + order.id + '/complete', {
                    queueType: 'unread',
                })
                .then(() => {
                    updateOrders()
                    showSnackbar('Notes Marked Read Successfully')
                })
        }

        const unreadNotes = ref([] as Note[])
        const loadingNotes = ref(true)

        return {
            reply,
            markRead,
            unreadNotes,
            loadingNotes,
        }
    },
    mounted() {
        //on mounted, go get the unread order notes for this specific order
        getUnreadNotes((this.data as OrderModel).id)
            .then((response) => {
                if (response && response.data) {
                    this.unreadNotes = response.data.sort((a: Note, b: Note) => {
                        if (!a.created_at || !b.created_at) {
                            return 0
                        }

                        if (a.created_at < b.created_at) {
                            return -1
                        }

                        if (a.created_at > b.created_at) {
                            return 1
                        }

                        return 0
                    })
                    this.loadingNotes = false
                }
            })
            .catch(() => {
                this.loadingNotes = false
            })
    },
})
