












































import { defineComponent } from '@vue/composition-api'
import { newFileDialog } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/AddFileDialog.vue'
import { newMortgageDirectorFileDialog } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/MortgageDirectorImportDialog.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        return {
            newFileDialog,
            newMortgageDirectorFileDialog,
            order,
        }
    },
})
