




















import { defineComponent } from '@vue/composition-api'
import { openModRequest } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/RequestModificationDialog.vue'

export default defineComponent({
    name: 'CustomerRequiresModRequest',
    setup() {
        return {
            openModRequest,
        }
    },
})
