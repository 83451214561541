





















import { defineComponent, ref } from '@vue/composition-api'
import { AppraiserPanel } from '@/types/appraisers'
import JaroAssignPanelsAppraiserTagsChipGroup from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsAppraiserTagsChipGroup.vue'

export default defineComponent({
    components: {
        JaroAssignPanelsAppraiserTagsChipGroup,
    },
    props: {
        item: {
            type: Object as () => AppraiserPanel,
            required: true,
        },
        largePanelViewer: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const showChips = ref(false)
        return {
            showChips,
        }
    },
})
