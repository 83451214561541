























































import { defineComponent } from '@vue/composition-api'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'
import { PropType } from '@vue/composition-api'
import { Address } from '@/types'

/**
 * @name 'Address Fields'
 * Formats and sets all the address fields data
 */

export default defineComponent({
    components: { StateSelect },
    props: {
        /**
         * The address value and v-model
         */
        address: {
            type: Object as PropType<Address>,
            default: () => ({
                street: '',
                sub_street: '',
                city: '',
                state: '',
                zip: '',
            }),
        },
        /**
         * A boolean if the state value is abbreviated
         */
        abbreviated: {
            type: Boolean,
            default: false,
        },
        /**
         * If the user can edit the address
         */
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            /**
             * the address value and all the corresponding fields
             */
            localAddress: {
                street: '',
                sub_street: '',
                city: '',
                state: '',
                zip: '',
            } as Address,
        }
    },
    mounted() {
        return (this.localAddress = this.address)
    },
    watch: {
        /**
         * Watches the address and sets the local address to the new address
         */
        address: function (newAddress) {
            this.localAddress = newAddress
        },
    },
    methods: {
        /**
         * Emits the address was updates and sends the updated address
         */
        emitAddressUpdated() {
            this.$emit('address-updated', this.localAddress)
        },
    },
})
