


























































































































import { defineComponent } from '@vue/composition-api'
import DownloadFile, { download } from '@/components/General/DownloadFile.vue'
import { VerosSubmissions } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, getVeros } from '@/plugins/order/Order'
import { orderLoaded } from '@/pages/AMC/Quality/Process/QcReview/Partials/QCReviewProcessData'

export default defineComponent({
    components: { DownloadFile },
    props: {},
    setup() {
        return {
            download,
            order,
            orderLoaded,
        }
    },
    data() {
        return {
            checking: false,
            headers: [
                { text: 'Submission ID', value: 'id' },
                { text: 'Type', value: 'action' },
                { text: 'Portal', value: 'type' },
                { text: 'CU Score', value: 'cuscore' },
                { text: 'Status', value: 'status' },
                { text: 'XML File', value: 'file' },
                { text: 'Date', value: 'date' },
                { text: 'Active', value: 'active' },
            ],
        }
    },
    methods: {
        reSubmit(submission: VerosSubmissions) {
            this.checking = true
            this.$axios.post('/v1/veros/' + submission.id).then(() => {
                setTimeout(() => {
                    this.checkForProcess(submission.id)
                }, 5000)
            })
        },
        checkForProcess(id: number) {
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/veros/check', {
                    id: id,
                })
                .then((response) => {
                    if (response.data.result === 'finished') {
                        this.checking = false
                        showSnackbar('Results loaded')
                        getVeros()
                    } else {
                        setTimeout(() => {
                            this.checkForProcess(id)
                        }, 5000)
                    }
                })
        },
        openFile(file_id: number) {
            if (!order.value) return
            this.$axios.get('/v1/order/' + order.value.id + '/file/' + file_id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        },
        removeActive(item: VerosSubmissions) {
            this.$axios.delete('/v1/veros-submission/' + item.id).then(() => {
                showSnackbar('Active Submission Changed')
                getVeros()
            })
        },
    },
})
