<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on }">
            <v-btn outlined color="primary" depressed small v-on="on">Split Payments</v-btn>
        </template>
        <v-card v-if="localOrder != null">
            <v-card-title>Split Payment Charging</v-card-title>
            <v-card-text v-if="localOrder.billingDetails">
                <v-list>
                    <v-list-item v-for="(split, index) in localOrder.billingDetails.splits" :key="index">
                        <v-list-item-title>
                            {{
                                paymentTypes.find((elem) => {
                                    return elem.id === split.method
                                }).text
                            }}
                        </v-list-item-title>
                        <v-list-item-action>{{ $ascent.currency(split.amount) }}</v-list-item-action>
                    </v-list-item>
                </v-list>
                <p
                    style="border: 1px solid; padding: 5px"
                    v-if="localOrder.billingDetails"
                    v-html="localOrder.billingDetails.note"
                ></p>
                <v-menu bottom offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" v-on="on" block small>Credit Card Actions</v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="
                                localOrder.billingMethod !== 'mercury-card' &&
                                localOrder.billingMethod !== 'valuepad-card'
                            "
                        >
                            <charge-card :id="localOrder.id" :total="localOrder.fee" :due="localOrder.due" />
                        </v-list-item>

                        <v-list-item v-if="localOrder.billingMethod === 'mercury-card'">
                            <charge-mercury-card :id="localOrder.id" :total="localOrder.fee" :due="localOrder.due" />
                        </v-list-item>
                        <v-list-item v-if="localOrder.billingMethod === 'valuepad-card'">
                            <charge-valuepad-card :id="localOrder.id" :total="localOrder.fee" :due="localOrder.due" />
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="link" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { paymentTypes } from '@/plugins/Data'
import Vue from 'vue'
import ChargeCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeCard.vue'
import ChargeMercuryCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeMercuryCard.vue'
import ChargeValuepadCard from '@/components/OrderScreens/Amc/AMCActions/Payment/ChargeValuepadCard.vue'

export default Vue.extend({
    components: {
        ChargeCard,
        ChargeMercuryCard,
        ChargeValuepadCard,
    },
    props: {
        localOrder: null,
    },
    setup() {
        return {
            paymentTypes,
        }
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        open() {
            this.dialog = true
        },
    },
})
</script>
