























import AddCondition from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/AddCondition.vue'
import ConditionList from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/ConditionList.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'
import { defineComponent } from '@vue/composition-api'
import { order, updateOrder } from '@/plugins/order/Order'

/**
 * @name AddConditionsToRequestDialog
 * Dialog to add and send conditions to the appraiser
 */

export default defineComponent({
    components: { AddCondition, ConditionList },
    data() {
        return {
            dialog: false,
            saving: false,
        }
    },
    methods: {
        /**
         * Saves and requests new conditions to add to the order
         */
        save() {
            this.saving = true
            if (!order.value) return
            this.$axios.post('/v1/order/' + order.value.id + '/action/request-new-conditions').then(() => {
                if (!order.value) return
                this.saving = false
                this.dialog = false
                updateOrder()
                getNotes(order.value.id)
                showSnackbar('Conditions Requested Successfully')
            })
        },
    },
})
