



















import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { lenderId, sublenderId } from '@/components/General/LenderOptions/LenderOptions'
import { DynamicForm as DynamicFormConfig } from '@/types/DynamicForm'
import { currentCustomer } from '@/plugins/User'
import DynamicForm from '@/components/DynamicForm/DynamicForm.vue'
export default defineComponent({
    components: {
        DynamicForm,
    },
    name: 'SublenderCompanyInfo',
    setup() {
        const forms = ref<DynamicFormConfig[]>([])
        const loaded = ref<boolean>(false)
        const formConfigData = computed(() => ({
            lenderId: lenderId.value,
            sublenderId: sublenderId.value,
        }))

        onMounted(() => {
            axios
                .get(`/v1/customer/${currentCustomer.value.id}/dynamic-form?type=sublender-contact`)
                .then((response) => {
                    if (response.data?.forms) {
                        forms.value = response.data.forms
                    }
                    loaded.value = true
                })
        })

        return {
            loaded,
            forms,
            formConfigData,
        }
    },
})
