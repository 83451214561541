




























































































































































































































































































import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { closeOrder, order, updateOrder } from '@/plugins/order/Order'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import TextDialog, { showTextDialog } from '@/components/General/TextDialog.vue'
import axios from '@/plugins/axios'
import AscentHelper from '@/helpers/ascent-helper'
import { user } from '@/plugins/User'
import { UsageFee } from '@/types'

/**
 * @name AppraiserAcceptAction
 * Workflow step in Appraiser Order Screen where Appraiser can accept/decline an order
 */

export default defineComponent({
    components: {
        TextDialog,
        ValidationObserver,
    },
    emits: ['acceptanceError'],
    setup() {
        const dialogText = ref('')
        const dialogTitle = ref('')
        const clickFee = ref(0)
        const clickFeeAgreement = ref({ text: '' })
        const clickFeeBillable = ref('customer')
        const clickFeeBillingMethod = ref('charge')
        const clickFeeEnabled = ref(false)
        const clickFeeRequired = ref(false)
        const hasExistingClickFee = ref(false)
        const savedCard = ref(null)

        const appraiserClickFeesEnabled = computed(() => {
            if (!order.value) return false
            if (!order.value.incremental) return false
            if (!order.value.incremental.appraiser) return false
            if (
                order.value.incremental.appraiser.amc_status &&
                order.value.incremental.appraiser.amc_status.relationship &&
                order.value.incremental.appraiser.amc_status.relationship.clickFee
            ) {
                return order.value.incremental.appraiser.amc_status.relationship.clickFee.billable_type === 'appraiser'
            }
            if (!order.value.incremental.appraiser.options) return false
            if (order.value.incremental.appraiser.options.length === 0) return false
            const clickFeesOption = order.value.incremental.appraiser.options.filter((option) => {
                return option.option_key === 'click_fees_enabled'
            })
            if (!clickFeesOption) return false
            if (clickFeesOption.length === 0) return AscentHelper.featureEnabled('orderScreen.clickFee')
            return clickFeesOption[0].option_value
        })

        const getClickFeeAgreement = () => {
            if (!appraiserClickFeesEnabled.value) return
            axios.get('/v1/order/' + order.value?.id + '/setting/click-fee-agreement').then((response) => {
                if (response.data != null) {
                    clickFeeAgreement.value = response.data
                }
            })
        }

        const getSavedCard = () => {
            if (!appraiserClickFeesEnabled.value) return
            if (
                order.value &&
                order.value.incremental &&
                order.value.incremental.appraiser &&
                order.value.incremental.appraiser.amc_status &&
                order.value.incremental.appraiser.amc_status.relationship &&
                order.value.incremental.appraiser.amc_status.relationship.clickFee
            ) {
                clickFeeBillable.value =
                    order.value.incremental.appraiser.amc_status.relationship.clickFee.billable_type ?? 'customer'
                clickFeeBillingMethod.value =
                    order.value.incremental.appraiser.amc_status.relationship.clickFee.billing_method ?? 'charge'
            }
            if (clickFeeBillable.value === 'appraiser' && clickFeeBillingMethod.value === 'charge') {
                axios
                    .get('/v1/appraiser/' + order.value?.appraiser_id + '/card')
                    .then((response) => {
                        savedCard.value = response.data.card
                    })
                    .catch((error) => {
                        const errorMessage = error.response ?? error
                        showError(errorMessage)
                    })
            }
        }

        const openAppraiserBillingPage = () => {
            if (!appraiserClickFeesEnabled.value) return
            window.open('/appraiser/' + order.value?.appraiser_id + '/payments', '_self')
        }

        const orderDuplicate = computed(() => {
            if (!order.value) return false
            if (!order.value.incremental) return false
            if (!order.value.incremental.duplicates) return false
            if (order.value.incremental.duplicates.length > 0) {
                for (let i = 0; i < order.value.incremental.duplicates.length; i++) {
                    if (!order.value.incremental.duplicates[i].duplicate_cache) return false
                    if (
                        order.value.appraiser_id === order.value.incremental.duplicates[i].duplicate_cache?.appraiser_id
                    )
                        return true
                }
            }
            return false
        })

        const filteredDuplicates = computed(() => {
            if (!order.value) return []
            if (!order.value.incremental) return []
            if (!order.value.incremental.duplicates) return []
            return order.value.incremental.duplicates.filter((elem) => elem.duplicate_cache)
        })

        const showTerms = (text: string, title: string) => {
            dialogText.value = text
            dialogTitle.value = title
            showTextDialog.value = true
        }

        const checkIfClickFeeIsRequired = async () => {
            if (!order.value) return
            clickFee.value = AscentHelper.customerFee(
                `orderType.${order.value.order_type}`,
                AscentHelper.customerFee('order'),
            )
            clickFeeEnabled.value = AscentHelper.featureEnabled('orderScreen.clickFee')
            if (
                clickFee.value > 0 &&
                clickFeeEnabled.value &&
                !hasExistingClickFee.value &&
                appraiserClickFeesEnabled.value &&
                !order.value?.incremental?.details?.details.ownClickFee
            ) {
                clickFeeBillable.value = 'appraiser'
                clickFeeRequired.value = true
                getClickFeeAgreement()
                getSavedCard()
            }
        }

        const checkExistingClickFee = () => {
            if (!order.value) return
            axios
                .get('/v1/order/' + order.value?.id + '/usage-fees?type=Click%20Fee')
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        const customerClickFees: UsageFee[] = response.data.filter(
                            (fee: UsageFee) => fee.invoice_product_id === 1 && fee.billable_type === 'customer',
                        )
                        const appraiserClickFees: UsageFee[] = response.data.filter(
                            (fee: UsageFee) =>
                                fee.invoice_product_id === 1 &&
                                fee.billable_type === 'appraiser' &&
                                fee.billable_id === order.value?.appraiser_id,
                        )
                        const totalCustomerClickFee = customerClickFees.reduce((acc, curr) => acc + curr.amount, 0)
                        const totalAppraiserClickFee = appraiserClickFees.reduce((acc, curr) => acc + curr.amount, 0)
                        if (totalCustomerClickFee > 0 || totalAppraiserClickFee > 0) {
                            hasExistingClickFee.value = true
                        }
                    }
                    checkIfClickFeeIsRequired()
                })
                .catch(() => {
                    checkIfClickFeeIsRequired()
                })
        }

        const ownClientTechFee = () => {
            confirm('Are you sure?', 'Are you sure you want to own the client tech fee?').then((confirm) => {
                if (!order.value) return
                if (confirm) {
                    axios
                        .post('/v1/order/' + order.value.id + '/action/own-click-fee', { ownClickFee: true })
                        .then(() => {
                            showSnackbar('Order updated successfully')
                            clickFeeBillable.value = 'customer'
                            clickFeeRequired.value = false
                        })
                }
            })
        }

        watch(
            () => order.value?.incremental,
            () => {
                checkExistingClickFee()
            },
        )

        onMounted(() => {
            checkExistingClickFee()
        })

        return {
            clickFee,
            clickFeeAgreement,
            clickFeeBillable,
            clickFeeBillingMethod,
            clickFeeEnabled,
            clickFeeRequired,
            dialogText,
            dialogTitle,
            openAppraiserBillingPage,
            order,
            orderDuplicate,
            savedCard,
            showTerms,
            filteredDuplicates,
            ownClientTechFee,
            user,
        }
    },
    data() {
        return {
            answer: '',
            reason: '',
            agreement: null,
            agree: false,
            clickFeeAgree: false,
            loading: false,
            settings: {
                text: null,
            },
            message: '',
        }
    },

    watch: {
        answer(value, oldValue) {
            if (oldValue === '') {
                this.getAgreement()
            }
        },
    },
    methods: {
        /**
         * Gets the agreement
         */
        getAgreement() {
            if (!order.value) return
            this.$axios.get('/v1/order/' + order.value.id + '/setting/agreement').then((response) => {
                if (response.data != null) {
                    this.settings = response.data
                }
            })
        },
        /**
         * Posts answer to /v1/order/order.id/workflow/answer an reason
         */
        submit() {
            this.loading = true
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/' + this.answer.replace(/\s+/g, '-').toLowerCase(), {
                    billable: this.clickFeeBillable,
                    fee: this.clickFee,
                    reason: this.reason,
                })
                .then(() => {
                    if (this.answer.toLowerCase() === 'decline') {
                        closeOrder()
                        showSnackbar('Submitted Sucessfully')
                        return
                    }
                    updateOrder()
                    showSnackbar('Submitted Sucessfully')
                    this.loading = false
                })
                .catch(({ response }) => {
                    let message = ''
                    if (response.data.errors && response.data.errors[0][0]) {
                        message = response.data.errors[0][0]
                    } else {
                        message = response.data.message ?? response
                    }

                    if (message.toLowerCase().includes('pick up card')) {
                        if (message.includes('- SF')) {
                            message =
                                message +
                                '. Our processor has sent a verification to confirm this transaction. After responding, please try again.'
                        } else {
                            message =
                                message +
                                '. Our processor has indicated this card has been reported as lost or stolen. Please use a different card and try again.'
                        }
                    }

                    if (message.includes('pending agreements')) {
                        this.message = message
                    } else {
                        this.$emit('acceptanceError', message)
                    }

                    this.loading = false
                })
        },
        toAppraiserAgreement() {
            if (!this.order || !this.order.appraiser_id) return
            window.open(`/appraiser/${this.order.appraiser_id}/clients`, '_blank')
        },
    },
    computed: {
        formIsValid: function () {
            if (this.answer === 'Decline') return true
            if (this.clickFeeRequired) {
                return (
                    this.answer !== '' &&
                    this.clickFeeAgree &&
                    (this.clickFeeBillingMethod === 'invoice' ||
                        (this.clickFeeBillingMethod === 'charge' &&
                            (this.savedCard || order.value?.incremental?.details?.details.ownClickFee))) &&
                    ((this.answer === 'Accept' && this.agree) ||
                        (this.answer === 'Conditionally Accept' && this.reason !== ''))
                )
            }
            return (
                this.answer !== '' &&
                ((this.answer === 'Accept' && this.agree) ||
                    (this.answer === 'Conditionally Accept' && this.reason !== ''))
            )
        },
    },
})
