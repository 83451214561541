import { computed, Ref, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { setClient } from '@/pages/Client/Client'
import { setAppraiser } from '@/pages/Appraiser/Appraiser'
import * as Sentry from '@sentry/vue'
import { getTagConfig } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import { UserTraining, User, UserData, Training, Customer, CustomerPages } from '@/types'
import router from '@/pages/router'
import { pages } from '@/static/FeatureList'
import { flushToken, revertImpersonateToken } from '@/helpers/token-helper'
import AscentHelper from '@/helpers/ascent-helper'
import { handleConnection } from '@/plugins/Channels'

export const user = ref(undefined as User | undefined)
export const role = ref('')
export const permissions = ref([] as string[])
export const impersonated = ref(false)
export const favorites = ref([] as number[])
export const favoriteTeams = ref([] as number[])
export const trainings = ref({} as UserTraining)
export const customers = ref([] as Customer[])
export const currentCustomer: Ref<Customer> = ref({} as Customer)
export const dashboardAccess = ref([] as string[])
export const customerPages: Ref<CustomerPages> = ref(pages)
export const userFeatures: Ref<Record<string, boolean | string>> = ref({})

export const enabledCustomerFeatures = computed(() => {
    if (!currentCustomer.value.features) return {}
    return currentCustomer.value.features
})

export const previousPath = ref('/login' as string)

export const checkUserTrainings = () => {
    if (impersonated.value) return

    for (const key of Object.keys(trainings.value)) {
        if (trainings.value[key as Training] == null) {
            if (!user.value) return
            $axios
                .patch(`/v1/user/${user.value.id}/settings/trainings`, {
                    training: key,
                })
                .then(() => {
                    window.stonlyTrack('track', key)
                })
        }
    }
}

export const setUserDetails = (data: UserData) => {
    user.value = data.user
    role.value = data.role
    permissions.value = data.permissions
    impersonated.value = data.impersonated
    favorites.value = []
    favoriteTeams.value = []
    trainings.value = data.trainings
    customers.value = data.customers
    dashboardAccess.value = data.dashboard_access
    userFeatures.value = data.features
    if (data.currentCustomer) {
        currentCustomer.value = data.currentCustomer
        customerPages.value = data.currentCustomer.pages as CustomerPages
    }

    if (Array.isArray(data.favorites)) {
        favorites.value = data.favorites
    }

    if (Array.isArray(data.favoriteTeams)) {
        favoriteTeams.value = data.favoriteTeams
    }

    switch (data.user.type) {
        case 'client':
            setClient(data.client)
            break
        case 'appraiser':
            setAppraiser(data.appraiser)
            if (
                data.appraiser?.appraiser?.customer_id &&
                AscentHelper.customerFeatureEnabled('orderScreen.appraisersCanManageTags')
            ) {
                getTagConfig()
            }
            break
        case 'amc':
        case 'office':
        case 'desk':
            getTagConfig()
            break
    }

    Sentry.configureScope((scope) => {
        if (!user.value) return
        scope.setUser({
            id: user.value.id.toString(),
            email: user.value.email,
        })
    })
}

export const subscribeUserUpdates = () => {
    if (!user?.value) return
    handleConnection({
        channelRef: 'userRefresh',
        id: user.value.id,
    }).then((connector) => {
        connector.listen('.userRefresh', (data: { features: { newOrderScreen: boolean } }) => {
            userFeatures.value = data.features
        })
    })
}

export const getUser = async () => {
    return await $axios.get('/v1/user-v2').then(({ data }) => {
        setUserDetails(data)
        AscentHelper.updateAscentCache()
        return data
    })
}
/**
 * Based on user type, redirect to specific home dashboard.
 * @param userType - the type of user logging in (office, appraiser, client, amc)
 * @param forceReload - redirect via page reload if true
 */
export const redirectTo = (userType: string, forceReload = false) => {
    const path = (type: string) => {
        switch (type) {
            case 'office':
                return '/office/dashboard'
            case 'appraiser':
                return '/appraiser/dashboard'
            case 'client':
                return '/client/dashboard'
            case 'desk':
                return '/desk/dashboard'
            case 'amc':
                return '/dashboard'
            case 'quality':
                return '/quality/external'
            case 'preload':
                return '/preload/dashboard'
            case 'insurance':
                return '/insurance/dashboard'
            default:
                return '/'
        }
    }

    if (forceReload) {
        window.location.href = path(userType)
        return
    }

    router
        .push(path(userType))
        .then(() => router.go(0))
        .catch(() => {
            /* do nothing */
        })
}

export const stopImpersonation = async () => {
    return await $axios
        .post('/v1/ascent/leave-impersonation')
        .then((response) => {
            revertImpersonateToken()
            const oldPath = response.data.data.path
            getUser().then(() => {
                router
                    .push({ path: oldPath })
                    .then(() => router.go(0))
                    .catch()
            })
        })
        .catch(() => {
            flushToken()
            router
                .push('/login')
                .then(() => router.go(0))
                .catch()
        })
}
