




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        source: { type: String, required: true },
    },
    setup() {
        return {}
    },
})
