import $axios from '@/plugins/axios'
import { filteredColumns, grid, updateOrders, searchText } from '@/components/OrderTable/Classes/OrderTable'

export const setInitialFilter = (
    filters: {
        field: string
        matchCase: boolean
        operator: string
        predicate: string
        value: string | Date
    }[],
) => {
    filters.forEach((elem) => filteredColumns.value.push(elem.field as string))
    updateOrders(filters)
}

export const getColumnFilters = async (type: string, where?: unknown): Promise<unknown> => {
    return await $axios
        .post('/v1/order-table-filter', {
            searchText: searchText.value,
            skip: 0,
            take: 1000,
            field: type,
            where: where,
        })
        .then(({ data }) => {
            return data
        })
}

export const clearFilters = () => {
    try {
        grid.value.clearFiltering(filteredColumns.value)
    } finally {
        filteredColumns.value = [] as string[]
        updateOrders()
    }
}
