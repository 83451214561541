










































const quickNotes = [
    { title: 'Canned Response 1 Title', value: 'Canned Response 1 Value' },
    { title: 'Canned Response 2 Title', value: 'Canned Response 2 Value' },
    { title: 'Canned Response 3 Title', value: 'Canned Response 3 Value' },
    { title: 'Canned Response 4 Title', value: 'Canned Response 4 Value' },
    { title: 'Canned Response 5 Title', value: 'Canned Response 5 Value' },
]

import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import $axios from '@/plugins/axios'
import { scrollToBottom } from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderNotes.vue'
import { resetNote, addNote as addAppraiserNote } from '@/plugins/Notes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'

/**
 * @name AddNewAppraiserNote
 * Component to let appraiser add new note to the order to the AMC.
 *
 * @SetupMethod addNote - posts note to /v1/order/order.id/note, with the note, source as Appraiser and target AMC
 */

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const note = ref('')
        const quickNote = ref('')
        const loading = ref(false)

        const addNote = (): void => {
            if (!order.value) return
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/note', {
                    note: note.value,
                    source: 'Appraiser',
                    target: 'AMC',
                })
                .then(({ data }) => {
                    showSnackbar('Note Added Successfully!')
                    loading.value = false
                    note.value = ''
                    resetNote()
                    addAppraiserNote(data)
                    setTimeout(() => {
                        scrollToBottom()
                    }, 300)
                })
                .catch(() => {
                    loading.value = false
                })
        }

        return {
            quickNote,
            quickNotes,
            note,
            loading,
            addNote,
        }
    },
})
