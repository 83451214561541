<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title>
                <a
                    class="text-decoration-none link"
                    :href="pdfUrl + '/order/reconsideration/' + order.id + '/' + order.incremental.token + '/'"
                    target="_blank"
                    style="color: #3b35b1"
                >
                    Reconsideration PDF
                </a>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="mx-1">
            <v-icon
                small
                @click="$emit('download', pdfUrl + '/pdf-download/reconsideration/' + order.incremental.token)"
            >
                $vuetify.icons.download
            </v-icon>
        </v-list-item-action>
        <v-list-item-action v-if="$ascent.canDo('Work')" class="mx-1">
            <v-icon
                small
                @click="sendInvoice"
                v-if="order.source === 'mercury' || order.source === 'firewall' || order.source === 'trk'"
            >
                $vuetify.icons.send
            </v-icon>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
    computed: {
        pdfUrl() {
            return process.env.VUE_APP_PDF
        },
    },
    methods: {
        sendInvoice() {
            confirm(
                'Send Reconsideration PDF to Client?',
                'Do you want to send a this file to the client through the integration?',
            ).then((confirm) => {
                if (confirm) {
                    this.$axios
                        .post('/v1/order/' + order.value.id + '/invoice/send', {
                            type: 'reconsideration',
                        })
                        .then(() => {
                            showSnackbar('Invoice Sent successfully')
                        })
                }
            })
        },
    },
})
</script>
