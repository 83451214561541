
































































import { computed, defineComponent } from '@vue/composition-api'

import { currentTab, tabsSelection } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { order } from '@/plugins/order/Order'
import { AppraiserPanel } from '@/types'

interface Position {
    lat: number
    lng: number
}

interface AppraiserPosition {
    latitude: string
    longitude: string
}

interface Appraiser {
    appraiser: AppraiserPosition
    availability: string
    distance: number
    panel: number
    stats: string
}

/**
 * @name PanelMap
 * Map to show the appraisers and how far they are away from the property on the order
 *
 * @SetupComputed currentAppraisers - grabs the appraisers for the current tab the user is viewing
 */

export default defineComponent({
    props: {
        jaroPanelAppraisers: {
            type: Array as () => AppraiserPanel[],
            default: () => [],
            required: false,
        },
    },
    setup(props) {
        const currentAppraisers = computed(() => {
            if (props.jaroPanelAppraisers.length > 0) {
                return props.jaroPanelAppraisers
            }
            switch (currentTab.value) {
                case 'branch-panel':
                    return tabsSelection.value.branch
                case 'lender-panel':
                    return tabsSelection.value.lender
                case 'secondary-panel':
                    return tabsSelection.value.secondary
                case 'primary-panel':
                default:
                    return tabsSelection.value.primary
            }
        })

        return {
            currentTab,
            currentAppraisers,
        }
    },
    data() {
        return {
            /**
             * determines if map is shown
             */
            show: false,
            /**
             * Selected appraiser on the map
             */
            selected: null as unknown,
            /**
             * Position of selected appraiser
             */
            position: {
                lat: 0,
                lng: 0,
            } as Position,
            open: false,
            /**
             * Offset details on the map
             */
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },
        }
    },
    computed: {
        /**
         * Returns lat and long of the property on the order
         */
        property() {
            if (!order.value) return
            return {
                longitude: parseFloat(order.value.address.longitude as string),
                latitude: parseFloat(order.value.address.latitude as string),
            }
        },
    },
    methods: {
        showMap() {
            this.show = true
        },
        /**
         * Sets the selected appraiser and finds it position
         * @param appraiser - appraiser that is selected
         */
        selectAppraiser(appraiser: Appraiser) {
            this.selected = appraiser
            this.position = {
                lat: parseFloat(appraiser.appraiser.latitude),
                lng: parseFloat(appraiser.appraiser.longitude),
            }
            this.open = true
        },
    },
})
