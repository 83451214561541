







import { defineComponent } from '@vue/composition-api'
import { order, isLinkReport } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        const openLinkReport = () => {
            window.open(`${process.env.VUE_APP_REPORT_EXTERNAL}?internal-order-id=${order.value?.id}`, '_blank')
        }

        return {
            order,
            isLinkReport,
            openLinkReport,
        }
    },
})
