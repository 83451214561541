
























import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { DefaultPaymentLinkOption, LenderOptions } from '@/types'

export default defineComponent({
    name: 'LenderOptionDefaultPaymentLink',
    components: {},
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref({ type: null } as DefaultPaymentLinkOption)

        const save = () => {
            $axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'payment-link-type',
                    option_value: optionLocal.value,
                })
                .then(() => {
                    showSnackbar(' Option updated successfully')
                })
        }

        onMounted(() => {
            if (props.option) {
                optionLocal.value = props.option?.option_value as DefaultPaymentLinkOption
            }
        })
        return {
            optionLocal,
            save,
        }
    },
})
