<template>
    <div v-if="data.tags != null">
        <v-chip
            v-for="tag in filterTags"
            :key="tag.id"
            :close="
                Boolean(tag.deletable) && $ascent.canDo('Work') && ['appraiser', 'client'].indexOf(user.type) === -1
            "
            small
            text-color="white"
            label
            :color="tag.color"
            class="mb-1 d-block"
            @click:close="removeTag(tag)"
        >
            <v-icon x-small v-if="tag.icon != null" left>$vuetify.icons.{{ tag.icon }}</v-icon>
            {{ tag.tag }}
        </v-chip>
    </div>
</template>

<script lang="js">
import {defineComponent} from "@vue/composition-api"
import {user} from "@/plugins/User"

/**
 * @name ColumnTags
 * Column on order table that shows the tags on a specific order
 *
 * @SubmitMethod removeTag - removes tag from the order. Uses delete endpoint: '/v1/tag/' + tag.id
 */

export default defineComponent({
    props: {},
    setup(){
      return {
        user
      }
    },
    data() {
        return {
            data: null
        }
    },
  computed:{
      filterTags(){
        if(this.data.tags === null || this.data.tags === undefined || this.data === null) return []
        const tags = this.data.tags.filter(tag => {
          if(tag.showOnTable === 0 || tag.showOnTable === false) return false
          if(user.value.type === 'client' && tag.showToClient === true)  return true
          if(user.value.type === 'appraiser' && tag.showToAppraiser === true) return true
          return (user.value?.type !== 'appraiser' && user.value?.type !== 'client')
        })
        return tags
      }
  },
    methods: {
        removeTag(tag) {
            this.$axios.delete('/v1/tag/' + tag.id).then(() => {
                this.data.tags.splice(this.data.tags.indexOf(tag), 1)
            })
        }

    }
})
</script>
