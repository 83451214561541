













































import { computed, defineComponent, nextTick, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { orderStatuses } from '@/components/OrderScreens/Office/OrderComponents/OrderStatusBar/OrderStatusBar'
import CustomerTimelineStep from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerTimelineStep.vue'
import AscentHelper from '@/helpers/ascent-helper'
import party from 'party-js'
import { Milestone } from '@/types'
import { DateTime } from 'luxon'

interface ChevronProgressItem {
    value?: number
    status: string
    title: string
    step: number
    tooltip?: string
    milestone?: string
}

const step = ref(1)
const progressBarInfo: Ref<ChevronProgressItem[]> = ref([])
const accept_tooltip = computed(() => {
    if (!order.value || !order.value?.incremental) return ''
    if (order.value.status === 'Broadcasting') return 'Order Broadcasting'

    return [
        (order.value.assigned as number) > 0
            ? 'Assigned: ' + AscentHelper.formatUnix(order.value.assigned as number, 'MM/dd/yyyy hh:mm a')
            : '',
        order.value.incremental.accepted
            ? 'Accepted: ' +
              AscentHelper.formatUnix(new Date(order.value.incremental.accepted).valueOf(), 'MM/dd/yyyy hh:mm a')
            : '',
    ]
        .filter((str) => str !== '')
        .join('\n')
})

const inspectionTitle = computed(() => {
    if ((order.value?.inspection as number) > new Date().getTime()) return 'Scheduled: '
    else return 'Inspected: '
})
const inspection_tooltip = computed(() => {
    if (!order.value || !order.value.incremental) return ''
    const date = order.value.incremental.milestones.find((milestone: Milestone) => {
        return milestone.milestone_type === 'Inspection Date'
    })
    if (!date) return ''
    const property = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone(order.value.timezone)
    const local = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone('local')
    return (
        inspectionTitle.value +
        property.toFormat('MM/dd/yyyy hh:mm a (ZZZZ)') +
        ' / ' +
        local.toFormat('hh:mm a (ZZZZ)')
    )
})
const completed_tooltip = computed(() => {
    if (!order.value || !order.value.incremental) return ''
    const date = order.value.incremental.milestones.find((milestone: Milestone) => {
        return milestone.milestone_type === 'Client Completed'
    })
    if (!date) return ''
    const property = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone(order.value.timezone)
    const local = DateTime.fromISO(date.milestone_value, { zone: 'UTC' }).setZone('local')
    return 'Completed: ' + property.toFormat('MM/dd/yyyy hh:mm a (ZZZZ)') + ' / ' + local.toFormat('hh:mm a (ZZZZ)')
})

const acceptStepStatus = computed(() => {
    if (order.value?.incremental?.status === 'Broadcasting') return 'Broadcasting'

    return order.value?.incremental?.customer.type === 'office' ? 'Allocate' : 'Accept'
})

const acceptStepTitle = computed(() => {
    if (order.value?.incremental?.status === 'Broadcasting') return 'Broadcasting'

    return order.value?.incremental?.customer.type === 'office' ? 'Assign' : 'Accept'
})

export const setBarInfo = () => {
    progressBarInfo.value = [
        {
            status: order.value?.incremental?.customer.type === 'office' ? 'Evaluate' : 'Assign',
            title: 'New Order',
            step: 1,
            tooltip:
                (order.value?.createdAt as number) > 0
                    ? 'Order Placed: ' + AscentHelper.formatUnix(order.value?.createdAt as number, 'MM/dd/yyyy hh:mm a')
                    : '',
        },
        {
            status: acceptStepStatus.value,
            title: acceptStepTitle.value,
            step: 2,
            tooltip: accept_tooltip.value,
        },
        {
            status: 'Schedule',
            title: 'Schedule',
            step: 3,
            tooltip:
                (order.value?.scheduled as number) > 0
                    ? 'Scheduled: ' + AscentHelper.formatUnix(order.value?.scheduled as number)
                    : '',
        },

        {
            value: order.value?.inspection as number,
            status: 'Waiting Inspection',
            title: 'Inspection',
            step: 4,
            milestone: 'Inspection',
            tooltip: inspection_tooltip.value,
        },

        {
            value: order.value?.appraiserDeadline as number,
            status: 'Complete',
            title: 'Appraiser Due',
            step: 5,
            milestone: 'Appraiser Deadline',
            tooltip:
                (order.value?.appraiserCompleted as number) > 0
                    ? 'Completed: ' + AscentHelper.formatUnix(order.value?.appraiserCompleted as number)
                    : '',
        },

        {
            value: order.value?.clientDeadline as number,
            status: 'QC Review',
            title: 'Client Due',
            step: 6,
            milestone: 'Client Deadline',
            tooltip:
                (order.value?.clientCompleted as number) > 0
                    ? 'Completed: ' + AscentHelper.formatUnix(order.value?.clientCompleted as number)
                    : '',
        },
        {
            value: order.value?.clientCompleted as number,
            status: 'Completed',
            title: 'Completed',
            step: 7,
            milestone: 'Completed',
            tooltip: completed_tooltip.value,
        },
    ]
    step.value = 0

    const index = progressBarInfo.value.findIndex((elem) => {
        return (
            elem.status === order.value?.status ||
            (order.value?.clientCompleted !== null && elem.status === 'Completed')
        )
    })

    nextTick(() => {
        step.value = index + 1
    })
}

export default defineComponent({
    components: {
        CustomerTimelineStep,
    },
    setup() {
        const partyTime = (ev: PointerEvent) => {
            party.confetti(ev, {
                count: party.variation.range(20, 60),
            })
        }

        onMounted(() => {
            setBarInfo()

            watch(
                () => order.value?.status,
                () => {
                    setBarInfo()
                },
            )

            watch(
                () => order.value?.appraiserDeadline,
                () => {
                    setBarInfo()
                },
            )

            watch(
                () => order.value?.clientDeadline,
                () => {
                    setBarInfo()
                },
            )

            watch(
                () => order.value?.inspection,
                () => {
                    setBarInfo()
                },
            )
        })

        return {
            step,
            order,
            progressBarInfo,
            orderStatuses,
            partyTime,
            acceptStepStatus,
            acceptStepTitle,
        }
    },
})
