














































































import { appraiserOffice } from '@/pages/Appraiser/Appraiser'
import { defineComponent } from '@vue/composition-api'
import { mini } from './LeftNav.vue'
import { orderSearchFilter } from '@/components/OrderTable/Classes/OrderTable'
import { user } from '@/plugins/User'

/**
 * @name 'Appraiser Nav'
 * Component description: The Navigation for the appraiser side.
 * Sets the routes, icons, and titles of each navigation.
 */
export default defineComponent({
    setup() {
        return {
            mini,
            appraiserOffice,
            orderSearchFilter,
            user,
        }
    },
})
