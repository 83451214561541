




























































import { defineComponent } from '@vue/composition-api'
import ClientEditClientContacts from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientEditClientContacts.vue'
import { order } from '@/plugins/order/Order'

/**
 * @name ClientContactsAndParticipants
 * Shows the name of the client on the order and the participants from the lender that are also on the order. Allows user to edit the client contacts
 */

export default defineComponent({
    name: 'ClientContactsAndParticipants',
    components: { ClientEditClientContacts },
    setup() {
        return { order }
    },
})
