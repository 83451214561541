



























































import { computed, defineComponent, onBeforeMount, Ref, ref } from '@vue/composition-api'
import { File } from '@/types'
import $axios from '@/plugins/axios'
import { fileStatuses, systemFileTypes } from '@/plugins/Data'
import { getFiles } from '@/plugins/Files'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order } from '@/plugins/order/Order'
import { dialog } from './EditFileDialog.vue'
import { user } from '@/plugins/User'

export const file = ref({
    type: '',
    status: '',
} as File)

export const saveFile = (): void => {
    $axios.patch('/v1/file/' + file.value.id, file.value).then(() => {
        dialog.value = false
        if (!order.value) return
        getFiles(order.value.id)
        showSnackbar('File Edited Successfully')
    })
}

const fileTypes: Ref<string[]> = ref([])

export default defineComponent({
    components: {},
    props: {
        forDialog: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        onBeforeMount(() => {
            $axios.get('v1/settings/file-list').then((res) => {
                if (!res.data) return
                fileTypes.value = res.data.result
            })
            $axios.get('v1/settings/system-file-list').then((res) => {
                if (!res.data) return
                systemFileTypes.value = res.data.result.sort()
            })
        })

        const refreshFile = (): void => {
            confirm('Refresh File?', 'Do you want to refresh this file?').then((confirm) => {
                if (confirm && order.value) {
                    $axios.post('/v1/order/' + order.value.id + '/file/' + file.value.id + '/refresh').then(() => {
                        showSnackbar('File Refreshed Successfully')
                        dialog.value = false
                    })
                }
            })
        }

        const reprocessENV = (): void => {
            confirm('Reprocess ENV?', 'Are you sure you want to send the Appraisal PDF to create a new ENV?').then(
                (confirm) => {
                    if (confirm && order.value) {
                        $axios.post('/v1/order/' + order.value.id + '/reprocess-env').then(() => {
                            showSnackbar(
                                'File Sent For Reprocessing Successfully. A note will be added when a new ENV is uploaded.',
                            )
                            dialog.value = false
                        })
                    }
                },
            )
        }

        const filteredFileTypes = computed(() => {
            if (user.value !== undefined && user.value.type !== 'appraiser')
                return systemFileTypes.value.concat(fileTypes.value).sort()
            return ['Additional']
        })

        return {
            dialog,
            file,
            saveFile,
            refreshFile,
            fileTypes,
            fileStatuses,
            order,
            reprocessENV,
            filteredFileTypes,
        }
    },
})
