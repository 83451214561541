
















import { defineComponent } from '@vue/composition-api'
import {
    getNotifications,
    addNewNotification,
    unseen,
    setShowNotifications,
} from '@/pages/System/Notifications/notifications'
import { Notification } from '@/types'
import { subscribeUserUpdates, user } from '@/plugins/User'
import { approvals, setShowApprovalNotifications } from '@/pages/System/Notifications/NotificationPartials/Approvals'
import { handleConnection } from '@/plugins/Channels'
import { subscribeChangeLogin } from '@/AppLayout/helpers/Prevent.vue'

/**
 * @name 'User Notifications'
 * The notifications for the user button (The Bell), with the number of notifications
 *
 * @Mounted gets the notifications
 */
export default defineComponent({
    setup() {
        return {
            unseen,
            user,
        }
    },
    props: {},
    data() {
        return {
            color: 'success',
            show: false,
            message: null,
            unread: [],
            read: [],
        }
    },
    mounted() {
        getNotifications()
        this.registerEvents()
    },
    methods: {
        /**
         * Will handle the connection for the channel to use pusher get notifications
         */
        registerEvents() {
            handleConnection({
                channelRef: 'notifications',
                id: user.value?.id as number,
            }).then((connector) => {
                connector.notification((notification: Notification) => {
                    addNewNotification(notification)
                    getNotifications()
                })
                subscribeChangeLogin(connector)
                subscribeUserUpdates()
            })
        },
        /**
         * Will toggle the notification tab a user is on. Either the notifications or the approvals (If there are approvals)
         */
        toggleNotificationDrawer() {
            if (approvals.value.showNotifications) {
                setShowApprovalNotifications() // closes approval notifications if open
            }
            setShowNotifications()
        },
    },
})
