
































































































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { ValidationObserver } from 'vee-validate'
import { appraiserSettingsId, canEdit } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { OfficeForms, Form, OfficeFormsData, RoleSetting } from '@/types'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

/**
 * @name 'Appraiser Fees Configuration'
 * Component description: The configuration of the forms page in the appraiser settings.
 * Initial get will get the fees - if there are no fees from the response, then it will get all the global forms and make default values.
 * @SetupData dialog: The boolean value that will determine if the editing dialog is open or closed;
 * @SetupData products - array of the main product forms if there are no fees saved
 * @SetupData fees - array of the forms and data
 * @SetupData newFeesValue - boolean to determine if there was fees saved or not.
 * @SetupMethod getForms - gets all main product forms and sets the fees to them.
 * @SetupMethod getFees - gets all fees form products and sets the fees variable to them.
 * @SetupMethod saveForms - saves the forms in the correct jsonObject
 */
export default defineComponent({
    name: 'AppraiserFeesConfigureDialog',
    components: { ValidationObserver },
    setup() {
        const dialog = ref(false)
        const products = ref([] as Form[])
        const fees = ref([] as OfficeFormsData[])
        const newFeesValue = ref(false)
        const percentage = ref(false)
        const percentageValue = ref(false)
        const roles: Ref<RoleSetting[]> = ref([])
        const feeMap = ref()
        const feeMapOptions: Ref<string[]> = ref([])

        const mappingFromOld: Record<string, string> = {
            Inspector: 'inspection',
            'Data Analyst': 'data',
            Reviewer: 'review',
            Primary: 'full',
            full: 'full',
            Trainee: 'trainee',
        }

        const getRoles = async () => {
            await $axios.get('/v1/settings/appraiser-roles').then((response) => {
                roles.value = (response.data.result as RoleSetting[]).filter((elem) => elem.role !== 'Primary')
            })
        }

        const getFeeMap = () => {
            $axios.get('/v1/settings/staff-appraiser-fee-map').then((response) => {
                if (!response?.data || !response?.data?.result) {
                    feeMap.value = false
                    return
                }
                feeMap.value = response.data.result
                for (const option in feeMap.value) {
                    feeMapOptions.value.push(feeMap.value[option]['name'])
                }
            })
        }

        const setMappedFees = async (form: OfficeFormsData) => {
            const feeMapType = form.fee_map?.toLowerCase()
            const fee = fees.value.findIndex((fee) => fee.form_id === form.form_id)

            if (!feeMapType || !feeMap.value[feeMapType] || !fees.value[fee]) return

            const roleFees = feeMap.value[feeMapType]['roles']
            fees.value[fee]['full'] = roleFees.full
            roles.value.forEach((role) => {
                fees.value[fee][mappingFromOld[role.role]] = roleFees[mappingFromOld[role.role]]
            })
        }

        const getForms = async () => {
            await $axios.get('/v1/form').then((response) => {
                newFeesValue.value = true
                products.value = response.data
                const newFees = [] as OfficeFormsData[]
                products.value.map((elem) => {
                    if (!elem.name) return
                    if (elem.type === 'Loan Type') return
                    if (elem.type === 'Miscellaneous') return
                    newFees[elem.id] = {
                        id: elem.id,
                        type: elem.type,
                        name: elem.name,
                        form_id: elem.id,
                        rules: elem.detail?.details?.rules ?? [],
                    }
                    roles.value.forEach((role) => {
                        newFees[elem.id][mappingFromOld[role.role]] = null
                    })
                })
                fees.value = newFees.filter((el) => {
                    return el !== null && typeof el !== 'undefined'
                })
            })
        }

        const getFees = async () => {
            await getForms()
            await $axios
                .get('v1/appraiser/' + appraiserSettingsId.value + '/fees')
                .then((response) => {
                    if (!response.data || response.data.length == 0 || response.data['undefined']) {
                        return
                    }
                    if (response.data['percentage']) {
                        percentage.value = response.data['percentage']
                        percentageValue.value = response.data['percentage']
                    }
                    const values = Object.keys(response.data)
                    values.forEach((elem) => {
                        if (isNaN(parseInt(elem))) return
                        const index = fees.value.findIndex((fee) => fee.form_id === response.data[elem].id)
                        if (index === -1) return

                        fees.value[index]['fee_map'] = null
                        const feeMapType = response.data[elem]['fee_map']

                        if (typeof response.data[elem]['fee_map'] === 'string' && feeMap.value[feeMapType]) {
                            fees.value[index]['fee_map'] = feeMapType.charAt(0).toUpperCase() + feeMapType.slice(1)
                        }
                        fees.value[index]['full'] = response.data[elem]['full']
                        roles.value.forEach((role) => {
                            fees.value[index][mappingFromOld[role.role]] =
                                response.data[elem][mappingFromOld[role.role]]
                        })
                    })
                })
                .catch(() => {
                    getForms()
                })
        }

        const updateOption = (event: boolean) => {
            confirm('Update Fees Configuration?', 'Updating this will reset all current values!').then((confirm) => {
                if (!confirm) return (percentageValue.value = percentage.value)
                percentage.value = event as boolean
                getForms()
            })
        }

        const saveForms = async () => {
            const target = {} as OfficeForms
            fees.value.map((elem) => {
                const key = elem.id
                target[key as number] = {
                    type: elem.type,
                    name: elem.name,
                    full: elem.full,
                    id: key,
                    rules: [],
                    fee_map: elem.fee_map?.toLowerCase(),
                }
                roles.value.forEach((role) => {
                    target[key as number][mappingFromOld[role.role]] = elem[mappingFromOld[role.role]]
                })
            })
            await $axios.post('v1/appraiser/' + appraiserSettingsId.value + '/fees', {
                fees: target,
                percentage: percentage.value,
            })
            showSnackbar('Forms Saved')
            dialog.value = false
        }

        const updatePercent = (item: number) => {
            if (!percentage.value) return
            if (item > 100) return showError('Percentage must not exceed 100%')
        }

        onMounted(() => {
            getFeeMap()
            getRoles()
        })

        return {
            dialog,
            products,
            getForms,
            saveForms,
            getFees,
            fees,
            canEdit,
            newFeesValue,
            updateOption,
            percentage,
            percentageValue,
            updatePercent,
            mappingFromOld,
            roles,
            getFeeMap,
            feeMap,
            setMappedFees,
            feeMapOptions,
        }
    },
})
