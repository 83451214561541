




























































































































import Vue from 'vue'
import { DialogPlugin } from '@syncfusion/ej2-vue-popups'

Vue.use(DialogPlugin)

import { defineComponent, reactive, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import $axios from '@/plugins/axios'
import { mask } from 'vue-the-mask'
import { Dialog } from '@syncfusion/ej2-popups'
import { contactTypes, masks, phoneTypes } from '@/plugins/Data'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, updateOrder } from '@/plugins/order/Order'

const syncDialog = ref({} as Dialog)

export const openAddContact = () => {
    syncDialog.value.show()
}

/**
 * @name ClientAddContactDialog
 * Component in the Client Order Screen that allows client to add a contact on the order
 * @SetupData adding - loading boolean, shows to user that contact is being added
 * @SetupData contact - Contact that is going to be added onto the order.
 *
 * @SetupMethod closeDialog - closes dialog
 * @SetupMethod addContact - posts to '/v1/order/' + order.value.id + '/contact' with the contact client is adding to order. Adds contact and refreshes order screen
 */

export default defineComponent({
    name: 'ClientAddContactDialog',
    directives: { mask },
    components: {
        ValidationObserver,
    },
    setup() {
        const state = reactive({
            adding: false,
            contact: {
                name: null,
                email: null,
                phones: [{ type: null, phone: null }],
                type: null,
                entry: false,
            },
        })

        const closeDialog = () => {
            syncDialog.value.hide()
        }

        const addContact = () => {
            state.adding = true
            if (!order.value || !order.value?.incremental) return
            $axios.post('/v1/order/' + order.value.id + '/contact', state.contact).then(({ data }) => {
                state.adding = false
                if (!order.value?.incremental) return
                const contacts = JSON.parse(JSON.stringify(order.value.incremental.contacts))
                contacts.push(data)
                order.value.incremental.contacts = contacts
                updateOrder()
                showSnackbar('Contact Added Successfully')
                syncDialog.value.hide()
            })
        }

        return {
            state,
            syncDialog,
            addContact,
            closeDialog,
            contactTypes,
            phoneTypes,
            masks,
        }
    },
})
