import { user, role, permissions } from '@/plugins/User'

export default function (permission: string) {
    if (user.value?.ascent) return true
    if (role.value === 'Admin') return true
    switch (permission) {
        case 'reports':
            return (
                permissions.value.filter((elem) => {
                    return (
                        elem === 'Client Billing' ||
                        elem === 'Appraiser Billing' ||
                        elem === 'Dashboard' ||
                        elem === 'Reports' ||
                        elem === 'Quality Reporting'
                    )
                }).length > 0
            )
        default:
            if (permissions.value === null) return false
            if (permission.includes('.')) {
                return permission.split('.').every((perm) => permissions.value.includes(perm))
            }
            return (
                permissions.value.filter((elem) => {
                    return elem === permission
                }).length > 0
            )
    }
}
