













import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

/**
 * @name LoanNumbers
 * Loan numbers on the order. Housed in the OrderDetails component in the Amc Order Screen
 */

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
})
