



























































import { defineComponent } from '@vue/composition-api'
import { orderTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { Tag } from '@/types'
import { order, addTag } from '@/plugins/order/Order'
import ModFeeRequestDialog, {
    openDialog,
    getModHistory,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/ModFeeRequestDialog.vue'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    name: 'OrderTags',
    components: {
        ModFeeRequestDialog,
    },
    props: {
        filteredTags: {
            type: [],
            default: () => [],
        },
    },
    setup(props, { root }) {
        const removeTag = (tag: Tag) => {
            root.$axios.delete('/v1/tag/' + tag.id).then(() => {
                if (!order.value) return
                const tags = order.value.tags
                tags.splice(tags.indexOf(tag), 1)
                order.value.tags = tags
                showSnackbar('Tag removed successfully')
            })
        }

        const canDeleteTag = (tag: Tag) => {
            return (
                tag.deletable &&
                AscentHelper.canDo('Work') &&
                user.value &&
                user.value.type !== 'client' &&
                (user.value.type !== 'appraiser' || AscentHelper.featureEnabled('orderScreen.appraisersCanManageTags'))
            )
        }

        return {
            addTag,
            removeTag,
            orderTags,
            order,
            openDialog,
            getModHistory,
            user,
            canDeleteTag,
        }
    },
})
