



































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { AscentGrid } from '@/helpers/AscentDataAdapter'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const listingHistory = ref()
        const tableSettings = ref({
            dataSource: [],
            allowResizing: false,
            allowTextWrap: true,
            allowExcelExport: true,
        } as AscentGrid)

        onMounted(() => {
            listingHistory.value = []
            listingHistory.value = preloadData.value.sale_history
        })

        return {
            tableSettings,
            listingHistory,
            formatOptions: { type: 'date', format: 'MM/dd/yyyy' },
            formatOption: { format: 'C2', currency: 'USD', minimumIntegerDigits: 8 },
        }
    },
})
