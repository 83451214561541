
























import { Note } from '@/types'
import { computed, defineComponent, UnwrapRef } from '@vue/composition-api'
import { changeRead, unreadNotes } from '@/plugins/Notes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { refreshDashboardCounts } from '@/components/OrderTable/Classes/TableFilters'

/**
 * @name 'Unread Notes'
 * The unread notes within the process queue
 *
 * @SetupMethod changeReadStatus - Will change the notes read status to read.
 * @SetupMethod refreshDashboardCounts Will refresh the dashboard counts.
 *  @SetupMethod changeRead: Will update the note
 *
 * @SetupComputed unreadNotesLocal - Returns the unread notes to be rendered
 */
export default defineComponent({
    props: {
        /**
         * The unread notes from props
         */
        removeTargets: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const changeReadStatus = (note: UnwrapRef<Note | undefined>) => {
            changeRead(note as Note).then(() => {
                showSnackbar('Note Marked As Read')
                refreshDashboardCounts()
            })
        }

        const unreadNotesLocal = computed(() => {
            return unreadNotes.value.filter((note) => props.removeTargets.indexOf(note.target) === -1)
        })

        return {
            changeReadStatus,
            unreadNotesLocal,
        }
    },
})
