<template>
    <v-dialog v-model="dialog" width="800">
        <ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
            <ACard title="Edit Order">
                <v-card-text v-if="localOrder != null">
                    <v-container grid-list-lg class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <a-text-field
                                    v-model="localOrder.address.street"
                                    rules="required"
                                    label="Street Address"
                                    name="street address"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-field
                                    v-model="localOrder.address.sub_street"
                                    label="Sub Street"
                                    name="sub_street"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-field
                                    v-model="localOrder.address.city"
                                    rules="required"
                                    label="City"
                                    name="city"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-select-field
                                    v-model="localOrder.address.state"
                                    rules="required"
                                    label="State"
                                    name="state"
                                    color="secondary"
                                    outlined
                                    :items="states"
                                    @change="fetchCounties"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-field
                                    v-model="localOrder.address.zip"
                                    rules="required"
                                    label="Zip Code"
                                    name="zip"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-field
                                    v-model="localOrder.address.latitude"
                                    label="Latitude"
                                    name="latitude"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-field
                                    v-model="localOrder.address.longitude"
                                    label="Longitude"
                                    name="longitude"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-select-field
                                    v-model="localOrder.county_id"
                                    name="county"
                                    label="County"
                                    color="secondary"
                                    :items="counties"
                                    item-value="id"
                                    item-text="full_name"
                                    single
                                    search-type="off"
                                    hide-no-data
                                />
                            </v-col>

                            <v-col cols="12" sm="6">
                                <a-text-field
                                    v-model="localOrder.lenderLoanNumber"
                                    rules="required"
                                    label="Loan Number"
                                    name="lender loan number"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <a-text-field
                                    v-model="localOrder.fha"
                                    label="FHA Case Number"
                                    name="case number"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-select-field
                                    v-model="localOrder.propertyType"
                                    :items="orderTypeSelections"
                                    label="Order Type"
                                    name="order type"
                                />
                            </v-col>
                            <template v-if="localOrder.propertyType === 'Commercial'">
                                <v-col cols="6">
                                    <a-select-field
                                        v-model="commercialType"
                                        label="Property Land Type"
                                        rules="required"
                                        :items="commercialLandTypes"
                                        color="secondary"
                                        name="property type"
                                        @change="localOrder.residence_type = ''"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <a-select-field
                                        v-model="localOrder.residence_type"
                                        :items="availablePropertyTypes"
                                        label="Property Type"
                                        name="property type"
                                    />
                                </v-col>
                            </template>

                            <v-col cols="6" v-if="localOrder.propertyType !== 'Commercial'">
                                <a-select-field
                                    v-model="localOrder.residence_type"
                                    :items="availablePropertyTypes"
                                    label="Property Type"
                                    name="residence type"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-select-field
                                    v-model="localOrder.occupancy"
                                    :items="occupancyTypeSelections"
                                    label="Property Occupancy"
                                    name="residence type"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-select-field
                                    v-model="localOrder.loanPurpose"
                                    :items="financeTypeSelections"
                                    label="Loan Purpose"
                                    name="loan purpose"
                                />
                                <v-text-field
                                    v-if="localOrder.loanPurpose == 'Purchase'"
                                    v-model="localOrder.purchasePrice"
                                    :rules="[
                                        (v) =>
                                            /^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:,\d{3})?)*((?=.*\d)(?:\.\d\d){1}?){0,1}$/gm.test(
                                                v,
                                            ) || 'The Purchase Price field must be a valid currency format.',
                                        (v) => /^[0-9.,]+$/.test(v) || 'Can only input numeric characters.',
                                    ]"
                                    label="Purchase Price"
                                    name="purchase price"
                                    color="secondary"
                                    @input="validatePrice(localOrder.purchasePrice)"
                                />
                            </v-col>
                            <v-col cols="6" v-if="$ascent.featureEnabled('orderScreen.edit.appraised_value')">
                                <a-text-field
                                    v-model="localOrder.appraised_value"
                                    label="Appraised Value"
                                    rules="numeric"
                                />
                            </v-col>
                            <v-col v-if="$ascent.featureEnabled('orderScreen.invoice.disclosedFee')" cols="6">
                                <a-text-field
                                    v-model="localOrder.disclosedFee"
                                    label="Disclosed Fee"
                                    name="disclosed fee"
                                    color="secondary"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox
                                    v-model="localOrder.priority"
                                    label="Rush Order?"
                                    true-value="Rush"
                                    false-value="Standard"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-h6 text-md-h5">Custom Fields</v-col>
                            <v-col cols="6">
                                <a-text-field
                                    v-model="localOrder.customFields.duCaseFileId"
                                    label="DU Case File ID"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-field
                                    v-model="localOrder.customFields.lpaKey"
                                    label="LPA Key"
                                    color="secondary"
                                    outlined
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <a-btn-cancel @click="dialog = false" />
                    <a-btn-confirm
                        :loading="loading"
                        :disabled="invalid || !validatePrice(localOrder.purchasePrice)"
                        @click="handleSubmit(saveOrder)"
                    >
                        Save Order
                    </a-btn-confirm>
                </v-card-actions>
            </ACard>
        </ValidationObserver>
    </v-dialog>
</template>

<script lang="js">
import {commercialLandTypes, financeTypeSelections, getCustomOrderDataSetting, getResidenceTypeSelections, occupancyTypeSelections, orderTypeSelections} from "@/plugins/order/CustomerDefinedOrderData.ts";
import {defineComponent, ref, computed, onMounted} from "@vue/composition-api"
import {ValidationObserver} from 'vee-validate'
import deepEqual from 'fast-deep-equal'
import { user } from '@/plugins/User'
import {showSnackbar} from '@/AppLayout/helpers/snackbar.vue'
import {order, refreshOrder} from "@/plugins/order/Order"
import { GET_PANELS } from "@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel"

function reset() {
    return {
        counties: [],
        localOrder: {
            address: {},
            residence_type: null,
            propertyType: null,
            lenderLoanNumber: null,
            occupancy: null,
            loanPurpose: null,
            fha: null,
            priority: null,
            county_id: null,
            purchasePrice: null,
            disclosedFee: null,
            customFields: {
                duCaseFileId: null,
                lpaKey: null,
            }
        },
        dialog: false,
        loading: false,
        instruction: null
    }
}

const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut","Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];

/**
 * @name EditOrderDialog
 * Dialog in the order screen to allow user to edit different parts of the order
 *
 * @ResetData localOrder - object that holds the different areas the user can edit in the order
 */

export default defineComponent({
    components: {ValidationObserver},
    setup() {
        const commercialType = ref()
        const availablePropertyTypes = computed(() => {
            return getResidenceTypeSelections(commercialType.value)
        })

        onMounted(() => {
            getCustomOrderDataSetting()
        })

        return {
            availablePropertyTypes,
            commercialType,
            commercialLandTypes,
            financeTypeSelections,
            occupancyTypeSelections,
            orderTypeSelections,
            user,
            states,
            getResidenceTypeSelections
        }
    },
    data() {
        return reset()
    },
    methods: {
        fetchCounties() {
            this.$axios.post('/v1/search', {
                val: { state: this.localOrder.address.state },
                type: 'county',
                object: true,
                limit: 1000
            }).then((response) => {
                if (!order.value?.incremental) return
                this.localOrder.county_id = order.value?.incremental.county.id
                this.counties = response.data
            })
        },
        /**
         * Opens the dialog and sets the local order to values in the order screen
         */
        open() {
            this.dialog = true
            if (!order.value) return
            this.localOrder = JSON.parse(JSON.stringify(order.value))
            if(order.value?.incremental?.appraised_value) {
                this.localOrder.appraised_value = JSON.parse(JSON.stringify(order.value.incremental.appraised_value))
            }
            if(!this.localOrder.customFields) {
                this.localOrder.customFields = {}
            }
            this.localOrder.customFields.duCaseFileId = order.value.incremental?.details?.details?.duCaseFileId ?? null
            this.localOrder.customFields.lpaKey = order.value.incremental?.details?.details?.lpaKey ?? null
            this.fetchCounties()
        },

        /**
         * Saves order with the new values that were entered. Refreshes order after saving
         */
        async saveOrder() {
            this.loading = true

            if (!order.value) return

            if (!deepEqual(order.value.address, this.localOrder.address)) {
                await this.$axios.patch('/v1/address/' + order.value.address.id, {
                    address: this.localOrder.address
                })
                GET_PANELS({ type: "order", id: order?.value?.id})
            }

            this.$axios.patch('/v1/order/' + order.value.id, {
                address: this.localOrder.address,
                appraised_value: this.localOrder.appraised_value,
                county_id: this.localOrder.county_id,
                customFields: this.localOrder.customFields,
                disclosedFee: this.localOrder.disclosedFee,
                fha: this.localOrder.fha,
                lenderLoanNumber: this.localOrder.lenderLoanNumber,
                loanPurpose: this.localOrder.loanPurpose,
                occupancy: this.localOrder.occupancy,
                priority: this.localOrder.priority,
                propertyType: this.localOrder.propertyType,
                purchase_price: this.localOrder.purchasePrice,
                residence_type: this.localOrder.residence_type,
            }).then(() => {
                if (!order.value) return
                order.value.address = this.localOrder.address
                order.value.addressFormatted = this.localOrder.address.street + ', ' + this.localOrder.address.city + ', ' + this.localOrder.address.state + ', ' + this.localOrder.address.zip
                order.value.lenderLoanNumber = this.localOrder.lenderLoanNumber
                order.value.fha = this.localOrder.fha
                order.value.purchasePrice = this.localOrder.purchasePrice
                order.value.occupancy = this.localOrder.occupancy
                order.value.loanPurpose = this.localOrder.loanPurpose
                order.value.priority = this.localOrder.priority
                order.value.disclosedFee = this.localOrder.disclosedFee
                if(order.value.incremental?.details) {
                    order.value.incremental.details.details.duCaseFileId = this.localOrder.customFields.duCaseFileId
                    order.value.incremental.details.details.lpaKey = this.localOrder.customFields.lpaKey
                }
                refreshOrder().then(() => {
                    Object.assign(this.$data, reset())
                    showSnackbar('Order updated successfully.')
                })
            }).catch(() => {
                this.loading = false
            })
        },

        validatePrice(price) {
          if(this.localOrder.loanPurpose != 'Purchase') return true

          if(price == null) return false
          if(typeof(price) != 'string') price = String(price)
          if (price.length < 1) return false

          if(!/^[0-9.,]+$/.test(price)) return false
          const formatted = Intl.NumberFormat('US', {
            style: 'decimal',
          }).format(parseFloat(price.replaceAll(',', '')))

          if (formatted == 'NaN') return false
          if (!/^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:,\d{3})?)*((?=.*\d)(?:\.\d\d){1}?){0,1}$/gm.test(formatted)) return false

          this.localOrder.purchasePrice = formatted

          return true
        }
    }
})
</script>
