<template>
    <v-dialog v-model="dialog" max-width="600px">
        <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-card>
                <v-form>
                    <v-card-title>
                        <span class="headline">Edit ACH</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-row>
                                <v-col cols="12">
                                    <a-text-field
                                        v-model="check.identifier"
                                        label="ACH Identifier"
                                        name="ach identifier"
                                    />
                                    <a-date-picker
                                        key="date"
                                        v-model="check.date"
                                        rules="required"
                                        label="Payment Date"
                                        name="payment date"
                                        color="secondary"
                                    />
                                    <a-text-field
                                        v-model="check.amount"
                                        rules="required|min_value:0"
                                        label="ACH Amount"
                                        name="amount"
                                        prepend-icon="$vuetify.icons.dollarSign"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-alert v-show="error != null" type="error" text>
                                        {{ error }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
                        <v-btn color="primary" depressed :loading="loading" :disabled="invalid" @click="saveChanges">
                            Save Changes
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { refreshOrder } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'

function reset() {
    return {
        dialog: false,
        loading: null,
        error: null,
        check: {
            id: null,
            amount: null,
            date: null,
            identifier: null,
        },
    }
}

/**
 * @name EditACH
 * Dialog in AMC Order Screen that allows user to edit an ach payment
 */

export default {
    components: {
        ValidationObserver,
    },
    data() {
        return reset()
    },
    computed: {},
    methods: {
        /**
         * Opens dialog and sets check details
         */
        open(item) {
            this.check.id = item.id
            this.check.date = AscentHelper.formatIsoDate(item.payment_date, 'yyyy-MM-dd', false)
            this.check.amount = item.amount
            this.check.identifier = item.details.check
            this.amount = item.amount
            this.dialog = true
        },
        /**
         * Saves check details. Updates order after saving.
         */
        saveChanges() {
            this.error = null
            this.loading = true
            this.$axios
                .patch('/v1/order/' + order.value.id + '/payment/' + this.check.id, { check: this.check })
                .then((response) => {
                    if (response.data.result === 'error') {
                        this.error = response.data.message
                        return
                    }

                    Object.assign(this.$data, reset())
                    showSnackbar('Payment updated successfully.')
                    refreshOrder()
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>
