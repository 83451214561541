






















import { defineComponent, PropType, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { CancelTokenSource } from 'axios'
import { SubLender } from '@/types'

export default defineComponent({
    props: {
        appendOuter: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array as PropType<SubLender[]>,
            default: () => {
                return []
            },
        },
        client_id: {
            type: Number,
            default: 0,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        search(val) {
            val && val.length > 4 && this.searchSubLender(val)
        },
        items(val) {
            this.localItems.value = val
            this.key.value = this.key.value + 1
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            localItems: ref([] as SubLender[]),
            cancelToken: undefined as undefined | CancelTokenSource,
            key: ref(1 as number),
        }
    },
    mounted() {
        this.localItems.value = this.items
    },
    methods: {
        searchSubLender(search: string) {
            if (this.cancelToken) {
                this.cancelToken.cancel('Cancelled')
            }
            this.loading = true
            this.cancelToken = $axios.CancelToken.source()
            this.$axios
                .post('/v1/search', {
                    val: search,
                    type: 'sublender',
                    object: true,
                    limit: 3,
                    where: this.client_id,
                })
                .then(({ data }) => {
                    this.localItems.value = data
                    this.loading = false
                })
        },
    },
})
