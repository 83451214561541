

















































import { defineComponent } from '@vue/composition-api'
import AssignTable from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/AssignTable.vue'
import {
    activeTab,
    getPanel,
    currentTab,
    panelTabs,
    trainee,
    closedPanel,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AssignDialog from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignDialog.vue'
import ClosedPanelOverlay from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/ClosedPanelOverlay.vue'
import { order } from '@/plugins/order/Order'

/**
 * @name AssignPanels
 * Shows all assigned panels for branch, lender, primary, and secondary if panels are available on order
 *
 * @SetupMethod setTab - sets current tab to the value of the tab clicked on
 */

export default defineComponent({
    props: {
        cannotAssign: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const setTab = (value: string) => {
            currentTab.value = value
        }

        return {
            panelTabs,
            activeTab,
            trainee,
            currentTab,
            getPanel,
            setTab,
            order,
            closedPanel,
        }
    },
    components: {
        AssignTable,
        AssignDialog,
        ClosedPanelOverlay,
    },
})
