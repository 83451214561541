






































import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import OfficeExpensesDetails from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/Partials/OfficeExpensesDetails.vue'
import OfficeRevenueDetails from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/Partials/OfficeRevenueDetails.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import { netRevenue } from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/OfficeOrderFees.vue'

export default defineComponent({
    name: 'ClientFeesSummary',
    components: {
        OfficeExpensesDetails,
        OfficeRevenueDetails,
    },
    setup() {
        const grossExpenses = computed(() => {
            if (
                !order.value ||
                !order.value.incremental ||
                !order.value.incremental.total ||
                !order.value?.incremental.total.appraiser_value
            )
                return 0
            const innovationFee = orderProducts.value.find((elem) => elem.product_name === 'Innovation Fee')
            if (innovationFee)
                return (
                    parseFloat(order.value?.incremental?.total?.appraiser_value as string) -
                    (innovationFee.appraiser_value as number)
                )
            return order.value?.incremental?.total.appraiser_value
        })

        const grossMarginDollar = computed(() => {
            if (!order.value || !order.value.incremental || !order.value.incremental.total) return 0
            return (netRevenue.value as number) - (grossExpenses.value as number)
        })

        const grossMarginPercentage = computed(() => {
            if (
                !order.value ||
                !order.value.incremental ||
                !order.value.incremental.total ||
                !order.value.incremental.total.amc_value
            )
                return 0
            return Math.floor(
                ((parseFloat(order.value?.incremental?.total?.total as string) - (grossExpenses.value as number)) /
                    (netRevenue.value as number)) *
                    100,
            )
        })
        return {
            order,
            grossExpenses,
            grossMarginDollar,
            grossMarginPercentage,
            netRevenue,
        }
    },
})
