








import { defineComponent, PropType } from '@vue/composition-api'
import { AppraiserPanel } from '@/types'

export default defineComponent({
    props: {
        /**
         * Specific appraiser in the AppraiserPanel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
})
