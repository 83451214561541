































































































































































import { defineComponent, ref } from '@vue/composition-api'
import { AppraiserPanel, AppraiserOptionPanel, OrderModel } from '@/types'
import { appraiserStatus, appraiserStatusColor } from '@/pages/Appraiser/Appraiser'
import AppraiserNamePartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AppraiserNamePartial.vue'
import EmailPhoneAction from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/EmailPhoneAction.vue'
import { order } from '@/plugins/order/Order'
import JaroAssignPanelsLicenseTagPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsLicenseTagPartial.vue'
import axios from '@/plugins/axios'
import { openAppraiserOrdersDialog } from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/ViewAppraiserOrdersDialog.vue'

export default defineComponent({
    components: {
        AppraiserNamePartial,
        EmailPhoneAction,
        JaroAssignPanelsLicenseTagPartial,
    },
    props: {
        item: {
            type: Object as () => AppraiserPanel,
            required: true,
        },
    },
    setup(props) {
        const showCard = ref(false)
        const allOtherOrders = ref(0)
        const appraiserOrders = ref([] as OrderModel[])

        const getStatsClasses = () => {
            return 'text-body-3 accentBlackdarken1 font-weight-bold mr-3'
        }

        const stripTags = (value: string) => {
            const div = document.createElement('div')
            div.innerHTML = value
            return div.textContent || div.innerText || ''
        }

        const getOption = <T extends keyof AppraiserOptionPanel>(key: T, notFound: string) => {
            const result = props.item.appraiser.options?.find((item) => Object.prototype.hasOwnProperty.call(item, key))
            if (result) {
                return result[key] as string
            }
            return notFound ?? ''
        }
        const getColor = (item: string, success?: string) => {
            switch (item) {
                case 'asc':
                    return props.item.appraiser.roster?.status === success ? 'success' : ''
                case 'hud':
                    return props.item.appraiser.hud?.aqb === success ? 'success' : ''
                case 'license':
                    return props.item.appraiser.license_status === success ? 'success' : ''
                case 'insurance':
                    return props.item.appraiser.insurance_status === success ? 'success' : ''
                case 'total_orders':
                    return 'secondary'
                case 'stagger':
                    return getOption('stagger', '0') === '0' ? '' : 'errorlighten4'
                default:
                    return ''
            }
        }

        const getContent = (item: string, success?: string) => {
            switch (item) {
                case 'asc':
                    return props.item.appraiser?.roster?.status === success ? 'Active' : 'Not Active or Not Linked'
                case 'hud':
                    return props.item.appraiser.hud?.aqb === success ? 'Active' : 'Not Active or Not Linked'
                case 'license':
                    return props.item.appraiser.license_status ?? 'Inactive'
                case 'insurance':
                    return props.item.appraiser.insurance_status ?? 'Inactive'
                case 'total_orders':
                    return props.item.open
                case 'assignment_notes':
                    return stripTags(getOption('assign_notes', 'No assignment notes.'))
                case 'stagger':
                    return getOption('stagger', '0')
                default:
                    return ''
            }
        }

        const open = async () => {
            showCard.value = true
            if (props.item.appraiser.id) {
                await axios
                    .get('/v1/appraiser/' + props.item.appraiser.id + '/orders')
                    .then((response) => {
                        allOtherOrders.value = response.data.totalCount ?? 0
                        appraiserOrders.value = response.data.orders ?? []
                    })
                    .catch(() => {
                        return
                    })
            }
        }

        const close = () => {
            showCard.value = false
            allOtherOrders.value = 0
            appraiserOrders.value = []
        }

        return {
            showCard,
            appraiserStatus,
            appraiserStatusColor,
            getColor,
            getContent,
            getStatsClasses,
            order,
            open,
            openAppraiserOrdersDialog,
            allOtherOrders,
            appraiserOrders,
            close,
        }
    },
})
