<template>
    <v-dialog v-model="dialog" width="700">
        <ACard title="Add Payment">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="type"
                                :items="['Check', 'Refund Check', 'ACH', 'Credit Card']"
                                label="Payment Type"
                                attach
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="type === 'Check'">
                        <v-col cols="12">
                            <a-text-field
                                key="check"
                                v-model="check.number"
                                rules="required"
                                label="Check Number"
                                name="check"
                                color="secondary"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                key="from"
                                v-model="check.name"
                                rules="required"
                                label="From Name"
                                name="from"
                                color="secondary"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-date-picker
                                key="date"
                                v-model="check.date"
                                rules="required"
                                label="Payment Date"
                                name="payment date"
                                color="secondary"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                key="amount"
                                v-model="check.amount"
                                rules="required"
                                label="Amount"
                                name="amount"
                                color="secondary"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="type === 'ACH'">
                        <v-col cols="12">
                            <a-text-field
                                key="check"
                                v-model="check.number"
                                label="Identifier"
                                name="identifier"
                                color="secondary"
                                hint="Optional"
                                persistent-hint
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-date-picker
                                key="date"
                                v-model="check.date"
                                rules="required"
                                label="Payment Date"
                                name="payment date"
                                color="secondary"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                key="amount"
                                v-model="check.amount"
                                rules="required"
                                label="Amount"
                                name="amount"
                                color="secondary"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="type === 'Refund Check'">
                        <v-col cols="12">
                            <a-text-field
                                key="refund check"
                                v-model="refund.number"
                                rules="required"
                                label="Check Number"
                                name="refund check"
                                color="secondary"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-date-picker
                                key="date"
                                v-model="refund.date"
                                rules="required"
                                label="Payment Date"
                                name="payment date"
                                color="secondary"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                key="refund amount"
                                v-model="refund.amount"
                                rules="required"
                                label="Amount"
                                name="refund amount"
                                color="secondary"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="type === 'Credit Card'">
                        <v-col cols="12">
                            <a-text-field
                                key="card amount"
                                v-model="card.amount"
                                data-vv-scope="Credit Card"
                                rules="required"
                                label="Amount"
                                name="card amount"
                                color="secondary"
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                v-model="cardEmail"
                                label="Email for Receipt"
                                color="secondary"
                                rules="email"
                                hint="Email for receipt is optional"
                                persistent-hint
                            />
                        </v-col>

                        <v-col cols="12">
                            <p>Choose which card and amount to charge.</p>
                            <a-select-field
                                v-model="card.selected"
                                :items="cards"
                                item-text="cc_label"
                                return-object
                                label="Credit Card Last Four"
                                name="credit card"
                                outlined
                                rules="required"
                                @change="resetError"
                            />
                            <v-alert v-show="error != null" color="error">
                                {{ error }}
                            </v-alert>
                        </v-col>
                    </v-row>

                    <CreditCard
                        v-if="card.selected.cc_label === 'New Card' && type === 'Credit Card'"
                        ref="credit"
                        :show="false"
                        @card-added="runCard"
                        @loading="loading"
                        @error="caughtError"
                        :gateway="$ascent.customerFeatureEnabled('gateway', 'nexio')"
                        :disallow="$ascent.customerFeatureEnabled('creditCard.disallow', [])"
                        :show-address="$ascent.customerFeatureEnabled('showCreditCardAddress')"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="link" text @click="dialog = false">Cancel</v-btn>
                    <v-btn v-if="type !== 'Credit Card'" color="primary" :loading="adding" @click="handleSubmit(save)">
                        Add Payment
                    </v-btn>
                    <v-btn
                        v-if="type === 'Credit Card'"
                        color="primary"
                        :loading="adding"
                        @click="handleSubmit(submit)"
                    >
                        Add Payment
                    </v-btn>
                </v-card-actions>
            </ValidationObserver>
        </ACard>
    </v-dialog>
</template>

<script>
import CreditCard from '@/components/General/CreditCard'
import { DateTime } from 'luxon'

function initialData() {
    return {
        dialog: false,
        adding: false,
        error: null,
        type: 'Check',
        check: {
            number: null,
            amount: null,
            name: null,
            date: DateTime.local().toISODate(),
        },
        refund: {
            number: null,
            amount: null,
            date: DateTime.local().toISODate(),
        },
        card: {
            amount: null,
            selected: {
                cc_label: 'New Card',
            },
        },
        cardEmail: '',
        cards: [
            {
                cc_label: 'New Card',
            },
        ],
    }
}

import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'

/**
 * @name AddClientPaymentDialog
 * Dialog in Order Screen where amc user can go in and add a client payment onto the order.
 *
 * @InitialData error -  error to be shown to user if something goes wrong adding a payment onto an order
 * @InitialData type - type of payment being added. 3 types are Check, Refund Check, and Credit Card
 * @InitialData check - when type is Check, this check object will be the fields required. Number is check number. Amount is check amount. Name is name on check, and date is the date they want to add on the check
 * @InitialData refund - Object updated when type is Refund Check. Has check number and amount on the object.
 * @InitialData card - Object updated when type is Credit Card. Has amount and selected card.
 * @InitialData cards - cards that user can charge.
 */

export default {
    components: {
        CreditCard,
        ValidationObserver,
    },
    data() {
        return initialData()
    },
    mounted() {
        this.cards = this.cards.concat(
            order.value.incremental.cards.filter((elem) => ['Nexio', 'Tokenex', 'Authorize'].includes(elem.type)),
        )
    },
    methods: {
        /**
         * Opens dialog
         */
        open() {
            this.dialog = true
        },
        /**
         * Resets the error
         */
        resetError() {
            this.error = null
        },
        /**
         * Saves the payment. Sends type, check, and refund to add to the order. Resets dialog and refreshes order after saved.
         */
        save() {
            this.adding = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/payment', {
                    type: this.type,
                    check: this.check,
                    refund: this.refund,
                })
                .then(() => {
                    this.adding = false
                    this.dialog = false
                    this.type = 'Check'
                    this.check = {
                        number: null,
                        amount: null,
                        name: null,
                        date: DateTime.local().toISODate(),
                    }
                    this.refund = {
                        number: null,
                        amount: null,
                        date: DateTime.local().toISODate(),
                    }
                    this.$refs.form.reset()
                    refreshOrder()
                    showSnackbar('Payment Recorded Successfully')
                })
                .catch(() => {
                    this.adding = false
                    this.dialog = false
                    showSnackbar("Oops! Something didn't work. Try again!")
                })
        },
        /**
         * Catches error and adds error message
         */
        caughtError() {
            this.adding = false
            this.error = "Oops! Something didn't work. Try again!"
        },
        /**
         * Updates the adding/loading variable in the dialog
         * @param {boolean} val - boolean that changes the loading status of the dialog
         */
        loading(val) {
            this.adding = val
        },
        /**
         * Grabs and charges credit card. If card is a new card, opens up a dialog to add a new card. If it isn't a new card, automatically charges the card.
         */
        submit() {
            if (this.card.selected.cc_label === 'New Card') {
                this.adding = true
                this.$refs.credit.addCard()
            } else {
                this.chargeCard()
            }
        },
        /**
         * Runs card manually. Refreshes order after card is ran
         * @param response - Credit card that is emitted from credit card components.
         */
        runCard(response) {
            const postData = {
                response: response,
                amount: this.card.amount,
            }
            if (this.cardEmail) postData.email = this.cardEmail
            this.$axios
                .post('/v1/order/' + order.value.id + '/payment/manual-charge', postData)
                .then((response) => {
                    if (response.data.result) {
                        showSnackbar('Payment Recorded Successfully')
                        this.adding = false
                        this.dialog = false
                        refreshOrder()
                    } else if (response.data.result === false) {
                        this.error = response.data.message
                        this.adding = false
                    }
                })
                .catch(() => {
                    this.adding = false
                    this.dialog = false
                    showSnackbar("Oops! Something didn't work. Try again!")
                })
        },
        /**
         * Charges selected credit card. Refreshes order after submission.
         */
        chargeCard() {
            this.adding = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/payment/' + this.card.selected.id + '/charge', {
                    amount: this.card.amount,
                })
                .then((response) => {
                    if (response.data.result) {
                        refreshOrder()
                        showSnackbar(
                            'Card Charged Successfully for ' +
                                this.card.amount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }),
                        )
                        ;(this.adding = false), (this.dialog = false)
                    } else {
                        this.error = response.data.message
                        this.adding = false
                    }
                })
                .catch(() => {
                    this.adding = false
                    showSnackbar("Oops! Something didn't work. Try again!")
                })
        },
    },
}
</script>
