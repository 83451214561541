

































import { defineComponent, computed, ComputedRef } from '@vue/composition-api'
import { Contact } from '@/types'
import { order } from '@/plugins/order/Order'

/**
 * @name AppraiserOrderContacts
 * Order contacts on the appraiser screen. Ordered from borrowers and other contacts, and then filtered so entry contacts are on top
 *
 * @SetupComputed orderedContacts - the order of the contacts on the order screen for the appraiser. Filtered by borrowers and then other contacts.
 */

export default defineComponent({
    setup() {
        const orderedContacts: ComputedRef<Contact[] | undefined> = computed(() => {
            if (!order.value || !order.value?.incremental) return
            const contacts = [...order.value.incremental.borrowers, ...order.value.incremental.contacts]

            const entryContacts = contacts.filter((contact: Contact) => contact.entry)
            const nonEntryContacts = contacts.filter((contact: Contact) => !contact.entry)

            return [...entryContacts, ...nonEntryContacts]
        })

        return {
            orderedContacts,
        }
    },
})
