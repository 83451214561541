






































































































import { computed, defineComponent, ref, watch, nextTick } from '@vue/composition-api'
import FacetSearch, { clearAdvFilters } from '@/pages/AMC/Settings/FacetSearch.vue'
import {
    tableColumns,
    AdvSearchArray,
    AdvSearchData,
    searchText,
    ordersLoaded,
    updateSearchTable,
    orderStartDate,
    completedEndDate,
    orderEndDate,
    completedStartDate,
    expanded,
} from '@/components/OrderTable/Classes/OrderTable'
import { AscentColumnModel } from '@/helpers/AscentDataAdapter'
import DatePicker from '@/components/General/DatePicker.vue'
import { order } from '@/plugins/order/Order'
import { filterItems, valueArray, keyArray } from '@/pages/AMC/Settings/OrderSearchFilter.vue'

export const lastUpdateVar = ref('')
export const collapseAll = () => {
    if (expanded.value.length < 0) return
    expanded.value = []
}
export const clearSearchFilters = () => {
    valueArray.value = []
    AdvSearchArray.value = {}
    lastUpdateVar.value = ''
    searchText.value = ''
    keyArray.value = []
    clearAdvFilters()
    updateSearchTable()
    collapseAll()
}
export default defineComponent({
    name: 'AdvancedSearch',
    components: { DatePicker, FacetSearch },
    props: {
        searchType: {
            type: String,
            default: 'orderSearch',
        },
    },
    setup(props, { root }) {
        const orderStartDateMenu = ref(false)
        const orderEndDateMenu = ref(false)
        const endDateAfterStart = computed(() => {
            if (completedStartDate.value) {
                if (!order.value) return
                if (new Date(completedEndDate.value) <= new Date(completedEndDate.value)) return false
            }
            return true
        })

        const getTitle = (key: string) => {
            if (key === 'tagFilter') return 'Tag'
            if (key === 'team') return 'Team'
            if (key === 'rep') return 'CSS'
            if (key === 'address.state') return 'State Address'
            if (key === 'countyName') return 'County Name'
            if (key === 'state') return 'State'
            if (key === 'clientName') return 'Client Name'
            if (key === 'officerName') return 'Officer Name'
            if (key === 'branchName') return 'Branch Name'
            if (key === 'lenderName') return 'Lender Name'
            if (key === 'lenderFormName') return 'Lender Form Name'
            if (key === 'sublenderName') return 'Sublender Name'
            if (key === 'appraiserName') return 'Vendor Name'
            if (key === 'appraiserNames') return 'Order Appraisers'
            if (key === 'appraiserCompany') return 'Appraiser Company'
            if (key === 'paymentType') return 'Payment Type'
            if (key === 'assigner') return 'Assigner'
            if (key === 'lead') return 'Lead'
            if (key === 'order_type') return 'Order Type'
            if (key === 'propertyType') return 'Property Type'
            if (key === 'residence_type') return 'Residence Type'

            const column = (tableColumns.value as AscentColumnModel[]).find((elem: AscentColumnModel) => {
                return (elem.field as string) === key
            })
            if (!column) return key
            return column.headerText
        }

        const openAll = () => {
            if (expanded.value.length > 0) return
            expanded.value = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        }

        const updateSearch = (value: string[], key: string) => {
            lastUpdateVar.value = key
            const filteredValues = value.filter((elem) => {
                const splitValues = elem.split('=')
                return splitValues[0] == key
            })
            lastUpdateVar.value = key
            AdvSearchArray.value[key] = filteredValues

            value.map((elem) => {
                if (elem === "orderStatus='All Pending'") {
                    AdvSearchArray.value[key] = [
                        "orderStatus='New Order'",
                        "orderStatus='Accepted'",
                        "orderStatus='Appointment Set'",
                        "orderStatus='Assigned'",
                        "orderStatus='Hold'",
                        "orderStatus='Inspected'",
                        "orderStatus='Payment Hold'",
                        "orderStatus='Pending Cancellation'",
                    ]
                }
                if (elem === "orderStatus='All Completed'") {
                    AdvSearchArray.value[key] = [
                        "orderStatus='Completed'",
                        "orderStatus='QC Review'",
                        "orderStatus='QC Revision'",
                        "orderStatus='UW Request'",
                        "orderStatus='UW Revision'",
                        "orderStatus='Reconsideration Review'",
                        "orderStatus='Reconsideration of Value'",
                    ]
                }
            })
            filterItems(AdvSearchArray.value[key], AdvSearchArray.value)
            updateSearchTable()
        }

        const setIdForAppraiserHistory = () => {
            if (props.searchType !== 'appraiserHistory') return
            if (
                !AdvSearchArray.value['appraiser_id'] ||
                (AdvSearchArray.value['appraiser_id'] && AdvSearchArray.value['appraiser_id'].length === 0)
            ) {
                updateSearch(["appraiser_id='" + root.$route.params.id + "'"], 'appraiser_id')
            }
        }

        watch(AdvSearchArray, async () => {
            if (!ordersLoaded.value) {
                await nextTick().then(() => {
                    setIdForAppraiserHistory()
                })
            }
            setIdForAppraiserHistory()
        })

        const shouldShowFacet = (title: string | number) => {
            if (props.searchType !== 'appraiserHistory') return true

            return !['appraiser_id', 'appraiserName', 'appraiserNames', 'appraiserCompany'].includes(title.toString())
        }

        const refreshSearch = () => {
            updateSearchTable()
        }

        const resetFilter = () => {
            lastUpdateVar.value = ''
            updateSearchTable()
            clearSearchFilters()
        }

        return {
            AdvSearchData,
            updateSearch,
            getTitle,
            expanded,
            collapseAll,
            openAll,
            orderStartDate,
            orderEndDate,
            completedStartDate,
            completedEndDate,
            refreshSearch,
            endDateAfterStart,
            AdvSearchArray,
            resetFilter,
            lastUpdateVar,
            orderStartDateMenu,
            keyArray,
            orderEndDateMenu,
            ordersLoaded,
            shouldShowFacet,
        }
    },
    methods: {
        allowedDates(val: string) {
            if (!orderStartDate.value) return
            return new Date(val).getTime() >= new Date(orderStartDate.value).getTime()
        },
    },
})
