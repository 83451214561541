





































































import { defineComponent, PropType, UnwrapRef } from '@vue/composition-api'
import { Note } from '@/types'
import { changeRead } from '@/plugins/Notes'
import { state } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderNotes/CustomerNoteMenu.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    name: 'CustomerOrderNote',
    emits: ['click:note-details'],
    props: {
        note: Object as PropType<Note>,
    },
    setup(props, { emit }) {
        const getOffset = (note: Note) => {
            switch (note.source) {
                case 'Appraiser':
                case 'Prior Appraiser':
                case 'Unknown Appraiser':
                    return 6
                case 'Client':
                case 'Borrower':
                    return 0
                case 'AMC':
                case 'JaroBot':
                case 'Desk':
                    if (['Borrower', 'Client', 'Client Audit'].indexOf(note.target) > -1) return 0
                    if (['Appraiser', 'Prior Appraiser', 'Appraiser Audit'].indexOf(note.target) > -1) return 6
                    return 3
                case 'AUDIT':
                case 'Unknown':
                    return 3
                default:
                    return 0
            }
        }

        const messageClass = (note: Note) => {
            switch (note.source) {
                case 'Appraiser':
                case 'Prior Appraiser':
                case 'Unknown Appraiser':
                    return 'note-appraiser'
                case 'Client':
                case 'Borrower':
                    return 'note-client'
                case 'AMC':
                case 'JaroBot':
                case 'Desk':
                    if (['AMC', 'Payment', 'Review'].indexOf(note.target) > -1) return 'note-internal'
                    if (['Client', 'Appraiser', 'Borrower', 'Prior Appraiser'].indexOf(note.target) > -1)
                        return 'note-customer'
                    return 'note-audit'
                default:
                    return 'note-audit'
            }
        }

        const openNoteMenu = (e: MouseEvent, note: Note) => {
            e.preventDefault()
            state.noteMenuX = e.clientX
            state.noteMenuY = e.clientY
            state.activeNote = note
            state.showNoteMenu = true
        }

        const removeTag = (note: UnwrapRef<Note | undefined>) => {
            confirm('Remove Tag?', 'Are you sure you want to remove this tag').then((confirm) => {
                if (confirm) {
                    $axios
                        .delete('/v1/note-tag/' + note?.tag?.id)
                        .then(() => {
                            if (note !== undefined) note.tag = undefined

                            state.showNoteMenu = false
                            showSnackbar('Tag Removed Successfully')
                        })
                        .catch(() => {
                            showError('Oops, something went wrong!')
                        })
                }
            })
        }

        const showAuditNoteDialog = (note: Note) => {
            emit('click:note-details', note)
        }

        return {
            getOffset,
            messageClass,
            changeRead,
            openNoteMenu,
            removeTag,
            showAuditNoteDialog,
        }
    },
})
