

























































import { defineComponent, ref, onMounted, PropType, computed, watch, onUnmounted } from '@vue/composition-api'
import AppraiserTeamSelect from '@/components/General/AutoCompletes/AppraiserTeamSelect.vue'
import { Appraiser, TeamAllocation, AppraiserRoleName } from '@/types'
import { FEES_BY_ROLE_NAME } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/CalcAppraiserProductFee'
import { GET_APPRAISER_FEE } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { order } from '@/plugins/order/Order'
import Vue from 'vue'

export default defineComponent({
    components: {
        AppraiserTeamSelect,
    },
    props: {
        type: {
            type: String,
            default: '',
        },
        role: {
            type: String as PropType<AppraiserRoleName>,
            default: '',
        },
        appraiser: {
            type: Object as PropType<TeamAllocation>,
            default: () => {
                return {
                    appraiser_id: 0,
                    fee: 0,
                    type: '',
                }
            },
        },
        primaryAppraiser: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const localAppraiser = ref({ type: props.type, appraiser_id: 0, fee: '' } as TeamAllocation)
        const loading = ref(false)
        const checked = ref(false)
        const checkboxChecked = ref(false)
        const availableAppraisers = ref([] as Appraiser[])

        const updateAppraiser = async (val: number) => {
            if (val != props.primaryAppraiser?.appraiser_id) {
                checked.value = false
                checkboxChecked.value = false
            }
            loading.value = true
            FEES_BY_ROLE_NAME.value[props.role] = { vendor: 0, total: 0 }
            localAppraiser.value.fee = await GET_APPRAISER_FEE(props.role, val)
            localAppraiser.value.appraiser_id = val
            if (
                localAppraiser.value.role === 'Primary' &&
                (!localAppraiser.value.fee || localAppraiser.value.fee === 0)
            )
                localAppraiser.value.fee = 0
            emit('input', localAppraiser.value)
            loading.value = false
        }

        const updateAppraiserFee = (appraiserFee: string | number) => {
            emit('updatedFee', appraiserFee, localAppraiser.value.role)
            emit('input', localAppraiser.value)
        }

        const syncAppraisers = async (appraisers: Appraiser[]) => {
            availableAppraisers.value = appraisers
            if (localAppraiser.value.appraiser_id)
                await GET_APPRAISER_FEE(props.role, localAppraiser.value.appraiser_id)
        }

        const emitCheck = (e: boolean) => {
            if (!props.primaryAppraiser) return
            if (e) localAppraiser.value.appraiser_id = props.primaryAppraiser.appraiser_id
            if (!e) localAppraiser.value.appraiser_id = 0
            checked.value = !checked.value
            updateAppraiser(props.primaryAppraiser.appraiser_id)
            emit('input', localAppraiser.value)
        }

        const disableCheck = computed(() => {
            if (!props.primaryAppraiser?.appraiser_id) return true
            if (!availableAppraisers.value.find((elem) => elem.id == props.primaryAppraiser?.appraiser_id)) return true
            return false
        })

        const onRefreshInfoAppraiser = () => {
            if (!props.primaryAppraiser) return
            if (localAppraiser.value.appraiser_id) updateAppraiser(localAppraiser.value.appraiser_id)
        }

        watch(disableCheck, () => {
            if (disableCheck.value) {
                checkboxChecked.value = false
                checked.value = false
            }
        })

        onMounted(() => {
            localAppraiser.value = props.appraiser
        })

        onUnmounted(() => {
            Vue.set(FEES_BY_ROLE_NAME.value, props.role, { vendor: 0, total: 0 })
            localAppraiser.value = {}
        })

        const feeDescription = computed(() => {
            const total = FEES_BY_ROLE_NAME.value[props.role].total
            const vendor = FEES_BY_ROLE_NAME.value[props.role].vendor

            return total || vendor ? `$${vendor} of $${total}` : ''
        })

        return {
            localAppraiser,
            updateAppraiser,
            emitCheck,
            loading,
            syncAppraisers,
            availableAppraisers,
            checked,
            disableCheck,
            checkboxChecked,
            order,
            updateAppraiserFee,
            onRefreshInfoAppraiser,
            feeDescription,
        }
    },
})
