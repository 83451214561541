






















import EditOrderForm from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles/EditOrderForm.vue'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'
import { defineComponent } from '@vue/composition-api'

/**
 * @name OrderPdf
 * The custom order pdf the backend generates. Includes info about the order that include lender directives for the appraiser
 */

export default defineComponent({
    setup() {
        return {
            user,
            order,
        }
    },
    components: { EditOrderForm },
    methods: {
        editOrderForm() {
            const editForm = this.$refs.editForm as Vue & { open: () => void }
            editForm.open()
        },
    },
    computed: {
        /**
         * The pdf url needed to open up the order pdf
         */
        pdfUrl(): string {
            if (!this.order || !this.order.incremental) return ''
            if (this.order.incremental && this.order.incremental.propertyType == 'Commercial')
                return `${process.env.VUE_APP_PDF}/pdf/commercial-order/${this.order.incremental.token}`
            return `${process.env.VUE_APP_PDF}/pdf/order/${this.order.incremental.token}`
        },
        downloadUrl(): string {
            if (!this.order || !this.order.incremental) return ''
            return `${process.env.VUE_APP_PDF}` + '/pdf-download/pdf/' + this.order.incremental.token
        },
    },
})
