import { ref } from '@vue/composition-api'
import { Appraiser, Offices } from '@/types'

export interface Update {
    appraiser: Appraiser | null
    office: Offices
}

export const backgroundLegalText = ref(
    '*The background check is conducted by a third-party provider, Sterling. Jaro neither conducts nor stores any data from these background checks.',
)
export const backgroundLegalInfo = ref(
    '<p>In order to be eligible for certain Interior Alternative Products, vendors may choose to undergo a background check*. This check serves to ensure the safety and security of our clients and market partners. While undergoing a background check can enhance your opportunities for more work, it is not mandatory. </p> <p>By opting for this check, you not only enhance your work opportunities but also contribute to the trust and confidence of our clients and market partners.</p>',
)
export const appraiser = ref({
    id: 0,
})
export const appraiserOffice = ref(undefined as Offices | undefined)

export const setAppraiser = (update: Update): void => {
    if (update['appraiser']) {
        appraiser.value = update['appraiser']
    }
    if (update['office']) {
        appraiserOffice.value = update['office']
    }
}

export const appraiserStatus = (ascentStatus: string, amcStatus: string) => {
    if (amcStatus == 'Terminated') return 'Terminated'
    if (ascentStatus === 'Active' && amcStatus === 'Active') return 'Active'
    return ascentStatus + '-(' + amcStatus + ')'
}

export const appraiserStatusColor = (ascentStatus: string, amcStatus: string) => {
    if (amcStatus === 'Terminated') return 'red darken-4'
    if (ascentStatus === 'Active' && amcStatus === 'Active') return 'success'
    if (ascentStatus === 'Vacation' && amcStatus === 'Active') return 'purple darken-4'
    if (amcStatus === 'Active') return 'teal darken-3'

    if (ascentStatus !== 'Active' || amcStatus !== 'Active') return 'warning'

    return 'deep-purple'
}
