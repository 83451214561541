



































































































































import { expMonths, expYears, masks } from '@/plugins/Data'
import axios from 'axios'
import { TokenexReponse } from '@/types/creditCard'
import { defineComponent } from '@vue/composition-api'
import { mask } from 'vue-the-mask'
import AscentHelper from '@/helpers/ascent-helper'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'

function reset() {
    return {
        payment: {
            ccnumber: '',
            expmonth: '',
            expyear: '',
            cvv: '',
            name: '',
        },
        loading: false,
        error: false,
        pubRSA: '',
        errorMessage: '',
        address: {
            street: '',
            substreet: '',
            city: '',
            state: '',
            zip: '',
        },
    }
}
export default defineComponent({
    name: 'TokenEx',
    directives: { mask },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        help: {
            type: Boolean,
            default: true,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        clearFields: {
            type: Boolean,
            default: true,
        },
        customerId: {
            type: Number,
            default: null,
        },
        disallow: {
            type: Array,
            default: () => [],
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        StateSelect,
    },
    setup() {
        return { expMonths, expYears, masks }
    },
    data() {
        return reset()
    },
    mounted() {
        this.setRsaKey()
        const subdomain = process.env.VUE_APP_ENV === 'production' ? 'api' : 'test-api'
        this.injectScript('https://' + subdomain + '.tokenex.com/inpage/js/TokenEx-Lite.js')
    },
    methods: {
        injectScript(source: string, body?: string) {
            return new Promise(function (resolve) {
                const scriptTag = document.createElement('script')

                scriptTag.type = 'text/javascript'
                scriptTag.async = true
                if (source !== '') {
                    scriptTag.src = source
                }

                if (body) {
                    const scriptTagContents = document.createTextNode(body)
                    scriptTag.appendChild(scriptTagContents)
                }

                document.head.appendChild(scriptTag)

                scriptTag.addEventListener('load', resolve)
            })
        },
        addCard() {
            this.errorMessage = ''
            const allowed = AscentHelper.checkAllowedCardTypes(this.disallow, this.payment.ccnumber)
            if (!allowed.allowed) {
                this.errorMessage = allowed.message
                this.$emit('error', { error: true, result: false, message: allowed.message })
                return
            }
            if (!this.pubRSA) return
            this.loading = true
            const cipherText = window.TxEncrypt(this.pubRSA, this.payment.ccnumber.replace(/\s+/g, ''))
            const cipherCVV = window.TxEncrypt(this.pubRSA, this.payment.cvv)
            this.encryptCard(cipherText, cipherCVV)
            this.loading = false
        },
        encryptCard(cipherText: string, cipherCVV: string) {
            axios
                .post('/v1/card/tokens/tokenex', {
                    cipherText: cipherText,
                    cipherCVV: cipherCVV,
                })
                .then((response) => {
                    const data = response.data as TokenexReponse

                    if (!data.token || data.error) {
                        this.errorMessage = this.convertTokenexErrorMessage(data.error)
                        this.$emit('error', { error: true, result: false, message: this.errorMessage })
                        return
                    }

                    this.$emit('card-added', {
                        card: this.payment.ccnumber.slice(-4),
                        token: data.token,
                        type: 'tokenex',
                        name: this.payment.name,
                        expYear: this.payment.expyear,
                        expMonth: this.payment.expmonth,
                        address: this.address,
                    })
                })
        },
        setRsaKey() {
            axios.get('/v1/card/tokens?type=tokenex').then((response) => {
                this.pubRSA = response.data.key
            })
        },
        cancel() {
            this.loading = false
            if (this.clearFields) {
                Object.assign(this.$data, reset())
            }
        },
        convertTokenexErrorMessage(errorMessage: string) {
            const code = errorMessage.substr(0, 4)
            switch (code) {
                case '2100':
                    return 'The card information provided is invalid. Please check the card number / CVV and try again.'
                case '2011':
                    return 'The CVV has not been supplied. Enter the CVV and try again.'
                case '2101':
                    return 'The card number must only contain numeric values.'
                case '2201':
                case '2211':
                case '2203':
                    return 'The card number must be a valid length.'
                case '2300':
                    return 'You must enter a card number.'
                case '2414':
                    return 'This card issuer is not accepted'
                default:
                    return errorMessage
            }
        },
    },
})
