var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.amc_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ascent.currency(item.amc))+" ")]}},{key:"item.appraiser_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ascent.currency(item.appraiser))+" ")]}},{key:"item.line_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.company ? _vm.$ascent.currency(item.company) : _vm.$ascent.currency(0))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"small":"","icon":"","color":"success"},on:{"click":function($event){return _vm.addAddOn(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.plus")])],1)],1)],1)]}}],null,true)}),_c('v-alert',{attrs:{"value":_vm.error !== '',"color":"error","dark":""}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }