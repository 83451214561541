
























































































import { CarbonCopy } from '@/types'
import { order, refreshOrder } from '@/plugins/order/Order'
import { defineComponent, onMounted, watch } from '@vue/composition-api'
import { user } from '@/plugins/User'
import ReviewFees from './SubWorkflow/ReviewOrder/ReviewFees.vue'
import ReviewFiles from './SubWorkflow/ReviewOrder/ReviewFiles.vue'
import ReviewNotes from './SubWorkflow/ReviewOrder/ReviewNotes.vue'
import ReviewProperty, { fhaNumber, isFHA } from './SubWorkflow/ReviewOrder/ReviewProperty.vue'
import ReviewDuplicates from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/ReviewDuplicates.vue'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import ClientReviewNotes from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/partials/ClientReviewNotes.vue'
import ReviewErrors from './SubWorkflow/ReviewOrder/partials/ReviewErrors.vue'
import {
    steps,
    conventionalFHACheck,
    currentReviewStep,
    finalReviewNote,
    getDuplicateFiles,
    otherLoanType,
    removeNote,
    reviewDisabled,
    filesReviewed,
    reviewNotes,
    saveNote,
    stepsCompleted,
} from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import ContactsDialog from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts/ContactsDialog.vue'

export default defineComponent({
    name: 'ReviewOrder',
    components: {
        ContactsDialog,
        ClientReviewNotes,
        ReviewDuplicates,
        ReviewErrors,
        ReviewFees,
        ReviewFiles,
        ReviewNotes,
        ReviewProperty,
    },
    setup() {
        watch(
            conventionalFHACheck,
            () => {
                const note = 'Loan type is conventional, but FHA case number was provided. Please advise.'
                conventionalFHACheck.value ? saveNote(note) : removeNote(note)
            },
            { immediate: true },
        )

        watch(
            otherLoanType,
            () => {
                const note = 'Loan type is set as Other. Please verify if this is correct'
                otherLoanType.value ? saveNote(note) : removeNote(note)
            },
            { immediate: true },
        )

        onMounted(() => {
            getDuplicateFiles()
            currentReviewStep.value = 1
            filesReviewed.value = false
            finalReviewNote.value = ''

            if (!order.value) return

            if (
                order.value.loanPurpose &&
                order.value.loanPurpose === 'Purchase' &&
                order.value.main.substr(0, 5) !== '1004D'
            ) {
                reviewNotes.value.push({ note: 'Please provide purchase contract', removable: true })
            }

            if (!order.value.lenderLoanNumber)
                reviewNotes.value.push({ note: 'Please provide loan number', removable: true })
        })

        const completeReview = () => {
            if (!order.value) return

            $axios.post('/v1/order/' + order.value.id + '/action/complete-review').then(() => {
                if (!finalReviewNote.value) {
                    showSnackbar('Order Reviewed.')
                    refreshOrder()
                    return
                }
                if (!order.value) return
                $axios
                    .post('/v1/order/' + order.value.id + '/note', {
                        note: finalReviewNote.value,
                        source: 'amc',
                        target: 'client',
                        tag: null,
                        cc: [] as (CarbonCopy | string)[],
                        read: false,
                    })
                    .then(() => {
                        if (!order.value) return
                        $axios
                            .post('/v1/order/' + order.value.id + '/tag', {
                                orderId: order.value.id,
                                title: 'Waiting on Client',
                                color: '#FF6DFF',
                            })
                            .then(() => {
                                showSnackbar('Order Reviewed and Client Review Note Added.')
                                refreshOrder()
                            })
                    })
            })
        }

        return {
            completeReview,
            currentReviewStep,
            finalReviewNote,
            order,
            reviewDisabled,
            reviewNotes,
            steps,
            stepsCompleted,
            user,
            fhaNumber,
            isFHA,
        }
    },
})
