var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.clientNoteDialog),callback:function ($$v) {_vm.clientNoteDialog=$$v},expression:"clientNoteDialog"}},[_c('ACard',{attrs:{"title":"Send Client Note"}},[_c('v-card-text',[_c('AEditor',{attrs:{"title":"Note to Client"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('a-card-action',{attrs:{"url":'/v1/order/' + _vm.order.id + '/note',"submitData":{
                note: _vm.note,
                source: 'AMC',
                target: 'client',
                tag: null,
                cc: [],
                read: false,
            },"btnText":"Send Note"},on:{"cancel":_vm.resetDialog,"success":_vm.clientNoteSent}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }