





















import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
})
