


























































import ClientAddContactDialog, {
    openAddContact,
} from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/OrderSubPartials/ClientAddContactDialog.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { MenuItem } from '@/types'
import { order } from '@/plugins/order/Order'
import { Dialog } from '@/ascent'
import ClientEditContactDialog, {
    editContact,
} from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/ClientEditContactDialog.vue'

/**
 * @name ClientOrderAllContacts
 * Shows the contacts on the order with boroowers on top and other contacts underneath
 *
 * @SetupData actions - array of menu items to open the dialog for adding a dialog on the order
 */

export default defineComponent({
    name: 'ClientOrderAllContacts.vue',
    components: { ClientAddContactDialog, ClientEditContactDialog },
    setup() {
        const actions: MenuItem[] = [
            {
                label: 'Add Contact',
                action: () => {
                    openAddContact()
                },
            },
        ]

        const EditContactDialog = ref({} as Dialog)

        return {
            actions,
            editContact,
            openAddContact,
            order,
            EditContactDialog,
        }
    },
})
