





















import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { AppraiserOption } from '@/types'

const dialog = ref(false)
const assignNotes = ref(undefined as AppraiserOption | undefined)
export const openAssignNotesDialog = (tagName: string) => {
    if (tagName == 'Service Issues') {
        if (!order.value || !order.value.incremental || !order.value.incremental.appraiser.options) return
        dialog.value = true
        assignNotes.value = (order.value.incremental.appraiser.options as AppraiserOption[]).find(
            (elem: AppraiserOption) => elem.option_key == 'assign_notes',
        )
    }
}

export default defineComponent({
    setup() {
        return {
            dialog,
            assignNotes,
        }
    },
})
