














































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import BranchEdit, { branchId, branchName, dialog } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'
import BranchAddDialog from '@/pages/AMC/Lenders/Branches/Partials/BranchAddDialog.vue'
import { Branch, Client, Task } from '@/types'
import ClientAddDialog from '@/pages/AMC/Lenders/Clients/Partials/ClientAddDialog.vue'
import ClientEditDialog, { open } from '@/pages/Client/ClientUsers/Partials/ClientEditDialog.vue'
import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import axios from 'axios'

export default defineComponent({
    name: 'LenderBranches',
    components: {
        LenderOnboardingStatus,
        BranchEdit,
        BranchAddDialog,
        ClientAddDialog,
        ClientEditDialog,
    },
    setup(_, { emit }) {
        const addBranchOrUserDialog = ref(false)
        const branches = ref([] as Branch[])
        const loaded = ref<boolean>(false)
        const task = ref<Task>({ id: 0, status: '', user_id: 0 })

        const activeClients = (clients: Client[]) => {
            return clients.filter((el: Client) => {
                return el.status === 'Active'
            })
        }
        const inactiveClients = (clients: Client[]) => {
            return clients.filter((el: Client) => {
                return el.status === 'Inactive'
            })
        }

        const updateStatus = (task: Task) => {
            emit('updateStatus', task)
        }

        const editUser = (client: Client) => {
            open(client)
        }

        const getTasks = () => {
            axios.get(`/v1/lender/${lender.value.id}/action/get-lender-branches`).then((res) => {
                branches.value = res.data.lender.branches
                task.value = res.data.task
                loaded.value = true
            })
        }

        const userAdded = (newClient: Client) => {
            branches.value = branches.value.map((branch) => {
                if (branch.id === newClient.branch_id) {
                    const clients = branch.clients as Client[]
                    clients.push(newClient)
                }
                return branch
            })
        }

        const branchAdded = (newBranch: Branch) => {
            if (newBranch.lender_id === lender.value.id) {
                branches.value = branches.value.concat({ ...newBranch, clients: [] })
            }
        }

        onMounted(getTasks)

        return {
            updateStatus,
            branchAdded,
            userAdded,
            loaded,
            task,
            branches,
            addBranchOrUserDialog,
            lender,
            editUser,
            activeClients,
            inactiveClients,
        }
    },
    methods: {
        editBranch(branch: Branch) {
            branchName.value = branch.name
            branchId.value = branch.id
            dialog.value = true
        },
    },
})
