













































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { AscentGrid } from '@/helpers/AscentDataAdapter'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import AscentHelper from '@/helpers/ascent-helper'
import { ListingHistory } from '@/types'
import { Column } from '@syncfusion/ej2-grids'

export default defineComponent({
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const listingHistory = ref()
        const tableSettings = ref({
            dataSource: [],
            allowResizing: false,
            allowTextWrap: true,
            allowExcelExport: true,
        } as AscentGrid)

        onMounted(() => {
            listingHistory.value = []
            const today = new Date(new Date().setFullYear(new Date().getFullYear() - 3))
            listingHistory.value = preloadData.value.listing_history.filter((elem) => {
                return new Date(elem.ModificationTimestamp) > today
            })
        })

        const currencyFormat = (column: Column, data: ListingHistory) => {
            return AscentHelper.currency(data[column.field], '$0,0')
        }

        return {
            tableSettings,
            listingHistory,
            formatOptions: { type: 'date', format: 'MM/dd/yyyy' },
            formatOption: { format: 'C2', currency: 'USD', minimumIntegerDigits: 8 },
            currencyFormat,
        }
    },
})
