







import ConditionList from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/ConditionList.vue'
import AddCondition from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/AddCondition.vue'
import { defineComponent } from '@vue/composition-api'

/**
 * @name OrderConditionAction
 * Shows the list of condition on the order and gives component to add more conditions
 */

export default defineComponent({
    components: {
        ConditionList,
        AddCondition,
    },
})
