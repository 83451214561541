<template>
    <v-dialog v-model="dialog" width="1000">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <v-card style="background-color: #f5f5f5">
                <v-toolbar color="primary" elevation="2" dark>
                    <v-toolbar-title>Manual Invoice</v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="addInvoice">Split Invoice</v-btn>
                </v-toolbar>
                <v-card-text>
                    <template v-for="(invoice, index) in invoices">
                        <v-container :key="index" fluid>
                            <v-row>
                                <v-col>
                                    <p>
                                        <v-btn v-show="index > 0" icon @click="removeInvoice(index)">
                                            <v-icon small color="error">$vuetify.icons.trash</v-icon>
                                        </v-btn>
                                        <strong>Invoice {{ index + 1 }}</strong>
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col><p>Product Lines</p></v-col>
                            </v-row>
                            <v-row
                                v-for="(product, productIndex) in invoice.products"
                                :key="'product_' + index + '_' + productIndex"
                            >
                                <v-col>
                                    <a-text-field
                                        v-model="product.name"
                                        rules="required"
                                        label="Product Name"
                                        name="name"
                                    />
                                </v-col>
                                <v-col>
                                    <a-text-field
                                        v-model="product.amount"
                                        :rules="'required'"
                                        label="Line Amount"
                                        name="amount"
                                        prepend-icon="$vuetify.icons.dollar-sign"
                                    />
                                </v-col>
                                <v-col class="flex-grow-0">
                                    <v-btn icon @click="addProduct(index, productIndex)">
                                        <v-icon small color="primary">$vuetify.icons.plus</v-icon>
                                    </v-btn>
                                    <v-btn v-show="productIndex > 0" icon @click="removeProduct(index, productIndex)">
                                        <v-icon small color="error">$vuetify.icons.minus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col><p>Payment Lines</p></v-col>
                            </v-row>
                            <v-row
                                v-for="(payments, paymentIndex) in invoice.payments"
                                :key="'payment_' + index + '_' + paymentIndex"
                            >
                                <v-col>
                                    <a-select-field
                                        v-model="payments.type"
                                        :rules="payments.amount > 0 ? 'required' : ''"
                                        :items="['Credit Card', 'Check', 'Cash']"
                                        label="Payment Type"
                                        name="type"
                                    />
                                </v-col>
                                <v-col>
                                    <a-text-field
                                        v-model="payments.amount"
                                        :rules="{ regex: /^[0-9]+[.]?[0-9]{0,2}$/ }"
                                        label="Payment Amount"
                                        name="payment amount"
                                        prepend-icon="$vuetify.icons.dollar-sign"
                                    />
                                </v-col>
                                <v-col class="flex-grow-0">
                                    <v-btn icon @click="addPayment(index, paymentIndex)">
                                        <v-icon small color="primary">$vuetify.icons.plus</v-icon>
                                    </v-btn>
                                    <v-btn v-show="paymentIndex > 0" icon @click="removePayment(index, paymentIndex)">
                                        <v-icon small color="error">$vuetify.icons.minus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider :key="'divider_' + index" />
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn link text @click="dialog = false">Cancel</v-btn>
                    <v-btn color="primary" depressed @click="handleSubmit(splitInvoice)">Generate Invoice(s)</v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import { orderProducts } from '@/plugins/order/OrderProducts.ts'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { getFiles } from '@/plugins/Files'

export default defineComponent({
    setup() {
        const dialog = ref(false)
        const invoices = ref([
            {
                products: [
                    {
                        name: '',
                        amount: null,
                    },
                ],
                payments: [
                    {
                        type: null,
                        amount: null,
                    },
                ],
            },
        ])
        return {
            order,
            dialog,
            invoices,
            orderProducts,
        }
    },
    components: { ValidationObserver },
    props: {},

    methods: {
        open() {
            this.dialog = true
            this.invoices[0].products[0] = {
                name: order.value.productList,
                amount: order.value.fee,
            }
        },
        addInvoice() {
            this.invoices.push({
                products: [
                    {
                        name: '',
                        amount: null,
                    },
                ],
                payments: [
                    {
                        type: null,
                        amount: null,
                    },
                ],
            })
        },
        removeInvoice(index) {
            this.invoices.splice(index, 1)
        },
        addProduct(invoice) {
            this.invoices[invoice].products.push({
                name: '',
                amount: null,
            })
        },
        removeProduct(invoice, product) {
            this.invoices[invoice].products.splice(product, 1)
        },
        addPayment(invoice) {
            this.invoices[invoice].payments.push({
                type: null,
                amount: null,
            })
        },
        removePayment(invoice, payment) {
            this.invoices[invoice].payments.splice(payment, 1)
        },
        splitInvoice() {
            if (!order.value) return
            this.$axios
                .post('/v1/order/' + order.value.id + '/invoice/split', {
                    invoices: this.invoices,
                })
                .then(() => {
                    showSnackbar('Invoice Split Successfully')
                    this.dialog = false
                    getFiles(order.value.id)
                })
        },
    },
})
</script>
