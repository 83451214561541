












































import ClientPaymentDetails from '@/components/OrderScreens/Client/OrderComponents/OrderPartials/OrderSubPartials/ClientPaymentDetails.vue'
import { ref, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper.js'
import { Payment } from '@/types'

/**
 * @name ClientOrderListOfPaymentsDialog
 * Dialog in the ClientOrderScreen that shows client all of the payments that have been made on the order.
 *
 * @SubmitData totalPaymentsRef - payments on the incremental order.
 * @SubmitMethod clicked - Formats totalPaymentsRef for the client to see.
 */

export default defineComponent({
    name: 'ClientOrderListOfPaymentsDialog',
    components: { ClientPaymentDetails },
    props: {
        titleProp: {
            type: Number,
        },
    },
    setup(props) {
        const totalPaymentsRef = ref([] as Payment[])
        const titleRef = ref(props.titleProp)
        const dialog = ref(false)
        const clicked = () => {
            if (totalPaymentsRef.value.length === 0) {
                if (!order.value || !order.value?.incremental) return
                const totalPayment = order.value?.incremental.payments
                for (let i = 0; i < order.value?.incremental.payments.length; i++) {
                    totalPayment[i].payment_date = AscentHelper.formatIsoDate(
                        totalPayment[i].payment_date,
                        'EEE, MMM d, yyyy',
                        false,
                    )
                    totalPayment[i].created_at = AscentHelper.formatIsoDate(
                        totalPayment[i].created_at,
                        'EEE, MMM d, yyyy',
                        false,
                    )
                    totalPayment[i].amount = AscentHelper.currency(totalPayment[i].amount)
                }
                totalPaymentsRef.value = totalPayment
            }
        }
        return { totalPaymentsRef, clicked, titleRef, dialog, order }
    },
})
