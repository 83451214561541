






























































import { ValidationObserver } from 'vee-validate'
import axios from 'axios'
import { defineComponent } from '@vue/composition-api'
import { order, refreshOrder } from '@/plugins/order/Order'
import { CarbonCopyList, Participant } from '@/types'
import Vue from 'vue'
import LoanOfficerSelect from '@/components/General/AutoCompletes/LoanOfficerSelect.vue'

/**
 * @name ClientEditClientContacts
 * Dialog on Client Order Screen that allows user to edit the client contacts on an order
 */

export default defineComponent({
    components: {
        LoanOfficerSelect,
        ValidationObserver,
    },
    props: {},
    setup() {
        return { order }
    },
    data() {
        return {
            dialog: false,
            branches: [],
            loading: false,
            /**
             * Object that holds the information about the client contacts on the order
             */
            newClient: {
                participants: [] as Participant[],
                officer: undefined as undefined | number,
                client: undefined as undefined | number,
            },
            /**
             * Carbon copy list on the order
             */
            carboncopylist: undefined as undefined | CarbonCopyList[],
            client: null,
        }
    },
    computed: {
        /**
         * Formats the carboncopylist
         */
        carbon(): undefined | CarbonCopyList[] {
            if (this.carboncopylist == undefined) return
            return this.carboncopylist.map((elem) => {
                elem.label = elem.name + ', ' + elem.type + ' (' + elem.email + ')'
                return elem
            })
        },
    },
    methods: {
        /**
         * Opens the dialog and grabs info about order from a get request to '/v1/setup/order'
         */
        open() {
            if (!order.value) return
            const idFromStore = order.value.id
            axios
                .get('/v1/setup/order', {
                    params: {
                        id: idFromStore,
                    },
                })
                .then((response) => {
                    this.carboncopylist = response.data.carbonCopy
                    if (!order.value) return
                    if (order.value.officer_id != null) this.newClient.officer = order.value.officer_id
                    if (order.value.client_id != null) this.newClient.client = order.value.client_id
                    this.mapParticipants()
                    this.dialog = true
                })
        },
        submit() {
            if (!order.value) return
            this.loading = true

            this.newClient.participants = this.newClient.participants.filter((elem) => {
                if (elem.participant && elem.participant.email) return true
                if (elem.email) return true
                return this.validateEmail(elem)
            })

            this.$axios
                .post('/v1/order/' + order.value.id + '/company', {
                    carboncopylist: this.newClient.participants,
                    loanofficer: this.newClient.officer,
                    processor: this.newClient.client,
                })
                .then(() => {
                    this.loading = false
                    const form = this.$refs.form as Vue & { reset: () => void }
                    form.reset()
                    refreshOrder()
                    this.dialog = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        validateEmail(email: Participant | string) {
            const re = /\S+@\S+\.\S+/
            return re.test(email as string)
        },
        /**
         * maps the participants on the order
         */
        mapParticipants() {
            if (!order.value || !order.value?.incremental) return
            this.newClient.participants = order.value.incremental.participants.map((elem) => {
                if (elem.participant_type == 'follower') {
                    elem.label = elem.participant.email
                } else {
                    elem.label = elem.participant.name + ' - ' + elem.participant.type
                }
                return elem
            })
        },
    },
})
