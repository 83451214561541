





































import { defineComponent } from '@vue/composition-api'
import AppraiserOrderNotes from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderNotes.vue'
import AppraiserActionComponent from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserActionComponent.vue'
import AppraiserOrderToolbarMobile from '@/components/OrderScreens/Appraiser/AppraiserMobileComponents/AppraiserOrderToolbarMobile.vue'
import AppraiserOrderHeaderMobile from '@/components/OrderScreens/Appraiser/AppraiserMobileComponents/AppraiserOrderHeaderMobile.vue'
import AddNewAppraiserNote from '@/components/OrderScreens/Appraiser/AppraiserOrderActions/AddNewAppraiserNote.vue'
import AppraiserOrderFiles from '@/components/OrderScreens/Appraiser/AppraiserOrderComponents/AppraiserOrderFiles.vue'
import { order } from '@/plugins/order/Order'

/**
 * @name AppraiserOrderScreenMobile
 * Mobile Order Screen for Appraisers
 */

export default defineComponent({
    name: 'AppraiserOrderScreenMobile',
    components: {
        AppraiserOrderFiles,
        AppraiserOrderNotes,
        AddNewAppraiserNote,
        AppraiserOrderHeaderMobile,
        AppraiserOrderToolbarMobile,
        AppraiserActionComponent,
    },
    setup() {
        return { order }
    },
})
