




























































import { computed, defineComponent, ref, onMounted } from '@vue/composition-api'
import { queue } from '@/pages/AMC/Quality/Process/QcReview/Partials/QCReviewProcessData'
import { closeOrder, openCloseOrder, order } from '@/plugins/order/Order'
import { refreshDashboardCounts } from '@/components/OrderTable/Classes/TableFilters'
import CustomerOrderActions from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderActions.vue'
import CustomerOrderReminder from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderReminder.vue'
import CustomerOrderWatcher from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderWatcher.vue'
import axios from 'axios'

export default defineComponent({
    name: 'CustomerOrderToolbar',
    components: { CustomerOrderActions, CustomerOrderReminder, CustomerOrderWatcher },
    props: {
        /**
         * Determines if toolbar is shown or not
         */
        fixedToolbar: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const showResidenceType = ref(true)

        const zillow = computed(() => {
            if (!order.value) return
            if (!order.value.address.street) return ''
            return (
                'https://www.zillow.com/homes/' +
                order.value.address.street.split(' ').join('-') +
                '-' +
                order.value.address.city +
                ',-' +
                order.value.address.state +
                ',-' +
                order.value.address.zip +
                '_rb'
            )
        })

        const google = computed(() => {
            if (!order.value) return
            if (!order.value.address.street) return ''
            return (
                'https://google.com/search?q=' +
                order.value.address.street +
                ' ' +
                order.value.address.city +
                ' ' +
                order.value.address.state +
                ' ' +
                order.value.address.zip
            )
        })

        onMounted(() => {
            axios.get('/v1/settings/new-order-form').then((response) => {
                if (response.data && response.data.result.residence_type === false) {
                    showResidenceType.value = false
                }
            })
        })

        return {
            order,
            zillow,
            google,
            showResidenceType,
        }
    },
    methods: {
        closeAppraisal(): void {
            if (queue.value !== undefined) {
                openCloseOrder(false)
                return
            }
            closeOrder()
            refreshDashboardCounts()
        },
    },
})
