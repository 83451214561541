

























































import { defineComponent, ref } from '@vue/composition-api'
import { Appraiser } from '@/types'
import ACardAction from '@/global/ACardAction.vue'
import AEditor from '@/global/Form/AEditor.vue'
import AttachFileEmail from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AttachFileEmail.vue'
import { order } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ValidationObserver } from 'vee-validate'
import { getNotes } from '@/plugins/Notes'

const dialog = ref(false)
const localType = ref('')
const localAppraiser = ref(undefined as Appraiser | undefined)
export const openAssignPanelNotesDialog = (type: string, appraiser: Appraiser) => {
    localType.value = type
    localAppraiser.value = appraiser
    dialog.value = true
}

/**
 * @name AssignPanelNotesDialog
 * Dialog for amc user to add phone notes or send an email to the appraiser they are looking to assign
 *
 * @SetupData localType - type of note, either phone or email
 * @SetupData localAppraiser - sets the appraiser information for the dialog
 * @SetupMethod openAssignPanelNotesDialog - opens up dialog and sets data
 *
 * @SetupData  note - note being added to order
 * @SetupData fileIds - the ids of the files on the order that could potentially be sent to appraiser in an email
 * @SetupData sendOrderPdf - boolean to determine if OrderPdf is going to be sent via email to appraiser
 * @SetupMethod filesUpdated - sets the fileIds to the files being put in as a param
 * @SetupMethod orderPdfUpdated - takes in orderPdf boolean and sets sendOrderPdf
 *
 * @SetupMethod closeDialog - closes dialog and resets data
 * @SetupMethod noteAdded - after note is posted to backend, updates the notes on the order and closes the dialog
 */

export default defineComponent({
    components: { ACardAction, AEditor, ValidationObserver, AttachFileEmail },
    setup() {
        const note = ref('')
        const form = ref({} as InstanceType<typeof ValidationObserver>)
        const fileIds = ref([] as number[])
        const sendOrderPdf = ref(false)

        const addFiles = ref(false)

        const filesUpdated = (files: number[]) => {
            fileIds.value = files
        }

        const orderPdfUpdated = (orderPdf: boolean) => (sendOrderPdf.value = orderPdf)

        const closeDialog = () => {
            note.value = ''
            addFiles.value = false
            sendOrderPdf.value = false
            fileIds.value = []
            form.value.reset()
            dialog.value = false
        }

        const noteAdded = () => {
            if (!order.value) return
            if (localType.value == 'email') showSnackbar('Email successfully sent to appraiser!')
            if (localType.value == 'phone') showSnackbar('Note successfully added!')
            closeDialog()
            getNotes(order.value.id)
        }

        return {
            dialog,
            order,
            localAppraiser,
            localType,
            noteAdded,
            note,
            form,
            fileIds,
            filesUpdated,
            orderPdfUpdated,
            addFiles,
            sendOrderPdf,
            closeDialog,
        }
    },
})
