




















import { computed, defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { File } from '@/types'
import { files } from '@/plugins/Files'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getVeros, order } from '@/plugins/order/Order'

export default defineComponent({
    props: {},
    setup() {
        const loading = ref(false)

        const hasXML = computed(() => {
            const xml = files.value.find((elem: File) => {
                return elem.type === 'Appraisal XML' && elem.status !== 'Inactive'
            })
            return typeof xml != 'undefined'
        })

        const submit = () => {
            if (!order.value) return
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/veros', {
                    receiver: 'Default',
                    manualSubmission: true,
                })
                .then((response) => {
                    if (response.data.result === 'error') {
                        showError(response.data.message)
                        loading.value = false
                        return
                    }
                    const message = response.data.message ?? 'Order Submitted Successfully'
                    showSnackbar(message)
                    getVeros()
                    loading.value = false
                })
                .catch((error) => {
                    loading.value = false
                    const errorMessage =
                        error.response?.data?.message ?? 'An error occurred while submitting the order.'
                    showError(errorMessage)
                })
        }
        return {
            loading,
            submit,
            hasXML,
        }
    },
})
