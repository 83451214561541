



















































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { ExcludeDeliveryFiles, LenderOptions } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)

        const local = ref({
            vendorInsurance: false,
            vendorLicense: false,
        } as ExcludeDeliveryFiles)

        if (props.option !== undefined) {
            local.value = props.option.option_value as ExcludeDeliveryFiles
        }

        const save = () => {
            const tempLocal = { ...local.value }

            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'exclude-delivery-files',
                    option_value: tempLocal,
                })
                .then(() => {
                    local.value = tempLocal
                    dialog.value = false
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            save,
            dialog,
            local,
        }
    },
})
