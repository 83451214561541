








































import { defineComponent, Ref, ref } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import { Quote } from '@/types'
import { updateQuoteTable } from '@/pages/AMC/Quotes/Quotes'

const dialog = ref(false)
const note = ref('')
const localQuote: Ref<Quote> = ref({} as Quote)

export const openPreQuoteNote = (quote: Quote) => {
    localQuote.value = { ...quote }
    dialog.value = true
}

/**
 * @name ViewPreQuoteDialog
 * Component description: Shows details of pre-quote. If quote is pending, allows quote to be sent.
 * @SetupMethod closeDialog - closes the dialog
 * @SetupMethod sendQuote - closes dialog, updates prequotetable
 */
export default defineComponent({
    components: { ACardAction, ValidationObserver },
    setup() {
        const closeDialog = () => {
            dialog.value = false
            note.value = ''
        }

        const sendQuote = () => {
            dialog.value = false
            note.value = ''
            showSnackbar('Quote Send Successfully')
            updateQuoteTable()
        }

        return {
            dialog,
            note,
            closeDialog,
            sendQuote,
            localQuote,
        }
    },
})
