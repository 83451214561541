










import { defineComponent } from '@vue/composition-api'
import { createContact } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts/ContactFormDialog.vue'

export default defineComponent({
    components: {},
    props: {},
    setup() {
        return {
            createContact,
        }
    },
    methods: {},
    computed: {},
})
