

































import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import axios from 'axios'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { BorrowerDelivery } from '@/types'
import { DateTime } from 'luxon'

interface LocalDelivery extends BorrowerDelivery {
    scheduled_at?: string
    disabled?: boolean
}
const borrowerDeliveries = ref([] as LocalDelivery[])
export const getScheduledBorrowerDeliveries = () => {
    if (!order.value) return
    axios.get('/v1/order/' + order.value.id + '/scheduled-delivery').then((response) => {
        borrowerDeliveries.value = response.data
        borrowerDeliveries.value.forEach((delivery) => {
            if (!delivery.scheduled_notification_id) {
                delivery.disabled = true
            }
            if (delivery.delay && delivery.delay > 0) {
                delivery.scheduled_at = DateTime.fromISO(delivery.created_at)
                    .plus({ minutes: delivery.delay })
                    .toLocal()
                    .toFormat('DD t')
            }
        })
    })
}
export default defineComponent({
    setup() {
        const deleteBorrowerDelivery = (delivery: BorrowerDelivery) => {
            if (!delivery) return
            if (!order.value) return
            confirm(
                'Delete Borrower Delivery',
                'Are you sure you want to delete this borrower delivery? This will prevent delivery, you can resend manually from the order screen.',
            ).then((confirm) => {
                if (confirm) {
                    axios
                        .delete('/v1/scheduled-delivery/' + delivery.id)
                        .then((response) => {
                            showSnackbar(response.data)
                            getScheduledBorrowerDeliveries()
                        })
                        .catch((error) => {
                            showError(error.response.data ?? error.data ?? error)
                            getScheduledBorrowerDeliveries()
                        })
                }
            })
        }

        return { borrowerDeliveries, deleteBorrowerDelivery }
    },
})
