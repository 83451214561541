
























import { defineComponent, ref, watch } from '@vue/composition-api'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'
import {
    appraiserValue,
    appraiserSettingsId,
    appraiserOptions,
    AppraiserOptionsSettings,
} from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    props: {
        option: {
            type: Object || undefined,
        },
    },
    setup(props, { root }) {
        const appraiserOptionsLocal = ref({
            jarokit_beta: false,
        } as { jarokit_beta: boolean | undefined })

        if (appraiserOptions.value.jarokit_beta === undefined) {
            appraiserOptionsLocal.value.jarokit_beta = false
        }

        watch(appraiserOptions && appraiserValue, () => {
            if (props.option && props.option.jarokit_beta) {
                appraiserOptions.value.jarokit_beta = props.option.jarokit_beta
                appraiserOptionsLocal.value.jarokit_beta = props.option.jarokit_beta
            }
        })

        if (props.option && props.option.jarokit_beta) {
            appraiserOptions.value.jarokit_beta = props.option.jarokit_beta
            appraiserOptionsLocal.value.jarokit_beta = props.option.jarokit_beta
        }

        const saveChanges = async () => {
            if (root.$route.params.id) appraiserSettingsId.value = root.$route.params.id
            await axios.post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                option_key: 'jarokit_beta',
                option_value: appraiserOptions.value.jarokit_beta,
            })
            appraiserOptionsLocal.value = appraiserOptions.value as AppraiserOptionsSettings
            showSnackbar('Saved successfully')
        }

        return {
            saveChanges,
            appraiserOptions,
            appraiserOptionsLocal,
        }
    },
})
