


























import { defineComponent, PropType, ref, watch } from '@vue/composition-api'

import { branch } from '../../../BranchEdit.vue'
import { LenderOptions } from '@/types'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: {},
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const optionLocal = ref(false)

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as boolean
        }

        const updateOption = (event: Event) => {
            if (!branch.value) return
            axios
                .post(`/v1/branch/${branch.value.id}/option`, {
                    option_key: 'closed-panel',
                    option_value: event,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        watch(() => props.option, () => {
            if (props.option !== undefined) {
                optionLocal.value = props.option.option_value as boolean
            }
        })

        return {
            optionLocal,
            updateOption,
        }
    },
})
