





















































































import { File } from '@/types'

interface Duplicate {
    id: number
}

import { order, refreshOrder } from '@/plugins/order/Order'
import { defineComponent, onMounted } from '@vue/composition-api'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import $axios from '@/plugins/axios'
import Vue from 'vue'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { duplicateFiles, getDuplicateFiles } from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'

/**
 * @name ReviewDuplicates
 * Step in the review order workflow that checks duplicates and has amc user review them
 *
 * @SetupMethod removeDuplicate - removes the duplicate from the order and updates the order
 * @SetupMethod addFileToOrder - takes a file, file index, and duplicate index, and adds a file from a duplicate onto the current order
 * @SetupMethod openFile - opens up the file
 */

export default defineComponent({
    components: {},
    props: {
        title: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const removeDuplicate = (duplicate: Duplicate) => {
            confirm(
                'Remove Duplicate?',
                'Are you sure? This cannot be undone and it will not show as a duplicate while the order is in progress',
                {},
            ).then((confirm) => {
                if (confirm) {
                    $axios.delete('/v1/duplicate/' + duplicate.id).then(() => refreshOrder())
                }
            })
        }

        const addFileToOrder = (file: File, fileIndex: number, duplicateIndex: number) => {
            if (!order.value) return
            $axios
                .post('/v1/order/' + order.value.id + '/action/add-existing-file', {
                    id: file.id,
                })
                .then((response) => {
                    showSnackbar(response.data)
                    duplicateFiles.value[duplicateIndex][fileIndex].addedToOrder = true
                    Vue.set(duplicateFiles.value, duplicateIndex, duplicateFiles.value[duplicateIndex])
                })
                .catch((error) => {
                    showError(error.response.data.message)
                })
        }

        const openFile = (file: File) => {
            $axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        }

        onMounted(() => {
            getDuplicateFiles()
        })

        return {
            openFile,
            addFileToOrder,
            duplicateFiles,
            order,
            removeDuplicate,
        }
    },
})
