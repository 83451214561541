
































import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { ValidationObserver } from 'vee-validate'
import { appraiserValue } from '@/pages/Appraiser/Profile/AppraiserProfile'

const dialog = ref(false)

export const openRemoveHoldDialog = () => {
    dialog.value = true
}

/**
 * @name RemoveHoldTerminationDialog
 * Dialog in appraiser settings to remove a hold/termination from an appraiser and set appraiser to either pending or active status
 *
 * @SetupData statuses - statuses that user can set appraiser to once appraiser is not on hold or terminated
 * @SetupMethod saveChanges - calls a delete request to backend with parameter of the status. Shows snackbar and emits event to parent component in order to refresh the status
 */

export default defineComponent({
    components: {
        ValidationObserver,
    },
    setup(_, { emit }) {
        const statuses = ['Active', 'Pending']
        const saveChanges = () => {
            if (!appraiserValue.value) return
            $axios
                .delete(
                    `/v1/appraiser/${appraiserValue.value.id}/termination-hold/${appraiserValue.value.current_hold_or_termination.id}`,
                    {
                        params: {
                            status: appraiserOptions.value.status,
                        },
                    },
                )
                .then(() => {
                    showSnackbar('Successfully Saved!')
                    emit('appraiser-status-saved')
                    dialog.value = false
                })
        }

        return {
            statuses,
            dialog,
            appraiserOptions,
            saveChanges,
        }
    },
})
