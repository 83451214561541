















import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { get } from 'lodash'
import ARadioField from '@/components/DynamicForm/ARadioField.vue'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'
import { mixed, SimpleDynamicField } from '@/types/SimpleDynamicForm'
import DetailWithLabelComponent from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailWithLabelComponent.vue'
import KitImageUpload from '@/pages/AlternativeProducts/Kit/Shared/Subject/KitImageUpload.vue'
import AManualAddress from '@/components/DynamicForm/AManualAddress.vue'
import ADatePicker from '@/components/DynamicForm/ADatePicker.vue'

export default defineComponent({
    name: 'DynamicField',
    components: {
        ARadioField,
        ADatePicker,
        StateSelect,
        DetailWithLabelComponent,
        KitImageUpload,
        AManualAddress,
    },
    props: {
        elem: {
            type: Object as PropType<SimpleDynamicField>,
            default: () => ({}),
        },
        errors: Object,
        formData: {
            type: Object,
            default: () => {
                return {}
            },
        },
        editing: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['updateValue'],
    setup(props, { emit }) {
        const localValue = ref(props.elem.val)

        watch(
            () => props.elem.val,
            (newVal) => {
                localValue.value = newVal
            },
        )

        const updateValue = (elem: SimpleDynamicField, val: string | number | boolean) => {
            localValue.value = val
            emit('updateValue', val)
        }

        const shouldShow = computed(() => {
            if (props.elem.conditions) {
                return props.elem.conditions.every((condition) => {
                    const value = get(props.formData, condition.conditionalField)

                    switch (condition.conditionalType) {
                        case 'is':
                            return value === condition.conditionalValue
                        case 'not':
                            return value !== condition.conditionalValue
                        case 'in':
                            return (condition.conditionalValue as mixed[]).indexOf(value) > -1
                        case 'not in':
                            return (condition.conditionalValue as mixed[]).indexOf(value) === -1
                    }
                })
            }
            return true
        })

        return {
            updateValue,
            localValue,
            shouldShow,
        }
    },
})
