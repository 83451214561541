























import { defineComponent } from '@vue/composition-api'
import OfficeAssignTable from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignTable.vue'
import OfficeAssignDialog from '@/components/OrderScreens/Office/OrderComponents/Workflows/OfficeAssignment/OfficeAssignDialog.vue'
import {
    activeTab,
    getPanel,
    currentTab,
    panelTabs,
    trainee,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'

export default defineComponent({
    setup() {
        const setTab = (value: string) => {
            currentTab.value = value
        }

        return {
            panelTabs,
            activeTab,
            trainee,
            currentTab,
            getPanel,
            setTab,
        }
    },
    components: {
        OfficeAssignTable,
        OfficeAssignDialog,
    },
})
