










































































import AscentHelper from '@/helpers/ascent-helper'
import { order } from '@/plugins/order/Order'
import { NewOrderFee } from '@/types'
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from '@vue/composition-api'
import {
    fees,
    getOrderConfiguration,
    getOrderTotal,
    mortgageDirectorId,
    newOrder,
    orderConfiguration,
    useDisclosedFee,
    setFeeType,
} from '@/pages/Client/NewOrderMethods'

export default defineComponent({
    props: {
        showDefaultFeeButton: {
            type: Boolean,
            default: true,
        },
        isReassignFeeTable: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const tableOrder = ref()
        const reassignFeeTable = ref(props.isReassignFeeTable)
        const corpCCApprovalFeatureEnabled = ref(
            AscentHelper.customerFeatureEnabled('monitoring.CorporateCreditCardApproval', false),
        )

        const getTotal = (): number | null => {
            if (fees.value.length == 0 || useDisclosedFee.value)
                return order.value?.disclosedFee === null ? 0 : order.value?.disclosedFee ?? 0

            const quoted = fees.value.find((elem: NewOrderFee) => elem.quote)

            if (typeof quoted != 'undefined') return 0
            return fees.value.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total
            }, 0)
        }

        const total = computed(() => {
            if (corpCCApprovalFeatureEnabled.value && !reassignFeeTable.value)
                return useDisclosedFee.value
                    ? newOrder.value.disclosed_fee
                    : newOrder.value.forms.total === 0
                    ? newOrder.value.forms.feeScheduleTotal
                    : newOrder.value.forms.total
            return getTotal()
        })

        const tableData = computed(() => {
            return fees.value.map((fee) => {
                fee.amc = !fee.amc ? 0 : fee.amc
                fee.appraiser = !fee.appraiser ? 0 : fee.appraiser
                fee.total = !fee.total ? 0 : fee.total

                let col2 = fee.total === 0 ? '---' : `$${fee.total.toString()}`
                if (fee.quote) {
                    col2 = 'Quoted'
                }

                return {
                    col1: fee.form,
                    col2: col2,
                }
            })
        })

        const feeButton = computed(() => {
            if (
                !props.showDefaultFeeButton ||
                AscentHelper.customerFeatureEnabled('assignType') !== 'vendor' ||
                tableOrder.value.appraiser_id === 0 ||
                reassignFeeTable.value
            ) {
                return null
            }

            // todo - clean if statement when monitoring feature is removed
            if (
                (corpCCApprovalFeatureEnabled.value && useDisclosedFee.value) ||
                (!corpCCApprovalFeatureEnabled.value && total.value !== tableOrder.value.disclosed_fee)
            ) {
                return AscentHelper.translate('lang.order_form_default_fee', 'Default')
            }
            if (
                mortgageDirectorId.value &&
                corpCCApprovalFeatureEnabled.value &&
                tableOrder.value.forms.total !== tableOrder.value.disclosed_fee
            )
                return 'Disclosed Fee'

            return null
        })

        const setTotalFromType = (totalType = null) => {
            setFeeType(totalType)
            // todo - remove if statement when monitoring feature is removed
            if (!corpCCApprovalFeatureEnabled.value) {
                tableOrder.value.disclosed_fee = newOrder.value.forms.total
                useDisclosedFee.value = false
            }
        }

        watch([() => newOrder.value.forms.main, () => newOrder.value.forms.loan], () => {
            if (!corpCCApprovalFeatureEnabled.value) return
            newOrder.value.forms.total = Number(newOrder.value.disclosed_fee)
            useDisclosedFee.value = true
            tableOrder.value = newOrder.value
        })

        onBeforeMount(() => {
            tableOrder.value = newOrder.value
            if (order.value) tableOrder.value = order.value
        })

        onMounted(async () => {
            if (Object.entries(orderConfiguration.value).length === 0) await getOrderConfiguration(false)
            if (useDisclosedFee.value === true) newOrder.value.forms.total = Number(newOrder.value.disclosed_fee)
        })

        return {
            mortgageDirectorId,
            tableOrder,
            total,
            corpCCApprovalFeatureEnabled,
            reassignFeeTable,
            feeButton,
            tableData,
            orderConfiguration,
            getTotal,
            setTotalFromType,
            newOrder,
            getOrderTotal,
            useDisclosedFee,
        }
    },
    data() {
        return {
            orderHeaders: [
                {
                    text: 'Product Title',
                    value: 'title',
                    align: 'left',
                    sortable: false,
                },
                { text: 'Fee', value: 'rate', align: 'right', sortable: false },
            ],
        }
    },
})
