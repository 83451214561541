var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canEdit)?_c('v-btn',_vm._g({attrs:{"x-small":"","color":"secondary","depressed":""}},on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("$vuetify.icons.plus")]),_vm._v(" "+_vm._s(!_vm.signature ? 'Add File' : 'Add Signature')+" ")],1):_vm._e()]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('a-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(!_vm.signature ? 'Upload File' : 'Upload Signature'))])]),_c('v-card-text',[(!_vm.signature && !_vm.customerFile)?_c('v-row',[_c('v-col',[_c('a-select-field',{attrs:{"rules":"required","items":_vm.fileTypes,"name":"file type","label":"File Type","color":"accentBlack"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1):_vm._e(),(!_vm.signature)?_c('v-row',[_c('v-col',[(_vm.type === 'Other')?_c('a-text-field',{attrs:{"rules":"required","name":"file type","label":"File Type","color":"accentBlack"},model:{value:(_vm.otherType),callback:function ($$v) {_vm.otherType=$$v},expression:"otherType"}}):_vm._e()],1)],1):_vm._e(),_c('v-row',[(_vm.file === '')?_c('v-col',{attrs:{"cols":"12"}},[(_vm.signature)?_c('div',[_c('FileUpload',{attrs:{"url":'appraiser/' + _vm.$route.params.id + '/files',"auto":true,"element":"SignatureUpload","hash":"","file-error":_vm.fileError},on:{"upload-success":_vm.getFile}})],1):_vm._e(),(!_vm.signature)?_c('div',[_c('FileUpload',{attrs:{"url":'appraiser/' + _vm.$route.params.id + '/files',"auto":true,"element":"ResumeUpload","hash":"","file-error":_vm.fileError},on:{"upload-success":_vm.getFile}})],1):_vm._e()]):_vm._e(),(_vm.file !== '')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"value":true,"type":"success"}},[_vm._v("File ready to be submitted")])],1):_vm._e()],1)],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"submitData":{
                    type: _vm.type === 'Other' ? _vm.otherType : _vm.type,
                    file: _vm.file,
                    customerFile: _vm.customerFile,
                },"disableSubmit":_vm.file === '',"btnText":"Upload File","url":'/v1/appraiser/' + _vm.$route.params.id + '/files'},on:{"success":_vm.uploadSubmit,"cancel":function($event){_vm.showDialog = false}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }