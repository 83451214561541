<template>
    <a-card style="width: 600px">
        <v-card-title class="headline grey lighten-3 mb-4">Client Details</v-card-title>

        <v-card-text v-if="client != null">
            <p class="text-subtitle-1 mb-0">{{ client.type }}</p>
            <p class="text-h5">{{ client.name }}</p>
            <p>{{ client.email }}</p>
            <p v-for="phone in client.phones" :key="phone.id" class="mb-1">
                <span class="font-weight-bold">{{ phone.type }}:</span>
                {{ $ascent.phone(phone.phone) }}
            </p>
        </v-card-text>
    </a-card>
</template>

<script lang="js">
import {defineComponent, ref} from "@vue/composition-api"
import $axios from "@/plugins/axios"
const client = ref(null)

export const openViewClientDialog = (id, type) => {
    $axios.get('/v1/'+ type + '/' + id).then((response) => {
        client.value = response.data
    })
}


export default defineComponent({
    name: "ViewClientPopover",
    setup() {
        return {
            client
        }
    }
})
</script>

<style scoped></style>
