













































import { revisions } from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchAssignmentPanelDirectives/BranchAssignmentPanelDirectives.vue'
import { defineComponent } from '@vue/composition-api'
import BranchDirectiveRevision from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchDirectives/BranchDirectiveRevision.vue'
export default defineComponent({
    name: 'BranchAssignmentCurrentAndHistory',
    components: { BranchDirectiveRevision },
    setup() {
        const headers = [
            { text: 'Date', value: 'created_at', width: 300, sortable: false },
            { text: 'Created By', value: 'saved_by', sortable: false },
            { value: 'view', width: 200, sortable: false },
        ]

        return { revisions, headers }
    },
})
