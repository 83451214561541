











































import { computed, defineComponent, ref } from '@vue/composition-api'
import CustomerOrderDetails from '@/components/OrderScreens/Customer/CustomerLayout/CustomerOrderDetails.vue'
import OrderVerosSubmissionList from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderUcdp/OrderVerosSubmissionList.vue'
import OrderUcdpDetails from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/OrderUcdpDetails.vue'
import { order, preloadTag } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import ReportDeliveryAction from '@/components/OrderScreens/Office/OrderComponents/Workflows/ReportDeliveryAction.vue'
import CustomerOrderActions from '@/components/OrderScreens/Customer/CustomerLayout/CustomerOrderActions.vue'
import CustomerKitTab from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerKitTab.vue'
import AscentHelper from '@/helpers/ascent-helper'

export default defineComponent({
    name: 'CustomerLeftSide',
    components: {
        ReportDeliveryAction,
        OrderUcdpDetails,
        OrderVerosSubmissionList,
        CustomerOrderActions,
        CustomerOrderDetails,
        CustomerKitTab,
    },
    setup() {
        const tab = ref(undefined)
        const deliveryTag = computed(() => {
            if (!order.value || !order.value.tags) return false
            return order.value.tags.find((elem) => elem.tag == 'Delivery Required')
        })

        const renderJaroKit = computed(() => {
            return (
                AscentHelper.featureEnabled('orderScreen.office.tdcx') ||
                AscentHelper.featureEnabled('pdr') ||
                AscentHelper.featureEnabled('orderScreen.office.boost')
            )
        })

        return {
            tab,
            order,
            user,
            deliveryTag,
            preloadTag,
            renderJaroKit,
        }
    },
})
