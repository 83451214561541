




































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import axios from 'axios'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'

/**
 * @name AssignDirectives
 * Component to show rep the directives of both the lender and branch that are on the order.
 * This component only shows up in the amc order screen if order status is in assign or accept.
 *
 * @SetupData lenderDirectives - Lender Directives that are on lender options
 * @SetupData branchDirectives - Branch directives that are on branch settings/options
 *
 * @onBeforeMount - Makes 2 axios calls to '/v1/lender/' + order.value?.lender_id + '/option/assign-directive' and '/v1/branch/' + order.value?.branch_id + '/option/assign-directive' that sets both the lender directives and the branch directives
 *
 *
 */

export default defineComponent({
    name: 'AssignDirectives',
    setup() {
        const lenderDirectives = ref('')
        const branchDirectives = ref('')
        onBeforeMount(() => {
            axios.get('/v1/lender/' + order.value?.lender_id + '/option/assign-directive').then((res) => {
                if (!res.data) return
                lenderDirectives.value = res.data.option_value
            })

            $axios.get('/v1/branch/' + order.value?.branch_id + '/option/assign-directive').then((response) => {
                if (!response.data) return
                branchDirectives.value = response.data.option_value
            })
        })
        return { lenderDirectives, branchDirectives, order }
    },
})
