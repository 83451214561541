























import { defineComponent, PropType } from '@vue/composition-api'
import { AppraiserPanel } from '@/types'

/**
 * @name ExpandPartial
 * Partial on the AssignTable that determines if expanded area of the appraiser is open or not
 *
 * @SetupMethod expand - emits event to expand this appraiser
 * @SetupMethod collapse - emits event to collapse appraiser
 */

export default defineComponent({
    props: {
        /**
         * Appraiser in Assign Table
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
        /**
         * List of appraisers that have been expanded already
         */
        expanded: {
            type: Array,
        },
    },
    setup(props, { emit }) {
        const expand = () => {
            emit('expand-opened', props.item)
        }

        const collapse = () => {
            if (!props.item) return
            emit('expand-collapse', props.item)
        }

        return {
            expand,
            collapse,
        }
    },
})
