
















































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order } from '@/plugins/order/Order'
import { Appraiser, AppraiserOptionPanel, PanelAppraiser } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { loadIncremental, updateOrder } from '@/plugins/order/Order'
import { GET_APPRAISER_FEE } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
interface DialogContent {
    appraiser: Appraiser | PanelAppraiser
    details: string
}

const dialogContent = ref({
    appraiser: undefined as undefined | Appraiser | PanelAppraiser,
    appraiser_deadline: '',
    client_deadline: '',
    eligible: false,
    details: '',
} as DialogContent)
const dialog = ref(false)
const loading = ref(false)
const fee = ref(undefined as undefined | number)

export const openAssignDialog = async (content: DialogContent) => {
    fee.value = await GET_APPRAISER_FEE('full', (content.appraiser as Appraiser).id)
    dialogContent.value = content
    dialog.value = true
}

export default defineComponent({
    props: {
        assignWorkflow: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        ValidationObserver,
    },
    setup() {
        const appraiserStagger = computed(() => {
            if (!dialogContent.value.appraiser || !dialogContent.value.appraiser.options) return -1
            return (dialogContent.value.appraiser as PanelAppraiser).options.findIndex(
                (elem: AppraiserOptionPanel) => elem.stagger,
            )
        })

        return {
            order,
            dialogContent,
            dialog,
            loading,
            appraiserStagger,
            fee,
        }
    },
    data() {
        return {
            resolve: null,
            assigning: false,
            overrideReason: null,
        }
    },
    methods: {
        confirm() {
            if (!order.value) return
            this.loading = true
            if (!this.assignWorkflow) return this.$emit('confirm-assign')
            this.$axios
                .post('v1/order/' + order.value.id + '/action/office-allocate-order', {
                    roles: [
                        {
                            role: 'Primary',
                            appraiser_id: this.dialogContent.appraiser.id,
                            fee: this.fee,
                        },
                    ],
                })
                .then(() => {
                    if (!order.value) return
                    loadIncremental()
                    order.value.appraiser_id = this.dialogContent.appraiser.id
                    order.value.appraiserName = this.dialogContent.appraiser.name
                    updateOrder()
                    showSnackbar('Appraiser Assigned Successfully!')
                    this.fee = undefined

                    this.assigning = false
                })
                .catch(() => {
                    this.assigning = false
                })

            this.dialog = false
            this.loading = false
        },
        cancel() {
            this.dialog = false
            this.loading = false
        },
    },
})
