

















import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { BranchOption } from '@/types'
import { branch } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'
import $axios from '@/plugins/axios'
import BranchAssignmentEdit from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchAssignmentPanelDirectives/subpartials/BranchAssignmentEdit.vue'
import BranchAssignmentCurrentAndHistory from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchAssignmentPanelDirectives/subpartials/BranchAssignmentCurrentAndHistory.vue'

export const revisions = ref([] as BranchOption[])

export default defineComponent({
    name: 'BranchAssignmentPanelDirectives',
    components: { BranchAssignmentCurrentAndHistory, BranchAssignmentEdit },
    setup() {
        const loaded = ref(false)

        const updateDirectives = (newDirective: BranchOption) => {
            revisions.value.push(newDirective)
        }

        const getDirectives = () => {
            if (!branch.value) return

            $axios
                .get('/v1/branch/' + branch.value.id + '/option/assign-directive', {
                    params: {
                        withRevisions: true,
                    },
                })
                .then((response) => {
                    loaded.value = true
                    if (!response.data) return
                    revisions.value = response.data || []
                })
        }

        onMounted(getDirectives)

        return { revisions, updateDirectives }
    },
})
