











































































































































































































































































































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { BillingOption, LenderOptions } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import MainSelect from '@/components/General/AutoCompletes/MainSelect.vue'
import $axios from '@/plugins/axios'

export default defineComponent({
    components: { MainSelect, ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref<boolean>(false)
        const billing = ref([] as BillingOption)
        const invoice = ref<boolean>(false)

        if (props.option !== undefined) {
            billing.value = props.option.option_value as BillingOption
        }

        const billingLocal = ref<BillingOption>(JSON.parse(JSON.stringify(billing.value)))

        const saveChanges = () => {
            $axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'billing',
                    option_value: billing.value,
                })
                .then(() => {
                    dialog.value = false
                    billingLocal.value = billing.value
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            billing,
            billingLocal,
            saveChanges,
            dialog,
            lender,
            invoice,
        }
    },
})
