







































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { DateTime } from 'luxon'
import { order } from '@/plugins/order/Order'
import {
    setAppraiserDeadline,
    setClientDeadline,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AscentHelper from '@/helpers/ascent-helper'
import { requiresDateModification } from '@/plugins/order/OrderModification'
import CustomerRequiresModRequest from '@/components/OrderScreens/Customer/CustomerEditComponents/CustomerRequiresModRequest.vue'

export default defineComponent({
    components: {
        CustomerRequiresModRequest,
    },
    setup(_, { emit }) {
        const appraiser = ref('')
        const client = ref('')
        const appraiserDateMenu = ref(false)
        const clientDateMenu = ref(false)
        const dateTime = ref(DateTime)

        const updateAppraiserDeadline = (value: string) => {
            setAppraiserDeadline(value)
            emit('updateDeadline', { type: 'appraiser', value: value })
        }

        const updateClientDeadline = () => {
            setClientDeadline(client.value)
            emit('updateDeadline', { type: 'client', value: client.value })
        }

        const syncAppraiser = (value: string) => {
            if (DateTime.fromISO(value).weekday === 1) {
                // Monday
                appraiser.value = DateTime.fromISO(value).minus({ days: 3 }).toISODate()
            } else {
                appraiser.value = DateTime.fromISO(value).minus({ days: 1 }).toISODate()
            }

            updateClientDeadline()
            updateAppraiserDeadline(appraiser.value)
        }

        const setDates = (): void => {
            if (order.value && order.value.appraiserDeadline != null) {
                setAppraiserDeadline(AscentHelper.formatUnix(order.value.appraiserDeadline, 'yyyy-MM-dd', false))
                appraiser.value = AscentHelper.formatUnix(order.value.appraiserDeadline, 'yyyy-MM-dd', false)
            }
            if (order.value && order.value.clientDeadline != null) {
                setClientDeadline(AscentHelper.formatUnix(order.value.clientDeadline, 'yyyy-MM-dd', false))

                client.value = AscentHelper.formatUnix(order.value.clientDeadline, 'yyyy-MM-dd', false)
            }
            syncAppraiser(client.value !== '' ? client.value : DateTime.local().toISODate())
        }

        const checkDeadlineDates = (val: string) => {
            return AscentHelper.isDateBlackedOut(val)
        }

        onMounted(() => {
            setDates()
        })

        return {
            appraiserDateMenu,
            clientDateMenu,
            syncAppraiser,
            dateTime,
            appraiser,
            client,
            checkDeadlineDates,
            updateAppraiserDeadline,
            updateClientDeadline,
            requiresDateModification,
        }
    },
})
