





















import { defineComponent } from '@vue/composition-api'
import { user } from '@/plugins/User'

export default defineComponent({
    props: {
        label: {
            type: String,
            default: 'User',
        },
        rules: {
            type: String,
            default: undefined,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            items: [],
        }
    },

    watch: {
        search(val: string) {
            val && val !== this.select && this.searchAmcUser(val)
        },
    },
    mounted() {
        this.searchAmcUser('')
    },
    methods: {
        searchAmcUser(search: string) {
            let userType = 'amc_user'
            if (user.value && user.value.type === 'office') userType = 'office_user'
            this.loading = true
            this.$axios
                .post('/v1/search', {
                    val: search,
                    type: userType,
                    object: true,
                    limit: 250,
                })
                .then(({ data }) => {
                    this.items = data.sort((a: { name: string }, b: { name: string }) => {
                        return a.name < b.name || a.name == null ? -1 : 1
                    })
                    this.loading = false
                })
        },
    },
})
