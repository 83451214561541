



























































































































































































































































import { defineComponent } from '@vue/composition-api'
import LenderSelect from '@/components/General/AutoCompletes/LenderSelect.vue'
import { Lender } from '@/types'

function reset() {
    return {
        dialog: false,
        error: '',
        client: {
            branches: [],
            branch_id: null,
            broker: false,
            name: null,
            phones: [],
            type: null,
            status: 'Active',
            email: null,
            permissions: [],
            role: null,
        },
        brokerBranch: {
            lenders: [] as Lender[],
            origLenders: [] as Lender[],
            settingsReady: false,
        },
        selectedLender: 0,
        loading: false,
        createUser: true,
    }
}

import { ValidationObserver } from 'vee-validate'
import BranchSelect from '@/components/General/AutoCompletes/BranchSelect.vue'
import { clientPermissions, clientRoles, clientTypes, masks } from '@/plugins/Data'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: {
        BranchSelect,
        LenderSelect,
        ValidationObserver,
    },
    props: {
        lenderId: {
            type: Number,
            default: null,
        },
    },
    setup() {
        return {
            masks,
            clientTypes,
            clientRoles,
            clientPermissions,
        }
    },
    data() {
        return reset()
    },
    mounted() {
        this.selectedLender = this.lenderId
    },
    methods: {
        handleBrokerBranchChange() {
            this.brokerBranch = {
                lenders: [],
                origLenders: [],
                settingsReady: false,
            }

            this.$axios.get('/v1/branch/' + this.client.branch_id).then((response) => {
                if (!response.data.wholesale_lenders) return
                response.data.wholesale_lenders.map((lender: Lender) => {
                    this.brokerBranch.lenders.push(lender)
                })
                this.brokerBranch.origLenders = JSON.parse(JSON.stringify(this.brokerBranch.lenders))
            })
        },
        saveChanges() {
            this.loading = true
            this.$axios
                .post('/v1/client', {
                    client: this.client,
                    create: this.createUser,
                })
                .then(() => {
                    this.saveBrokerBranchChanges().finally(() => {
                        this.$emit('user-added', this.client)
                        Object.assign(this.$data, reset())
                        this.selectedLender = this.lenderId
                        const form = this.$refs.form as Vue & {
                            reset: () => void
                        }
                        form.reset()
                        showSnackbar('User added successfully')
                        this.$emit('dialog-closed')
                    })
                })
                .catch((error) => {
                    this.loading = false
                    if (error.response.status === 422) {
                        this.error = 'This email address is already in use'
                    }
                })
        },
        async saveBrokerBranchChanges() {
            return new Promise((resolve) => {
                if (this.client.broker && this.brokerBranchChanged()) {
                    this.$axios
                        .patch('/v1/branch/' + this.client.branch_id, {
                            branch: [],
                            lenders: this.brokerBranch.lenders,
                        })
                        .then(() => {
                            this.$emit('branch-updated')
                            resolve(true)
                        })
                        .catch(() => {
                            resolve(false)
                        })
                } else {
                    this.$emit('branch-unchanged')
                    resolve(true)
                }
            })
        },
        brokerBranchChanged() {
            if (this.brokerBranch.lenders.length !== this.brokerBranch.origLenders.length) {
                return true
            }

            let changed = false
            this.brokerBranch.lenders.forEach((lender) => {
                if (!this.brokerBranch.origLenders.includes(lender)) {
                    changed = true
                }
            })

            return changed
        },
        closeDialog() {
            this.$emit('dialog-closed')
            this.dialog = false
            Object.assign(this.$data, reset())
        },
    },
})
