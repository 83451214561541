






























































import { defineComponent, Ref, ref } from '@vue/composition-api'
import $axios from 'axios'
import { order, refreshOrder } from '@/plugins/order/Order'
import { Tag } from '@/types'
import ACardAction from '@/global/ACardAction.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export const openDialog = ref(false)
const tableItems = ref([] as Tag[])
const requiresApproval = ref(false)
const currentTag: Ref<Tag | undefined> = ref(undefined)

export const getModHistory = (tag: Tag) => {
    currentTag.value = { ...tag }
    if (tag.extra?.approvalRequired) {
        requiresApproval.value = true
        return
    }
    tableItems.value = []
    $axios.get('/v1/order/' + order.value?.id + '/get-modification-requests').then((res) => {
        tableItems.value = res.data.tags
    })
}
const headers = [
    { text: 'Date Requested', value: 'created_at' },
    { text: 'Total Fee', value: 'total', sortable: false },
]
const expandHeaders = [
    { text: 'Product', value: 'product_name', width: '210' },
    { text: 'AMC Fee', value: 'amc_value', width: '210' },
    { text: 'Appraiser Fee', value: 'appraiser_value', width: '210' },
    { text: 'Line Total', value: 'total' },
]

export default defineComponent({
    name: 'ModFeeRequestDialog',
    components: { ACardAction },
    setup() {
        const declineMod = () => {
            $axios.post('/v1/task/' + currentTag.value?.id + '/decline-modification').then(({ data }) => {
                openDialog.value = false
                refreshOrder()
                showSnackbar(data.message)
            })
        }
        return {
            openDialog,
            getModHistory,
            tableItems,
            headers,
            expandHeaders,
            order,
            requiresApproval,
            currentTag,
            declineMod,
            refreshOrder,
        }
    },
})
