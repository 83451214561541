






























































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import $axios from '@/plugins/axios'
import AmcUserSelect from '@/components/General/AutoCompletes/AmcUserSelect.vue'
import { DateTime } from 'luxon'
import { Reminder } from '@/types'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import AEditor from '@/global/Form/AEditor.vue'
import AscentKeyboardEvents from '@/plugins/KeyEventListener'
import { queueTitle } from '@/pages/AMC/Dashboard/Processes/DashboardProcesses'

const disablePastDates = (val: Event) => {
    return val >= DateTime.now().toFormat('yyyy-MM-dd')
}

const state = reactive({
    error: false,
    dialog: false,
    dateMenu: false,
    option: 'personal',
    reminder: {
        date: '',
        time: '',
        note: '',
        assignedUser: 0,
        id: 0,
    },
})

let originalReminder = {} as Reminder
const assignedUser = () => {
    if (!order.value || !order.value?.incremental) return
    return order.value?.user_id ?? order.value.incremental.assigner_id
}

const queueReminder = ref(false)

export const open = (reminder: Reminder | undefined, isQueue?: boolean) => {
    if (isQueue) queueReminder.value = true
    if (reminder) {
        originalReminder = reminder
        const time = new Date(reminder.reminder_date).getTime()
        const date = DateTime.fromMillis(time, { zone: 'local' })

        state.reminder = {
            id: reminder.id as number,
            date: date.toFormat('yyyy-MM-dd'),
            note: reminder.note,
            assignedUser: reminder.user_id,
            time: date.toFormat('HH:mm'),
        }
    }
    if (!reminder) {
        const time =
            parseInt(DateTime.now().toFormat('HH')) >= 16
                ? '09:00'
                : DateTime.now().plus({ hours: 2 }).toFormat('HH:mm')
        state.reminder = {
            date: DateTime.now().plus({ hours: 2 }).toFormat('yyyy-MM-dd'),
            time: time,
            note: '',
            assignedUser: assignedUser() as number,
            id: 0,
        }
    }
    state.dialog = true
}

/**
 * @name AddReminderDialog
 * Dialog in order screen to allow user to add a reminder to the order
 *
 * @SetupMethod saveReminder - saves reminder and refreshes orders
 * @SetupMethod checkTime - checks time of reminder and adds 12 hours if reminder is before 8
 * @SetupMethod open - takes in a reminder and sets reminder if there is one. If there is no reminder, sets the date and preps user to add reminder
 */

export default defineComponent({
    components: {
        AEditor,
        ValidationObserver,
        AmcUserSelect,
    },
    setup(props, { refs, emit }) {
        const close = () => (state.dialog = false)

        const resetForm = () => (refs.form as InstanceType<typeof ValidationObserver>).reset()

        const saveReminder = () => {
            emit('reminder-added')

            const dt = DateTime.fromFormat(state.reminder.date + ' ' + state.reminder.time, 'yyyy-MM-dd HH:mm', {
                zone: 'local',
            }).toUTC()

            state.error = false

            if (dt.ts < DateTime.now()) {
                state.error = true
                return
            }

            const reminder = {
                user: state.reminder.assignedUser,
                date: dt.toISO(),
                note: state.reminder.note,
            } as Reminder

            if (state.reminder.id) {
                reminder.id = state.reminder.id

                const updatedReminder = {
                    user_id: reminder.user,
                    reminder_date: reminder.date as string | number,
                    note: reminder.note,
                    id: reminder.id,
                }

                axios.patch('/v1/reminder/' + state.reminder.id, updatedReminder).then((response) => {
                    state.dialog = false

                    if (!order.value?.incremental) return
                    order.value.incremental.reminders = order.value?.incremental?.reminders.map((item: Reminder) => {
                        if (reminder.id === item.id) {
                            return response.data.reminder
                        }
                        return item
                    })
                    showSnackbar('Reminder Updated Successfully')
                })

                updatedReminder.reminder_date = new Date(updatedReminder.reminder_date as string).getTime()
                originalReminder.reminder_date = updatedReminder.reminder_date
                originalReminder.note = updatedReminder.note
                resetForm()
                AscentKeyboardEvents.focusEditor()
                return
            }

            if (!order.value) return
            $axios.post('/v1/order/' + order.value.id + '/reminder', reminder).then((response) => {
                state.dialog = false
                if (!order.value?.incremental) return
                order.value.incremental.reminders.push(response.data.reminder)
                if (queueReminder.value && queueTitle.value != 'Appraiser Late') order.value = undefined
                showSnackbar('Reminder Added Successfully')
            })
            resetForm()
        }

        const checkTime = (data: string) => {
            const hour = parseInt(data)
            const ampm = state.reminder.time.split(':')
            if (hour <= 8) {
                ampm[0] = (parseInt(ampm[0]) + 12).toString()
                state.reminder.time = `${ampm[0]}:${ampm[1]}`
            }
        }

        return {
            resetForm,
            disablePastDates,
            open,
            state,
            saveReminder,
            close,
            checkTime,
        }
    },
})
