


















import { defineComponent, computed, ref, onMounted, ComputedRef } from '@vue/composition-api'
import AppraiserNotesList from '@/pages/Appraiser/Profile/AMC/Partials/AppraiserNotesList.vue'
import { AppraiserOption, Appraiser } from '@/types'
import $ascent from '@/helpers/ascent-helper'
import $axios from '@/plugins/axios'
import {
    statuses,
    canEdit,
    appraiserOptions,
    appraiserValue,
    amcStatus,
    appraiserSettings,
    appraiserSettingsOptions,
    AppraiserSettings,
} from '@/pages/Appraiser/Profile/AppraiserProfile'
import { checkStatuses } from '@/pages/Appraiser/Profile/AppraiserProfile'
import AppraiserGeneralSettings from '@/pages/Appraiser/Profile/SharedProfile/AppraiserGeneralSettings.vue'
import AppraiserOfficeSettings from '@/pages/Appraiser/Profile/AppraisalOffice/AppraiserOfficeSettings.vue'
import { user } from '@/plugins/User'
import AppraiserSettingsPage from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPage.vue'

export const appraiserTagColors = {
    Flagstar: 'red lighten-1',
    Jumbo: 'blue',
    'New Construction': 'teal',
    Probation: 'orange darken-4',
    'Service Issues': 'error',
    'Important Notes': 'warning',
    'Priority Appraiser': 'success',
    'S1L +$100': 'success',
    Golden: 'yellow darken-2',
    Staff: 'blue darken-4',
    'Fix and Flip': 'pink lighten-2',
}

export const appraiser = ref({
    options: [] as AppraiserOption[],
} as Appraiser)

const lastUpdated = ref('')

export const assignDate: ComputedRef<string> = computed(() => {
    if (!appraiser.value || !appraiser.value.all_options) return 'N/A'
    return $ascent.formatIsoDate(lastUpdated.value, 'EEE, MMM d, t')
})

export default defineComponent({
    components: {
        AppraiserNotesList,
        AppraiserGeneralSettings,
        AppraiserOfficeSettings,
        AppraiserSettingsPage,
    },
    setup(_, { root }) {
        const status = ref('')
        const amc_status = ref('')
        const options = ref()
        const navItems = ref()

        const getOptions = () => {
            $axios.get('/v1/appraiser/' + root.$route.params.id + '/options').then((response) => {
                appraiserOptions.value = Object.assign(appraiserOptions.value, response.data.options)
                appraiser.value = response.data.appraiser
                if (response.data.appraiser.all_options[response.data.appraiser.all_options.length - 1])
                    lastUpdated.value =
                        response.data.appraiser.all_options[response.data.appraiser.all_options.length - 1].updated_at
                appraiserValue.value = response.data.appraiser
                amc_status.value = response.data.appraiser.status
                amcStatus.value = response.data.appraiser.status
            })
            checkStatuses(root.$route.params.id)
        }

        onMounted(async () => {
            await getOptions()
            $axios.get('/v1/settings/appraiser-settings').then((response) => {
                options.value = response.data.result
                appraiserSettings.value = response.data.result
                navItems.value = appraiserSettings.value.map((elem: AppraiserSettings) => {
                    return elem.type
                })
                response.data.result.map((elem: AppraiserSettings) => {
                    if (elem.type === 'settings-page') appraiserSettingsOptions.value = elem.options
                })
            })
        })

        return {
            status,
            amc_status,
            appraiser,
            appraiserOptions,
            assignDate,
            options,
            canEdit,
            statuses,
            appraiserTagColors,
            appraiserSettings,
            user,
            navItems,
            getOptions,
        }
    },
})
