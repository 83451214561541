





















































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { HoldForApprovalOption, LenderOptions } from '@/types'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const optionLocal = ref({
            hold: false,
            notifyEmail: '',
        } as HoldForApprovalOption)

        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as HoldForApprovalOption
        }

        const emails = ref(JSON.parse(JSON.stringify(optionLocal.value.notifyEmail)))

        const save = () => {
            const tempLocal = { ...optionLocal.value, notifyEmail: emails.value }

            axios
                .post('/v1/lender/' + lender.value.id + '/option', {
                    option_key: 'hold-for-approval',
                    option_value: tempLocal,
                })
                .then(() => {
                    optionLocal.value = tempLocal
                    dialog.value = false
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            emails,
            save,
            optionLocal,
            dialog,
        }
    },
})
