


































import { defineComponent, PropType } from '@vue/composition-api'
import axios from '@/plugins/axios'
import { Method, AxiosRequestConfig } from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    props: {
        handleSubmit: {
            type: Function as PropType<Function | undefined>,
            default: (cb: Function) => {
                return cb()
            },
        },
        submitData: {
            type: Object,
        },
        method: {
            type: String as PropType<Method>,
            default: 'POST',
        },
        url: {
            type: String,
            default: '',
            required: true,
        },
        btnText: {
            type: String,
            default: 'Save',
        },
        disableSubmit: {
            type: Boolean,
            default: false,
        },
        showCancel: {
            type: Boolean,
            default: true,
        },
        showSubmit: {
            type: Boolean,
            default: true,
        },
        showSnackbarMessage: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loading: false,
            errors: [] as Array<string>,
        }
    },
    methods: {
        submit(): void {
            const request: AxiosRequestConfig = {
                method: this.method,
                url: this.url,
                data: undefined,
                params: undefined,
            }
            if (this.method === 'POST' || this.method === 'PATCH') {
                request.data = this.submitData
            }
            if (this.method === 'GET') {
                request.params = this.submitData
            }
            this.loading = true
            axios
                .request(request)
                .then(({ data }) => {
                    if (data.message && this.showSnackbarMessage) {
                        showSnackbar(data.message)
                    }
                    this.$emit('success', data)
                    this.errors = []
                })
                .catch(({ response }) => {
                    this.errors = response.data.errors ?? [response.data.message]
                    if (typeof this.errors === 'object') {
                        this.errors = Object.values(this.errors)
                    }
                    this.$emit('error', response.data.errors, response.data.message)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
})
