


























import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import axios from 'axios'
import { LenderOptions } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: {},
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
    },
    setup() {
        const optionLocal = ref(false)

        onMounted(() => {
            axios.get(`/v1/lender/${lender.value.id}/option/new-client-tag`).then(({ data }) => {
                optionLocal.value = data.option_value
            })
        })

        const updateOption = (event: Event) => {
            axios
                .post(`/v1/lender/${lender.value.id}/option`, {
                    option_key: 'new-client-tag',
                    option_value: event,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        return {
            optionLocal,
            updateOption,
        }
    },
})
