




import { defineComponent, PropType, computed } from '@vue/composition-api'
import { AuditEventSourceType } from '@/types/OrderAuditLog'
import EventSourceText from '@/components/OrderAuditLog/EventSourceText.vue'
import EventSourceJSON from '@/components/OrderAuditLog/EventSourceJSON.vue'
import EventSourceXML from '@/components/OrderAuditLog/EventSourceXML.vue'
import EventSourceTable from '@/components/OrderAuditLog/EventSourceTable.vue'
import EventSourceHTML from '@/components/OrderAuditLog/EventSourceHTML.vue'

export default defineComponent({
    props: {
        sourceType: {
            type: String as PropType<AuditEventSourceType>,
            required: true,
        },
        source: {
            type: String,
            required: true,
        },
    },
    components: {
        EventSourceText,
        EventSourceJSON,
        EventSourceHTML,
        EventSourceTable,
        EventSourceXML,
    },
    setup(props) {
        const typeToComponent: { [type in AuditEventSourceType]: unknown } = {
            html: EventSourceHTML,
            json: EventSourceJSON,
            table: EventSourceTable,
            text: EventSourceText,
            xml: EventSourceXML,
        }

        const eventSourceComponent = computed(() => {
            return typeToComponent[props.sourceType] || EventSourceText
        })

        return { eventSourceComponent }
    },
})
