















import axios from 'axios'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        label: {
            type: String,
            default: 'Loan Type',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            loanTypes: [],
        }
    },
    mounted() {
        this.getLoanTypes()
    },
    methods: {
        getLoanTypes() {
            this.loading = true
            axios.post('/v1/search', { type: 'loan_type', object: true }).then((response) => {
                this.loanTypes = response.data
                this.loading = false
            })
        },
    },
})
