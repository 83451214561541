<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-icon class="mr-2" small>$vuetify.icons.plus</v-icon>
                <v-list-item-subtitle>Add Fee</v-list-item-subtitle>
            </v-list-item>
        </template>

        <ValidationObserver ref="form" v-slot="{ invalid }">
            <ACard title="Add Product">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <a-select-field
                                v-model="line.form"
                                rules="required"
                                label="Product"
                                :items="fees"
                                name="product"
                                color="secondary"
                                item-value="id"
                                item-text="name"
                                return-object
                            />
                        </v-col>
                        <v-col cols="12">
                            <a-text-field
                                v-model="line.amc_value"
                                :rules="'required'"
                                label="Fee"
                                name="fee"
                                color="secondary"
                                prepend-inner-icon="$vuetify.icons.dollarSign"
                                outlined
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <a-btn-cancel @click="dialog = false" />
                    <v-btn color="primary" :loading="saving" :disabled="invalid" @click="addLine">Add Fee</v-btn>
                </v-card-actions>
            </ACard>
        </ValidationObserver>
    </v-dialog>
</template>

<script lang="js">
import {ValidationObserver} from 'vee-validate'

export default {
    components: {
        ValidationObserver
    },
    props: {
        fees: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            dialog: false,
            saving: false,
            line: {
                form: null,
                amc_value: null,
                appraiser_value: null
            },
            type: null,
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.line = {
                id: null,
                order_id: null,
                product_id: null,
                product_name: null,
                amc_value: null,
                appraiser_value: null
            }
        },
        addLine() {
            this.$emit('fee-added', this.line)
            this.dialog = false
        }
    }
}
</script>
