


























import { defineComponent, watch } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import $axios from '../../../../../plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserSettingsId, appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    components: {},
    props: {
        option: {
            type: Object,
        },
    },

    setup(props) {
        watch(appraiserOptions, () => {
            if (props.option && props.option.stagger) {
                appraiserOptions.value.stagger = props.option.stagger
            }
        })

        if (props.option && props.option.stagger) {
            appraiserOptions.value.stagger = props.option.stagger
        }

        const saveChanges = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'stagger',
                    option_value: appraiserOptions.value.stagger,
                })
                .then(() => {
                    showSnackbar('Saved successfully')
                })
        }

        return {
            saveChanges,
            lender,
            appraiserOptions,
        }
    },
})
