const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Virgin Islands',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
]

export default states

export const stateValues = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
]

export const stateNames = [
    { states: 'Alabama' },
    { states: 'Alaska' },
    { states: 'Arizona' },
    { states: 'Arkansas' },
    { states: 'California' },
    { states: 'Colorado' },
    { states: 'Connecticut' },
    { states: 'Delaware' },
    { states: 'District of Columbia' },
    { states: 'Florida' },
    { states: 'Georgia' },
    { states: 'Hawaii' },
    { states: 'Idaho' },
    { states: 'Illinois' },
    { states: 'Indiana' },
    { states: 'Iowa' },
    { states: 'Kansas' },
    { states: 'Kentucky' },
    { states: 'Louisiana' },
    { states: 'Maine' },
    { states: 'Maryland' },
    { states: 'Massachusetts' },
    { states: 'Michigan' },
    { states: 'Minnesota' },
    { states: 'Mississippi' },
    { states: 'Missouri' },
    { states: 'Montana' },
    { states: 'Nebraska' },
    { states: 'Nevada' },
    { states: 'New Hampshire' },
    { states: 'New Jersey' },
    { states: 'New Mexico' },
    { states: 'New York' },
    { states: 'North Carolina' },
    { states: 'North Dakota' },
    { states: 'Ohio' },
    { states: 'Oklahoma' },
    { states: 'Oregon' },
    { states: 'Pennsylvania' },
    { states: 'Puerto Rico' },
    { states: 'Rhode Island' },
    { states: 'South Carolina' },
    { states: 'South Dakota' },
    { states: 'Tennessee' },
    { states: 'Texas' },
    { states: 'Utah' },
    { states: 'Vermont' },
    { states: 'Virginia' },
    { states: 'Virgin Islands' },
    { states: 'Washington' },
    { states: 'West Virginia' },
    { states: 'Wisconsin' },
    { states: 'Wyoming' },
]
