
















































import { defineComponent, PropType, UnwrapRef } from '@vue/composition-api'
import { Note } from '@/types'
import { changeRead } from '@/plugins/Notes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

/**
 * @name AppraiserPanelNotes
 * Partial in the expanded assign table to show the notes that were sent and received from specfic appraiser in the panel
 *
 * @SetupMethod noteIcon - takes in note and returns icon depending on the target or source of the note
 * @SetupMethod noteColor - takes in note and returns color depending  on target and source of the note
 * @SetupMethod changeReadStatus - takes in note and marks the note as read
 */

export default defineComponent({
    props: {
        /**
         * Notes send to and from the appraiser
         */
        notes: {
            type: [] as PropType<Note[]>,
        },
    },
    setup() {
        const noteIcon = (note: Note) => {
            if (note.target == 'Assign Email') return '$vuetify.icons.envelope'
            if (note.target == 'Assign Phone') return '$vuetify.icons.phoneAlt'
            if (note.source == 'Prior Appraiser' && note.target == 'AMC') return '$vuetify.icons.user'
            if (note.source == 'AMC' && note.target == 'Prior Appraiser') return '$vuetify.icons.buildingLight'
        }

        const noteColor = (note: Note) => {
            if (note.target == 'Assign Email') return 'primary'
            if (note.target == 'Assign Email') return 'secondary'
            if (note.source == 'Prior Appraiser' && note.target == 'AMC') return 'warning'
            if (note.source == 'AMC' && note.target == 'Prior Appraiser') return '#00897B'
        }

        const changeReadStatus = (note: UnwrapRef<Note | undefined>) => {
            changeRead(note as Note).then(() => {
                showSnackbar('Note Updated')
            })
        }

        return {
            noteIcon,
            noteColor,
            changeReadStatus,
        }
    },
})
