



















































import { defineComponent } from '@vue/composition-api'
import { addTag, order, removeTag } from '@/plugins/order/Order'
import { orderTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import { user } from '@/plugins/User'
import {
    getModHistory,
    openDialog,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/ModFeeRequestDialog.vue'
import { Tag } from '@/types'
import { openTagActionDialog } from '@/components/TagActions/TagActionDialog.vue'

export default defineComponent({
    name: 'CustomerOrderTags',
    components: {},
    setup() {
        const handleTagClick = (tag: Tag) => {
            if (tag.action_id) {
                openTagActionDialog(tag)
                return
            }

            switch (tag.tag) {
                case 'Mod Fee Req':
                    openDialog.value = true
                    getModHistory(tag)
                    return
                default:
                    return
            }
        }

        return {
            order,
            orderTags,
            user,
            removeTag,
            addTag,
            openDialog,
            getModHistory,
            handleTagClick,
        }
    },
    computed: {
        filteredTags() {
            if (!order.value?.tags) return []
            if (order.value?.tags?.length < 1) return []
            const tags = order.value?.tags.filter((tag) => {
                if (tag.showOnOrder === false) return false
                if (user.value?.type === 'client' && tag.showToClient === true) return true
                if (user.value?.type === 'appraiser' && tag.showToAppraiser === true) return true
                return user.value?.type !== 'appraiser' && user.value?.type !== 'client'
            })
            return tags
        },
    },
})
