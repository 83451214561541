

















































































import { defineComponent, onMounted, ref, computed } from '@vue/composition-api'
import axios from 'axios'
import { user } from '@/plugins/User'
import { orderAuditLogOpen, order } from '@/plugins/order/Order'
import EventsList from '@/components/OrderAuditLog/EventsList.vue'
import { AuditEvent } from '@/types/OrderAuditLog'
import EventDetail from '@/components/OrderAuditLog/EventDetail.vue'
import EventFilter from '@/components/OrderAuditLog/Filter.vue'
import DateFilter from '@/components/OrderAuditLog/DateFilter.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { DateTime } from 'luxon'
import CustomerOrderToolbar from '@/components/OrderScreens/Customer/CustomerLayout/CustomerOrderToolbar.vue'

export default defineComponent({
    components: {
        EventsList,
        EventDetail,
        EventFilter,
        DateFilter,
        CustomerOrderToolbar,
    },
    setup() {
        const loading = ref(true)
        const downloadLoading = ref(false)
        const events = ref<AuditEvent[]>([])
        const selectedEvent = ref<AuditEvent | null>(null)
        const userFilter = ref<{ id: string; title: string } | null>(null)
        const typeFilter = ref<{ id: string; title: string } | null>(null)
        const dateFilter = ref<{ from: string; to: string }>({ from: '', to: '' })

        const filteredEvents = computed(() => {
            return events.value.filter((e) => {
                if (userFilter.value?.id && userFilter.value?.id !== String(e.user.id)) return false
                if (typeFilter.value?.id && typeFilter.value?.id !== e.event) return false

                const createdAt = DateTime.fromISO(e.created_at)
                const from = DateTime.fromISO(dateFilter.value.from, { zone: 'UTC' })
                const to = DateTime.fromISO(dateFilter.value.to, { zone: 'UTC' }).plus({ days: 1 })
                if (dateFilter.value.from && dateFilter.value.to && (createdAt < from || createdAt > to)) return false

                return true
            })
        })

        const selectEvent = (event: AuditEvent) => {
            selectedEvent.value = event
        }

        const close = () => {
            orderAuditLogOpen.value = false
        }

        const onDownload = () => {
            downloadLoading.value = true
            axios
                .post(`v1/order/${order.value?.id}/audit-logs/download`)
                .then((response) => {
                    location.href = response.data.message
                    downloadLoading.value = false
                })
                .catch(() => {
                    showSnackbar('Error exporting Order Audit Log', 'error')
                    downloadLoading.value = false
                })
        }

        onMounted(() => {
            loading.value = true
            axios
                .get(`v1/order/${order.value?.id}/audit-logs`)
                .then((response) => {
                    events.value = response.data
                    selectedEvent.value = events.value[0]
                    loading.value = false
                })
                .catch(() => {
                    loading.value = false
                    showSnackbar('Error fetching Order Audit Log', 'error')
                })
        })

        return {
            user,
            loading,
            downloadLoading,
            order,
            orderAuditLogOpen,
            close,
            events,
            selectedEvent,
            selectEvent,
            filteredEvents,
            userFilter,
            typeFilter,
            dateFilter,
            onDownload,
        }
    },
})
