



















































import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { files } from '@/plugins/Files'

import InvoiceFile from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/InvoiceFile.vue'
import AmcFileComponent from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AmcFileComponent.vue'
import SendInvoiceDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/SendInvoiceDialog.vue'
import EditFile, {
    open,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditFileDialog.vue'

export default defineComponent({
    components: {
        InvoiceFile,
        AmcFileComponent,
        SendInvoiceDialog,
        EditFile,
    },
    setup() {
        const downloadPDF = (url: string) => {
            window.location.href = url
        }

        const pdfUrl = computed(() => {
            return process.env.VUE_APP_PDF
        })
        return {
            order,
            files,
            open,
            downloadPDF,
            pdfUrl,
        }
    },
})
