












































































































import { defineComponent, ref } from '@vue/composition-api'
import { CommercialDetails } from '@/types'
import { ValidationObserver } from 'vee-validate'
import { order, orderCommercial } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

const dialog = ref(false)
const editCommercialData = ref({
    parcel: '',
    notes: '',
    propertyInterest: '',
    approachToValue: [],
    valueScenarios: [],
} as CommercialDetails)
export const openEditOfficeCommercialDialog = (commercialData: CommercialDetails) => {
    if (commercialData) {
        editCommercialData.value = JSON.parse(JSON.stringify(commercialData))
    }
    dialog.value = true
}

export default defineComponent({
    components: {
        ValidationObserver,
    },
    setup() {
        const commercialDataSaved = () => {
            showSnackbar('Commercial Data Saved Successfully!')
            orderCommercial.value = JSON.parse(JSON.stringify(editCommercialData.value))
            dialog.value = false
        }
        const filterApproachToValue = () => {
            editCommercialData.value.approachToValue = editCommercialData.value.approachToValue.filter(
                (elem) => elem === 'All Applicable Approaches',
            )
        }

        return {
            editCommercialData,
            dialog,
            order,
            commercialDataSaved,
            filterApproachToValue,
        }
    },
})
