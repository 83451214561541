import $axios from '@/plugins/axios'
import { ref } from '@vue/composition-api'

interface Approval {
    id: number
    customer_order_id?: string
    approval_status: string
    approval_details: string
}

interface DataType {
    order: { id: number }
    ticket: boolean
    message: string
    event_type: string
}

interface Notification {
    data: DataType
    actionType: string
}

export const approvals = ref({
    pending: [] as Notification[],
    recentlyResolved: [] as Notification[],
    showNotifications: false,
})

const process = (type: string, orders: Approval[]) => {
    const approvalNotifications = orders.map((order: Approval) => {
        return {
            actionType: 'order',
            data: {
                event_type: type === 'pending' ? 'Approval Request' : order.approval_status,
                message: order.approval_details,
                order: { id: order.id, customer_order_id: order.customer_order_id },
                ticket: false,
            } as DataType,
        }
    })
    switch (type) {
        case 'pending':
            approvals.value.pending = approvalNotifications
            break
        case 'resolved':
            approvals.value.recentlyResolved = approvalNotifications
            break
    }
}

export const getPendingApprovals = () => {
    $axios
        .post('/v1/order-table', {
            filter: 'Approvals',
            criteria: null,
            viewUser: -1,
            search: null,
        })
        .then((response) => {
            process('pending', response.data.result)
        })
}

export const getResolvedApprovals = () => {
    $axios
        .post('/v1/order-table', {
            filter: 'Recent Approvals',
            criteria: null,
            viewUser: -1,
            search: null,
        })
        .then((response) => {
            process('resolved', response.data.result)
        })
}

export const groupedApprovalNotifications = () => [
    {
        title: 'Pending Approvals',
        items: approvals.value.pending,
        unread: true,
        canToggle: false,
    },
    {
        title: 'Recently Resolved Approvals',
        items: approvals.value.recentlyResolved,
        unread: false,
        canToggle: false,
    },
]

export const setShowApprovalNotifications = () => {
    approvals.value.showNotifications = !approvals.value.showNotifications
}
