var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"30px"},on:{"mouseover":function($event){_vm.show = true},"mouseleave":function($event){_vm.show = false}}},[(_vm.item.appraiser.id === _vm.selectedAppraiser)?_c('v-chip',{attrs:{"small":"","color":"secondary","label":""}},[_vm._v(" "+_vm._s(_vm.item.appraiser.status === 'Active' ? 'Assigned' : 'Assigned Override')+" ")]):_vm._e(),(_vm.item.eligible.result === false)?_c('v-chip',{attrs:{"small":"","color":"error","outlined":"","label":""}},[_vm._v("Not Eligible")]):_vm._e(),(_vm.item.eligible.result === false)?_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.item.eligible.details)+" ")]):_vm._e(),(
            _vm.item.eligible.result &&
            !_vm.isSameAppraiser &&
            _vm.allowSelect &&
            !_vm.isConditional &&
            _vm.order &&
            _vm.order.status !== 'Payment Hold' &&
            _vm.show &&
            _vm.$ascent.featureEnabled('orderScreen.assign.quickAssign')
        )?_c('v-btn',{attrs:{"small":"","color":"secondary ","depressed":""},on:{"click":_vm.assignOrder}},[_vm._v(" Assign ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }