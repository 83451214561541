
































import { defineComponent } from '@vue/composition-api'
import { OrderModel } from '@/types'
import { order, orderTotalPayments, orderTotalDue } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        return {
            order,
        }
    },
    props: {},
    data() {
        return {
            orderTotalPayments,
            orderTotalDue,
        }
    },
    computed: {
        totalDueClass() {
            if (orderTotalPayments.value > (order.value as OrderModel).fee) return 'warning'
            if (orderTotalPayments.value == (order.value as OrderModel).fee) return 'success'
            return 'error'
        },
    },
})
