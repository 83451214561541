





























import { defineComponent, ref } from '@vue/composition-api'
import { AscentGrid } from '@/helpers/AscentDataAdapter'
import ListingIdTemplate from './ListingIdTemplate.vue'

export default defineComponent({
    props: {
        comps: {
            type: Array,
        },
    },
    setup(props) {
        const compsDataSource = ref()

        const tableSettings = ref({
            dataSource: [],
            allowResizing: false,
            allowTextWrap: true,
            allowExcelExport: true,
        } as AscentGrid)

        if (props.comps) {
            compsDataSource.value = props.comps
        }

        const ListingColumn = () => {
            return {
                template: ListingIdTemplate,
            }
        }

        return { tableSettings, compsDataSource, formatOptions: { type: 'date', format: 'MM/dd/yyyy' }, ListingColumn }
    },
})
