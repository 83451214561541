






import { defineComponent } from '@vue/composition-api'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getEscalationTags } from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/EscalationTags'
import $axios from '@/plugins/axios'
import { EscalationTag } from '@/types'

export default defineComponent({
    setup() {
        const deleteTag = (tag: EscalationTag) => {
            confirm('Delete Tag?', 'Are you sure you want to delete this tag?').then((confirm) => {
                if (confirm) {
                    $axios.delete(`/v1/escalation/${tag.id}`).then(() => {
                        showSnackbar('Tag successfully deleted!')
                        getEscalationTags()
                    })
                }
            })
        }

        return {
            deleteTag,
        }
    },
})
