var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"dense":"","color":"#f5f5f5"}},[_c('v-toolbar-title',{attrs:{"color":"accentWhite"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"accentBlack"}},[_vm._v("$vuetify.icons.certificate")]),_vm._v(" UCDP/EAD Submissions ")],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-6",attrs:{"tile":""}},[(_vm.order.incremental)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.order.incremental.submissions,"item-key":"id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',[(
                                    item.status != null &&
                                    item.submit_code === '1' &&
                                    typeof item.status.submission != 'undefined'
                                )?_c('v-chip',{attrs:{"label":"","small":"","color":item.status.submission['@attributes']._Condition === 'SUCCESS'
                                        ? 'success'
                                        : 'warning'}},[_vm._v(" "+_vm._s(item.status.submission['@attributes']._Condition)+" ")]):_vm._e(),(
                                    item.status != null &&
                                    item.submit_code === '1' &&
                                    typeof item.status.result != 'undefined'
                                )?_c('v-alert',{staticClass:"my-2 py-1",attrs:{"color":"error","dense":""}},[_c('span',{staticClass:"white--text caption",domProps:{"innerHTML":_vm._s(item.status.Error)}})]):_vm._e(),(item.status == null && item.submit_code === '1' && item.complete === 0)?_c('v-chip',{attrs:{"label":"","small":"","color":"info"}},[_vm._v(" Processing ")]):_vm._e(),(item.status == null && item.submit_code === '1' && item.complete === 2)?_c('v-chip',{attrs:{"label":"","small":"","color":"info"}},[_vm._v(" Delayed Result ")]):_vm._e(),(item.submit_code === '3')?_c('v-chip',{attrs:{"label":"","small":"","color":"error"}},[_vm._v("Failed")]):_vm._e(),(item.status == null && item.submit_code === '1' && item.complete === 2)?_c('v-btn',{attrs:{"small":"","color":"secondary","loading":_vm.checking},on:{"click":function($event){return _vm.reSubmit(item)}}},[_vm._v(" Refresh Result ")]):_vm._e()],1)]}},{key:"item.file",fn:function(ref){
                                var item = ref.item;
return [_c('td',[_c('v-btn',{attrs:{"icon":"","color":"secondary","target":"_blank"},on:{"click":function($event){return _vm.openFile(item.file_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.view")])],1),_c('v-btn',{attrs:{"icon":"","color":"secondary","target":"_blank"},on:{"click":function($event){return _vm.download(item.file_id, _vm.order.id + '_Appraisal.xml')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.download")])],1)],1)]}},{key:"item.date",fn:function(ref){
                                var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$ascent.formatIsoDate(item.created_at, 'EEE, MMM d, t'))+" ")])]}},{key:"item.active",fn:function(ref){
                                var item = ref.item;
return [_c('td',[(item.active)?_c('v-chip',{attrs:{"label":"","color":"primary","close":(item.status != null && item.status.result !== undefined) ||
                                    item.submit_code !== '1'},on:{"click:close":function($event){return _vm.removeActive(item)}}},[_vm._v(" Active ")]):_vm._e()],1)]}}],null,true)}),_c('DownloadFile')],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }