


















import { defineComponent, PropType } from '@vue/composition-api'
import { AppraiserPanel } from '@/types'
import { allowSelect } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { sendQuotes } from '@/pages/AMC/Quotes/Quotes'
import { sendQuote } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignTable/TablePartials/AssignTablePrepend.vue'

/**
 * @name SendQuotePartial
 * Partial in the assign table where user can select multiple appraisers to send a quote to
 *
 * @SetupMethod openQuote - emits value to open up quote dialog
 */

export default defineComponent({
    props: {
        /**
         * Appraiser in the appraiser panel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup(props, { emit }) {
        const openQuote = () => {
            if (!props.item) return
            emit('open-quote', props.item)
        }

        return {
            allowSelect,
            openQuote,
            sendQuotes,
            sendQuote,
        }
    },
})
