



































import AppHeader from '@/AppLayout//AppHeader.vue'
import AppFooter from '@/AppLayout//AppFooter.vue'
import LeftNav from '@/AppLayout//navigation/LeftNav.vue'
import RightNav from '@/AppLayout//navigation/RightNav.vue'
import { defineComponent } from '@vue/composition-api'
import OrderScreen from '@/AppLayout//order/OrderScreen.vue'
import OrderAuditLog from '@/components/OrderAuditLog/OrderAuditLog.vue'
import ScriptInjector from '@/ScriptInjector.vue'
import Prevent from '@/AppLayout/helpers/Prevent.vue'
import DownloadFile from '@/components/General/DownloadFile.vue'
import { user } from '@/plugins/User'
import NotificationSnackbar from '@/pages/System/Notifications/NotificationSnackbar.vue'
import { orderOpen, orderAuditLogOpen } from '@/plugins/order/Order'
import AddReminderDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderReminders/AddReminderDialog.vue'
import { updateReminderAdded } from '@/pages/AMC/Dashboard/Processes/DashboardProcesses'
import { getSession } from '@/helpers/token-helper'

export default defineComponent({
    name: 'App',
    metaInfo: {
        title: 'Main',
        titleTemplate: '%s | JaroDesk',
    },
    components: {
        NotificationSnackbar,
        DownloadFile,
        Prevent,
        LeftNav,
        AppHeader,
        AppFooter,
        OrderScreen,
        ScriptInjector,
        RightNav,
        AddReminderDialog,
        OrderAuditLog,
    },
    setup() {
        const isProduction = process.env.VUE_APP_ENV === 'production'

        return {
            isProduction,
            user,
            orderOpen,
            updateReminderAdded,
            orderAuditLogOpen,
        }
    },
    mounted() {
        if (Object.keys(getSession()).length === 0) {
            this.$axios.get('/sanctum/csrf-cookie')
        }
    },
})
