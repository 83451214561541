









import { defineComponent, ref } from '@vue/composition-api'
import BorrowerPaymentLinksPartial, {
    getPaymentLinks,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderBorrower/BorrowerPaymentLinksPartial.vue'
import BorrowerScheduledDeliveryPartial, {
    getScheduledBorrowerDeliveries,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderBorrower/BorrowerScheduledDeliveryPartial.vue'

const dialog = ref(false)
export const openBorrowerOutboundsDialog = () => {
    dialog.value = true
    getPaymentLinks()
    getScheduledBorrowerDeliveries()
}

export default defineComponent({
    components: {
        BorrowerPaymentLinksPartial,
        BorrowerScheduledDeliveryPartial,
    },
    setup() {
        return { dialog }
    },
})
