var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{staticClass:"mt-1 d-flex",attrs:{"to":"/client/dashboard","active-class":"text-ascent-secondary"},on:{"click":function($event){$event.stopPropagation();_vm.mini = true}}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"ml-n1",attrs:{"size":"20"}},[_vm._v("$vuetify.icons.tachometerAltFast")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("Orders")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("My Orders")])],1)],1),(
            _vm.$ascent.canDo('Order') &&
            _vm.user != null &&
            _vm.user.client != null &&
            _vm.user.client.branch != null &&
            (_vm.user.client.id === 16296 || [64, 188, 92, 183, 450, 5437].indexOf(_vm.user.client.branch.lender_id) === -1)
        )?_c('v-list-item',{staticClass:"d-flex",attrs:{"to":"/client/order","active-class":"text-ascent-secondary"},on:{"click":function($event){$event.stopPropagation();_vm.mini = true}}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"ml-1",attrs:{"size":"20"}},[_vm._v("$vuetify.icons.clipboardList")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("New")]):_vm._e(),(_vm.mini === true)?_c('p',{staticClass:"my-0",staticStyle:{"font-size":"12px"}},[_vm._v("Order")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("New Order")])],1)],1):_vm._e(),(
            _vm.user != null &&
            _vm.user.client != null &&
            _vm.user.client.branch != null &&
            [313, 64, 92, 361, 369, 117, 33, 222, 183, 450].indexOf(_vm.user.client.branch.lender_id) > -1
        )?_c('v-list-item',{staticClass:"d-flex",attrs:{"to":"/client/encompass/orders","active-class":"text-ascent-secondary"},on:{"click":function($event){$event.stopPropagation();_vm.mini = true}}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$vuetify.icons.building")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"11px"}},[_vm._v("Potential")]):_vm._e(),(_vm.mini === true)?_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"11px"}},[_vm._v("Orders")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Potential Orders")])],1)],1):_vm._e(),(
            _vm.user != null &&
            _vm.$ascent.pageEnabled('clients.clientFeeEstimator') &&
            _vm.$ascent.canDo('Client Fee Estimator')
        )?_c('v-list-item',{staticClass:"d-flex",attrs:{"to":"/client/fee-estimator","active-class":"text-ascent-secondary"},on:{"click":function($event){$event.stopPropagation();_vm.mini = true}}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$vuetify.icons.calculator")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"11px"}},[_vm._v("Fee")]):_vm._e(),(_vm.mini === true)?_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"11px"}},[_vm._v("Estimator")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fee Estimator")])],1)],1):_vm._e(),(_vm.user && _vm.user.client != null)?_c('v-list-item',{staticClass:"d-flex",attrs:{"to":'/client/' + _vm.user.client.id + '/profile',"active-class":"text-ascent-secondary"},on:{"click":function($event){$event.stopPropagation();_vm.mini = true}}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$vuetify.icons.user")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("Profile")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Profile")])],1)],1):_vm._e(),(
            _vm.user.client != null && (_vm.$ascent.canDo('Manage Company Users') || _vm.$ascent.canDo('Manage Branch Users'))
        )?_c('v-list-item',{staticClass:"d-flex",attrs:{"to":'/client/users',"active-class":"text-ascent-secondary"},on:{"click":function($event){$event.stopPropagation();_vm.mini = true}}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"ml-n1",attrs:{"size":"20"}},[_vm._v("$vuetify.icons.users")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("Users")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Users")])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && _vm.$ascent.canDo('Order Snapshot Review') && _vm.snapshotReview)?_c('v-list-item',{staticClass:"d-flex",attrs:{"to":"/client/alt-products","active-class":"text-ascent-secondary"},on:{"click":function($event){$event.stopPropagation();_vm.mini = true}}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$vuetify.icons.ballotCheck")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("Review")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Snapshot Review")])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-list-item',{staticClass:"d-flex",attrs:{"to":"/client/order-search","active-class":"text-ascent-secondary"},on:{"click":[_vm.orderSearchFilter,function($event){$event.stopPropagation();_vm.mini = true}]}},[_c('v-list-item-action',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"ml-n1",attrs:{"size":"20"}},[_vm._v("$vuetify.icons.search")]),(_vm.mini === true)?_c('p',{staticClass:"mt-2 mb-0",staticStyle:{"font-size":"12px"}},[_vm._v("Search")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Search")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }