













































import { defineComponent, computed } from '@vue/composition-api'
import { order, refreshOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import AppraiserPaymentDetails from '@/components/OrderScreens/Amc/AMCActions/Payment/AppraiserPaymentDetails.vue'
import Vue from 'vue'
import AscentHelper from '@/helpers/ascent-helper'
import { AppraiserPayment } from '@/types'

export default defineComponent({
    components: { AppraiserPaymentDetails },
    setup() {
        const totalPaid = computed(() =>
            AscentHelper.currency(
                order.value?.incremental?.appraiser_payment.reduce((total, current) => total + current.amount, 0),
            ),
        )

        const confirmOrder = (appraiser_id: number, amount: number) => {
            confirm('Place Order on Hold?', 'This will hold this order from being paid to the appraiser').then(
                (confirm) => {
                    if (confirm) {
                        if (!order.value) return
                        $axios
                            .post('/v1/order/' + order.value.id + '/action/hold-appraiser-payment', {
                                amount: amount,
                                appraiser_id: appraiser_id,
                            })
                            .then(() => {
                                refreshOrder()
                                showSnackbar('Order on Hold')
                            })
                    }
                },
            )
        }

        const holdOrder = () => {
            if (!order.value || !order.value.incremental) return
            if (order.value.incremental.order_appraisers?.length > 0) {
                order.value.incremental.order_appraisers.forEach((value) => {
                    confirmOrder(value.appraiser_id, value.fee)
                })
                return
            }

            confirmOrder(order.value.appraiser_id as number, order.value.appraiserFee as number)
        }
        const resumeOrder = () => {
            confirm('Remove Order Payment Hold?', 'This will enable the order to be paid').then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    $axios.post('/v1/order/' + order.value.id + '/action/resume-appraiser-payment').then(() => {
                        refreshOrder()
                        showSnackbar('Order Resumed')
                    })
                }
            })
        }

        return {
            order,
            holdOrder,
            totalPaid,
            resumeOrder,
        }
    },
    methods: {
        openPayment(item: AppraiserPayment) {
            const details = this.$refs.details as Vue & { open: (item: AppraiserPayment) => void }
            details.open(item)
        },
        openDialog() {
            this.$emit('open-client-payment')
        },
    },
})
