























































import { defineComponent, ref, onMounted, computed } from '@vue/composition-api'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { User } from '@/types'

export const returnAppraiser = (): void => {
    confirm(
        'Return file to appraiser?',
        'This will move the file back to the previous status. You will need to send a separate note to explain to the appraiser.',
        { confirm: 'Return to Appraiser', x_btn: true },
    ).then((confirm) => {
        if (confirm) {
            if (!order.value) return
            $axios
                .post('/v1/quality/action/' + order.value.id + '/reject-appraisal-submission')
                .then(() => {
                    if (!order.value) return
                    showSnackbar('Order Moved Successfully')
                    refreshOrder()
                })
                .catch(() => {
                    showError('Sorry! Something went wrong.')
                })
        }
    })
}

/**
 * @name QualityAction
 * Workflow step in AMC workflow that opens the quality tool or sends order back to appraiser
 *
 * @SetupData reviewers - reviewers that can work on the QC
 * @SetupMethod returnAppraiser - returns the order to the appraiser to work on.
 */

export default defineComponent({
    setup() {
        const reviewers = ref([] as User[] | null)
        const rovLink = ref(false)

        const reconsiderationURL = computed(() => {
            if (!order.value) return ''
            if (!order.value.incremental) return ''
            return '/appraisal/reconsideration/' + order.value.id + '/' + order.value.incremental.token
        })

        onMounted(() => {
            if (!order.value) return
            $axios.get(`/v1/order/${order.value.id}/action/get-active-quality-review-details`).then((response) => {
                if (response.data.reviewers === undefined) reviewers.value = null
                if (response.data.reviewers !== undefined) reviewers.value = response.data.reviewers
            })

            if (!order.value) return
            $axios.get('v1/lender/' + order.value.lender_id + '/option/reconsideration-of-value').then((response) => {
                if (response.data === '') {
                    return rovLink.value
                }
                if (response.data.option_value.rovLink) {
                    return (rovLink.value = response.data.option_value.rovLink)
                }
                rovLink.value = false
            })
        })

        return {
            order,
            rovLink,
            reconsiderationURL,
            returnAppraiser,
            reviewers,
        }
    },
    props: {},
    data() {
        return {
            step: 1,
        }
    },
    methods: {
        complete() {
            this.step = this.step + 1
        },
        goBack() {
            this.step = this.step - 1
        },
    },
})
