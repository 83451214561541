































import { AppraiserPanel } from '@/types'
import { defineComponent, computed, ref } from '@vue/composition-api'

export default defineComponent({
    emits: ['handleAssign'],
    props: {
        item: {
            type: Object as () => AppraiserPanel,
            required: true,
        },
        largePanelViewer: {
            type: Boolean,
            default: false,
        },
        canAssign: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const showOverrideDetails = ref(false)
        const isEligible = computed(() => {
            return props.item && props.item.eligible && props.item.eligible.result
        })

        const smallButtonClass = computed(() => {
            if (!isEligible.value) {
                return 'error--text'
            }
            return 'primary--text'
        })

        const largeButtonClass = computed(() => {
            if (!isEligible.value) {
                return 'error'
            }
            return 'primary'
        })

        const handleAssign = () => {
            emit('handleAssign', props.item)
        }

        return {
            smallButtonClass,
            largeButtonClass,
            isEligible,
            showOverrideDetails,
            handleAssign,
        }
    },
})
