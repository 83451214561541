<template>
    <v-dialog v-model="dialog" width="900">
        <template v-if="activator" v-slot:activator="{ on }">
            <v-list-item v-on="on">
                <v-list-item-title>Remove From Follow Up</v-list-item-title>
            </v-list-item>
        </template>
        <ACard title="Remove From Follow Up">
            <v-card-text>
                <v-row>
                    <v-col>
                        <p class="text-xl-h4">Add A Snooze?</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-date-picker v-model="snoozeDate" reactive full-width />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <a-select-field
                            v-model="hour"
                            rules="required"
                            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                            name="hour"
                            label="Hour"
                        />
                    </v-col>
                    <v-col>
                        <a-select-field
                            v-model="minute"
                            rules="required"
                            :items="['00', '15', '30', '45']"
                            name="minute"
                            label="Minute"
                        />
                    </v-col>
                    <v-col>
                        <a-select-field
                            v-model="time"
                            rules="required"
                            :items="['AM', 'PM']"
                            name="time"
                            label="AM/PM"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <a-btn-cancel @click="dialog = false" />
                <a-btn-confirm :loading="saving" @click="remove">Remove</a-btn-confirm>
            </v-card-actions>
        </ACard>
    </v-dialog>
</template>

<script lang="js">
import {defineComponent} from '@vue/composition-api'
import {order} from '@/plugins/order/Order'

/**
 * @name QcFollowUpRemoveDialog
 * Removes order from the follow up queues for QC
 *
 *
 */

export default defineComponent({
    setup() {
        return {
            order
        }
    },
    props: {
        /**
         * Variable to show if activator is going to be present or not. Defaults to true
         */
        activator: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dialog: false,
            saving: false,
            /**
             * How long to snooze the followup
             */
            snoozeDate: null,
            /**
             * Hour to snooze to
             */
            hour: 12,
            /**
             * minute to snooze to
             */
            minute: '00',
            /**
             * Time of day to snooze to
             */
            time: 'PM',
        }
    },
    methods: {
        open() {
            this.dialog = true
        },
        /**
         * Function to remove the follow up and snooze it to a different date/time
         */
        remove() {
            if (!order.value) return
            this.saving = true
            this.$axios.post('/v1/order/' + order.value.id + '/action/remove-qc-followup', {
                date: this.snoozeDate,
                snooze: this.snoozeDate + ' ' + this.hour + ':' + this.minute + ' ' + this.time
            }).then(() => {
                this.saving = false
                this.dialog = false
                this.$emit('snoozed')
            })
        }
    }
})
</script>
