



















import { computed, defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { File } from '@/types'
import UrlFileUpload from '@/components/General/UrlFileUpload.vue'
import FileUpload from '@/components/General/FileUpload.vue'

export default defineComponent({
    name: 'CommercialComplete',
    components: { UrlFileUpload, FileUpload },
    setup(_, { emit }) {
        const url = computed(() => {
            if (!order.value) return
            return 'order/' + order.value.id
        })
        const appraisalFilePath = ref('')
        const addAppraisalFile = (file: File) => {
            appraisalFilePath.value = file.path
            emit('input', file.path)
        }
        return {
            order,
            appraisalFilePath,
            url,
            addAppraisalFile,
        }
    },
})
