


























































































































import { ref, defineComponent } from '@vue/composition-api'

/**
 * @name ClientPaymentDetails
 * Gives the details of a client payment when clicked on
 */

export default defineComponent({
    props: {
        /**
         * Specific payment that will be opened in the dialog
         */
        paymentIdentifier: {
            type: Object,
        },
    },
    setup(props) {
        const payment = ref(props.paymentIdentifier)
        const dialog = ref(false)
        const clicked = () => {
            dialog.value = true
        }
        const close = () => {
            dialog.value = false
        }
        return { payment, dialog, clicked, close }
    },
})
