import { AscentColumnModel, AscentGrid } from '@/helpers/AscentDataAdapter'
import { Dialog } from '@syncfusion/ej2-popups'
import { DatePicker, DateRangePicker, RangeEventArgs, ChangedEventArgs } from '@syncfusion/ej2-calendars'
import { createElement } from '@syncfusion/ej2-base'
import { DropDownListModel, ChangeEventArgs as DropDownChange } from '@syncfusion/ej2-dropdowns'
import { Filter } from '@syncfusion/ej2-grids'
import { setInitialFilter } from '@/components/OrderTable/Classes/OrderFilters'

declare interface FilterMenuInstance {
    dropOptr: DropDownListModel
}

export default class {
    public value: Date | Date[] = new Date()

    public choice = 'Equal'
    public rangeValue: Date[] = [new Date(), new Date()]

    getFilter = () => {
        return {
            create: (args: {
                column: AscentColumnModel
                target: HTMLElement
                getOptrInstance: FilterMenuInstance
                dialogObj: Dialog
            }) => {
                args.getOptrInstance.dropOptr.value = this.choice
                args.dialogObj.buttons = [args.dialogObj.buttons[1], args.dialogObj.buttons[0]]
                const datePickerInstance = new DatePicker({
                    placeholder: 'Select a Date',
                    openOnFocus: true,
                    value: this.value as Date,
                    change: (e: ChangedEventArgs | undefined) => {
                        if (e !== undefined) {
                            this.value = e.value as Date
                        }
                    },
                })
                const flValInput = createElement('input', {
                    className: 'flm-input',
                    id: 'datePicker',
                })
                args.target.appendChild(flValInput)
                datePickerInstance.appendTo(flValInput)
                const dateRangeInstance = new DateRangePicker({
                    placeholder: 'Select a Range',
                    openOnFocus: true,
                    value: this.rangeValue,
                    change: (e: RangeEventArgs | undefined) => {
                        if (e !== undefined) {
                            this.value = e.value as Date[]
                            this.rangeValue = e.value as Date[]
                            e.event?.stopPropagation()
                        }
                    },
                })
                const rangeValInput = createElement('input', {
                    className: 'flm-input',
                    id: 'dateRangePicker',
                })
                args.target.appendChild(rangeValInput)
                dateRangeInstance.appendTo(rangeValInput)
                if (rangeValInput.parentElement != null) {
                    rangeValInput.parentElement.style.display = 'none'
                }
                args.getOptrInstance.dropOptr.change = (e: DropDownChange | undefined) => {
                    if (e !== undefined && e.value === 'between') {
                        if (flValInput.parentElement != null) {
                            flValInput.parentElement.style.display = 'none'
                        }
                        if (rangeValInput.parentElement != null) {
                            rangeValInput.parentElement.style.display = 'inline-flex'
                        }
                    } else {
                        if (flValInput.parentElement != null) {
                            flValInput.parentElement.style.display = 'inline-flex'
                        }
                        if (rangeValInput.parentElement != null) {
                            rangeValInput.parentElement.style.display = 'none'
                        }
                    }
                }
            },
            write: (args: {
                column: AscentColumnModel
                target: HTMLInputElement
                parent: AscentGrid
                filteredValue: undefined | Date | Date[]
            }) => {
                args.filteredValue = this.value
            },
            read: (args: {
                column: AscentColumnModel
                element: HTMLInputElement
                fltrObj: Filter
                operator: string
            }) => {
                this.choice = args.operator
                if (args.operator === 'between') {
                    setInitialFilter([
                        {
                            field: args.column.field as string,
                            matchCase: false,
                            operator: 'greaterthan',
                            predicate: 'and',
                            value: (this.value as Date[])[0],
                        },
                        {
                            field: args.column.field as string,
                            matchCase: false,
                            operator: 'lessthan',
                            predicate: 'and',
                            value: (this.value as Date[])[1],
                        },
                    ])

                    return
                }
                args.fltrObj.filterByColumn(args.column.field as string, args.operator, this.value)
            },
        }
    }
}
