





















import { defineComponent } from '@vue/composition-api'
import { openAssign } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { order } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'
import ReviewOrder from '@/components/OrderScreens/Workflow/ReviewOrder.vue'
import AssignOverride from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignOverride.vue'
import AssignOrderViaJaroPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/AssignOrderViaJaroPanels.vue'

export default defineComponent({
    components: {
        ReviewOrder,
        AssignOverride,
        AssignOrderViaJaroPanels,
    },
    name: 'AssignWorkflowNotice',
    setup() {
        return {
            openAssign,
        }
    },
    computed: {
        isOrderReviewed() {
            if (!order.value?.incremental) return false
            if (!AscentHelper.featureEnabled('reviewOrder')) return true
            return order.value.incremental.reviewed?.value
        },
        /**
         * Determines if needs override approval
         */
        isOverrideNeeded() {
            if (!order.value) return
            if (!order.value?.incremental) return
            return order.value.incremental.assign_override_approval
        },
        /**
         * Determines if order is in a hold status
         */
        isOrderOnHold() {
            if (!order.value) return
            return order.value.status === 'Hold' || order.value.status === 'Payment Hold'
        },
    },
})
