



















































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { LenderOptions } from '@/types'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import LenderDirectiveEdit from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderDirectives/LenderDirectiveEdit.vue'
import LenderDirectiveRevision from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderDirectives/LenderDirectiveRevision.vue'

export default defineComponent({
    name: 'LenderAdminDirectives',
    components: { LenderDirectiveEdit, LenderDirectiveRevision },
    setup() {
        const loaded = ref(false)
        const dialog = ref(false)
        const revisions = ref([] as LenderOptions[])

        const headers = [
            { text: 'Date', value: 'created_at', width: 300, sortable: false },
            { text: 'Created By', value: 'saved_by', sortable: false },
            { value: 'view', width: 200, sortable: false },
        ]

        const updateDirectives = (newDirective: LenderOptions) => {
            revisions.value.push(newDirective)
        }

        const getDirectives = () => {
            axios
                .get('/v1/lender/' + lender.value.id + '/option/admin-directives', {
                    params: {
                        withRevisions: true,
                    },
                })
                .then((response) => {
                    loaded.value = true
                    revisions.value = response.data || []
                })
        }

        onMounted(getDirectives)

        return { dialog, revisions, headers, updateDirectives, loaded }
    },
})
