



































import { defineComponent, PropType } from '@vue/composition-api'
import { AppraiserPanel } from '@/types'

/**
 * @name AppraiserNamePartial
 * Partial in Assign Table that shows Appraiser Name and if that appraiser is a golden or staff appraiser
 */

export default defineComponent({
    props: {
        /**
         * Specific appraiser in the AppraiserPanel
         */
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
        fromJaroPanels: {
            type: Boolean,
            default: false,
        },
    },
})
