






















import { defineComponent, ref } from '@vue/composition-api'
import OfficeSelect from '@/components/General/AutoCompletes/OfficeSelect.vue'
import { AppraiserOffice } from '@/types'
import { order, refreshOrder } from '@/plugins/order/Order'

const dialog = ref(false)

export const openEditAccountingOffice = () => {
    dialog.value = true
}

/**
 * @name EditAccountingOfficeDialog
 * Dialog in the Office Order Screen where user can edit the appraiser's accounting office on the order.
 *
 * @SetupMethod officeSubmitted - refreshes order and closes dialog
 */

export default defineComponent({
    components: {
        OfficeSelect,
    },
    setup() {
        const localOffice = ref({} as AppraiserOffice)
        const officeSubmitted = () => {
            refreshOrder()
            dialog.value = false
        }

        const cancel = () => {
            localOffice.value = {} as AppraiserOffice
            dialog.value = false
        }

        return {
            dialog,
            localOffice,
            officeSubmitted,
            order,
            cancel,
        }
    },
})
