

























































































import { expMonths, expYears, masks } from '@/plugins/Data'
import AscentHelper from '@/helpers/ascent-helper'

interface Payment {
    ccnumber: string
    expmonth: string
    expyear: string
    cvv: string
    name: string
}

import { defineComponent } from '@vue/composition-api'

import { mask } from 'vue-the-mask'
import { AuthNetResponse } from '@/types/creditCard'

function reset() {
    return {
        payment: {
            ccnumber: '',
            expmonth: '',
            expyear: '',
            cvv: '',
            name: '',
        },
        loading: false,
        error: false,
        errorMessage: '',
    }
}

export default defineComponent({
    name: 'AuthorizeNet',
    directives: { mask },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        help: {
            type: Boolean,
            default: true,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        clearFields: {
            type: Boolean,
            default: true,
        },
        customerId: {
            type: Number,
            default: null,
        },
        disallow: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        return { expMonths, expYears, masks }
    },
    data() {
        return reset()
    },
    mounted() {
        const subdomain = process.env.VUE_APP_ENV === 'production' ? 'js' : 'jstest'
        this.injectScript('https://' + subdomain + '.authorize.net/v1/Accept.js')
    },
    methods: {
        injectScript(source: string, body?: string) {
            return new Promise(function (resolve) {
                const scriptTag = document.createElement('script')

                scriptTag.type = 'text/javascript'
                scriptTag.async = true
                if (source !== '') {
                    scriptTag.src = source
                }

                if (body) {
                    const scriptTagContents = document.createTextNode(body)
                    scriptTag.appendChild(scriptTagContents)
                }

                document.head.appendChild(scriptTag)

                scriptTag.addEventListener('load', resolve)
            })
        },
        addCard() {
            this.errorMessage = ''
            const allowed = AscentHelper.checkAllowedCardTypes(this.disallow, this.payment.ccnumber)
            if (!allowed.allowed) {
                this.errorMessage = allowed.message
                this.$emit('error', { error: true, result: false, message: allowed.message })
                return
            }

            if (!this.customerId) return
            this.loading = true
            this.$axios.post('/v1/card/tokens/authorize', { customer_id: this.customerId }).then(({ data }) => {
                const cardData = {
                    authData: {
                        clientKey: data.pubKey,
                        apiLoginID: data.apiLoginID,
                    },
                    cardData: {
                        cardNumber: this.payment.ccnumber.replace(/\s+/g, ''),
                        month: this.payment.expmonth,
                        year: this.payment.expyear.slice(-2),
                        cardCode: this.payment.cvv,
                    },
                }
                window.Accept.dispatchData(cardData, this.encryptCard)
            })
        },
        cancel() {
            this.loading = false
            if (this.clearFields) {
                Object.assign(this.$data, reset())
            }
        },
        encryptCard(data: AuthNetResponse) {
            this.$emit('card-added', {
                card: this.payment.ccnumber.slice(-4),
                token: data.opaqueData.dataValue,
                type: 'authorize',
                name: this.payment.name,
                expMonth: this.payment.expmonth,
                expYear: this.payment.expyear,
            })
        },
    },
})
