import { computed, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { Product } from '@/types'
import { order } from '@/plugins/order/Order'
import { LenderOptions, Options } from '@/types'
import OrderHelper from '@/helpers/OrderHelper'

export const lenderOptions = ref({} as Options)

export const optionsLoaded = ref(false)

export const getLenderOptions = (id: number) => {
    $axios.get('/v1/lender/' + id + '/option').then((response) => {
        lenderOptions.value = {} as Options
        if (!response || !response.data) return
        response.data.forEach((option: LenderOptions) => {
            if (option.option_key !== undefined) {
                lenderOptions.value[option.option_key] = option.option_value
            }
        })
        optionsLoaded.value = true
    })
}

export const getOrderLenderOptions = (id: number) => {
    $axios.get('/v1/order/' + id + '/lender-options').then((response) => {
        lenderOptions.value = {} as Options
        if (!response || !response.data) return
        response.data.forEach((option: LenderOptions) => {
            if (option.option_key !== undefined) {
                lenderOptions.value[option.option_key] = option.option_value
            }
        })
        optionsLoaded.value = true
    })
}

export const lenderHasUcdpHold = computed(() => {
    if (lenderOptions.value.veros === undefined) {
        return false
    }

    if (JSON.parse(JSON.stringify(lenderOptions.value.veros)).hold === undefined) {
        return false
    }

    return JSON.parse(JSON.stringify(lenderOptions.value.veros)).hold
})

export const lenderCanChangeOrderStatus = computed(() => {
    if (lenderOptions.value['change-order-status'] === undefined) {
        return false
    }

    if (JSON.parse(JSON.stringify(lenderOptions.value['change-order-status'])).enabled === undefined) {
        return false
    }

    return JSON.parse(JSON.stringify(lenderOptions.value['change-order-status'])).enabled
})

export const lenderHasFHA = computed(() => {
    if (lenderOptions.value.veros !== undefined) {
        return JSON.parse(JSON.stringify(lenderOptions.value.veros)).ead_enabled === 'Yes'
    }
    return false
})

export const lenderHasUCDP = computed(() => {
    if (lenderOptions.value.veros !== undefined) {
        return (
            JSON.parse(JSON.stringify(lenderOptions.value.veros)).ucdp_enabled === 'Yes' ||
            JSON.parse(JSON.stringify(lenderOptions.value.veros)).ead_enabled === 'Yes'
        )
    }
    return false
})

export const lenderHasFHAFinal = computed(() => {
    if (!lenderOptions.value.veros) return false
    if (order.value && (order.value.fha === null || order.value.fha === '')) return false
    if (OrderHelper.is1004D.value) return JSON.parse(JSON.stringify(lenderOptions.value.veros)).ead_final === 'Yes'
    return JSON.parse(JSON.stringify(lenderOptions.value.veros)).ead_enabled === 'Yes'
})

export const LenderHasUCDPFinal = computed(() => {
    if (!lenderOptions.value.veros) return false
    //This was commented out because a ucdp can be on FHA && conventional loans.
    // if(order.value && (order.value.fha !== null && order.value.fha !== '')) return false
    if (OrderHelper.is1004D.value) return JSON.parse(JSON.stringify(lenderOptions.value.veros)).ucdp_final === 'Yes'
    return JSON.parse(JSON.stringify(lenderOptions.value.veros)).ucdp_enabled === 'Yes'
})

export const lenderHasBorrowerDelivery = computed(() => {
    if (
        lenderOptions.value.mercury !== undefined &&
        JSON.parse(JSON.stringify(lenderOptions.value.mercury)).borrower === 'Yes'
    ) {
        return true
    }

    if (
        lenderOptions.value['borrower-delivery'] !== undefined &&
        JSON.parse(JSON.stringify(lenderOptions.value['borrower-delivery'])).option !== 'off'
    ) {
        return true
    }

    return false
})

export const lenderCanManuallySendBorrowerDelivery = computed(() => {
    if (order.value && !optionsLoaded.value) getOrderLenderOptions(order.value.id)

    if (lenderOptions.value['borrower-delivery'] !== undefined) {
        return JSON.parse(JSON.stringify(lenderOptions.value['borrower-delivery'])).manuallySendReport
    }

    return false
})

export const lenderCanResendBorrowerDelivery = computed(() => {
    if (order.value && !optionsLoaded.value) getOrderLenderOptions(order.value.id)

    if (lenderOptions.value['borrower-delivery'] !== undefined) {
        return JSON.parse(JSON.stringify(lenderOptions.value['borrower-delivery'])).sendRevisedReport
    }

    return false
})

export const lenderShouldShowUcdp = (order: { products: Product[] }) => {
    if (lenderOptions.value.veros === undefined) return false
    const internalFormIds = [19, 20]
    if (
        order.products &&
        order.products.length > 0 &&
        order.products.some((product: Product) => {
            return internalFormIds.includes(product.product_id)
        }) &&
        JSON.parse(JSON.stringify(lenderOptions.value.veros)).ucdp_final !== 'Yes'
    )
        return false
    if (
        JSON.parse(JSON.stringify(lenderOptions.value.veros)).ucdp_enabled !== 'Yes' &&
        JSON.parse(JSON.stringify(lenderOptions.value.veros)).ead_enabled !== 'Yes'
    )
        return false
    return true
}

export const licensedAppraiserProhibited = computed(() => {
    if (lenderOptions.value['prevent-licensed'] !== undefined) {
        return lenderOptions.value['prevent-licensed']
    }

    return false
})
