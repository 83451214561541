





















































































































import { computed, defineComponent, inject, ref, onMounted } from '@vue/composition-api'
import OrderActions from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/OrderActions.vue'
import OrderTags from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/OrderTags.vue'
import { queue } from '@/pages/AMC/Quality/Process/QcReview/Partials/QCReviewProcessData'
import { user } from '@/plugins/User'
import { getPresentUsers } from '@/plugins/Channels'
import { refreshDashboardCounts } from '@/components/OrderTable/Classes/TableFilters'
import { order, openCloseOrder, closeOrder } from '@/plugins/order/Order'

/**
 * @name 'Order Toolbar'
 * Component description: The order toolbar which will display all the needing information
 * @onMounted - Sets the distance from the top
 * @SetupData handleScroll() => : sets the scroll distance from the top
 * @SetupData _fixedToolbar: Sets a fixed boolean value
 * @SetupData sidebarItem: Sets the sidebar item
 * @SetupData otherUsers: uses the channel to see if there are other users in the order
 * @SetupData openCloseReminders: Ability to open or close the reminders sidebar
 * @SetupData userColor: The user color for the chip if they are in the order.
 */
export default defineComponent({
    components: { OrderTags, OrderActions },
    props: {
        /**
         * Indicates if the toolbar is fixes
         */
        fixed: {
            type: Boolean,
            default: true,
        },
        /**
         * Indicates to show the order tags
         */
        showOrderTags: {
            type: Boolean,
            default: false,
        },
        /**
         * Indicates to show the reminders
         */
        showOrderReminders: {
            type: Boolean,
            default: false,
        },
        /**
         * Indicates to show the order actions
         */
        showOrderActions: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const _fixedToolbar = ref(props.fixed)

        const sidebarItem = inject('sidebarItem', ref(''))

        const otherUsers = computed(() => {
            const userId = user.value?.id ?? 0

            return getPresentUsers('orderPresence').filter((elem: { id: number; name: string }) => elem.id !== userId)
        })

        const showResidenceType = ref(true)

        const openCloseReminders = () => {
            if (sidebarItem.value === 'reminders') {
                sidebarItem.value = ''
                return
            }
            sidebarItem.value = 'reminders'
        }

        const userColor = computed(() => {
            otherUsers.value.map((elem) => {
                if (elem.type === 'amc') elem.color = 'secondary'
                if (elem.type === 'desk') elem.color = 'secondary'
                if (elem.type === 'ascent') elem.color = 'primary'
                if (elem.type === 'appraiser') elem.color = '#8434A3'
                if (elem.type === 'client') elem.color = 'pink darken-4'
                return true
            })
            return true
        })

        const distanceFromTop = ref(60 as undefined | number)
        onMounted(() => {
            if (!document.getElementById('orderToolbar')) return
            distanceFromTop.value = document.getElementById('orderToolbar')?.getBoundingClientRect().top
        })

        const handleScroll = () => {
            if (distanceFromTop.value) _fixedToolbar.value = window.scrollY >= distanceFromTop.value
        }

        return {
            otherUsers,
            openCloseReminders,
            sidebarItem,
            user,
            _fixedToolbar,
            handleScroll,
            distanceFromTop,
            order,
            userColor,
            showResidenceType,
        }
    },
    methods: {
        closeAppraisal(): void {
            if (queue.value !== undefined) {
                openCloseOrder(false)
                return
            }
            closeOrder()
            refreshDashboardCounts()
        },
    },
    computed: {
        filteredTags() {
            if (!order.value?.tags) return []
            if (order.value?.tags?.length < 1) return []
            const tags = order.value?.tags.filter((tag) => {
                if (tag.showOnOrder === false) return false
                if (user.value?.type === 'client' && tag.showToClient === true) return true
                if (user.value?.type === 'appraiser' && tag.showToAppraiser === true) return true
                return user.value?.type !== 'appraiser' && user.value?.type !== 'client'
            })
            return tags
        },
    },
})
