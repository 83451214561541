var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.order && _vm.order.status === 'Assign') || _vm.isOrderOnHold || !_vm.isOrderReviewed)?_c('ACard',{attrs:{"elevation":0}},[_c('template',{slot:"top"},[(_vm.isOrderReviewed)?_c('v-toolbar',{attrs:{"flat":"","color":"accentWhitedarken1","dense":""}},[_c('v-toolbar-title',{staticClass:"title pl-0 text-h5"},[_c('span',[_vm._v("Assign Appraisal")])]),_c('v-spacer'),(
                    _vm.order &&
                    _vm.order.status === 'Assign' &&
                    _vm.$ascent.canDo('Override Appraiser Assignment') &&
                    _vm.$ascent.featureEnabled('orderScreen.overrideAppraiser', false) &&
                    !_vm.$ascent.customerFeatureEnabled('usesJaroPanels', false)
                )?_c('v-menu',{attrs:{"bottom":"","offset-y":"","rounded":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-sm-1 d-none d-sm-inline-block",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$vuetify.icons.ellipsis")])],1)]}}],null,false,693893375)},[_c('v-list',{attrs:{"dense":""}},[(
                            _vm.$ascent.canDo('Override Appraiser Assignment') &&
                            _vm.$ascent.featureEnabled('orderScreen.overrideAppraiser', false)
                        )?_c('v-list-item',{on:{"click":_vm.openOverrideDialogAction}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Override Appraiser Assignment")])],1)],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()],1),_c('span',[(!_vm.isOrderReviewed && _vm.filesAndNotesLoaded)?_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.reviewTab),callback:function ($$v) {_vm.reviewTab=$$v},expression:"reviewTab"}},[_c('v-tab',{attrs:{"value":"review"}},[_vm._v("Review")]),(_vm.$ascent.customerFeatureEnabled('usesJaroPanels', false) && !_vm.isOrderOnHold)?_c('v-tab',{attrs:{"value":"panel"}},[_vm._v(" Panels ")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.reviewTab),callback:function ($$v) {_vm.reviewTab=$$v},expression:"reviewTab"}},[_c('v-tab-item',[_c('v-toolbar',[_c('v-toolbar-title',{staticClass:"title pl-0 text-h5"},[_c('span',[_vm._v("Review Order")])])],1),_c('ReviewOrder')],1),(_vm.$ascent.customerFeatureEnabled('usesJaroPanels', false) && !_vm.isOrderOnHold)?_c('v-tab-item',[_c('JaroAssignPanels',{attrs:{"jaro-panels":_vm.jaroPanels,"large-panel-viewer":true,"allow-assignment":false}})],1):_vm._e()],1)],1):_c('v-card-text',[(_vm.isOverrideNeeded && !_vm.isOrderOnHold)?_c('assign-override'):_vm._e(),(
                    _vm.isOrderReviewed &&
                    !_vm.isOverrideNeeded &&
                    !_vm.isOrderOnHold &&
                    !_vm.$ascent.customerFeatureEnabled('usesJaroPanels', false)
                )?_c('assign-appraiser'):_vm._e(),(
                    _vm.isOrderReviewed && !_vm.isOverrideNeeded && _vm.$ascent.customerFeatureEnabled('usesJaroPanels', false)
                )?[(_vm.quotesArray.length > 0)?_c('QuoteTable'):_vm._e(),(!_vm.isOrderOnHold)?_c('JaroAssignPanelsVendorAssignedPartial'):_vm._e(),(!_vm.isOrderOnHold)?_c('JaroAssignPanels',{attrs:{"jaro-panels":_vm.jaroPanels,"large-panel-viewer":true}}):_vm._e()]:_vm._e(),(_vm.isOrderReviewed && !_vm.isOverrideNeeded && !_vm.isOrderOnHold)?_c('EditOrderInvoice'):_vm._e()],2)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }