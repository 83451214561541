
















































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { ValidationObserver } from 'vee-validate'
import axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    name: 'LenderAssignmentDirectives',
    components: { ValidationObserver },
    setup() {
        const currentAssignmentDirective = ref({})
        const dialog = ref(false)
        const optionEdit = ref('')

        const getAssignmentDirectives = () => {
            axios
                .get('/v1/lender/' + lender.value.id + '/option/assign-directive', {
                    params: {
                        withRevisions: false,
                    },
                })
                .then((res) => {
                    if (!res.data) return
                    optionEdit.value = res.data.option_value
                    currentAssignmentDirective.value = res.data
                })
        }

        onBeforeMount(() => {
            getAssignmentDirectives()
        })

        const saveChanges = () => {
            showSnackbar('Saved directive successfully!')
            dialog.value = false
            getAssignmentDirectives()
        }
        return { dialog, optionEdit, lender, saveChanges, currentAssignmentDirective }
    },
})
