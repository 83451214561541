
















import { File } from '@/types'

import { defineComponent, PropType } from '@vue/composition-api'

import { download as DownloadFile } from '@/components/General/DownloadFile.vue'

/**
 * @name ClientFileComponent
 * Component that takes a file on the order screen and formats it, and it allows client to view/download file
 */

export default defineComponent({
    props: {
        /**
         * File on the file order screen
         */
        file: {
            type: Object as PropType<File>,
        },
    },
    data() {
        return {}
    },
    methods: {
        /**
         * Takes the status of the file, and returns a color of the chip
         * @param {string} status - status of the file
         * @returns {string} color
         */
        getColor(status: string) {
            switch (status) {
                case 'All':
                    return 'successdarken3'
                case 'Admin':
                    return 'teal darken-3'
                case 'Appraiser':
                    return 'yellow darken-4'
                case 'Client':
                    return 'purple darken-3'
                case 'Inactive':
                    return 'red darken-3'
            }
        },
        /**
         * Takes in file and downloads the file
         * @param {File} file - file that is going to be downloaded
         */
        download(file: File) {
            const name =
                file.type.replace(/^.*[\\/]/, '') +
                '_' +
                file.order_id +
                '.' +
                file.path.substr(file.path.lastIndexOf('.') + 1)
            DownloadFile(file.id, name)
        },
        /**
         * Opens the file in another tab to view in the browers
         * @param {File} file - file that is going to be downloaded
         */
        openFile(file: File) {
            this.$axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        },
        openEdit(file: File) {
            this.$emit('editFile', file)
        },
    },
})
