import UnreadNoteDetailTemplate from '@/components/OrderTable/DetailTemplates/UnreadNoteDetailTemplate.vue'
import ReminderDetailTemplate from '@/components/OrderTable/DetailTemplates/ReminderDetailTemplate.vue'
import { orderFilter } from '@/components/OrderTable/Classes/OrderTable'

export default () => {
    if (
        orderFilter.value === 'Unread' ||
        orderFilter.value === 'Unread Messages' ||
        orderFilter.value === 'Critical Messages' ||
        orderFilter.value === 'Normal Messages' ||
        orderFilter.value === 'Normal Unread' ||
        orderFilter.value === 'Critical Comments' ||
        orderFilter.value === 'QC Unread' ||
        orderFilter.value === 'Assigner Unread' ||
        orderFilter.value === 'Assigner Critical Unread' ||
        orderFilter.value === 'Assigner Normal Unread' ||
        orderFilter.value === 'CSS Unread' ||
        orderFilter.value === 'CSS Critical Comments' ||
        orderFilter.value === 'CSS Normal Unread' ||
        orderFilter.value === 'ROV Unread'
    ) {
        return {
            template: UnreadNoteDetailTemplate,
        }
    }
    if (
        orderFilter.value === 'Reminders' ||
        orderFilter.value === 'Upcoming' ||
        orderFilter.value === 'Assigner Reminders' ||
        orderFilter.value === 'Upcoming Reminders' ||
        orderFilter.value === 'Past Reminders'
    ) {
        return {
            template: ReminderDetailTemplate,
        }
    }
    return undefined
}
