




import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import AscentHelper from '@/helpers/ascent-helper'
import DOMPurify from 'dompurify'
import { Tag } from '@/types'

export default defineComponent({
    props: {
        source: {
            type: String,
            default: '',
        },
        tag: {
            type: Object as () => Tag,
            default: () => ({}),
        },
    },
    setup(props) {
        const allowedHtmlTags = [
            'html',
            'body',
            'a',
            'b',
            'br',
            'em',
            'hr',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'i',
            'li',
            'ol',
            'p',
            's',
            'span',
            'strong',
            'table',
            'tr',
            'td',
            'u',
            'ul',
        ]

        /**
         * Strips HTML tags and replaces content merge variables in the given source string.
         *
         * @param {string} source - The source string containing HTML content and merge variables.
         * @param {string[]} allowedTags - An array of allowed HTML tags.
         * @returns {string} - The source string with HTML tags stripped and merge variables replaced with corresponding values.
         */
        const stripTagsAndParseContent = (source: string, allowedTags: string[]): string => {
            let sanitizedHTML = DOMPurify.sanitize(source, { ALLOWED_TAGS: allowedTags })

            // Define the pattern to match content placeholders
            const contentPattern = /\[%(.*?)%]/g

            // Replace content placeholders with corresponding values
            sanitizedHTML = sanitizedHTML.replace(contentPattern, (_, mergeVariable) => {
                switch (mergeVariable) {
                    case 'Disclosed Amount':
                        return String(AscentHelper.currency(order.value?.disclosedFee ?? 0, '0,0.00'))
                    case 'Vendor Fee':
                        return String(
                            AscentHelper.currency(
                                order.value?.incremental?.mainProduct?.fee_schedule_fee ?? 0,
                                '0,0.00',
                            ),
                        )
                    case 'Amount to Approve':
                        return String(
                            AscentHelper.currency(
                                ((order.value?.incremental?.mainProduct?.fee_schedule_fee as number) ?? 0) -
                                    (order.value?.disclosedFee ?? 0) <
                                    0
                                    ? 0
                                    : ((order.value?.incremental?.mainProduct?.fee_schedule_fee as number) ?? 0) -
                                          (order.value?.disclosedFee ?? 0),
                                '0,0.00',
                            ),
                        )
                    case 'Total Due':
                        return String(AscentHelper.currency(order.value?.incremental?.total?.due ?? 0, '0,0.00'))
                    case 'Borrower Amount':
                        return String(
                            AscentHelper.currency(
                                (order.value?.disclosedFee ?? 0) >
                                    ((order.value?.incremental?.mainProduct?.fee_schedule_fee as number) ?? 0)
                                    ? (order.value?.incremental?.mainProduct?.fee_schedule_fee as number) ?? 0
                                    : order.value?.disclosedFee ?? 0,
                                '0,0.00',
                            ),
                        )
                    case 'FHA Number':
                        return String(order.value?.fha)
                    default:
                        return '' // Placeholder not recognized, replace with an empty string
                }
            })

            if (props.tag && props.tag.extra && props.tag.extra.appraisalScopeUrl) {
                sanitizedHTML += `<p><a href=${props.tag.extra.appraisalScopeUrl} target="_blank">Click Here To Enter Card Info</a></p>`
            }

            if (props.tag && props.tag.extra && props.tag.extra.feeData) {
                let feeData = '<table>'
                for (const key in props.tag.extra.feeData) {
                    feeData += `<tr><td><b>${key}:</b></td><td><b>$${AscentHelper.currency(
                        props.tag.extra.feeData[key],
                        '0,0.00',
                    )}</b></td></tr>`
                }
                feeData += '</table>'
                sanitizedHTML += feeData
            }

            return sanitizedHTML
        }

        const parsedContent = computed(() => {
            return stripTagsAndParseContent(props.source, allowedHtmlTags)
        })

        return {
            parsedContent,
            order,
        }
    },
})
