import { EventTypes, FormActionObject, FormConditionObject, FormTrigger } from '@/types/customerActions'
import { ref } from '@vue/composition-api'
import AscentHelper from '@/helpers/ascent-helper'

export const formActions: FormActionObject = {
    order: [
        { id: 'addAuditNote', name: 'Add Audit Note' },
        { id: 'addCriticalComment', name: 'Add Critical Comment' },
        { id: 'addNote', name: 'Add Order Note' },
        { id: 'addOrderDirective', name: 'Add Order Directive' },
        { id: 'addOrderEntity', name: 'Add Order Entity' },
        { id: 'addParticipants', name: 'Add Participant(s)' },
        { id: 'deleteParticipants', name: 'Delete Participant(s)' },
        { id: 'addTags', name: 'Add Tag(s)' },
        { id: 'deleteTags', name: 'Delete Tag(s)' },
        { id: 'sendEmail', name: 'Send Email' },
        { id: 'capturePendingCardPayment', name: 'Capture Pending Card Payment' },
        { id: 'createAndSendBorrowerPaymentLink', name: 'Send Payment Link' },
        { id: 'sendFileToValutracVendor', name: 'Send SSR Files to Valutrac' },
        { id: 'unbroadcast', name: 'Remove Order from Broadcasting' },
    ],
    invite: [{ id: 'sendEmail', name: 'Send Email' }],
    appraiser: [{ id: 'sendEmail', name: 'Send Email' }],
}

export const eventTypes: EventTypes[] = [
    { id: 'order', name: 'Order' },
    {
        id: 'appraiser',
        name: AscentHelper.customerFeatureEnabled('lang.vendors', 'Vendor'),
    },
]

const priority_options = ['Standard', 'Rush']

const status_options = ['Active', 'Pending', 'Suspended', 'Disabled']

const property_type_options = ['Residential', 'Commercial']

const order_attr_options = [
    { id: 'jumbo', text: 'Jumbo' },
    { id: 'construction', text: 'Construction' },
]

const order_type_options = [
    { id: 'appraisal', text: 'Appraisal' },
    { id: 'alternative', text: 'Alternative' },
    { id: 'review', text: 'Review' },
]

const defaultItems = [
    { id: 'borrower-paid', text: 'Borrower' },
    { id: 'net-30', text: 'Bill To' },
    { id: 'credit-card', text: 'Credit Card' },
    { id: 'split', text: 'Split' },
    { id: 'cod', text: 'COD' },
    { id: 'deferred-card', text: 'Deferred - Waiting on Borrower' },
    { id: 'mercury-card', text: 'Mercury Card' },
    { id: 'company-credit-card', text: 'Company Credit Card' },
    { id: 'valuepad-card', text: 'Valuepad Card' },
]

export const botMilestones: string[] = [
    'Accepted',
    'Appraiser Accessed',
    'Appraiser Completed',
    'Appraiser Deadline',
    'Assigned',
    'Bill Date',
    'Broadcasted',
    'Cancelled',
    'Client Accessed',
    'Client Completed',
    'Client Deadline',
    'Conditionally Accepted',
    'Declined',
    'Effective Date',
    'First Appraiser Complete',
    'Inspected',
    'Inspection Complete',
    'Inspection Date',
    'Order Date',
    'Original Appraiser Deadline',
    'Original Client Deadline',
    'Original Requested Deadline',
    'Second Inspection Date',
]

const existsItems = [{ id: 'Empty', text: 'Empty' }]

export const formConditions: FormConditionObject = {
    order: [
        {
            id: 'appraiser_id',
            model: 'order',
            name: 'Vendor',
            component: 'appraiser-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'appraiser_office',
            model: 'appraiser_office',
            name: 'Vendor Company',
            component: 'appraiser-office-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'add_ons',
            model: 'add_ons',
            name: 'Add On',
            component: 'add-on-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'billing_method',
            model: 'order',
            name: 'Billing Method',
            component: 'a-select-field',
            operator: 'select',
            items: defaultItems,
            type: ['event', 'scheduled'],
        },
        {
            id: 'branch_id',
            model: 'order',
            name: 'Branch',
            component: 'branch-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'client_id',
            model: 'client',
            name: 'Client',
            component: 'client-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'county_id',
            model: 'order',
            name: 'County',
            component: 'county-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'total.due',
            model: 'total',
            name: 'Due',
            component: 'order-due-input',
            operator: 'comparison',
            type: ['event'],
        },
        {
            id: 'entities',
            model: 'entities',
            name: 'Entities',
            component: 'entities-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'entity_roles',
            model: 'entity_roles',
            name: 'Entity Roles',
            component: 'entity-roles-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'fee_schedule',
            model: 'fee_schedule',
            name: 'Fee Schedule',
            component: 'fee-schedule-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'user_id',
            model: 'order',
            name: 'Officer',
            component: 'amc-user-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'products',
            model: 'order',
            name: 'Loan Type',
            component: 'loan-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'panels',
            model: 'order_panel',
            name: 'Order Panels',
            component: 'panel-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'panel.closed',
            model: 'order_panel',
            name: 'Closed Panel',
            component: 'closed_panel',
            operator: 'yes_no',
            type: ['event'],
        },
        {
            id: 'lender_id',
            model: 'lender',
            name: 'Lender',
            component: 'lender-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'lender.status',
            model: 'lender',
            name: 'Lender Status',
            component: 'a-select-field',
            operator: 'select',
            items: status_options,
            type: ['event'],
        },
        {
            id: 'lender.team',
            model: 'lender',
            name: 'Lender Team',
            component: 'a-select-field',
            operator: 'select',
            items: existsItems,
            type: ['event'],
        },
        {
            id: 'loan_purpose',
            model: 'order',
            name: 'Loan Purpose',
            component: 'loan-purpose-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'main_product',
            model: 'order',
            name: 'Main Product',
            component: 'main-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'propertyType',
            model: 'order',
            name: 'Property Type',
            component: 'a-select-field',
            items: property_type_options,
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'total.total',
            model: 'total',
            name: 'Total',
            component: 'order-total-input',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'residence_type',
            model: 'order',
            name: 'Residence Type',
            component: 'property-type-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'source_system',
            model: 'order',
            name: 'Source System',
            component: 'integration-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'status',
            model: 'order',
            name: 'Status',
            component: 'status-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'address.state',
            model: 'order',
            name: 'State',
            component: 'state-select',
            operator: 'select',
            type: ['event'],
        },
        {
            id: 'tags',
            model: 'tag',
            name: 'Tag',
            component: 'tag-select',
            operator: 'select',
            type: ['event', 'scheduled'],
        },
        {
            id: 'priority',
            model: 'order',
            name: 'Priority',
            component: 'a-select-field',
            operator: 'select',
            items: priority_options,
            type: ['event'],
        },
        {
            id: 'order_type',
            model: 'order',
            name: 'Order Type',
            component: 'a-select-field',
            operator: 'select',
            items: order_type_options,
            type: ['event', 'scheduled'],
        },
        {
            id: 'original.details',
            model: 'order',
            name: 'Order Attributes',
            component: 'a-select-field',
            operator: 'select',
            items: order_attr_options,
            type: ['event'],
        },
    ].sort((a, b) => a.name.localeCompare(b.name)),
    invite: [],
    appraiser: [],
}

export const formConditionOperators = {
    select: [
        { id: 'checkIfOrderConditionsExist', name: 'is' },
        { id: 'checkIfOrderConditionsDoNotExist', name: 'is not' },
    ],
    yes_no: [
        { id: 'checkIfOrderConditionsExist', name: 'Yes' },
        { id: 'checkIfOrderConditionsDoNotExist', name: 'No' },
    ],
    comparison: [
        { id: 'checkIfOrderConditionsIsEqual', name: 'Equals' },
        { id: 'checkIfOrderConditionsIsNotEqual', name: 'Does Not Equal' },
        { id: 'checkIfOrderConditionsIsGreaterThan', name: 'Is Greater Than' },
        { id: 'checkIfOrderConditionsIsGreaterThanOrEqualTo', name: 'Is Greater Than or Equal to' },
        { id: 'checkIfOrderConditionsIsLessThan', name: 'Is Less Than' },
        { id: 'checkIfOrderConditionsIsLessThanOrEqualTo', name: 'Is Less Than or Equal to' },
    ],
}

export const formConditionTypes = {
    is: 'checkIfOrderConditionsExist',
    is_not: 'checkIfOrderConditionsDoNotExist',
}

export const formTimings = [
    { id: 'before_delay', name: 'Before delay' },
    { id: 'after_delay', name: 'After delay' },
]

export const intervals: string[] = [
    'Monthly',
    'Weekly',
    'Daily',
    'Hourly',
    'Every 30 min',
    'Every 10 min',
    'Every 5 min',
]

export const weekDays: { id: number; name: string }[] = [
    { id: 0, name: 'Sunday' },
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
]
export const timezones: string[] = [
    'America/Denver',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Chicago',
    'America/New_York',
    'America/Adak',
    'America/Anchorage',
    'Pacific/Honolulu',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Hobart',
    'Australia/Brisbane',
    'Australia/Sydney',
    'Australia/Perth',
    'Pacific/Auckland',
]

export const compareSelections: { id: string; name: string }[] = [
    { id: '=', name: 'the Current Date' },
    { id: '>=', name: 'after the Current Date' },
    { id: '<=', name: 'before the Current Date' },
]

export const timeUnits = [
    { id: 'minutes', name: 'Minute(s)' },
    { id: 'hours', name: 'Hour(s)' },
    { id: 'b_days', name: 'Business Day(s)' },
    { id: 'days', name: 'Calendar Day(s)' },
]
export const formTriggers = ref([] as FormTrigger[])
