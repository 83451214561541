<template>
    <v-dialog v-model="dialog" width="600">
        <ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
            <v-card>
                <v-card-title primary-title>Send Payment Link</v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col v-if="order && order.incremental" cols="12">
                            <v-radio-group v-model="option">
                                <v-radio
                                    v-for="borrower in order.incremental.borrowers"
                                    v-show="borrower.email"
                                    :key="borrower.id"
                                    :label="borrower.name + ' ' + borrower.email"
                                    :value="borrower.email"
                                />
                                <v-radio
                                    v-if="order.incremental.payment_email"
                                    :label="'Client Supplied - ' + order.incremental.payment_email.email"
                                    :value="order.incremental.payment_email.email"
                                />
                                <v-radio label="Manual Entry" value="manual" />
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox
                                v-if="$ascent.featureEnabled('orderScreen.collectCard')"
                                v-model="collectOnly"
                                label="Collect card only, but do not charge card."
                            />
                            <a-currency-field
                                v-if="collectOnly === false"
                                v-model="amount"
                                label="Split Amount - Optional"
                                prepend-icon="$vuetify.icons.dollarSign"
                            />
                            <a-text-field
                                v-if="option === 'manual'"
                                v-model="email"
                                :rules="'required|email'"
                                label="Email Address"
                                autofocus
                                name="email"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-alert type="info" text>
                                <span v-if="collectOnly === false && amount !== null">
                                    A payment link for {{ $ascent.currency(amount) }} will be sent.
                                </span>
                                <span v-if="collectOnly === false && amount === null">
                                    If no amount is entered, a payment link for the full outstanding amount will be
                                    sent.
                                </span>
                                <span v-if="collectOnly === true">
                                    No amount will be collected with this payment link. Card information will be stored
                                    on the order.
                                </span>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn link text @click="dialog = false">Cancel</v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        :loading="loading"
                        :disabled="invalid"
                        @click="handleSubmit(sendLink)"
                    >
                        Send Link
                    </v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'
import { getNotes } from '@/plugins/Notes'

function reset() {
    return {
        dialog: false,
        option: 'manual',
        collectOnly: false,
        email: null,
        loading: false,
    }
}

export default {
    setup() {
        const amount = ref(null)
        const collectOnly = ref(false)

        return {
            order,
            amount,
            collectOnly,
        }
    },
    props: {},
    components: {
        ValidationObserver,
    },
    data() {
        return reset()
    },
    methods: {
        open() {
            this.dialog = true
        },
        sendLink() {
            this.loading = true
            let email = ''
            if (this.option === 'manual') {
                email = this.email
            } else {
                email = this.option
            }
            this.$axios
                .post('/v1/order/' + order.value.id + '/payment/link', {
                    email: email,
                    amount: this.amount,
                    collect: this.collectOnly,
                })
                .then(() => {
                    Object.assign(this.$data, reset())
                    getNotes(order.value.id)
                    showSnackbar('Payment link sent successfully')
                    this.loading = false
                })
                .catch((error) => {
                    if (error) {
                        return showError(error.response.data.errors.email[0])
                    }
                    showError('Oops! Something went wrong!')
                    this.loading = false
                })
        },
    },
}
</script>
