















































































































































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    components: {
        StateSelect,
    },
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const address = ref('')
        const city = ref('')
        const state = ref('')
        const sub_street = ref('')
        const zipcode = ref('')
        const associationYN = ref('')
        const associationFee = ref('')
        const county = ref('')
        const assessments = ref('')
        const propertyType = ref('')
        const AssociationFeeFrequency = ref('')

        watch(props, () => {
            if (!props.editData && !props.cancelEdit) {
                if (associationYN.value == 'No') associationFee.value = ''

                preloadData.value.primary.address = address.value
                preloadData.value.primary.city = city.value
                preloadData.value.primary.state = state.value
                preloadData.value.primary.sub_street = sub_street.value
                preloadData.value.primary.zipcode = zipcode.value
                preloadData.value.primary.associationYN = associationYN.value
                preloadData.value.primary.associationFee = associationFee.value
                preloadData.value.primary.county = county.value
                preloadData.value.primary.assessments = assessments.value
                preloadData.value.primary.propertyType = propertyType.value
                preloadData.value.primary.AssociationFeeFrequency = AssociationFeeFrequency.value
            }

            if (props.editData) {
                address.value = preloadData.value.primary.address
                city.value = preloadData.value.primary.city
                state.value = preloadData.value.primary.state
                sub_street.value = preloadData.value.primary.sub_street
                zipcode.value = preloadData.value.primary.zipcode
                associationYN.value = preloadData.value.primary.associationYN
                associationFee.value = preloadData.value.primary.associationFee
                county.value = preloadData.value.primary.county
                assessments.value = preloadData.value.primary.assessments
                propertyType.value = preloadData.value.primary.propertyType
                AssociationFeeFrequency.value = preloadData.value.primary.AssociationFeeFrequency
            }
        })

        return {
            preloadData,
            address,
            city,
            state,
            sub_street,
            zipcode,
            associationYN,
            associationFee,
            county,
            assessments,
            order,
            propertyType,
            AssociationFeeFrequency,
        }
    },
})
