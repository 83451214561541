


























import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { Task } from '@/types'
import { lenderId } from '@/components/General/LenderOptions/LenderOptions'
import { DynamicForm as DynamicFormConfig } from '@/types/DynamicForm'
import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import { currentCustomer } from '@/plugins/User'
import DynamicForm from '@/components/DynamicForm/DynamicForm.vue'

export default defineComponent({
    components: {
        LenderOnboardingStatus,
        DynamicForm,
    },
    name: 'LenderCompanyInfo',
    setup(_, { emit }) {
        const task = ref<Task>({ id: 0, status: '', user_id: 0 })
        const forms = ref<DynamicFormConfig[]>([])
        const loaded = ref<boolean>(false)

        const formConfigData = computed(() => ({
            lenderId: lenderId.value,
        }))

        const updateStatus = (task: Task) => {
            emit('updateStatus', task)
        }

        onMounted(() => {
            const companyInfoRequest = axios.get(`/v1/lender/${lenderId.value}/action/get-company-information`)
            const formConfigRequest = axios.get(
                `/v1/customer/${currentCustomer.value.id}/dynamic-form?type=lender-contact`,
            )

            Promise.all([companyInfoRequest, formConfigRequest]).then(([companyInfoRes, formConfigRes]) => {
                task.value = companyInfoRes.data.task
                forms.value = formConfigRes.data.forms
                loaded.value = true
            })
        })

        return {
            loaded,
            task,
            updateStatus,
            forms,
            formConfigData,
        }
    },
})
