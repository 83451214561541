
















import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { orderTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import { order } from '@/plugins/order/Order'
import axios from 'axios'

export default defineComponent({
    name: 'ClientTags',
    components: {},
    setup() {
        const lenderClientTags = ref([])

        onMounted(() => {
            if (!order.value) return
            if (!order.value.lender_id) return
            axios.get('/v1/lender/' + order.value.lender_id + '/option/office-client-tags').then((response) => {
                lenderClientTags.value = response.data.option_value
            })
        })

        return {
            orderTags,
            order,
            lenderClientTags,
        }
    },
})
