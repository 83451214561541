














import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import DetailVendorWithFee from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDetailComponents/DetailVendorWithFee.vue'
import { openAssign } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'

export default defineComponent({
    name: 'CustomerMultipleVendorDetails',
    components: {
        DetailVendorWithFee,
    },
    setup() {
        return {
            openAssign,
            order,
        }
    },
})
