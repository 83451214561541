



















import { defineComponent } from '@vue/composition-api'
import { step, reviewStep } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        const advancePreload = () => {
            if (step.value == 5) reviewStep.value = true
            step.value += 1
        }
        const previousPreload = () => {
            step.value -= 1
        }
        const cancelEdit = () => {
            emit('cancel-edit')
        }
        const saveEdit = () => {
            emit('save-edit')
        }

        return {
            advancePreload,
            previousPreload,
            step,
            cancelEdit,
            saveEdit,
            reviewStep,
        }
    },
})
