var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"d-flex flex-row align-center"},[(
            _vm.item.appraiser.appraiser_tags == null ||
            (_vm.item.appraiser.appraiser_tags != null &&
                !_vm.item.appraiser.appraiser_tags.find(function (el) { return el.tag === 'Staff'; }))
        )?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openAssignPanelNotesDialog('phone', _vm.item.appraiser)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.phoneAlt")])],1):_vm._e(),(
            _vm.item.appraiser.appraiser_tags == null ||
            (_vm.item.appraiser.appraiser_tags != null &&
                !_vm.item.appraiser.appraiser_tags.find(function (el) { return el.tag === 'Staff'; }))
        )?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openAssignPanelNotesDialog('email', _vm.item.appraiser)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.envelope")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }