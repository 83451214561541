
























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { Lender } from '@/types'
import AddSublenderDialog, {
    dialog,
} from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderSublenders/AddSublenderDialog.vue'
import SublenderEditDialog, {
    openEdit,
} from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderSublenders/SublenderEditDialog.vue'

export default defineComponent({
    components: {
        SublenderEditDialog,
        AddSublenderDialog,
    },
    setup() {
        const sublenders = ref([] as Lender[])
        const integrationOption = ref()

        const getSublenders = () => {
            $axios.get('v1/lender/' + lender.value.id + '/sublenders').then((response) => {
                sublenders.value = response.data
            })
        }

        onMounted(() => {
            getSublenders()
        })

        return {
            sublenders,
            lender,
            dialog,
            getSublenders,
            openEdit,
            integrationOption,
        }
    },
})
