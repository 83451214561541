











































































import { defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'
import Lenderx from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/lenderx.vue'
import reggora from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/reggora.vue'
import Mercury from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/mercury.vue'
import Shield from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/shield.vue'
import Trk from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/trk.vue'
import Valuepad from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/valuepad.vue'
import valuelink from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/valuelink.vue'
import encompass from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/encompass.vue'
import closing from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/closing.vue'
import firewall from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/firewall.vue'
import appraisalport from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/appraisalport.vue'
import collateralxp from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/collateralxp.vue'
import freddiemac from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/freddiemac.vue'
import { Task } from '@/types'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'

import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

const task = ref({ id: 0, status: '', user_id: 0 } as Task)
const options = ref({})
const integrations = ref([
    'lenderx',
    'mercury',
    'reggora',
    'shield',
    'trk',
    'valuepad',
    'valuelink',
    'appraisalport',
    'collateralxp',
    'freddiemac',
] as string[])
const loaded = ref(false)

export const getTasks = () => {
    axios.get(`/v1/lender/${lender.value.id}/action/get-lender-integration`).then((res) => {
        task.value = res.data.task
        options.value = res.data.options

        Object.keys(options.value).forEach((key) => {
            integrations.value.splice(integrations.value.indexOf(key), 1)
        })

        options.value = { ...options.value, ...res.data.static_options }
        loaded.value = true
    })
}

export default defineComponent({
    components: {
        LenderOnboardingStatus,
        firewall,
        closing,
        valuelink,
        encompass,
        Lenderx,
        Mercury,
        reggora,
        Shield,
        Trk,
        Valuepad,
        appraisalport,
        collateralxp,
        ValidationObserver,
        freddiemac,
    },
    setup(_, { emit }) {
        const dialog = ref(false)
        const integrationSelect = ref()

        const updateStatus = (task: Task) => {
            emit('updateStatus', task)
        }

        const save = () => {
            integrations.value.splice(integrations.value.indexOf(integrationSelect.value), 1)
            options.value = { ...options.value, [integrationSelect.value]: undefined }
            dialog.value = false
            showSnackbar('Integration added successfully')
        }

        onMounted(getTasks)

        onUnmounted(() => {
            options.value = {}
        })

        return {
            save,
            integrationSelect,
            dialog,
            integrations,
            options,
            loaded,
            task,
            lender,
            updateStatus,
        }
    },
})
