import { RouteConfig } from 'vue-router/types'

const routes: Array<RouteConfig> = [
    {
        path: '/snapshot/comp-data/:id',
        name: 'Snapshot Comparable Data',
        component: () => import('@/pages/AlternativeProducts/Snapshot/SnapshotCompData.vue'),
        meta: { requiresAuth: true, userType: ['amc'], showNav: false },
    },
    {
        path: '/kit/:id/complete-report',
        name: 'Kit Report',
        component: () => import('@/pages/AlternativeProducts/Kit/CompleteKitReport.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'appraiser'], showNav: false },
    },
    {
        path: '/kit/config/:id/propmix',
        name: 'Kit Config Propmix',
        component: () => import('@/pages/AlternativeProducts/Kit/Config/ConfigPropMix.vue'),
        meta: { requiresAuth: true, userType: ['amc', 'appraiser'], showNav: false },
    },
    {
        path: '/appraiser-order-broadcast/:token',
        name: 'Broadcast Order',
        component: () => import('@/pages/Public/Order/BroadcastOrder.vue'),
        meta: { requiresAuth: false },
    },
]

export default routes
