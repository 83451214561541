import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { openCloseDrawer, drawer } from '@/plugins/Ascent'

Vue.use(VueRouter)

import Public from './Public/PublicRoutes'
import Client from './Client/ClientRoutes'
import Appraiser from './Appraiser/AppraiserRoutes'
import AMC from './AMC/AMCRoutes'
import Ascent from './Ascent/AscentRoutes'
import Auth from './Auth/AuthRoutes'
import System from './System/SystemRoutes'
import Office from './Office/OfficeRoutes'
import Desk from './Desk/DeskRoutes'
import AlternativeProducts from './AlternativeProducts/AlternativeRoutes'
import { getUser, user, role } from '@/plugins/User'
import { User } from '@/types'
import AscentHelper from '@/helpers/ascent-helper'

const baseRoutes: Array<RouteConfig> = []

const routes = baseRoutes
    .concat(Public)
    .concat(Client)
    .concat(Appraiser)
    .concat(AMC)
    .concat(Ascent)
    .concat(Auth)
    .concat(System)
    .concat(Office)
    .concat(Desk)
    .concat(AlternativeProducts)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach(async (to, from, next) => {
    //Checks to see if route requires Auth, if not then directs.
    if (!to.meta.requiresAuth && to.path !== '/') {
        return next()
    }

    //Gets the user, checks to see if there is a user, if not it will direct to login
    if ((to.meta.requiresAuth || to.path === '/') && user.value === undefined) {
        await getUser()
        if (user.value === undefined) {
            if (localStorage.getItem('jaro_auth')) {
                return next({ path: to.path })
            }
            if (to.path !== '/') {
                localStorage.setItem('jaro_redirect_path', to.path)
            }
            return next({ path: '/login' })
        }
    }

    //sets the user to the correct path based on the type
    if (user.value !== undefined && (to.path === '/' || to.path === '/login')) {
        if (user.value?.type === 'amc') {
            return next({ path: '/dashboard' })
        }
        if (user.value?.type === 'quality') {
            return next({ path: '/quality/external' })
        }
        if (user.value?.type === 'preload') {
            return next({ path: '/preload/dashboard' })
        }
        if (user.value?.type === 'insurance') {
            return next({ path: '/insurance/dashboard' })
        }
        return next({
            path: '/' + (user.value as User).type + '/dashboard',
        })
    }

    //if types dont match, will direct to a 401

    if (
        to.meta.userType !== undefined &&
        !to.meta.userType.includes((user.value as User).type) &&
        !(user.value as User).ascent
    )
        return next('/401')

    if (to.meta.notViewOnly && to.meta.notViewOnly !== AscentHelper.canDo('Order')) return next('/401')

    //Checks to see if the user types match. If not, directs to a 401

    if (
        to.meta.permissions &&
        to.meta.permissions.length > 0 &&
        !to.meta.permissions.some((elem: string) => AscentHelper.canView(elem))
    )
        return next('/401')

    if (to.meta.role && !to.meta.role.includes(role.value) && !user.value?.ascent) {
        return next('/401')
    }

    if (to.meta.pageEnabled && !AscentHelper.pageEnabled(to.meta.pageEnabled) && !user.value?.ascent) {
        return next('/401')
    }

    return next()

    if (to.path != '/change-password' && (user.value as User).must_change) {
        return next('/change-password')
    }

    next()
})

router.afterEach(() => {
    if (drawer.value) {
        openCloseDrawer(false)
    }
})

export default router
