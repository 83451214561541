


























import { defineComponent } from '@vue/composition-api'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import axios from 'axios'
import { appraiserSettingsId } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    setup() {
        const unlinkAppraiser = () => {
            if (!appraiserSettingsId.value) return

            confirm(
                'Unlink and Terminate Appraiser',
                'Are you sure you want to unlink this appraiser? <br> <br>' +
                    'This will permanently remove this appraiser from your office and will allow them to re-register in Jaro under a different alias.<br><br>' +
                    'The appraiser will no longer have access to this profile, or their orders. <br><br>' +
                    "If you want to receive new notifications regarding the appraiser's orders, you will need to change their notification email in their profile." +
                    '<br><br><b>This action is irreversible!</b>',
                { color: 'error', confirm: 'Unlink Appraiser' },
            ).then((confirm) => {
                if (confirm) {
                    axios
                        .post('/v1/appraiser/' + appraiserSettingsId.value + '/action/unlink')
                        .then((response) => {
                            showSnackbar(response.data)
                        })
                        .catch((error) => {
                            showError(error)
                        })
                        .finally(() => {
                            window.location.href = '/appraisers'
                        })
                }
            })
        }

        return {
            unlinkAppraiser,
            appraiserSettingsId,
        }
    },
})
