



















































































































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { OfficeAssigningOption } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { ValidationObserver } from 'vee-validate'

/**
 * @name 'Office Lender Assigning'
 * Component description: Is a Lender Option for the Office Assigning. It will display the assigning options and also the ability to edit the assigning options in a dialog.;
 * @SetupData getTasks() => will get the office assigning values for the customer id and set the correct values;
 * @SetupData saveOption() => Will save the updated option and reset the displayed values to the correct values while closing the dialog;
 * @SetupData assigning: The value that is the the v-model when editing and saving ;
 * @SetupData displayAssigning: The value that is displayed and is reset every time a new option gets saved;
 * @SetupData dialog: The boolean value that will determine if the editing dialog is open or closed;
 */
export default defineComponent({
    components: { ValidationObserver },
    name: 'OfficeLenderAssigning',
    setup() {
        const options = ref({})
        const assigning = ref([] as OfficeAssigningOption[])
        const displayAssigning = ref()
        const dialog = ref(false)

        const getTasks = () => {
            $axios.get(`/v1/lender/${lender.value.id}/option/office-assigning`).then((response) => {
                assigning.value = response.data.option_value
                displayAssigning.value = response.data.option_value
            })
        }

        onMounted(getTasks)

        const saveOption = () => {
            dialog.value = false
            displayAssigning.value = assigning.value
            showSnackbar('Option saved successfully')
        }

        return {
            options,
            assigning,
            saveOption,
            dialog,
            displayAssigning,
            lender,
        }
    },
})
