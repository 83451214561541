











































































import { ValidationObserver } from 'vee-validate'
import { defineComponent } from '@vue/composition-api'
import { Client, CarbonCopy } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

/**
 * @name 'Client Profile Auto CC'
 * Component description: Allows the user to add or remove a user inside their company to all their orders automatically
 * Allows a user to add or remove an email to auto cc
 */
export default defineComponent({
    components: { ValidationObserver },
    props: {
        /**
         * the client's ID
         */
        client: {
            type: Number,
        },
    },
    data() {
        return {
            /**
             * the users they have added to their list
             */
            participants: [],
            /**
             * the users they can add to cc list
             */
            carboncopylist: [],
            /**
             * the v-model for the user of the order participant
             */
            newParticipant: null,
            /**
             * the v-model for the user of the email cc list
             */
            newFollower: null,
        }
    },
    watch: {
        /**
         * Will watch the prop of client ID and will call getList and getParticipants
         */
        client(newVal) {
            if (newVal > 0) {
                this.getList()
                this.getParticipants()
            }
        },
    },
    methods: {
        /**
         * Will get the list of the clients
         */
        getList() {
            this.$axios
                .get('/v1/client/' + this.client + '/auto-participant', {
                    params: {
                        search: true,
                    },
                })
                .then((response) => {
                    this.carboncopylist = response.data.map((elem: CarbonCopy) => {
                        elem.text = elem.name + ' - ' + elem.type
                        return elem
                    })
                })
        },
        /**
         * Will get the list of the participants
         */
        getParticipants() {
            this.$axios.get('/v1/client/' + this.client + '/auto-participant').then((response) => {
                this.participants = response.data
            })
        },
        /**
         * Will add a participant to either the cc or the orders list.
         * @param {Client} type - The client that is being added to the list.
         * Will refresh the participants
         */
        addParticipant(type: Client) {
            this.$axios
                .post('/v1/client/' + this.client + '/auto-participant', {
                    newParticipant: this.newParticipant,
                    newFollower: this.newFollower,
                    type: type,
                })
                .then(() => {
                    this.newFollower = null
                    this.newParticipant = null
                    this.getParticipants()
                    showSnackbar('Notification updated successfully', 'success')
                })
        },
        /**
         * Will delete a participant to either the cc or the orders list.
         * @param {Client} participant - The client that is being removed from the list.
         */
        deleteParticipant(participant: Client) {
            confirm(
                'Remove Auto Copy Person?',
                'This action cannot be undone. It also does not remove them from orders already in progress.',
            ).then((confirm) => {
                if (confirm) {
                    this.$axios.delete('/v1/client/' + this.client + '/auto-participant/' + participant.id).then(() => {
                        showSnackbar('Auto Copy Removed Successfully')
                        this.getParticipants()
                    })
                }
            })
        },
    },
    mounted() {
        this.getParticipants()
        this.getList()
    },
})
