





























































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { refreshDashboardCounts, UserList, teams, getTableTeams } from '@/components/OrderTable/Classes/TableFilters'
import { updateOrders, viewTeam } from '@/components/OrderTable/Classes/OrderTable'
import { favoriteTeams, user as userStore } from '@/plugins/User'
import { name } from '@/components/OrderTable/Classes/OrderTable'
import { updateQuoteTable } from '@/pages/AMC/Quotes/Quotes'

export const dialog = ref(false)

/**
 * @name TableTeamSelectDialog
 * Dialog on Order Table that allows user to select different teams to see their orders on the Order Table
 *
 * @SetupData favorites - Array of teams that the user selected as their favorites
 * @SetupData team - Object of current team
 * @SetupMethod refreshDash - Resets the dashboard with orders that the user has selected
 * @SetupMethod resetSelection - Will reset dashboard back to the specific team or selects all teams
 * @SetupMethod selectAllFavoriteTeams - Selects all favorites of the user logged in
 */

export default defineComponent({
    props: {
        tableType: {
            type: String,
        },
    },
    setup(props) {
        const refreshDash = () => {
            dialog.value = false
            localStorage.setItem(
                `team-select-${userStore.value?.id}_${userStore.value?.customer_id}`,
                JSON.stringify(viewTeam.value),
            )
            if (name.value === 'Quote Table') return updateQuoteTable()
            if (!props.tableType) {
                refreshDashboardCounts()
                updateOrders()
            }
        }

        const resetSelection = () => {
            if (teams.value.some((team: UserList) => team.id === userStore.value?.id)) {
                viewTeam.value = [userStore.value?.id as number]
            } else {
                viewTeam.value = [-1]
            }
        }

        const selectAllWhenNoneSelected = (id: number) => {
            if (viewTeam.value.length === 0 && id !== -1) viewTeam.value = [-1]
        }

        const selectAllFavoriteTeams = () => {
            viewTeam.value = favoriteTeams.value
        }

        watch(
            () => favoriteTeams.value,
            () => {
                getTableTeams()
            },
        )

        onMounted(() => {
            if (!teams.value || teams.value.length === 0) {
                getTableTeams()
            }
        })

        return {
            favoriteTeams,
            viewTeam,
            teams,
            dialog,
            refreshDash,
            resetSelection,
            selectAllWhenNoneSelected,
            selectAllFavoriteTeams,
        }
    },
})
