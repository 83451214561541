






















































































import { computed, defineComponent, watch, PropType, onMounted } from '@vue/composition-api'
import { ProductFee, Product } from '@/types'

/**
 * @name OfficeFeeTable
 * Fee table component on the office order screen
 *
 * @SetupData headers - headers on the table
 * @SetupComputed lineTotal - takes the products on the props and gets the total from the products
 * @SetupComputed getFees - emits 'get-fees' with product
 * @SetupComputed total - total of amc fee and appraiser fee
 * @SetupMethod getItems - takes in value, and gets products
 * @SetupMethod updateInput - takes in value, product, and column, and emits to parent component
 * @SetupMethod deleteFee - takes in product and emits product to parent component
 *
 */

export default defineComponent({
    props: {
        products: {
            type: [] as PropType<Product[]>,
            default: () => [],
        },
        fees: {
            type: [] as PropType<ProductFee>,
            default: () => [],
        },
        error: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const headers = [
            { text: 'Product', value: 'form.name' },
            { text: 'Type', value: 'form.type' },
            { text: 'Total Fee', value: 'amc_value' },
            { text: '', value: 'action', align: 'center' },
        ]
        const lineTotal = computed(() => {
            if (props.products.length === 0) return 0
            return props.products.reduce((total, currentItem) => {
                return total + parseFloat(currentItem.line_total as string)
            }, 0)
        })

        const getFees = (item: Product) => {
            emit('get-fees', item)
        }

        const total = computed(() => {
            return lineTotal.value
        })

        const getItems = (val: string) => {
            if (val === 'Main Product') return props.fees.main
            return props.fees.loans
        }

        const updateInput = (val: string, item: Product, column: string) => {
            emit('update-input', { val, item, column })
        }

        const deleteFee = (item: Product) => {
            emit('delete-fee', item)
        }

        watch(total, () => {
            emit('total-updated', total.value)
        })

        onMounted(() => {
            emit('total-updated', total.value)
        })

        const formatValue = (val: string, item: Product, column: string) => {
            item[column] = parseFloat(item[column] as string).toFixed(2)
        }

        return {
            headers,
            getItems,
            updateInput,
            deleteFee,
            lineTotal,
            formatValue,
            total,
            getFees,
        }
    },
})
