
















































































































































































































import { ValidationObserver } from 'vee-validate'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { BorrowerDeliveryOption, LenderOptions } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import LoanSelect from '@/components/General/AutoCompletes/LoanSelect.vue'

export default defineComponent({
    components: { LoanSelect, ValidationObserver },
    props: {
        option: {
            type: Object as PropType<LenderOptions | undefined>,
        },
        mercury: {
            type: Object as PropType<LenderOptions>,
        },
        optionType: {
            type: String,
            default: 'lender',
        },
        branchId: {
            type: Number,
        },
    },
    setup(props) {
        const dialog = ref(false)
        const optionLocal = ref({
            option: 'off',
            delay: '',
            excludeLoanTypes: [],
            manuallySendReport: false,
            sendRevisedReport: false,
            useLenderOption: true,
        } as BorrowerDeliveryOption)
        const mercuryBorrower = ref()
        const submitUrl = `/v1/${
            props.optionType === 'lender' ? 'lender/' + lender.value.id : 'branch/' + props.branchId
        }/option`

        const items = ref([
            { value: 'consent', text: 'E-Delivery - Consent Required' },
            { value: 'noconsent', text: 'E-Delivery - No Consent Required' },
            { value: 'off', text: 'Disabled' },
        ])
        if (props.option !== undefined) {
            optionLocal.value = props.option.option_value as BorrowerDeliveryOption
        }

        if (props.mercury && props.mercury.id) {
            items.value = [{ value: 'mercury', text: 'Mercury Borrower Delivery' }, ...items.value]
        }

        const optionRef = ref(JSON.parse(JSON.stringify(optionLocal.value)))

        const excludedLoanTypes = computed(() => {
            if (
                !optionLocal.value ||
                !optionLocal.value.excludeLoanTypes ||
                optionLocal.value.excludeLoanTypes.length === 0
            ) {
                return 'Not Set'
            }

            return optionLocal.value.excludeLoanTypes.map((loan) => loan.name).join(', ')
        })

        const save = () => {
            optionRef.value = optionLocal.value
            dialog.value = false
            showSnackbar('Borrower Delivery saved successfully')
        }

        const showOptions = computed(() => {
            if (!props) return true
            if (props.optionType === 'lender') return true
            if (!optionLocal.value) return true
            return !optionLocal.value.useLenderOption
        })

        return {
            items,
            mercuryBorrower,
            optionRef,
            save,
            dialog,
            optionLocal,
            lender,
            excludedLoanTypes,
            submitUrl,
            showOptions,
        }
    },
})
