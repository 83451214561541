










































































import { defineComponent, ref, computed } from '@vue/composition-api'
import ScheduleSecondInspectionDialog from '@/components/OrderScreens/Customer/CustomerEditComponents/ScheduleSecondInspectionDialog.vue'
import CancelRevisionDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/CancelRevisionDialog.vue'
import AddConditionsToRequestDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderConditions/AddConditionsToRequestDialog.vue'
import QcFollowUpRemoveDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderActions/QcFollowUpRemoveDialog.vue'
import { Milestone } from '@/types'
import { Dialog } from '@/ascent'
import { user } from '@/plugins/User'
import { order } from '@/plugins/order/Order'
import { returnAppraiser } from '@/components/OrderScreens/Workflow/QualityAction.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import AppraiserAMCCompleteAction from '@/components/OrderScreens/Customer/CustomerEditComponents/AppraiserAMCCompleteAction.vue'
import $axios from '@/plugins/axios'
import { getNotes } from '@/plugins/Notes'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import AppraiserOfficeCompleteAction from '@/components/OrderScreens/Customer/CustomerEditComponents/AppraiserOfficeCompleteAction.vue'
import { cancelInspection } from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/CancelInspectionButton.vue'
import { openCompleteOrderWithoutUploadActionDialog } from '@/components/OrderScreens/Shared/Actions/CompleteOrderWithoutUploadAction.vue'

export default defineComponent({
    components: {
        AppraiserOfficeCompleteAction,
        QcFollowUpRemoveDialog,
        AddConditionsToRequestDialog,
        ScheduleSecondInspectionDialog,
        CancelRevisionDialog,
        AppraiserAMCCompleteAction,
    },
    setup() {
        const override = ref(false)
        const stepText = computed(() => {
            if (!order.value) return
            switch (order.value.status) {
                case 'Complete':
                    return 'Complete Appraisal'
                default:
                    return order.value.status
            }
        })
        const showSecondInspectionButton = computed(() => {
            if (!order.value) return
            if (!order.value.incremental) return
            const secondInspectionMilestone = order.value.incremental.milestones.find(
                (milestone: Milestone) =>
                    milestone.type === 'current' && milestone.milestone_type === 'Second Inspection Date',
            )

            return secondInspectionMilestone === undefined && order.value.status === 'Complete'
        })

        const ScheduleSecondInspectionDialog = ref({} as Dialog)
        const openSecondInspection = () => {
            ScheduleSecondInspectionDialog.value.open()
        }

        const canCancelRevision = computed(() => {
            if (!order.value) return false
            if (!user.value) return false
            if (user.value.type !== 'office' && order.value.status !== 'Complete') return true
            if (order.value.status === 'Reconsideration of Value' && order.value.source !== 'ascent') return true
            return ['UW Revision', 'QC Revision'].includes(order.value.status)
        })
        const convertToRushUW = (): void => {
            confirm('Are you sure?', 'Do you want to convert this work to a rush UW?').then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    $axios.post(`/v1/order/${order.value.id}/action/convert-uw-request-to-rush`).then(() => {
                        if (!order.value) return
                        getNotes(order.value.id)
                        showSnackbar('UW Revision successfully converted to UW Rush Revision')
                    })
                }
            })
        }

        return {
            stepText,
            showSecondInspectionButton,
            ScheduleSecondInspectionDialog,
            openSecondInspection,
            user,
            order,
            returnAppraiser,
            orderProducts,
            convertToRushUW,
            canCancelRevision,
            override,
            cancelInspection,
            openCompleteOrderWithoutUploadActionDialog,
        }
    },
})
