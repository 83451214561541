
































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
    props: {
        option: String,
        createdAt: String,
    },
    setup(props) {
        const dialog = ref(false)
        const value = ref(props.option)

        return {
            dialog,
            value,
        }
    },
})
