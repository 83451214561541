































































import { defineComponent } from '@vue/composition-api'
import { Note } from '@/types'

/**
 * @name MessageTracking
 * Dialog to show the tracking of messages
 */

export default defineComponent({
    props: {},
    data() {
        return {
            dialog: false,
            tracking: {
                recipients: [],
            },
        }
    },
    methods: {
        /**
         * Opens the dialog with the note and tracking data
         * @param note
         */
        open(note: Note) {
            this.$axios.get('/v1/note/' + note.id + '/note-tracking').then((response) => {
                if (response.data === 'null') {
                    this.tracking = {
                        recipients: [],
                    }
                    return
                }
                this.tracking = response.data
            })
            this.dialog = true
        },
    },
})
