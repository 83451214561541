import $axios from '@/plugins/axios'
import { ViewFilters } from '@/types'
import { DashboardFilterLabel } from '@/types/DashboardSettings'
import { ref } from '@vue/composition-api'
import { Dashboard, FilterConditionKeys } from '@/types/Dashboard'
import { user, dashboardAccess } from '@/plugins/User'
import { orderFilter } from '@/components/OrderTable/Classes/OrderTable'
import { activeFilter, filterViews, oneEnabled, singleFilter } from '@/components/OrderTable/Classes/TableFilters'
import AscentHelper from '@/helpers/ascent-helper'
import axios from 'axios'

export const dashboards = ref([] as Dashboard[])
export const customerDashboards = ref([] as Dashboard[])
export const currentDashboard = ref({} as Dashboard)
export const component = ref('')

export const getDashboards = () => {
    $axios.get('v1/orders/action/get-dashboards').then(({ data }) => {
        const customerDashboards = data.dashboards.sort((a: Dashboard, b: Dashboard) => {
            return a.dashboardName === b.dashboardName ? 0 : a.dashboardName < b.dashboardName ? -1 : 1
        })

        dashboards.value = customerDashboards
        if (!AscentHelper.canDo('Admin')) {
            dashboards.value = customerDashboards.filter((elem: Dashboard) => {
                return dashboardAccess.value.some((dashboardId: string) => dashboardId == elem.id)
            })
        }

        if (dashboards.value.length == 0) {
            dashboards.value = customerDashboards.filter((elem: Dashboard) => {
                return elem.isDefault
            })
        }

        if (user.value) {
            const defaultDashboard = dashboards.value.find((dashboard) => dashboard.defaultDashboard)
            const storedDashboard = JSON.parse(
                localStorage.getItem(`user_dashboard_${user.value?.id}_${user.value?.customer_id}`) as string,
            ) as Dashboard | null
            const updatedStoredDashboard = dashboards.value.find((dashboard) => dashboard.id === storedDashboard?.id)
            const firstDashboard = dashboards.value[0]

            if (defaultDashboard) {
                currentDashboard.value = defaultDashboard
            } else if (updatedStoredDashboard) {
                currentDashboard.value = updatedStoredDashboard
            } else if (firstDashboard) {
                currentDashboard.value = firstDashboard
            }

            if (!currentDashboard.value) return

            orderFilter.value = currentDashboard.value.defaultFilter
            activeFilter.value = orderFilter.value as string
            localStorage.setItem(
                `user_dashboard_${user.value.id}_${user.value.customer_id}`,
                JSON.stringify(currentDashboard.value),
            )
        }
    })
}

export const getCustomerDashboards = () => {
    $axios.get('v1/orders/action/get-dashboards').then(({ data }) => {
        customerDashboards.value = data.dashboards.sort((a: Dashboard, b: Dashboard) => {
            return a.dashboardName === b.dashboardName ? 0 : a.dashboardName < b.dashboardName ? -1 : 1
        })
    })
}

export const getDashboardViewFilterLabels = () => {
    const dashboardViewFilterLabels = ref<DashboardFilterLabel[]>([])

    if (!currentDashboard.value.viewFilters) return dashboardViewFilterLabels.value

    for (const viewFilter of currentDashboard.value.viewFilters) {
        for (const condition of viewFilter.conditions) {
            const label = Object.keys(condition)[0] as FilterConditionKeys
            if (!(label in condition)) continue

            const conditionData = condition[label]
            if (!conditionData) continue

            const filterLabel = label
                .split('_')
                .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                .join(' ')
            const existing = dashboardViewFilterLabels.value.findIndex((viewFilterLabel: DashboardFilterLabel) => {
                if (viewFilterLabel.filterLabel === filterLabel) return true
            })

            let filterVal = ''
            let first = true
            for (const data of conditionData) {
                if (existing === -1) {
                    filterVal += first ? data : '|' + data
                    first = false
                    continue
                }

                if (
                    !(existing in dashboardViewFilterLabels.value) ||
                    !dashboardViewFilterLabels.value[existing].filterVal
                )
                    continue

                const currentFilterValues = dashboardViewFilterLabels.value[existing].filterVal.split('|')
                if (currentFilterValues.includes(String(data))) continue

                currentFilterValues.push(String(data))
                dashboardViewFilterLabels.value[existing].filterVal = currentFilterValues.join('|')
            }

            if (existing !== -1) continue

            dashboardViewFilterLabels.value.push({
                label: 'dashboardViewFilter',
                component: 'CustomDashboardViewFilter',
                enabled: true,
                title: viewFilter.name,
                filterLabel: filterLabel,
                filterVal: filterVal,
                handled: false,
            })
        }
    }

    return dashboardViewFilterLabels.value.filter((val) => val)
}

export const setFilterViews = () => {
    axios.get(`/v1/settings/view-filters`).then(({ data }) => {
        if (!data.result) return
        filterViews.value = data.result
        let enabledCount = 0
        filterViews.value.map((elem: ViewFilters) => {
            if (elem.enabled) enabledCount += 1
            return elem
        })
        filterViews.value = filterViews.value.concat(getDashboardViewFilterLabels())
        if (enabledCount <= 1) {
            oneEnabled.value = true
            singleFilter.value = filterViews.value.filter((elem: ViewFilters) => {
                return elem.enabled
            })
        }
    })
}
