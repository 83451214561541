




























































import { defineComponent } from '@vue/composition-api'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    setup() {
        return {
            preloadData,
        }
    },
})
