











































































































































































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { branch, branchName } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'

import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ValidationObserver } from 'vee-validate'
import $axios from '@/plugins/axios'
import BranchSpecialDirectivesTag from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchInfo/BranchSpecialDirectivesTag.vue'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'

interface WholeSale {
    amc: boolean
    company_id: number | null
    created_at: string
    form_name: string
    id: number
    name: string
    pivot: {
        branch_id: number
        confirmation_status: string
        lender_id: number
    }
    status: string
    updated_at: string
    wholesaler: boolean
}

export default defineComponent({
    components: {
        ValidationObserver,
        BranchSpecialDirectivesTag,
        StateSelect,
    },
    name: 'BranchInfo',
    setup(_, { emit }) {
        const dialog = ref(false)
        const broker = ref(branch.value?.broker)

        if (branch.value?.lender_id === null) broker.value = true

        const lenders = ref(
            branch.value?.wholesale_lenders_include_unconfirmed
                ? branch.value.wholesale_lenders_include_unconfirmed.map((item) => item.id)
                : [],
        )

        const unFilteredLenders = ref([] as WholeSale[])

        const lenderNames = ref([] as WholeSale[])
        const unConfirmedLenderNames = ref([] as WholeSale[])
        const rejectedLenderNames = ref([] as WholeSale[])

        const getLenderNames = () => {
            if (!branch.value) return
            lenderNames.value = []
            unConfirmedLenderNames.value = []
            unFilteredLenders.value = []
            rejectedLenderNames.value = []
            $axios.get('v1/branch/' + branch.value.id + '/wholesale-lenders').then((response) => {
                response.data.map((elem: WholeSale) => {
                    unFilteredLenders.value.push(elem)
                    if (elem.pivot.confirmation_status === 'Pending') {
                        return unConfirmedLenderNames.value.push(elem)
                    }
                    if (elem.pivot.confirmation_status === 'Accepted') {
                        lenderNames.value.push(elem)
                    }
                    if (elem.pivot.confirmation_status === 'Rejected') {
                        rejectedLenderNames.value.push(elem)
                    }
                })
            })
        }

        const lendersEdit = ref(JSON.parse(JSON.stringify(lenders.value)))

        const branchEdit = ref(JSON.parse(JSON.stringify(branch.value)))

        const saveChanges = () => {
            getLenderNames()
            branch.value = JSON.parse(JSON.stringify(branchEdit.value))
            lenders.value = JSON.parse(JSON.stringify(lendersEdit.value))
            broker.value = branchEdit.value.broker
            branchName.value = branchEdit.value.name
            emit('branch-updated')
            showSnackbar('Branch Updated Successfully')
            dialog.value = false
        }

        onMounted(() => getLenderNames())

        return {
            lenderNames,
            saveChanges,
            dialog,
            lenders,
            lendersEdit,
            branchEdit,
            broker,
            branch,
            unConfirmedLenderNames,
            unFilteredLenders,
            rejectedLenderNames,
        }
    },
})
