var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ACard',{attrs:{"title":"Edit Order Instructions"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('a-editor',{attrs:{"name":"Special Instructions","label":"Special Instructions","uuid":"EditOrderEditor","outlined":""},model:{value:(_vm.instructions),callback:function ($$v) {_vm.instructions=$$v},expression:"instructions"}})],1)],1)],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"submitData":{
                    order: {
                        instruction: {
                            instructions: _vm.instructions,
                        },
                    },
                },"method":"PATCH","url":'/v1/order/' + _vm.order.id + '/edit/instruction'},on:{"success":_vm.updateInstructions,"cancel":function($event){_vm.dialog = false}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }