import { ref, computed } from '@vue/composition-api'
import { Notification, Notifications } from '@/types'
import $axios from '@/plugins/axios'
import { user } from '@/plugins/User'

export const seen = ref([] as Notification[])
export const unseen = ref([] as Notification[])
export const newNotifications = ref([] as Notification[])
export const showNotifications = ref(false)

// Getters
export const unseenOrders = computed(() => {
    return unseen.value.filter((notification) => {
        return notification.type == 'App\\Notifications\\AmcUser\\NewOrderAssignedNotification'
    })
})
export const unseenReminders = computed(() => {
    return unseen.value.filter((notification) => {
        return notification.type == 'App\\Notifications\\AmcUser\\ReminderAlarmNotification'
    })
})
export const groupedNotifications = computed(() => {
    return [
        {
            title: 'New Orders',
            ref: 'NewOrderAssignedNotification',
            items: unseenOrders.value,
            unread: true,
            canToggle: true,
            color: 'teal darken-3',
        },
        {
            title: 'New Reminders',
            ref: 'ReminderAlarmNotification',
            items: unseenReminders.value,
            unread: true,
            canToggle: true,
            color: 'light-blue darken-3',
        },
        {
            title: 'Read Notifications',
            ref: null,
            items: seen.value,
            unread: false,
            canToggle: true,
            color: 'primary',
        },
    ]
})
export const getShowNotifications = computed(() => showNotifications.value)

// Mutations

const itemTypeMap = function (type: string): string {
    switch (type) {
        case 'App\\Notifications\\AmcUser\\NewOrderAssignedNotification':
        case 'App\\Notifications\\Order\\ApprovalAssigned':
            return 'order'
        case 'App\\Notifications\\AmcUser\\ReminderAlarmNotification':
            return 'reminder'
        default:
            return ''
    }
}

const appendUnread = (notification: Notification): void => {
    const index = seen.value
        .map(function (x) {
            return x.id
        })
        .indexOf(notification.id)
    seen.value.splice(index, 1)
    unseen.value.push(notification)
}

export const markUnread = (notification: Notification): void => {
    $axios
        .patch('/v1/notification/' + notification.id, {
            read: false,
        })
        .then((response) => {
            response.data.actionType = itemTypeMap(response.data.type)
            appendUnread(response.data)
        })
}

const setNotifications = (notifications: Notifications): void => {
    seen.value = notifications.read
    unseen.value = notifications.unread
}
export const addNewNotification = (notification: Notification): void => {
    notification.actionType = ''
    notification.show = true
    newNotifications.value.push(notification)
    markUnread(notification)
}
export const setShowNotifications = (): void => {
    showNotifications.value = !showNotifications.value
}

const appendRead = (notification: Notification): void => {
    const index = unseen.value
        .map(function (x) {
            return x.id
        })
        .indexOf(notification.id)
    unseen.value.splice(index, 1)
    seen.value.push(notification)
}

//Actions

const processNotifications = (items: Notifications) => {
    const notifications = {
        read: [],
        unread: [],
    } as Notifications

    notifications.read = items.read.map((item) => {
        return { ...item, actionType: itemTypeMap(item.type) }
    })

    notifications.unread = items.unread.map((item) => {
        return { ...item, actionType: itemTypeMap(item.type) }
    })

    setNotifications(notifications)
}

export const getNotifications = () => {
    return new Promise((resolve, reject) => {
        if (user.value) {
            $axios
                .get('/v1/user/' + user.value.id + '/notification')
                .then((response) => {
                    processNotifications(response.data)
                    resolve(response.data)
                })
                .catch(function (response) {
                    reject(response.error)
                })
        }
    })
}

export const markRead = (notification: Notification) => {
    $axios
        .patch('/v1/notification/' + notification.id, {
            read: true,
        })
        .then((response) => {
            appendRead(response.data)
        })
}

export const markAllRead = (type: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        $axios
            .post('v1/user/' + user.value?.id + '/action/mark-all-notifications-read', {
                type: type,
            })
            .then(() => {
                getNotifications()
                resolve()
            })
            .catch(() => {
                reject()
            })
    })
}

export const hideSnackbar = (notification: Notification) => {
    newNotifications.value.splice(newNotifications.value.indexOf(notification), 1)
}
