


































import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { orderProducts } from '@/plugins/order/OrderProducts'

export default defineComponent({
    props: {
        netRevenue: {
            type: Number,
            default: 0,
        },
    },
    setup() {
        return {
            order,
            orderProducts,
        }
    },
})
