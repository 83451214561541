
































import { computed, defineComponent, ref } from '@vue/composition-api'
import { notes } from '@/plugins/Notes'

export default defineComponent({
    props: {
        item: {
            required: true,
            type: String,
        },
        secondItem: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const auditNotes = computed(() => {
            return notes.value.filter((note) => [props.item, props.secondItem].indexOf(note.target) > -1)
        })
        const showNote = ref(false)

        return {
            showNote,
            auditNotes,
        }
    },
})
