











































































































import { defineComponent, reactive, ref, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import AscentHelper from '@/helpers/ascent-helper'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import OrderFeeTable, {
    FeeUpdateInput,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderFeeTable.vue'
import AddProductDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AddProductDialog.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import { confirm } from '@/AppLayout/helpers/confirm.vue'

const state = reactive({
    dialog: false,
    date: '',
    products: [] as Product[],
    type: '',
    reason: '',
    error: '',
    loading: false,
})
import { order, updateOrder, refreshOrder } from '@/plugins/order/Order'
import { DateTime } from 'luxon'
import $axios from '@/plugins/axios'
import { Product, ProductFee } from '@/types'
import { requiresDateModification, requiresFeeModification } from '@/plugins/order/OrderModification'

const fees = ref({
    main: [],
    loans: [],
    other: [],
} as ProductFee)

const getFeeList = () => {
    if (!order.value) return
    $axios.get('/v1/order/' + order.value.id + '/product').then((response) => {
        fees.value = response.data
    })
}
const modQuickResponse = [
    {
        title: 'Per Your Approval',
        value: 'Per your approval, we have submitted the modification request for the order so it can be updated.',
    },
    { title: 'Please Approve', value: 'Please approve the changes to the order so we can proceed. ' },
]
const modNoteQuickResponse = ref('')
export const openModRequest = (type = 'amc') => {
    if (!order.value?.incremental) return
    getFeeList()
    state.products = JSON.parse(JSON.stringify(orderProducts.value))
    if (type === 'vendor') {
        state.type = 'Fee'
    }
    state.dialog = true
    modNoteQuickResponse.value = ''
    state.reason = ''
}

export const reggoraDisabled = computed(() => {
    return (
        order.value?.source === 'reggora' &&
        order.value?.tags &&
        order.value?.tags.find((tag) => tag.tag === 'Mod Fee Req' || tag.tag === 'Mod Date Req')
    )
})

/**
 * @name RequestModificationDialog
 * Dialog on Order Screen for amc user to request a modification request for certain integrations that need a request for when the amc wants to change the fee or order
 * @StateData date - date that the amc wants to change.
 * @StateData products - products on the order
 * @StateData type - type of mod request that the amc wants to change
 * @StateData reason - reason for change
 * @SetupData fees - fees for the order
 *
 * @SetupMethod getFeeList - gets the list of fees that can be put on the orders.
 * @SetupData modQuickReponse - canned responses for amc users to quickly add a note
 * @SetupData modNoteQuickResponse - response chosen from the modQuickResponse
 * @SetupMethod openModRequest - Opens the mod request dialog, grabs the fees, and sets the data
 *
 * @SetupMethod submit - resets data after mod request was posted to backend and sent
 * @SetupMethod deleteFee - takes the product out of the products list
 * @SetupMethod getFees - takes in a product, and gets the fees for that product
 * @SetupMethod updateInput - updates fees and prices of fees
 * @SetupMethod allowedDates - takes in a date, and returns if that date is a blackout date or not
 * @SetupMethod addFee - adds a product and fee onto the product array
 */

export default defineComponent({
    components: {
        ValidationObserver,
        OrderFeeTable,
        AddProductDialog,
    },
    props: {
        userType: {
            type: String,
            default: 'amc',
        },
    },
    setup() {
        const submit = () => {
            state.dialog = false
            state.reason = ''
            state.type = ''
            state.date = ''
            state.products = []
            showSnackbar('Modification Requested Successfully')
            updateOrder()
        }

        const deleteFee = (item: Product) => {
            state.products.splice(state.products.indexOf(item), 1)
        }

        const getFees = (item: Product) => {
            if (!order.value) return
            $axios.get('/v1/order/' + order.value.id + '/product/' + item.form.id).then((response) => {
                if (response.data.quote) {
                    state.products[state.products.indexOf(item)].amc_value = 0
                    state.products[state.products.indexOf(item)].appraiser_value = 0
                    state.products[state.products.indexOf(item)].line_total = 0
                    return
                }

                state.products[state.products.indexOf(item)].product_name = response.data.form.name
                state.products[state.products.indexOf(item)].amc_value = response.data.amc
                state.products[state.products.indexOf(item)].appraiser_value = response.data.appraiser
                state.products[state.products.indexOf(item)].line_total =
                    parseFloat(response.data.amc) + parseFloat(response.data.appraiser)
            })
        }

        const refreshModificationRequest = () => {
            state.loading = true
            let total = ''
            let date = ''
            if (!order.value) return
            if (!order.value.tags) return
            const feeTag = order.value.tags.find((tag) => tag.tag === 'Mod Fee Req')
            const dateTag = order.value.tags.find((tag) => tag.tag === 'Mod Date Req')
            if (feeTag && feeTag.extra) {
                total = String(feeTag.extra.total)
            }
            if (dateTag && dateTag.extra) {
                date = dateTag.extra.date
            }

            $axios
                .post('/v1/order/' + order.value?.id + '/refresh-modification-request', { fee: total, date: date })
                .then(() => {
                    if (!order.value) return
                    showSnackbar('Modification Request Refreshed')
                    refreshOrder()
                    state.loading = false
                    state.dialog = false
                })
                .catch(() => {
                    showError('There was an error in refreshing the Modification Request')
                    refreshOrder()
                    state.loading = false
                    state.dialog = false
                })
        }

        const removeModificationRequest = () => {
            confirm('Remove Modification Requests?', 'This will remove any Mod Fee Req and Mod Date Req tags on the order. This does NOT remove anything in Reggora. Please ensure that you have removed the modification in Reggora prior to proceeding.')
                .then(confirm => {
                    if(confirm) {
                        if(!order.value) return
                        $axios.post('/v1/order/' + order.value.id + '/remove-modification-request')
                            .then(() => {
                                showSnackbar('Modification Request Removed successfully')
                                state.dialog = false
                            })
                            .catch(() => {
                                showError('There was an error in removing the modification request')
                                state.dialog = false
                            })
                    }
                })
        }

        const updateInput = (updatedInput: FeeUpdateInput) => {
            state.products[state.products.indexOf(updatedInput.item)][updatedInput.column] = isNaN(
                parseInt(updatedInput.val),
            )
                ? 0
                : parseInt(updatedInput.val)

            state.products[state.products.indexOf(updatedInput.item)].amc_value =
                (state.products[state.products.indexOf(updatedInput.item)].line_total as number) -
                (state.products[state.products.indexOf(updatedInput.item)].appraiser_value as number)
        }

        const allowedDates = (date: number) => {
            if (date === order.value?.clientDeadline) return false
            if (date <= DateTime.now().toISODate()) return false
            return AscentHelper.isDateBlackedOut(date)
        }

        const addFee = (line: Product) => {
            state.products.push({
                product_id: line.form?.id as number,
                product_name: line.form?.name as string,
                amc_value: line.amc_value,
                appraiser_value: line.appraiser_value,
                line_total: parseFloat(line.amc_value as string) + parseFloat(line.appraiser_value as string),
                form: line.form,
            })
        }

        return {
            state,
            allowedDates,
            submit,
            order,
            modQuickResponse,
            modNoteQuickResponse,
            fees,
            deleteFee,
            getFees,
            updateInput,
            addFee,
            reggoraDisabled,
            refreshModificationRequest,
            removeModificationRequest,
            requiresFeeModification,
            requiresDateModification,
        }
    },
})
