







import { defineComponent, computed } from '@vue/composition-api'

import CustomerSingleVendorDetails from '@/components/OrderScreens/Customer/CustomerOrderComponents/DuplicatedComponents/CustomerSingleVendorDetails.vue'
import CustomerMultipleVendorDetails from '@/components/OrderScreens/Customer/CustomerOrderComponents/DuplicatedComponents/CustomerMultipleVendorDetails.vue'
import { order } from '@/plugins/order/Order'

export default defineComponent({
    name: 'CustomerVendorComponent',
    components: {
        CustomerMultipleVendorDetails,
        CustomerSingleVendorDetails,
    },
    setup() {
        const render = computed(() => {
            if (!order.value) return 'single'
            if (!order.value.incremental?.order_appraisers || order.value?.incremental?.order_appraisers.length === 0)
                return 'single'
            if (
                order.value?.incremental.order_appraisers.length > 0 &&
                order.value?.incremental?.customer.type === 'office'
            )
                return 'multiple'
            if (
                order.value?.incremental.order_appraisers.length === 2 &&
                order.value?.incremental.order_appraisers.find((appraiser) => {
                    return appraiser.role === 'Trainee'
                }) !== undefined
            )
                return 'single'
            if (order.value?.incremental.order_appraisers.length > 1) return 'multiple'
            return 'single'
        })

        return {
            order,
            render,
        }
    },
})
