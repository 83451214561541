




import { defineComponent, computed } from '@vue/composition-api'
import xmlFormatter from 'xml-formatter'

export default defineComponent({
    props: {
        source: { type: String, required: true },
    },
    setup(props) {
        const formattedSource = computed(() =>
            xmlFormatter(props.source, {
                indentation: '  ',
                filter: (node) => node.type !== 'Comment',
                collapseContent: true,
                lineSeparator: '\n',
            }),
        )

        return { formattedSource }
    },
})
