




















import { defineComponent } from '@vue/composition-api'
import CommercialOrderDetails from '@/components/OrderScreens/Commercial/CommercialComponents/CommercialOrderDetails.vue'

export default defineComponent({
    components: {
        CommercialOrderDetails,
    },
    setup() {
        return {
            CommercialOrderDetails,
        }
    },
})
