var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        (_vm.order &&
            _vm.order.incremental &&
            _vm.order.incremental.appraiser &&
            _vm.order.incremental.appraiser.status === 'Vacation') ||
        _vm.appraiserTags.length > 0
    )?_c('v-list-item',{staticClass:"ma-0 pa-0"},[_c('v-list-item-content',{staticClass:"ma-0 pa-0"},[_c('v-chip-group',{attrs:{"column":""}},[(
                    _vm.order &&
                    _vm.order.incremental &&
                    _vm.order.incremental.appraiser &&
                    _vm.order.incremental.appraiser.status === 'Vacation'
                )?_c('v-chip',{staticClass:"mr-1",attrs:{"color":"red","x-small":"","text-color":"white","pill":"","dark":""}},[_vm._v(" On Vacation ")]):_vm._e(),_vm._l((_vm.appraiserTags),function(name,index){return _c('v-chip',{key:index,attrs:{"color":name.color,"dark":"","label":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openAssignNotesDialog(name)}}},[_vm._v(" "+_vm._s(name.tag)+" ")])})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }