








import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import AppraiserCompleteActionWrapper from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserCompleteActionWrapper.vue'
/**
 * @name CompleteOrderAction
 * Workflow step for amc and appraiser or Office to complete the appraisal and upload report
 */
export default defineComponent({
    components: {
        AppraiserCompleteActionWrapper,
    },
    setup() {
        return {
            order,
        }
    },
})
