




























































import { defineComponent, onMounted, ref } from '@vue/composition-api'

import $axios from '@/plugins/axios'
import { branch } from '../../BranchEdit.vue'

import BranchDirectiveRevision from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchDirectives/BranchDirectiveRevision.vue'
import directives from '@/pages/AMC/Lenders/Branches/Partials/BranchEditPartials/BranchDirectives/BranchDirectiveEdit.vue'
import { BranchOption } from '@/types'

export default defineComponent({
    components: {
        BranchDirectiveRevision,
        directives,
    },
    setup() {
        const loaded = ref(false)
        const dialog = ref(false)
        const revisions = ref([] as BranchOption[])

        const headers = [
            { text: 'Date', value: 'created_at', width: 300, sortable: false },
            { text: 'Created By', value: 'saved_by', sortable: false },
            { value: 'view', width: 200, sortable: false },
        ]

        const updateDirectives = (newDirective: BranchOption) => {
            revisions.value.push(newDirective)
        }

        const getDirectives = () => {
            if (!branch.value) return

            $axios
                .get('/v1/branch/' + branch.value.id + '/option/directive', {
                    params: {
                        withRevisions: true,
                    },
                })
                .then((response) => {
                    loaded.value = true
                    revisions.value = response.data || []
                })
        }

        onMounted(getDirectives)

        return {
            headers,
            revisions,
            loaded,
            dialog,
            updateDirectives,
        }
    },
})
