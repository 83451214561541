



































































import axios from '@/plugins/axios'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import LenderNote from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderNotes/LenderNote.vue'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import AEditor from '@/global/Form/AEditor.vue'
import { ValidationObserver } from 'vee-validate'
import { AuditNotes } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    components: {
        AEditor,
        LenderNote,
        ValidationObserver,
    },
    setup() {
        const notes = ref([] as AuditNotes[])
        const newNote = ref('')
        const dialog = ref(false)

        const getNotes = () => {
            axios.get('/v1/lender/' + lender.value.id + '/note').then((response) => {
                notes.value = response.data
                notes.value.reverse()
            })
        }

        const save = (data: AuditNotes) => {
            notes.value.unshift(data)
            dialog.value = false
            showSnackbar('Note added successfully!')
            newNote.value = ''
        }

        onMounted(getNotes)

        return {
            lender,
            save,
            dialog,
            notes,
            newNote,
        }
    },
})
