





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        label: {
            type: String,
            default: 'User',
        },
        rules: {
            type: String,
            default: undefined,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            items: [],
        }
    },

    watch: {
        search(val: string) {
            val && val !== this.select && this.searchAmcUser()
        },
    },
    mounted() {
        this.searchAmcUser()
    },
    methods: {
        searchAmcUser() {
            this.loading = true
            this.$axios.get('v1/location').then(({ data }) => {
                this.items = data.sort((a: { name: string }, b: { name: string }) => {
                    return a.name < b.name || a.name == null ? -1 : 1
                })
                this.loading = false
            })
        },
    },
})
