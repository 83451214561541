<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on }">
            <v-list-item v-on="on" @click="open">
                <v-list-item-avatar>
                    <v-icon small color="primary">$vuetify.icon.edit</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ buttonText }}</v-list-item-title>
            </v-list-item>
        </template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <v-card>
                <v-card-title primary-title>Edit Lender Details for Order Form</v-card-title>
                <v-card-text>
                    <v-row v-if="lender === null">
                        <v-col>
                            <a-select-field
                                v-model="lender"
                                rules="required"
                                :items="lenders"
                                label="Lender"
                                hint="Lender"
                                name="lender"
                                persistent-hint
                                item-value="id"
                                item-text="name"
                                @change="setOverrideLender"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12">
                            <a-text-field
                                v-model="name"
                                rules="required"
                                label="Lender Name"
                                name="lender name"
                                color="secondary"
                                :disabled="lender !== 'new'"
                                :read-only="lender !== 'new'"
                            />
                        </v-col>

                        <v-col cols="12">
                            <a-text-field
                                v-model="address.street"
                                rules="required"
                                label="Lender Street Address"
                                name="lender street address"
                                color="secondary"
                                :disabled="lender !== 'new'"
                                :read-only="lender !== 'new'"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <a-text-field
                                v-model="address.city"
                                rules="required"
                                label="Lender City"
                                name="lender city"
                                color="secondary"
                                :disabled="lender !== 'new'"
                                :read-only="lender !== 'new'"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <a-text-field
                                v-model="address.zip"
                                rules="required"
                                label="Lender Zip Code"
                                name="Lender Zip"
                                color="secondary"
                                :disabled="lender !== 'new'"
                                :read-only="lender !== 'new'"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <a-text-field
                                v-model="address.state"
                                rules="required"
                                label="State"
                                name="State"
                                color="secondary"
                                :disabled="lender !== 'new'"
                                :read-only="lender !== 'new'"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="link" text @click="close">Cancel</v-btn>
                    <v-btn color="primary" :loading="saving" @click="handleSubmit(save)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        dialog: false,
        saving: false,
        lender: null,
        name: null,
        lenders: [{ id: 'new', name: 'New Lender' }],
        address: {
            street: null,
            city: null,
            state: null,
            zip: null,
        },
    }
}
export default {
    components: { ValidationObserver },
    data() {
        return reset()
    },
    props: {
        buttonText: {
            type: String,
            default: 'Edit',
        },
    },
    methods: {
        close() {
            Object.assign(this.$data, reset())
        },
        open() {
            if (!order.value) return
            this.$axios.get('/v1/order/' + order.value.id + '/lender-address').then((response) => {
                if (response.data) {
                    this.address = response.data
                }
            })

            this.$axios.get('v1/lender/' + order.value.lender_id + '/sublenders').then(({ data }) => {
                data.forEach((elem) => this.lenders.push(elem))
            })
        },
        setOverrideLender(id) {
            if (id !== 'new') {
                const lender = this.lenders.find((elem) => elem.id === id)
                this.name = lender.name
                this.address.state = lender.address.state
                this.address.street = lender.address.street
                this.address.city = lender.address.city
                this.address.zip = lender.address.zip
            }
        },
        save() {
            this.saving = true
            if (!order.value) return
            delete this.address.full_address_formatted
            delete this.address.id
            this.$axios
                .post('/v1/order/' + order.value.id + '/lender-address', {
                    lender: this.lender,
                    name: this.name,
                    address: this.address,
                })
                .then(() => {
                    this.dialog = false
                    Object.assign(this.$data, reset())
                    showSnackbar('Order Form Updated')
                })
        },
    },
}
</script>
