




import { defineComponent, computed } from '@vue/composition-api'
import AppraiserAcceptAction from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserAcceptAction.vue'
import AppraiserScheduleAction from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserScheduleAction.vue'
import AppraiserWaitingStep from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserWaitingStep.vue'
import CompleteOrderAction from '@/components/OrderScreens/Workflow/CompleteOrderAction.vue'
import AppraiserOfficeReviewAction from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserOfficeReviewAction.vue'
import { order } from '@/plugins/order/Order'
import StartKitReport from '@/components/OrderScreens/Workflow/Kit/StartKitReport.vue'
import { mainProduct } from '@/plugins/order/OrderProducts'

/**
 * @name AppraiserActionComponent
 * Component in Appraiser Order Screen that shows the current workflow step to the appraiser depending on the status of the order
 */

export default defineComponent({
    components: {
        AppraiserAcceptAction,
        AppraiserScheduleAction,
        AppraiserWaitingStep,
        CompleteOrderAction,
        AppraiserOfficeReviewAction,
        StartKitReport,
    },
    emits: ['actionError'],
    setup(props, { emit }) {
        const showAcceptanceError = (error: string) => {
            emit('actionError', error)
        }

        const getActionComponent = computed(() => {
            if (!order.value) return
            switch (order.value.status) {
                case 'Accept':
                    return 'AppraiserAcceptAction'
                case 'Schedule':
                    return 'AppraiserScheduleAction'
                case 'Waiting Inspection':
                    return 'AppraiserWaitingStep'
                case 'Complete':
                case 'QC Revision':
                case 'UW Revision':
                case 'Reconsideration of Value':
                    if (mainProduct.value && mainProduct.value.report) {
                        return 'StartKitReport'
                    }
                    return 'CompleteOrderAction'
                case 'Internal Review':
                case 'QC Review':
                case 'Reconsideration Review':
                    if (
                        order?.value?.tags?.some((elem) => {
                            if (elem.tag === 'Primary Review Required') return true
                            if (elem.tag === 'Review Required') return true
                            if (elem.tag === 'Commercial Review') return true
                        })
                    ) {
                        return 'AppraiserOfficeReviewAction'
                    }
                    return ''
                default:
                    return ''
            }
        })

        return {
            getActionComponent,
            showAcceptanceError,
        }
    },
})
