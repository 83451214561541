









































































import { ValidationObserver } from 'vee-validate'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import $axios from '@/plugins/axios'
import {
    appraiserValue,
    appraiserSettingsId,
    appraiserOptions,
    AppraiserOptionsSettings,
} from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object || undefined,
        },
    },

    setup(props, { root }) {
        const dialog = ref<boolean>(false)
        const appraiserOptionsLocal = ref({
            require_max_approval: false,
            max_orders: 0,
        })
        watch(appraiserOptions && appraiserValue, () => {
            if (props.option && props.option.max_orders) {
                appraiserOptions.value.max_orders = props.option.max_orders
                appraiserOptionsLocal.value.max_orders = props.option.max_orders
            }
            if (props.option && props.option.require_max_approval) {
                appraiserOptions.value.require_max_approval = props.option.require_max_approval
                appraiserOptionsLocal.value.require_max_approval = props.option.require_max_approval
            }
        })

        if (props.option && props.option.max_orders) {
            appraiserOptions.value.max_orders = props.option.max_orders
            appraiserOptionsLocal.value.max_orders = props.option.max_orders
        }
        if (props.option && props.option.require_max_approval) {
            appraiserOptions.value.require_max_approval = props.option.require_max_approval
            appraiserOptionsLocal.value.require_max_approval = props.option.require_max_approval
        }
        const saveChanges = async () => {
            if (root.$route.params.id) appraiserSettingsId.value = root.$route.params.id
            await $axios.post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                option_key: 'max_orders',
                option_value: appraiserOptions.value.max_orders,
            })
            await $axios.post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                option_key: 'require_max_approval',
                option_value: appraiserOptions.value.require_max_approval,
            })
            dialog.value = false
            appraiserOptionsLocal.value = appraiserOptions.value as AppraiserOptionsSettings
            showSnackbar('Saved successfully')
        }

        return {
            saveChanges,
            dialog,
            lender,
            appraiserOptions,
            appraiserOptionsLocal,
        }
    },
})
