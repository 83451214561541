<template>
    <v-list-item v-if="isCompleted">
        <v-list-item-content>
            <v-list-item-title>
                <a
                    class="text-decoration-none link"
                    :href="pdfUrl + '/pdf/compliance/' + order.incremental.token"
                    target="_blank"
                    style="color: #3b35b1"
                >
                    Compliance Cert
                </a>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="mx-1">
            <v-icon small @click="$emit('download', pdfUrl + '/pdf-download/compliance/' + order.incremental.token)">
                $vuetify.icon.download
            </v-icon>
        </v-list-item-action>
        <v-list-item-action
            class="mx-1"
            v-if="
                (order.source === 'mercury' || order.source === 'trk' || order.source === 'valutrac') &&
                $ascent.canDo('Work')
            "
        >
            <v-icon small @click="sendInvoice">$vuetify.icons.share</v-icon>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { isCompleted, order } from '@/plugins/order/Order'

export default {
    setup() {
        return { order, isCompleted }
    },
    computed: {
        pdfUrl() {
            return process.env.VUE_APP_PDF
        },
    },
    methods: {
        sendInvoice() {
            confirm(
                'Send Compliance Cert?',
                'Do you want to send a new compliance cert to the client through the integration?',
            ).then((confirm) => {
                if (confirm && order.value) {
                    this.$axios
                        .post('/v1/order/' + order.value.id + '/invoice/send', { type: 'compliance' })
                        .then(() => showSnackbar('Compliance Cert Sent successfully'))
                }
            })
        },
    },
}
</script>
