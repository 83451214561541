






































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import { DateTime } from 'luxon'
import { ValidationObserver } from 'vee-validate'
import { order, updateOrder } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { Milestone } from '@/types'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { getNotes } from '@/plugins/Notes'
import AscentHelper from '@/helpers/ascent-helper'

/**
 * @name WaitingStep
 * Workflow step over inspection has been scheduled. Allows user to reschedule inspection if needed.
 *
 * @SetupData date - date of new inspection date
 * @SetupData time - time of new inspection
 * @SetupData showInspectionDate - determines if the module of changing inspection date is shown or not.
 * @SetupData isSecondInspection - determines if is second inspection or not
 * @SetupData reason - reason for rescheduling the inspection
 *
 * @SetupComputed inspection_date - splits up date and returns in a new format
 * @SetupMethod getInspectionMilestone - looks through milestones on order and returns inspection date if it is there
 * @SetupMethod saveInspection - posts to '/v1/order/order.id/workflow/reschedule' with new inspection date. Refreshes order after posting.
 * @SetupMethod cancelInspection - posts to '/v1/order/' + order.value.id + '/workflow/schedule-cancel' and cancels the inspection
 *
 * @OnMounted - gets the inspection dates if there.
 */

export default defineComponent({
    components: {
        ValidationObserver,
    },
    setup() {
        const modal = ref(false)
        const date = ref('')
        const time = ref('')
        const loading = ref(false)
        const showInspectionDate = ref(false)
        const isSecondInspection = ref(false)
        const reason = ref('')

        const inspection_date = computed(() => {
            if (!date.value) return ''

            const [year, month, day] = date.value.split('-')
            return `${month}/${day}/${year}`
        })

        const inspectionMilestone = computed(() => {
            if (!order.value) return
            if (!order.value.incremental) return

            return order.value.incremental.milestones.find(
                (milestone: Milestone) =>
                    (milestone.milestone_type == 'Inspection Date' ||
                        milestone.milestone_type == 'Second Inspection Date') &&
                    milestone.type == 'current',
            )
        })

        const pastInspectionDate = computed(() => {
            if (inspectionMilestone === undefined) return

            const inspectionTimestamp = DateTime.fromISO(inspectionMilestone.value?.milestone_value, { zone: 'UTC' })
            const nowTimestamp = DateTime.now().toUTC()

            return nowTimestamp > inspectionTimestamp
        })

        const getInspectionMilestone = () => {
            if (!inspectionMilestone.value) return

            const timestamp = DateTime.fromISO(inspectionMilestone.value.milestone_value, { zone: 'UTC' })
            const property = timestamp.setZone('America/Los_Angeles')
            date.value = property.toFormat('yyyy-LL-dd')
            time.value = property.toFormat('HH:mm')
            isSecondInspection.value = inspectionMilestone.value.milestone_type == 'Second Inspection Date'
        }

        const saveInspection = () => {
            if (!order.value) return
            loading.value = true
            $axios
                .post('/v1/order/' + order.value.id + '/workflow/reschedule', {
                    appointment: date.value + ' ' + time.value,
                    note: reason.value,
                    is_second_inspection: isSecondInspection.value,
                })
                .then(() => {
                    if (!order.value) return
                    modal.value = false
                    date.value = ''
                    time.value = ''
                    reason.value = ''
                    loading.value = false
                    showInspectionDate.value = false
                    showSnackbar('Inspection Date Updated Successfully')
                    updateOrder()
                })
                .catch(() => {
                    loading.value = false
                })
        }
        const expanded = ref(false)

        onMounted(() => {
            getInspectionMilestone()
            if (AscentHelper.userOrCustomerFeatureEnabled('orderScreen.newOrderScreen', 'newOrderScreen', true)) {
                expanded.value = true
            }
        })

        const completeInspection = () => {
            if (!order.value) return

            loading.value = true
            $axios
                .post(`/v1/order/${order.value.id}/workflow/complete-inspection`)
                .then(() => {
                    loading.value = false
                    showSnackbar('Order inspection completed successfully')
                    updateOrder()
                })
                .catch((e) => {
                    loading.value = false
                    showError(e.response.data.message)
                    updateOrder()
                })
        }

        const cancelInspection = () => {
            confirm('Cancel Inspection?', 'Are you sure you want to cancel this inspection?').then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    $axios
                        .post('/v1/order/' + order.value.id + '/workflow/schedule-cancel')
                        .then(() => {
                            if (!order.value) return
                            if (!order.value.incremental) return
                            updateOrder()
                            order.value.inspection = null
                            order.value.incremental.inspection = null
                            getNotes(order.value.id)
                            showSnackbar('Inspection Cancelled')
                        })
                        .catch(() => {
                            showError('Oops, something went wrong!')
                        })
                }
            })
        }

        return {
            modal,
            date,
            time,
            reason,
            loading,
            showInspectionDate,
            inspection_date,
            saveInspection,
            cancelInspection,
            completeInspection,
            expanded,
            pastInspectionDate,
        }
    },
})
