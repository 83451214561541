











import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'

/**
 * @name OrderInstructions
 * Shows the order instructions to the appraiser on the order screen if there are any.
 */

export default defineComponent({
    setup() {
        return { order }
    },
})
