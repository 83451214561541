




import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { branch } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'
import $axios from 'axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    name: 'BranchSpecialDirectivesTag',
    setup() {
        const specialTagOption = ref(false)
        onBeforeMount(() => {
            branch.value?.options?.forEach((option) => {
                if (option.option_key === 'special-tag') {
                    specialTagOption.value = option.option_value as unknown as boolean
                }
            })
        })

        const updateOption = () => {
            if (!branch.value) return
            $axios
                .post(`/v1/branch/${branch.value.id}/option`, {
                    option_key: 'special-tag',
                    option_value: specialTagOption.value,
                })
                .then(() => {
                    showSnackbar('Option saved successfully')
                })
        }

        return { branch, specialTagOption, updateOption }
    },
})
