import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import router from '@/pages/router'

if (process.env.VUE_APP_ENV === 'production') {
    Sentry.init({
        Vue,

        dsn: 'https://d159883c8bf7404cbc12cd72793bf548@o451462.ingest.sentry.io/5663515',

        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],

        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'UnhandledRejection: Non-Error promise rejection captured with value:',
            'Request failed with status code 403',
            'Request failed with status code 401',
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.1,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.01,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 0.25,

        trackComponents: true,
    })
}
