



















import { queue } from '@/pages/AMC/Quality/Process/QcReview/Partials/QCReviewProcessData'
import { defineComponent } from '@vue/composition-api'
import { order, openCloseOrder, closeOrder } from '@/plugins/order/Order'

/**
 * @name ClientOrderToolbarMobile
 * Mobile toolbar for Client Order Screen
 *
 * @SetupMethod closeClientOrder - closes the order screen
 */

export default defineComponent({
    setup() {
        return { order }
    },
    methods: {
        closeClientOrder(): void {
            if (queue.value !== undefined) {
                openCloseOrder(false)
                return
            }
            closeOrder()
        },
    },
})
