var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',[_c('v-col',{attrs:{"align":_vm.revisionsLength ? 'end' : 'center'}},[(_vm.$ascent.canDo('Edit Clients'))?_c('v-btn',_vm._g(_vm._b({class:_vm.revisionsLength && 'mr-8 mt-4',attrs:{"text":"","color":"secondarydarken4"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.directiveTitle)+" ")]):_vm._e()],1),(!_vm.$ascent.canDo('Edit Clients') && !_vm.revisionsLength)?_c('h4',[_vm._v("No Directives Added")]):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('a-card',{attrs:{"title":"Edit Directive"}},[_c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-container',{staticClass:"pa-2"},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-text',[_c('a-editor',{attrs:{"id":_vm.isAdmin ? 'adminDirectiveEditor' : 'DirectiveEditor',"uuid":_vm.isAdmin ? 'adminDirectiveEditor' : 'DirectiveEditor',"useLink":true,"toolbarOptions":[
                                'bold',
                                'italic',
                                'underline',
                                '|',
                                'insertLink',
                                '|',
                                'align',
                                'formatOL',
                                'formatUL',
                                'outdent',
                                'indent',
                                'clearFormatting',
                                'insertTable' ]},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":!_vm.updated,"color":"primary"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v(" Save Directives ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }