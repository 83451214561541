import { ref } from '@vue/composition-api'
import { AppraiserProductFees, ProductForm as Product, AppraiserRole, AppraiserRoleName } from '@/types'
import { netRevenue } from '@/components/OrderScreens/Office/OrderComponents/OfficeOrderFees/OfficeOrderFees.vue'

export const FEES_BY_ROLE_NAME = ref<{ [roleName in AppraiserRoleName]: { vendor: number; total: number } }>({
    Inspector: { vendor: 0, total: 0 },
    'Data Analyst': { vendor: 0, total: 0 },
    Reviewer: { vendor: 0, total: 0 },
    Primary: { vendor: 0, total: 0 },
    full: { vendor: 0, total: 0 },
    Trainee: { vendor: 0, total: 0 },
})

const APPRAISER_ROLE_BY_ROLE_NAME: Record<AppraiserRoleName, AppraiserRole> = {
    Inspector: 'inspection',
    'Data Analyst': 'data',
    Reviewer: 'review',
    Primary: 'full',
    full: 'full',
    Trainee: 'trainee',
}

const calcProductFee = (productItem: Product, feeSetting: AppraiserProductFees, innovationProduct: Product) => {
    const productLineTotal = Number(productItem.line_total)

    switch (true) {
        case new Boolean(!feeSetting[productItem.product_id]):
            return productLineTotal
        case productItem.formType === 'Main Product' && !!innovationProduct:
            return productLineTotal - (innovationProduct.appraiser_value as number)
        default:
            return productLineTotal
    }
}

export const calcVendorFee = (
    roleName: AppraiserRoleName,
    productItem: Product,
    feeSetting: AppraiserProductFees,
    innovationProduct: Product,
    mainProduct: Product,
) => {
    const roleFormat = APPRAISER_ROLE_BY_ROLE_NAME[roleName] ?? roleName

    let [vendorFeeFinal, productFeeFinal, vendorFeeSetting] = Array(3).fill(0)

    productFeeFinal = calcProductFee(productItem, feeSetting, innovationProduct)

    switch (true) {
        case productItem.product_name === 'Innovation Fee':
            break
        case !feeSetting.percentage && !!feeSetting[productItem.product_id]:
            vendorFeeSetting = feeSetting[productItem.product_id]
            vendorFeeFinal = vendorFeeSetting[roleFormat]
            break
        case feeSetting.percentage:
            if (!netRevenue.value) {
                break
            }

            if (!feeSetting[productItem.product_id]) {
                if (mainProduct && feeSetting[mainProduct.product_id]) {
                    vendorFeeSetting = feeSetting[mainProduct.product_id]
                    vendorFeeFinal = (productFeeFinal / 100) * vendorFeeSetting[roleFormat]
                }
                break
            }

            vendorFeeSetting = feeSetting[productItem.product_id]
            vendorFeeFinal = (productFeeFinal / 100) * vendorFeeSetting[roleFormat]
            break
    }

    if (Boolean(vendorFeeSetting) && (vendorFeeFinal !== 0 || productFeeFinal !== 0)) {
        FEES_BY_ROLE_NAME.value = {
            ...(FEES_BY_ROLE_NAME.value || {}),
            [roleName]: {
                vendor: FEES_BY_ROLE_NAME.value[roleName].vendor + vendorFeeFinal,
                total: FEES_BY_ROLE_NAME.value[roleName].total + productFeeFinal,
            },
        }
    }

    return vendorFeeFinal
}
