





























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    data() {
        return {
            dialog: false,
            loading: false,
            lenders: [],
        }
    },
    methods: {
        lookup() {
            this.loading = true
            this.$axios.get('/v1/integration/action/get-reggora-lenders').then((response) => {
                this.loading = false
                this.lenders = response.data.data
            })
        },
    },
})
