













import { computed, defineComponent } from '@vue/composition-api'
import { finalReview } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    props: {
        title: {
            type: String,
            default: '',
        },
        editEnabled: {
            type: Boolean,
            default: false,
        },
        review: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const emitEditClick = () => {
            emit('edit-clicked')
        }

        const canEdit = computed(() => {
            if (finalReview.value) return false
            if (props.review) return false
            return !props.editEnabled
        })

        return {
            emitEditClick,
            finalReview,
            canEdit,
        }
    },
})
