var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order.incremental)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._t("default")],2),_c('v-col',{attrs:{"cols":"12"}},[(_vm.submission == null && !_vm.order.incremental.ucdp.veros && _vm.lenderShouldShowUcdp(_vm.order.incremental))?_c('OrderUcdp'):_vm._e(),(
                _vm.submission != null &&
                _vm.submission.complete === 1 &&
                (_vm.submission.submit_code !== '1' ||
                    (_vm.submission.status != null &&
                        _vm.submission.status.result !== undefined &&
                        _vm.submission.status.result === 'Failed'))
            )?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"type":"error","text":""}},[_c('h6',{staticClass:"text-h6 error--text font-weight-bold"},[_vm._v("UCDP/EAD Submission Error")]),(_vm.submission.status != null)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.submission.status.Error))]):_vm._e()])],1)],1):_vm._e(),(_vm.submission != null && _vm.submission.status != null && _vm.order.incremental.ucdp.veros)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('h6',{staticClass:"text-h6"},[_vm._v("Current UCDP/EAD Submission")])]),_c('v-col',{staticClass:"text-right"},[(_vm.order.incremental.ucdp.veros)?_c('p',{staticClass:"small"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Document File Identifier:")]),_vm._v(" "+_vm._s(_vm.order.incremental.ucdp.veros.DocumentFileIdentifier)+" ")]):_vm._e()])],1),(typeof _vm.submission.status.submission !== 'undefined')?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"top","colored-border":"","color":_vm.submission.status.submission['@attributes']._Condition === 'SUCCESS'
                                    ? 'success'
                                    : 'warning',"icon":_vm.submission.status.submission['@attributes']._Condition === 'SUCCESS'
                                    ? '$vuetify.icons.check-circle'
                                    : '$vuetify.icons.exclamation-circle'}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Status:")]),_vm._v(" "+_vm._s(_vm.submission.status.submission['@attributes']._Condition)+" ")])],1),(typeof _vm.submission.status.FNM != 'undefined')?_c('v-col',[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"top","colored-border":"","color":_vm.submission.status.FNM === 'SUCCESSFUL' ? 'success' : 'warning',"icon":_vm.submission.status.FNM === 'SUCCESSFUL'
                                    ? '$vuetify.icons.check-circle'
                                    : '$vuetify.icons.exclamation-circle'}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Fannie Status:")]),_vm._v(" "+_vm._s(_vm.submission.status.FNM)+" ")])],1):_vm._e(),(typeof _vm.submission.status.FRE != 'undefined')?_c('v-col',[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"top","colored-border":"","color":_vm.submission.status.FRE === 'SUCCESSFUL' ? 'success' : 'warning',"icon":_vm.submission.status.FRE === 'SUCCESSFUL'
                                    ? '$vuetify.icons.check-circle'
                                    : '$vuetify.icons.exclamation-circle'}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Freddie Status:")]),_vm._v(" "+_vm._s(_vm.submission.status.FRE)+" ")])],1):_vm._e(),(typeof _vm.submission.status.FHA != 'undefined')?_c('v-col',[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"top","colored-border":"","color":_vm.submission.status.FHA === 'SUCCESSFUL' ? 'success' : 'warning',"icon":_vm.submission.status.FHA === 'SUCCESSFUL'
                                    ? '$vuetify.icons.check-circle'
                                    : '$vuetify.icons.exclamation-circle'}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("EAD Status:")]),_vm._v(" "+_vm._s(_vm.submission.status.FHA)+" ")])],1):_vm._e()],1):_vm._e(),(_vm.submission != null && _vm.submission.status != null && _vm.submission.submit_code === 1)?_c('h6',{staticClass:"mt-6"},[_vm._v(" Findings ")]):_vm._e(),(_vm.submission != null && _vm.submission.status != null && _vm.submission.submit_code === '1')?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.findingHeader,"items":_vm.order.incremental.ucdp.findings,"item-key":"id","hide-default-footer":"","disable-pagination":"","hide-default-header":_vm.order.incremental.ucdp.findings && _vm.order.incremental.ucdp.findings.length === 0},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mb-0",attrs:{"dark":"","tile":"","color":"success","outlined":""}},[_vm._v("No Warnings")])]},proxy:true},{key:"item",fn:function(ref){
                                    var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.FormSection))]),_c('td',[_vm._v(_vm._s(item.FormField))]),_c('td',[_vm._v(_vm._s(item.DataPoint))]),_c('td',[_vm._v(_vm._s(item.PropertyAffected))]),_c('td',[_vm._v(_vm._s(item.ActionMessage))]),_c('td',[_vm._v(_vm._s(item.Severity))]),(_vm.actions)?_c('td',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(typeof item.added !== 'boolean'),expression:"typeof item.added !== 'boolean'"}],staticClass:"ma-0",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){return _vm.addWarning(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.plus")])],1)],1):_vm._e()])]}}],null,false,659261517)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.stopsHeader,"items":_vm.order.incremental.ucdp.stops,"item-key":"id","hide-default-footer":"","disable-pagination":"","hide-default-header":_vm.order.incremental.ucdp.stops && _vm.order.incremental.ucdp.stops.length === 0},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mb-0",attrs:{"dark":"","tile":"","outlined":"","color":"success"}},[_vm._v("No Hard Stops")])]},proxy:true},{key:"item",fn:function(ref){
                                    var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[_vm._v(_vm._s(item.code))]),_c('td',[(
                                                item.OverrideStatus !== '' && item.OverrideStatus !== 'Not Approved'
                                            )?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(item.OverrideStatus)+" ")]):_vm._e(),(
                                                (item.OverrideStatus === '' ||
                                                    item.OverrideStatus === 'Not Approved') &&
                                                _vm.isOverrideable(item.code)
                                            )?_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.$refs.override.open(item)}}},[_vm._v(" Submit Override ")]):_vm._e(),(
                                                (item.OverrideStatus === '' ||
                                                    item.OverrideStatus === 'Not Approved') &&
                                                !_vm.isOverrideable(item.code)
                                            )?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v(" Failed/Not Able to Override ")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.OverrideDecisionCode))]),_c('td',[_vm._v(" "+_vm._s(item.OverrideDecisionDescription)+" ")]),(_vm.actions)?_c('td',[_c('v-btn',{staticClass:"ma-0",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){return _vm.addStop(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.plus")])],1)],1):_vm._e()])]}}],null,false,103662212)}),_c('UcdpOverride',{ref:"override"})],1)],1)],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }