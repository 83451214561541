














































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import AscentHelper from '@/helpers/ascent-helper'

interface Broadcast {
    id: string
    appraiser_name: string
    appraiser_email: string
    status: string
    status_date: string
    created_at: string
    note: string
}
export default defineComponent({
    computed: {
        AscentHelper() {
            return AscentHelper
        },
    },
    setup() {
        const broadcasts = ref<Broadcast[]>([])

        onMounted(async () => {
            try {
                broadcasts.value = (
                    await axios.get(`/v1/appraiser-order-broadcast/${order.value?.id}/broadcasts`)
                ).data.data
            } catch (e: any) {
                showError(`Error fetching data. (${e})`)
            }
        })
        const onManuallyAssign = () => {
            const payload = {
                note: 'Move from Broadcasting to Manually Assign',
                decline: 'declined',
                reassignment: 'broadcasting',
                selectedAppraiser: '',
            }

            axios.post(`v1/order/${order.value?.id}/workflow/reassign`, payload).catch(() => {
                showError('Error moving order to Manual Assign')
            })
        }

        return {
            onManuallyAssign,
            broadcasts,
        }
    },
})
