


































import { defineComponent } from '@vue/composition-api'
import { AppraiserPayment } from '@/types'

export default defineComponent({
    name: 'AppraiserPaymentDetails',
    data() {
        return {
            dialog: false,
            payment: undefined as unknown as undefined | AppraiserPayment,
        }
    },
    methods: {
        open(payment: AppraiserPayment) {
            this.payment = payment
            this.dialog = true
        },
        close() {
            this.dialog = false
            this.payment = undefined
        },
    },
})
