var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ACard',{attrs:{"title":"Escalate Order"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',[(_vm.escalationTags.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-overflow-btn',{attrs:{"items":_vm.escalationTags,"placeholder":"Select a tag","item-value":"id","solo":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-chip',{attrs:{"color":data.item.color,"dark":"","small":""}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('p',{staticClass:"caption ml-6 mt-9",domProps:{"innerHTML":_vm._s(data.item.description)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":item.color,"dark":"","small":""}},on),[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"caption ml-6 mt-9",domProps:{"innerHTML":_vm._s(item.description)}})]}}],null,true),model:{value:(_vm.escalationTag),callback:function ($$v) {_vm.escalationTag=$$v},expression:"escalationTag"}})],1),_c('v-col',[_c('a-editor',{attrs:{"rules":"required","label":"Escalation Reason","name":"escalation reason","title":"Escalation Reason"},model:{value:(_vm.escalationReason),callback:function ($$v) {_vm.escalationReason=$$v},expression:"escalationReason"}})],1)],1):_vm._e(),(!_vm.escalationTags.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"text":"","type":"info"}},[_vm._v("No escalation tags created for this order status yet.")])],1)],1):_vm._e()],1),_c('a-card-action',{attrs:{"url":'/v1/order/' + _vm.order.id + '/escalate',"submitData":{
                    note: _vm.escalationReason,
                    escalation_tag: _vm.escalationTag,
                },"handleSubmit":handleSubmit},on:{"cancel":function($event){_vm.dialog = false},"success":_vm.orderEscalated}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }