<template>
    <v-container>
        <template v-slot:activator="{ on }">
            <v-list-item @click="open" v-on="on">
                <v-list-item-title>Reassign</v-list-item-title>
            </v-list-item>
        </template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
            <ACard title="Reassign Order" :elevation="0">
                <v-card-text v-if="!loaded">
                    <v-skeleton-loader type="card" />
                </v-card-text>
                <v-card-text v-if="loaded">
                    <v-row v-if="isOfficeAppraiser">
                        <v-col cols="12">
                            <v-radio-group row v-model="reassignment">
                                <v-radio
                                    class="mb-3"
                                    label="Reassign in Appraiser Office"
                                    value="office"
                                    :disabled="officeAppraiserReassign"
                                />
                                <v-radio
                                    class="mb-3"
                                    label="Reassign Outside Appraiser Office"
                                    value="outside"
                                    :disabled="officeAppraiserReassign"
                                />
                            </v-radio-group>
                            <v-alert type="warning" outlined v-if="officeAppraiserReassign">
                                You will need to Accept or Reject the conditions before reassigning the Order
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="reassignment === 'office'">
                        <v-col cols="12">
                            <a-autocomplete
                                v-model="selectedAppraiser"
                                rules="required"
                                label="Appraisers in Office"
                                :items="officeAppraiserPanel"
                                item-value="id"
                                item-text="name"
                            >
                                <template v-slot:item="{ item }">
                                    <v-list dense>
                                        <v-list-item>
                                            <v-list-item-content>
                                                {{ item.name }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="!item.eligible.result">
                                            <v-list-item-action>
                                                <v-chip x-small color="error" outlined label>Not Eligible</v-chip>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                {{ item.eligible.details }}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </template>
                            </a-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row v-if="reassignment === 'outside' || !isOfficeAppraiser">
                        <v-col cols="12">
                            <a-radio-group v-model="decline" name="decline">
                                <v-radio
                                    v-for="reason in computedReasons"
                                    :key="reason.value"
                                    :label="reason.label"
                                    :value="reason.value"
                                />
                            </a-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="order.incremental && order.incremental.customer.type === 'desk'">
                        <v-col cols="12">
                            <AmcSelect
                                v-model="selectedAppraiser"
                                rules="required"
                                label="Available AMCs"
                                :return-object="false"
                                :check-allocation-rules="$ascent.customerFeatureEnabled('assignType') === 'vendor'"
                                @set-selected-appraiser="selectedAppraiser = $event"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="reassignment || !isOfficeAppraiser">
                        <v-col cols="12">
                            <v-textarea
                                label="Type Reason for Reassigning"
                                name="Reassigning Appraiser"
                                outlined
                                v-model="note"
                            />
                        </v-col>
                    </v-row>
                    <v-alert type="warning" outlined v-if="isEligible && !isEligible.result">
                        You are about to assign an appraiser who is not eligible because:
                        {{ isEligible.details }} Please verify before assigning!
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <a-btn-confirm
                        @click="handleSubmit(reassignOrder)"
                        :disabled="
                            invalid ||
                            !note ||
                            ($ascent.customerFeatureEnabled('orderScreen.useAmcReassignAllocationRules') &&
                                !selectedAppraiser)
                        "
                        :loading="loading"
                    >
                        Reassign Order
                    </a-btn-confirm>
                </v-card-actions>
            </ACard>
        </ValidationObserver>
    </v-container>
</template>
<script lang="js">
import {assignOpen} from "@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel";
import {defineComponent} from "@vue/composition-api"
import {ValidationObserver} from 'vee-validate'
import {showSnackbar, showError} from '@/AppLayout/helpers/snackbar.vue'
import {order, refreshOrder} from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import AmcSelect from "@/components/General/AutoCompletes/AmcSelect.vue";
function reset() {
    return {
        note: null,
        decline: null,
        childOrder: false,
        reasons: [
            {label: 'Appraiser Declined', value: 'declined'},
            {label: 'Appraiser Recusal', value: 'recusal'},
            {label: 'Appraiser Unresponsive', value: 'unresponsive'},
            {label: 'Better Quote', value: 'better quote'},
            {label: 'Complaint', value: 'complaint'},
            {label: 'AMC Reassigned', value: 'amc reassigned'},
        ],
        isOfficeAppraiser: false,
        officeAppraiserPanel: [],
        reassignment: '',
        selectedAppraiser: '',
        loaded: false,
        loading: false,
    }
}
/**
 * @name CustomerReassignVendor
 * Reassign appraiser with reason of why user is reassigning the appraiser
 */
export default defineComponent({
    setup() {
        return {
            order
        }
    },
    name: "CustomerReassignVendor",
    components: { ValidationObserver, AmcSelect },
    data() {
        return reset()
    },
    mounted() {
        $axios.get(`/v1/order/${order.value.id}/action/get-appraiser-office-panel`).then(response => {
            this.officeAppraiserPanel = response.data.appraisers.map(elem => {
                return  {
                    ...elem.appraiser,
                    eligible: elem.eligible
                }
            } ).filter(elem => elem.id !== order.value.appraiser_id)
            if (response.data.appraisers.length) this.isOfficeAppraiser = true
        }).then(() =>{
            this.loaded = true
        })
    },
    methods: {

        /**
         * Reassigns the order. Posts to /v1/order/' + order.value.id + '/workflow/reassign with note.
         */
        reassignOrder() {
            if (!order.value) return
            this.loading = true

            if (!this.isOfficeAppraiser) this.reassignment = "outside"
            if (order.value.incremental && order.value.incremental.customer && order.value.incremental.customer.type === 'desk') this.reassignment = 'desk'

            this.$axios.post('/v1/order/' + order.value.id + '/workflow/reassign', {
                note: this.note,
                decline: this.decline,
                reassignment: this.reassignment,
                selectedAppraiser: this.selectedAppraiser
            }).then(() => {
                if (!order.value) return
                refreshOrder()
                order.value.appraiser_id = null
                order.value.appraiserName = null
                showSnackbar('Order marked for reassignment.')
                Object.assign(this.$data, reset())
                this.$refs.form.reset()
                this.loading = false
            }).catch((error) => {
                let errorMessage = 'Something went wrong'
                if(error.response?.data?.message) {
                    errorMessage = error.response.data.message
                } else if(error.response?.data?.errors && error.response?.data?.errors.length > 0) {
                    errorMessage = error.response.data.errors[0]
                    if(typeof errorMessage === 'object') {
                        errorMessage = errorMessage[0]
                    }
                }
                showError(errorMessage)
                this.loading = false
            })
            assignOpen.value = false
        }
    },
    computed: {
        officeAppraiserReassign() {
            if(!order.value) return false
            if(!order.value.incremental) return false
            if(!order.value.incremental.child_order) return false
            if(!order.value.incremental.child_order.id) return false
            if (
                order.value.tags?.find(
                    (elem) => elem.tag == 'Conditional',
                ) !== undefined
            ) return true
            return false
        },
        isEligible() {
            if(!order.value) return false
            if(!order.value.incremental) return false
            if(this.officeAppraiserPanel === []) return false
            if(this.selectedAppraiser === '') return false
            return this.officeAppraiserPanel.find((el) => {
                return el.id === this.selectedAppraiser
            })?.eligible
        },
        computedReasons() {
            if(order.value.incremental.customer.type === 'amc') return this.reasons
            return [
                {label: 'AMC Reassigned', value: 'amc reassigned'}
            ]
        }
    },
})
</script>
