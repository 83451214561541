import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/notifications',
        name: 'All Notifications',
        component: () => import('@/pages/System/Notifications/GeneralNotificationsPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/approvals',
        name: 'Approvals',
        component: () => import('@/pages/System/Notifications/ApprovalNotificationsPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/pages/System/UserProfile/UserProfilePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/profile/reset-password',
        name: 'Change Password System',
        component: () => import('@/pages/System/UserProfile/UserResetPassword.vue'),
        meta: { requiresAuth: true, showNav: false },
    },
    {
        path: '/order/:id',
        name: 'View Order',
        component: () => import('@/pages/System/ViewOrder.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/order/:id/:type',
        name: 'View Order - Read Only',
        component: () =>
            import(
                '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerClientVendorOrderScreenViewOnly.vue'
            ),
        meta: { requiresAuth: true, userType: ['amc', 'desk', 'office'] },
    },
]

export default routes
