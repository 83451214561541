















import { defineComponent } from '@vue/composition-api'
import AppraiserFeesConfigureDialog from '@/pages/Appraiser/Profile/AppraiserSettings/AppraiserSettingsPartials/AppraiserRolePartials/AppraiserFeesConfigureDialog.vue'
/**
 * @name 'Appraiser Forms'
 * Component description: The configuration of the forms page in the appraiser settings.
 */
export default defineComponent({
    components: { AppraiserFeesConfigureDialog },
    props: {
        option: {
            type: Object,
        },
    },
    setup() {
        return {}
    },
})
