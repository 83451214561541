var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"text-right"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"depressed":"","small":"","color":"grey lighten-3","width":"150"},on:{"click":function($event){$event.stopPropagation();return _vm.editAppraiser.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("$vuetify.icons.externalLinkSquare")]),_vm._v(" View Profile ")],1),(_vm.appraiser.appraiserOrders)?_c('v-chip',{staticStyle:{"width":"150px"},attrs:{"color":"primarylighten5 mt-2","small":""},on:{"click":function($event){return _vm.openAppraiserOrdersDialog(
                        _vm.appraiser.appraiserOrders,
                        _vm.item.appraiser.name,
                        _vm.appraiser.allOtherOrders
                    )}}},[_c('v-avatar',{staticClass:"white--text mr-2",attrs:{"color":"primary","dark":"","left":""}},[_vm._v(" "+_vm._s(_vm.appraiser.appraiserOrders.length)+" ")]),_vm._v(" Other Orders ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(
                    _vm.order.incremental.source_system === 'ascent' &&
                    _vm.order.incremental.appraiser_id !== _vm.item.appraiser.id
                )?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_c('p',[_vm._v("Primary Appraiser for this order is "+_vm._s(_vm.order.incremental.appraiser.name)+".")]),_c('p',[_vm._v("Contact AMC if primary appraiser must be reassigned.")])]):_c('OfficeAssignButton',{attrs:{"appraiser":_vm.appraiser,"item":_vm.item}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }