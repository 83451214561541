var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"mt-0 pt-0 ml-0 pl-0 mr-0 pr-0",attrs:{"items":_vm.conditionList,"color":"accentWhitedarken1"}},[_vm._l((_vm.conditionList),function(item,i){return [_c('v-divider',{key:(i + "-divider")}),_c('v-list-item',{key:i,attrs:{"value":item,"color":"primary"}},[_c('v-row',[_c('v-col',{staticClass:"v-align",attrs:{"cols":"8"}},[_c('v-list-item-title',{staticClass:"v-align",domProps:{"innerHTML":_vm._s(item.condition)}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-action',{staticClass:"float-right mr-0 mt-0 mb-0"},[(_vm.user && _vm.user.type !== 'appraiser')?_c('span',{staticClass:"d-flex align-center"},[(
                                    item.appraiser_complete &&
                                    !item.verified &&
                                    (_vm.order.status !== 'QC Review' || _vm.order.status !== 'Reconsideration Review')
                                )?_c('v-alert',{staticClass:"ma-2 small",staticStyle:{"font-size":"small","width":"100%"},attrs:{"type":"warning","dense":"","text":"","small":""}},[_vm._v(" Pending Completion ")]):_vm._e(),(
                                    item.appraiser_complete &&
                                    !item.verified &&
                                    (_vm.order.status === 'QC Review' || _vm.order.status === 'Reconsideration Review')
                                )?_c('v-btn',{attrs:{"ripple":"","icon":"","small":"","color":"success"},on:{"click":function($event){return _vm.verifyCondition(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.success")])],1):_vm._e(),(
                                    item.appraiser_complete &&
                                    !item.verified &&
                                    (_vm.order.status === 'QC Review' || _vm.order.status === 'Reconsideration Review')
                                )?_c('v-btn',{attrs:{"ripple":"","icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.failCondition(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.times")])],1):_vm._e(),(
                                    (!item.appraiser_complete &&
                                        (_vm.order.status === 'QC Review' ||
                                            _vm.order.status === 'UW Request' ||
                                            _vm.order.status === 'UW Revision' ||
                                            _vm.order.status === 'QC Revision' ||
                                            _vm.order.status === 'Reconsideration Review')) ||
                                    (item.appraiser_complete &&
                                        !item.verified &&
                                        (_vm.order.status !== 'QC Review' || _vm.order.status !== 'Reconsideration Review'))
                                )?_c('v-btn',{staticClass:"ma-0",attrs:{"icon":"","small":"","ripple":""},on:{"click":function($event){return _vm.removeCondition(item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("$vuetify.icons.trashAlt")])],1):_vm._e(),(item.appraiser_complete && item.verified)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" $vuetify.icons.thumbsUp ")]):_vm._e()],1):_vm._e()])],1)],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }