
































import { defineComponent, PropType } from '@vue/composition-api'
import { Entity } from '@/types'

/**
 * @name AppraiserOrderEntities
 * Shows the entities on the order with appraiser-level visibility
 */

export default defineComponent({
    name: 'AppraiserOrderEntities',
    props: {
        entities: {
            type: Array as PropType<Entity[]>,
            default: () => [],
        },
    },
})
