<template>
    <v-list v-if="data[data.column.field] != null" style="background-color: transparent" dense>
        <a v-if="data.status === 'Pending'" class="primary--text" href="#" @click.stop="openPreQuoteNote(data)">
            <strong>{{ data[data.column.field] }}</strong>
        </a>
        <strong v-else>{{ data[data.column.field] }}</strong>
    </v-list>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { openPreQuoteNote } from '@/pages/AMC/Quotes/Partials/ViewPreQuoteDialog.vue'

/**
 * @name PreQuoteIdColumn
 * Component description: A template cell for the id of quote that allows user to open dialog with quote notes.
 * if the status is pending, open the prequotenote dialog.
 */
export default defineComponent({
    props: {},
    data() {
        return { openPreQuoteNote }
    },
})
</script>
