































































import { defineComponent, ref, computed } from '@vue/composition-api'
import PreloadStepperHeader from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadStepperHeader.vue'
import PreloadActionButtons from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/Partials/PreloadActionButtons.vue'
import PreloadPropertySize from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadZoning/PreloadPropertySize.vue'
import PreloadZoningDetails from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadZoning/PreloadZoningDetails.vue'
import PreloadGoogleMap from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadZoning/PreloadGoogleMap.vue'
import { order } from '@/plugins/order/Order'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'
import { reviewStep } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    components: {
        PreloadStepperHeader,
        PreloadActionButtons,
        PreloadPropertySize,
        PreloadZoningDetails,
        PreloadGoogleMap,
    },
    setup() {
        const editData = ref(false)
        const editCancelled = ref(false)

        const cancelEdit = () => {
            editCancelled.value = true
            editData.value = false
        }

        const openEditData = () => {
            editData.value = true
            editCancelled.value = false
        }

        const saveEdit = () => {
            editData.value = false
        }
        const zoningMapUrl = computed(() => {
            if (!order.value) return ''
            return `https://www.zoneomics.com/api/get_zone_screen_shot?address=${order.value.address.street} ${order.value.address.city} ${order.value.address.state} ${order.value.address.zip}&api_key=${process.env.VUE_APP_ZONEOMICS_API_KEY}`
        })

        return {
            editData,
            openEditData,
            cancelEdit,
            saveEdit,
            reviewStep,
            editCancelled,
            zoningMapUrl,
            preloadData,
        }
    },
})
