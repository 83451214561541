import Tribute from 'tributejs'
import $axios from '@/plugins/axios'
import { get as lodashGet } from 'lodash'
import AscentHelper from '@/helpers/ascent-helper'

export default class {
    static async init(order, incrementalOrder, user) {
        if (!user || (user.type !== 'office' && user.type !== 'amc')) return
        const mergedOrder = { ...order, ...incrementalOrder }
        let defaultSnippets = [
            {
                code: 'Appraiser',
                value: this.getMergedText('[appraiser]', mergedOrder),
            },
            {
                code: 'Subject Property Address',
                value: this.getMergedText('[subject_property_address]', mergedOrder),
            },
            {
                code: 'Appraiser Due Date',
                value: this.getMergedText('[appraiser_deadline]', mergedOrder),
            },
            {
                code: 'Client Fee',
                value: this.getMergedText('[client_fee]', mergedOrder),
            },
        ]

        const amcSnippets = [
            {
                code: 'Ordered By Contact',
                value: this.getMergedText('[ordered_by_contact]', mergedOrder),
            },
            {
                code: 'Appraiser Fee',
                value: this.getMergedText('[appraiser_fee]', mergedOrder),
            },
        ]

        if (AscentHelper.isCustomer()) defaultSnippets = [...defaultSnippets, ...amcSnippets]

        try {
            const response = await $axios.get(`/v1/customer/snippet/action/get-user-and-global-snippets/${user.id}`)

            const filteredSnippets = response.data.filter((snippet) => {
                if (snippet.statuses === null) return true
                if (snippet.statuses.length === 0) return true
                return snippet.statuses.indexOf(mergedOrder.amc_status.ascent_status) > -1
            })

            if (AscentHelper.isCustomer()) defaultSnippets = [...defaultSnippets, ...filteredSnippets]

            defaultSnippets = defaultSnippets.sort((a, b) => {
                return a.code.localeCompare(b.code)
            })

            const collection = {
                trigger: '#',
                lookup: 'code',
                allowSpaces: true,
                values: defaultSnippets,
                noMatchTemplate: '',
                selectTemplate: (item) => {
                    return this.getMergedText(item.original.value, order)
                },
            }
            const tribute = new Tribute(collection)
            return tribute
        } catch (error) {
            return
        }
    }

    static getMergedText(item, order) {
        let text = typeof item === 'object' ? item.original.value : item

        if (typeof item === 'object' && !item.original.has_merge_var) {
            return text
        }

        const mergeVariableMap = [
            { variable: 'fha' },
            { variable: 'borrowers' },
            {
                variable: 'appraiser_fee',
                replacePath: 'appraiserFee',
            },
            {
                variable: 'client_fee',
                replacePath: 'amcFee',
            },
            {
                variable: 'appraiser_deadline',
                replacePath: 'appraiserDeadline',
            },
            {
                variable: 'lender_loan_number',
                replacePath: 'lenderLoanNumber',
            },
            {
                variable: 'lender_name',
                replacePath: 'lenderName',
            },
            {
                variable: 'appraiser',
                replacePath: 'appraiserName',
            },
            {
                variable: 'ordered_by_contact',
                replacePath: 'clientName',
            },
            {
                variable: 'subject_property_address',
                replacePath: 'address.full_address_formatted',
            },
        ]

        mergeVariableMap.forEach((el) => {
            let replacement = lodashGet(order, el.replacePath || el.variable)
            if (!replacement && el.variable) replacement = `[merge not available - ${el.variable}]`
            if (el.variable === 'appraiser_deadline' && replacement !== `[merge not available - ${el.variable}]`)
                replacement = AscentHelper.formatUnix(replacement, 'MM/dd/yyyy', false)
            text = text.replace(`[${el.variable}]`, replacement)
        })
        return text
    }

    static appraiserTotal(order) {
        if (order.products.length == 0) return 0
        const quoted = order.products.find((elem) => {
            return elem.quote
        })
        if (typeof quoted != 'undefined') return null
        return order.products.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.appraiser_value)
        }, 0)
    }

    static amcTotal(order) {
        if (order.products.length == 0) return 0
        const quoted = order.products.find((elem) => {
            return elem.quote
        })
        if (typeof quoted != 'undefined') return null
        return order.products.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.amc_value)
        }, 0)
    }
}
