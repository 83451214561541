


























import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, updateOrder } from '@/plugins/order/Order'

export const removeHoldDialog = ref(false)

/**
 * @name OrderRemoveHoldDialog
 * Dialog in the Order Screen that removes a hold on an order and sends a note to the appraiser
 *
 * @SetupData appraiserNotes - note being sent to appraiser
 * @SetupMethod removeHold - removes the hold from the order and refreshes the order
 */

export default defineComponent({
    components: { ValidationObserver },
    setup() {
        const loading = ref(false)
        const appraiserNotes = ref(null)

        const removeHold = (): void => {
            if (!order.value) return
            loading.value = true
            axios
                .post('/v1/order/' + order.value.id + '/workflow/hold-remove', {
                    note: appraiserNotes.value,
                })
                .then(() => {
                    updateOrder()
                    loading.value = false
                    showSnackbar('Order removed from hold successfully')
                    removeHoldDialog.value = false
                })
        }

        return {
            order,
            loading,
            appraiserNotes,
            removeHoldDialog,
            removeHold,
        }
    },
})
