






import { defineComponent } from '@vue/composition-api'
import CustomerEditOrderInvoice from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerEditOrderInvoice.vue'
import OrderReminders from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/OrderReminders.vue'
import CustomerOrderDuplicates from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderDuplicates.vue'
import CustomerOrderContacts from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderContacts.vue'
import CustomerOrderConditions from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderConditions.vue'
import CustomerEditOrderInstructions from '@/components/OrderScreens/Customer/CustomerEditComponents/CustomerEditOrderInstructions.vue'
import CustomerOrderInvoice from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderInvoice.vue'
import ChangeAssigner from '@/components/OrderScreens/Customer/CustomerEditComponents/ChangeAssigner.vue'
import ChangeCSS from '@/components/OrderScreens/Customer/CustomerEditComponents/ChangeCSS.vue'
import ChangeTeam from '@/components/OrderScreens/Customer/CustomerEditComponents/ChangeTeam.vue'
import ClientEdit from '@/components/OrderScreens/Customer/CustomerEditComponents/ClientEdit.vue'
import ClientDirectives from '@/components/OrderScreens/Customer/CustomerOrderComponents/ClientDirectives.vue'
import OrderAccess from '@/components/OrderScreens/Amc/OrderComponents/OrderScreen/OrderAccess.vue'
import CustomerOrderFiles from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderFiles.vue'
import CommercialOrderDetails from '@/components/OrderScreens/Commercial/CommercialComponents/CommercialOrderDetails.vue'
import CustomerOrderEntities from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderEntities.vue'

export default defineComponent({
    name: 'DetailEditComponent',
    props: {
        detailItem: String,
    },
    components: {
        CustomerEditOrderInvoice,
        CustomerOrderEntities,
        CustomerOrderFiles,
        OrderReminders,
        CustomerOrderDuplicates,
        CustomerOrderContacts,
        CustomerOrderConditions,
        CustomerEditOrderInstructions,
        CustomerOrderInvoice,
        ChangeAssigner,
        ClientDirectives,
        ChangeCSS,
        ChangeTeam,
        ClientEdit,
        OrderAccess,
        CommercialOrderDetails,
    },
})
