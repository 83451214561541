import { CustomerFeatures, CustomerPages } from '@/types'

const features: CustomerFeatures = {
    orderScreen: {
        allowTraineeAppraisers: false,
        rep: false,
        assignDirectives: false,
        appraiserDeadline: false,
        appraiserOtherOrders: false,
        accessLog: false,
        useEngagementLetter: false,
        autoInvoice: false,
        autoComplianceFiles: false,
        acceptType: 'full',
        invoice: {
            feeSummaryType: 'total_fees',
            allowCreditCard: false,
            lenderInvoiceCode: false,
            sendBorrowerPayment: false,
            disclosedFee: false,
        },
        workflow: false,
        showLenderAddress: false,
    },
}

export default features

export const pages: CustomerPages = {
    clients: {
        clientFeeEstimator: false,
    },
    lenders: {
        groups: false,
        lender: false,
        clients: false,
        branches: false,
        investors: false,
        brokerages: false,
    },
    vendors: {
        amcs: false,
        matches: false,
        offices: false,
        pending: false,
        directory: false,
        appraisers: false,
        exclusionary: false,
    },
    columns: [],
    accounting: {
        client: {
            AR: true,
            bulk: true,
            paid: true,
            failed: true,
            unpaid: true,
            enabled: true,
            deferred: true,
            overPayment: true,
        },
        enabled: true,
        appraiser: {
            paid: true,
            manual: true,
            enabled: true,
            payroll: true,
        },
    },
}
