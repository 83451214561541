








































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import FileUpload from '@/components/General/FileUpload.vue'
import { get } from 'lodash'
import $axios from '@/plugins/axios'
import { File, OfficeCompleteFiles, UppyFileResponse } from '@/types'
import convert, { ElementCompact } from 'xml-js'
import { Dialog } from '@/ascent'
import { user, enabledCustomerFeatures } from '@/plugins/User'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { returnAppraiser } from '@/components/OrderScreens/Workflow/QualityAction.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import CommercialComplete from '@/components/OrderScreens/Office/OrderComponents/Workflows/CommercialComplete.vue'
import { ValidationObserver } from 'vee-validate'
import AscentHelper from '@/helpers/ascent-helper'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import CancelInspectionButton from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/CancelInspectionButton.vue'

interface ValuationResponse {
    [x: string]: ElementCompact
}

export default defineComponent({
    components: {
        CommercialComplete,
        FileUpload,
        ValidationObserver,
        CancelInspectionButton,
    },
    setup() {
        const loading = ref(false)
        const error = ref('')
        const actionLoading = ref(false)
        const modal = ref(false)
        const CompletedAppraisalFileRef = ref(
            {} as Vue & { removeFile: (file: UppyFileResponse) => void } & { resetFiles: () => void },
        )
        const appraisalFileData = ref({} as ValuationResponse)
        const isPdf = ref(false)

        const url = computed(() => {
            if (!order.value) return
            return 'order/' + order.value.id
        })
        const stepText = computed(() => {
            if (!order.value) return
            switch (order.value.status) {
                case 'Complete':
                    return 'Complete Appraisal'
                default:
                    return order.value.status
            }
        })
        const filePath = ref('')
        const appraisalFilePath = ref('')
        const ENVFilePath = ref('')

        const xmlRequired = computed(() => {
            if (!AscentHelper.customerFeatureEnabled('orderScreen.workflow.completed.requireXml', true)) return false
            const mainProduct = orderProducts.value.find((elem) => elem.form.type === 'Main Product')
            if (!mainProduct || !mainProduct.form?.detail?.details) return true
            return !!mainProduct.form?.detail?.details?.xml
        })

        const setAppraisalFilePath = (filePath: string) => {
            appraisalFilePath.value = filePath
        }

        const removeXml = () => {
            appraisalFilePath.value = ''
        }
        const removeZap = () => {
            filePath.value = ''
        }
        const deleteFile = () => {
            error.value = ''
            CompletedAppraisalFileRef.value.resetFiles()
        }
        const addFile = (file: File) => {
            filePath.value = file.path
        }
        const addAppraisalFile = (file: File) => {
            appraisalFilePath.value = file.path
        }
        const addENVFile = (file: File) => {
            ENVFilePath.value = file.path
        }

        const ScheduleSecondInspectionDialog = ref({} as Dialog)
        const openSecondInspection = () => {
            ScheduleSecondInspectionDialog.value.open()
        }

        const disableSubmit = computed(() => {
            if (loading.value) return true
            if (order.value?.incremental?.propertyType === 'Commercial' && order.value?.status !== 'QC Revision')
                return false
            if (order.value?.incremental?.propertyType === 'Commercial') return !appraisalFilePath.value
            return !filePath.value || !appraisalFilePath.value
        })

        const loadFile = async (file: UppyFileResponse) => {
            isPdf.value = false
            error.value = ''
            if (!file.extension) {
                error.value = 'This file does not have an extension, please upload another file.'
                return
            }
            if (file.extension.toLowerCase() !== 'xml') {
                isPdf.value = true
                return
            }
            const text = await file.data.text().then((result: string) => {
                return result
            })
            appraisalFileData.value = JSON.parse(JSON.stringify(convert.xml2js(text, { compact: true })))
        }

        const submit = () => {
            if (!appraisalFilePath.value && order.value?.incremental?.propertyType !== 'Commercial') return
            error.value = ''
            const submitData: OfficeCompleteFiles = {
                appraisal: appraisalFilePath.value,
            }
            if (ENVFilePath.value) submitData['env'] = ENVFilePath.value
            if (
                order.value?.incremental?.propertyType === 'Residential' &&
                AscentHelper.featureEnabled('orderScreen.office.appraisalBackup', true)
            )
                submitData['zap'] = filePath.value
            if (order.value?.incremental?.propertyType === 'Commercial') submitData['isCommercial'] = true

            $axios
                .post('/v1/order/' + order.value?.id + '/action/office-complete', submitData)
                .then((response) => {
                    loading.value = false
                    if (response.data.result === 'fail') {
                        error.value = response.data.message
                        return
                    }
                    refreshOrder()
                    showSnackbar('Appraisal Completed Successfully')
                })
                .catch((error) => {
                    if (error.response.data.errors.length > 0) {
                        showError(error.response.data.errors[0])
                    }
                    loading.value = false
                })
        }

        const complete = () => {
            error.value = ''
            if (!order.value) return
            if (!appraisalFileData.value && order.value?.incremental?.propertyType !== 'Commercial') {
                error.value = 'You must submit the appraisal.'
                return
            }

            if (order.value?.incremental?.propertyType === 'Commercial') {
                isPdf.value = true
            }

            loading.value = true

            if (!isPdf.value) {
                const result = AscentHelper.checkXml(appraisalFileData.value)

                if (result.error) {
                    error.value = result.message
                }
                const checkXmlBeforeSubmit = get(enabledCustomerFeatures.value, 'checkXmlBeforeSubmit', 'soft')

                if (checkXmlBeforeSubmit === 'hard' && error.value) {
                    showError('Unable to submit, please correct errors')
                    loading.value = false
                    return
                }
                if (checkXmlBeforeSubmit === 'soft' && error.value) {
                    confirm('Please verify the report is accurate', error.value, { confirm: 'Submit' }).then(
                        (confirm) => {
                            if (confirm) {
                                submit()
                            }
                        },
                    )
                    loading.value = false
                    return
                }
            }

            submit()
            loading.value = false
        }

        return {
            loading,
            error,
            actionLoading,
            modal,
            url,
            stepText,
            complete,
            submit,
            ScheduleSecondInspectionDialog,
            openSecondInspection,
            user,
            order,
            returnAppraiser,
            addFile,
            addAppraisalFile,
            appraisalFilePath,
            filePath,
            addENVFile,
            xmlRequired,
            disableSubmit,
            removeZap,
            removeXml,
            CompletedAppraisalFileRef,
            loadFile,
            deleteFile,
            setAppraisalFilePath,
        }
    },
})
