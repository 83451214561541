import { computed, Ref, ref } from '@vue/composition-api'
import { ProductForm } from '@/types'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'

export const orderProducts = ref([] as ProductForm[])

export const mainProduct: Ref<undefined | ProductForm> = computed(() => {
    return orderProducts.value.find((elem) => elem.formType === 'Main Product')
})

export const getProducts = async () => {
    if (!order.value) return []
    $axios.get(`/v1/order/${order.value.id}/products`).then(({ data }) => {
        orderProducts.value = data
    })
}
