











































































import { defineComponent, watch, ref } from '@vue/composition-api'
import { preloadData } from '@/components/OrderScreens/Office/OrderComponents/Workflows/Preload/PreloadData'

export default defineComponent({
    props: {
        editData: {
            type: Boolean,
            default: false,
        },
        cancelEdit: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const area = ref('')
        const dimensions = ref('')
        const shape = ref('')

        const areaCalc = () => {
            if (!preloadData.value.plat_map.LotSizeAcres) return ''
            preloadData.value.plat_map.LotSizeSquareFeet = ((preloadData.value.plat_map.LotSizeAcres as number) *
                43560) as number
            if (preloadData.value.plat_map.LotSizeAcres < 1) {
                return (preloadData.value.plat_map.LotSizeSquareFeet as number).toFixed(2) + ' sq ft'
            }
            return preloadData.value.plat_map.LotSizeAcres + ' acre(s)'
        }

        watch(props, () => {
            if (!props.editData && !props.cancelEdit) {
                preloadData.value.plat_map.LotSizeAcres = area.value
                preloadData.value.plat_map.MajorDimensionsString = dimensions.value
                preloadData.value.plat_map.LotShape = shape.value
            }
            if (props.editData) {
                area.value = preloadData.value.plat_map.LotSizeAcres as string
                dimensions.value = preloadData.value.plat_map.MajorDimensionsString
                shape.value = preloadData.value.plat_map.LotShape
            }
        })

        return {
            preloadData,
            area,
            dimensions,
            shape,
            areaCalc,
        }
    },
})
