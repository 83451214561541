

















import axios from 'axios'

import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {},
    data() {
        return {
            loading: false,
            search: null,
            addOns: [],
            items: [],
        }
    },
    mounted() {
        this.searchAddOn()
    },
    methods: {
        searchAddOn() {
            this.loading = true
            axios.post('/v1/search', { type: 'add_on', object: true }).then((response) => {
                this.addOns = response.data
                this.loading = false
            })
        },
    },
})
