<template>
    <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-icon class="mr-2" small>$vuetify.icons.plus</v-icon>
                <v-list-item-subtitle>Add Fee</v-list-item-subtitle>
            </v-list-item>
        </template>

        <ValidationObserver ref="form" v-slot="{ invalid }">
            <ACard title="Add Product">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <a-select-field
                                v-model="line.form"
                                rules="required"
                                label="Product"
                                :items="fees"
                                name="product"
                                color="secondary"
                                item-value="id"
                                item-text="name"
                                return-object
                                @change="updateFee"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" v-if="!usesTotalFees">
                            <a-text-field
                                v-model="line.appraiser_value"
                                :rules="'required'"
                                label="Appraiser Fee"
                                name="appraiser fee"
                                color="secondary"
                                prepend-inner-icon="$vuetify.icons.dollarSign"
                                outlined
                            />
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" sm="5" v-if="!usesTotalFees">
                            <a-text-field
                                v-model="line.amc_value"
                                :rules="'required'"
                                label="Our Fee"
                                name="our fee"
                                color="secondary"
                                prepend-inner-icon="$vuetify.icons.dollarSign"
                                outlined
                            />
                        </v-col>
                        <v-col cols="12" v-if="usesTotalFees">
                            <a-text-field
                                v-model="line.appraiser_value"
                                :rules="'required'"
                                label="Total Fee"
                                name="total fee"
                                color="secondary"
                                prepend-inner-icon="$vuetify.icons.dollarSign"
                                outlined
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <a-btn-cancel @click="dialog = false" />
                    <v-btn color="primary" :loading="saving" :disabled="invalid" @click="addLine">Add Fee</v-btn>
                </v-card-actions>
            </ACard>
        </ValidationObserver>
    </v-dialog>
</template>

<script lang="js">
import {ValidationObserver} from 'vee-validate'
import {order} from "@/plugins/order/Order";
import AscentHelper from "@/helpers/ascent-helper"

export default {
    components: {
        ValidationObserver
    },
    props: {
        fees: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            dialog: false,
            saving: false,
            line: {
                form: null,
                amc_value: null,
                appraiser_value: null
            },
            type: null,
        }
    },
    computed: {
        usesTotalFees: () => {
            return AscentHelper.customerFeatureEnabled('orderScreen.invoice.feeSummaryType') === 'total_fees'
        },
    },
    methods: {
        open() {
            this.dialog = true
            this.line = {
                id: null,
                order_id: null,
                product_id: null,
                product_name: null,
                amc_value: null,
                appraiser_value: null
            }
        },
        updateFee(value) {
            if (!order.value) return
            this.$axios.get('/v1/order/' + order.value.id + '/product/' + value.id).then((response) => {
                if (!response.data.quote) {
                    this.line.amc_value = response.data.amc
                    this.line.appraiser_value = response.data.appraiser
                }

            })
        },
        addLine() {
            if(!this.line.appraiser_value) this.line.appraiser_value = 0
            if(!this.line.amc_value) this.line.amc_value = 0
            this.$emit('fee-added', this.line)
            this.dialog = false
        }
    }
}
</script>
