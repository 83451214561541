




























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { Channel } from 'laravel-echo/dist/channel'
import { handleConnection } from '@/plugins/Channels'
import { redirectTo, user } from '@/plugins/User'
import { order, closeOrder } from '@/plugins/order/Order'
import { updateOrders } from '@/components/OrderTable/Classes/OrderTable'

const overlay = ref(false)

export const subscribeChangeLogin = (connector: Channel) => {
    connector.listen('.ChangedLogin', (event: { type: string }) => {
        if (event.type === 'login') {
            overlay.value = true
            return
        }

        if (event.type === 'logout') {
            overlay.value = false
        }
    })
}

export const subscribeChangeTenancy = (connector: Channel) => {
    connector.listen('.SwitchTenancy', (event: { user_id: number; user_type: string }) => {
        if (localStorage.getItem('change-tenancy-originator')) {
            localStorage.removeItem('change-tenancy-originator')
            return
        }
        redirectTo(event.user_type)
    })
}

export default defineComponent({
    setup(_, { root }) {
        const appraiserOverlay = ref(false)

        const closeAppraiserOrder = () => {
            closeOrder()
            appraiserOverlay.value = false
        }

        onMounted(() => {
            if (user.value && user.value.type == 'appraiser') {
                handleConnection({
                    channelRef: 'notifications',
                    id: user.value?.id as number,
                }).then((connector) => {
                    connector.listen('.AppraiserAssignedToOrder', (event: { orderId: number }) => {
                        if (!order.value && root.$route.name == 'Appraiser Dashboard') {
                            updateOrders()
                            return
                        }
                        if (!order.value) return
                        if (order.value.id == event.orderId) appraiserOverlay.value = true
                    })
                })
            }
        })

        return { overlay, appraiserOverlay, user, closeAppraiserOrder }
    },
    props: {},
})
