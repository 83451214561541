import { ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { Tag } from '@/types'
import { user } from '@/plugins/User'

export const tags = ref([] as Tag[])
export const appraiserTags = ref([] as string[])

export const getTags = async (id: number) => {
    return await $axios.get('/v1/order/' + id + '/tag').then((response) => {
        if (!response) return
        tags.value = response.data.filter((tag: Tag) => {
            if (tag.showOnOrder === undefined) return true
            if (!tag.showOnOrder) return false
            if (user.value?.type === 'client' && tag.showToClient === true) return true
            if (user.value?.type === 'appraiser' && tag.showToAppraiser === true) return true
            return user.value?.type === 'amc'
        })
        return
    })
}

export const getAppraiserTags = async (appraiser_id: null | number): Promise<string[]> => {
    if (appraiser_id === undefined || appraiser_id === null) return []
    appraiserTags.value = await $axios.get('/v1/appraiser/' + appraiser_id + '/options/tags').then((res) => {
        if (res.data.option_value === undefined) return []
        return res.data.option_value
    })

    return appraiserTags.value
}
