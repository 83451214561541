



















import { defineComponent } from '@vue/composition-api'
import OrderToolbar from '@/AppLayout/order/OrderToolbar.vue'
import { order } from '@/plugins/order/Order'
import SnapshotOrderDetails from '@/components/OrderScreens/Snapshot/SnapshotOrderDetails.vue'
import SnapshotOrderFiles from '@/components/OrderScreens/Snapshot/SnapshotOrderFiles.vue'
import SnapshotReviewAction from '@/components/OrderScreens/Snapshot/SnapshotReviewAction.vue'

export default defineComponent({
    components: {
        OrderToolbar,
        SnapshotOrderDetails,
        SnapshotOrderFiles,
        SnapshotReviewAction,
    },
    setup() {
        return {
            order,
        }
    },
})
