var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-app-bar',{staticClass:"mt-5",attrs:{"flat":"","color":"white","dense":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-toolbar-title',{staticClass:"title pl-0 text-h4",attrs:{"color":"accentBack"}},[_vm._v(" "+_vm._s(_vm.order.status)+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"error"},on:{"click":_vm.returnAppraiser}},[_vm._v("Return to Appraiser")])],1)],1)],1),_c('v-banner',{staticClass:"mt-4 ml-4 mr-4",attrs:{"color":"accentWhitedarken1"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
                    _vm.$ascent.canDo('Manual QC') ||
                    (!_vm.order.active_quality &&
                        _vm.reviewers !== null &&
                        _vm.reviewers.find(function (elem) { return elem.id === _vm.user.id; }) !== undefined)
                )?_c('v-btn',{attrs:{"target":"_blank","href":'/quality/order/' + _vm.order.id,"color":"primary","outlined":""}},[_vm._v(" Open in Quality Tool "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary","small":""}},[_vm._v("$vuetify.icons.externalLinkSquare")])],1):_vm._e(),(
                    _vm.$ascent.featureEnabled('orderScreen.actions.showRovPostCompletion') &&
                    _vm.rovLink === false &&
                    _vm.order.status != 'Reconsideration Review'
                )?_c('v-btn',{staticClass:"mx-2",staticStyle:{"font-weight":"bold"},attrs:{"href":_vm.reconsiderationURL,"target":"_blank","small":"","depressed":"","color":"primary","outlined":""}},[_vm._v(" Start Reconsideration of Value ")]):_vm._e()]},proxy:true}])},[_vm._v(" The order has been uploaded and is ready for review. Open the Quality Tool to review the order. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }