var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order && _vm.order.incremental)?_c('ACard',{attrs:{"min-height":"400"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"accentWhite","dense":""}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("$vuetify.icon.ballotCheck")]),_vm._v(" Snapshot Review ")],1),_c('v-spacer')],1)]},proxy:true}],null,false,1858292286)},[_c('v-card-text',[_c('v-row',[(
                    _vm.order.status === 'Open' ||
                    (_vm.order.status === 'Processing' &&
                        _vm.order.incremental.user &&
                        _vm.order.incremental.user.id === _vm.user.id)
                )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"info","text":""}},[_vm._v("Snapshot Review Incomplete")]),_c('v-btn',{attrs:{"block":"","color":"primary","target":"_blank","href":'/snapshot-review/order/' + _vm.order.id}},[_vm._v(" Complete Review ")])],1):_vm._e(),(
                    _vm.order.status === 'Processing' && _vm.order.incremental.user && _vm.order.incremental.user.id !== _vm.user.id
                )?_c('v-col',[_c('v-alert',{attrs:{"type":"info","text":""}},[_vm._v("Snapshot is currently being worked on by another user.")])],1):_vm._e(),(_vm.order.status === 'Completed')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"info","text":""}},[_vm._v("Snapshot Review Completed")])],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }