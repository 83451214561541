































import { defineComponent } from '@vue/composition-api'
import { order, orderTotalPayments, orderTotalDue } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        return {
            order,
            orderTotalPayments,
            orderTotalDue,
        }
    },
})
