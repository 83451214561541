
































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order } from '@/plugins/order/Order'
import { EntityRole, RawEntity } from '@/types'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import axios from '@/plugins/axios'
import { getEntities } from '@/components/OrderScreens/Customer/CustomerOrderComponents/CustomerOrderEntities/Entities'

export default defineComponent({
    components: {
        ValidationObserver,
    },
    setup() {
        const addEntityDialog = ref<boolean>(false)
        const entities = ref<RawEntity[]>([])
        const loadingEntities = ref(false)
        const loadingRoles = ref(false)
        const loadingMessage = ref('Loading entities...')
        const roles = ref<EntityRole[]>([])
        const selectedEntity = ref<RawEntity | null>(null)
        const selectedRole = ref<EntityRole | null>(null)

        const close = () => {
            addEntityDialog.value = false
            entities.value = []
            loadingEntities.value = false
            loadingRoles.value = false
            loadingMessage.value = 'Loading entities...'
            roles.value = []
            selectedEntity.value = null
            selectedRole.value = null
        }

        const filteredEntities = computed(() => {
            if (!order.value || !order.value.incremental || !selectedRole.value) {
                return []
            }

            return entities.value.filter(
                (entity: RawEntity) => selectedRole.value && entity.roles.includes(selectedRole.value.id),
            )
        })

        const getEntitiesAndRoles = () => {
            if (!order.value || !order.value.id) return

            loadingEntities.value = true
            loadingRoles.value = true

            axios
                .get('/v1/customer/' + order.value.customer_id + '/entities')
                .then((response) => {
                    entities.value = response.data
                    loadingEntities.value = false
                })
                .catch(() => {
                    loadingEntities.value = false
                })

            axios
                .get('/v1/customer/' + order.value.customer_id + '/entity-roles?system=true')
                .then((response) => {
                    roles.value = response.data
                    loadingRoles.value = false
                })
                .catch(() => {
                    loadingRoles.value = false
                })
        }

        const submit = () => {
            if (!order.value || !order.value.id || !selectedEntity.value || !selectedRole.value) return

            loadingEntities.value = true

            axios
                .post('/v1/order/' + order.value.id + '/entities', {
                    id: selectedEntity.value.uuid,
                    role: selectedRole.value.id,
                })
                .then(() => {
                    const name = selectedRole.value?.name ?? 'An entity'
                    showSnackbar(name + ' was added to this order.')
                    getEntities()
                    close()
                })
                .catch(() => {
                    loadingEntities.value = false
                    showError('An error occurred. Please try again.')
                })
        }

        watch(
            () => addEntityDialog.value,
            () => {
                if (addEntityDialog.value) {
                    getEntitiesAndRoles()
                }
            },
        )

        return {
            addEntityDialog,
            close,
            entities,
            filteredEntities,
            getEntitiesAndRoles,
            loadingEntities,
            loadingRoles,
            loadingMessage,
            roles,
            selectedEntity,
            selectedRole,
            submit,
        }
    },
})
