




































import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import ACardAction from '@/global/ACardAction.vue'
import AEditor from '@/global/Form/AEditor.vue'

const dialog = ref(false)
const instructions = ref('')

import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { loadIncremental, order } from '@/plugins/order/Order'

export const openEditInstructions = (): void => {
    dialog.value = true

    if (order.value?.incremental?.instruction) {
        if (!order.value) return
        if (!order.value.incremental) return
        instructions.value = order.value.incremental.instruction.instructions
    }
}

export default defineComponent({
    components: { AEditor, ACardAction, ValidationObserver },
    setup() {
        const updateInstructions = () => {
            showSnackbar('Order Instructions Successfully Updated')
            dialog.value = false
            loadIncremental()
            instructions.value = ''
        }

        return {
            dialog,
            instructions,
            updateInstructions,
            order,
        }
    },
})
