

















import { defineComponent } from '@vue/composition-api'

/**
 * @name CLientApproval
 * Not currently in use
 */

export default defineComponent({})
