






















import { computed, defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import { user } from '@/plugins/User'
import AscentHelper from '@/helpers/ascent-helper'
import axios from '@/plugins/axios'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    name: 'CustomerOrderWatcher',

    setup() {
        const showWatchButton = computed(() => {
            if (!order.value) return false
            if (!user.value) return false
            if (user.value.ascent) return true
            if (!AscentHelper.isCustomer()) return false
            if (!AscentHelper.customerFeatureEnabled('orderScreen.allowOrderWatching', true)) return false
            if (!order.value.user_id && order.value.assigner_id === user.value.id) return false
            if (order.value.user_id === user.value.id) return false
            return true
        })

        const isWatchingOrder = computed(() => {
            if (!order.value) return false
            if (!user.value || !user.value.watching) return false
            if (!showWatchButton.value) return false
            return user.value.watching.includes(order.value.id)
        })

        const toggleWatchOrder = () => {
            if (!order.value) return
            if (!user.value) return

            const action = isWatchingOrder.value ? 'stop' : 'start'
            const orderId = order.value.id

            axios
                .post('/v1/user/' + user.value.id + '/watch-order', {
                    action: action,
                    order_id: orderId,
                })
                .then(({ data }) => {
                    showSnackbar(data.message)

                    if (!user.value) return

                    if (action === 'start') {
                        user.value.watching.push(orderId)
                    } else {
                        const watching = user.value.watching.filter((el) => el !== orderId)
                        user.value.watching = watching
                    }
                })
                .catch((error) => {
                    const message = error.response?.data?.message ?? error
                    showError(message)
                })
        }

        return {
            isWatchingOrder,
            showWatchButton,
            toggleWatchOrder,
        }
    },
})
