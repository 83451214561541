


















































































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import AppraiserSelect from '@/components/General/AutoCompletes/AppraiserSelect.vue'
import { Appraiser, RoleSetting } from '@/types'
import { sendToAssignDialogAction } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignAppraiser.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { user } from '@/plugins/User'
import { loadIncremental, order, refreshOrder, updateOrder } from '@/plugins/order/Order'
import axios from 'axios'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import { GET_APPRAISER_FEE } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AmcSelect from '@/components/General/AutoCompletes/AmcSelect.vue'
export const overrideDialog = ref(false)

export default defineComponent({
    components: {
        AmcSelect,
        AppraiserSelect,
    },
    props: {
        appraiserDue: {
            type: String,
            default: '',
            required: false,
        },
        clientDue: {
            type: String,
            default: '',
            required: false,
        },
        appraiserRoles: {
            type: Array,
            default: () => [],
            required: false,
        },
    },
    setup(props) {
        const selectedAppraiser = ref({} as Appraiser)
        const selectedRole = ref({} as RoleSetting)
        const appraiserFee = ref(undefined as undefined | number)
        const reassignmentType = ref('az')
        const loading = ref(false)

        const reset = () => {
            selectedRole.value = {} as RoleSetting
            appraiserFee.value = 0
            selectedAppraiser.value = {} as Appraiser
            loading.value = false
        }

        const isPrimaryAndAscentIntegratedOrder = computed(() => {
            if (!order.value || !order.value?.incremental?.integration) return false
            if (!selectedRole.value.role) return false
            return order.value?.incremental?.integration.type === 'Ascent' && selectedRole.value.role === 'Primary'
        })

        const officeDisabled = computed(() => {
            if (!order.value) return true
            if (!selectedRole.value.role) return true
            if (selectedRole.value.role === 'Primary' && !isPrimaryAndAscentIntegratedOrder) return true
            return !selectedAppraiser.value.id
        })
        const cancel = () => {
            reset()
            overrideDialog.value = false
        }
        const redirect = () => {
            if (!selectedAppraiser.value.id) return
            confirm(
                'Override Appraisal Panel Logic?',
                'Are you sure you want to override the panel logic to place ' +
                    selectedAppraiser.value.name +
                    ' on this order? <br> ' +
                    'This bypasses all checks of license, insurance, and eligibility.',
            ).then((confirm) => {
                if (confirm) {
                    sendToAssignDialogAction(selectedAppraiser.value, props.appraiserDue, props.clientDue)
                }
            })
        }

        const overrideOfficePrimary = () => {
            if (!selectedAppraiser.value.id) return
            confirm(
                'Override Appraisal Panel Logic?',
                'Are you sure you want to override the panel logic to place ' +
                    selectedAppraiser.value.name +
                    ' on this order? <br> ' +
                    'This bypasses all checks of license, insurance, and eligibility.',
            ).then(async (confirm) => {
                if (confirm) {
                    if (!order.value) return
                    if (!user.value) return
                    appraiserFee.value = await GET_APPRAISER_FEE('full', selectedAppraiser.value.id)
                    await axios
                        .post('/v1/order/' + order.value.id + '/action/office-allocate-order', {
                            roles: [
                                {
                                    role: 'Primary',
                                    appraiser_id: selectedAppraiser.value.id,
                                    fee: appraiserFee.value,
                                },
                            ],
                        })
                        .then(() => {
                            if (!order.value) return
                            loadIncremental()
                            order.value.appraiser_id = selectedAppraiser.value.id
                            order.value.appraiserName = selectedAppraiser.value.name
                            updateOrder()
                            showSnackbar('Appraiser Assigned Successfully!')
                            overrideDialog.value = false
                        })
                }
            })
        }

        const officeAssignAppraiser = () => {
            if (!selectedAppraiser.value.id) return
            confirm(
                'Override Appraisal Panel Logic?',
                'Are you sure you want to override the panel logic to place ' +
                    selectedAppraiser.value.name +
                    ' on this order? <br> ' +
                    'This bypasses all checks of license, insurance, and eligibility.',
            ).then((confirm) => {
                if (confirm) {
                    if (!order.value) return
                    if (!user.value) return
                    loading.value = true
                    axios
                        .patch('/v1/order/' + order.value.id + '/action/appraiser-office-reassign', {
                            roles: [
                                {
                                    role: selectedRole.value.role,
                                    appraiser_id: selectedAppraiser.value.id,
                                    fee: appraiserFee.value,
                                    appraiser: selectedAppraiser.value,
                                    required: selectedRole.value.required,
                                },
                            ],
                            note: selectedRole.value.role + ' role has been manually overridden by ' + user.value.name,
                            type: reassignmentType.value,
                        })
                        .then(() => {
                            reset()
                            showSnackbar('Appraiser override submitted successfully')
                            refreshOrder()
                            loading.value = false
                            overrideDialog.value = false
                        })
                        .catch((error) => {
                            showError(error)
                        })
                }
            })
        }

        const officeAllocatePrimary = () => {
            if (!order.value) return
            if (!order.value.appraiser_id) return
            if (!order.value.appraiserName) return
            selectedAppraiser.value.id = order.value.appraiser_id
            selectedAppraiser.value.name = order.value.appraiserName

            overrideOfficePrimary()
        }

        return {
            selectedAppraiser,
            overrideDialog,
            sendToAssignDialogAction,
            redirect,
            cancel,
            user,
            selectedRole,
            appraiserFee,
            officeDisabled,
            order,
            isPrimaryAndAscentIntegratedOrder,
            officeAssignAppraiser,
            reassignmentType,
            reset,
            loading,
            overrideOfficePrimary,
            officeAllocatePrimary,
        }
    },
})
