











































































































































































































































import BranchSelect from '@/components/General/AutoCompletes/BranchSelect.vue'
import { Lender, Client } from '@/types'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import ClientProfileAutoCc from '../../ClientProfile/Partials/ClientProfileAutoCc.vue'
import { mask } from 'vue-the-mask'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { user } from '@/plugins/User'
import { clientPermissions, clientRoles, clientTypes, masks, phoneTypes } from '@/plugins/Data'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'

function initialData() {
    return {
        loading: false,
        making: false,
    }
}

const initialClient: Client = {
    id: 0,
    status: '',
    type: '',
    branch_id: 0,
    broker: false,
    name: '',
    email: '',
    phones: [],
    lender: undefined,
    permissions: [],
    role: [],
    branches: [],
}

const client = ref(initialClient)

interface BrokerBranch {
    lenders: number[]
    origLenders: number[]
    settingsReady: boolean
}

const dialog = ref(false)
const userDisabled = ref(false)
const brokerBranch = ref({
    lenders: [],
    origLenders: [],
    settingsReady: false,
} as BrokerBranch)

const handleBrokerBranchChange = (): void => {
    brokerBranch.value = {
        lenders: [],
        origLenders: [],
        settingsReady: false,
    }

    axios.get('/v1/branch/' + client.value.branch_id).then((response) => {
        if (!response.data.wholesale_lenders) return
        response.data.wholesale_lenders.map((lender: Lender) => {
            brokerBranch.value.lenders.push(lender.id)
        })
        brokerBranch.value.origLenders = JSON.parse(JSON.stringify(brokerBranch.value.lenders))
    })
}

export const open = (selectedClient: Client) => {
    axios.get('/v1/client/' + selectedClient.id).then((response) => {
        client.value = {
            id: response.data.id,
            status: response.data.status,
            type: response.data.type,
            branch_id: response.data.branch_id,
            broker: response.data.broker,
            name: response.data.name,
            email: response.data.email,
            phones: response.data.phones,
            lender: response.data.branch.lender_id,
            branches: response.data.branches,
            user: response.data.user,
            permissions: response.data.permissions,
            role: response.data.role,
        }

        if (response.data.user == null) {
            userDisabled.value = true
        } else {
            userDisabled.value = false
        }

        if (response.data.broker) {
            handleBrokerBranchChange()
        }
        dialog.value = true
    })
}

export default defineComponent({
    setup() {
        const currentClientPermissions = ref(client.value?.permissions)
        watch(currentClientPermissions, () => {
            if (!client.value) return
            client.value.permissions = currentClientPermissions.value
        })
        watch(client, () => {
            currentClientPermissions.value = client.value.permissions
        })
        return {
            client,
            open,
            dialog,
            userDisabled,
            brokerBranch,
            user,
            phoneTypes,
            clientTypes,
            clientRoles,
            clientPermissions,
            currentClientPermissions,
        }
    },
    directives: { mask },
    components: { BranchSelect, ValidationObserver, ClientProfileAutoCc },
    data() {
        return initialData()
    },
    methods: {
        phoneMask(phone: string): string {
            if (phone === 'Office') return masks.phoneExt
            return masks.phone
        },
        saveChanges() {
            this.$axios
                .patch('/v1/client/' + client.value.id, {
                    client: client.value,
                })
                .then(() => {
                    this.saveBrokerBranchChanges().finally(() => {
                        dialog.value = false
                        client.value = initialClient
                        showSnackbar('User saved successfully')
                        this.$emit('user-updated')
                    })
                })
        },
        makeUser() {
            this.making = true
            this.$axios
                .post('/v1/client/' + client.value.id + '/action/make-user', {
                    client: client.value,
                })
                .then(() => {
                    this.open(client.value)
                    showSnackbar('User Created successfully')
                    this.making = false
                    this.$emit('user-updated')
                })
                .catch((err) => {
                    this.making = false
                    if (err.toString().includes('422')) return showError('Error - Please provide an email.')
                    showError('Error - Unable to add user.')
                })
        },
        async saveBrokerBranchChanges() {
            return new Promise((resolve) => {
                if (client.value.broker && this.brokerBranchChanged()) {
                    this.$axios
                        .patch('/v1/branch/' + client.value.branch_id, {
                            branch: [],
                            lenders: brokerBranch.value.lenders,
                        })
                        .then(() => {
                            this.$emit('branch-updated')
                            resolve(true)
                        })
                        .catch(() => {
                            resolve(false)
                        })
                } else {
                    this.$emit('branch-unchanged')
                    resolve(true)
                }
            })
        },
        brokerBranchChanged() {
            if (brokerBranch.value.lenders.length !== brokerBranch.value.origLenders.length) {
                return true
            }

            let changed = false
            brokerBranch.value.lenders.forEach((lender: number) => {
                if (!brokerBranch.value.origLenders.includes(lender)) {
                    changed = true
                }
            })

            return changed
        },
        closeDialog() {
            dialog.value = false
            client.value = initialClient
        },
    },
})
