


































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'
import { order } from '@/plugins/order/Order'

function reset() {
    return {
        dialog: false,
        saving: false,
        reason: '',
    }
}

/**
 * @name ScheduleIssue
 * Dialog for appraiser to report a scheduling issue they are having with the order
 */

export default defineComponent({
    components: {
        ValidationObserver,
    },
    setup() {
        return { order }
    },
    data() {
        return reset()
    },
    methods: {
        open() {
            this.dialog = true
        },
        /**
         * Order is posted to '/v1/order/' + order.id + '/workflow/schedule-issue' with the reason. After notes are refreshed
         */
        save() {
            if (!order.value) return
            Object.assign(this.$data, reset())
            getNotes(order.value.id)
            showSnackbar('Scheduling Issue Reported.')
        },
    },
})
