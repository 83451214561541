






















import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import { uniqBy } from 'lodash'

export default defineComponent({
    props: {
        filterValues: {
            type: Array as PropType<{ id: string; title: string }[]>,
            required: true,
        },
        value: {
            type: Object as PropType<{ id: string; title: string }>,
        },
        name: {
            type: String as PropType<string>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const filterValue = ref<{ id: string; title: string }>(props.value || { id: '', title: `${props.name}: All` })
        const filterItems = computed(() => [
            { id: '', title: `${props.name}: All` },
            ...uniqBy(props.filterValues, (v) => v.id),
        ])

        const onUserFilter = (value: { id: string; title: string }) => {
            filterValue.value = value
            emit('input', value)
        }

        return {
            filterItems,
            filterValue,
            onUserFilter,
        }
    },
})
