













































































































































import AscentHelper from '@/helpers/ascent-helper'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { EmailTemplate } from '@/types/emailTemplates'
import AEditor from '@/global/Form/AEditor.vue'

export const email = ref({
    templateText: {
        body: '',
        subject: '',
        to: '',
        to_emails: '',
        to_other: '',
        introLines: '',
        outroLines: '',
    },
    name: '',
    notification: '',
    markdown: '',
} as EmailTemplate)

export default defineComponent({
    components: {
        AEditor,
    },
    emits: ['click:close', 'click:save', 'input'],
    props: {
        editSubject: {
            type: Boolean,
            default: false,
        },
        editTitle: {
            type: Boolean,
            default: false,
        },
        editTo: {
            type: Boolean,
            default: false,
        },
        entity: {
            type: String,
            default: 'order',
        },
        openOnMount: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        validate: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showExtraSelection() {
            return ['custom', 'other'].includes(email.value.templateText.to || '')
        },
    },
    data() {
        return {
            loading: false,
            selectedMergeVariable: null,
            mergeVariables: {
                order: [
                    { name: 'Order Id', value: '{order.customer_order_id}' },
                    {
                        name: 'Inspection Deadline',
                        value: '@inspectionTimezone',
                    },
                    {
                        name: 'Appraiser Deadline',
                        value: '{order.appraiser_deadline}',
                    },
                    {
                        name: 'Borrower Last Name',
                        value: '{order.borrower_last}',
                    },
                    { name: 'FHA Number', value: '{order.fha}' },
                    { name: 'Lender Name', value: '{order.lender.name}' },
                    {
                        name: 'Loan Number',
                        value: '{order.lender_loan_number}',
                    },
                    {
                        name: 'Subject Property Address',
                        value: '{order.address.full_address}',
                    },
                    { name: 'Client Name', value: '{order.client.name}' },
                    { name: 'Vendor Name', value: '{order.appraiser.name}' },
                    { name: 'Vendor Email', value: '{order.appraiser.email}' },
                    { name: 'Vendor Company', value: '{order.appraiser.company}' },
                ],
                appraiser: [
                    { name: 'Vendor Name', value: '{appraiser.name}' },
                    { name: 'Vendor Email', value: '{appraiser.email}' },
                    { name: 'Vendor Company', value: '{appraiser.company}' },
                ],
            },
            recipients: {
                order: [
                    { name: 'Borrower(s)', value: 'borrowers' },
                    { name: 'Entry Contact(s)', value: 'entry' },
                    { name: 'Custom', value: 'custom' },
                    { name: 'Other', value: 'other' },
                ],
                invite: [
                    { name: 'Recipient', value: 'recipient' },
                    { name: 'Custom', value: 'custom' },
                ],
                appraiser: [
                    { name: 'Recipient', value: 'recipient' },
                    { name: 'Custom', value: 'custom' },
                ],
            },
            insertReady: false,
        }
    },
    setup() {
        const otherRecipientTypes = { rep: 'CSS', assigner: 'Assigner' }
        const otherRecipients = computed(() => {
            return Object.entries(otherRecipientTypes).map(([key, value]) => ({
                text: AscentHelper.translate('lang.' + key, value),
                value: key,
            }))
        })
        const showLocal = ref(false)

        return {
            showLocal,
            email,
            otherRecipients,
        }
    },
    methods: {
        closeEditor() {
            this.$emit('click:close')
            this.showLocal = false
            email.value = {
                templateText: {
                    body: '',
                    subject: '',
                    to: '',
                    to_emails: '',
                    to_other: '',
                    introLines: '',
                    outroLines: '',
                },
                name: '',
                notification: '',
                markdown: '',
            } as EmailTemplate
        },
        emailUpdated() {
            this.loading = true
            if (email.value.templateText.to !== 'other') {
                email.value.templateText.to_other = ''
            }
            this.$emit('click:save', email.value)
            this.$emit('input', email.value)
            this.showLocal = false
            this.loading = false
            email.value = {
                templateText: {
                    body: '',
                    subject: '',
                    to: '',
                    to_emails: '',
                    to_other: '',
                    introLines: '',
                    outroLines: '',
                },
                name: '',
                notification: '',
                markdown: '',
            } as EmailTemplate
        },
        insertMergeVariable() {
            email.value.templateText.body += ' ' + this.selectedMergeVariable
            email.value.templateText.body = this.stripTags(email.value.templateText.body)
        },
        stripTags(value: string) {
            const div = document.createElement('div')
            div.innerHTML = value
            return div.textContent || div.innerText || ''
        },
        validateEmail() {
            if (this.$props.validate) {
                email.value.valid = true

                if (this.$props.editTitle && email.value.name === '') {
                    email.value.valid = false
                    return
                }

                if (this.$props.editSubject && email.value.templateText.subject === '') {
                    email.value.valid = false
                    return
                }

                if (this.$props.editTo) {
                    if (email.value.templateText.to === '') {
                        email.value.valid = false
                        return
                    }

                    if (email.value.templateText.to === 'custom' && email.value.templateText.to_emails === '') {
                        email.value.valid = false
                        return
                    }
                }

                if (email.value.templateText.body === '') {
                    email.value.valid = false
                    return
                }
            }
        },
    },

    mounted() {
        if (this.$props.openOnMount) {
            this.showLocal = true
        }

        watch(
            () => this.$props.show,
            (isShowing) => {
                this.showLocal = this.$props.show
                if (isShowing) {
                    if (this.$props.value && Object.keys(this.$props.value).length > 0) {
                        email.value = JSON.parse(JSON.stringify(this.$props.value))
                    }
                    this.$emit('input', email.value)
                }
            },
        )

        watch(
            () => this.showLocal,
            (isShowing) => {
                if (!isShowing) {
                    this.$emit('click:close')
                }
            },
        )
    },
})
