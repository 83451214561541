















































import { computed, defineComponent, onMounted } from '@vue/composition-api'
import { getOrderCommercialData, orderCommercial } from '@/plugins/order/Order'

export default defineComponent({
    setup() {
        const approachToValue = computed(() => {
            if (!orderCommercial.value) return ''
            if (!orderCommercial.value.approachToValue.length) return 'No Approach to Value Selected'
            return orderCommercial.value.approachToValue.join(', ')
        })

        const valueScenarios = computed(() => {
            if (!orderCommercial.value) return ''
            if (!orderCommercial.value.valueScenarios.length) return 'No Value Scenarios Selected'
            return orderCommercial.value.valueScenarios.join(', ')
        })

        onMounted(() => {
            getOrderCommercialData()
        })
        return {
            approachToValue,
            valueScenarios,
            orderCommercial,
        }
    },
})
