












import { defineComponent, PropType } from '@vue/composition-api'
import { AppraiserPanel } from '@/types'
import { appraiserStatus, appraiserStatusColor } from '@/pages/Appraiser/Appraiser'

/**
 * @name AppraiserStatusPartial
 * Partial in AssignTable that shows that current status of the appraiser
 *
 * @SetupMethod appraiserStatusColor - takes in status and appraiser status and outputs the amc status of the appraiser
 * @SetupMethod appraiserStatus - takes in status and amc status and outputs the correct status of appraiser
 */

export default defineComponent({
    props: {
        item: {
            type: Object as PropType<AppraiserPanel>,
        },
    },
    setup() {
        return {
            appraiserStatus,
            appraiserStatusColor,
        }
    },
})
