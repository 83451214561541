




























import { branch } from '@/pages/AMC/Lenders/Branches/Partials/BranchEdit.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
        return { branch }
    },
})
