




























import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import { user } from '@/plugins/User'

interface Settings {
    message: string
    color: string
    mode: string
    x: string
    y: string
}
export const state = reactive({
    message: 'null',
    color: 'success',
    show: false,
    mode: '',
    x: 'right',
    y: 'bottom',
    downloading: false,
})
export const location = ref()
export const updateLocation = () => {
    location.value = localStorage.getItem('snackbarLocation')
    if (!location.value || location.value === 'Bottom Right') {
        location.value = 'Bottom Right'
        state.x = 'right'
        state.y = 'bottom'
    }
    if (location.value === 'Bottom Left') {
        state.x = 'left'
        state.y = 'bottom'
    }
    if (location.value === 'Top Left') {
        state.x = 'left'
        state.y = 'top'
    }
    if (location.value === 'Top Right') {
        state.x = 'right'
        state.y = 'top'
    }
}
export const showSnackbar = (message: string, color = 'success') => {
    state.message = message
    state.color = color
    state.show = true
}
export const showCustomSnackbar = (settings: Settings) => {
    state.message = settings.message
    state.color = settings.color
    state.mode = settings.mode
    state.x = settings.x
    state.y = settings.y
    state.show = true
}
export const showError = (message: string, location: Record<string, string> | undefined = undefined) => {
    state.message = 'We could not process state request'
    if (message != '' && message) state.message = message
    state.color = 'error'
    if (location) {
        state.x = location.x
        state.y = location.y
    }
    state.show = true
    setTimeout(() => updateLocation(), 10000)
}
export const refreshPage = () => {
    window.location.reload()
}
export const showDownload = () => {
    state.downloading = true
}
export const hideDownload = () => {
    state.downloading = false
}

export default defineComponent({
    setup() {
        onMounted(() => {
            updateLocation()
        })

        return {
            user,
            state,
            showSnackbar,
            refreshPage,
            showCustomSnackbar,
            showError,
            showDownload,
            hideDownload,
        }
    },
})
