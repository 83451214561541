





































































import { defineComponent } from '@vue/composition-api'
import { formTriggers } from '@/pages/AMC/Tools/CustomerActions/Data/Form'

export default defineComponent({
    emits: ['click:delete', 'click:select'],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        entity: {
            type: String,
            default: '',
        },
        list: {
            type: Array,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const deleteItem = (item: object) => {
            if (!props.disabled) {
                emit('click:delete', item)
            }
        }

        const selectItem = (item: object) => {
            if (!props.disabled) {
                emit('click:select', item)
            }
        }

        const isIcon = (text: string): boolean => {
            const pattern = new RegExp(/(\$[a-zA-Z0-9.]*)/g)
            return pattern.test(text)
        }

        const shouldEmphasize = (text: string): boolean => {
            return text !== 'When' && text !== 'then'
        }

        const getEventNameById = (itemId: string): string => {
            const foundItem = formTriggers.value.find((event) => event.id === itemId)
            return foundItem ? foundItem.name : ''
        }

        return {
            deleteItem,
            selectItem,
            isIcon,
            shouldEmphasize,
            formTriggers,
            getEventNameById,
        }
    },
})
