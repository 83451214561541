





























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import ACardAction from '@/global/ACardAction.vue'
import { ValidationObserver } from 'vee-validate'
import { getEscalationTags } from '@/pages/Shared/CustomerSettings/CompanySettings/TagsAndEscaltions/EscalationTags/EscalationTags'
import { order, updateOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { Tag } from '@/types'

const dialog = ref(false)
export const openOrderEscalation = () => {
    dialog.value = true
}

export default defineComponent({
    components: { ACardAction, ValidationObserver },
    setup() {
        const escalationReason = ref('')
        const escalationTag = ref(0)
        const escalationTags = ref([] as Tag[])

        const orderEscalated = () => {
            showSnackbar('Order escalated successfully!')
            escalationReason.value = ''
            escalationTag.value = 0
            updateOrder()
            dialog.value = false
        }

        onMounted(async () => {
            if (!order.value) return
            const tags: Tag[] = (await getEscalationTags()) as Tag[]
            escalationTags.value = tags.filter((tag: Tag) => {
                if (!order.value) return
                return (tag.order_statuses as string[]).indexOf(order.value.orderStatus) > -1
            })
        })

        return {
            dialog,
            escalationTags,
            escalationReason,
            escalationTag,
            order,
            orderEscalated,
        }
    },
})
