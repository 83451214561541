import { DashboardFilter } from '@/types/Dashboard'
import { ref, watch } from '@vue/composition-api'
import { GridComponent } from '@syncfusion/ej2-vue-grids'
import { DataResult } from '@syncfusion/ej2-data/src/adaptors'
import columns, { version } from '@/components/OrderTable/columns/ColumnDefinitions'
import { user } from '@/plugins/User'
import { Appraiser, OrderModel, OrderSearchParams } from '@/types'
import setAvailableColumns from '@/components/OrderTable/Classes/SetAvailableColumns'
import detailTemplate from '@/components/OrderTable/DetailTemplates/DetailTemplateLogic'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
export const loading = ref(false)
import axios from '@/plugins/axios'
export const AdvSearchArray = ref({} as { [x: string]: string[] })
export const AdvSearchData = ref({} as { [x: string]: number })
export const orderStartDate = ref('')
export const orderEndDate = ref('')
export const completedStartDate = ref('')
export const completedEndDate = ref('')
export const ordersLoaded = ref(true)
export const expanded = ref([0, 1, 2] as number[])
export const dataSourceSearch = ref({
    count: 0,
    result: [],
} as DataResult)
import { lastUpdateVar } from '@/pages/AMC/Settings/AdvancedSearch.vue'
import { searchDropDown } from '@/plugins/order/Order'
import { AscentApi, AscentColumnModel, AscentGrid, FixedActionArgs } from '@/helpers/AscentDataAdapter'
import $axios from '@/plugins/axios'
import { ActionEventArgs, DataStateChangeEventArgs, PredicateModel } from '@syncfusion/ej2-grids'
import { getColumnFilters } from '@/components/OrderTable/Classes/OrderFilters'
import { tagItems } from '@/plugins/Data'
import { filteredTagItem } from '@/plugins/Data'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getOrder } from '@/plugins/order/Order'
import { tableTags } from '@/components/General/SharedDialogs/CustomerTags/CustomerTagConfig'
import {
    filterConditions,
    selectedFilterSubFilters,
    filterConditionsDetails,
} from '@/components/OrderTable/Classes/TableFilters'
import { currentDashboard } from '@/components/General/Dashboard/Dashboards'
import AscentHelper from '@/helpers/ascent-helper'
import _ from 'lodash'

export const clearingTable = ref(false)

interface RowEvent {
    name: string
    data: OrderModel
}

interface SelectedEvent {
    name: string
    isInteracted: boolean
}

interface ViewFilterConditionData {
    originalKey: string
    viewFilterKey: FilterList
    conditionVal: string[]
}

export const searchItems = ['All', 'ID', 'Loan #']
export const clientSearchItems = ['Loan #', 'All']
export const searchType = ref('All')
export const searchText = ref('')
export const grid = ref({} as GridComponent)
export const tableColumns = ref(columns as AscentColumnModel[])
export const defaultVisibleTableColumns = ref(
    tableColumns.value.filter((val) => val.visible).map((val) => val.field) as string[],
)
export const OrderQuery = new AscentApi('/v1/order-table')
export const OrderSearchQuery = new AscentApi('v1/order-search')
export const filteredColumns = ref([] as string[])
export const orderFilter = ref('' as string | string[])
export const selected = ref([] as OrderModel[])
export const selectionEnabled = ref(false)

export const name = ref('Order Table')

export const initialFilter = ref([])

export const viewFilters = ref({
    state: [] as string[],
    lender_id: [] as number[] | string[],
    propertyType: [] as string[],
    appraiserAccess: [] as number[] | string[],
    customViewFilters: [] as DashboardFilter[],
})

declare type FilterList = 'state' | 'lender_id' | 'propertyType' | 'appraiserAccess' | 'customViewFilters'

export const resetViewFilters = () => {
    viewFilters.value = {
        state: ['All States'],
        lender_id: [-1],
        propertyType: ['All Types'],
        appraiserAccess: [-1],
        customViewFilters: [],
    }
    const savedFilterValue = localStorage.getItem(`view-filters-${user.value?.id}_${user.value?.customer_id}`)

    if (savedFilterValue) {
        const oldFilters = JSON.parse(savedFilterValue)
        Object.keys(oldFilters).forEach((filter) => {
            viewFilters.value[filter as FilterList] = oldFilters[filter]
        })
    }

    if (currentDashboard.value && currentDashboard.value['view-filter'])
        viewFilters.value = { ...viewFilters.value, ...currentDashboard.value['view-filter'] }
    if (currentDashboard.value && currentDashboard.value['viewFilters'])
        viewFilters.value.customViewFilters = currentDashboard.value['viewFilters']
}

export const viewTeam = ref([-1] as number[])
export const viewUser = ref([-1] as number[])

export const rowSelection = (args: RowEvent) => {
    if (args.name === 'rowDeselected') {
        selected.value.splice(
            selected.value.findIndex((elem) => elem.id === args.data.id),
            1,
        )
    }
    if (args.name === 'rowSelected') {
        selected.value.push(args.data)
    }
}

export const saveTable = () => {
    if (!grid.value.ej2Instances) return
    const columns = grid.value.ej2Instances.getPersistData()
    localStorage.setItem(name.value, JSON.stringify(JSON.parse(columns).columns))
    setAvailableColumns()
    localStorage.setItem('table-version', version)
    showSnackbar('Table Saved Successfully')
}

export const toggleVisibleColumns = (visibleColumns: string[]) => {
    tableColumns.value = tableColumns.value.map((column) => {
        if (column.visible) return column
        column.visible = visibleColumns.includes(column.field as string)
        return column
    })
}

export const toggleOffVisibleColumns = (visibleColumns: string[]) => {
    tableColumns.value = tableColumns.value.map((column) => {
        if (visibleColumns.includes(column.field as string)) {
            column.visible = false
        }
        return column
    })
}

export const dataSource = ref({
    count: 0,
    result: [],
} as DataResult)

const userSettingsSort = (): boolean => {
    if (!user.value) return false
    if (user.value.type === 'client') return false
    if (user.value.type === 'appraiser') return false
    return true
}

export const tableSettings = ref({
    detailTemplate: detailTemplate,
    detailDataBound: (args) => {
        if (args && args.detailElement) {
            args.detailElement.addEventListener('contextmenu', function () {
                if (args.detailElement?.parentNode?.previousSibling) {
                    const rowIndex = args.detailElement.parentNode.previousSibling['rowIndex' as keyof object]
                    const selectedRows = grid.value.ej2Instances.getSelectedRowIndexes()
                    if (!selectedRows.includes(rowIndex)) {
                        grid.value.ej2Instances.selectRow(rowIndex)
                    }
                }
            })
        }
    },
    isResponsive: true,
    allowFiltering: true,
    allowReordering: true,
    allowSorting: true,
    allowSelection: true,
    allowMultiSorting: true,
    allowEditing: false,
    showColumnChooser: true,
    pageSettings: { pageSizes: [50, 100, 250, 500], pageSize: 50 },
    allowPaging: true,
    allowResizing: true,
    allowTextWrap: true,
    allowExcelExport: true,
    selectionSettings: {
        type: 'Multiple',
        enableToggle: true,
    },
    sortSettings: {},
    filterSettings: {
        type: 'Menu',
        operators: {
            dateOperator: [
                { value: 'equal', text: 'Equal' },
                { value: 'greaterthan', text: 'Greater Than' },
                { value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
                { value: 'lessthan', text: 'Less Than' },
                { value: 'lessthanorequal', text: 'Less Than Or Equal' },
                { value: 'notequal', text: 'Not Equal' },
                { value: 'between', text: 'Between' },
            ],
        },
        columns: initialFilter.value,
    },
    rowDataBound: (args) => {
        if (args === undefined) return
        if (args.row === undefined) return
        if (args.data !== undefined && (args.data as OrderModel).priority === 'Rush') {
            const td = args?.row.querySelector('td') as HTMLTableDataCellElement
            td.classList.add('colorRush')
        }

        if (
            [
                'QC Unread',
                'Unread',
                'Unread Messages',
                'Normal Messages',
                'Critical Messages',
                'Normal Unread',
                'Critical Comments',
                'Reminders',
                'Upcoming',
                'Upcoming Reminders',
                'ROV Unread',
                'Assigner Reminders',
                'Assigner Unread',
                'Assigner Critical Unread',
                'Assigner Normal Unread',
                'CSS Unread',
                'CSS Critical Comments',
                'CSS Normal Unread',
                'Past Reminders',
            ].indexOf(orderFilter.value as string) === -1 ||
            (searchText.value !== null && searchText.value.length > 0)
        ) {
            const div = args?.row.querySelector('.e-detailrowcollapse div') as HTMLDivElement
            div.classList.add('hide-selector')
            const td = args?.row.querySelector('.e-detailrowcollapse') as HTMLTableDataCellElement
            td.classList.add('disable-selector')
        } else {
            const div = args?.row.querySelector('.e-detailrowcollapse div') as HTMLDivElement
            div.classList.remove('hide-selector')
            const td = args?.row.querySelector('.e-detailrowcollapse') as HTMLTableDataCellElement
            td.classList.remove('disable-selector')
        }
    },
    contextMenuItems: [
        {
            text: 'Add Tag',
            target: '.e-gridcontent',
            id: 'addTag',
            items: tableTags?.value ?? [],
        },
        { text: 'Open Order', target: '.e-gridcontent', id: 'openOrder' },
    ],
    contextMenuOpen: (args) => {
        if (!args?.items) return
        args.items[0].items = tableTags.value
    },
    contextMenuClick: (args) => {
        if (args?.item && args?.item.id === 'openOrder') {
            getOrder(grid.value.ej2Instances.getSelectedRecords()[0])
            return
        }
        if (args?.item && args?.item.id !== 'addTag') {
            const order = grid.value.ej2Instances.getSelectedRecords()[0]
            $axios
                .post(
                    '/v1/order/' + order.id + '/tag',
                    tagItems.find((elem) => elem.title === args.item.id),
                )
                .then(({ data }) => {
                    if (order.tags === null) {
                        order.tags = []
                    }
                    order.tags.push(data)
                    grid.value.ej2Instances.setRowData(order.id, order)
                    //ctx.root.$showSnackbar('Tag added successfully');
                })
        }
    },
} as AscentGrid)

export const updateSearchTable = () => {
    if (grid.value.ej2Instances) grid.value.ej2Instances.pageSettings.currentPage = 1
    if (clearingTable.value) return
    ordersLoaded.value = false
    if (searchDropDown.value !== false && userSettingsSort()) {
        const searchParams: OrderSearchParams = {
            filter: orderFilter.value,
            viewUser: viewUser.value,
            viewFilters: viewFilters.value,
            searchType: searchType.value,
            filterConditions: filterConditions.value,
            subFilters: selectedFilterSubFilters.value,
            fixedFilters: currentDashboard.value?.fixedFilters,
            filterConditionsDetails: filterConditionsDetails.value,
        }

        if (user.value && user.value.teams) {
            searchParams.viewTeam = viewTeam.value
            searchParams.teams = user.value.teams
        }

        OrderQuery.setParams(searchParams)
            .search(searchText.value)
            .update()
            .then((data) => {
                if (data === undefined) return
                return (dataSource.value = data)
            })
    }
    if (searchDropDown.value === false) {
        const searchParams: OrderSearchParams = {
            facetFilters: Object.values(AdvSearchArray.value),
            q: searchText.value,
            orderStartDate: orderStartDate.value,
            orderEndDate: orderEndDate.value,
            completedStartDate: completedStartDate.value,
            completedEndDate: completedEndDate.value,
        }

        if (user.value && user.value.teams) {
            if (AscentHelper.customerFeatureEnabled('teamAllocation.strictMode', false)) {
                searchParams.viewTeam = viewTeam.value
            }
            searchParams.teams = user.value.teams
        }

        OrderSearchQuery.setParams(searchParams)
            .search(searchText.value)
            .update()
            .then((response) => {
                ordersLoaded.value = true
                if (response === undefined) return
                if (lastUpdateVar.value.length > 0) {
                    for (const key in response.facetsDistribution) {
                        if (key === lastUpdateVar.value) {
                            Object.keys(response.facetsDistribution).forEach((newKey) => {
                                if (key !== newKey) {
                                    AdvSearchData.value[newKey] = (
                                        response.facetsDistribution as { [x: string]: number }
                                    )[newKey]
                                }
                            })
                        }
                    }
                }
                if (lastUpdateVar.value.length === 0 || lastUpdateVar.value === 'appraiser_id') {
                    AdvSearchData.value = response.facetsDistribution as {}
                }
                dataSourceSearch.value = {
                    result: response.hits,
                    count: response.estimatedTotalHits,
                }
                dataSource.value = {
                    result: response.hits,
                    count: response.estimatedTotalHits,
                }

                ordersLoaded.value = true
            })
    }
}

export const orderSearchFilter = () => {
    orderFilter.value = 'Active'
}

export const updateOrders = (where?: PredicateModel[]) => {
    if (clearingTable.value) return

    if (AscentHelper.customerFeatureEnabled('viewByUser', true)) {
        const savedValue = localStorage.getItem(`user-select-${user.value?.id}_${user.value?.customer_id}`)
        if (savedValue) viewUser.value = JSON.parse(savedValue)
    }

    if (AscentHelper.customerFeatureEnabled('assignType') === 'team') {
        const savedTeamValue = localStorage.getItem(`team-select-${user.value?.id}_${user.value?.customer_id}`)
        if (savedTeamValue) viewTeam.value = JSON.parse(savedTeamValue)
    }

    resetViewFilters()

    if (viewTeam.value == []) (viewTeam.value as number[]) = [-1]
    if (viewUser.value == []) (viewUser.value as number[]) = [-1]
    if (
        [
            'QC Unread',
            'Unread',
            'Normal Unread',
            'Critical Comments',
            'CSS Unread',
            'CSS Critical Comments',
            'CSS Normal Unread',
            'Assigner Unread',
            'Assigner Critical Unread',
            'Assigner Normal Unread',
            'Unread Notes',
            'ROV Unread',
        ].indexOf(orderFilter.value as string) > -1 &&
        (searchText.value === null || searchText.value.length === 0)
    ) {
        toggleVisibleColumns(['last_note'])
        const lastNoteSetting: 'Ascending' | 'Descending' | 'None' | null = localStorage.getItem(
            'user_' + user.value?.id + '_sort_last_note',
        ) as 'Ascending' | 'Descending' | 'None' | null
        if (lastNoteSetting && lastNoteSetting !== 'None') {
            tableSettings.value.sortSettings = { columns: [{ field: 'last_note', direction: lastNoteSetting }] }
        }
        if (!lastNoteSetting) {
            localStorage.setItem('user_' + user.value?.id + '_sort_last_note', 'Descending')
            tableSettings.value.sortSettings = { columns: [{ field: 'last_note', direction: 'Descending' }] }
        }
    }
    if (
        (grid.value !== undefined &&
            grid.value.ej2Instances !== undefined &&
            [
                'QC Unread',
                'Unread',
                'Normal Unread',
                'Critical Comments',
                'CSS Unread',
                'CSS Critical Comments',
                'CSS Normal Unread',
                'Assigner Unread',
                'Assigner Critical Unread',
                'Assigner Normal Unread',
                'Unread Notes',
                'ROV Unread',
            ].indexOf(orderFilter.value as string) < 0) ||
        (searchText.value !== null && searchText.value.length > 0)
    ) {
        //grid.value.ej2Instances.clearSorting()
        toggleOffVisibleColumns(['last_note'])
    }
    orderFilter.value === 'QC Review'
        ? toggleVisibleColumns(['qc_priority', 'qc_type', 'qc_assignee'])
        : toggleOffVisibleColumns(['qc_priority', 'qc_type', 'qc_assignee'])

    if (user.value && user.value.type === 'client' && searchType.value === 'All') {
        searchDropDown.value = false
        updateSearchTable()

        return
    }

    const searchParams: OrderSearchParams = {
        filter: orderFilter.value,
        viewUser: viewUser.value,
        viewFilters: viewFilters.value,
        where: where,
        searchType: searchType.value,
        filterConditions: filterConditions.value,
        dashboard: currentDashboard.value,
        subFilters: selectedFilterSubFilters.value,
        fixedFilters: currentDashboard.value?.fixedFilters,
        filterConditionsDetails: filterConditionsDetails.value,
    }

    if (user.value && user.value.teams) {
        searchParams.viewTeam = viewTeam.value
        searchParams.teams = user.value.teams
    }

    OrderQuery.setParams(searchParams)
        .search(searchText.value)
        .update()
        .then((data) => {
            if (data === undefined) return
            dataSource.value = data
        })

    if (orderFilter.value !== 'QC Review') {
        filteredTagItem.value = tagItems.filter((elem) => elem.visible[0] === 'All')
    }
    if (orderFilter.value === 'QC Review') {
        filteredTagItem.value = tagItems
    }
}

export const actionBegin = async (args: ActionEventArgs) => {
    if (args && args.columns && args?.action === 'filter') {
        filteredColumns.value = []
        args.columns.forEach((elem) => filteredColumns.value.push(elem.field as string))
    }
}

export const actionHandling = async (args: FixedActionArgs) => {
    if (clearingTable.value) return
    if (!args) return

    if (args && args.action && args.action.requestType === 'sorting') {
        if (args.action.columnName && args.action.columnName === 'last_note' && args.action.direction) {
            localStorage.setItem('user_' + user.value?.id + '_sort_last_note', args.action.direction)
        }
        if (!args.action.columnName && args.action.target && args.action.target.innerText === 'Last Note') {
            localStorage.setItem('user_' + user.value?.id + '_sort_last_note', 'None')
        }
    }

    if (
        (args && args.action && args.action.requestType === 'filterchoicerequest') ||
        args.action.requestType === 'filtersearchbegin'
    ) {
        const filters = await getColumnFilters(args.action.filterModel.options.field, args.action.query)
        args.dataSource(filters)
        return
    }
    if (Object.values(AdvSearchArray.value).length > 0) return

    const searchParams: OrderSearchParams = {
        filter: orderFilter.value,
        viewUser: viewUser.value,
        viewFilters: viewFilters.value,
        filterConditions: filterConditions.value,
        subFilters: selectedFilterSubFilters.value,
        fixedFilters: currentDashboard.value?.fixedFilters,
        filterConditionsDetails: filterConditionsDetails.value,
    }

    if (searchText.value && searchText.value !== '') {
        searchParams.search = searchText.value
        searchParams.searchType = searchType.value
    }

    if (user.value && user.value.teams) {
        searchParams.viewTeam = viewTeam.value
        searchParams.teams = user.value.teams
    }

    OrderQuery.setParams(searchParams)
        .execute(args as DataStateChangeEventArgs)
        .then((orderData) => {
            if (orderData === undefined) return
            dataSource.value = orderData
        })
}

export const actionHandlingOrderSearch = async (args: FixedActionArgs) => {
    if (!args) return
    if (args.action.requestType === 'filterchoicerequest' || args.action.requestType === 'filtersearchbegin') {
        const filters = await getColumnFilters(args.action.filterModel.options.field, args.action.query)
        args.dataSource(filters)
        return
    }

    const searchParams: OrderSearchParams = {
        facetFilters: Object.values(AdvSearchArray.value),
        q: searchText.value,
        orderStartDate: orderStartDate.value,
        orderEndDate: orderEndDate.value,
        completedStartDate: completedStartDate.value,
        completedEndDate: completedEndDate.value,
    }

    if (user.value && user.value.teams) {
        if (AscentHelper.customerFeatureEnabled('teamAllocation.strictMode', false)) {
            searchParams.viewTeam = viewTeam.value
        }
        searchParams.teams = user.value.teams
    }

    OrderSearchQuery.setParams(searchParams)
        .execute(args as DataStateChangeEventArgs)
        .then((response) => {
            if (response === undefined) return
            if (lastUpdateVar.value.length === 0) {
                AdvSearchData.value = response.facetsDistribution as {}
            }
            dataSourceSearch.value = {
                result: response.hits,
                count: response.estimatedTotalHits,
            }
            dataSource.value = {
                result: response.hits,
                count: response.estimatedTotalHits,
            }
        })
}

interface OrderSearch {
    [key: string]: string | undefined
}

export const clearTable = () => {
    clearingTable.value = true
    let filter = ['Accept', 'Schedule', 'Waiting Inspection', 'Complete'] as string | string[]
    if (user.value && user.value.type === 'client') {
        filter = orderFilter.value
    }

    const searchParams: OrderSearchParams = {
        filter: filter,
        viewUser: viewUser.value,
        viewFilters: viewFilters.value,
        searchType: '',
        search: '',
        filterConditions: filterConditions.value,
        subFilters: selectedFilterSubFilters.value,
        fixedFilters: currentDashboard.value?.fixedFilters,
        filterConditionsDetails: filterConditionsDetails.value,
    }

    if (user.value && user.value.teams) {
        searchParams.viewTeam = viewTeam.value
        searchParams.teams = user.value.teams
    }

    OrderQuery.setParams(searchParams)
        .update()
        .then((data) => {
            clearingTable.value = false
            if (data === undefined) return
            return (dataSource.value = data)
        })
}

export const saveTableFilters = (searchName: string) => {
    if (!user.value) return
    axios.post('v1/user/' + user.value.id + '/search/' + searchName).then(() => {
        showSnackbar('Saved Filters')
    })
}

watch(orderFilter, (newVal, oldVal) => {
    if (oldVal === '') return
    updateOrders()
})

watch(searchType, () => {
    if (searchText.value !== '' && searchText.value !== null) {
        updateSearchTable()
    }
    localStorage.setItem('searchType', searchType.value)
})

export const lastUpdatedAppraiserVar = ref('')
export const lastUpdatedDirectoryVar = ref('')
export const AppraiserSearchText = ref('')
export const DirectorySearchText = ref('')
export const expandedAppraiserSearch = ref([0] as number[])
export const expandedDirectorySearch = ref([0] as number[])
export const AdvSearchDataAppraiser = ref({} as { [x: string]: number })
export const AdvSearchDataDirectory = ref({} as { [x: string]: number })
export const registerStartDate = ref('')
export const registerEndDate = ref('')
export const registerDirectoryStartDate = ref('')
export const registerDirectoryEndDate = ref('')
export const dataSourceAppraiser = ref({
    count: 0,
    result: [],
} as DataResult)
export const dataSourceDirectory = ref({
    count: 0,
    result: [],
} as DataResult)
export const AdvSearchAppraiserObj = ref({} as { [x: string]: string[] })
export const AdvSearchDirectoryObj = ref({} as { [x: string]: string[] })
export const AppraiserSearchQuery = new AscentApi('v1/appraiser/search')
export const DirectorySearchQuery = new AscentApi('v1/appraiser-directory-list')
export const appraisersLoaded = ref(true)
interface AppraiserSearch {
    [key: string]: string | undefined
}

export const updateAppraiserTable = () => {
    appraisersLoaded.value = false
    AppraiserSearchQuery.setParams({
        facetFilters: Object.values(AdvSearchAppraiserObj.value),
        q: AppraiserSearchText.value,
        registerStartDate: registerStartDate.value,
        registerEndDate: registerEndDate.value,
    })
        .search(searchText.value)
        .update()
        .then((response) => {
            if (response === undefined) return
            if (lastUpdatedAppraiserVar.value && lastUpdatedAppraiserVar.value.length > 0) {
                for (const key in response.facetsDistribution) {
                    if (key === lastUpdatedAppraiserVar.value) {
                        Object.keys(response.facetsDistribution).forEach((newKey) => {
                            if (key !== newKey) {
                                AdvSearchDataAppraiser.value[newKey] = (
                                    response.facetsDistribution as { [x: string]: number }
                                )[newKey]
                            }
                        })
                    }
                }
            }
            if (!lastUpdatedAppraiserVar.value || lastUpdatedAppraiserVar.value.length === 0) {
                AdvSearchDataAppraiser.value = response.facetsDistribution as {}
            }
            dataSourceAppraiser.value = {
                result: response.hits,
                count: response.estimatedTotalHits,
            }
            appraisersLoaded.value = true
        })
}
export const arrSelected = ref([] as Appraiser[])

export const handleSelected = (event: SelectedEvent) => {
    if (!event.isInteracted && event.name === 'rowDeselected') return
    const items = dataSourceDirectory.value.result as Appraiser[]
    arrSelected.value = arrSelected.value.filter((vendor) => {
        return !items.some((item) => item.id === vendor.id)
    })
    arrSelected.value.push(...(grid.value.getSelectedRecords() as Appraiser[]))
    arrSelected.value = _.uniqBy(arrSelected.value, (vendor) => {
        return vendor.id
    })
}

export const setRowSelected = () => {
    const exist = (dataSourceDirectory.value.result as Appraiser[])
        .map((vendor, index) => {
            if (arrSelected.value.some((item) => item.id === vendor.id)) {
                return index
            }
        })
        .filter((item) => item !== undefined)
    if (grid.value.ej2Instances) grid.value.selectRows(exist as number[])
}
export const updateAppraiserDirectoryTable = () => {
    appraisersLoaded.value = false

    DirectorySearchQuery.setParams({
        facetFilters: Object.values(AdvSearchDirectoryObj.value),
        q: DirectorySearchText.value,
        registerStartDate: registerDirectoryStartDate.value,
        registerEndDate: registerDirectoryEndDate.value,
    })
        .search(searchText.value)
        .update()
        .then((response) => {
            if (response === undefined) return
            if (lastUpdatedDirectoryVar.value.length > 0) {
                for (const key in response.facetsDistribution) {
                    if (key === lastUpdatedDirectoryVar.value) {
                        Object.keys(response.facetsDistribution).forEach((newKey) => {
                            if (key !== newKey) {
                                AdvSearchDataDirectory.value[newKey] = (
                                    response.facetsDistribution as { [x: string]: number }
                                )[newKey]
                            }
                        })
                    }
                }
            }
            if (lastUpdatedDirectoryVar.value.length === 0) {
                AdvSearchDataDirectory.value = response.facetsDistribution as {}
            }
            dataSourceDirectory.value = {
                result: response.hits,
                count: response.estimatedTotalHits,
            }
            appraisersLoaded.value = true
        })
}

export const actionHandlingAppraiserSearch = async (args: FixedActionArgs) => {
    if (!args) return

    if (args.action.requestType === 'filterchoicerequest' || args.action.requestType === 'filtersearchbegin') {
        const filters = await getColumnFilters(args.action.filterModel.options.field, args.action.query)
        args.dataSource(filters)
        return
    }

    AppraiserSearchQuery.setParams({
        facetFilters: Object.values(AdvSearchAppraiserObj.value),
        q: AppraiserSearchText.value,
        registerStartDate: registerStartDate.value,
        registerEndDate: registerEndDate.value,
    })
        .execute(args as DataStateChangeEventArgs)
        .then((response) => {
            if (response === undefined) return
            dataSourceAppraiser.value = {
                result: response.hits,
                count: response.estimatedTotalHits,
            }
        })
}

export const actionHandlingDirectorySearch = async (args: FixedActionArgs) => {
    if (!args) return
    if (args.action.requestType === 'filterchoicerequest' || args.action.requestType === 'filtersearchbegin') {
        const filters = await getColumnFilters(args.action.filterModel.options.field, args.action.query)
        args.dataSource(filters)
        return
    }

    DirectorySearchQuery.setParams({
        facetFilters: Object.values(AdvSearchDirectoryObj.value),
        q: DirectorySearchText.value,
        registerStartDate: registerDirectoryStartDate.value,
        registerEndDate: registerDirectoryEndDate.value,
    })
        .execute(args as DataStateChangeEventArgs)
        .then((response) => {
            if (response === undefined) return
            dataSourceDirectory.value = {
                result: response.hits,
                count: response.estimatedTotalHits,
            }
        })
}

export const changeUserType = (userType: string) => {
    if (!userType) return
    $axios
        .post('v1/user/change-usertype', {
            type: userType,
        })
        .then((res) => {
            window.location.href = process.env.VUE_APP_BASE_URL + '/' + userType + '/dashboard'
            user.value = res.data.user
        })
        .catch((err) => {
            throw Error('Error: ' + err)
        })
}

export const inviteMultiVendor = () => {
    if (!arrSelected.value.length) {
        showError(`There is no vendor selected to invite.`)
        return
    }
    confirm(
        `Are your sure you want to invite these vendors?`,
        `- ${arrSelected.value.map((vendor) => vendor.name).join('<br/>- ')}`,
    ).then((confirm) => {
        if (confirm) {
            appraisersLoaded.value = false
            axios
                .post(`/v1/appraiser-directory/invite`, { vendors: arrSelected.value.map((vendor) => vendor.id) })
                .then(() => {
                    arrSelected.value.length = 0
                    grid.value.selectRows([])
                    showSnackbar(`Invite successfully!`)
                })
                .catch(({ response }) => {
                    showError(response.data.errors.is_public_directory[0])
                })
                .finally(() => {
                    updateAppraiserDirectoryTable()
                })
        }
    })
}

export interface OrderMeliSearch {
    facetFilters: string[]
    q: string
    orderStartDate: string
    orderEndDate: string
    completedStartDate: string
    completedEndDate: string
    searchName?: string
    searchText: string
    0?: number
}
