




































import { defineComponent } from '@vue/composition-api'
import { stateOption } from '@/components/OrderTable/Classes/TableFilters'
import { viewFilters } from '@/components/OrderTable/Classes/OrderTable'

/**
 * @name LocationSelectFilter
 * Dialog on Order Table that allows user to select different states to see their orders on the Order Table
 *
 */

export default defineComponent({
    setup() {
        const resetSelection = () => {
            viewFilters.value.state = ['All States']
        }

        const selectAllWhenNoneSelected = (state: string) => {
            if (viewFilters.value.state.length === 0 && state !== 'All States') viewFilters.value.state = ['All States']
            if (state === 'All States') viewFilters.value.state = []
        }

        return {
            resetSelection,
            selectAllWhenNoneSelected,
            stateOption,
            viewFilters,
        }
    },
})
