import { computed, ref } from '@vue/composition-api'
import { orderFilter, updateOrders } from '@/components/OrderTable/Classes/OrderTable'
import { component } from '@/components/General/Dashboard/Dashboards'

export const view = ref('Table')

export const allowedProcess = computed(() => {
    return !(
        orderFilter.value === 'QC Escalated' ||
        orderFilter.value === 'QC Unreviewed' ||
        orderFilter.value === 'QC Payment Hold' ||
        orderFilter.value === 'Follow Up CSS' ||
        orderFilter.value === 'Follow Up AMC'
    )
})

export const updateSelection = (value: string): void => {
    view.value = value
    if (view.value === 'Process') {
        component.value = orderFilter.value + 'Process'
    }
    updateOrders()
}
