



















































































































































import { lender } from '@/pages/Client/Client'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { user as userLocal } from '@/plugins/User'
import canDo from '@/store/Helpers/CanDo.ts'
import { mini } from './LeftNav.vue'
import { orderSearchFilter } from '@/components/OrderTable/Classes/OrderTable'
import $axios from '@/plugins/axios'

/**
 * @name 'Client Nav'
 * Component description: The Navigation for the client side.
 * Sets the routes, icons, and titles of each navigation.
 */
export default defineComponent({
    setup() {
        const user = computed(() => userLocal.value)
        const snapshotReview = ref(false)

        onMounted(() => {
            if (!user.value) return (snapshotReview.value = true)
            if (!user.value.client) return (snapshotReview.value = true)
            if (!user.value.client.branch) return (snapshotReview.value = true)
            if (!user.value.client.branch.lender_id) return (snapshotReview.value = true)
            $axios
                .get('v1/lender/' + user.value.client.branch.lender_id + '/option/snapshot-review')
                .then((response) => {
                    snapshotReview.value = response.data.option_value
                })
        })

        return {
            lender,
            user,
            canDo,
            mini,
            orderSearchFilter,
            snapshotReview,
        }
    },
})
