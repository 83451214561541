import { ref } from '@vue/composition-api'
import { Client, Branch, Lender } from '@/types'

export interface GlobalClient {
    client: Client
    branch: Branch
}

export const globalClient = ref({} as GlobalClient)
export const branch = ref({} as Branch)
export const lender = ref({} as Lender)

export const setClient = (client: GlobalClient) => {
    if (client) globalClient.value = client
    if (client.branch !== undefined) branch.value = client.branch
    if (client?.branch?.lender !== undefined) lender.value = client.branch.lender
}
