




import { defineComponent, PropType } from '@vue/composition-api'
import IntegrationWithCompanyID from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderIntegration/LenderIntegrationPartials/IntegrationWithCompanyID.vue'
import Integration from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderSublenders/Integration.vue'
import { LenderOptions, SublenderOptions } from '@/types'

export default defineComponent({
    components: { IntegrationWithCompanyID, Integration },
    props: {
        option: {
            type: Object as PropType<LenderOptions | SublenderOptions | undefined>,
        },
        componentName: {
            type: String,
            default: 'integration-with-company-i-d',
        },
    },
})
