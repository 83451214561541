































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { showError } from '@/AppLayout/helpers/snackbar.vue'

export default defineComponent({
    setup() {
        const accessLogs = ref([])
        const error = ref(false)

        onMounted(() => {
            if (!order.value) return
            error.value = false
            $axios
                .get('/v1/order/' + order.value.id + '/access')
                .then((response) => {
                    if (!response.data) showError('An error occurred in retrieving the access log.')
                    if (response.data.length === 0) showError('The access log for this file is blank.')
                    accessLogs.value = response.data
                })
                .catch(() => {
                    error.value = true
                })
        })

        return {
            accessLogs,
            error,
        }
    },
})
