import AscentKeyboardEvents from '@/plugins/KeyEventListener'
import { CommercialDetails, Product, ProductFee, Tag } from '@/types'
import { Payment, OrderModel } from '@/types'
import DefaultFeatures from '@/static/FeatureList'
import { PDRPDCDataKey, PDRPDCDetails } from '@/types/orderDetails'
import Vue from 'vue'

import { computed, Ref, ref } from '@vue/composition-api'
import { files, getFiles } from '@/plugins/Files'
import { getNotes, ResetAllNotes } from '@/plugins/Notes'
import { getAppraiserTags, getTags } from '@/plugins/Tags'
import { getPresentUsers, handleConnection, resetChannelRef } from '@/plugins/Channels'
import { getProducts, orderProducts } from '@/plugins/order/OrderProducts'
import { setRecentOrders } from '@/components/OrderTable/TablePartials/TableSearch.vue'
import { getLenderOptions, lenderOptions, optionsLoaded } from '@/pages/AMC/Lenders/Lender'
import $axios from '@/plugins/axios'
import { updateOrders } from '@/components/OrderTable/Classes/OrderTable'
import { AppraisalPanelReset } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import AmcContextMenu from '@/components/OrderScreens/Amc/AMCActions/AmcContextMenu'
import { reviewNotes, stepsCompleted } from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import { user } from '@/plugins/User'
import { showCustomSnackbar, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import AscentHelper from '@/helpers/ascent-helper'
import { loadComplexity } from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderComplexity/OrderComplexity'
import { setBarInfo } from '@/components/OrderScreens/Customer/CustomerLayout/CustomerStatusBar.vue'
import { appraiser } from '@/pages/Appraiser/Appraiser'
import { OfficeOrderAppraiser } from '@/types/appraisers'

export const orderOpen = ref(false)
export const orderAuditLogOpen = ref(false)
export const searchDropDown = ref(true)
export const order: Ref<undefined | OrderModel> = ref(undefined as undefined | OrderModel)
export const filesAndNotesLoaded = ref(false)
export const recentLocalOrders = ref([] as number[])
export const assignAnswer = ref('')
export const orderCommercial = ref(undefined as CommercialDetails | undefined)
export const snapshotOrder = ref(false)

export const orderTotalPayments = computed(() => {
    if (!order.value || !order.value?.incremental) return 0
    return order.value.incremental.payments.reduce((total: number, item: Payment) => total + item.amount, 0)
})

export const orderTotalDue = computed(() => (order.value as OrderModel).fee - orderTotalPayments.value)

export const appraiserFee = computed(() => {
    if (!order.value) return 0
    if (!order.value?.incremental) return 0
    if (order.value.incremental.propertyType === 'Residential' && AscentHelper.customerFee('resCommissionable') > 0) {
        return AscentHelper.customerFee('resCommissionable')
    }
    if (!order.value.incremental.order_appraisers.length) {
        return order.value?.appraiserFee
    }
    return order.value.incremental.order_appraisers
        .reduce((accumulator: number, currentValue: OfficeOrderAppraiser) => {
            if (currentValue.appraiser_id !== appraiser.value.id) return accumulator
            return accumulator + currentValue.fee
        }, 0)
        .toFixed(2)
})

export const customerFees = computed(() => {
    if (!order.value?.incremental?.customer.fees) return {}
    return order.value.incremental?.customer.fees
})

export const representativePhone = computed(() => {
    const phones = order.value?.incremental?.user.phones || []
    return phones[phones.length - 1]
})

export const directOrder = computed(() => {
    if (!order.value) return false
    return ['guild', 'encompass', 'form', 'mortgagedirector'].indexOf(order.value?.source as string) > -1
})

export const enabledFeatures = computed(() => {
    if (!order.value?.incremental?.customer.features) return DefaultFeatures
    return order.value.incremental?.customer.features
})

const defaultTabs = [
    { name: 'Notes', key: 'notes' },
    { name: 'Workflow', key: 'workflow' },
]

export const tabItems = ref(defaultTabs)
export const currentTab = ref('')

export const preloadTag = computed(() => {
    if (!order.value || !order.value.tags) return false
    return order.value.tags.find((elem) => elem.tag == 'Preload Required')
})
const setTabItems = async () => {
    if (currentTab.value == '') {
        currentTab.value = 'notes'
    }
    tabItems.value = [...defaultTabs]
    if (order.value?.status === 'Assign') {
        tabItems.value = [
            { name: 'Workflow', key: 'workflow' },
            { name: 'Notes', key: 'notes' },
        ]
        currentTab.value = 'workflow'
    }

    if (order.value?.incremental?.veros_submission != null) {
        tabItems.value.push({ name: 'Quality', key: 'quality' })
    }
}

export const loadIncremental = () => {
    return new Promise((resolve, reject) => {
        if (!order.value) return resolve({})

        Promise.all([getProducts(), getNotes(order.value.id), getFiles(order.value.id), getTags(order.value.id)]).then(
            () => {
                if (AscentHelper.isCustomer() && order.value) {
                    getAppraiserTags(order.value.appraiser_id)
                }
                filesAndNotesLoaded.value = true
            },
        )

        if (
            user.value &&
            user.value.type != 'appraiser' &&
            user.value.type != 'client' &&
            user.value.type != 'preload'
        ) {
            getLenderOptions(order.value.lender_id)
            loadComplexity()
        }
        $axios
            .get('/v1/order/' + order.value.id + '/incremental')
            .then((response) => {
                if (!order.value || !response) return resolve(false)
                Vue.set(order.value, 'incremental', response.data)
                resolve(response.data)
                setTabItems()
                if (AscentHelper.userOrCustomerFeatureEnabled('orderScreen.newOrderScreen', 'newOrderScreen', true)) {
                    setBarInfo()
                }
            })
            .catch(({ error }) => {
                reject(error)
            })
    })
}

export const openCloseOrder = (value: boolean) => {
    orderOpen.value = value
    if (value) {
        AscentKeyboardEvents.removeFocusSearch()
        AscentKeyboardEvents.focusEditor()
        currentTab.value = ''
    }
}

export interface OrderUpdateInterface {
    id: number
    userId: number
    message?: string
}

export const getVeros = () => {
    if (!order.value) return
    $axios.get('/v1/order/' + order.value.id + '/veros/findings').then(({ data }) => {
        if (!order.value?.incremental) return
        order.value.incremental.submissions = data.submissions
        order.value.incremental.ucdp = {
            findings: data.findings,
            stops: data.stops,
            submission: data.submission,
            veros: data.veros,
            submissions: data.submissions,
        }
    })
}

export const refreshOrder = async () => {
    if (!order.value) return
    return await $axios.get('/v1/ordercache/' + order.value.id).then(({ data }) => {
        if (!order.value) return
        order.value = { ...data, incremental: order.value.incremental }
        loadIncremental()
        return data
    })
}

export const subscribeOrderUpdates = () => {
    if (!order.value) return
    handleConnection({
        channelRef: 'orderUpdate',
        id: order.value.id,
    }).then((connector) => {
        connector.listen('.file-added', () => {
            if (order.value) getFiles(order.value.id)
            showCustomSnackbar({
                message: 'New files have been attached to the order',
                color: 'info',
                mode: '',
                x: 'right',
                y: 'top',
            })
        })

        connector.listen('.update-order', (data: OrderUpdateInterface) => {
            if (user.value?.id === data.userId) return
            if (order.value) {
                getNotes(order.value.id)
                getFiles(order.value.id)
                refreshOrder()
            }
            showCustomSnackbar({
                message: 'Updates have been made to this order. The order has automatically refreshed.',
                color: 'info',
                mode: '',
                x: 'right',
                y: 'top',
            })
        })

        connector.listen('.veros-submission-updated', (data: OrderUpdateInterface) => {
            if (order.value) {
                getVeros()
            }
            showCustomSnackbar({
                message:
                    data.message ?? 'SSR Updates have been made to this order. The order has automatically refreshed.',
                color: data.message ? 'error' : 'info',
                mode: '',
                x: 'right',
                y: 'top',
            })
        })
    })
}

export const getOrder = (tableOrder: OrderModel) => {
    if (tableOrder.order_type == 'review' && tableOrder.productList?.toLowerCase().includes('snapshot'))
        snapshotOrder.value = true
    openCloseOrder(true)
    order.value = tableOrder
    loadIncremental()
    setRecentOrders(tableOrder)
    handleConnection({
        channelRef: 'orderPresence',
        id: order.value.id,
    })

    subscribeOrderUpdates()
}

export const refreshOrderById = async (id: number) => {
    return await $axios.get('/v1/ordercache/' + id).then(({ data }) => {
        order.value = data
        loadIncremental()
        return data
    })
}

export const getOrderById = async (id: number | string) => {
    $axios.get('/v1/ordercache/' + id).then(({ data }) => {
        getOrder(data)
        return data
    })
}

export const getOrderByToken = (token: string) => {
    $axios.get('/v1/ordercache/token/' + token).then(({ data }) => {
        order.value = data as OrderModel
        loadIncremental()
        setRecentOrders(data)
        handleConnection({
            channelRef: 'orderPresence',
            id: order.value.id,
        })
        subscribeOrderUpdates()
    })
}

export const closeOrder = () => {
    if (!orderOpen.value) return
    order.value = undefined
    files.value = []
    orderOpen.value = false
    orderAuditLogOpen.value = false
    orderCommercial.value = undefined
    snapshotOrder.value = false
    ResetAllNotes()
    AppraisalPanelReset()
    updateOrders()
    resetChannelRef('orderPresence')
    AmcContextMenu.destroyMenus()
    AscentKeyboardEvents.removeFocusEditor()
    AscentKeyboardEvents.focusSearch()

    reviewNotes.value = []
    stepsCompleted.value = 0
}

export const isCompleted = computed(() => {
    if (!order.value) return null
    return (
        [
            'Completed',
            'QC Review',
            'QC Revision',
            'UW Request',
            'UW Revision',
            'Reconsideration Request',
            'Reconsideration of Value',
            'Reconsideration Review',
        ].indexOf(order.value.status) > -1
    )
})

export const isLinkReport = computed(() => {
    if (!order.value?.incremental) return
    if (order.value.incremental.customer_id !== 2) return false
    return ['Evaluate', 'Allocate'].indexOf(order.value.status) == -1
})

export const updateOrder = () => {
    if (!order.value) return
    getNotes(order.value.id)
    $axios.get('/v1/order/' + order.value.id + '/action/refresh-order').then(({ data }) => {
        if (!order.value) return
        if (!order.value.incremental) return
        order.value.tags = data['order.tags']
        order.value.orderStatus = data['order.orderStatus']
        order.value.status = data['order.status']
        order.value.incremental.amc_status.amc_status = data['order.orderStatus']
        order.value.incremental.amc_status.ascent_status = data['order.status']
    })
}

export const addTag = (tag: Tag) => {
    if (!order.value) return
    $axios
        .post('/v1/order/' + order.value.id + '/tag', tag)
        .then(({ data }) => {
            if (!order.value) return
            let tags = order.value.tags
            if (tags == null) {
                tags = []
            }
            data.deletable = true
            tags.push(data)
            showSnackbar('Tag Added Successfully.')
            order.value.tags = tags
        })
        .catch(({ response }) => {
            if (response.status == 422) showSnackbar(response.data, 'error')
        })
}

export const removeTag = (tag: Tag) => {
    $axios.delete('/v1/tag/' + tag.id).then(() => {
        if (!order.value) return
        const tags = order.value.tags
        tags.splice(tags.indexOf(tag), 1)
        order.value.tags = tags
        showSnackbar('Tag removed successfully')
    })
}

export const isOtherUsersInOrder = computed(() => {
    const userId = user.value?.id ?? 0
    return getPresentUsers('orderPresence').filter((elem: { id: number; name: string }) => elem.id !== userId).length
})

export const getOrderCommercialData = () => {
    if (orderCommercial.value || !order.value) return
    $axios.get(`/v1/order/${order.value.id}/commercial`).then((response) => {
        orderCommercial.value = response.data
    })
}

export const fees = ref({
    main: [],
    loans: [],
    other: [],
} as ProductFee)
export const originalAddOn = ref([] as Product[])
export const addOn = ref([] as Product[])
export const getAddOnList = () => {
    if (!order.value) return
    $axios
        .get('/v1/order/' + order.value.id + '/product', {
            params: {
                hasFee: 1,
            },
        })
        .then((response) => {
            if (!response.data) return
            originalAddOn.value = response.data
            if (!order.value) return
            if (!order.value?.incremental) return
            if (!orderProducts.value) {
                addOn.value = response.data
                return
            }
            if (typeof response.data === 'object') return
            addOn.value = response.data.filter((elem: Product) => {
                return orderProducts.value.findIndex((e) => e.form.id === elem.form.id) === -1
            })
        })
}
export const getFeeList = () => {
    if (!order.value) return
    $axios.get('/v1/order/' + order.value.id + '/product').then((response) => {
        fees.value = response.data
        getAddOnList()
    })
}

export const getLenderBaceId = (): null | string => {
    const products: string[] = ['ACE + PDR', 'Value Acceptance + PDC', 'ACE + PDR Repair Completion']

    if (order.value && !products.includes(order.value?.main)) return null
    if (order.value && !optionsLoaded.value) getLenderOptions(order.value.lender_id)

    if (!lenderOptions?.value['bace-id']) return null

    return typeof lenderOptions?.value['bace-id'] === 'string' ? lenderOptions?.value['bace-id'] : null
}

export const getPdrPdcData = (baceId: null | string = null): PDRPDCDetails => {
    const details = order.value?.incremental?.details?.details ? order.value?.incremental?.details?.details : {}

    const pdrPdcData: PDRPDCDetails = {
        baceLenderId: { label: 'BACE Lender ID: ', val: baceId ? baceId : getLenderBaceId() },
        lpaKey: { label: 'LPA Key: ', val: 'lpaKey' in details ? details['lpaKey'] : null },
        duCaseFileId: { label: 'DU Case File ID: ', val: 'duCaseFileId' in details ? details['duCaseFileId'] : null },
    }

    for (const entry of Object.entries(pdrPdcData)) {
        const dataKey: string = entry[0]
        if (entry[1] && typeof entry[1] === 'object' && 'val' in entry[1] && !entry[1].val && dataKey in pdrPdcData) {
            delete pdrPdcData[dataKey as PDRPDCDataKey]
        }
    }

    return pdrPdcData
}
