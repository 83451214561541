



























import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    components: {
        ValidationProvider,
    },
    props: {
        rules: {
            type: [Object, String],
            default: '',
        },
        value: {
            type: null,
        },
        clear: {
            type: String,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        max: {
            type: String,
            default: () => {
                return new Date().toISOString().split('T')[0]
            },
        },
    },
    data() {
        return {
            innerValue: null,
            datemenu: false,
        }
    },
    watch: {
        innerValue(newVal) {
            this.$emit('input', newVal)
        },
        value(newVal) {
            this.innerValue = newVal
        },
    },
    created() {
        if (this.value) {
            this.innerValue = this.value
        }
    },
})
