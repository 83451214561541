













import { defineComponent } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import CompleteOfficeOrderAction from '@/components/OrderScreens/Office/OrderComponents/Workflows/CompleteOfficeOrderAction.vue'
import AppraiserAMCCompleteAction from '@/components/OrderScreens/Appraiser/AppraiserWorkflow/AppraiserAMCCompleteAction.vue'
/**
 * @name CompleteOrderAction
 * Workflow step for amc and appraiser or Office to complete the appraisal and upload report
 */
export default defineComponent({
    components: {
        CompleteOfficeOrderAction,
        AppraiserAMCCompleteAction,
    },
    setup() {
        return {
            order,
        }
    },
})
