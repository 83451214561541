
























import { defineComponent, ref } from '@vue/composition-api'
import AEditor from '@/global/Form/AEditor.vue'
import ACardAction from '@/global/ACardAction.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'

export const clientNoteDialog = ref(false)

export default defineComponent({
    components: { ACardAction, AEditor },
    setup() {
        const note = ref(
            'Thank you for your order. Upon review, it looks like we still need the purchase contract before we can assign this to an appraiser.',
        )

        const resetDialog = () => {
            clientNoteDialog.value = false
            note.value =
                'Thank you for your order. Upon review, it looks like we still need the purchase contract before we can assign this to an appraiser.'
        }
        const clientNoteSent = () => {
            showSnackbar('Note successfully sent to client!')
            refreshOrder()
            resetDialog()
        }

        return {
            clientNoteDialog,
            note,
            order,
            resetDialog,
            clientNoteSent,
        }
    },
})
