



































import { defineComponent } from '@vue/composition-api'
import NotificationGroup from '@/pages/System/Notifications/NotificationPartials/NotificationGroup.vue'
import { markRead, markUnread, markAllRead } from '@/pages/System/Notifications/notifications'
import { Notification } from '@/types'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getOrderById } from '@/plugins/order/Order'

export default defineComponent({
    components: { NotificationGroup },
    props: {
        page: {
            type: Boolean,
            default: false,
        },
        itemGroups: {
            type: Array,
            default: () => {
                return []
            },
        },
        viewAll: {
            type: Object,
            default: () => {
                return {
                    text: 'See All Notifications',
                    route: '/notifications',
                }
            },
        },
    },
    data() {
        return {}
    },
    computed: {
        maxRecent() {
            if (!this.page) {
                return 5
            }

            return null
        },
    },
    methods: {
        openNotification(item: Notification) {
            switch (item.actionType) {
                case 'order':
                case 'reminder':
                    return this.openOrder(item)
            }
        },
        openOrder(item: Notification) {
            getOrderById(item.data.order.id)
            if (item.id && item.read_at == null) {
                markRead(item)
            }
        },
        marketSelectedRead(item: Notification) {
            markRead(item)
        },
        markSelectedUnread(item: Notification) {
            markUnread(item)
        },
        markSelectedAllRead(type: string) {
            markAllRead(type).then(() => {
                showSnackbar('Notifications marked as read.')
            })
        },
    },
})
