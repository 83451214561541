


















import { setFilterViews } from '@/components/General/Dashboard/Dashboards'
import { computed, defineComponent, onBeforeMount } from '@vue/composition-api'
import { filterViews, lenderSelection, users, appraiserSelection, teams } from '../../Classes/TableFilters'
import { ViewFilters } from '@/types'
import { viewFilters, viewTeam, viewUser } from '@/components/OrderTable/Classes/OrderTable'

export default defineComponent({
    setup() {
        onBeforeMount(() => setFilterViews())

        const viewingTeams = computed(() => {
            let enabled = true
            filterViews.value.filter((elem: ViewFilters) => {
                if (elem.label === 'viewTeam') enabled = elem.enabled
            })
            if (!enabled) return false
            if (viewTeam.value.length === 0) return ''
            const text = [] as string[]
            for (const i in viewTeam.value) {
                const col = teams.value.find((elem) => elem.id === viewTeam.value[i])?.name
                if (col) {
                    text.push(col)
                }
            }
            return text
        })

        const viewingUsers = computed(() => {
            let enabled = true
            filterViews.value.filter((elem: ViewFilters) => {
                if (elem.label === 'viewUser') enabled = elem.enabled
            })
            if (!enabled) return false
            if (viewUser.value.length === 0) return ''
            const text = [] as string[]
            for (const i in viewUser.value) {
                const col = users.value.find((elem) => elem.id === viewUser.value[i])?.name
                if (col) {
                    text.push(col)
                }
            }
            return text
        })

        const viewingStates = computed(() => {
            let enabled = true
            filterViews.value.filter((elem: ViewFilters) => {
                if (elem.label === 'viewState') enabled = elem.enabled
            })
            if (!enabled) return false
            if (viewFilters.value.state.length === 0) return ''
            const text = [] as string[]
            viewFilters.value.state.map((elem) => {
                text.push(elem)
            })

            return text
        })

        const viewingLenders = computed(() => {
            let enabled = true
            filterViews.value.filter((elem: ViewFilters) => {
                if (elem.label === 'viewLender') enabled = elem.enabled
            })
            if (!enabled) return false
            if (viewFilters.value.lender_id.length === 0) return ''
            if (viewFilters.value.lender_id[0] === -1) return ['All Lenders']
            const text = [] as string[]
            for (const i in viewFilters.value.lender_id) {
                const col = lenderSelection.value.find((elem) => elem.id === viewFilters.value.lender_id[i])?.name
                if (col) {
                    text.push(col)
                }
            }

            return text
        })

        const viewingPropertyType = computed(() => {
            let enabled = true

            filterViews.value.filter((elem: ViewFilters) => {
                if (elem.label === 'viewPropertyType') enabled = elem.enabled
            })
            if (!enabled) return false
            return viewFilters.value.propertyType
        })

        const viewingAppraiser = computed(() => {
            let enabled = true

            filterViews.value.filter((elem: ViewFilters) => {
                if (elem.label === 'viewAppraiser') enabled = elem.enabled
            })
            if (!enabled) return false
            if (viewFilters.value.appraiserAccess && viewFilters.value.appraiserAccess[0] === -1)
                return ['All Appraisers']
            const text = [] as string[]
            for (const i in viewFilters.value.appraiserAccess) {
                const col = appraiserSelection.value.find(
                    (elem) => elem.id === viewFilters.value.appraiserAccess[i],
                )?.name
                if (col) {
                    text.push(col)
                }
            }
            return text
        })

        const getCustomViewFilterValue = (filter: ViewFilters) => {
            if (viewFilters.value && viewFilters.value.customViewFilters.length === 0) return false
            if (!filter.enabled) return false
            if (filter && filter.filterVal) return [filter.filterVal]

            return false
        }

        const getData = (filter: ViewFilters) => {
            const label = filter.label

            if (label === 'viewTeam') return viewingTeams.value
            if (label === 'viewUser') return viewingUsers.value
            if (label === 'viewState') return viewingStates.value
            if (label === 'viewLender') return viewingLenders.value
            if (label === 'viewPropertyType') return viewingPropertyType.value
            if (label === 'viewAppraiser') return viewingAppraiser.value
            if (label === 'dashboardViewFilter') return getCustomViewFilterValue(filter)

            return true
        }

        return {
            filterViews,
            getData,
            viewingPropertyType,
            viewingAppraiser,
        }
    },
})
