






import { defineComponent } from '@vue/composition-api'
import { Order } from '@/types'

/**
 * @name ColumnGoogleAddress
 * Column on order table that has a link to google to quickly look up the address from google when clicked
 *
 * @SetupComputed - google: Returns a string of a url for a google search for the address on an order
 */

export default defineComponent({
    data() {
        return {
            data: {} as Order,
        }
    },
    computed: {
        google() {
            const order = this.data as Order
            if (order === undefined || order.address === undefined || typeof order.address.street === 'undefined')
                return ''
            return (
                'https://google.com/maps?q=' +
                order.address.street +
                ' ' +
                order.address.city +
                ' ' +
                order.address.state +
                ' ' +
                order.address.zip
            )
        },
    },
})
