






































































































import { defineComponent, onBeforeMount } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { order, updateOrder } from '@/plugins/order/Order'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { getNotes } from '@/plugins/Notes'
import { GET_PANELS } from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AppraisalPanel'
import { user } from '@/plugins/User'
import AssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/AssignPanels.vue'
import AscentHelper from '@/helpers/ascent-helper'
import JaroAssignPanels from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroAssignPanels.vue'
import {
    jaroPanels,
    getValidJaroPanels,
} from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/JaroPanels'
import JaroAssignPanelsVendorAssignedPartial from '@/components/OrderScreens/Workflow/SubWorkflow/Assign/JaroAssignPanels/Partials/JaroAssignPanelsVendorAssignedPartial.vue'

/**
 * @name AcceptAction
 * Accept Action in order workflow where Appraiser or Amc can accept or decline an order once it is assigned.
 *
 * OnBeforeMount is getting the panels for this particular order in the setup function
 */

export default defineComponent({
    setup() {
        onBeforeMount(async () => {
            if (AscentHelper.customerFeatureEnabled('usesJaroPanels', false)) {
                await getValidJaroPanels({ order_id: order.value?.id, customer_id: order.value?.customer_id })
                return
            }
            await GET_PANELS({
                type: 'order',
                id: order?.value?.id as number,
            })
        })

        return {
            order,
            jaroPanels,
        }
    },
    name: 'AcceptAction',
    components: {
        ValidationObserver,
        AssignPanels,
        JaroAssignPanels,
        JaroAssignPanelsVendorAssignedPartial,
    },
    data() {
        return {
            /**
             * The answer if the appraiser is accepting or declining
             */
            answer: '',
            /**
             * The reason for accepting or declining
             */
            reason: '',
            agreement: null,
            agree: false,
            conditionAgree: false,
            loading: false,
            expanded:
                user?.value?.type !== 'amc' ||
                AscentHelper.userOrCustomerFeatureEnabled('orderScreen.newOrderScreen', 'newOrderScreen', true),
            message: '',
        }
    },
    methods: {
        /**
         * Submits answer and reason to backend whether they accept the order or not
         * Endpoint: /v1/order/order.id/workflow/conditionally-accept
         */
        submit() {
            if (!order.value) return
            this.loading = true
            this.$axios
                .post('/v1/order/' + order.value.id + '/workflow/conditionally-accept', {
                    reason: this.reason,
                })
                .then(() => {
                    if (!order.value) return
                    updateOrder()
                    getNotes(order.value.id)

                    showSnackbar('Submitted Successfully')
                })
                .catch(({ response }) => {
                    if (!response.data.errors[0][0]) return
                    this.message = response.data.errors[0][0]
                    this.loading = false
                })
        },
    },
})
