



























import { defineComponent, PropType } from '@vue/composition-api'
import { ExtraNoteField } from '@/types'
import { hasValueFormat, formatValue } from '../AuditNoteDetails.vue'

export default defineComponent({
    name: 'AuditNoteTable',
    props: {
        details: {
            type: Object as PropType<ExtraNoteField>,
            default: null,
        },
    },
    setup() {
        return {
            hasValueFormat,
            formatValue,
        }
    },
})
