

























































































import { expMonths, expYears, masks } from '@/plugins/Data'

interface Payment {
    ccnumber: string
    expmonth: string
    expyear: string
    cvv: string
    name: string
}

import { defineComponent } from '@vue/composition-api'

import JSEncrypt from 'jsencrypt'
import { mask } from 'vue-the-mask'
import AscentHelper from '@/helpers/ascent-helper'

function reset() {
    return {
        payment: {
            ccnumber: '',
            expmonth: '',
            expyear: '',
            cvv: '',
            name: '',
        },
        loading: false,
        error: false,
        errorMessage: '',
    }
}

export default defineComponent({
    name: 'Nexio',
    directives: { mask },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        help: {
            type: Boolean,
            default: true,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        clearFields: {
            type: Boolean,
            default: true,
        },
        customerId: {
            type: Number,
            default: null,
        },
        disallow: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        return { expMonths, expYears, masks }
    },
    data() {
        return reset()
    },
    methods: {
        addCard() {
            this.errorMessage = ''
            const allowed = AscentHelper.checkAllowedCardTypes(this.disallow, this.payment.ccnumber)
            if (!allowed.allowed) {
                this.errorMessage = allowed.message
                this.$emit('error', { error: true, result: false, message: allowed.message })
                return
            }

            this.loading = true
            let url = '/v1/card/tokens'
            if (typeof this.$props.customerId === 'number') {
                url += '?customer_id=' + this.$props.customerId
            }
            this.$axios
                .get(url)
                .then((response) => {
                    this.encryptCard(response.data.key, response.data.token, response.data.url)
                })
                .catch((error) => {
                    this.loading = false
                    this.$emit('error')
                    this.errorMessage = error.response.data ?? error
                })
        },
        cancel() {
            this.loading = false
            if (this.clearFields) {
                Object.assign(this.$data, reset())
            }
        },
        encryptCard(key: string, token: string, url: string) {
            const crypt = new JSEncrypt({})
            crypt.setKey(key)

            const encryptedNumber = crypt.encrypt(this.payment.ccnumber.replace(/\s+/g, ''))
            fetch(url + '/saveCard', {
                method: 'POST',
                body: JSON.stringify({
                    token: token,
                    processingOptions: {
                        verifyCvc: true,
                    },
                    card: {
                        encryptedNumber: encryptedNumber,
                        expirationMonth: this.payment.expmonth,
                        expirationYear: this.payment.expyear.slice(-2),
                        cardHolderName: this.payment.name,
                        securityCode: this.payment.cvv,
                    },
                }),
            })
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.loading = false

                    if (response && response.error) {
                        this.$emit('error')
                        this.errorMessage = response.message ?? response.error
                    } else {
                        this.$emit('card-added', {
                            card: this.payment.ccnumber.slice(-4),
                            token: response.token.token,
                            name: this.payment.name,
                            type: 'nexio',
                            expMonth: this.payment.expmonth,
                            expYear: this.payment.expyear,
                        })

                        if (this.clearFields) {
                            Object.assign(this.$data, reset())
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false
                    this.errorMessage = error.response ?? error
                })
        },
    },
})
