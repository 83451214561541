















































































import { defineComponent, ref, PropType } from '@vue/composition-api'
import { Form, DefaultBillingMethodOption } from '@/types'
import { ValidationObserver } from 'vee-validate'

export const optionsProductDialog = ref(false)

export default defineComponent({
    emits: ['add', 'remove', 'save', 'cancel'],
    components: {
        ValidationObserver,
    },
    props: {
        optionLocal: {
            type: Object as PropType<DefaultBillingMethodOption>,
        },
        billingOptionItems: {
            type: Array as PropType<{ title: string; value: string }[]>,
        },
        mainProducts: {
            type: Array as PropType<Form[]>,
        },
    },
    setup(props) {
        const filteredProducts = (index: number) => {
            const prod = props.optionLocal?.productBillingOptions?.flatMap((el, idx) => {
                if (idx != index) return el.products
            })

            const newProducts = props.mainProducts?.filter((form) => {
                if (prod && !prod.includes(form.id)) {
                    return form
                }
            })

            if (newProducts && newProducts.length > 0) return newProducts
            return props.mainProducts
        }

        return {
            optionsProductDialog,
            filteredProducts,
        }
    },
})
