
























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import LenderOptionBilling from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionBilling.vue'
import LenderOptionLenderSavedCard from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionLenderSavedCard.vue'
import axios from 'axios'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import LenderOnboardingStatus from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderOnboardingStatus/LenderOnboardingStatus.vue'
import { Task } from '@/types'
import LenderOptionHoldForApproval from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionHoldForApproval.vue'
import LenderOptionHoldForPayment from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionHoldForPayment.vue'
import LenderOptionInvoiceOptions from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionInvoiceOptions.vue'
import LenderOptionPaymentName from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionPaymentName.vue'
import LenderOptionBillingNotes from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionBillingNotes.vue'
import LenderOptionQcBilling from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionQcBilling.vue'
import LenderOptionBillingTerms from '@/components/General/LenderOptions/OfficeLenderEditPartials/LenderPagePartails/LenderOptionBillingTerms.vue'
import LenderOptionTechFee from '@/components/General/LenderOptions/OfficeLenderEditPartials/LenderPagePartails/LenderOptionTechFee.vue'
import LenderOptionAutoChargeBorrower from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionAutoChargeBorrower.vue'
import LenderOptionDefaultBillingMethod from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionDefaultBillingMethod.vue'
import LenderOptionDisableSurcharges from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionDisableSurcharges.vue'
import { lenderBillingPageOptions } from '@/components/General/LenderOptions/LenderOptions'
import LenderOptionDefaultPaymentLink from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionDefaultPaymentLink.vue'
import LenderOptionPreAuthorizationCardPayment from '@/components/General/LenderOptions/AmcLenderEditPartials/LenderBilling/LenderBillingPartials/LenderOptionPreAuthorizationCardPayment.vue'

export default defineComponent({
    components: {
        LenderOptionPaymentName,
        LenderOptionInvoiceOptions,
        LenderOptionHoldForPayment,
        LenderOptionHoldForApproval,
        LenderOnboardingStatus,
        LenderOptionLenderSavedCard,
        LenderOptionBilling,
        LenderOptionAutoChargeBorrower,
        LenderOptionBillingNotes,
        LenderOptionQcBilling,
        LenderOptionBillingTerms,
        LenderOptionTechFee,
        LenderOptionDefaultBillingMethod,
        LenderOptionDisableSurcharges,
        LenderOptionDefaultPaymentLink,
        LenderOptionPreAuthorizationCardPayment,
    },
    setup(_, { emit }) {
        const task = ref<Task>({ id: 0, status: '', user_id: 0 })
        const loaded = ref(false)

        const options = ref()

        const updateStatus = (status: Event) => {
            emit('updateStatus', status)
        }

        const getTasks = () => {
            axios.get(`/v1/lender/${lender.value.id}/action/get-lender-billing`).then((res) => {
                task.value = res.data.task
                options.value = { ...lenderBillingPageOptions.value, ...res.data.options }
                loaded.value = true
            })
        }

        onMounted(getTasks)

        return {
            updateStatus,
            options,
            loaded,
            task,
            lenderBillingPageOptions,
        }
    },
})
