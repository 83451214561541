




























import { defineComponent, watch } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import $axios from '../../../../../plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { appraiserSettingsId, appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'

export default defineComponent({
    components: {},
    props: {
        option: {
            type: Object,
        },
    },

    setup(props) {
        const reviewOptions = [
            { title: 'Disable Automated Reviews', optionValue: 'disable_automated_reviews' },
            { title: 'Disable External Reviews', optionValue: 'disable_external_reviews' },
        ]

        watch(appraiserOptions, () => {
            if (props.option && props.option.reviews) {
                appraiserOptions.value.reviews.disable_automated_reviews =
                    props.option.reviews.disable_automated_reviews
                appraiserOptions.value.reviews.disable_external_reviews = props.option.reviews.disable_external_reviews
            }
        })

        if (props.option && props.option.reviews) {
            appraiserOptions.value.reviews.disable_automated_reviews = props.option.reviews.disable_automated_reviews
            appraiserOptions.value.reviews.disable_external_reviews = props.option.reviews.disable_external_reviews
        }

        const saveChanges = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'reviews',
                    option_value: appraiserOptions.value.reviews,
                })
                .then(() => {
                    showSnackbar('Saved successfully')
                })
        }

        return {
            saveChanges,
            lender,
            appraiserOptions,
            reviewOptions,
        }
    },
})
