



































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { ValidationObserver } from 'vee-validate'

/**
 * @name 'Office Lender Delivery Instructions'
 * Component description: Is a Lender Option for the Office Delivery Instructions. Will Display the Current Instructions Document with the option to edit;
 * @SetupData onMounted() => will get the office delivery instructions value for the customer id and set the correct values;
 * @SetupData save() => Will save the updated option and reset the displayed values to the correct values while closing the dialog;
 * @SetupData document: The value that is the the v-model when editing and saving ;
 * @SetupData option: The value that is displayed and is reset every time a new option gets saved;
 * @SetupData dialog: The boolean value that will determine if the editing dialog is open or closed;
 */
export default defineComponent({
    components: {
        ValidationObserver,
    },
    name: 'OfficeLenderDeliveryInstructions',
    setup() {
        const options = ref('')
        const dialog = ref(false)
        const document = ref()

        const save = () => {
            showSnackbar('Option saved successfully')
            dialog.value = false
            options.value = document.value
        }

        onMounted(() => {
            $axios.get('/v1/lender/' + lender.value.id + '/option/office-delivery-instructions').then((response) => {
                if (response.data.option_value) {
                    document.value = response.data.option_value
                    options.value = response.data.option_value
                }
            })
        })

        return {
            save,
            dialog,
            document,
            lender,
            options,
        }
    },
})
