






















































import { defineComponent, ref } from '@vue/composition-api'
import AddProductDialog from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/AddProductDialog.vue'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { order, refreshOrder } from '@/plugins/order/Order'
import Vue from 'vue'
import $axios from '@/plugins/axios'
import { Product, ProductFee } from '@/types'
import { lenderOptions } from '@/pages/AMC/Lenders/Lender'
import { currentReviewStep } from '@/components/OrderScreens/Workflow/SubWorkflow/ReviewOrder/Review'
import OrderFeeTable, {
    FeeUpdateInput,
} from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderFeeTable.vue'
import OrderAddOnTable from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderDetails/OrderAddOnTable.vue'
import { orderProducts } from '@/plugins/order/OrderProducts'
import { requiresFeeModification } from '@/plugins/order/OrderModification'

const dialog = ref(false)
const products = ref([] as Product[])
const fees = ref({
    main: [],
    loans: [],
    other: [],
} as ProductFee)

const addOn = ref([] as Product[])
const showAddOn = ref(false)
const newFee = ref(false)
const originalAddOn = ref([] as Product[])

const getAddOnList = () => {
    if (!order.value) return
    $axios
        .get('/v1/order/' + order.value.id + '/product', {
            params: {
                hasFee: 1,
            },
        })
        .then((response) => {
            if (!response.data) return
            originalAddOn.value = response.data
            if (!order.value) return
            if (!order.value?.incremental) return
            if (!orderProducts.value) {
                addOn.value = response.data
                return
            }
            if (typeof response.data.filter !== 'undefined') {
                addOn.value = response.data.filter((elem: Product) => {
                    return orderProducts.value.findIndex((e) => e.form.id === elem.form.id) === -1
                })
            }
        })
}
const getFeeList = () => {
    if (!order.value) return
    $axios.get('/v1/order/' + order.value.id + '/product').then((response) => {
        fees.value = response.data
        getAddOnList()
    })
}
export const open = (newFeeInput = false) => {
    dialog.value = true
    if (!order.value?.incremental) return
    products.value = JSON.parse(JSON.stringify(orderProducts.value))
    getFeeList()
    newFee.value = newFeeInput
    showAddOn.value = false
}

export default defineComponent({
    components: { AddProductDialog, OrderFeeTable, OrderAddOnTable },
    setup() {
        return {
            dialog,
            products,
            fees,
            newFee,
            lenderOptions,
            currentReviewStep,
            addOn,
            showAddOn,
        }
    },
    data() {
        return {
            loading: false,
            error: '',
            total: 0,
        }
    },
    methods: {
        deleteFee(item: Product) {
            if (!order.value || !order.value?.incremental) return
            products.value.splice(products.value.indexOf(item), 1)
            orderProducts.value.splice(products.value.indexOf(item), 1)
            if (originalAddOn.value.length > 0) {
                originalAddOn.value.map((elem) => {
                    if (elem.form.id === item.form.id) addOn.value.push(elem)
                })
            }
        },
        addFee(line: Product) {
            products.value.push({
                product_id: line.form?.id as number,
                product_name: line.form?.name as string,
                amc_value: line.amc_value,
                appraiser_value: line.appraiser_value,
                line_total: parseFloat(line.amc_value as string) + parseFloat(line.appraiser_value as string),
                form: line.form,
            })
            if (!order.value || !order.value?.incremental) return
            orderProducts.value.push({
                product_id: line.form?.id as number,
                product_name: line.form?.name as string,
                amc_value: line.amc_value,
                appraiser_value: line.appraiser_value,
                line_total: parseFloat(line.amc_value as string) + parseFloat(line.appraiser_value as string),
                form: line.form,
            })
        },
        addAddOn(addOns: Product) {
            products.value.push({
                product_id: addOns.form?.id as number,
                product_name: addOns.form?.name as string,
                amc_value: parseFloat(addOns.amc as string) as number,
                appraiser_value: parseFloat(addOns.appraiser as string) as number,
                line_total: parseFloat(addOns.company as string) as number,
                form: addOns.form,
            })
            addOn.value.splice(addOn.value.indexOf(addOns), 1)
            if (!order.value || !order.value?.incremental) return
            orderProducts.value.push({
                product_id: addOns.form?.id as number,
                product_name: addOns.form?.name as string,
                amc_value: parseFloat(addOns.amc as string) as number,
                appraiser_value: parseFloat(addOns.appraiser as string) as number,
                line_total: parseFloat(addOns.company as string) as number,
                form: addOns.form,
            })
        },
        getFees(item: Product) {
            if (!order.value) return
            this.$axios.get('/v1/order/' + order.value.id + '/product/' + item.form.id).then((response) => {
                if (response.data.quote) {
                    products.value[products.value.indexOf(item)].amc_value = 0
                    products.value[products.value.indexOf(item)].appraiser_value = 0
                    products.value[products.value.indexOf(item)].line_total = 0
                    return
                }

                products.value[products.value.indexOf(item)].product_name = response.data.name
                products.value[products.value.indexOf(item)].amc_value = parseFloat(response.data.amc)
                    ? parseFloat(response.data.amc).toFixed(2)
                    : 0
                products.value[products.value.indexOf(item)].appraiser_value = parseFloat(response.data.appraiser)
                    ? parseFloat(response.data.appraiser).toFixed(2)
                    : 0
                products.value[products.value.indexOf(item)].line_total =
                    (products.value[products.value.indexOf(item)].amc_value as number) +
                    (products.value[products.value.indexOf(item)].appraiser_value as number)
            })
        },
        updateFees() {
            if (products.value.filter((elem) => elem.form.type === 'Main Product').length !== 1) {
                this.error = 'You can only have one main product on the order and you must have at least one'
            }
            if (products.value.filter((elem) => elem.form.type === 'Loan Type').length !== 1) {
                this.error = 'You can only have one loan type on the order and you must have at least one'
            }
            if (this.error) return false
            this.error = ''
            this.loading = true
            if (!order.value) return
            this.$axios
                .patch('/v1/order/' + order.value.id + '/action/update-products', {
                    products: products.value,
                })
                .then(() => {
                    dialog.value = false
                    if (!order.value) return
                    refreshOrder().then(() => {
                        if (!order.value?.incremental) return
                        Vue.set(order.value.incremental, 'products', products.value)
                        order.value.loan = products.value.find((product) => (product.formType = 'Loan Type'))
                            ?.product_name as string
                    })

                    showSnackbar('Fees and Forms Updated Successfully')
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        updateTotal(updatedTotal: number) {
            this.total = updatedTotal
        },
        updateInput(updatedInput: FeeUpdateInput) {
            products.value[products.value.indexOf(updatedInput.item)][updatedInput.column] = isNaN(
                parseFloat(updatedInput.val),
            )
                ? 0
                : parseFloat(updatedInput.val).toFixed(2)

            products.value[products.value.indexOf(updatedInput.item)].amc_value =
                (products.value[products.value.indexOf(updatedInput.item)].line_total as number) -
                (products.value[products.value.indexOf(updatedInput.item)].appraiser_value as number)
        },
    },
    computed: {
        requiresModification(): boolean {
            return (requiresFeeModification.value as boolean) && this.total !== order.value?.incremental?.total?.total
        },
    },
})
