

























import { defineComponent, onMounted, watch, ref, PropType } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import { order } from '@/plugins/order/Order'
import { TeamAllocation, Appraiser } from '@/types'

export default defineComponent({
    props: {
        appraiserId: {
            type: Number,
            default: 0,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        panel: {
            type: String,
            default: '',
        },
        value: {
            type: [Object, Number],
            default: null,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        appraiserProp: {
            type: Object as PropType<TeamAllocation>,
            default: () => {
                return {
                    appraiser_id: 0,
                    fee: '',
                    type: '',
                }
            },
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const appraisers = ref([] as { id: number }[])
        const appraiser = ref(props.value as number | Appraiser | null)
        const localChecked = ref(false)

        const getAppraisers = () => {
            if (!order.value) return
            $axios
                .post('/v1/order/' + order.value.id + '/assign/get-office-team-panel', {
                    panel: props.panel,
                })
                .then(({ data }) => {
                    appraisers.value = data
                    if (data.appraisers)
                        appraisers.value = data.appraisers.map((elem: TeamAllocation) => elem.appraiser)
                    emit('update-appraisers', data)
                })
        }

        const updateAppraiser = (e: number) => {
            emit('input', e)
        }

        watch(
            props,
            () => {
                localChecked.value = props.checked
                if (
                    localChecked.value &&
                    props.appraiserId !== 0 &&
                    appraisers.value.find((elem) => elem.id === props.appraiserId)
                )
                    appraiser.value = props.appraiserId
            },
            { deep: true },
        )

        onMounted(() => {
            getAppraisers()
            localChecked.value = props.checked
            if (props.appraiserProp) appraiser.value = props.appraiserProp.appraiser_id as number
        })

        return {
            appraisers,
            updateAppraiser,
            appraiser,
        }
    },
})
