


































import { defineComponent, ref } from '@vue/composition-api'
import { order } from '@/plugins/order/Order'
import $axios from '@/plugins/axios'
import { AppraiserPanel } from '@/types'
import { showError } from '@/AppLayout/helpers/snackbar.vue'
import { setImpersonateToken } from '@/helpers/token-helper'

export const impersonateDialog = ref(false)
export default defineComponent({
    setup(_, { root }) {
        const headers = [
            { text: 'Name', value: 'appraiser.name' },
            { text: 'Company', value: 'appraiser.company' },
            { text: 'Status', value: 'appraiser.status' },
            { text: 'Role', value: 'role' },
            { value: 'view', width: 200, sortable: false },
        ]

        const impersonateAppraiser = (item: AppraiserPanel) => {
            $axios
                .post('/v1/ascent/impersonate/appraiser/' + item.appraiser.id, {
                    path: root.$route.path,
                })
                .then((response) => {
                    if (response.data.data.token) {
                        setImpersonateToken(response.data.data.token)
                        window.location = response.data.data.path
                    } else {
                        showError('Unable to impersonate ' + item.appraiser.name)
                    }
                })
                .catch(({ response }) => {
                    showError(response.data.message)
                })
        }
        return {
            impersonateDialog,
            order,
            headers,
            impersonateAppraiser,
        }
    },
})
