import { ref, computed } from '@vue/composition-api'
import { Quote } from '@/types/quotes'
import $axios from '@/plugins/axios'
import { AppraiserQuoteResponse } from '@/types'
import { AscentApi } from '@/helpers/AscentDataAdapter'
import { tableSettings } from '@/pages/AMC/Quotes/Partials/PreQuote.vue'

export const sendQuotes = ref<number[]>([])
export const quotesArray = ref([] as AppraiserQuoteResponse[])
export const quote = ref([] as Quote[])
export const type = ref<string>('')
export const id = ref<number>(0)

import { viewFilters, viewUser } from '@/components/OrderTable/Classes/OrderTable'

export const getQuote = computed(() => quote.value)
export const getType = computed(() => type.value)

export const getAllQuotes = (types: string, details: number) => {
    return new Promise((resolve, reject) => {
        type.value = types
        id.value = details

        $axios
            .get('/v1/quotable/' + type.value + '/' + id.value + '/quoteResponse')
            .then((response) => {
                quotesArray.value = response.data
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const refreshQuotes = () => {
    return new Promise((resolve, reject) => {
        $axios
            .get('/v1/quotable/' + type.value + '/' + id.value + '/quoteResponse')
            .then((response) => {
                quotesArray.value = response.data
                resolve(true)
            })
            .catch(() => {
                reject(false)
            })
    })
}

export const RESET = (): void => {
    sendQuotes.value = []
    quotesArray.value = []
    quote.value = []
    type.value = ''
    id.value = 0
}

export const setQuotes = (value: AppraiserQuoteResponse[]) => {
    quotesArray.value = value
}

export const setQuote = (value: Quote[]) => {
    quote.value = value
}

export const searchTypeItems = ref<string[]>(['Order ID', 'Address', 'All'])
export const searchType = ref<string>('Order ID')
export const searchText = ref<string>('')
export const zipSearch = ref<string>('')
export const QuoteQuery = new AscentApi('/v1/quote-table')
export const dates = ref<string>('')
export const sort = ref<{ name: string; direction: string }[]>([] as unknown as { name: string; direction: string }[])
/**
 * Called every time an input is added on PreQuote
 */
export const updateQuoteTable = () => {
    QuoteQuery.setParams({
        filter: type.value,
        viewUser: viewUser.value.length ? viewUser.value : [-1],
        searchType: searchType.value,
        created_after: dates.value,
        sorted: sort.value,
        zip: zipSearch.value,
        viewFilters: viewFilters.value,
    })
        .search(searchText.value)
        .update()
        .then((data) => {
            if (data === undefined) return
            tableSettings.value.dataSource = data
        })
}
