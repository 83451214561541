













































































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { financeTypes } from '@/plugins/Data'
import { showSnackbar, showError } from '@/AppLayout/helpers/snackbar.vue'
import AEditor from '@/global/Form/AEditor.vue'
import MainSelect from '@/components/General/AutoCompletes/MainSelect.vue'
import LoanSelect from '@/components/General/AutoCompletes/LoanSelect.vue'
import AddOnSelect from '@/components/General/AutoCompletes/AddOnSelect.vue'
import LenderSelect from '@/components/General/AutoCompletes/LenderSelect.vue'
import { updateQuoteTable } from '@/pages/AMC/Quotes/Quotes'

function reset() {
    return {
        dialog: false,
        saving: false,
        useClient: false,
        errorMessage: undefined as string | undefined,
        quote: {
            address: {
                street: null,
                city: null,
                zip: null,
            },
            fee: null,
            loanNumber: null,
            completeDate: null,
            note: '',
            loan: null,
            main: null,
            addOns: [],
            email: '',
            client_contact_name: '',
            quoted_business_days: '',
            lender_id: 0,
        },
    }
}

/**
 * @name AddPreQuoteDialog
 * Component description: Creates a quote request with a requester, email, associated lender, loan details, quote address and internal notes.
 */
export default defineComponent({
    components: {
        AEditor,
        ValidationObserver,
        MainSelect,
        LoanSelect,
        AddOnSelect,
        LenderSelect,
    },
    setup() {
        return { financeTypes }
    },
    data() {
        return reset()
    },
    methods: {
        /**
         * updates table, creates a pre quote, resets all data in form
         * @emits added
         */
        saved() {
            updateQuoteTable()
            showSnackbar('Quote Request Created Successfully')
            const form = this.$refs.form as InstanceType<typeof ValidationObserver>
            form.reset()
            Object.assign(this.$data, reset())
            this.dialog = false
            this.$emit('added')
        },
        /**
         * Shows errors when attempts to create form (missing info, etc)
         * @param error - given error handling the submit button
         */
        showError(error: { [key: string]: string }) {
            if (error['address.zip']) {
                this.errorMessage = error['address.zip'][0]
            }

            if (error['email']) {
                this.errorMessage = error['email'][0]
            }

            showError(this.errorMessage as string)
        },
    },
})
