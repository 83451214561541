








































































import { File } from '@/types/files.ts'
import { defineComponent, PropType } from '@vue/composition-api'
import { download as DownloadFile } from '@/components/General/DownloadFile.vue'
import { showError, showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { confirm } from '@/AppLayout/helpers/confirm.vue'
import { refreshOrder } from '@/plugins/order/Order'
import EditOrderForm from '@/components/OrderScreens/Amc/OrderComponents/OrderPartials/OrderFiles/EditOrderForm.vue'

export default defineComponent({
    components: {
        EditOrderForm,
    },
    props: {
        file: {
            type: Object as PropType<File>,
        },
    },
    data() {
        return {
            hover: false,
        }
    },
    methods: {
        getColor(status: string) {
            switch (status) {
                case 'All':
                    return 'successdarken2'
                case 'Admin':
                    return 'teal darken-3'
                case 'Appraiser':
                    return 'yellow darken-4'
                case 'Client':
                    return 'purple darken-3'
                case 'Inactive':
                    return 'red darken-3'
            }
        },
        download(file: File) {
            const name =
                file.type.replace(/^.*[\\/]/, '') +
                '_' +
                file.order_id +
                '.' +
                file.path.substr(file.path.lastIndexOf('.') + 1)
            DownloadFile(file.id, name)
        },
        openFile(file: File) {
            this.$axios.get('/v1/file/' + file.id).then((response) => {
                window.open(response.data.url, '_blank')
            })
        },
        openEdit(file: File) {
            this.$emit('editFile', file)
        },
        regenerateFile(file: File) {
            confirm(
                'Confirm ' + file.type + ' Regeneration',
                'Are you sure you want to regenerate the ' + file.type + '?',
            ).then((confirm) => {
                if (confirm) {
                    this.$axios
                        .post('/v1/order/' + file.order_id + '/file/' + file.id + '/regenerate')
                        .then((response) => {
                            showSnackbar(response.data)
                            refreshOrder()
                        })
                        .catch((error) => {
                            showError(error)
                        })
                }
            })
        },
    },
})
