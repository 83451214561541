































































import { defineComponent, ref } from '@vue/composition-api'
import { lender } from '@/components/General/LenderOptions/LenderOptions'
import $axios from '@/plugins/axios'
import { showSnackbar } from '@/AppLayout/helpers/snackbar.vue'
import { assignDate } from '@/pages/Appraiser/Profile/SharedProfile/AppraiserAmcSettingsPage.vue'
import { appraiserSettingsId, appraiserOptions } from '@/pages/Appraiser/Profile/AppraiserProfile'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
    components: { ValidationObserver },
    props: {
        option: {
            type: Object,
        },
    },

    setup(props) {
        const dialog = ref<boolean>(false)
        const localAssignNotes = ref('')
        if (props.option && props.option.assign_notes) {
            appraiserOptions.value.assign_notes = props.option.assign_notes
            localAssignNotes.value = props.option.assign_notes
        }

        const saveChanges = () => {
            $axios
                .post('/v1/appraiser/' + appraiserSettingsId.value + '/options', {
                    option_key: 'assign_notes',
                    option_value: appraiserOptions.value.assign_notes,
                })
                .then(() => {
                    localAssignNotes.value = appraiserOptions.value.assign_notes
                    showSnackbar('Saved successfully')
                    dialog.value = false
                })
        }

        return {
            saveChanges,
            lender,
            appraiserOptions,
            assignDate,
            dialog,
            localAssignNotes,
        }
    },
})
